/* eslint-disable react/require-default-props */
import {
  Tab, Tabs, Typography, Box, InputBase,
} from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { useWorkflowState } from '../../hooks/useWorkflowState';
import RecordChangeHistoryTable from '../RecordChangeHistoryTable';
import Select from '../Select';

import { useStyles } from './style';

interface TabPanelProps {
  index: number;
  value: number;
  historyModalData: any;
  tableHeader?: any;
  searchHeader?: any;
  tableTitle?: string;
  defaultRecordsToShow?: number;
}

const TabPanel = (props: TabPanelProps) => {
  const {
    value,
    index,
    historyModalData,
    searchHeader,
    tableHeader,
    tableTitle,
    defaultRecordsToShow,
  } = props;
  const classes = useStyles();
  const {
    recordsToShow,
    tableState,
    handlePaginationChange,
    handleSearchChange,
    handleSelectChange,
    handleSortTable,
  } = useWorkflowState(
    searchHeader,
    historyModalData,
    null,
    false,
    true,
    defaultRecordsToShow || 1,
    true,
  );

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              margin: 10,
            }}
          >
            {tableTitle ? (
              <Typography style={{ color: 'gray' }}>
                {' '}
                {tableTitle}
                {' '}
              </Typography>
            ) : null}
          </div>
          <div
            className={clsx(
              'flexStartSpaceBetweenRow',
              classes.subHeaderContainer,
            )}
          >
            <div className={clsx('flexRow')}>
              <span className={classes.label}>Records :</span>
              <Select
                options={['Auto', '10', '20', '25', '35', '50', 'All']}
                width={200}
                onChange={handleSelectChange}
                value={tableState.recordsNumber}
              />
            </div>
            <div className="flexRow">
              <span className={classes.label}>Search :</span>
              <InputBase
                className={classes.searchInput}
                onChange={handleSearchChange}
                value={tableState.searchText}
              />
            </div>
          </div>
          <RecordChangeHistoryTable
            allDataCount={historyModalData.length}
            handleClickRow={() => null}
            handlePaginationChange={handlePaginationChange}
            recordsToShow={recordsToShow}
            tableHeader={tableHeader}
            tableState={tableState}
            order={tableState.order}
            orderBy={tableState.orderBy}
            searchText={tableState.searchText}
            sortTable={handleSortTable}
            visibleColumns={tableHeader}
          />
        </>
      )}
    </div>
  );
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface IChangeHistory {
  historyModalData: any;
  tabLabel: string;
  tableHeader?: any;
  searchHeader?: any;
  limitNumber?: number;
  tableTitle?: string;
  defaultRecordsToShow?: number;
}

const ChangeHistory: React.FC<IChangeHistory> = ({
  historyModalData,
  tabLabel,
  searchHeader,
  tableHeader,
  tableTitle,
  defaultRecordsToShow,
}) => {
  const [value, setValue] = React.useState(0);
  const classes = useStyles();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div>
      <div style={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              label={tabLabel}
              {...a11yProps(0)}
              className={classes.tab}
              style={{ textTransform: 'none' }}
            />
          </Tabs>
        </Box>
        <TabPanel
          value={value}
          index={0}
          historyModalData={historyModalData}
          tableHeader={tableHeader}
          searchHeader={searchHeader}
          tableTitle={tableTitle}
          defaultRecordsToShow={defaultRecordsToShow}
        />
      </div>
    </div>
  );
};

export default ChangeHistory;
