/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable max-len */
/* eslint-disable react/require-default-props */
import React from 'react';

// Librairies
import { Grid, InputBase, Typography } from '@mui/material';

// Styles
import clsx from 'clsx';
import { useStyles } from './style';
import DynamicFilter from '../DynamicFilter';

interface IProps {
    current: number;
    total: number;
    handleFilter: any;
    filterTxt: string;
}

const DynamicSearch: React.FC<IProps> = ({
  current,
  total,
  handleFilter,
  filterTxt,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={10}>
          <DynamicFilter
            onChange={handleFilter}
            value={filterTxt}
            maxLength={100}
            style={{ width: '100%' }}
            placeholder="Dynamic Filter"
            name="dynamicFilter"
          />
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body2" className={classes.viewOf}>Viewing {current} of {total}</Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default DynamicSearch;
