/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';

// Librairies
import { Grid } from '@mui/material';
import clsx from 'clsx';

// styles
import { useStyles } from './style';

// Utils
const getStepStyles = (
  rank: number,
  current: number,
): { step: React.CSSProperties; stepNumber: React.CSSProperties } => {
  if (rank > current) {
    return {
      step: {
        backgroundColor: '#ccc',
      },
      stepNumber: {
        backgroundColor: '#aaa',
      },
    };
  }
  if (rank === current) {
    return {
      step: { backgroundColor: '#2096cd' },
      stepNumber: { backgroundColor: '#1b7fad' },
    };
  }
  return {
    step: { backgroundColor: '#01a101' },
    stepNumber: { backgroundColor: '#006900' },
  };
};

type Step = {
  description: string;
  title: string;
};

interface IHorizontalStepperProps {
  steps: Step[];
  current: number;
  selectStep: (index: number) => void;
}

const HorizontalStepper: React.FC<IHorizontalStepperProps> = ({
  steps,
  current,
  selectStep,
}) => {
  const classes = useStyles();
  return (
    <Grid container spacing={2} classes={{ root: classes.container }}>
      {steps.map((step, index) => (
        <Grid item xs={12} md={4} classes={{ item: classes.item }} key={step.title}>
          <StepComponent
            key={index.toExponential()}
            description={step.description}
            title={step.title}
            number={index + 1}
            index={index}
            selectStep={selectStep}
            current={current}
          />
        </Grid>
      ))}
    </Grid>
  );
};

interface IStepComponentProps {
  title: string;
  description: string;
  number: number;
  index: number;
  selectStep: (index: number) => void;
  current: number;
}

const StepComponent: React.FC<IStepComponentProps> = ({
  description,
  title,
  number,
  selectStep,
  index,
  current,
}) => {
  const classes = useStyles();

  return (
    <div
      style={getStepStyles(index, current).step}
      onClick={() => selectStep(index)}
      className={clsx('flexRow', classes.step)}
    >
      <span
        style={getStepStyles(index, current).stepNumber}
        className={classes.stepNumber}
      >
        {number}
      </span>
      <div className={clsx('flexColumnCenterCenter', classes.stepTitle)}>
        {title}
        <br />
        {description}
      </div>
    </div>
  );
};

export default HorizontalStepper;
