/* eslint-disable max-len */
import { Dispatch } from 'redux';
import Api from '../../api/index';
import { GET_INIT_DATA_RCU_DITTO } from '../../constants/workflows';

interface GetWorkflow70InitResponse {
  data: GetWorkflow70InitData
}
export interface GetWorkflow70InitData {
  screenTitle: string;
  baysdittolist: Array<BaysDitto>;
  bayIDList: Array<string>;
}
interface BaysDitto {
  bayName: string;
  bayid: string;
  screendata: string;
}
export const getInitialData = () => async (dispatch: Dispatch) => {
  try {
    const screenId = 'WORKFLOW-070';
    const lang = 'en-US';
    const url = `workflow070/init?screenID=${screenId}&termID=B344&lang=${lang}`;
    const response = await Api.get<GetWorkflow70InitResponse>(url);
    dispatch({
      type: GET_INIT_DATA_RCU_DITTO,
      payload: response.data.data,
    });
  } catch (error) {
    console.error('getTankStatuses error', error);
  }
};
