import React, { useState } from 'react';

// Librairies
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ButtonBase, InputBase } from '@mui/material';
import clsx from 'clsx';

// Components
import SubHeader from '../../../components/subHeader';
import TmsHeader from '../../../components/header';
import ColumnsDialog from '../../../components/ColumnsDialog';
import UsersTable from '../../../components/UsersTable';

// Styles
import { useStyles } from './style';

// Actions
import { useWorkflowState } from '../../../hooks/useWorkflowState';
import Select from '../../../components/Select';
import UsersOptions from '../../../components/UsersOptions';
import OptionsButton from '../../../components/optionsButton';
import { getUsersData } from '../../../redux/actions/workflows/users';

// Utils
export const tableHeader = {
  UserId: 'User ID',
  Name: 'Name',
  'Group Name': 'Group Name',
  Inactive: 'Inactive',
  'Last Access': 'Last Access',
  'Password Modified Date': 'Password Modified Date',
  'Timeout Login Tries': 'Timeout Login Tries',
};

const searchHeader = [
  { label: 'User ID', key: 'UserId' },
  { label: 'Name', key: 'Name' },
  { label: 'Group Name', key: 'Group Name' },
  { label: 'Inactive', key: 'Inactive' },
  { label: 'Last Access', key: 'Last Access' },
  { label: 'Password Modified Date', key: 'Password Modified Date' },
  { label: 'Timeout Login Tries', key: 'Timeout Login Tries' },
];

const UsersScreen: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [columnsDialogOpen, setColumnsDialogOpen] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [batchModeActive, setBatchModeActive] = useState(false);
  const { data, fields } = useSelector((state: any) => state.Workflows.Users);
  const navigate = useNavigate();
  const {
    recordsToShow,
    tableState,
    handlePaginationChange,
    handleSearchChange,
    handleSelectChange,
    handleSortTable,
    handleCloseMenu,
    handleOptionClick,
  } = useWorkflowState(searchHeader, data, getUsersData, false, false, 18);

  const handleCloseSnackBar = () => {
    setSnackBarOpen(false);
  };

  const handleOpenColumnsDialog = () => {
    setColumnsDialogOpen(true);
    handleCloseMenu();
  };

  const handleCloseColumnsDialog = () => {
    setColumnsDialogOpen(false);
  };

  const handleSubmitNewColumns = (newColumns: string[]) => {
    // dispatch(updateConditionSetColumns(newColumns));
  };

  const handleRestoreDefault = () => {
    // dispatch(updateConditionSetColumns(Object.keys(tableHeader)));
  };

  const handleClickRow = (userId: string) => {
    if (batchModeActive) {
      if (selectedRows.includes(userId)) {
        const newRows = selectedRows.filter((id) => id !== userId);
        setSelectedRows(newRows);
      } else {
        setSelectedRows([...selectedRows, userId]);
      }
    }
  };

  const goToNewRecord = () => {
    // navigate('newRecord');
  };

  return (
    <>
      <TmsHeader />
      <SubHeader title="Users">
        <OptionsButton handleClick={handleOptionClick} />
        <UsersOptions
          anchorEl={tableState.anchorEl}
          goToNewRecord={() => null}
          onClose={handleCloseMenu}
        />
      </SubHeader>
      <div
        className={clsx('flexStartSpaceBetweenRow', classes.subHeaderContainer)}
      >
        <div
          className={clsx('flexRow')}
          style={{ flexGrow: '0.25', justifyContent: 'space-between' }}
        >
          <div className="flexRow">
            <span className={classes.label}>Records :</span>
            <Select
              options={['Auto', '10', '20', '25', '35', '50', 'All']}
              width={200}
              value={tableState.recordsNumber}
              onChange={handleSelectChange}
            />
          </div>
          <span />
          <div className={clsx('flexRow', classes.buttonsContainer)}>
            <ButtonBase
              className={classes.submitButton}
              onClick={() => setBatchModeActive(!batchModeActive)}
            >
              {batchModeActive ? 'Single Mode' : 'Batch Mode'}
            </ButtonBase>
            {batchModeActive ? (
              <>
                <ButtonBase className={classes.modifyButton}>Modify ...</ButtonBase>
                <ButtonBase className={classes.deleteButton}>Remove ...</ButtonBase>
              </>
            ) : null}
          </div>
        </div>
        <div className="flexRow">
          <span className={classes.label}>Search :</span>
          <InputBase
            className={classes.searchInput}
            value={tableState.searchText}
            onChange={handleSearchChange}
          />
        </div>
      </div>
      <UsersTable
        handleClickRow={handleClickRow}
        visibleColumns={fields}
        allDataCount={data.length}
        handlePaginationChange={handlePaginationChange}
        recordsToShow={recordsToShow}
        tableHeader={fields}
        tableState={tableState}
        order={tableState.order}
        orderBy={tableState.orderBy}
        searchText={tableState.searchText}
        sortTable={handleSortTable}
        batchMode={batchModeActive}
        selectedRows={selectedRows}
      />
      <ColumnsDialog
        title="Configure Visible Columns"
        onClose={handleCloseColumnsDialog}
        open={columnsDialogOpen}
        visibleColumns={{}}
        hiddenColumns={{}}
        columnsLabels={tableHeader}
        submitNewColumns={handleSubmitNewColumns}
        handleRestoreDefault={handleRestoreDefault}
      />
    </>
  );
};

export default UsersScreen;
