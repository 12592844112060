/* eslint-disable operator-linebreak */
import { Dispatch } from 'redux';
import Api from '../../api';
import {
  LOAD_EMCS_MANAGER,
  SET_EMCS_MANAGER_EXPORT_FILE,
  SET_EMCS_MANAGER_EXPORT_LINK,
  CLEAR_EMCS_MANAGER_EXPORT_FILE,
  CLEAR_EMCS_MANAGER_EXPORT_LINK,
} from '../../constants/workflows';

export const getEMCSManagerData = () => async (dispatch: Dispatch) => {
  const response = await Api.post('synajax/procajax', {
    data: {
      cmd: 'emcsmanager',
      parm: {
        action: 'interval',
        statusmode: 'collapsed',
      },
    },
  });
  dispatch({
    type: LOAD_EMCS_MANAGER,
    payload: {
      data: response.data[0].tableData,
      mode: response.data[0].mode,
    },
  });
};

export const exportEMCSManagerColumnsFileName =
  () => async (dispatch: Dispatch) => {
    const payload = {
      export_table: 'ARCRequest',
      workflow_screen_id: 'WORKFLOW-130',
      filter_name: '',
      export_data: false,
      file_type: 'xlsx',
      report_type: 'ARCRequest',
      selected_columns: {
        term_id: 'false',
      },
      spool_all_columns: true,
      filters: {
        table: 'ARCRequest',
        relation: 'all',
        filter_name: 'not in',
        filters: [
          {
            column: 'ARCStatus',
            operator: 'not_in',
            compare_value: "('S', 'X')",
          },
        ],
      },
    };
    const response = await Api.post('exportworkflow/spooldata', payload);
    dispatch({
      type: SET_EMCS_MANAGER_EXPORT_FILE,
      payload: response.data.data[0],
    });
  };

export const getEMCSManagerColumnsDownloadLink =
  (exportFile: string) => async (dispatch: Dispatch) => {
    let attempts = 0;
    const downloadFn = () => {
      if (attempts === 10) return;
      Api.get(`export/download-file?file=${exportFile}`)
        .then((res: any) => {
          if (res.status === 200) {
            dispatch({
              type: SET_EMCS_MANAGER_EXPORT_LINK,
              payload: res.data,
            });
          } else {
            setTimeout(downloadFn, 100);
            attempts += 1;
          }
        })
        .catch(() => {
          setTimeout(downloadFn, 100);
          attempts += 1;
        });
    };
    if (attempts === 10) return;
    downloadFn();
  };

export const clearEMCSManagerColumnsFileName = () => ({
  type: CLEAR_EMCS_MANAGER_EXPORT_FILE,
});

export const clearEMCSManagerColumnsExportLink = () => ({
  type: CLEAR_EMCS_MANAGER_EXPORT_LINK,
});
