/* eslint-disable max-len */
/* eslint-disable react/require-default-props */
import React, { useState } from 'react';

// Components
import ButtonBase from '@mui/material/ButtonBase';
import {
  List, ListItemText, ListItemButton, Collapse, Box, Typography,
} from '@mui/material';
import {
  Add, Clear, Remove,
} from '@mui/icons-material';

// Actions
import { useStyles } from './style';

export type ListItem = {
    id: string;
    title: string;
    type: 'group' | 'entry' | string,
    items?: ListItem[];
}

interface Props {
    rowHeight: number | string,
    items: ListItem[];
    clearable?: boolean;
    handleSelectEntry: (item: any) => void;
    handleSelectGroup: (item: any) => void;
    handleDeleteEntry: (item: any) => void;
    handleDeleteGroup: (item: any) => void;
    selectedEntryId?: any;
    selectedGroupId?: any;
    height?: string | number,
  }

const ExpandableList: React.FC<Props> = ({
  rowHeight,
  items,
  clearable = false,
  handleSelectEntry,
  handleSelectGroup,
  handleDeleteEntry,
  handleDeleteGroup,
  selectedEntryId,
  selectedGroupId,
  height,
}) => {
  const [open, setOpen] = useState<string[]>([]);
  const classes = useStyles();
  const [selectedRow, setSelectedRow] = useState<string>();

  const handleClick = (id: string) => {
    const currentIndex = open.indexOf(id);
    const newOpen = [...open];

    if (currentIndex === -1) {
      newOpen.push(id);
      setSelectedRow(id);
    } else {
      newOpen.splice(currentIndex, 1);
    }

    setOpen(newOpen);
  };

  function getColor(item: any, entryIdSelected: any, groupIdSelected: any) {
    switch (item.type) {
      case 'group':
        return item?.id === groupIdSelected ? '#428BCA' : 'transparent';
      case 'entry':
        return item?.id === entryIdSelected ? '#428BCA' : 'transparent';
      default: return 'inherit';
    }
  }

  function getTextColor(item: any, entryIdSelected: any, groupIdSelected: any) {
    return (item?.id === groupIdSelected || item?.id === entryIdSelected
      ? 'white' : 'inherit');
  }

  return (
    <List
      style={{ height }}
      classes={{
        root: classes.listItem,
      }}
    >
      {items.map((item) => (
        <Box
          key={item.id}
          sx={{ border: !clearable ? 'dashed 1px lightgray' : 'inherit', marginBottom: '8px' }}
        >
          <ListItemButton
            dense
            disableTouchRipple
            disableGutters
            style={{
              height: rowHeight,
              paddingRight: 8,
              backgroundColor: getColor(item, selectedEntryId, selectedGroupId),
              paddingTop: 0,
              paddingBottom: 0,
            }}
            selected={selectedRow === item.id}
            classes={{
              selected: classes.listItemButtonStyle,
            }}
            onClick={() => {
              if (item.type === 'group') {
                handleClick(item.id);
                handleSelectGroup(item.id);
              } else if (item.type === 'entry') {
                handleSelectEntry(item.id);
              }
            }}
          >
            {item.type === 'group' && (
            <>
              {item.items && open.includes(item.id) ? (
                <Remove fontSize="small" className={classes.expandIconStyle} />
              ) : (
                <Add fontSize="small" className={classes.expandIconStyle} />
              )}
            </>
            )}
            <ListItemText
              primary={(
                <Typography
                  variant="caption"
                  style={{ color: getTextColor(item, selectedEntryId, selectedGroupId) }}
                >
                  {item.title}
                </Typography>
                  )}
              style={{ paddingLeft: 12 }}
            />
            {clearable && (
            <ButtonBase
              onClick={() => {
                if (item.type === 'group') {
                  handleDeleteGroup(item);
                } else if (item.type === 'entry') {
                  handleDeleteEntry(item);
                }
              }}
            >
              <Clear fontSize="small" className={classes.removeIconStyle} />
            </ButtonBase>
            )}
          </ListItemButton>
          {item.items ? (
            <Collapse
              in={open.includes(item.id)}
              timeout="auto"
              unmountOnExit
              className={classes.nestedListWrapper}
            >
              <ExpandableList
                height="75%"
                rowHeight={rowHeight}
                items={item.items}
                clearable={!!item.items}
                selectedEntryId={selectedEntryId}
                selectedGroupId={selectedGroupId}
                handleSelectEntry={handleSelectEntry}
                handleSelectGroup={handleSelectGroup}
                handleDeleteEntry={handleDeleteEntry}
                handleDeleteGroup={handleDeleteGroup}
              />
            </Collapse>
          ) : null}
        </Box>
      ))}
    </List>
  );
};

export default ExpandableList;
