import React from 'react';

// Librairies
import { Menu, MenuItem, Divider } from '@mui/material';

// Styles
import { useStyles } from './style';

interface IProps {
  anchorEl: any;
  onClose: () => void;
  openCloneScreen: () => void;
  openGenerateDocument: () => void;
  openExportDocument: () => void;
}

const DocumentBuilderDetailMenu: React.FC<IProps> = ({
  anchorEl,
  onClose,
  openCloneScreen,
  openGenerateDocument,
  openExportDocument,
}) => {
  const classes = useStyles();
  return (
    <Menu
      classes={{ root: classes.root, list: classes.list, paper: classes.paper }}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
    >
      <MenuItem
        classes={{ root: classes.menuItem }}
        onClick={openCloneScreen}
      >
        Clone
      </MenuItem>
      <Divider />

      <MenuItem
        classes={{ root: classes.menuItem }}
        onClick={openGenerateDocument}
      >
        Generate Document ...
      </MenuItem>
      <Divider />

      <MenuItem
        classes={{ root: classes.menuItem }}
        onClick={openExportDocument}
      >
        Export Document
      </MenuItem>
    </Menu>
  );
};

export default DocumentBuilderDetailMenu;
