import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  wrapper: {
    padding: '0px 10px',
    marginBottom: 10,
  },
  searchInput: {
    fontWeight: '400 !important',
    fontSize: '12px !important',
    lineHeight: '1.42857143 !important',
    padding: '4px 4px',
    color: '#333333',
    backgroundColor: '#fff',
    border: '1px solid #e5e5e5',
    height: 33,
    width: 200,
  },
  utilsContainer: {
    marginBottom: 5,
  },
  label: {
    marginRight: 5,
    fontSize: 12,
    fontWeight: 400,
    display: 'flex',
    alignItems: 'center',
  },
  actionsWrapper: {
    margin: 5,
    marginLeft: 30,
    gap: 15,
    display: 'flex',
  },
  container: {
    width: '50%',
    overflow: 'hidden auto',
    boxSizing: 'border-box',
    border: '1px solid #e4e4e4',
    padding: '5px',
    marginRight: '2px',
  },
});
