/* eslint-disable max-len */
/* eslint-disable react/jsx-one-expression-per-line */
import * as React from 'react';
import ReactDOM from 'react-dom';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import ControlButton from '../../controlButton';
import useWindowDimensions from '../../../utils/windowDimensions';

const style = {
  position: 'absolute' as const,
  left: '20%',
  top: '10%',
  width: '50%',
  height: 170,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
};

interface IProps {
  title: string;
  message: string;
  handleYes: any;
  handleCancel: any;
  Visible: boolean;
}
const RackStatusDetailConfirmationModal: React.FC<IProps> = ({
  title,
  message,
  handleYes,
  handleCancel,
  Visible,
}) => {
  const { activeSreen, ScreensList } = useSelector((state: any) => state.ScreenReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  return ReactDOM.createPortal(
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={Visible}
      onClose={handleCancel}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 460,
      }}
    >
      <Fade in={Visible}>
        <Box sx={style}>
          <div style={{ padding: 10, backgroundColor: '#d2d2d2' }}>
            <Typography>{title}</Typography>
          </div>
          <div style={{
            height: 'auto', padding: '25px 5px',
          }}
          >
            <Typography>{message}?</Typography>
          </div>
          <Divider />
          <div style={{
            height: 'auto', padding: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center',
          }}
          >
            {/* <Typography color="red">This action cannot be undone.</Typography> */}
            <Grid container>
              <Grid item xs={10} />
              <Grid item xs={2}>
                <div style={{
                  height: 'auto', display: 'flex', gap: 5, alignItems: 'flex-end', alignSelf: 'center',
                }}
                >
                  <ControlButton
                    label="Yes"
                    color="#00c851"
                    disabled={false}
                    handleClick={handleYes}
                  />
                  <ControlButton
                    label="Cancel"
                    color="#ffbb33"
                    disabled={false}
                    handleClick={handleCancel}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </Box>
      </Fade>
    </Modal>,
    document.getElementById('draggable') as HTMLElement,
  );
};
export default RackStatusDetailConfirmationModal;
