/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  Checkbox,
  TextField,
  Typography,
  Select,
  MenuItem,
  ButtonBase,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import ClearIcon from '@mui/icons-material/Clear';
import Autocomplete from '@mui/material/Autocomplete';
import { v4 as uuidv4 } from 'uuid';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import React, { useState, useEffect } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { useStyles } from './style';
import api from '../../../redux/api';

const OrderMaintenanceRowComponent = ({
  classes,
  item,
  index,
  key,
  rowName,
  values,
  CancelNewRow, showExtraFields,
  items,
  handleDetailSelectChange,
  itemsRecord,
  setItemsRecord,
  validationMsg,
  setValidationMsg,
  contractItems,
  tooltipOpen,
  setTooltipOpen,
}:any) => {
  console.log('item r', itemsRecord);
  // console.log('item v', itemsRecord[0].ProdID === undefined);
  const dispatch = useDispatch();
  const [contractLoadData, setContractLoadData] = useState<any>(null);
  console.log('contractLoadData', contractLoadData);
  console.log('itemsRecord', itemsRecord);

  const handleFieldFunc = async (e: any, i = null) => {
    // console.log('evalue', e.target.value);
    console.log('target', e.target.value);

    const previousArray = JSON.parse(JSON.stringify(itemsRecord));
    previousArray[index] = {
      ...previousArray[index],
      [e.target.name]: e.target.value,
    };

    setItemsRecord(previousArray);

    if (e.target.name === 'ProdID') {
      const chainFields = {
        ProductId: e.target.value,
        TermID: (values.TermID !== '') ? values.TermID.value : '',
        SupplierNo: (values.SupplierNo !== '') ? values.SupplierNo.value : '',
        CustNo: (values.CustNo !== '') ? values.CustNo.value : '',
        AcctNo: (values.AcctNo !== '') ? values.AcctNo.value : '',
        DestinationNo: (values.DestinationNo !== '') ? values.DestinationNo.value : '',
      };
      const response = await api.get(
        'workflow161/get_load_id?screenID=WORKFLOW-161', {
          params: {
            chainFields: JSON.stringify(chainFields).toString(),
          },
          headers: {
            accept: '*/*',
          },
        },
      );
      setContractLoadData(response?.data);
      console.log('res', response);
    }
    if (validationMsg?.OrderItemNo === '' && validationMsg?.OrderQty === '') {
      setValidationMsg({ ...validationMsg, general: '' });
    }
  };
  return (

    <div className="detail-row">
      <thead>
        <tr className="tableRowHead">
          <th className={showExtraFields ? classes.productId : classes.w40}>Product ID</th>
          <th className={showExtraFields ? classes.w5 : classes.w20}>Order Quantity</th>
          <th className={showExtraFields ? classes.w5 : classes.w20}>Trailer</th>
          <th className={showExtraFields ? classes.w5 : classes.w20}>Compartment</th>
          {
                  showExtraFields && (
                  <>
                    <th className={classes.w5}>Retained</th>
                    <th className={classes.w5}>Loaded</th>
                    <th className={classes.w5}>Returned</th>
                    <th className={classes.w10}>ERP Handling Type</th>
                    <th className={classes.w10}>Tank</th>
                    <th className={classes.w5}>Tax Status</th>
                    <th className={classes.w5}>Contract Load ID</th>
                    <th className={classes.w5}>CPE Annual No</th>
                    <th className={classes.w5}>Contract Item No.</th>
                    <th className={classes.w5}>Batch No.</th>
                    <th className={classes.w5}>Batch Date</th>
                    <th className={classes.w5}>Batch Time</th>
                  </>
                  )
            }
          <th>
            <ClearIcon
              onClick={CancelNewRow(item)}
              className="icon-style"
            />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <Tooltip
              open={itemsRecord[index]?.ProdID === undefined || itemsRecord[index]?.ProdID === ''}
              className="custom-tooltip"
              title={(
                <>
                  <div>Value is required</div>
                </>
                  )}
              arrow
              placement="top"
            >
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                className="custom-select"
                onChange={(e) => handleFieldFunc(e, index)}
                name="ProdID"
                value={itemsRecord[index]?.ProdID}
                size="small"
                sx={{
                  width: '100%',
                  borderRadius: '0px',
                  marginTop: '5px',
                  '& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select':
                {
                  padding: '2px 8px',
                  border: '1px solid #E4E4E4',
                },
                }}
              >
                {
                    items?.length === undefined
                    && (
                    <MenuItem value="" className="list-item">
                      No data Available
                    </MenuItem>
                    )
                  }
                {
                    items?.map((prod:any) => (
                      <MenuItem value={prod.prod_id} className="list-item">
                        {prod.prod_name}
                      </MenuItem>
                    ))
                  }
              </Select>
            </Tooltip>
          </td>
          <td className="orderQty">
            <Tooltip
              open={itemsRecord[index]?.OrderQty < 0}
              className="custom-tooltip"
              title={(
                <>
                  <div>Value is required</div>
                  <div>Cannot be negative</div>
                </>
                  )}
              arrow
              placement="top"
            >
              <TextField
                id="outlined-basic"
                size="small"
                name="OrderQty"
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
                value={itemsRecord[index]?.OrderQty}
                onChange={(e) => handleFieldFunc(e, index)}
                sx={{
                  '& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
                    padding: '2px 8px',
                    border: '1px solid #E4E4E4',
                  },
                  '& input[type="time"]::-webkit-calendar-picker-indicator': {
                    display: 'inlineBlock',
                    bottom: 0,
                    color: 'transparent',
                    cursor: 'pointer',
                    height: 'auto',
                    left: 0,
                    position: 'absolute',
                    right: 0,
                    top: 0,
                  },
                }}
              />
            </Tooltip>

          </td>
          <td>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="Trailer"
              value={itemsRecord[index]?.Trailer}
              onChange={(e) => handleFieldFunc(e)}
              size="small"
              sx={{
                width: '100%',
                borderRadius: '0px',
                marginTop: '5px',
                '& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select':
                                  {
                                    padding: '2px 8px',
                                    border: '1px solid #E4E4E4',
                                  },
              }}
            >
              <MenuItem key={uuidv4()} value="" selected>
                No Data Available
              </MenuItem>
            </Select>
          </td>
          <td>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="Compartment"
              value={itemsRecord[index]?.Compartment}
              onChange={(e) => handleFieldFunc(e)}
              size="small"
              label="-- select --"
              sx={{
                width: '100%',
                borderRadius: '0px',
                marginTop: '5px',
                '& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select':
                              {
                                padding: '2px 8px',
                                border: '1px solid #E4E4E4',
                              },
              }}
            >
              <MenuItem key={uuidv4()} value="">
                No Data Available
              </MenuItem>
            </Select>
          </td>
          {
                  showExtraFields && (
                  <>
                    <td>
                      <TextField
                        id="outlined-basic"
                        size="small"
                        name="Retained"
                        type="number"
                        InputProps={{ inputProps: { min: 0 } }}
                        value={itemsRecord[index]?.Retained}
                        onChange={(e) => handleFieldFunc(e)}
                        sx={{
                          '& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
                            padding: '2px 8px',
                            border: '1px solid #E4E4E4',
                          },
                          '& input[type="time"]::-webkit-calendar-picker-indicator': {
                            display: 'inlineBlock',
                            bottom: 0,
                            color: 'transparent',
                            cursor: 'pointer',
                            height: 'auto',
                            left: 0,
                            position: 'absolute',
                            right: 0,
                            top: 0,
                          },
                        }}
                      />
                    </td>
                    <td>
                      <TextField
                        id="outlined-basic"
                        size="small"
                        name="Loaded"
                        type="number"
                        InputProps={{ inputProps: { min: 0 } }}
                        value={itemsRecord[index]?.Loaded}
                        onChange={(e) => handleFieldFunc(e)}
                        sx={{
                          '& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
                            padding: '2px 8px',
                            border: '1px solid #E4E4E4',
                          },
                          '& input[type="time"]::-webkit-calendar-picker-indicator': {
                            display: 'inlineBlock',
                            bottom: 0,
                            color: 'transparent',
                            cursor: 'pointer',
                            height: 'auto',
                            left: 0,
                            position: 'absolute',
                            right: 0,
                            top: 0,
                          },
                        }}
                      />
                    </td>
                    <td>
                      <TextField
                        id="outlined-basic"
                        size="small"
                        name="Returned"
                        type="number"
                        InputProps={{ inputProps: { min: 0 } }}
                        value={itemsRecord[index]?.Returned}
                        onChange={(e) => handleFieldFunc(e)}
                        sx={{
                          '& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
                            padding: '2px 8px',
                            border: '1px solid #E4E4E4',
                          },
                          '& input[type="time"]::-webkit-calendar-picker-indicator': {
                            display: 'inlineBlock',
                            bottom: 0,
                            color: 'transparent',
                            cursor: 'pointer',
                            height: 'auto',
                            left: 0,
                            position: 'absolute',
                            right: 0,
                            top: 0,
                          },
                        }}
                      />
                    </td>
                    <td>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="ERPHandlingType"
                        value={itemsRecord[index]?.ERPHandlingType}
                        onChange={(e) => handleFieldFunc(e)}
                        size="small"
                        sx={{
                          width: '100%',
                          borderRadius: '0px',
                          marginTop: '5px',
                          '& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select':
                                  {
                                    padding: '2px 8px',
                                    border: '1px solid #E4E4E4',
                                  },
                        }}
                      >
                        <MenuItem key={uuidv4()} value="" selected>
                          No Data Available
                        </MenuItem>
                      </Select>
                    </td>
                    <td>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        onChange={(e) => handleFieldFunc(e)}
                        name="Tank"
                        value={itemsRecord[index]?.Tank}
                        size="small"
                        sx={{
                          width: '100%',
                          borderRadius: '0px',
                          marginTop: '5px',
                          '& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select':
                                  {
                                    padding: '2px 8px',
                                    border: '1px solid #E4E4E4',
                                  },
                        }}
                      >
                        <MenuItem key={uuidv4()} value="" selected>
                          No Data Available
                        </MenuItem>
                      </Select>
                    </td>
                    <td>
                      <TextField
                        id="outlined-basic"
                        size="small"
                        name="TaxStatus"
                        value={itemsRecord[index]?.TaxStatus}
                        onChange={(e) => handleFieldFunc(e)}
                        sx={{
                          '& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
                            padding: '2px 8px',
                            border: '1px solid #E4E4E4',
                          },
                          '& input[type="time"]::-webkit-calendar-picker-indicator': {
                            display: 'inlineBlock',
                            bottom: 0,
                            color: 'transparent',
                            cursor: 'pointer',
                            height: 'auto',
                            left: 0,
                            position: 'absolute',
                            right: 0,
                            top: 0,
                          },
                        }}
                      />
                    </td>
                    <td>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="ContractLoadId"
                        value={itemsRecord[index]?.ContractLoadId}
                        onChange={(e) => handleFieldFunc(e)}
                        size="small"
                        sx={{
                          width: '100%',
                          borderRadius: '0px',
                          marginTop: '5px',
                          '& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select':
                              {
                                padding: '2px 8px',
                                border: '1px solid #E4E4E4',
                              },
                        }}
                      >
                        {
                            contractLoadData?.length ? contractLoadData?.map((data:any) => (
                              <MenuItem key={uuidv4()} value={data?.contract_load_id} className="list-item">
                                {data?.contract_load_id}
                              </MenuItem>
                            ))
                              : (
                                <MenuItem value="" className="list-item">
                                  No data Available
                                </MenuItem>
                              )
                        }
                      </Select>
                    </td>
                    <td>
                      <TextField
                        id="outlined-basic"
                        size="small"
                        name="CPEAnnualNo"
                        value={itemsRecord[index]?.CPEAnnualNo}
                        onChange={(e) => handleFieldFunc(e)}
                        sx={{
                          '& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
                            padding: '2px 8px',
                            border: '1px solid #E4E4E4',
                          },
                          '& input[type="time"]::-webkit-calendar-picker-indicator': {
                            display: 'inlineBlock',
                            bottom: 0,
                            color: 'transparent',
                            cursor: 'pointer',
                            height: 'auto',
                            left: 0,
                            position: 'absolute',
                            right: 0,
                            top: 0,
                          },
                        }}
                      />
                    </td>
                    <td>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="ContractItemNo"
                        value={itemsRecord[index]?.ContractItemNo}
                        onChange={(e) => handleFieldFunc(e)}
                        size="small"
                        sx={{
                          width: '100%',
                          borderRadius: '0px',
                          marginTop: '5px',
                          '& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select':
                              {
                                padding: '2px 8px',
                                border: '1px solid #E4E4E4',
                              },
                        }}
                      >
                        {
                          contractItems?.length === undefined
                          && (
                          <MenuItem value="" className="list-item">
                            No data Available
                          </MenuItem>
                          )
                        }
                        {
                          contractItems?.map((contractItem:any) => (
                            <MenuItem value={contractItem.id} className="list-item">
                              {contractItem.label}
                            </MenuItem>
                          ))
                        }
                      </Select>
                    </td>
                    <td>
                      <TextField
                        id="outlined-basic"
                        size="small"
                        name="BatchNo"
                        value={itemsRecord[index]?.BatchNo}
                        onChange={(e) => handleFieldFunc(e)}
                        sx={{
                          '& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
                            padding: '2px 8px',
                            border: '1px solid #E4E4E4',
                          },
                          '& input[type="time"]::-webkit-calendar-picker-indicator': {
                            display: 'inlineBlock',
                            bottom: 0,
                            color: 'transparent',
                            cursor: 'pointer',
                            height: 'auto',
                            left: 0,
                            position: 'absolute',
                            right: 0,
                            top: 0,
                          },
                        }}
                      />
                    </td>
                    <td>
                      <TextField
                        id="outlined-basic"
                        size="small"
                        name="BatchDate"
                        value={itemsRecord[index]?.BatchDate}
                        onChange={(e) => handleFieldFunc(e)}
                        sx={{
                          '& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
                            padding: '2px 8px',
                            border: '1px solid #E4E4E4',
                          },
                          '& input[type="time"]::-webkit-calendar-picker-indicator': {
                            display: 'inlineBlock',
                            bottom: 0,
                            color: 'transparent',
                            cursor: 'pointer',
                            height: 'auto',
                            left: 0,
                            position: 'absolute',
                            right: 0,
                            top: 0,
                          },
                        }}
                      />
                    </td>
                    <td>
                      <TextField
                        id="outlined-basic"
                        size="small"
                        name="BatchTime"
                        value={itemsRecord[index]?.BatchTime}
                        onChange={(e) => handleFieldFunc(e)}
                        sx={{
                          '& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
                            padding: '2px 8px',
                            border: '1px solid #E4E4E4',
                          },
                          '& input[type="time"]::-webkit-calendar-picker-indicator': {
                            display: 'inlineBlock',
                            bottom: 0,
                            color: 'transparent',
                            cursor: 'pointer',
                            height: 'auto',
                            left: 0,
                            position: 'absolute',
                            right: 0,
                            top: 0,
                          },
                        }}
                      />
                    </td>
                  </>
                  )
      }

        </tr>
      </tbody>
    </div>
  );
};
export default OrderMaintenanceRowComponent;
