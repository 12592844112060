import { combineReducers } from 'redux';
import AboutPage from './aboutPage';
import IoMonitor from './ioMonitor';
import DeviceCommunications from './deviceCommunications';
import MarqueeManager from './marqueeManager';
import TankGaugeEntry from './tankGaugeEntry';
import Traffic from './traffic';
import VehiclesInFacility from './vehiclesInFacility';
import MeterEntry from './meterEntry';
import TankStatusReducer from './tankStatus';
import DriversInFacility from './driversInFacility';
import EmcsConfigurationReducer from './emcsConfiguration';
import FolioFreezeClose from './folioFreezeClose';
import PersonnelInFacility from './personnelInFacility';
import ConditionSet from './conditionSet';
import DriversThroughFacility from './driversThroughFacility';
import ViewTraceLogs from './viewTraceLogs';
import ProductGroupCrossReference from './productGroupCrossReference';
import EMCSManager from './emcsManager';
import ContractDispatch from './contractDispatch';
import TileLayoutReducer from './tileLayout';
import TransactionViewer from './transactionViewer';
import TankStrapping from './tankStrapping';
import RackStatus from './rackStatus';
import RcuDitto from './rcuDitto';
import AddAuthProductsReducer from './addAuthProducts';
import OrderMaintenanceReducer from './orderMaintenance';
import UserSession from './userSession';
import DocumentBuilder from './documentBuilder';
import FieldAttributes from './fieldAttributes';
import Users from './users';

export default combineReducers({
  IoMonitor,
  DeviceCommunications,
  MarqueeManager,
  TankGaugeEntry,
  VehiclesInFacility,
  Traffic,
  MeterEntry,
  TankStatusReducer,
  DriversInFacility,
  EmcsConfigurationReducer,
  FolioFreezeClose,
  PersonnelInFacility,
  ConditionSet,
  DriversThroughFacility,
  ViewTraceLogs,
  ProductGroupCrossReference,
  EMCSManager,
  ContractDispatch,
  TileLayoutReducer,
  TransactionViewer,
  TankStrapping,
  RackStatus,
  RcuDitto,
  AddAuthProductsReducer,
  OrderMaintenanceReducer,
  UserSession,
  DocumentBuilder,
  AboutPage,
  FieldAttributes,
  Users,
});
