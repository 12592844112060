/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import {
  Alert,
  Button,
  Collapse,
  IconButton,
  Paper,
  Snackbar,
  Typography,
} from '@mui/material';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { add, format } from 'date-fns';
import { useStyles } from './style';
import TmsHeader from '../../../components/header';
import SubHeader from '../../../components/subHeader';
import CustomField from '../../../components/CustomField/CustomField';
import { TmsCustomDnD } from '../../../components/tmscustomDragandDrop';
import OperationalTable from '../../../components/operationalTable/operationalTable';
import stepOneStructure from './stepOneStructure.json';
import { unsetChildValuesOfChain } from '../../../utils';
import { getScreensList } from '../../../redux/actions/ScreenActions';

const headerStepper = [
  {
    key: 1,
    label: 'Step 1',
    description: 'Choose Business Level',
  },
  {
    key: 2,
    label: 'Step 2',
    description: 'Add Products',
  },
  {
    key: 3,
    label: 'Step 3',
    description: 'Finish / Confirm',
  },
];
const AddAthorizedProducts = () => {
  const { TerminalProducts, AccountProducts } = useSelector(
    (state: any) => state.Workflows.AddAuthProductsReducer,
  );
  const dispatch = useDispatch();
  const [values, setvalues] = useState({});
  const [StepOneValues, setStepOneValues] = useState<any>({});
  const [StepOneValuesToSend, setStepOneValuesToSend] = useState<any>({});
  const [Products, setProducts] = useState<any>([]);
  const [ProductsInistialState, setProductsInistialState] = useState<any>([]);
  const [selectedTaskIds, setSelectedTaskIds] = useState<any>([]);
  const [confirmStepProducts, setconfirmStepProducts] = useState(false);
  const [ProductsInitialList, setProductsInitialList] = useState([]);
  const [CustomerProducts, setCustomerProducts] = useState<any>([]);
  const [resType, setresType] = useState('');
  const [ConfirmationMsg, setConfirmationMsg] = useState('');
  const [datesFormat, setdatesFormat] = useState<any>({});
  const [ConfigDates, setConfigDates] = useState<any>({
    auth_eff_dt: format(new Date(), 'yyyy-MM-dd').toString(),
    auth_expr_dt: format(add(new Date(), {
      months: 1,
    }), 'yyyy-MM-dd').toString(),
    active_enable: { value: '20Y//', label: 'Yes' },
  });
  const [Errors, setErrors] = useState('');
  const [Structure, setStructure] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [Step2headerFields, setStep2headerFields] = useState([]);

  const [ProductList, setProductList] = useState<any>({});
  const [Step, setStep] = useState(1);
  const classes = useStyles();
  const step1Query = async () => {
    const responseQuery1 = await axios.get(
      `${process.env.REACT_APP_API_URL}workflow032/showstep?step=S1&lang=en-US&terminal=B344&supplier=0000000371&customer=0000008888&account=0000008888&scadProductTable=AccountProducts&auth_eff_dt=20221128&auth_expr_dt=20221128&active_enable=Y&groupID=Admin`,
    );
    const responseQuery2 = await axios.get(
      `${process.env.REACT_APP_API_URL}workflow032/showstep?step=S2&lang=en-US&terminal=B344&supplier=0000000371&customer=0000008888&account=0000008888&groupID=Admin`,
    );
    setStep2headerFields(responseQuery2.data.fields_return);
    const tempProducts: any = responseQuery2.data.sourceDestination[0].map(
      (val: any, key: any) => ({ id: key.toString(), title: val }),
    );
    setProductsInitialList(tempProducts);
    await setProductList({
      tasks: responseQuery2.data.sourceDestination[0].map(
        (val: any, key: any) => ({ id: key.toString(), title: val }),
      ),
      columnIds: ['todo', 'done'],
      columns: {
        todo: {
          id: 'todo',
          title: 'To do',
          taskIds: TerminalProducts.length
            ? TerminalProducts
            : tempProducts.map((val: any, key: any) => key.toString()),
        },
        done: {
          id: 'done',
          title: 'Done',
          taskIds: AccountProducts.length ? AccountProducts : [],
        },
      },
    });
    setProductsInistialState({
      tasks: responseQuery2.data.sourceDestination[0].map(
        (val: any, key: any) => ({ id: key.toString(), title: val }),
      ),
      columnIds: ['todo', 'done'],
      columns: {
        todo: {
          id: 'todo',
          title: 'To do',
          taskIds: TerminalProducts.length
            ? TerminalProducts
            : tempProducts.map((val: any, key: any) => key.toString()),
        },
        done: {
          id: 'done',
          title: 'Done',
          taskIds: [],
        },
      },
    });
    setStructure(responseQuery1.data);
    setLoading(false);
  };
  const handleSelectChange = (
    val: any,
    e: React.ChangeEvent<HTMLInputElement>,
    newValue: any,
  ) => {
    setConfigDates({
      ...ConfigDates,
      [val]: newValue.value,
    });
  };
  useEffect(() => {
    setdatesFormat(
      Object.entries(ConfigDates).map((item: any) => ({
        field: item[0],
        value: typeof item[1] === 'string' && item[1].search('-') === -1 ? `20${item[1]?.slice(0, 2)}/${item[1].slice(2, 4)}/${item[1].slice(4, 6)}` : item[1],
      })),
    );
  }, [ConfigDates]);

  const handleInputChange = (
    val: any,
    e: React.ChangeEvent<HTMLInputElement>,
    newValue: any,
  ) => {
    const { name, value } = val.target;
    setConfigDates({
      ...ConfigDates,
      [name]: value,
    });
    setdatesFormat(
      Object.entries(ConfigDates).map((item: any) => ({
        field: item[0],
        value: item[1],
      })),
    );
  };
  const handleReset = () => {
    setProductList(ProductsInistialState);
  };
  const handleSelectValuesChange = (
    label: any,
    e: React.ChangeEvent<HTMLInputElement>,
    newValue: any,
    _: any,
    chainedKeys?: string[],
  ) => {
    let newValues = { ...StepOneValues, [label]: newValue };
    let newValuesToSend = { ...StepOneValuesToSend, [label]: newValue.value };

    // Handle value chaining for chain type
    if (chainedKeys) {
      const { resultValues, resultValuesToSend } = unsetChildValuesOfChain(
        chainedKeys,
        newValues,
        newValuesToSend,
      );
      newValues = resultValues;
      newValuesToSend = resultValuesToSend;
    }

    setStepOneValues(newValues);
    setStepOneValuesToSend(newValuesToSend);
  };
  // const handleSelectValuesChange = (
  //   name: any,
  //   e: React.ChangeEvent<HTMLInputElement>,
  //   newValue: any,
  // ) => {
  //   setStepOneValues({
  //     ...StepOneValues,
  //     [name]: newValue.value,
  //   });
  // };
  const handleSelectProduct = (val: any) => {
    const prodList: any = Object.values(val)[1];
    setProducts(prodList.items.map((value: any) => ({ ProductId: value })));
  };
  useEffect(() => {
    step1Query();
    dispatch(getScreensList());
  }, []);
  const handleSubmit = async () => {
    if (Step === 1) {
      setStep(Step + 1);
    }
    if (Step === 2) {
      setconfirmStepProducts(true);
      setCustomerProducts(ProductsInitialList.filter((val: any, key: any) => ProductList?.columns?.done?.taskIds?.includes(val?.id)).map((prod: any) => ({ productId: prod?.title })));
      if (StepOneValues?.supplier_no?.value) {
        setStep(Step + 1);
      }
    }
    if (Step === 2 && StepOneValuesToSend.cust_no) {
      setStep(Step + 1);
    } if (Step === 3 && CustomerProducts.length !== 0) {
      try {
        const productsValuesToSubmit:any = ProductsInitialList.filter((val: any, key: any) => ProductList?.columns?.done?.taskIds?.includes(val?.id)).map((prod: any) => (prod?.title));
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}workflow032/update`,
          {
            terminal: 'B344',
            supplier: StepOneValuesToSend?.supplier_no,
            customer: StepOneValuesToSend?.cust_no,
            account: StepOneValuesToSend?.cust_no,
            destination: StepOneValuesToSend?.destination_no,
            scadProductTable: 'AccountProducts',
            postproductlist: productsValuesToSubmit.join(','),
            auth_eff_dt: ConfigDates?.auth_eff_dt,
            auth_expr_dt: ConfigDates?.auth_expr_dt,
            active_enable: ConfigDates?.active_enable?.value,
            lang: 'en-US',
            userID: 'tms',
          },
          {
            headers: {
              accept: '*/*',
              'Content-Type': 'application/json',
            },
          },
        );
        if (response.data.type === 'error') {
          setErrors(response.data.msg);
          setresType('error');
        } else {
          setConfirmationMsg(response.data.msg);
          setresType('success');
          setStepOneValues({});
          setStepOneValuesToSend({});
          handleReset();
          setStep(1);
        }
      } catch (e: any) {
        alert(e.message);
      }
    }
  };
  const handleStepClick = (step: number) => {
    if (step === 1) {
      setStep(step);
    }
    if (step === 1 && !confirmStepProducts) {
      handleReset();
      setStep(step);
    }
    if (step === 2) {
      setStep(step);
    }
    if (step === 3) {
      setconfirmStepProducts(true);
      setCustomerProducts(ProductsInitialList.filter((val: any, key: any) => ProductList?.columns?.done?.taskIds?.includes(val?.id)).map((prod: any) => ({ productId: prod?.title })));
      if (StepOneValues?.supplier_no?.value) {
        setStep(step);
      }
    }
  };
  const manageAddAuthHeader = () => {
    if (StepOneValues?.acct_no?.value) {
      return ['Terminal Products', 'Account Products'];
    } if (StepOneValues?.cust_no?.value) {
      return ['Terminal Products', ' Customer Products'];
    } if (StepOneValues?.supplier_no?.value) {
      return ['Terminal Products', ' Supplier Products'];
    }
  };
  const saveTerminalProducts = (list: any) => {
    setProductList(list);
  };
  const handlePreviousStep = () => {
    if (!confirmStepProducts && Step === 2) {
      handleReset();
    }
    setStep(Step - 1);
  };
  const handleNextStep = () => {
    if (Step === 2) {
      setconfirmStepProducts(true);
    }
    setStep(Step - 1);
  };
  const handleClose = () => {
    setConfirmationMsg('');
    setErrors('');
  };
  const tableOptionsprop = {
    tableID: 'test',
    page: '1',
    results_per_page: '10',
    fields: 'test',
    order_by: 'name desc',
    where: '',
  };
  return Loading ? (
    <Typography>loading...</Typography>
  ) : (
    <>
      <TmsHeader />
      <SubHeader
        title="Add Authorized Products"
        stepValues={StepOneValues}
        Step={Step}
      />
      {Object.keys(StepOneValues).length === 0 && Step >= 2 && (
        <Collapse
          in={Object.keys(StepOneValues).length === 0 && Step >= 2}
          style={{ minHeight: 50 }}
        >
          <Alert
            severity="error"
            action={(
              <IconButton aria-label="close" color="inherit" size="small">
                <CloseIcon fontSize="inherit" />
              </IconButton>
            )}
            sx={{ mb: 2 }}
          >
            <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
              <Typography style={{ fontWeight: 'bold', color: 'red' }}>
                Error!
              </Typography>
              {' '}
              No data found for Supplier / Customer / Account / Destination.
              Please return to step 1 or close view.
            </div>
          </Alert>
        </Collapse>
      )}
      { CustomerProducts.length === 0 && Step === 3 && (
      <Collapse
        in={CustomerProducts.length === 0 && Step === 3}
        style={{ minHeight: 50 }}
      >
        <Alert
          severity="error"
          action={(
            <IconButton aria-label="close" color="inherit" size="small">
              <CloseIcon fontSize="inherit" />
            </IconButton>
            )}
          sx={{ mb: 2 }}
        >
          <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
            <Typography style={{ fontWeight: 'bold', color: 'red' }}>
              Error!
            </Typography>
            {' '}
            No products selected to be added. Please return to step 2.
          </div>
        </Alert>
      </Collapse>
      )}
      { ((ConfigDates?.auth_eff_dt?.search('-') < 1 && ConfigDates?.auth_expr_dt?.search('-') < 1) && new Date(ConfigDates?.auth_eff_dt)?.getTime() > new Date(ConfigDates?.auth_expr_dt)?.getTime()) && Step === 2 && (
      <Collapse
        in={(new Date(ConfigDates?.auth_eff_dt)?.getTime() > new Date(ConfigDates?.auth_expr_dt)?.getTime())}
        style={{ minHeight: 50 }}
      >
        <Alert
          severity="warning"
          action={(
            <IconButton aria-label="close" color="inherit" size="small">
              <CloseIcon fontSize="inherit" />
            </IconButton>
            )}
          sx={{ mb: 2 }}
        >
          <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
            <Typography style={{ fontWeight: 'bold', color: 'orange' }}>
              Warning!
            </Typography>
            {' '}
            Effective Date is AFTER Expiration Date
          </div>
        </Alert>
      </Collapse>
      )}
      <Paper className={classes.headerStepper}>
        {headerStepper.map((val: any, key: any) => (
          <div
            key={val.key}
            className={
              Step < val.key
                ? classes.stepperNavigatorDisabled
                : Step > val.key
                  ? classes.stepperNavigatorSuccess
                  : classes.stepperNavigator
            }
            onClick={() => {
              handleStepClick(val.key);
            }}
          >
            <div
              className={
                Step < val.key
                  ? classes.stepNumberDisabled
                  : Step > val.key
                    ? classes.stepNumberSucess
                    : classes.stepNumber
              }
            >
              {val.key}
            </div>
            <div className={classes.stepLabel}>
              <Typography>{val.label}</Typography>
              <Typography>{val.description}</Typography>
            </div>
          </div>
        ))}
      </Paper>
      <div className={classes.controler}>
        <Button
          variant="contained"
          size="small"
          sx={{
            textTransform: 'none',
          }}
          disabled={Step === 1}
          onClick={handlePreviousStep}
        >
          Previous Step
        </Button>
        <Button
          variant="contained"
          size="small"
          sx={{
            textTransform: 'none',
          }}
          onClick={handleSubmit}
        >
          {Step === 3 ? 'Submit' : 'Next Step'}
        </Button>
      </div>
      <>
        {Step === 1 && (
          <div>
            {stepOneStructure?.map((val: any, key: any) => (
              <CustomField
                val={val}
                values={StepOneValues}
                valuesToSend={StepOneValuesToSend}
                handleInputChange={handleInputChange}
                handleSelectChange={handleSelectValuesChange}
                size="100%"
              />
            ))}
          </div>
        )}
        {Step === 2 && (
          <div>
            {ProductList && StepOneValues?.supplier_no?.value && (Step2headerFields?.map((val: any, key: any) => (
              <CustomField
                val={val}
                values={ConfigDates}
                valuesToSend={ConfigDates}
                handleInputChange={handleInputChange}
                handleSelectChange={handleSelectChange}
                size="100%"
              />
            )))}
            {ProductList && StepOneValues?.supplier_no?.value && (
              <TmsCustomDnD
                entitiesMock={ProductList}
                saveTerminalProducts={saveTerminalProducts}
                list={selectedTaskIds}
                title={manageAddAuthHeader()}
              />
            )}
          </div>
        )}
        {Step === 3 && CustomerProducts.length !== 0 && (
          <div style={{
            width: 'auto',
            paddingLeft: 10,
          }}
          >
            <div style={{
              width: '97%',
              border: '1px solid #000',
              margin: '10px 1px',
              padding: '10px',
              maxHeight: '45vh',
              position: 'relative',
              overflowY: 'auto',
            }}
            >
              <OperationalTable
                pages={CustomerProducts?.length}
                title={`Records List: ${CustomerProducts?.length}`}
                headCells={['Product ID']}
                rows={CustomerProducts}
                tableOpt={tableOptionsprop}
                BatchModeScreen="/batch-mode"
                routeDetails=""
                screenID={undefined}
                backScreen=""
                localSearchEnabled
                isBatch="Batch"
              />
            </div>
            <div style={{
              width: '97%',
              border: '1px solid #000',
              margin: '10px 1px',
              padding: '10px',
              maxHeight: '45vh',
              position: 'relative',
              overflowY: 'auto',
            }}
            >
              <OperationalTable
                pages={datesFormat?.length}
                title={`Field List: ${datesFormat?.length}`}
                headCells={['Field', 'Value']}
                rows={datesFormat}
                tableOpt={tableOptionsprop}
                BatchModeScreen="/batch-mode"
                routeDetails=""
                screenID={undefined}
                backScreen=""
                localSearchEnabled
                isBatch="Batch"
              />
            </div>
            {/* <OperationalTable /> */}
          </div>
        )}
      </>
      <Snackbar
        open={Errors !== '' || ConfirmationMsg !== ''}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={resType === 'error' ? 'error' : 'success'}
        >
          {ConfirmationMsg}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AddAthorizedProducts;
