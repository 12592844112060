/* eslint-disable object-curly-newline */
/* eslint-disable max-len */
/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';

// Librairies
import {
  FormControl,
  Grid,
  InputBase,
  InputLabel,
  Paper,
  Select,
  Typography,
  MenuItem,
  Divider,
  Stack,
} from '@mui/material';

// Styles
import { ArrowDownward } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { useStyles } from './style';
import HoverTooltip from '../HoverTooltip';
import ConfirmationModalCommand from '../ConfirmationModal';
import { sendCommandRackStatus } from '../../../redux/actions/workflows/rackStatus';
import SmartLinkPop from '../../SmartLinkPop';
import PresetField from '../PresetField';

interface IProps {
    presetDescription: string;
    presetOpt: any[];
    presetStatusStyle: string;
    presetStatusLang: any;
    meterlist: any;
    presetID: string;
    preset: string;
    delivered: string;
    temperature: string;
    flowRate: string;
    pctComplete: string;
    etcStr: string;
    confirmationBeforeProCommand: any;
}

const PresetCard: React.FC<IProps> = ({
  presetDescription,
  presetOpt,
  presetStatusStyle,
  presetStatusLang,
  meterlist,
  presetID,
  preset,
  delivered,
  temperature,
  flowRate,
  pctComplete,
  etcStr,
  confirmationBeforeProCommand,
}) => {
  const classes = useStyles();
  const history = useNavigate();
  const dispatch = useDispatch();
  const [optionCommand, setOptionCommand] = useState<string>('');
  const [Visible, setVisible] = useState<boolean>(false);
  function cssToObj(css: string) {
    const obj: any = {};
    const s = css.toLowerCase().replace(/-(.)/g, (_m, g) => g.toUpperCase()).replace(/;\s?$/g, '').split(/:|;/g);
    for (let i = 0; i < s.length; i += 2) obj[s[i].replace(/\s/g, '')] = s[i + 1].replace(/^\s+|\s+$/g, '');
    return obj;
  }
  const procBayCommandModal = (e: any) => {
    // console.log(e.target.value, bayID);
    setOptionCommand(e.target.value);
    setVisible(true);
  };
  return (
    <>
      <Grid item lg={4} xs={12} sm={6} md={6}>
        <Paper elevation={2}>
          <Grid container p={1}>
            <Grid item xs={8}>
              <Typography variant="subtitle1" className={classes.description}>
                {`${presetID} - ${presetDescription}`}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <FormControl sx={{ width: '100%' }}>
                <InputLabel id="demo-simple-select-label">Options</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Options"
                  size="small"
                  value=""
                  onChange={procBayCommandModal}
                >
                  {presetOpt.map((item: any) => {
                    if (item.type === 'preset') {
                      return (<MenuItem key={item.options} onClick={() => confirmationBeforeProCommand(item.presetID, item.options, item.presetDescription)} value={item}>{_.upperFirst(item.options) || ''}</MenuItem>);
                    } if (item.type === 'meter') {
                      return (<MenuItem key={item.options} onClick={() => confirmationBeforeProCommand(item.meterID, item.options, item.meterDescription)} value={item}>{`${_.upperFirst(item.options) || ''} ${item.meterDescription}`}</MenuItem>);
                    }
                    return (<Divider className={classes.divider} />);
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Divider className={classes.divider} />
            </Grid>
            <Grid item xs={12}>
              <div className={classes.statusHeadingDiv} style={{ ...cssToObj(presetStatusStyle), borderRadius: '5px' }}>
                <Typography variant="h2" className={classes.statusHeading}>{presetStatusLang}</Typography>
              </div>
            </Grid>
            <Grid item xs={6}>
              <PresetField
                fontSize="180%"
                label="Preset"
                value={preset}
              />
            </Grid>
            <Grid item xs={6}>
              <PresetField
                fontSize="180%"
                label="Delivered"
                value={delivered}
              />
            </Grid>
            <Grid item xs={3}>
              <PresetField
                fontSize="130%"
                label="Temperature"
                value={temperature}
              />
            </Grid>
            <Grid item xs={3}>
              <PresetField
                fontSize="130%"
                label="Flow Rate"
                value={flowRate}
              />
            </Grid>
            <Grid item xs={3}>
              <PresetField
                fontSize="130%"
                label="Percent Complete"
                value={pctComplete}
              />
            </Grid>
            <Grid item xs={3}>
              <PresetField
                fontSize="130%"
                label="ETC"
                value={etcStr}
              />
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body2" className={classes.fields}>
                Meters:
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <div style={{ overflow: 'auto', minHeight: '23px', maxHeight: '23px', maxWidth: '400px' }}>
                <Grid container spacing={1}>
                  {meterlist && meterlist.map((meter: any) => (
                    <Grid item xs={12 / (meterlist.length > 2 ? 3 : 2)}>
                      <div key={meter?.meterDescription} style={{ ...cssToObj(meter?.meterStyle), textAlign: 'center', maxWidth: '95%', border: '0.5px solid black', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '0.8rem' }}>
                        {meter?.meterDescription}
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>
  );
};

export default PresetCard;
