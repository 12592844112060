import { LOAD_SYSTEM_UTILITY_QUEUE, CLEAR_SYSTEM_UTILITY_QUEUE } from '../../constants/workflows';

const INITIAL_STATE = {
  data: [],
};

const SystemUtilityQueueReducer = (state = INITIAL_STATE, { type, payload }: any) => {
  switch (type) {
    case LOAD_SYSTEM_UTILITY_QUEUE: {
      return {
        ...state,
        data: payload,
      };
    }
    case CLEAR_SYSTEM_UTILITY_QUEUE: {
      return {
        ...state,
        data: [],
      };
    }
    default:
      return state;
  }
};

export default SystemUtilityQueueReducer;
