import React, { useEffect, useState } from 'react';

// Libraries
import {
  ButtonBase,
  DialogActions,
  Divider,
  TextareaAutosize,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';

// Components
import { DialogComponent } from '../Dialog/Dialog.component';

// Styles
import { useStyles } from './style';
import Select from '../Select';
import Input from '../Input';

// Actions
import { submitDeviceCommunications } from '../../redux/actions/workflows/deviceCommunications';

interface IProps {
  onClose: () => void;
  open: boolean;
  modalList: any;
  selectedRow: any;
}

const allFieldsHaveData = (fields: { [key: string]: any }) => {
  const fieldsToFilter = { ...fields };
  delete fieldsToFilter.response;
  return Object.keys(fieldsToFilter).every((x) => fields[x]);
};

const DeviceCommunicationsDialog: React.FC<IProps> = ({
  onClose,
  open,
  modalList,
  selectedRow,
}) => {
  const classes = useStyles();
  const { submitResponse } = useSelector(
    (state: any) => state?.Workflows?.DeviceCommunications,
  );
  const dispatch = useDispatch();
  const [disableWhenRequest, setDisableWhenRequest] = useState(false);
  const [dialogState, setDialogState] = useState({
    line: '',
    protocol: '',
    unit: '',
    command: '',
    response: '',
  });

  useEffect(() => {
    if (submitResponse?.tableData) {
      setDialogState((prevState) => ({
        ...prevState,
        response: submitResponse?.tableData.unitData,
      }));
    }
  }, [submitResponse?.tableData]);

  useEffect(() => {
    setDialogState({
      line: selectedRow?.PostedName || { label: 'RACK1', value: 'ss_137' },
      protocol: selectedRow?.protocol || { label: 'Smith', value: 'S' },
      unit: '',
      command: '',
      response: '',
    });
  }, [selectedRow]);

  const handleChange = (name: string, option: any) => {
    setDialogState({ ...dialogState, [name]: option });
  };

  const handleSubmit = () => {
    setDisableWhenRequest(true);
    dispatch(submitDeviceCommunications(dialogState));
    setTimeout(() => {
      setDisableWhenRequest(false);
    }, 1000);
  };

  return (
    <DialogComponent
      title="Device Communications"
      onClose={onClose}
      open={open}
    >
      <div className={clsx('flexRow', classes.wrapper)}>
        <Typography className={classes.label}>Line</Typography>
        <Select
          onChange={(e, option) => handleChange('line', option)}
          options={modalList?.[0]?.list}
          value={dialogState.line}
          width="75%"
          style={{ margin: '2px 10px' }}
        />
      </div>
      <div className={clsx('flexRow', classes.wrapper)}>
        <Typography className={classes.label}>Protocol</Typography>
        <Select
          onChange={(e, option) => handleChange('protocol', option)}
          options={modalList?.[1]?.list}
          value={dialogState.protocol}
          width="75%"
          style={{ margin: '2px 10px' }}
        />
      </div>
      <div className={clsx('flexRow', classes.wrapper)}>
        <Typography className={classes.label}>Unit</Typography>
        <Select
          onChange={(e, option) => handleChange('unit', option)}
          options={
            modalList?.[2]?.list?.length
              ? modalList?.[2]?.list
              : [{ value: '001', label: '001' }]
          }
          value={dialogState.unit}
          width="75%"
          style={{ margin: '2px 10px' }}
          placeholder="Nothing Selected"
        />
      </div>
      <div className={clsx('flexRow', classes.wrapper)}>
        <Typography className={classes.label}>Command</Typography>
        <Input
          name="command"
          onChange={(e) => handleChange(e.target.name, e.target.value)}
          style={{ width: '75%', margin: '2px 10px' }}
          value={dialogState.command}
          placeholder="Command"
          disabled={disableWhenRequest}
        />
      </div>
      <Divider variant="middle" />
      <div className={clsx('flexRow', classes.wrapper)}>
        <Typography className={classes.label}>Response</Typography>
        {/* <TextareaAutosize
          className={classes.textArea}
          name="response"
          onChange={(e) => handleChange(e.target.name, e.target.value)}
          value={disableWhenRequest ? '' : dialogState.response}
          disabled
        /> */}
      </div>
      <Divider variant="middle" />
      <DialogActions>
        <ButtonBase
          classes={{ root: classes.applyButton }}
          disabled={!allFieldsHaveData(dialogState)}
          onClick={handleSubmit}
        >
          Submit
        </ButtonBase>
        <ButtonBase classes={{ root: classes.closeButton }} onClick={onClose}>
          Close
        </ButtonBase>
      </DialogActions>
    </DialogComponent>
  );
};

export default DeviceCommunicationsDialog;
