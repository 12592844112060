import * as React from 'react';
import ReactDOM from 'react-dom';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getScreensList, LoadRecord, LoadTables } from '../../../../redux/actions/ScreenActions';
import ControlButton from '../../../controlButton';
import { currentReport, formInEdit } from '../../../../redux/actions/reports/reports';
import useWindowDimensions from '../../../../utils/windowDimensions';
import { currentUtility } from '../../../../redux/actions/systemUtilities/systemUtilities';

const style = {
  position: 'absolute' as const,
  left: '10%',
  top: '10%',
  width: '70%',
  height: 170,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
};

const ConfirmationModalLoseChanges = (props:any) => {
  const {
    Visible, setShowModal, option,
  } = props;
  const { activeSreen, ScreensList } = useSelector((state: any) => state.ScreenReducer);
  const {
    IsReportEdit,
  } = useSelector(
    (state: any) => state?.Reports?.Reports,
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  const handleClose = () => {
    setShowModal(false);
  };
  const handleDelete = () => {
    setShowModal(false);
    dispatch(formInEdit(false));
    if (option.type === 'Utility') {
      dispatch(currentUtility({
        reportId: option.reportId,
        heading: option.label,
        progName: option.progName,
      }));
      navigate('/workflow/system-utility');
      return;
    }
    if (option.type === 'WorkflowsReport') {
      dispatch(currentReport({
        reportId: option.reportid,
        heading: option.label,
        progName: option.prog_name,
      }));
      navigate('/workflow/reports');
      return;
    }
    if (option.type === 'Workflows') {
      navigate(`${option.path}`);
      return;
    }
    navigate('/home');

    const fields = Array.from(new Set([...option.fields.split(',')])).join(',');
    dispatch(
      LoadTables({
        tableID: option.tableId,
        page: '1',
        results_per_page: `${Math.ceil(height / 60)}`,
        fields: option.fields ? fields : '',
        fieldsConcatenated: option.fieldsConcatenated,
        order_by: '',
        where: option.screenConfiguration || '',
        redirect: `/maint/${option.screenId}`,
        screenId: option.screenId,
        keys: ScreensList.filter((item:any) => item.screenId === option.screenId)[0].keys || '',
        defaultcolumns: ScreensList.filter((item:any) => item.screenId === option.screenId)[0].defaultcolumns || '',
        navigate,
      }),
    );
    dispatch(LoadRecord({
      tableID: option.tableId,
      screenID: option.screenId,
      fields: option.fields || '',
      where: '',
    }));
  };

  return ReactDOM.createPortal(
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={Visible}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 460,
      }}
    >
      <Fade in={Visible}>
        <Box sx={style}>
          <div style={{ padding: 10, backgroundColor: '#d2d2d2' }}>
            <Typography>Leave Page</Typography>
          </div>
          <div style={{
            height: 'auto', display: 'flex', justifyContent: 'center', alignSelf: 'center', padding: '25px 0px',
          }}
          >
            <Typography>You have made changes on this page.If you leave without saving, your changes will be lost. Do you want to continue?</Typography>
          </div>
          <Divider />
          <div style={{
            height: 'auto', padding: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center',
          }}
          >
            <Typography color="red">This action cannot be undone.</Typography>
            <div style={{
              height: 'auto', display: 'flex', gap: 5, alignItems: 'center', alignSelf: 'center',
            }}
            >
              <ControlButton
                label="Cancel"
                color="#ffbb33"
                disabled={false}
                handleClick={handleClose}
              />
              <ControlButton
                label="Yes"
                color="#00c851"
                disabled={false}
                handleClick={handleDelete}
              />
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>,
    document.getElementById('draggable') as HTMLElement,
  );
};
export default ConfirmationModalLoseChanges;
