import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  searchInput: {
    fontWeight: '400 !important',
    fontSize: '12px !important',
    lineHeight: '1.42857143 !important',
    padding: '4px 4px',
    color: '#333333',
    backgroundColor: '#fff',
    border: '1px solid #e5e5e5',
    height: 33,
    width: 200,
  },
  subHeaderContainer: {
    padding: '0px 10px',
    marginBottom: 5,
  },
  label: {
    marginRight: 5,
    fontSize: 12,
    fontWeight: 400,
    display: 'flex',
    alignItems: 'center',
  },
  submitButton: {
    color: 'black !important',
    textShadow: 'none !important',
    backgroundColor: '#ddd !important',
    border: '1px solid transparent !important',
    padding: '6px 10px !important',
    borderWidth: '1px 1px 1px 5px !important',
    borderColor: 'black !important',
    '&:hover': {
      backgroundColor: '#555555 !important',
      color: 'white !important',
    },
    '&:disabled': {
      color: '#555 !important',
      backgroundColor: '#bcbcbc !important',
      border: '1px solid transparent',
      borderColor: '#bcbcbc !important',
      cursor: 'not-allowed',
    },
  },
  modifyButton: {
    color: 'black !important',
    textShadow: 'none !important',
    backgroundColor: '#ddd !important',
    border: '1px solid transparent !important',
    padding: '6px 10px !important',
    borderWidth: '1px 1px 1px 5px !important',
    borderColor: '#35aa47 !important',
    '&:hover': {
      backgroundColor: '#555555 !important',
      color: 'white !important',
    },
    '&:disabled': {
      color: '#555 !important',
      backgroundColor: '#bcbcbc !important',
      border: '1px solid transparent',
      borderColor: '#bcbcbc !important',
      cursor: 'not-allowed',
    },
  },
  deleteButton: {
    color: 'black !important',
    textShadow: 'none !important',
    backgroundColor: '#ddd !important',
    border: '1px solid transparent !important',
    padding: '6px 10px !important',
    borderWidth: '1px 1px 1px 5px !important',
    borderColor: '#d84a38 !important',
    '&:hover': {
      backgroundColor: '#555555 !important',
      color: 'white !important',
    },
    '&:disabled': {
      color: '#555 !important',
      backgroundColor: '#bcbcbc !important',
      border: '1px solid transparent',
      borderColor: '#bcbcbc !important',
      cursor: 'not-allowed',
    },
  },
  buttonsContainer: {
    gap: 5,
  },
});
