/* eslint-disable no-nested-ternary */
import { Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LoadRecord, LoadTables, UpdateRecord } from '../../redux/actions/ScreenActions';
import ControlButton from '../controlButton';
import TmsHeader from '../header';
import OperationalTable from '../operationalTable/operationalTable';
import Subnavbar from '../Sub-navbar/Subnavbar';
import EnhancedTable from '../TableView/TableView';
import './styles.css';

const BatchModeUpdate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    loading,
    data,
    Selectedcolumnss,
    activeScreen,
    FilterSaved,
    recordsColumns,
    batchRecordsDefaultColumns,
    batchRecordsDefaultLabels,
    BatchModeQuery,
    activeScreenLabels,
  } = useSelector((state: any) => state.ScreenReducer);
  const {
    Language,
  } = useSelector((state: any) => state.LoginReducer);
  useEffect(() => {
    if (!activeScreen) {
      navigate('/home');
    } else if (activeScreen) {
      dispatch(LoadRecord({
        tableID: activeScreen.tableID,
        screenID: activeScreen.screenId,
        fields: Object.keys(recordsColumns?.selectedBatch[0]).join(','),
        where: '',
        lang: Language,

      }));
    }
  }, []);

  const tableOptionsprop = {
    tableID: recordsColumns.tableOpt,
    page: '1',
    results_per_page: '10',
    fields: Selectedcolumnss,
    order_by: 'name desc',
    where: '',
  };
  const keyFields = { ...recordsColumns.selectedBatch };
  const handleCancel = () => {
    navigate(`${activeScreen.redirect}`);
  };
  const handleSave = () => {
    dispatch(
      UpdateRecord({
        table: recordsColumns.tableOpt,
        navigate,
        backScreen: activeScreen.redirect,
        compulsory_data: recordsColumns.selectedBatch,
        form_data: { ...BatchModeQuery.query },
      }),
    );
  };
  return loading || data.length === 0 ? (
    <Typography>loading...</Typography>
  ) : data?.data?.length !== 0 ? (
    <>
      <TmsHeader />
      <div className="container">
        <div className="sub-nav-container">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <h3 id="pagetitlebar_title" className="page-title">
              {recordsColumns.tableOpt === 'Driver' ? 'Drivers' : recordsColumns.tableOpt}
            </h3>
          </div>
        </div>
        <div className="controlersBtchMode">
          <ControlButton
            label="Update all"
            color="#00c851"
            disabled={false}
            handleClick={handleSave}
          />
          <ControlButton
            label="Cancel"
            color="#ffbb33"
            disabled={false}
            handleClick={handleCancel}
          />
        </div>
        <div className="selectedRecords">
          <Typography variant="h6">
            Records List:
            {' '}
            {recordsColumns.selectedBatch.length}
          </Typography>
          <OperationalTable
            pages="1"
            headCells={batchRecordsDefaultLabels}
            rows={batchRecordsDefaultColumns}
            tableOpt={tableOptionsprop}
            BatchModeScreen="/batch-mode"
            routeDetails=""
            screenID={undefined}
            backScreen=""
            localSearchEnabled
            isBatch="Batch"
          />
        </div>
        <div className="selectedRecords">
          <Typography variant="h6">
            Field List:
            {' '}
            {BatchModeQuery?.armixed?.length}
          </Typography>
          <OperationalTable
            pages="1"
            headCells={['Keys', 'Values']}
            rows={BatchModeQuery.values}
            tableOpt={tableOptionsprop}
            BatchModeScreen="/batch-mode"
            routeDetails=""
            screenID={undefined}
            backScreen=""
            localSearchEnabled
            isBatch="Batch"
          />
        </div>
      </div>
    </>

  ) : (
    <>
      <TmsHeader />
      {FilterSaved?.length > 0 && (
      <Subnavbar
        label=" "
        // menuOptionType="exportItems"
        menuOptions={FilterSaved}
      />
      )}
      {/* <Typography>hello</Typography> */}
      <EnhancedTable
        pages="1"
        headCells={[]}
        rows={[
          {
            term_id: '',
            city: '',
            zip: '',
            addr1: '',
            state: '',
            country: '',
            splc_code: '',
          },
        ]}
        tableOpt={tableOptionsprop}
        routeDetails="/termProfileform"
        screenID="MAINT-146"
        backScreen="/terminalprofile"
      />
    </>
  );
};

export default BatchModeUpdate;
