/* eslint-disable max-len */
/* eslint-disable no-tabs */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
// eslint-disable jsx-a11y/anchor-is-valid
import React from 'react';

// Librairies
import { Grid, InputBase, Typography } from '@mui/material';

// Styles
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import LinkIcon from '@mui/icons-material/Link';
import cssObject from './style';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

interface IProps {
    toolTipTitle: string;
    toolTipMessage: string;
    label: string;
    title: string;
}

const SmartLinkPop: React.FC<IProps> = ({
  toolTipTitle,
  toolTipMessage,
  label,
  title,
}) => {
  const [tooltipIsOpenCarrier, setTooltipIsOpenCarrier] = React.useState(false);
  return (
    <>
      <style>{cssObject}</style>
      <div className="baygroup-bottom">
        <HtmlTooltip
          open={tooltipIsOpenCarrier}
          onClose={() => setTooltipIsOpenCarrier(false)}
          title={(
            <>
              {toolTipTitle && (
              <b>
                {toolTipTitle}
                {' '}
                <LinkIcon className="link" />
              </b>
              )}
              <hr />
              {toolTipMessage && <Typography color="inherit">{toolTipMessage}</Typography>}
            </>
                )}
          arrow
        >
          <>
            <a
              onClick={() => setTooltipIsOpenCarrier(true)}
              title={title}
              href="#"
              className="smartlink default"
            >
              <span title={title}>
                {label || ''}
              </span>
            </a>
          </>
        </HtmlTooltip>
      </div>
    </>
  );
};

export default SmartLinkPop;
