import {
  LOAD_TRANSACTION_VIEWER,
  CLEAR_TRANSACTION_VIEWER,
  LOAD_ALL_TRANSACTION_VIEWER_DATA,
  SET_TRANSACTION_VIEWER_LOADING,
  LOAD_TRANSACTION_VIEWER_COLUMNS,
  SET_TRANSACTION_VIEWER_EXPORT_FILE,
  SET_TRANSACTION_VIEWER_EXPORT_LINK,
  CLEAR_TRANSACTION_VIEWER_EXPORT_FILE,
  CLEAR_TRANSACTION_VIEWER_EXPORT_LINK,
  SET_TRANSACTION_VIEWER_RECORD_DETAIL,
  CLEAR_TRANSACTION_VIEWER_RECORD_DETAIL,
  SET_TRANSACTION_VIEWER_FILTERS,
  CLEAR_TRANSACTION_VIEWER_FILTERS,
} from '../../constants/workflows';

const INITIAL_STATE = {
  data: [],
  headerList: {},
  recordDetail: {},
  searchHeader: [],
  transStat: [],
  columnsList: [],
  encodedTableData: '',
  exportFile: '',
  exportLink: '',
  loading: false,
  hiddenColumns: {},
  visibleColumns: {},
  filters: {},
};

const transactionViewerReducer = (state = INITIAL_STATE, { type, payload }: any) => {
  switch (type) {
    case LOAD_TRANSACTION_VIEWER: {
      return {
        ...state,
        data: payload.data,
        headerList: payload.headerList,
        searchHeader: payload.searchHeader,
        transStat: payload.transStat,
        encodedTableData: payload.encodedTableData,
      };
    }
    case LOAD_ALL_TRANSACTION_VIEWER_DATA: {
      return {
        ...state,
        data: payload.data,
        transStat: payload.transStat,
        loading: false,
      };
    }
    case SET_TRANSACTION_VIEWER_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case LOAD_TRANSACTION_VIEWER_COLUMNS: {
      return {
        ...state,
        visibleColumns: payload.visibleColumns,
        hiddenColumns: payload.hiddenColumns,
        columnsList: payload.columnsList,
      };
    }
    case SET_TRANSACTION_VIEWER_RECORD_DETAIL: {
      return {
        ...state,
        recordDetail: payload,
      };
    }
    case CLEAR_TRANSACTION_VIEWER_RECORD_DETAIL: {
      return {
        ...state,
        recordDetail: {},
      };
    }
    case SET_TRANSACTION_VIEWER_FILTERS: {
      return {
        ...state,
        filters: payload,
      };
    }
    case CLEAR_TRANSACTION_VIEWER_FILTERS: {
      return {
        ...state,
        filters: {},
      };
    }
    case SET_TRANSACTION_VIEWER_EXPORT_FILE: {
      return {
        ...state,
        exportFile: payload,
      };
    }
    case CLEAR_TRANSACTION_VIEWER_EXPORT_FILE: {
      return {
        ...state,
        exportFile: '',
      };
    }
    case SET_TRANSACTION_VIEWER_EXPORT_LINK: {
      return {
        ...state,
        exportLink: payload,
      };
    }
    case CLEAR_TRANSACTION_VIEWER_EXPORT_LINK: {
      return {
        ...state,
        exportLink: '',
      };
    }
    case CLEAR_TRANSACTION_VIEWER: {
      return {
        ...state,
        data: [],
        headerList: {},
        searchHeader: [],
        transStat: [],
        encodedTableData: '',
        visibleColumns: {},
        hiddenColumns: {},
        columnsList: [],
      };
    }
    default:
      return state;
  }
};

export default transactionViewerReducer;
