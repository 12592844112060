/* eslint-disable no-debugger */
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getScreensList } from '../redux/actions/ScreenActions';
import { getRecordToShowValue, searchRecords, sortedTable } from '../utils';

export interface ITableState {
  tableData: any[];
  currentPage: number;
  searchedDataCount: number;
  searchText: string;
  recordsNumber: string | number;
  orderBy: string;
  order: string;
  anchorEl: null | HTMLElement;
}

export const useWorkflowState = (
  searchHeader: any,
  fetchedData: any,
  getWorkflowData: any,
  allRecords?: boolean,
  noScreenList?: boolean,
  defaultRecordsToShow?: number,
  isChangeHistory?: boolean,
  searchPreText?: string,
) => {
  const [tableState, setTableState] = useState<ITableState>(() => ({
    tableData: [],
    currentPage: 1,
    searchedDataCount: 0,
    recordsNumber: allRecords ? 'All' : 'Auto',
    searchText: searchPreText || '',
    orderBy: '',
    order: 'asc',
    anchorEl: null,
  }));
  const dispatch = useDispatch();

  const recordsToShow = getRecordToShowValue(
    tableState.recordsNumber,
    fetchedData.length,
    defaultRecordsToShow || 18,
  );

  const handleSelectChange = (e: any, option: any) => {
    setTableState({ ...tableState, recordsNumber: option, currentPage: 1 });
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTableState({
      ...tableState,
      searchText: e.target.value,
      currentPage: 1,
    });
  };

  const handleOptionClick = (event: React.MouseEvent<HTMLElement>) => {
    setTableState({ ...tableState, anchorEl: event.currentTarget });
  };

  const handleCloseMenu = () => {
    setTableState({ ...tableState, anchorEl: null });
  };

  const handlePaginationChange = (event: any, targetPage: any) => {
    if (!tableState.searchText) {
      const selectedData = fetchedData.slice(
        (targetPage - 1) * recordsToShow,
        targetPage * recordsToShow,
      );
      setTableState({
        ...tableState,
        currentPage: targetPage,
        tableData: selectedData,
      });
    } else {
      const selectedData = searchRecords(
        tableState.searchText,
        fetchedData,
        searchHeader,
        isChangeHistory,
      ).slice((targetPage - 1) * recordsToShow, targetPage * recordsToShow);
      setTableState({
        ...tableState,
        currentPage: targetPage,
        tableData: selectedData,
      });
    }
  };

  const handleSortTable = (key: string) => {
    const sortedData = sortedTable(fetchedData, key, tableState.order) ?? fetchedData;
    setTableState({
      ...tableState,
      currentPage: 1,
      searchedDataCount: 0,
      tableData: sortedData.slice(0, recordsToShow),
      orderBy: key,
      order: tableState.order === 'asc' ? 'desc' : 'asc',
    });
  };

  useEffect(() => {
    if (getWorkflowData) {
      dispatch(getWorkflowData());
    }
    if (!noScreenList) {
      dispatch(getScreensList());
    }
  }, []);

  useEffect(() => {
    if (tableState.searchText) {
      const filtredRecords = searchRecords(
        tableState.searchText,
        fetchedData,
        searchHeader,
        isChangeHistory,
      );
      setTableState({
        ...tableState,
        searchedDataCount: filtredRecords.length,
        tableData: filtredRecords.slice(0, recordsToShow),
        currentPage: 1,
      });
    } else {
      setTableState({
        ...tableState,
        searchedDataCount: 0,
        tableData: fetchedData.slice(0, recordsToShow),
      });
    }
  }, [fetchedData?.length, recordsToShow, tableState.searchText]);

  return {
    tableState,
    recordsToShow,
    handleSelectChange,
    handleSearchChange,
    handlePaginationChange,
    handleSortTable,
    handleOptionClick,
    handleCloseMenu,
  };
};
