/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';

import { useStyles } from './style';

interface IProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick: (param: any) => void;
  checked: boolean;
  label: string | null;
  style?: React.CSSProperties;
  disabled?: boolean;
}

const CheckboxComponent: React.FC<IProps> = ({
  onChange,
  checked,
  label,
  onClick,
  style,
  disabled,
}) => {
  const classes = useStyles();
  return (
    <label
      className={classes.container}
      style={style}
      htmlFor="checkbox"
      onClick={(e: any) => !disabled && onClick(e)}
    >
      {label}
      <input
        type="checkbox"
        onChange={onChange}
        checked={checked}
        disabled={disabled}
      />
      <span className={classes.checkmark} />
    </label>
  );
};

export default CheckboxComponent;
