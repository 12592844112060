/* eslint-disable max-len */
/* eslint-disable react/no-danger */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/require-default-props */
import React from 'react';

// Librairies
import { Grid, InputBase, Typography } from '@mui/material';

// Styles
import { useStyles } from './style';

interface IProps {
    fontSize: string;
    label: string;
    value: string;
}
const PresetField: React.FC<IProps> = ({ fontSize, label, value }) => {
  const classes = useStyles();
  return (
    <>
        <div className={classes.presetsubtitle}>
          {label}
        </div>
        <div className={classes.presetsubdata}>
          <div className={classes.presetrow1} style={{ fontSize }}>
            <span className={classes.span} dangerouslySetInnerHTML={{ __html: value || '&nbsp;' }} />
          </div>
        </div>
    </>
  );
};

export default PresetField;
