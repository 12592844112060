/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';

// Librairies
import { InputBase, Box } from '@mui/material';
import clsx from 'clsx';
import Moment from 'moment';
import Api from '../../../redux/api/index';

// Components
import SubHeader from '../../../components/subHeader';
import TmsHeader from '../../../components/header';
import Options from '../../../components/Options';
import CustomDropdown from '../../../components/customDropdown';
import CommonTable from '../../../components/CommonTable';
import Select from '../../../components/Select';

// Styles
import { useStyles } from './style';

const ITEMS: Array<string> = [
  'Read Id',
  'Ground Status',
  'Status Word',
  '',
  'Authorize',
  'Unauthorize',
  '',
  'Reboot',
  '',
  'Clear',
];

const VipScullyCommunicationsScreen: React.FC = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [Rows, setRows] = useState<any>([]);
  const handleOptionClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClearVip = () => {
    setRows([]);
  };
  const handleAddCommand = async (
    event: React.MouseEvent<HTMLElement>,
    command: any,
  ) => {
    setAnchorEl(null);
    let cmd = '';
    if (command === 'Read Id') {
      cmd = 'READID';
    } else if (command === 'Ground Status') {
      cmd = 'GROUNDSTATUS';
    } else if (command === 'Status Word') {
      cmd = 'STATUSWORD';
    } else if (command === 'Authorize') {
      cmd = 'AUTHORIZE';
    } else if (command === 'Unauthorize') {
      cmd = 'UNAUTHORIZE';
    } else if (command === 'Reboot') {
      cmd = 'REBOOT';
    }
    const response = await Api.post(
      'synajax/procajax',
      {
        data: {
          cmd: 'syscmd',
          parm: {
            cmd: 'vipscully',
            tab: {
              tab: 'VIPlog',
              userlist: 'loglist',
              vipName: '',
              vipAddr: '',
              command: cmd,
              logList: '[]',
            },
          },
        },
        tagid: null,
        jqxhr: null,
      },
    );
    const dataresult = response.data[0]?.tableData[0]?.data[0] || '';
    setRows([
      ...Rows,
      dataresult && {
        output: dataresult[0],
        vipname: dataresult[1],
        vipid: dataresult[2],
        command: dataresult[3],
        status: dataresult[4],
        description: dataresult[5],
      },
    ]);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <TmsHeader />
      <SubHeader title="VIP Scully Communications">
        <Options
          handleClick={handleOptionClick}
          handleAddCommand={handleAddCommand}
          handleClearVip={handleClearVip}
          anchorEl={anchorEl}
          onClose={handleCloseMenu}
          openDialog={() => null}
          items={ITEMS}
          onItemClick={(_menuId: number) => handleCloseMenu()}
        />
      </SubHeader>
      <div className={clsx(classes.container)}>
        <CustomDropdown
          label="Bay - Trailer Id"
          name="Bay - Trailer Id"
          value="01-001"
          onChange={() => null}
          size="100%"
          options={[{ label: '01-001', value: '01-001' }]}
          isDisabled={false}
        />
      </div>
      <Box className={classes.boxLine} />
      <div className={clsx('flexStartSpaceBetweenRow', classes.container)}>
        <div className={clsx('flexRow')}>
          <span className={classes.label}>Records :</span>
          <Select
            options={['Auto', 10, 20, 25, 35, 50, 'All']}
            width={200}
            value="Auto"
          />
        </div>
        <div className="flexRow">
          <span className={classes.label}>Search :</span>
          <InputBase className={classes.searchInput} />
        </div>
      </div>
      <CommonTable rows={Rows} />
    </>
  );
};

export default VipScullyCommunicationsScreen;
