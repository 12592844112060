import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    padding: '0px 10px',
    overflow: 'auto',
  },
  tableRoot: {
  },
  headerCell: {
    borderBottom: 'none !important',
    padding: '5px 9px !important',
    fontSize: '14px !important',
    fontWeight: 'bold !important',
  },
  headerRow: {
    boxShadow: '0 4px 6px -3px #555',
  },
  bodyCell: {
    borderBottom: 'none !important',
    padding: '5px 9px !important',
    fontSize: '12px !important',
    fontWeight: 'normal !important',
  },
  bodyCellEmpty: {
    borderBottom: 'none !important',
    padding: '8px 7px !important',
  },
  tableRow: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#fbf8e9 !important',
    },
  },
  actionButton: {
    border: 'solid 1px #ddd !important',
    padding: '0px 1px !important',
    '&:hover': {
      backgroundColor: '#e6e6e6 !important',
      borderColor: '#adadad !important',
    },
  },
  actionsWrapper: {
    display: 'flex',
    gap: 10,
  },
  input: {
    fontWeight: '400 !important',
    fontSize: '12px !important',
    lineHeight: '1.42857143 !important',
    padding: '4px 4px',
    color: '#333333',
    backgroundColor: '#fff',
    border: '1px solid #e5e5e5',
    height: 33,
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
});
