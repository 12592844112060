import { Dispatch } from 'redux';
import Api from '../../api/index';
import { START_LOADING, STOP_LOADING } from '../../constants/Screens';
import {
  LOAD_EMAIL_GROUP,
  LOAD_SYSTEM_PRINTERS,
  LOAD_VIEW_REPORT,
  LOAD_VIEW_REPORT_COMMENT,
  VIEW_REPORT_SAVE_REPORT_DESCRIPTION,
  VIEW_UTILITY_SAVE_SEQ_NO,
} from '../../constants/workflows';

// eslint-disable-next-line max-len, camelcase
export const viewUtility = (seqno: string, reportName: string) => async (dispatch: Dispatch) => {
  dispatch({ type: START_LOADING });
  const response = await Api.get(
    `report/show?mode=showreport&seqno=${seqno}&reportType=T`,
  );
  dispatch({
    type: VIEW_UTILITY_SAVE_SEQ_NO,
    payload: seqno,
  });
  dispatch({
    type: VIEW_REPORT_SAVE_REPORT_DESCRIPTION,
    payload: reportName,
  });
  dispatch({
    type: LOAD_VIEW_REPORT,
    payload: response.data,
  });
  dispatch({ type: STOP_LOADING });
};

export const getPrinters = () => async (dispatch: Dispatch) => {
  const response = await Api.get('/report/screenConfig?reportid=REPORT-014');
  dispatch({
    type: LOAD_SYSTEM_PRINTERS,
    payload: response.data[0].options,
  });
};

export const getEmailGroup = () => async (dispatch: Dispatch) => {
  const response = await Api.get('report/emailGroups');
  console.log(response.data.email_groups);
  dispatch({
    type: LOAD_EMAIL_GROUP,
    payload: response.data?.email_groups,
  });
};

// eslint-disable-next-line max-len
export const submitPrintReport = (seqno: string, routeCode: string) => async (dispatch: Dispatch) => {
  const response = await Api.post('report/print', { seqno, routeCode });
};

export const getComment = (seqno: string) => async (dispatch: Dispatch) => {
  const response = await Api.get(`/report/comment?seqno=${seqno}`);
  dispatch({
    type: LOAD_VIEW_REPORT_COMMENT,
    payload: response.data.comment,
  });
};

export const commentSubmit = (seqno: string, comment: string) => async (dispatch: Dispatch) => {
  // eslint-disable-next-line max-len
  const response = await Api.post('/report/comment', { seqno, wysi_comment: comment, striphtml: '' });
};

export const emailSubmit = (
  seqno: string,
  groupName: string,
  emailGroupName: string,
  emailList: string,
) => async (dispatch: Dispatch) => {
  // eslint-disable-next-line max-len
  const response = await Api.post('/report/email', {
    seqno,
    group_name: groupName,
    email_group_name: emailGroupName,
    email_list: emailList,
    reportDesc: 'R Desc',
  });
};
