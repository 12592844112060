/* eslint-disable max-len */
/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import {
  Button, Checkbox, FormControlLabel, FormGroup, InputAdornment, MenuItem, Pagination, Select, Switch, TextField,
} from '@mui/material';
import {
  Close, ClosedCaption, Edit, Search,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import {
  DeleteRecord, LoadAllColumnsTables, LoadTables, SetBatchMode, SetRecordsList, StartLoading, StopLoading,
} from '../../../redux/actions/ScreenActions';
import ColumnsModal from '../../CustomModal/ColumnsModal';
import ConfirmationModal from '../../confirmationModal';
import useDebounce from '../../../utils/searchDebounce';
import TransitionsModal from '../../CustomModal';
import { isObject } from '../../../utils';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number,
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface EnhancedTableProps {
  headCells: any;
}
interface EnhancedTabledataProps {
  headCells: any;
  rows: any;

}

const EnhancedTableHead = (props: EnhancedTableProps) => {
  const {
    headCells,
  } = props;

  return (
    <TableHead sx={{ boxShadow: 3 }}>
      <TableRow>
        {headCells?.map((headCell:any) => (
          <TableCell
            key={headCell}
            align="center"
            padding="none"
            sortDirection={false}
            style={{
              background: '#F5F5F5', border: '2px solid #fff', fontWeight: 'bold', padding: '5px 20px',
            }}
          >
            {headCell}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const RackStatusTable = (props: EnhancedTabledataProps) => {
  const {
    rows, headCells,
  } = props;
  return (
    <Box sx={{ padding: '0px 8px' }}>
      {/* {loading && 'loading'} */}
      <Box sx={{ mb: 2 }}>

        <TableContainer sx={{ boxShadow: 5 }}>
          <Table
            sx={{ boxShadow: 3, width: '100%' }}
            aria-labelledby="tableTitle"
            size="small"
          >
            <EnhancedTableHead
              headCells={headCells}
            />

            <TableBody sx={{ boxShadow: 3 }}>
              {rows
                .map((row: any, index: number) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={uuidv4() as any}
                      sx={{
                        backgroundColor: index % 2 ? '#F5F5F5' : 'white',
                        cursor: 'pointer',
                        '&:hover': {
                          background: '#F7EBD0',
                        },
                      }}
                    >
                      {
                             Object.values(row)?.map((v:any) => (
                               <TableCell
                                 sx={{
                                   padding: '10px 20px',
                                   border: '2px solid #fff',
                                   fontSize: 12,
                                   width: 'auto',
                                 }}
                                 align="center"
                                 dangerouslySetInnerHTML={{ __html: v }}
                               />
                             ))
     }
                    </TableRow>
                  );
                })}
            </TableBody>

          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};
export default RackStatusTable;
