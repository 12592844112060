import React, { useEffect, useState } from 'react';

// Librairies
import { InputBase } from '@mui/material';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';

// Components
import SubHeader from '../../../components/subHeader';
import TmsHeader from '../../../components/header';
import OptionsButton from '../../../components/optionsButton';
import EMCSManagerOptions from '../../../components/EMCSManagerOptions';
import Loader from '../../../components/Loader';
import ConfirmationDialog from '../../../components/ConfirmDialog';

// Styles
import { useStyles } from './style';

// Actions
import {
  clearEMCSManagerColumnsExportLink,
  clearEMCSManagerColumnsFileName,
  exportEMCSManagerColumnsFileName,
  getEMCSManagerColumnsDownloadLink,
  getEMCSManagerData,
} from '../../../redux/actions/workflows/emcsManager';

// Hooks
import { useWorkflowState } from '../../../hooks/useWorkflowState';
import EMCSManagerTable from '../../../components/EMCSManagerTable';

const tableHeader = [
  { label: '', key: '' },
  { label: 'Driver', key: 'DriverName' },
  { label: 'Vehicle Tag', key: 'VehicleTag' },
  { label: 'ARC Number', key: 'ARCNumber' },
  { label: 'LR Number', key: 'LRNumber' },
  { label: 'Status', key: 'ARCStatus' },
  { label: 'Request Date', key: 'RequestDate' },
  { label: 'Received Date', key: 'ReceivedDate' },
  { label: 'Request Type', key: 'ARCType' },
];

const EMCSManagerScreen: React.FC = () => {
  const classes = useStyles();
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState<boolean>(false);
  const {
    data, mode, exportFile, exportLink,
  } = useSelector(
    (state: any) => state.Workflows.EMCSManager,
  );
  const dispatch = useDispatch();
  const {
    tableState,
    handleSearchChange,
    handleSortTable,
    handleOptionClick,
    handleCloseMenu,
  } = useWorkflowState(tableHeader, data, getEMCSManagerData, true);

  useEffect(() => {
    if (exportFile) {
      dispatch(getEMCSManagerColumnsDownloadLink(exportFile));
    }
    return () => {
      dispatch(clearEMCSManagerColumnsFileName());
    };
  }, [exportFile]);

  useEffect(() => {
    if (exportLink) {
      const link = document.createElement('a');
      link.href = exportLink;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    return () => {
      dispatch(clearEMCSManagerColumnsExportLink());
    };
  }, [exportLink]);

  const handleOpenConfirmationDialog = () => {
    setConfirmationDialogOpen(true);
    handleCloseMenu();
  };
  const handleCloseConfirmationDialog = () => {
    setConfirmationDialogOpen(false);
  };

  const exportColumns = () => {
    dispatch(exportEMCSManagerColumnsFileName());
    handleCloseMenu();
  };

  return (
    <>
      <TmsHeader />
      <SubHeader title="EMCS Manager">
        <div className="flexRow" style={{ gap: 5 }}>
          <Loader getWorkflowData={getEMCSManagerData} />
          <OptionsButton handleClick={handleOptionClick} />
        </div>
        <EMCSManagerOptions
          anchorEl={tableState.anchorEl}
          onClose={handleCloseMenu}
          openNormalModeDialog={handleOpenConfirmationDialog}
          exportColumns={exportColumns}
        />
      </SubHeader>
      <div
        className={classes.status}
        style={{
          backgroundColor: mode === 'Fallback ()' ? '#FFEB99' : '#95E695',
        }}
      >
        {mode}
      </div>
      <div className={clsx('flexRow', classes.wrapper)}>
        <span className="takeTheRest" />
        <div>
          <InputBase
            className={classes.input}
            placeholder="Search"
            onChange={handleSearchChange}
          />
          <span className={classes.helperText}>
            Viewing
            {' '}
            {tableState.tableData?.length || 0}
            {' '}
            of
            {' '}
            {data?.length || 0}
          </span>
        </div>
      </div>
      <EMCSManagerTable
        handleClickRow={() => null}
        openDeviceEMCSManagerDialog={() => null}
        tableData={tableState.tableData}
        tableHeader={tableHeader}
        searchText={tableState.searchText}
        order={tableState.order}
        orderBy={tableState.orderBy}
        sortTable={handleSortTable}
      />
      <ConfirmationDialog
        open={confirmationDialogOpen}
        handleCloseAgree={handleCloseConfirmationDialog}
        message="Are you sure you want to go to 'Normal' Mode?"
        handleCloseCancel={handleCloseConfirmationDialog}
        title="Normal Mode Confirmation"
        cancelLabel="Cancel"
        confirmLabel="Yes"
      />
    </>
  );
};

export default EMCSManagerScreen;
