import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Alert, CircularProgress, Collapse, IconButton, Snackbar, Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation, useNavigate } from 'react-router-dom';
import Subnavbar from '../../components/Sub-navbar/Subnavbar';
import {
  CloneRecordSubmit, dissmissConfirmationAlert, LoadRecord, UpdateRecord,
} from '../../redux/actions/ScreenActions';
import TmsHeader from '../../components/header';
import CreateRecordHeader from '../../components/createMaintcomp/createRecordHeader';
import CreateRecordBody from '../../components/createMaintcomp/createRecordBody';
import ConfirmationModalLoseChanges from '../../components/confirmationModal/ConfirmationModalLoseChanges';

const CreateRecord = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const {
    recordDetails, parsedValues, activeScreen, openConfirmationAlert, confirmationAlert, loading,
  } = useSelector((state: any) => state.ScreenReducer);
  const [values, setValues] = useState<any>([]);
  const [open, setOpen] = useState(true);
  const [showOptionalFields, setShowOptionalFields] = useState(false);
  const [Visible, setVisible] = useState(false);
  const [isModifiy, setIsModifiy] = useState(false);
  const [myscreen, setScreen] = useState<any>();
  const [recentScreen, setRecentScreen] = useState<any>();
  const [valuesToSend, setValuesToSend] = useState<any>([]);
  const [VisibleConfirm, setVisibleConfirm] = useState(false);
  console.log('activeScreen', activeScreen);
  const {
    Language,
  } = useSelector((state: any) => state.LoginReducer);
  useEffect(() => {
    if (activeScreen) {
      dispatch(LoadRecord({
        tableID: activeScreen.tableID,
        screenID: activeScreen.screenId,
        where: '',
        fields: activeScreen.fields,
        lang: Language,
      }));
    } else if (!activeScreen) {
      navigate('/home');
    }
  }, [activeScreen]);
  useEffect(() => {
    setValues(parsedValues);
    setValuesToSend(parsedValues);
  }, [parsedValues]);
  const handleCloseConfirm = () => {
    setVisible(!Visible);
  };
  const handleLoseConfirm = () => {
    setVisibleConfirm(!VisibleConfirm);
  };
  const handleCloseModalYes = ():void => {
    setValues({});
    setValuesToSend({});
    // window.location.reload();
  };
  const handleShowOptionalFields = () => {
    setShowOptionalFields(!showOptionalFields);
  };
  const handleClose = () => {
    dispatch(dissmissConfirmationAlert());
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    setValuesToSend({
      ...valuesToSend,
      [name]: value,
    });
    setIsModifiy(true);
  };
  const handleSelectChange = (label:any, e:React.ChangeEvent<HTMLInputElement>, newValue:any) => {
    setValues({
      ...values,
      [label]: newValue,
    });
    setValuesToSend({
      ...valuesToSend,
      [label]: newValue.value,
    });
    setIsModifiy(true);
  };
  const handleSave = () => {
    const requiredFieldsKeys = Object.values(recordDetails).reduce((ac: any, item: any) => {
      const kk = item.reduce((acc: any, val: any) => {
        if (val.required === true) {
          return Object.assign(acc, { [val.id]: valuesToSend[val.id] });
        }
        return Object.assign(acc, {});
      }, {});
      return Object.assign(ac, kk);
    }, {});

    dispatch(
      CloneRecordSubmit({
        table: activeScreen.tableID,
        navigate,
        backScreen: activeScreen.redirect,
        form_data: valuesToSend,
        modifyDep: requiredFieldsKeys,
      }),
    );
  };
  // console.log('values saved', values);
  const compare = (currentValue: any) => {
    // console.log('---currentValue -- createMaintScreen====> ', currentValue);
    const result = currentValue?.obj['\u0000*\u0000parm']?.options?.filter((e: any) => (e?.value === values[currentValue?.id]));
    // console.log('---result -- createMaintScreen====> ', result);
    return result ? result[0]?.label : '';
  };

  const { _, ...restData } = recordDetails;

  useEffect(() => {
    window.parent.postMessage({ isModifiy });
  });

  return (
    Object.keys(recordDetails).length === 0 || loading ? (
      <div style={{
        width: '100%',
        height: '90vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      >
        <CircularProgress />
      </div>
    )
      : (
        <>
          <TmsHeader
            isModifiy={isModifiy}
            setVisibleConfirm={setVisibleConfirm}
            setScreen={setScreen}
            setRecentScreen={setRecentScreen}
          />
          <Subnavbar
            label
            menuOptionType="simpleItems"
            handleShowOptionalFields={handleShowOptionalFields}
            showOptionalFields={showOptionalFields}
          />
          <ConfirmationModalLoseChanges
            Visible={VisibleConfirm}
            handleCloseConfirm={handleLoseConfirm}
            handleCloseModalYes={handleCloseModalYes}
            myscreen={myscreen || ''}
            activeScreen={activeScreen.redirect}
            setScreen={setScreen}
            recentScreen={recentScreen}
          />
          {/* <Collapse in={open}>
        <Alert
          severity="warning"
          action={(
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          )}
          sx={{ mb: 2 }}
        >
          <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
            <Typography style={{ fontWeight: 'bold', color: 'orange' }}>Warning!</Typography>
            {' '}
            You must change the key fields before saving!
          </div>
        </Alert>
      </Collapse> */}
          <CreateRecordHeader
            Visible={Visible}
            options={activeScreen.redirect}
            handleCloseConfirm={handleCloseConfirm}
            dataHeader={_}
            // routeBack={routeBack}
            handleSelectChange={handleSelectChange}
            handleInputChange={handleInputChange}
            values={values}
            handleSave={handleSave}
            isModifiy={isModifiy}
            compare={compare}
            valuesToSend={valuesToSend}
            tableId={activeScreen.tableID}
            setVisibleConfirm={setVisibleConfirm}
          />
          <CreateRecordBody
            dataTabs={restData}
            handleSelectChange={handleSelectChange}
            handleInputChange={handleInputChange}
            values={values}
            compare={compare}
            valuesToSend={valuesToSend}
            showOptionalFields={showOptionalFields}
            handleShowOptionalFields={handleShowOptionalFields}
          />
          <Snackbar open={openConfirmationAlert} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error">
              {confirmationAlert}
            </Alert>
          </Snackbar>
        </>
    // )
      )
  );
};

export default CreateRecord;
