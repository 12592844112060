import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  resizableBox: {
    position: 'absolute',
    border: 'dashed 1px black',
    width: '200px',
    height: '200px',
    borderRadius: '3px',
    display: 'flex',
    justifyContents: 'center',
    alignItems: 'center',
    minWidth: '20px',
    minHeight: '20px',
    background: 'lightgrey',
  },
  resizableLeft: {
    position: 'absolute',
    background: 'transparent',
    cursor: 'col-resize',
    height: '100%',
    left: 0,
    top: 0,
    width: '5px',
  },
  resizableTop: {
    position: 'absolute',
    background: 'transparent',
    cursor: 'row-resize',
    width: '100%',
    left: 0,
    top: 0,
    height: '5px',
  },
  resizableBottom: {
    position: 'absolute',
    background: 'transparent',
    cursor: 'row-resize',
    width: '100%',
    bottom: 0,
    left: 0,
    height: '5px',
  },
  resizableRight: {
    position: 'absolute',
    background: 'transparent',
    cursor: 'col-resize',
    height: '100%',
    right: 0,
    top: 0,
    width: '5px',
  },

});
