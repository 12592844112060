/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Autocomplete,
  Button,
  Checkbox,
  TextField,
  Typography,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useStyles } from './style';
import ProductRecordComponent from './productsRecord';
import GaugeTransactionTankStep from '../gaugeTransaction/gaugeTransactionStep4';

const UniteOfMeasure = [
  {
    val: 'G',
    label: 'G: Gallons',
  },
  {
    val: 'B',
    label: 'B: Barrels',
  },
];
const BulkTransactionProductStep = ({
  productsData,
  values,
  handleInputChange,
  handleSelectChange,
  valuesToSend,
  stateValues,
  handleInputProductChange,
  screenID,
  visible,
  prefixProducts,
  resetPrefixProducts,
  prefix,
  products,
  addProducts,
  removeFormFields,
  review,
  transactionMode,
}: any) => {
  const classes = useStyles();
  const [showBaseUnitQty, setShowBaseUnitQty] = React.useState(false);
  const [subProducts, setSubProducts] = useState<any>([]);
  return (
    <div>
      <div className={classes.header}>
        <Button
          variant="contained"
          color="success"
          sx={{ textTransform: 'none' }}
          onClick={addProducts}
        >
          Add New Detail
        </Button>
        {/* <div className={classes.dropdownUnit}>
          <Typography>Unit Of Measure</Typography>
          <Autocomplete
            disablePortal
            size="small"
            id="combo-box-demo"
            options={UniteOfMeasure}
            sx={{
              '& .MuiInputBase-root': {
                padding: '2px 8px',
                '& > fieldset': {
                  color: 'gray',
                  borderLeft: '5px solid #ff3b3b',
                  borderRadius: '0px !important',
                  padding: '2px 8px',
                },
              },
              width: 300,
            }}
            renderInput={(params) => (
              <TextField
                sx={{
                  '& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
                    padding: '2px 8px',
                    border: '1px solid #E4E4E4',
                  },
                }}
                {...params}
              />
            )}
          />
          <Checkbox
            checked={showBaseUnitQty}
            onClick={() => setShowBaseUnitQty(!showBaseUnitQty)}
          />
          <Typography>Show / Hide Base Unit Qty</Typography>
        </div> */}
      </div>
      {products.map((product: any, key: any) => (
        <div key={product.id}>
          {screenID === 'WORKFLOW-039' ? (
            <GaugeTransactionTankStep
              values={values}
              product={product}
              productsData={productsData}
              products={products}
              handleInputChange={handleInputChange}
              handleSelectChange={handleSelectChange}
              valuesToSend={valuesToSend}
              stateValues={stateValues}
              handleInputProductChange={handleInputProductChange}
              screenID={screenID}
              prefixProducts={prefixProducts}
              resetPrefixProducts={resetPrefixProducts}
              prefix={prefix}
              visible={visible}
              removeFormFields={removeFormFields}
              itemKey={product?.id}
              review={review}
              transactionMode={transactionMode}
            />
          ) : (
            <ProductRecordComponent
              values={values}
              product={product}
              productsData={productsData}
              products={products}
              handleInputChange={handleInputChange}
              handleSelectChange={handleSelectChange}
              valuesToSend={valuesToSend}
              stateValues={stateValues}
              handleInputProductChange={handleInputProductChange}
              screenID={screenID}
              prefixProducts={prefixProducts}
              resetPrefixProducts={resetPrefixProducts}
              prefix={prefix}
              visible={visible}
              removeFormFields={removeFormFields}
              itemKey={product?.id}
              review={review}
              transactionMode={transactionMode}
            />
          )}
        </div>
      ))}
    </div>
  );
};
export default BulkTransactionProductStep;
