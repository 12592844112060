import { makeStyles } from '@mui/styles';

export const useDialogStyles = makeStyles({
  root: {},
  dialogTitle: {
    background: '#d2d2d2',
    padding: 15,
    cursor: 'move',
  },
  modalHeader: {
    backgroundColor: '#d2d2d2',
    padding: 15,
    borderBottom: '1px solid #e5e5e5',
    display: 'flex',
    justifyContent: 'space-between',
  },
  modalTitle: {
    margin: 0,
    fontSize: 18,
    fontWeight: 400,
  },
  small: {
    caretColor: 'rgb(119, 119, 119)',
    color: 'rgb(119, 119, 119)',
    columnRuleColor: 'rgb(119, 119, 119)',
    perspectiveOrigin: '0px 0px',
    pointerEvents: 'all',
    textDecoration: 'none solid rgb(119, 119, 119)',
    textEmphasisColor: 'rgb(119, 119, 119)',
    textSizeAdjust: '100%',
    transformOrigin: '0px 0px',
    border: '0px none rgb(119, 119, 119)',
    font: '13.5px / 13.5px "Open Sans"',
    outline: 'rgb(119, 119, 119) none 0px',
    transform: 'translateY(9px)',
  },
  TitleWithCap: {
    display: 'flex',
  },
});
