import { createStyles, makeStyles } from '@mui/styles';
import { createTheme } from '@mui/material';

const theme = createTheme();

export const useStyles = makeStyles({
  stepButton: {
    color: 'white !important',
    textShadow: 'none !important',
    backgroundColor: '#6086cf !important',
    border: '1px solid transparent !important',
    padding: '6px 10px !important',
    marginRight: '15px !important',
    borderWidth: '1px 1px 1px 5px !important',
    borderColor: '#ffb848 !important',
    '&:disabled': {
      color: '#555 !important',
      backgroundColor: '#bcbcbc !important',
      border: '1px solid transparent',
      borderColor: '#bcbcbc !important',
      cursor: 'not-allowed',
    },
  },
  submitButton: {
    color: 'white !important',
    textShadow: 'none !important',
    backgroundColor: '#555555 !important',
    border: '1px solid transparent !important',
    padding: '6px 10px !important',
    marginRight: '15px !important',
    borderWidth: '1px 1px 1px 5px !important',
    borderColor: 'black !important',
    '&:disabled': {
      color: '#555 !important',
      backgroundColor: '#bcbcbc !important',
      border: '1px solid transparent',
      borderColor: '#bcbcbc !important',
      cursor: 'not-allowed',
    },
  },
  gridWrapper: {
    margin: '0px !important',
    padding: '0px !important',
  },
  label: {
    [theme.breakpoints.up('md')]: {
      textAlign: 'right',
    },
    paddingTop: 5,
    paddingRight: 5,
    fontWeight: 400,
    fontSize: 12,
    lineHeight: 1.42857143,
  },
});
