import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Alert,
  CircularProgress,
  Collapse,
  IconButton,
  Snackbar,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import Subnavbar from '../../components/Sub-navbar/Subnavbar';
import {
  CloneRecordSubmit,
  dissmissConfirmationAlert,
  LoadRecord,
  UpdateRecord,
} from '../../redux/actions/ScreenActions';
import CloneRecordHeader from '../../components/FormHeaderTermDetail/CloneRecordHeader';
import CloneRecordBody from '../../components/formBodyTermDetail/CloneRecordsBody';
import TmsHeader from '../../components/header';
import EmbeddedSubnavbar from './subNavEmbedded/subNavEmbedded';

const CloneRecord = ({ handleEmbeddedClose }:any) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const {
    recordDetails,
    recordsColumns,
    activeScreen,
    parsedValues,
    openConfirmationAlert,
    confirmationAlert,
  } = useSelector((state: any) => state.EmbbededScreenReducer);
  const { Language } = useSelector((state: any) => state.LoginReducer);
  const [values, setValues] = useState<any>([]);
  const [valuesToSend, setValuesToSend] = useState<any>([]);
  //   console.log(location.state);
  const [open, setOpen] = useState(true);
  const [Visible, setVisible] = useState(false);
  const [isModifiy, setIsModifiy] = useState(false);
  //   useEffect(() => {
  //     dispatch(LoadRecord(location.state));
  //   }, []);

  const handleCloseConfirm = () => {
    setVisible(!Visible);
  };
  useEffect(() => {
    setValues(parsedValues);
    setValuesToSend(parsedValues);
  }, [parsedValues]);

  const handleClose = () => {
    dispatch(dissmissConfirmationAlert());
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    setValuesToSend({
      ...valuesToSend,
      [name]: value,
    });
    setIsModifiy(true);
  };
  const handleSelectChange = (
    name: any,
    e: React.ChangeEvent<HTMLInputElement>,
    newValue: any,
  ) => {
    setValues({
      ...values,
      [name]: newValue,
    });
    setValuesToSend({
      ...valuesToSend,
      [name]: newValue.value,
    });
    setIsModifiy(true);
  };
  const handleSave = () => {
    dispatch(
      CloneRecordSubmit({
        table: activeScreen.tableID,
        navigate,
        backScreen: activeScreen.redirect,
        form_data: valuesToSend,
      }),
    );
  };
  const compare = (currentValue: any) => {
    const result = currentValue?.obj['\u0000*\u0000parm']?.options?.filter(
      (e: any) => e?.value === values[currentValue?.id],
    );
    return result ? result[0]?.label : '';
  };

  const { _, ...restData } = recordDetails;

  return recordDetails.length === 0 ? (
    <div
      style={{
        width: '100%',
        height: '90vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress />
    </div>
  ) : (
    <>
      <EmbeddedSubnavbar
        label
        menuOptionType="simpleItems"
            // menuOptions={FilterSaved}
        handleEmbeddedClose={handleEmbeddedClose}
        exportTable={() => { console.log('sdfds'); }}
      />
      <Collapse in={open}>
        <Alert
          severity="warning"
          action={(
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          )}
          sx={{ mb: 2 }}
        >
          <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
            <Typography style={{ fontWeight: 'bold', color: 'orange' }}>
              Warning!
            </Typography>
            {' '}
            You must change the key fields before saving!
          </div>
        </Alert>
      </Collapse>
      <CloneRecordHeader
        Visible={Visible}
        options={activeScreen.redirect}
        handleCloseConfirm={handleCloseConfirm}
        dataHeader={_}
        compare={compare}
        // routeBack={routeBack}
        handleSelectChange={handleSelectChange}
        handleInputChange={handleInputChange}
        values={values}
        handleSave={handleSave}
        isModifiy={isModifiy}
        valuesToSend={valuesToSend}
        embedded
      />
      <CloneRecordBody
        dataTabs={restData}
        handleSelectChange={handleSelectChange}
        handleInputChange={handleInputChange}
        values={values}
        compare={compare}
        valuesToSend={valuesToSend}
      />
      <Snackbar
        open={openConfirmationAlert}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          {confirmationAlert}
        </Alert>
      </Snackbar>
    </>
    // )
  );
};

export default CloneRecord;
