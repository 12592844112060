/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/function-component-definition */
import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { PlusOne } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxRoundedIcon from '@mui/icons-material/IndeterminateCheckBoxRounded';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import CustomDropdown from '../../../components/bulkTransactionCustomDropdown/index';
import CustomInput from '../../../components/bulkTransactionCustomInput/index';
import { useStyles } from '../bulkTransaction/style';
import BulkTransactionProductStep from '../../../components/bulkTransactionProducts/bulkTransactionProductStep';
import CustomAddDropdown from '../../../components/customAddDropdown';
import { GetTransactionSchema } from '../../../redux/actions/ScreenActions';
import { SubmitTransactionDialog } from '../../../components/dialogSubmitTransaction';
import { SetActiveStep } from '../../../redux/actions/workflows/contractTransaction';
import OrderMaintenanceProductStep from './OrderMaintenanceProductStep';

const checkOrderStatus :any = ['A', 'C', 'F', 'L', 'H'];
const step5Detail = [
  { id: 'DateAdded', label: 'Date Added' },
  { id: 'DateLstMod', label: 'Date Last Modified' },
  { id: 'DateAuthorized', label: 'Date Authorized' },
  { id: 'TruckDriver', label: 'Truck Driver' },
  { id: 'GrossWeight', label: 'Gross Weight' },
  { id: 'TareWeight', label: 'Tare Weight' },
  { id: 'ProcessException', label: 'Process Exception' },
  { id: 'LoadStartDate', label: 'Load Start Date' },
  { id: 'LoadStopDate', label: 'Load Stop Date' },
  { id: 'TransRefNo', label: 'Trans Ref Number' },
  { id: 'TransSequenceNo', label: 'Trans Sequence No' },
];

export default function OrderMaintenanceVerticalStepper({
  data,
  values,
  setValues,
  handleInputChange,
  handleSelectChange,
  valuesToSend,
  isBulkTransaction,
  handleOtherStepsChange,
  visible,
  stepperTitles,
  handleInputProductChange,
  screenID,
  onOpenSubmit,
  currentSchema,
  prefixProducts,
  resetPrefixProducts,
  prefix,
  products,
  addProducts,
  removeFormFields,
  handleRecordSelectChange,
  handleDetailSelectChange,
  ProductStepReviewState,
  startNewTransaction,
  onCloseSubmit,
  openSubmit,
  setValuesToSend,
  contractidOptions,
}: any) {
  const [activeStep, setActiveStep] = React.useState<any>(0);
  const [disableFields, setDisableFields] = React.useState<any>([]);
  const [EditingStep, setEditingStep] = React.useState<any>(0);
  const { currentRecord, orderData } = useSelector((state: any) => state.Workflows.OrderMaintenanceReducer);
  const orderStatus = orderData?.Orders?.[0]?.OrderStatus;
  const [name, setName] = React.useState<any>([]);
  const [review, setReview] = React.useState(false);
  const [expandedState, setExpandedState] = React.useState<any>([]);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loading, stepperData } = useSelector(
    (state: any) => state.ScreenReducer,
  );
  const {
    chainedList,
  } = useSelector((state: any) => state.Workflows.OrderMaintenanceReducer);

  React.useEffect(() => {
    const order = orderData?.Orders?.[0];
    setValuesToSend((prev:any) => ({ ...prev, ...order }));
    setValues((prev:any) => ({ ...prev, ...order }));
    if (orderData?.Orders?.length > 0) {
      setDisableFields(['TermID', 'RecType', 'OrderNo', 'SupplierNo']);
    } else {
      setDisableFields([]);
    }
  }, [orderData]);
  const handleExpandStep = (stepKey: string, collapseAll = false) => {
    if (collapseAll) {
      const keysList = Object.keys(expandedState);
      const newStates = keysList?.reduce(
        (acc: any, key: any) => ({ ...acc, [key]: false }),
        {},
      );
      setExpandedState(newStates ?? expandedState);
    } else {
      setExpandedState((previousExpandState: any) => ({
        ...previousExpandState,
        [stepKey]: !previousExpandState[stepKey],
      }));
    }
  };
  const getValueForReview = (key:string, field:string) => {
    const searchedField:any = Object.keys(valuesToSend?.[key]).filter((item:any) => item === field);
    return field === 'contractid' ? field : searchedField?.[0];
  };
  React.useEffect(() => {
    dispatch(SetActiveStep({ activeStep, EditingStep }));
  }, [activeStep, EditingStep]);

  const handleNext = () => {
    if (activeStep < 3) {
      setReview(true);
      setActiveStep((prevActiveStep:any) => prevActiveStep + 1);
      setEditingStep((prevEditingStep:any) => prevEditingStep + 1);
    } else {
      onOpenSubmit();
    }
  };
  const handleBack = () => {
    setReview(true);
    setActiveStep((prevActiveStep:any) => prevActiveStep - 1);
    setEditingStep((prevEditingStep:any) => prevEditingStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setEditingStep(0);
  };

  const handleActiveStepEditing = (index: number) => {
    setEditingStep(index);
    setActiveStep(index);
  };
  const handleActiveStepReviewing = (index: number) => {
    if (!checkOrderStatus?.includes(orderStatus)) {
      setReview(false);
      setActiveStep(index);
    }
  };
  const startNewTransactionAndReset = () => {
    setActiveStep(0);
    setEditingStep(0);
    startNewTransaction();
    onCloseSubmit();
  };
  const copyTransaction = () => {
    setActiveStep(0);
    setEditingStep(0);
    onCloseSubmit();
  };

  React.useEffect(() => {
    const stepNames = data?.reduce(
      (h: any, step: any) => Object.assign(h, {
        [step[0]?.stepID]: false,
      }),
      {},
    );

    setExpandedState(stepNames);
    if (checkOrderStatus?.includes(orderStatus)) {
      // if order maintenance is readonly then no step to show so that why set value -1.
      handleActiveStepEditing(-1);
    } else {
      handleActiveStepEditing(0);
    }
  }, [data]);
  console.log('currentSchema', currentSchema);
  return (
    <div
      style={{
        width: '100%',
        padding: '10px 0px',
        display: 'flex',
        justifyContent: 'center !important',
        alignItems: 'center !important',
        maxHeight: '90vh',
        overflow: 'auto',
      }}
    >
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        sx={{
          width: '100%',
          '& .css-8t49rw-MuiStepConnector-line': {
            display: 'block',
            borderLeft: '1px solid #bdbdbd',
            minHeight: '2px',
          },
        }}
      >
        {data?.map((step: any, index: any) => (
          <Step
            key={index}
            sx={{
              width: '100%',
              padding: '0px',
            }}
            expanded={index === activeStep}
          >
            <StepLabel
              onClick={index === activeStep ? () => setActiveStep(null) : () => handleActiveStepReviewing(index)}
              icon={
                index === activeStep ? (
                  <IndeterminateCheckBoxRoundedIcon onClick={() => setActiveStep(null)} color="inherit" />
                ) : (
                  <IconButton disabled={!checkOrderStatus?.includes(orderStatus)}>
                    <AddBoxIcon onClick={() => handleActiveStepReviewing(index)} />
                  </IconButton>
                )
              }
              sx={{
                backgroundColor: index === activeStep ? '#d8f0ff' : '#ffffff',
                border: index === activeStep ? 'none' : '1px solid #C0C0C0',
                fontWeight: 'bold',
                alignSelf: 'center',
                padding: '0px 20px',
                display: 'flex',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <div
                  onClick={() => handleActiveStepReviewing(index)}
                >
                  <Typography variant="h6" fontFamily="inherit" fontSize={14} color="#000">
                    Step -
                    {index + 1}
                  </Typography>
                  <Typography fontSize={11} color="#777">
                    {index === EditingStep
                      ? (
                        <div style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 5,
                        }}
                        >
                          {stepperTitles[index]}
                          <Typography fontSize={11} color="#000" fontFamily="inherit" fontWeight="bold">(Editing)</Typography>
                        </div>
                      )
                      : stepperTitles[index]}
                  </Typography>
                </div>
                {index !== activeStep ? (
                  index < 3 ? (
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        width: '100%',
                        whiteSpace: 'nowrap',
                        position: 'relative',
                        padding: '12px',
                      }}
                    >
                      {step.map(
                        (val: any, key: any) => (valuesToSend?.[val.id] ? (
                          <div className={classes.review}>
                            <div
                              style={{
                                width: '200px',
                                display: 'flex',
                                justifyContent: 'flex-end',
                              }}
                            >
                              <Typography
                                variant="body2"
                                fontFamily="inherit"
                                fontSize={12}
                                fontWeight="bolder"
                              >
                                {val?.label || val}
                                {' '}
                              </Typography>
                            </div>
                            <div
                              style={{
                                width: '200px',
                                display: 'flex',
                                justifyContent: 'flex-start',
                              }}
                            >
                              <Typography
                                variant="body2"
                                fontFamily="inherit"
                                fontSize={12}
                              >

                                {val?.id?.search('date') > 1 ? `20${valuesToSend?.[val?.id].slice(0, 2)}/${valuesToSend?.[val?.id].slice(
                                  3,
                                  5,
                                )}/${valuesToSend?.[val?.id].slice(4, 6)}` : (values?.[val?.id]?.label || valuesToSend?.[val?.id])}
                              </Typography>
                            </div>
                          </div>
                        ) : orderData?.Orders?.length > 0 && orderData?.Orders?.[0][val.id] && (
                          <div className={classes.review}>
                            <div
                              style={{
                                width: '200px',
                                display: 'flex',
                                justifyContent: 'flex-end',
                                color: 'black',
                              }}
                            >
                              <Typography
                                variant="body2"
                                fontFamily="inherit"
                                fontSize={12}
                                fontWeight="bold"
                              >
                                {val?.label || val}
                                {' '}
                              </Typography>
                            </div>
                            <div
                              style={{
                                width: '200px',
                                display: 'flex',
                                justifyContent: 'flex-start',
                              }}
                            >
                              <Typography
                                variant="body2"
                                fontFamily="inherit"
                                fontSize={12}
                              >
                                {orderData?.Orders?.[0][val.id]}
                              </Typography>
                            </div>
                          </div>
                        )),
                      )}
                    </div>
                  )
                    : (
                      <table style={{ margin: 'auto', padding: '12px', width: '80%' }}>
                        <tbody>
                          <tr>
                            {ProductStepReviewState?.map((product:any, keyProd:any) => (<td style={{ padding: '0px 20px', fontWeight: 'bold' }}>{product?.label}</td>))}
                          </tr>
                          {
                            products?.length > 0
                              ? products?.map((product:any, keyProd:any) => (
                                <tr>
                                  {ProductStepReviewState?.map((reviewProduct:any, key:any) => {
                                    getValueForReview(product.id, reviewProduct?.id);
                                    return (
                                      <td style={{ padding: '0px 20px' }}>
                                        <Typography fontSize={12} fontFamily="inherit">
                                          {valuesToSend?.[product.id]?.[getValueForReview(product.id, reviewProduct?.id)]?.label
                                        || valuesToSend?.[product.id]?.[getValueForReview(product.id, reviewProduct?.id)] }
                                        </Typography>

                                      </td>
                                    );
                                  })}
                                </tr>
                              ))
                              : (
                                orderData?.OrderItem?.map((row:any) => (
                                  <tr>
                                    <td style={{ padding: '0px 20px' }}>
                                      <Typography fontSize={12} fontFamily="inherit">
                                        {row.OrderItemNo}
                                      </Typography>
                                    </td>
                                    <td style={{ padding: '0px 20px' }}>
                                      <Typography fontSize={12} fontFamily="inherit">
                                        {row.ProdID}
                                      </Typography>
                                    </td>
                                    <td style={{ padding: '0px 20px' }}>
                                      <Typography fontSize={12} fontFamily="inherit">
                                        {row.OrderQty}
                                      </Typography>
                                    </td>
                                    <td style={{ padding: '0px 20px' }}>
                                      <Typography fontSize={12} fontFamily="inherit">
                                        {row.Trailer}
                                      </Typography>
                                    </td>
                                    <td style={{ padding: '0px 20px' }}>
                                      <Typography fontSize={12} fontFamily="inherit">
                                        {row.Retained}
                                      </Typography>
                                    </td>
                                    <td style={{ padding: '0px 20px' }}>
                                      <Typography fontSize={12} fontFamily="inherit">
                                        {row.Loaded}
                                      </Typography>
                                    </td>
                                    <td style={{ padding: '0px 20px' }}>
                                      <Typography fontSize={12} fontFamily="inherit">
                                        {row.Returned}
                                      </Typography>
                                    </td>
                                  </tr>
                                ))

                              )
                              }
                        </tbody>
                      </table>
                    )
                ) : (
                  <></>
                )}
                {EditingStep !== index && !checkOrderStatus?.includes(orderStatus) && (
                <Button
                  variant="outlined"
                  size="small"
                  color="inherit"
                  sx={{
                    textTransform: 'none',
                    height: '80%',
                  }}
                  onClick={() => { handleActiveStepEditing(index); }}
                >
                  Edit
                </Button>
                )}
              </div>
            </StepLabel>

            <StepContent>
              {isBulkTransaction
                && index !== 4
                && activeStep < 3
                && step.map((val: any, key: any) => (!currentSchema?.[val.id]?.visible && !visible ? (
                  <></>
                ) : ['Vehicle#1', 'Vehicle#2', 'Vehicle#3']?.includes(val.id) ? (
                  <>
                    <CustomAddDropdown
                      val={val}
                      isBatch={false}
                      label={val?.label}
                      name={val?.id}
                     // defaultValue={val?.value}
                      isDisabled={activeStep !== EditingStep}
                      value={values[val?.id]}
                      isOptionEqualToValue={(option: any, value: any) => option?.label === value}
                      onChange={handleRecordSelectChange}
                      size="70%"
                      margin={0}
                      options={
                        val?.list?.length
                          ? val?.list.map(
                            (item: any) => ({
                              value: item.value,
                              label: item.label,
                            }),
                          ) : []
                      }
                      type={val?.type}
                      obj={val?.obj}
                      query={val?.query}
                      required={val?.attr?.includes('required')}
                      selectList={val?.selectList}
                      chain={val?.chain}
                      stateValues={values}
                      valuesToSend={valuesToSend}
                    />
                  </>
                )
                  : val?.list
                    ? (
                      <>
                        <CustomDropdown
                          val={currentSchema?.[val.id]}
                          isBatch={false}
                          label={val?.label}
                          name={val?.id}
                          isDisabled={disableFields.includes(val.id)}
                          value={values[val?.id]}
                          isOptionEqualToValue={(option: any, value: any) => option?.label === value && value !== ''}
                          onChange={handleRecordSelectChange}
                          size="70%"
                          margin={0}
                          chained={currentSchema?.[val?.id]?.['\u0000*\u0000parm']}
                          options={
                            val?.list?.length
                              ? val?.list.map(
                                (item: any) => ({
                                  value: item.value,
                                  label: item.label,
                                }),
                              )
                              : chainedList?.chain_fields_list?.[val?.id]?.length
                                ? chainedList?.chain_fields_list?.[val?.id].map(
                                  (item: any) => ({
                                    value: item.value,
                                    label: item.label,
                                  }),
                                )
                                : []
                          }
                          type={val?.type}
                          obj={val?.obj}
                          query={currentSchema?.[val.id]?.['\u0000*\u0000parm']}
                          required={val?.attr?.includes('required')}
                          selectList={val?.selectList}
                          chain={val?.chain}
                          stateValues={values}
                          valuesToSend={valuesToSend}
                          chainedList={chainedList?.chain_fields_list?.[val?.id]}
                        />
                      </>
                    ) : (
                      <CustomInput
                        dateType={val?.type
                        === 'datetime'}
                        type={
                            val?.type
                        === 'datetime'
                              ? 'date'
                              : 'text'
                      }
                        label={val?.label}
                        required={val?.attr?.includes('required')}
                        name={val?.id}
                        attr={val?.attr}
                        value={orderData?.Orders?.[0] ? orderData?.Orders?.[0]?.[val?.id] : valuesToSend?.[val?.id]}
                        onChange={handleInputChange}
                        size="70%"
                        maxLength={10}
                        inputProps={{ maxLength: 10 }}
                        placeholder={val.label}
                        isDisabled={disableFields.includes(val.id)}
                        validate={val?.validate}
                      />
                    )))}
              {activeStep === 3 && (
                <OrderMaintenanceProductStep
                  handleDetailSelectChange={handleDetailSelectChange}
                  values={values}
                  handleBack={handleBack}
                  valuesToSend={valuesToSend}
                  orderItems={[orderData?.OrderItem?.[0]]}
                />
              )}

              {isBulkTransaction && index - 1 === activeStep && (
                <Box sx={{ mb: 2 }}>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Button
                      variant="contained"
                      disabled={index === 0}
                      onClick={handleBack}
                      sx={{ mt: 1, mr: 1, textTransform: 'none' }}
                    >
                      Prev Step
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      sx={{ mt: 1, mr: 1, textTransform: 'none' }}
                      disabled={index === data?.length - 1}
                    >
                      Next Step
                    </Button>
                  </div>
                </Box>
              )}
            </StepContent>
          </Step>
        ))}
        {
          checkOrderStatus?.includes(orderStatus) && (
            <Step
              key={-1}
              sx={{
                width: '100%',
                padding: '0px',
              }}
            >
              <StepLabel
                icon={(
                  <IconButton disabled>
                    <AddBoxIcon />
                  </IconButton>
                )}
                sx={{
                  backgroundColor: '#ffffff',
                  border: '1px solid #C0C0C0',
                  fontWeight: 'bold',
                  alignSelf: 'center',
                  padding: '0px 20px',
                  display: 'flex',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <div>
                    <Typography variant="h6" fontFamily="inherit" fontSize={14} color="#000">
                      Step - 5
                    </Typography>
                    <Typography fontSize={11} color="#777">
                      System Data
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      width: '100%',
                      whiteSpace: 'nowrap',
                      position: 'relative',
                      padding: '12px',
                      paddingLeft: '60px',
                    }}
                  >
                    {step5Detail.map((val:any) => (
                      <div className={classes.review}>
                        <div
                          style={{
                            width: '200px',
                            display: 'flex',
                            color: 'black',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <Typography
                            variant="body2"
                            fontFamily="inherit"
                            fontSize={12}
                            fontWeight="bolder"
                          >
                            {orderData?.Orders?.[0][val.id] && val.label}
                          </Typography>
                        </div>
                        <div
                          style={{
                            width: '200px',
                            display: 'flex',
                            justifyContent: 'flex-start',
                          }}
                        >
                          <Typography
                            variant="body2"
                            fontFamily="inherit"
                            fontSize={12}
                          >
                            {orderData?.Orders?.[0][val.id]}
                          </Typography>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </StepLabel>
            </Step>
          )
        }
      </Stepper>
      {activeStep === data?.length && data?.length > 0 && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
        </Paper>
      )}
      <SubmitTransactionDialog
        onClose={onCloseSubmit}
        open={openSubmit}
        startNewTransaction={startNewTransactionAndReset}
        copyTransaction={copyTransaction}
      />
    </div>
  );
}
