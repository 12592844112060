import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {},
  closeButton: {
    color: 'black !important',
    textShadow: 'none !important',
    backgroundColor: '#ffbb33 !important',
    border: '1px solid transparent !important',
    padding: '6px 10px !important',
  },
  label: {
    paddingTop: '5px !important',
    paddingRight: '5px !important',
    fontSize: '12px !important',
    fontWeight: '400 !important',
    color: 'black !important',
  },
  textArea: {
    resize: 'vertical',
    height: '80px !important',
    width: '75% !important',
    margin: '2px 10px !important',
  },
  wrapper: { justifyContent: 'flex-end' },
  dialogBody: { minHeight: 200 },
  applyButton: {
    color: 'black !important',
    textShadow: 'none !important',
    backgroundColor: '#00C851 !important',
    border: '1px solid transparent !important',
    padding: '6px 10px !important',
    '&:hover': {
      backgroundColor: '#007E33 !important',
      color: '#fff !important',
    },
    '&:disabled': {
      color: '#555 !important',
      backgroundColor: '#bcbcbc !important',
      border: '1px solid transparent',
    },
  },
  freezeButton: {
    borderWidth: '1px 1px 1px 5px !important',
    borderColor: '#4d90fe !important',
    color: 'black !important',
    textShadow: 'none !important',
    backgroundColor: '#ddd !important',
    border: '1px solid transparent !important',
    padding: '6px 10px !important',
    marginRight: '15px !important',
    '&:hover': {
      color: '#fff !important',
      backgroundColor: '#545454 !important',
    },
  },
  headerTitle: {
    fontSize: 12,
    fontWeight: 'bold',
    color: 'black',
  },
  headerValue: {
    fontSize: 12,
    fontWeight: 'normal',
    color: 'black',
  },
  headerWrapper: {
    paddingBottom: 20,
    paddingTop: 5,
  },
  divider: {
    marginBottom: '15px !important',
  },
  alert: {
    marginBottom: 10,
    borderLeft: '15px solid #FEAF20 !important',
    backgroundColor: '#fcf8e3 !important',
    borderRadius: '0px !important',
  },
  warningMessage: { color: 'black' },
  warningTitle: { color: '#FEAF20' },
});
