import { Reducer } from 'redux';
import { ReleaseInfo } from '../../actions/workflows/aboutPage';
import { GET_ABOUT_PAGE_DATA } from '../../constants/workflows';

export interface ProductVersion {
  product: string;
  version: string;
  build: string;
  time: string;
  certificateNumber: string;
}
export interface AboutPageState {
  data : {
    installedPackages: Array<never>;
    productVersion: ProductVersion;
    releaseInfo: ReleaseInfo;
  }
}

const INITIAL_STATE : AboutPageState = {
  data: {
    installedPackages: [],
    productVersion: {
      product: '',
      version: '',
      build: '',
      time: '',
      certificateNumber: '',
    },
    releaseInfo: {
      product: '',
      versions: {},
    },
  },
};

const AboutPage : Reducer<AboutPageState> = (
  state = INITIAL_STATE,
  { type, payload }: any,
) => {
  switch (type) {
    case GET_ABOUT_PAGE_DATA: {
      return {
        ...state,
        data: payload,
      };
    }
    default:
      return state;
  }
};

export default AboutPage;
