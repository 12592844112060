/* eslint-disable import/order */
/* eslint-disable no-debugger */
/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Grid } from '@mui/material';
// Librairies
import './style.css';
import RackStatusTransportTable from '../RackStatusTransportTable';
import RCUDitto from '../../RCUDitto';
import { useSelector } from 'react-redux';
// import ConfirmationDialog from '../../../ConfirmDialog';
interface IProps {
    pageImgList: any;
    transportDetailData: any;
    dittoBayID: string;
}
const RackStatusRCUDitto: React.FC<IProps> = ({ pageImgList, transportDetailData, dittoBayID }) => {
  const {
    RCUScreen,
  } = useSelector((state: any) => state.Workflows.RackStatus);
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <div id="transporttabledetail">
            {transportDetailData.length !== 0 && (
            <RackStatusTransportTable
              Table={transportDetailData}
            />
            )}
          </div>
        </Grid>
      </Grid>
      <table style={{ width: '100%' }}>
        <tbody>
          <tr>
            <Grid container>
              <Grid item xs={12} lg={3}>
                <td>
                  <div id="device_$dittoBayID" style={{ float: 'right' }}>
                    <div style={{ position: 'relative' }}>
                      <RCUDitto RCUScreen={RCUScreen} dittoBayID={dittoBayID} />
                    </div>
                  </div>
                </td>
              </Grid>
              <Grid item xs={12} lg={4} sx={{ textAlign: 'center', marginTop: '4em' }}>
                <td>
                  <table style={{ margin: '0 auto' }}>
                    <tbody>
                      <tr>
                        {pageImgList.map((imgNo: any) => {
                          if (!imgNo.isData) {
                            return <></>;
                          }
                          const pathSrc = `/assets/images/${imgNo.imgSrc}`;
                          return (
                            <td style={{ position: 'relative' }} key={imgNo.imgSrc}>
                              <div id={`vehicleframe-${imgNo.index}`}>
                                <img id={imgNo.imgID} src={pathSrc} style={{ height: `${imgNo.sizePercent}vw`, minHeight: '120px' }} />
                                <div id={imgNo.capacityFrameID} className="facimg" style={{ position: 'absolute', overflow: 'hidden', ...imgNo.capacitystyle }}>
                                  {imgNo.compartment.length !== 0 && (
                                  <table className="compartment">
                                    <tbody>
                                      {imgNo.compartment.map((comp: any) => (
                                        <td style={comp.tdStyle} key={comp.compartmentNumber}>
                                          <div className={comp.loadClass} style={comp.firstStyle}></div>
                                          <div style={{ position: 'absolute', top: '0', opacity: '0.5' }}>
                                            <div style={{
                                              color: 'black', fontStyle: 'italic', fontWeight: 'bold', fontSize: '14px', padding: '0 5px 0 5px', width: '35px',
                                            }}
                                            >
                                              #
                                              {comp.compartmentNumber}
                                            </div>
                                          </div>
                                        </td>
                                      ))}
                                    </tbody>
                                  </table>
                                  )}
                                </div>
                              </div>
                            </td>
                          );
                        })}
                      </tr>
                    </tbody>
                  </table>
                </td>
              </Grid>
            </Grid>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default RackStatusRCUDitto;
