/* eslint-disable no-restricted-syntax */
import { Dispatch } from 'redux';
import Api from '../../api/index';
import {
  GENERATE_UTILITY_RESPONSE,
  GET_UTILITY_CUSTOMERS,
  GET_UTILITY_FOLIOMONTH,
  GET_UTILITY_FOLIONUMBER,
  GET_UTILITY_PRODUCTS,
  LOAD_SYSTEM_UTILITY_FIELDS,
  LOAD_UTILITY_SCREEN,
  SET_FORM_EDIT_STATE,
} from '../../constants/workflows';

const splitKeyValue = (obj:any) => {
  const keys = Object.keys(obj);
  const res = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < keys.length; i++) {
    res.push({
      value: keys[i],
      label: obj[keys[i]].replace(/&nbsp;/g, ''),
    });
  }
  return res;
};

// eslint-disable-next-line max-len
const getFilterValue = (arr: any, value: any) => {
  const Result = arr.find((item: any) => item.value === value);
  return Result || { value: '', label: '' };
};

const UtilityToFilterScadArray = (arr: any) => {
  const filterArr = arr.filter((item: any) => item?.id !== 'genreport' && item?.id !== 'reportid');
  const scadData = filterArr.map((item: any) => {
    if (item?.type === 'productchain') {
      return [{
        options: splitKeyValue(item?.options?.selectList[0]?.list),
        label: 'Product',
        name: 'rptfield_p',
        defaultValue: getFilterValue(
          splitKeyValue(item?.options?.selectList[0]?.list),
          '',
        ),
      }];
    }
    if (item?.type === 'chain' || item?.type === 'foliochain') {
      if (item?.options?.selectList[0]?.id === 'rptfield_F_month_F') {
        return [{
          options: splitKeyValue(item?.options?.selectList[0]?.list),
          label: 'Folio Month',
          name: 'rptfield_F_month_F',
          defaultValue: getFilterValue(
            splitKeyValue(item?.options?.selectList[0]?.list),
            item?.options?.allOptions?.selected[0],
          ),
          key: Math.random(),
        },
        {
          options: splitKeyValue(item?.options?.selectList[1]?.list),
          label: 'Folio Number',
          name: 'rptfield_F_num_F',
          defaultValue: getFilterValue(
            splitKeyValue(item?.options?.selectList[1]?.list),
            item?.options?.allOptions?.selected[1],
          ),
          key: Math.random(),
        }];
      }
      if (item?.options?.selectList[0]?.id === 'rptfield_F_month_j') {
        return [{
          options: splitKeyValue(item?.options?.selectList[0]?.list),
          label: 'Folio Month',
          name: 'rptfield_F_month_j',
          defaultValue: getFilterValue(
            splitKeyValue(item?.options?.selectList[0]?.list),
            item?.options?.allOptions?.selected[0],
          ),
          key: Math.random(),
        },
        {
          options: splitKeyValue(item?.options?.selectList[1]?.list),
          label: 'Folio Number',
          name: 'rptfield_F_num_j',
          defaultValue: getFilterValue(
            splitKeyValue(item?.options?.selectList[1]?.list),
            item?.options?.allOptions?.selected[1],
          ),
          key: Math.random(),
        }];
      }
      if (item?.options?.selectList[0]?.id === 'rptfield_F_month_i') {
        return [{
          options: splitKeyValue(item?.options?.selectList[0]?.list),
          label: 'Folio Month',
          name: 'rptfield_F_month_i',
          defaultValue: getFilterValue(
            splitKeyValue(item?.options?.selectList[0]?.list),
            item?.options?.allOptions?.selected[0],
          ),
          key: Math.random(),
        },
        {
          options: splitKeyValue(item?.options?.selectList[1]?.list),
          label: 'Folio Number',
          name: 'rptfield_F_num_i',
          defaultValue: getFilterValue(
            splitKeyValue(item?.options?.selectList[1]?.list),
            item?.options?.allOptions?.selected[1],
          ),
          key: Math.random(),
        }];
      }
      if (item?.options?.selectList[0]?.id === 'rptfield_p') {
        return [{
          options: splitKeyValue(item?.options?.selectList[0]?.list),
          label: 'Product',
          name: 'rptfield_p',
        }];
      }
    }
    if (item?.htype === 'scad') {
      if (item?.scadLevel === 'S') {
        return [{
          options: splitKeyValue(item?.options?.hierarchyScad?.list),
          label: 'Supplier',
          name: 'rptfield_S',
        }];
      }
      if (item?.scadLevel === 'SC') {
        return [{
          options: splitKeyValue(item?.options?.hierarchyScad?.list),
          label: 'Supplier',
          name: 'rptfield_S',
        },
        {
          options: [{ value: '', label: 'All' }],
          label: 'Customer',
          name: 'rptfield_C',
        }];
      }
      if (item?.scadLevel === 'SCA') {
        return [{
          options: splitKeyValue(item?.options?.hierarchyScad?.list),
          label: 'Supplier',
          name: 'rptfield_S',
        },
        {
          options: [{ value: '', label: 'All' }],
          label: 'Customer',
          name: 'rptfield_C',
        },
        {
          options: [{ value: '', label: 'All' }],
          label: 'Account',
          name: 'rptfield_A',
        }];
      }
      if (item?.scadLevel === 'SCAD') {
        return [{
          options: splitKeyValue(item?.options?.hierarchyScad?.list),
          label: 'Supplier',
          name: 'rptfield_S',
        },
        {
          options: [{ value: '', label: 'All' }],
          label: 'Customer',
          name: 'rptfield_C',
        },
        {
          options: [{ value: '', label: 'All' }],
          label: 'Account',
          name: 'rptfield_A',
        },
        {
          options: [{ value: '', label: 'All' }],
          label: 'Destination',
          name: 'rptfield_D',
        }];
      }
    }
    return item;
  });
  return scadData.flat(1);
};

export const getSystemUtilityData = (reportId: string) => async (dispatch: Dispatch) => {
  const response = await Api.get(`/report/screenConfig?reportid=${reportId}&reportType=S`);
  const ArrayOfObject = response.data.flat(1);
  const result = UtilityToFilterScadArray(ArrayOfObject);
  console.log(result);
  dispatch({
    type: LOAD_SYSTEM_UTILITY_FIELDS,
    payload: result,
  });
};

export const getCustomers = (supplier: string) => async (dispatch: Dispatch) => {
  // eslint-disable-next-line max-len
  const response = await Api.get(`/tablescreen/tablemaintdata?tableID=Customer&page=1&results_per_page=-1&where=supplier_no=${supplier}&fields=cust_no:Customer.cust_name`);
  const options = response.data.data.map((item: any) => item[Object.keys(item)[0]]);
  const customers = {
    options,
    label: 'Customer',
    name: 'rptfield_C',
  };
  dispatch({
    type: GET_UTILITY_CUSTOMERS,
    payload: customers,
  });
};

export const getFolioMonth = (TermId: string) => async (dispatch: Dispatch) => {
  // eslint-disable-next-line max-len
  const response = await Api.get(`/foliohierarchy?termid=${TermId}&type=foliomonth`);
  const data = splitKeyValue(response?.data[0]);
  // eslint-disable-next-line max-len
  const defaultValue = data.find((item: any) => item?.value === response.data[1]?.foliomonth) || '';
  const result = {
    options: data,
    defaultValue,
    key: Math.random(),
  };
  dispatch({
    type: GET_UTILITY_FOLIOMONTH,
    payload: result,
  });
};

// eslint-disable-next-line max-len
export const getFolioNumber = (TermId: string, foliomonth: string) => async (dispatch: Dispatch) => {
  // eslint-disable-next-line max-len
  const response = await Api.get(`/foliohierarchy?termid=${TermId || 'B344'}&foliomonth=${foliomonth}&type=folionumber`);
  const data = splitKeyValue(response?.data[0]);
  let flag = true;
  for (const arr of response.data[1]) {
    if (arr.length === 0) {
      flag = false;
    }
  }
  if (flag) {
    // eslint-disable-next-line max-len
    const defaultValue = data.find((item: any) => item?.value === response.data[1]?.folionumber) || data.at(-1);
    const result = {
      options: data,
      defaultValue,
      key: Math.random() * 10,
    };
    dispatch({
      type: GET_UTILITY_FOLIONUMBER,
      payload: result,
    });
  }
};

export const savedResponsedata = (response: any) => async (dispatch: Dispatch) => {
  dispatch({
    type: GENERATE_UTILITY_RESPONSE,
    payload: response,
  });
};

export const currentUtility = (response: any) => async (dispatch: Dispatch) => {
  dispatch({
    type: LOAD_UTILITY_SCREEN,
    payload: response,
  });
};

export const formInEdit = (response: any) => async (dispatch: Dispatch) => {
  dispatch({
    type: SET_FORM_EDIT_STATE,
    payload: response,
  });
};

export const getProducts = (termId: string) => async (dispatch: Dispatch) => {
  // eslint-disable-next-line max-len
  const response = await Api.get(`/tablescreen/tablemaintdata?tableID=Product&page=1&results_per_page=-1&where=term_id='${termId}'&fields=DISTINCT prod_id, prod_name&ShowRecordQuery=1`);
  const options = response.data.data.map((item: any) => ({
    value: item[Object.keys(item)[0]],
    label: `${item[Object.keys(item)[0]]}:${item[Object.keys(item)[1]]}`,
  }));
  const products = {
    options,
    label: 'Product',
    name: 'rptfield_p',
  };
  console.log(products);
  dispatch({
    type: GET_UTILITY_PRODUCTS,
    payload: products,
  });
};
