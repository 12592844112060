import React from 'react';
import { useNavigate } from 'react-router-dom';
// Librairies
import { Divider, Menu, MenuItem } from '@mui/material';

// Styles
import { useStyles } from './style';

interface IProps {
  anchorEl: any;
  onClose: () => void;
  clone: boolean;
  setClone: any;
  setOpen: any;
  // exportOption: any;
  // setExportOption: any;
  exportColumns: any;
}

const GroupDetailOptions: React.FC<IProps> = ({
  anchorEl,
  onClose,
  clone,
  setClone,
  setOpen,
  // exportOption,
  // setExportOption,
  exportColumns,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <Menu
      classes={{ root: classes.root, list: classes.list, paper: classes.paper }}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
    >
      <MenuItem
        classes={{ root: classes.menuItem }}
        onClick={() => {
          navigate('/clone-record', { replace: true });
        }}
      >
        Clone Record
      </MenuItem>
      <Divider />
      <MenuItem
        classes={{ root: classes.menuItem }}
        onClick={() => exportColumns({ type: 'csv', spool: false })}
      >
        Export Visible Columns (CSV)
      </MenuItem>
      <MenuItem
        classes={{ root: classes.menuItem }}
        onClick={() => exportColumns({ type: 'csv', spool: true })}
      >
        Export All Columns (CSV)
      </MenuItem>
      <MenuItem
        classes={{ root: classes.menuItem }}
        onClick={() => exportColumns({ type: 'xlsx', spool: false })}
      >
        Export Visible Columns (XLSX)
      </MenuItem>
      <MenuItem
        classes={{ root: classes.menuItem }}
        onClick={() => exportColumns({ type: 'xlsx', spool: true })}
      >
        Export All Columns (XLSX)
      </MenuItem>
    </Menu>
  );
};

export default GroupDetailOptions;
