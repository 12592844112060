/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
import React from 'react';

// Librairies
import { Autocomplete, ButtonBase, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

// Styles
import { useStyles } from './Style';

interface ISelectProps {
  options: any[];
  width?: number | string;
  value?: any;
  style?: React.CSSProperties;
  required?: boolean;
  onChange?: (e: any, option: any) => void;
  disableClearInput?: boolean;
  getOptionLabel?: (option: any) => string;
  name?: string;
  placeholder?: string;
  disabled?: boolean;
  noBorder?: boolean;
  addable?: boolean;
  handleChangeFilterText?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  filterTextValue?: string;
  onAdd?: () => void;
  showAdd?: boolean;
}

const Select: React.FC<ISelectProps> = ({
  options,
  width,
  value,
  style,
  onChange,
  disableClearInput,
  getOptionLabel,
  name,
  disabled,
  placeholder,
  addable,
  handleChangeFilterText,
  filterTextValue,
  onAdd,
  required,
  noBorder,
  showAdd,
}) => {
  const classes = useStyles();
  return (
    <>
      <Autocomplete
        getOptionLabel={getOptionLabel}
        disableClearable={disableClearInput || false}
        disabled={disabled}
        style={{ width, ...style }}
        value={value}
        onChange={onChange}
        classes={{
          root: classes.root,
          input: noBorder
            ? classes.simpleAutoCompleteInput
            : required
              ? classes.autoCompleteRequired
              : classes.autoCompleteInput,
        }}
        renderInput={(params: any) => (
          <div className="flexRow">
            <TextField
              classes={{ root: classes.input }}
              onChange={handleChangeFilterText}
              placeholder={placeholder || '-- Select --'}
              value={filterTextValue}
              name={name}
              {...params}
            />
            {addable && showAdd ? (
              <ButtonBase onClick={onAdd}>
                {' '}
                <AddIcon fontSize="small" />
                {' '}
                Add
              </ButtonBase>
            ) : null}
          </div>
        )}
        options={options}
      />
    </>
  );
};

export default Select;
