import React from 'react';
import TextField from '@mui/material/TextField';
import ReactInputMask from 'react-input-mask';

const MaskInput = (props: any) => {
  const {
    mask,
    onChange,
    value,
    size,
    name,
  } = props;

  const handleChange = (e: any) => {
    onChange({ target: { name, value: e.target.value.replace(/[^\d]/g, '') } });
  };

  return (
    <ReactInputMask
      mask={mask}
      value={value}
      onChange={(e: any) => handleChange(e)}
      style={{ flex: 3 }}
    >
      { (maskProps: any) => (
        <TextField
          className="textfield"
          {...maskProps}
          size="small"
          sx={{
            '& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
              padding: '4px 8px',
              border: '1px solid #dddddd',
            },
            '& .MuiInputBase-root': {
              '& > fieldset': {
                borderLeft: '5px solid #555',
                borderRadius: '0px !important',
              },
            },
            width: size,
          }}
        />
      )}
    </ReactInputMask>
  );
};

export default MaskInput;
