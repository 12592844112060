import React from 'react';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, SelectChangeEvent } from '@mui/material';
import ControlButton from '../controlButton';
import CustomDropdown from '../customDropdown/index';
import CustomInput from '../customInput';
import './style.css';
import { saveFieldBatchMode } from '../../redux/actions/ScreenActions';
import { saveFieldBatchModeEmbedded, SwitchEmbeddedNavigation } from '../../redux/actions/embbededScreensActions';
import CustomField from '../CustomField/CustomField';
import ConfirmationModalLoseChanges from '../confirmationModal/ConfirmationModalLoseChanges';

const BatchModeFormHeaderTerminal = (props: any) => {
  const {
    dataHeader, options, values, handleSelectChange, handleInputChange, isModifiy, valuesToSend, isEmbedded,
  } = props;
  const {
    activeScreen,
    recordDetails,
  } = useSelector(
    (state: any) => state?.[isEmbedded ? 'EmbbededScreenReducer' : 'ScreenReducer'],
  );
  const Ids = dataHeader.map((element: any) => [element.id, element.valForm]);
  const mapData = new Map(Ids);
  const arrayToObject = Object.fromEntries(mapData);
  const dispatch = useDispatch();
  const [BatchModeSelect, setBatchModeSelect] = React.useState({});
  const [VisibleConfirm, setVisibleConfirm] = React.useState(false);
  const navigate = useNavigate();
  const handleLoseConfirm = () => {
    setVisibleConfirm(!VisibleConfirm);
  };
  const handleCancel = async () => {
    if (isModifiy) {
      handleLoseConfirm();
    } else {
      if (isEmbedded) {
        await dispatch(SwitchEmbeddedNavigation('tableScreen'));
        return;
      }
      navigate(`${activeScreen.redirect}`);
    }
  };
  const handleCloseModalYes = async () => {
    if (isEmbedded) {
      await dispatch(SwitchEmbeddedNavigation('tableScreen'));
      return;
    }
    navigate(`${activeScreen.redirect}`);
  };
  const handleSave = async () => {
    if (isEmbedded) {
      await dispatch(saveFieldBatchModeEmbedded({ valuesToSend, values }));
      await dispatch(SwitchEmbeddedNavigation('batchUpdate'));
    } else {
      await dispatch(saveFieldBatchMode({ valuesToSend, values }));
      navigate('/batch-mode-confirm');
    }
  };

  return (
    <>
      <Paper
        sx={{
          width: '100%',
          height: '8vh',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <div className="headerContainer">
          <div className="controlers">

            <ControlButton
              label="Next"
              color="#00c851"
              disabled={!isModifiy}
              handleClick={handleSave}
            />
            <ControlButton
              label="Cancel"
              color="#ffbb33"
              disabled={false}
              handleClick={handleCancel}
            />

          </div>
        </div>
      </Paper>
      <div className="fields">
        {dataHeader?.map((val: any, key: any) => {
          const tabOpt = val?.obj['\u0000*\u0000parm'];
          let defVal;
          let optionsResult;
          if (tabOpt?.options !== undefined) {
            if (tabOpt?.options.length === 0) {
              defVal = 'Nothing Selected';
              optionsResult = Array.of({ label: defVal });
            } else {
              defVal = tabOpt?.options[0].label;
              optionsResult = tabOpt?.options;
            }
          } else if (tabOpt?.nothingselected !== null) {
            if (val?.selectList) {
              defVal = tabOpt?.selecttext;
              optionsResult = val?.selectList;
            } else {
              defVal = tabOpt?.nothingselected;
              optionsResult = tabOpt?.options;
            }
          }
          return (
            <>
              <CustomField
                val={val}
                values={values}
                valuesToSend={valuesToSend}
                handleInputChange={handleInputChange}
                handleSelectChange={handleSelectChange}
                size={1000}
                headerDisabled={val?.required}
                isBatch="Batch"
              />
            </>
          );
        })}
      </div>
      <ConfirmationModalLoseChanges
        Visible={VisibleConfirm}
        handleCloseConfirm={handleLoseConfirm}
        handleCloseModalYes={handleCloseModalYes}
        options={options}
      />
    </>
  );
};

export default BatchModeFormHeaderTerminal;
