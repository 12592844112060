import React from 'react';
import {
  Page, Text, Document, StyleSheet,
} from '@react-pdf/renderer';

const styles = StyleSheet.create({
  body: {
    paddingTop: 28,
    paddingBottom: 65,
    paddingHorizontal: 20,
  },
  text: {
    fontSize: 7,
    fontFamily: 'Courier',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 7,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
});

interface IProps {
    data: any;
}
const ViewReportPDFFile: React.FC<IProps> = ({ data }) => (
  <Document>
    {data.length !== 0 ? data?.rpt.map((rptpage: any, index: any) => (
      <Page style={styles.body}>
        {rptpage.map((item: any) => (
          <Text style={styles.text} key={Math.random()}>{item === '&nbsp;' ? ' ' : item}</Text>
        ))}
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
        />
      </Page>
    )) : '' }
  </Document>
);

export default ViewReportPDFFile;
