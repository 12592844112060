/* eslint-disable max-len */
/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import {
  Button, Checkbox, FormControlLabel, FormGroup, InputAdornment, MenuItem, Pagination, Select, Switch, TextField,
} from '@mui/material';
import {
  Close, ClosedCaption, Edit, Search,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import {
  DeleteRecord, LoadAllColumnsTables, LoadTables, SetBatchMode, SetRecordsList, StartLoading, StopLoading,
} from '../../redux/actions/ScreenActions';
import ColumnsModal from '../CustomModal/ColumnsModal';
import ConfirmationModalEmbedded from '../confirmationModal/ConfirmationModalEmbedded';
import useDebounce from '../../utils/searchDebounce';
import TransitionsModal from '../CustomModal';
import { isObject } from '../../utils';
import {
  LoadEmbbededTables,
  LoadRecordEmbbeded,
  SetBatchModeEmbedded,
  SetRecordsListEmbedded,
  SwitchEmbeddedNavigation,
} from '../../redux/actions/embbededScreensActions';
import TransitionsModalEmbedded from '../CustomModal/TransitionModalEmbedded';
import ColumnsModalEmbedded from '../CustomModal/ColumnsModalEmbedded';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number,
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  headCells: any;
  BatchMode:any;
  rows?: [];

}
interface EnhancedTabledataProps {
  headCells: any;
  rows: any;
  pages:any;
  tableOpt:any;
  routeDetails: string;
  screenID: any;
  backScreen:string
  BatchModeScreen?:any

}

const EnhancedTableHead = (props: EnhancedTableProps) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    headCells,
    rows,
    BatchMode,
    onRequestSort,
  } = props;
  const createSortHandler = (property: any) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead sx={{ boxShadow: 3 }}>
      <TableRow>
        {BatchMode && (
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        )}
        {headCells?.map((headCell:any) => (
          <TableCell
            key={headCell}
            align={headCell.numeric ? 'right' : 'left'}
            padding="none"
            sortDirection={orderBy === headCell ? order : false}
            style={{
              background: '#F5F5F5', border: '2px solid #fff', fontWeight: 'bold', padding: '5px 20px',
            }}
          >
            <TableSortLabel
              active={orderBy === headCell}
              direction={orderBy === headCell ? order : 'asc'}
              onClick={createSortHandler(headCell)}
              style={{ width: '100%', justifyContent: 'space-between' }}
            >
              {headCell}
              {orderBy === headCell ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

interface EnhancedTableToolbarProps {
  numSelected: number;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) => alpha(
            theme.palette.primary.main,
            theme.palette.action.activatedOpacity,
          ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected}
          {' '}
          selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

const EmbeddedEnhancedTable = (props: EnhancedTabledataProps) => {
  const {
    rows, headCells, pages, tableOpt, routeDetails, screenID, backScreen, BatchModeScreen,
  } = props;
  const {
    loading, data, recordsColumns, FilterSaved, activeScreen, activeScreenLabels,
  } = useSelector(
    (state: any) => state.EmbbededScreenReducer,
  );
  const {
    Language,
  } = useSelector((state: any) => state.LoginReducer);
  console.log('activeScreen', activeScreen);
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<any>('Term_id');
  const [selected, setSelected] = React.useState<any>([]);
  const [selectedBatch, setSelectedBatch] = React.useState<any>([]);
  const [selectedEditBatch, setSelectedEditBatch] = React.useState<any>([]);
  const [BatchMode, setBatchMode] = React.useState(false);
  const [Rows, setRows] = React.useState(data.data);
  const [deleteQuery, setdeleteQuery] = React.useState<any>([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState<any>(tableOpt.results_per_page);
  const [searching, setSearching] = React.useState(false);
  const [TableId, setTableId] = React.useState(tableOpt.tableID);
  const [Visible, setVisible] = React.useState(false);
  const [debouncedSearch, setDebouncedSearch] = useDebounce('', 300);
  const history = useNavigate();
  const dispatch = useDispatch();
  const column = activeScreen?.fields?.split(',') || [];
  const [currentPage, setCurrentPage] = React.useState<number>(parseInt(activeScreen.page, 10));
  // getting records list
  console.log('column', column);
  const recordsList = rows?.map((row:any, key:any) => (
    {
      key,
      tableID: tableOpt.tableID,
      screenID,
      lang: Language,
      where: activeScreen.keys ? JSON.stringify(activeScreen.keys.split(',').reduce((acc:any, item:any) => ({ ...acc, [item]: row[item]?.value }), {})) : null,
      modifyDep: activeScreen.keys ? activeScreen?.keys.split(',').reduce((acc:any, item:any) => ({ ...acc, [item]: row[item]?.value }), {}) : null,
    }
  ));

  // React.useEffect(() => {
  //   setRecordListRdx();
  // }, []);
  function extractContent(s:any) {
    const span:HTMLElement = document.createElement('span');
    span.innerHTML = isObject(s) ? s.label : s;
    return span.textContent || span.innerText;
  }

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n: any) => n);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, row: any) => {
    console.log('row clicked', row);
    const selectedIndex = selected.indexOf(row);
    let newSelected: any = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    newSelected.map((val:any) => {
      // const queryObject = activeScreen.keys.split(',').reduce((acc:any, item:any) => (val[item] !== undefined ? { ...acc, [item]: `'${val[item]?.value}'` } : { ...acc }), {});
      // const queryObjectEdit = activeScreen.keys.split(',').reduce((acc:any, item:any) => (val[item] !== undefined ? { ...acc, [item]: `${val[item]?.value}` } : { ...acc }), {});
      const queryObject = activeScreen.keys.split(',').reduce((acc:any, item:any) => ({ ...acc, [item]: `'${val[item].value}'` }), {});
      const queryObjectEdit = activeScreen.keys.split(',').reduce((acc:any, item:any) => ({ ...acc, [item]: `${val[item].value}` }), {});
      setdeleteQuery([...deleteQuery, queryObjectEdit]);
      setSelectedBatch([...selectedBatch, queryObject]);
      setSelectedEditBatch([...selectedEditBatch, queryObjectEdit]);
      return true;
    });

    setSelected(newSelected);
    if (!BatchMode) {
      (async () => {
        await dispatch(SetRecordsListEmbedded(recordsList));
        await dispatch(LoadRecordEmbbeded({
          tableID: tableOpt.tableID,
          screenID: activeScreen.screenId,
          where: JSON.stringify(activeScreen.keys.split(',').reduce((acc:any, item:any) => ({ ...acc, [item]: newSelected[0][item]?.value }), {})),
          routeDetails: backScreen,
          modifyDep: activeScreen.keys.split(',').reduce((acc:any, item:any) => ({ ...acc, [item]: newSelected[0][item]?.value }), {}),
          recordsList,
        }));

        // history(`${routeDetails}`,
        //   {
        //     state:
        //      {
        //        tableID: tableOpt.tableID,
        //        screenID: activeScreen.screenId,
        //        where: JSON.stringify(activeScreen.keys.split(',').reduce((acc:any, item:any) => ({ ...acc, [item]: newSelected[0][item]?.value }), {})),
        //        routeDetails: backScreen,
        //        modifyDep: activeScreen.keys.split(',').reduce((acc:any, item:any) => ({ ...acc, [item]: newSelected[0][item]?.value }), {}),
        //        recordsList,
        //      },
        //   });
        await dispatch(SwitchEmbeddedNavigation('details'));
      })();
    }
  };
  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const handleChange = async (event:any) => {
    setRowsPerPage(event.target.value as number);
    const maxValueForPage = Math.ceil(pages / event.target.value as number);
    await dispatch(StartLoading());
    await dispatch(
      LoadEmbbededTables({
        tableID: tableOpt.tableID,
        page: Math.max(1, maxValueForPage < currentPage ? maxValueForPage : currentPage),
        results_per_page: event.target.value,
        fields: tableOpt.fields,
        where: activeScreen.where,
        order_by: '',
        keys: activeScreen.keys,
        EmptyRows: activeScreen.EmptyRows,
        screenId: activeScreen.screenId,
        lang: Language,
      }),
    );
    await dispatch(StopLoading());

    setCurrentPage(Math.max(1, maxValueForPage < currentPage ? maxValueForPage : currentPage));
  };
  const handleCloseConfirm = () => {
    setVisible(!Visible);
  };
  const handleBatchModeRdx = async () => {
    // history(`${BatchModeScreen}`,
    //   {
    //     state:
    //                  {
    //                    tableID: tableOpt.tableID,
    //                    screenID,
    //                    where: activeScreen.keys.split(',').map((item:any) => `${item}='${selected[0][item]}'`).join(' AND '),
    //                  },
    //   });
    await dispatch(SetBatchModeEmbedded({
      tableOpt: tableOpt.tableID, deleteQuery: deleteQuery.join(' or ').slice(3), selected: selectedBatch, selectedBatch: selectedEditBatch, backScreen,
    }));
    await dispatch(SwitchEmbeddedNavigation('batchEdit'));
  };

  const handleBatchdelete = () => {
    setVisible(true);
    dispatch(SetBatchModeEmbedded({
      tableOpt: tableOpt.tableID, deleteQuery: deleteQuery.join(' or ').slice(3), selected: selectedBatch, selectedBatch: selectedEditBatch, backScreen,
    }));
  };

  const handleChangePage = async (event:any, targetPage:any) => {
    await dispatch(StartLoading());
    await dispatch(
      LoadEmbbededTables({
        tableID: tableOpt.tableID,
        page: targetPage,
        results_per_page: rowsPerPage,
        fields: tableOpt.fields,
        where: activeScreen.where,
        order_by: '',
        redirect: activeScreen.redirect,
        keys: activeScreen.keys,
        EmptyRows: activeScreen.EmptyRows,
        screenId: activeScreen.screenId,
        lang: Language,
      }),
    );
    await dispatch(StopLoading());

    setCurrentPage(targetPage);
  };
  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows?.length) : 0;
  // Load all table columns

  const handleSearch = (search:string) => {
    dispatch(LoadEmbbededTables({
      tableID: activeScreen.tableID,
      page: '1',
      results_per_page: '10',
      fields: activeScreen.fields,
      screenId: activeScreen.screenId,
      where: activeScreen.where,
      order_by: '',
      search,
      redirect: activeScreen.redirect,
      keys: activeScreen.keys,
      lang: Language,
    }));
    setCurrentPage(1);
  };
  console.log('rowsPerPage', rowsPerPage);
  console.log('currentPage', currentPage);
  return (
    <Box sx={{ width: '100%', p: 1 }}>
      {loading && 'loading'}
      <Box sx={{ width: '99%', mb: 2 }}>
        <Box sx={{
          width: '97%', mb: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '5px 0px',
        }}
        >
          <div style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          >
            <TextField
              size="small"
              label="search"
              onChange={(e:React.ChangeEvent<HTMLInputElement>) => handleSearch(e.target.value)}
              // onChange={(e:React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
            />
            <div style={{
              width: '40%',
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 10,
              alignItems: 'center',
            }}
            >
              {BatchMode && (
              <>
                <Button
                  variant="outlined"
                  size="small"
                  color="error"
                  disabled={selected.length < 2}
                  onClick={handleBatchdelete}
                  startIcon={<DeleteIcon />}
                >
                  Delete...
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  color="success"
                  // sx={{
                  //   backgroundColor: '#dddddd',
                  //   borderLeft: '5px solid green',
                  //   borderRight: '1px solid green',
                  //   borderBottom: '1px solid green',
                  //   borderTop: '1px solid green',
                  //   color: '#000',
                  //   borderRadius: '0px',
                  //   fontSize: 12,
                  //   width: '120px',
                  // }}
                  disabled={selected.length < 2}
                  onClick={() => handleBatchModeRdx()}
                  startIcon={<Edit />}
                >
                  Edit...
                </Button>

              </>
              )}
              <FormGroup>
                <FormControlLabel
                  className="batchlabel"
                  labelPlacement="start"
                  control={(
                    <Switch
                      type="checkbox"
                      checked={BatchMode}
                      onChange={(e:any) => setBatchMode(!BatchMode)}
                    />
)}
                  label="Batch Mode"
                />

              </FormGroup>
            </div>

          </div>

        </Box>
        {(searching && Rows?.length !== 0) || (!searching && rows?.length && headCells?.length) ? (
          <TableContainer sx={{ boxShadow: 5 }}>
            <Table
              sx={{ boxShadow: 3, width: tableOpt.tableID === 'TerminalProfile' ? '100%' : '100%' }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                numSelected={selected?.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows?.length}
                headCells={headCells}
                rows={rows}
                BatchMode={BatchMode}
              />

              <TableBody sx={{ boxShadow: 3 }}>

                {stableSort(searching ? Rows : rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: any, index, key:any) => {
                    const isItemSelected = isSelected(row);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event:any) => handleClick(event, row)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={uuidv4() as any}
                        selected={isItemSelected}
                        sx={{
                          backgroundColor: index % 2 ? '#F5F5F5' : 'white',
                          cursor: 'pointer',
                          '&:hover': {
                            background: '#F7EBD0',
                          },
                        }}
                      >
                        {BatchMode && (
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                          />
                        </TableCell>
                        )}

                        {
                             column.map((v:any) => (
                               <TableCell
                                 sx={{
                                   padding: '10px 20px',
                                   border: '2px solid #fff',
                                   fontSize: 12,
                                   width: 'auto',
                                   //  backgroundColor: row[v].search('DATA INTEGRITY FAILURE') === -1 ? 'white' : '#F7EBD0',

                                 }}
                               >
                                 {extractContent(row[v])}
                               </TableCell>
                             ))
     }
                      </TableRow>
                    );
                  })}
                {/* {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
                )} */}
              </TableBody>

            </Table>
          </TableContainer>
        ) : (
          <TableContainer sx={{ boxShadow: 5, width: '100%' }}>
            <Table
              sx={{ minWidth: 750, boxShadow: 3 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                headCells={headCells}
                rows={rows}
                BatchMode={BatchMode}
              />
              <div style={{ width: '100%' }}><Typography style={{ marginLeft: 10 }}>No matching records found</Typography></div>
            </Table>
          </TableContainer>
        )}
        <div style={{
          width: '100%', display: 'flex', justifyContent: 'space-between', margin: 10,
        }}
        >
          {/* {!searching && rows.length
            ? (
              <Typography fontFamily="Open Sans" fontSize={12} fontWeight="400">
                Showing 1 to
                {' '}
                {rows.length * (page + 1)}
                {' '}
                of
                {' '}
                {pages}
                {' '}
                entries
              </Typography>
            )
            : searching && Rows.length
              ? (
                <Typography fontFamily="Open Sans" fontSize={12} fontWeight="400">
                  Showing 1 to
                  {' '}
                  {Rows.length * (page + 1)}
                  {' '}
                  of
                  {' '}
                  {Rows.length}
                  {' '}
                  entries
                </Typography>
              )
              : <Typography>No data available</Typography>} */}
          <div style={{
            minWidth: '30%',
            gap: 15,
            display: 'flex',
            alignItems: 'center',
          }}
          >
            <Typography className="f12">
              Items per page:
            </Typography>
            <Select
              size="small"
              variant="standard"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={rowsPerPage}
              label="Rows"
              onChange={handleChange}
              style={{ width: 70 }}
            >
              <MenuItem className="f12" value={10}>auto</MenuItem>
              <MenuItem className="f12" value={15}>15</MenuItem>
              <MenuItem className="f12" value={20}>20</MenuItem>
              <MenuItem className="f12" value={25}>25</MenuItem>
              <MenuItem className="f12" value={35}>35</MenuItem>
              <MenuItem className="f12" value={50}>50</MenuItem>
              <MenuItem className="f12" value={data.totalItems || 100}>all</MenuItem>
            </Select>
            {(data.data.length) ? <Typography variant="caption">{`Showing ${currentPage === 1 ? currentPage : Number(rowsPerPage) * Number(currentPage - 1)} to ${currentPage === 1 ? rows.length : rowsPerPage * currentPage - (rowsPerPage - rows.length)} of ${data.totalItems} entries`}</Typography> : <Typography variant="caption">No records available</Typography> }
          </div>
          <Pagination count={searching ? 0 : Math.ceil(pages / rowsPerPage)} onChange={handleChangePage} page={currentPage} variant="outlined" shape="rounded" style={{ alignSelf: 'flex-end' }} />
        </div>

      </Box>
      <ColumnsModalEmbedded />
      <TransitionsModalEmbedded />
      <ConfirmationModalEmbedded Visible={Visible} handleCloseConfirm={handleCloseConfirm} options={deleteQuery} isbatch={BatchMode} tableId={tableOpt.tableID} setBatchMode={setBatchMode} />
    </Box>
  );
};
export default EmbeddedEnhancedTable;
