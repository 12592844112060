/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useState } from 'react';

// Librairies
import { useDispatch, useSelector } from 'react-redux';
import { ButtonBase, Divider } from '@mui/material';

// Components
import SubHeader from '../../../components/subHeader';
import TmsHeader from '../../../components/header';
import ConfirmFormForm from './ConfirmForm';
import FieldAttributes from './FieldAttributes';
import GroupScreenForm from './GroupScreenForm';

// Styles
import { useStyles } from './style';
import HorizontalStepper from '../../../components/HorizontalStepper';

// Actions
import { getFieldAttributesGroupScreen } from '../../../redux/actions/workflows/fieldAttributes';

// Utils
const steps = [
  { description: 'Choose Group / Screen', title: 'Step-1' },
  { description: 'Update Field Attributes', title: 'Step-2' },
  { description: 'Finish / Confirm', title: 'Step-3' },
];

const FieldAttributeSettingsScreen: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [screenState, setScreenState] = useState(() => ({
    currentStep: 0,
    groupScreen: {
      uigroupid: '',
      uiscreenid: '',
    },
  }));
  const { groupsScreensFields } = useSelector(
    (state: any) => state.Workflows.FieldAttributes,
  );

  useEffect(() => {
    dispatch(getFieldAttributesGroupScreen());
  }, []);

  const handleSelectStep = (index: number) => {
    setScreenState({ ...screenState, currentStep: index });
  };

  const handleNextStep = () => {
    setScreenState({
      ...screenState,
      currentStep: screenState.currentStep + 1,
    });
  };

  const handlePrevStep = () => {
    setScreenState({
      ...screenState,
      currentStep: screenState.currentStep - 1,
    });
  };

  const handleGroupScreenFieldChange = (name: string, option: any) => {
    setScreenState({
      ...screenState,
      groupScreen: {
        ...screenState.groupScreen,
        [name]: option,
      },
    });
  };

  return (
    <>
      <TmsHeader />
      <SubHeader title="Field Attribute Settings" />
      <div className="overFlowXAuto overFlowYAuto takeTheRest">
        <HorizontalStepper
          steps={steps}
          current={screenState.currentStep}
          selectStep={handleSelectStep}
        />
        <div className="fullWidth flexRow">
          <span className="takeTheRest" />
          <ButtonBase
            className={classes.stepButton}
            disabled={screenState.currentStep === 0}
            onClick={handlePrevStep}
          >
            Prev Step
          </ButtonBase>
          <ButtonBase
            className={
            screenState.currentStep === 2
              ? classes.submitButton
              : classes.stepButton
          }
            onClick={handleNextStep}
          >
            {screenState.currentStep === 2 ? 'Submit' : 'Next Step'}
          </ButtonBase>
        </div>
        <Divider style={{ margin: '5px 0px' }} variant="middle" />
        <DisplayCurrentStepAction
          currentStep={screenState.currentStep}
          groupsScreensFields={groupsScreensFields}
          groupScreenState={screenState.groupScreen}
          handleGroupScreenFieldChange={handleGroupScreenFieldChange}
        />
      </div>
    </>
  );
};

interface IDisplayCurrentStepAction {
  currentStep: number;
  groupsScreensFields: any[];
  groupScreenState: { [key: string]: any };
  handleGroupScreenFieldChange: (name: string, option: any) => void;
}

const DisplayCurrentStepAction: React.FC<IDisplayCurrentStepAction> = ({
  currentStep,
  groupsScreensFields,
  groupScreenState,
  handleGroupScreenFieldChange,
}) => {
  switch (currentStep) {
    case 0: {
      return (
        <GroupScreenForm
          groupsScreensFields={groupsScreensFields}
          groupScreenState={groupScreenState}
          handleGroupScreenFieldChange={handleGroupScreenFieldChange}
        />
      );
    }
    case 1: {
      return <FieldAttributes groupScreenState={groupScreenState} />;
    }
    case 2: {
      return <ConfirmFormForm state={{}} />;
    }
    default:
      return null;
  }
};

export default FieldAttributeSettingsScreen;
