/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Close, FilterAlt } from '@mui/icons-material';
// Libraries
import clsx from 'clsx';

// Styles
import Typography from '@mui/material/Typography';
import { Tooltip } from '@mui/material';
import { useDispatch } from 'react-redux';
import {
  getOrderMaintenanceData,
  showFilterText,
} from '../../redux/actions/workflows/orderMaintenance';
import { useStyles } from './style';

interface IProps {
  title: string;
  stepValues?: any;
  Step?: any;
  menuOptions?:string;
}

const SystemStartupOptions: React.FC<IProps> = ({
  title, children, stepValues, Step, menuOptions,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const closeFilter = () => {
    dispatch(getOrderMaintenanceData());
    dispatch(showFilterText(''));
  };
  const manageAddAuthHeader = () => {
    if (stepValues?.acct_no?.value) {
      return ` Account: [${stepValues?.acct_no?.label}]`;
    } if (stepValues?.cust_no?.value) {
      return ` Customer: [${stepValues?.cust_no?.label}]`;
    } if (stepValues?.supplier_no?.value) {
      return ` Supplier: [${stepValues?.supplier_no?.label}]`;
    } return '';
  };
  const getTooltipAuthHeader = () => {
    let str = '';
    if (stepValues?.acct_no?.value) {
      str += ` Account: [${stepValues?.acct_no?.label}]`;
    } if (stepValues?.cust_no?.value) {
      str += ` Customer: [${stepValues?.cust_no?.label}]`;
    } if (stepValues?.supplier_no?.value) {
      str += ` Supplier: [${stepValues?.supplier_no?.label}]`;
    } return str;
  };
  return (
    <div className={clsx(classes.header, 'flexStartSpaceBetweenRow')}>
      <div className={classes.title}>
        <Typography variant="h5">
          {' '}
          {title}
          {' '}
        </Typography>
        {menuOptions && (
        <div
          style={{
            width: 'auto',
            background: '#fff',
            color: '#0473bd',
            display: 'flex',
            alignItems: 'center',
            marginLeft: 5,
            flexWrap: 'nowrap',
          }}
        >
          <FilterAlt />
          <Typography>{menuOptions}</Typography>
          <Close onClick={closeFilter} />
        </div>
        )}
        {Step > 1 && (
        <Tooltip title={getTooltipAuthHeader()} placement="bottom-end" sx={{ width: 'auto', whiteSpace: 'nowrap' }}>
          <Typography variant="h6">
            {manageAddAuthHeader()}
          </Typography>
        </Tooltip>
        )}
      </div>
      <div className="flexRowAlignCenter">
        {children}
        <span className={classes.spacer} />
      </div>
    </div>
  );
};

export default SystemStartupOptions;
