import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {},
  closeButton: {
    color: 'black !important',
    textShadow: 'none !important',
    backgroundColor: '#00C851 !important',
    border: '1px solid transparent !important',
    padding: '6px 10px !important',
    marginRight: '15px !important',
  },
  label: {
    paddingTop: 5,
    paddingRight: 5,
    fontSize: 12,
    fontWeight: 400,
    width: '25%',
    textAlign: 'end',
  },
  textArea: {
    resize: 'vertical',
    height: '300px !important',
    width: '75% !important',
    margin: '2px 10px !important',
  },
  wrapper: { padding: '5px 10px 5px 10px' },
  detailUpperTable: {
    marginTop: '10px',
    width: '100%',
    borderCollapse: 'collapse',
  },
  upperTableTh: {
    fontWeight: '400',
    color: 'white',
    backgroundColor: '#2f2f2f',
    borderBottom: '1px solid #6678b1',
    padding: '2px 8px',
    whiteSpace: 'nowrap',
    fontSize: 12,
  },
  upperTableTd: {
    backgroundColor: '#eeeeee',
    padding: '4px 8px 3px 8px',
    whiteSpace: 'nowrap',
    fontSize: 12,
  },
  detailTable: {
    marginTop: '10px',
    width: '100%',
    borderCollapse: 'collapse',
  },
  detailTableTh: {
    fontWeight: '400',
    color: 'white',
    backgroundColor: '#595959',
    borderBottom: '1px solid #6678b1',
    padding: '2px 8px',
    whiteSpace: 'nowrap',
    fontSize: 12,
  },
  detailTableTd: {
    border: '1px solid #efefef',
    backgroundColor: '#f1f8ff',
    padding: '4px 8px 3px 8px',
    fontSize: 12,
  },
  innerTable: {
    marginTop: '10px',
    marginLeft: '100px',
    width: '80%',
    borderCollapse: 'collapse',
    whiteSpace: 'nowrap',
  },
  innerTableTh: {
    fontSize: '85%',
    fontWeight: '100',
    color: 'black',
    backgroundColor: '#d7d7d7',
    borderBottom: '1px solid #cdd3e4',
    padding: '2px 8px',
    whiteSpace: 'nowrap',
  },
  innerTableTd: {
    whiteSpace: 'nowrap',
    fontSize: '85%',
    fontWeight: '100',
  },
  gridWrapper: {
    margin: '0px !important',
    padding: '0px !important',
    marginBottom: '5px !important',
  },
  regenerateButton: {
    color: 'white !important',
    textShadow: 'none !important',
    backgroundColor: '#852b99 !important',
    border: '1px solid transparent !important',
    padding: '6px 10px !important',
    marginRight: '15px !important',
    borderWidth: '1px 1px 1px 5px !important',
    borderColor: '#ffb848 !important',
  },
  editTableTh: {
    fontSize: '85%',
    fontWeight: '400',
    color: '#039',
    borderBottom: '1px solid #6678b1',
    padding: '2px 8px',
    whiteSpace: 'nowrap',
  },
});
