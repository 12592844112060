import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {},
  cancelButton: {
    color: 'black !important',
    textShadow: 'none !important',
    backgroundColor: '#ffbb33 !important',
    padding: '4px 10px !important',
    marginLeft: '10px !important',
    border: '1px solid transparent',
    '&:hover': {
      backgroundColor: '#FF8800 !important',
      color: 'white !important',
    },
  },
  confirmButton: {
    verticalAlign: 'middle',
    textAlign: 'center',
    backgroundColor: '#00C851 !important',
    color: 'black !important',
    padding: '4px 10px !important',
    border: '1px solid transparent',
    '&:hover': {
      backgroundColor: '#007E33 !important',
      color: 'white !important',
    },
  },
  childrenWrapper: {
    marginTop: 15,
  },
  helperText: {
    color: 'red',
    fontSize: 12,
    marginLeft: 10,
  },
});
