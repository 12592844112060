import React, { useState } from 'react';

// Librairies
import { ButtonBase } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

// Components
import SubHeader from '../../../components/subHeader';
import TmsHeader from '../../../components/header';
import OptionsButton from '../../../components/optionsButton';
import SingleItemOptions from '../../../components/SingleItemOptions';
import Select from '../../../components/Select';
import ConfirmationDialog from '../../../components/ConfirmDialog';
import Checkbox from '../../../components/Checkbox';
import ColumnsDialog from '../../../components/ColumnsDialog';
import Loader from '../../../components/Loader';
import WorkflowTable from '../../../components/WorkflowTable';

// Actions
import {
  getFolioFreezeCloseData,
  getNewFolioFreezeCloseColumns,
} from '../../../redux/actions/workflows/folioFreezeClose';

// Styles
import { useStyles } from './style';

// hooks
import { useWorkflowState } from '../../../hooks/useWorkflowState';
import WorkflowTableUtils from '../../../components/WorkflowTableUtils';

const tableHeader = {
  folioMonth: 'Folio Month',
  folNumber: 'Folio Number',
  folioStatus: 'Folio Status',
  openDate: 'Open Date',
  freezeDate: 'Freeze Date',
  freezeCloseStateDescr: 'Freeze/Close State',
  statusDate: 'Status Date',
  transmitDate: 'Transmit Date',
  petroexDate: 'Petroex Transmit Date',
  lastloadDate: 'Last Load Date',
  transSeqNo: 'Sequence No',
  statusUser: 'Status User',
  activity: 'Activity',
  folioTransmitStatus: 'Transmit Status',
  petroexTransmitStatus: 'Petroex Status',
};

const searchHeader = [
  { label: 'Folio Month', key: 'folioMonth' },
  { label: 'Folio Number', key: 'folNumber' },
  { label: 'Folio Status', key: 'folioStatus' },
  { label: 'Open Date', key: 'openDate' },
  { label: 'Freeze Date', key: 'freezeDate' },
  { label: 'Freeze/Close State', key: 'freezeCloseStateDescr' },
  { label: 'Status Date', key: 'statusDate' },
  { label: 'Transmit Date', key: 'transmitDate' },
  { label: 'Petroex Transmit Date', key: 'petroexDate' },
  { label: 'Last Load Date', key: 'lastloadDate' },
  { label: 'Sequence No', key: 'transSeqNo' },
  { label: 'Status User', key: 'statusUser' },
  { label: 'Activity', key: 'activity' },
  { label: 'Transmit Status', key: 'folioTransmitStatus' },
  { label: 'Petroex Status', key: 'petroexTransmitStatus' },
];

export interface ITableState {
  tableData: any[];
  currentPage: number;
  searchedDataCount: number;
  searchText: string;
  recordsNumber: string;
  orderBy: string,
  order: string,
}

const FolioFreezeClose: React.FC = () => {
  const classes = useStyles();
  const [freezeConfirmDialogOpen, setFreezeConfirmDialogOpen] = useState<boolean>(false);
  const [closeFolioConfirmDialogOpen, setCloseFolioConfirmDialogOpen] = useState<boolean>(false);
  const [columnsDialogOpen, setColumnsDialogOpen] = useState<boolean>(false);
  const [closeChecked, setCloseChecked] = useState<boolean>(false);
  const { data, visibleColumns, hiddenColumns } = useSelector(
    (state: any) => state.Workflows?.FolioFreezeClose,
  );
  const dispatch = useDispatch();
  const {
    recordsToShow,
    tableState,
    handlePaginationChange,
    handleSearchChange,
    handleSortTable,
    handleOptionClick,
    handleCloseMenu,
    handleSelectChange,
  } = useWorkflowState(searchHeader, data, getFolioFreezeCloseData);

  const handleOpenFreezeConfirmDialog = () => {
    setFreezeConfirmDialogOpen(true);
    handleCloseMenu();
  };

  const handleCloseFreezeConfirmDialog = () => {
    setFreezeConfirmDialogOpen(false);
  };

  const handleOpenCloseFolioConfirmDialog = () => {
    setCloseFolioConfirmDialogOpen(true);
    handleCloseMenu();
  };

  const handleCloseCloseFolioConfirmDialog = () => {
    setCloseFolioConfirmDialogOpen(false);
  };

  const handleCheckBoxChange = () => {
    setCloseChecked(!closeChecked);
  };

  const handleOpenColumnsDialog = () => {
    setColumnsDialogOpen(true);
    handleCloseMenu();
  };

  const handleCloseColumnsDialog = () => {
    setColumnsDialogOpen(false);
  };

  const handleSubmitNewColumns = (newColumns: string[]) => {
    dispatch(getNewFolioFreezeCloseColumns(newColumns));
  };

  const handleRestoreDefault = () => {
    dispatch(
      getNewFolioFreezeCloseColumns([
        'folioMonth',
        'folNumber',
        'folioStatus',
        'openDate',
        'freezeDate',
        'freezeCloseStateDescr',
        'statusDate',
        'transmitDate',
        'petroexDate',
        'lastloadDate',
        'transSeqNo',
        'statusUser',
        'activity',
        'folioTransmitStatus',
        'petroexTransmitStatus',
      ]),
    );
  };

  return (
    <>
      <TmsHeader />
      <SubHeader title="Folio Freeze/Close">
        <div className="flexRow" style={{ gap: 5 }}>
          <Loader getWorkflowData={getFolioFreezeCloseData} />
          <OptionsButton handleClick={handleOptionClick} />
        </div>
        <SingleItemOptions
          anchorEl={tableState.anchorEl}
          onClose={handleCloseMenu}
          openDialog={handleOpenColumnsDialog}
          itemLabel="Columns ..."
        />
      </SubHeader>
      <div className={classes.actionsWrapper}>
        <ButtonBase
          className={classes.freezeButton}
          onClick={handleOpenFreezeConfirmDialog}
        >
          Freeze
        </ButtonBase>
        <ButtonBase
          className={classes.closeButton}
          onClick={handleOpenCloseFolioConfirmDialog}
        >
          Close
        </ButtonBase>
      </div>
      <WorkflowTableUtils
        handleSearchChange={handleSearchChange}
        handleSelectChange={handleSelectChange}
        recordsNumber={tableState.recordsNumber}
        searchText={tableState.searchText}
      />
      <WorkflowTable
        handleClickRow={() => null}
        visibleColumns={tableHeader}
        allDataCount={data.length}
        handlePaginationChange={handlePaginationChange}
        recordsToShow={recordsToShow}
        tableHeader={tableHeader}
        tableState={tableState}
        order={tableState.order}
        orderBy={tableState.orderBy}
        searchText={tableState.searchText}
        sortTable={handleSortTable}
      />
      <ConfirmationDialog
        open={freezeConfirmDialogOpen}
        handleCloseAgree={handleCloseFreezeConfirmDialog}
        message="Would you like to Freeze Folio July 001?"
        handleCloseCancel={handleCloseFreezeConfirmDialog}
        title="Freeze Folio"
        cancelLabel="No"
        confirmLabel="Yes"
      />
      <ConfirmationDialog
        open={closeFolioConfirmDialogOpen}
        handleCloseAgree={handleCloseCloseFolioConfirmDialog}
        message="Would you like to Close Folio April 006?"
        handleCloseCancel={handleCloseCloseFolioConfirmDialog}
        title="Close Folio"
        cancelLabel="No"
        confirmLabel="Yes"
      >
        <Checkbox
          label="Close up to"
          checked={closeChecked}
          onChange={() => null}
          onClick={handleCheckBoxChange}
        />
        <Select options={[]} style={{ width: '100%' }} disabled={!closeChecked} />
      </ConfirmationDialog>
      <ColumnsDialog
        title="Configure Visible Columns"
        onClose={handleCloseColumnsDialog}
        open={columnsDialogOpen}
        visibleColumns={visibleColumns}
        hiddenColumns={hiddenColumns}
        columnsLabels={tableHeader}
        submitNewColumns={handleSubmitNewColumns}
        handleRestoreDefault={handleRestoreDefault}
      />
    </>
  );
};

export default FolioFreezeClose;
