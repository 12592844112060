import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  wrapper: {
    padding: '0px 10px',
    marginBottom: 10,
  },
  searchInput: {
    fontWeight: '400 !important',
    fontSize: '12px !important',
    lineHeight: '1.42857143 !important',
    padding: '4px 4px',
    color: '#333333',
    backgroundColor: '#fff',
    border: '1px solid #e5e5e5',
    height: 33,
    width: 200,
  },
  helperText: {
    borderColor: '#e5e5e5',
    lineHeight: '1.42857143',
    fontWeight: 400,
    fontSize: 12,
    padding: '4px 4px',
    background: '#e5e5e5',
    display: 'flex',
    alignItems: 'center',
  },
  subHeaderContainer: {
    padding: '0px 10px',
    marginBottom: 5,
  },
  label: {
    marginRight: 5,
    fontSize: 12,
    fontWeight: 400,
    display: 'flex',
    alignItems: 'center',
  },
  table: {
    marginBottom: 10,
    marginTop: 5,
    overflow: 'hidden !important',
  },
  headerCell: {
    borderBottom: 'none !important',
    padding: '0px !important',
    fontWeight: 'bold !important',
    border: '1px solid #fff',
    backgroundColor: '#fff !important',
    color: '#222 !important',
  },
  headerRow: {
    boxShadow: '0 1px 7px #555',
  },
  bodyCell: {
    borderBottom: 'none !important',
    padding: '8px 0px !important',
  },
  bodyCellEmpty: {
    borderBottom: 'none !important',
    padding: '8px 7px !important',
  },
  list: {
    listStyle: 'none',
    margin: 0,
    display: 'flex',
  },
  listItem: {
    marginRight: 10,
    float: 'left',
    fontSize: 12,
  },
  itemSign: {
    border: '1px solid #ccc',
    float: 'left',
    width: '12px',
    height: '12px',
    margin: '4px',
  },
  TareExceeded: {
    backgroundColor: 'firebrick',
  },
  VoucherFlag: {
    backgroundColor: 'magenta',
  },
  BlendOFS: {
    backgroundColor: 'green',
  },
  EMCS: {
    backgroundColor: 'chocolate',
  },
  spacer: {
    marginTop: 5,
  },
});
