/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { RcuKeyboardHook, useRcuKeyboard } from '../../hooks/useRcuKeyboard';
import { KeyboardComponent } from '../KeyboardComponent/Keyboard';
import RCUDitto from '../RCUDitto/index';
import { useStyles } from './styles';

interface IProps {
  bayId: string;
  bayName: string;
  bodyContent: any;
  index: number;
  rcuKeyboardHook: RcuKeyboardHook;
}

export const RcuCard: React.FC<IProps> = (
  {
    bayId, bayName, bodyContent, index,
    rcuKeyboardHook: { handleOpenKeyboard, keyPress, showingKeyboardIndex },
  },
) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        {bayName}
        <span className={classes.rcuSubTitle}>{bayId}</span>
      </div>
      <div onClick={() => handleOpenKeyboard(index)}>
        <pre id={`screen_${bayId}`} className="rcuscreen">
          {
            bodyContent && bodyContent.split('\n').map((line: string) => (
              <>
                <span dangerouslySetInnerHTML={{ __html: line }} />
                <br />
              </>
            ))
          }
        </pre>
      </div>
      <div className={classes.keypadContainer}>
        {(showingKeyboardIndex === index) && <KeyboardComponent keyPress={keyPress} />}
      </div>
    </div>
  );
};
