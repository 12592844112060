import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  expandIconStyle: {
    border: 'dashed 1px lightgray',
    backgroundColor: 'white',
  },
  listItemButtonStyle: {
    background: '#CFDCE6',
    '&:hover': {
      backgroundColor: '#CFDCE6',
    },
  },
  listItem: {
    padding: 0,
  },
  nestedListWrapper: {
    paddingLeft: 24,
  },
  removeIconStyle: {
    color: 'gray',
  },
  listItemGroup: {
    border: 'dashed 1px lightgray', marginBottom: '8px',
  },
  listItemEntry: {
    border: 'inherit', marginBottom: '8px',
  },
});
