import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Footer from '../../../components/Footer/Footer';
import TmsHeader from '../../../components/header/index';
import SubHeader from '../../../components/subHeader/index';
import { getAboutScreenData } from '../../../redux/actions/workflows/aboutPage';
import { AboutOptionsMenu } from './aboutOptionsMenu';
import { ReleaseNotesDialog } from './releaseNotesDialog';
import { ServerProductVersion } from './serverProductVersion';
import { UpgradeHistory } from './upgradeHistory';

interface UserState {
  showReleaseNotesDialog: boolean;
}

const initialState: UserState = {
  showReleaseNotesDialog: false,
};

export default () => {
  const [userState, setUserState] = React.useState<UserState>(initialState);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAboutScreenData());
  }, [dispatch]);
  const handleReleaseNotesSelected: VoidFunction = () => {
    setUserState((prevState) => ({ ...prevState, showReleaseNotesDialog: true }));
  };
  const handleReleaseNotesDialogClosed: VoidFunction = () => {
    setUserState((prevState) => ({ ...prevState, showReleaseNotesDialog: false }));
  };

  return (
    <>
      <TmsHeader />
      <SubHeader title="About">
        <AboutOptionsMenu onReleaseNotesSelected={handleReleaseNotesSelected} />
      </SubHeader>
      <ServerProductVersion />
      <UpgradeHistory />
      <ReleaseNotesDialog
        onClose={handleReleaseNotesDialogClosed}
        open={userState.showReleaseNotesDialog}
      />
      <span className="takeTheRest" />
      <Footer />
    </>
  );
};
