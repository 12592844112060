import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    padding: '0px 10px',
  },
  tableRoot: {
  },
  headerCell: {
    borderBottom: 'none !important',
    padding: '0px !important',
  },
  childHeaderCell: {
    fontWeight: 'bold !important',
    borderBottom: 'none !important',
    padding: '0px !important',
    paddingLeft: '35px !important',

  },

  headerRow: {
    boxShadow: '0 4px 6px -3px #555',
  },
  bodyCell: {
    borderBottom: 'none !important',
    padding: '8px 0px !important',
  },
  childBodyCell: {
    borderBottom: 'none !important',
    padding: '8px 0px !important',
    paddingLeft: '35px !important',
  },
  actionButton: {
    border: 'solid 1px #ddd !important',
    padding: '0px 1px !important',
    marginLeft: '5px',
    '&:hover': {
      backgroundColor: '#e6e6e6 !important',
      borderColor: '#adadad !important',
    },
  },
  bodyCellEmpty: {
    borderBottom: 'none !important',
    padding: '8px 7px !important',
  },
  tableRow: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#fbf8e9 !important',
    },
  },
  subHeaderContainer: {
    padding: '0px 10px',
    marginBottom: 5,
  },
  label: {
    marginRight: 5,
    fontSize: 12,
    fontWeight: 400,
    display: 'flex',
    alignItems: 'center',
  },
  searchInput: {
    fontWeight: '400 !important',
    fontSize: '12px !important',
    lineHeight: '1.42857143 !important',
    padding: '4px 4px',
    color: '#333333',
    backgroundColor: '#fff',
    border: '1px solid #e5e5e5',
    height: 33,
    width: 200,
  },
  footerWrapper: { marginTop: 5 },
  showingText: { fontSize: 12, fontWeight: 400, color: 'black' },
  paginationContainer: {
    marginTop: 5,
  },
  batch: {
    backgroundColor: '#ddd !important',
    border: '1px solid black',
    borderLeft: '5px solid black',
  },
  rowBg: {
    backgroundColor: '#b0bed9 !important',
    '&:hover': {
      backgroundColor: '#6783bc !important',
    },
  },
  sortLabel: {
    display: 'flex !important',
    justifyContent: 'space-between !important',
  },
  modifySearchButton: {
    borderWidth: '1px 1px 1px 5px !important',
    borderColor: 'black !important',
    position: 'relative',
    backgroundColor: '#ddd !important',
    outline: 'none !important',
    border: '1px solid black !important',
    padding: '4px 10px !important',
    color: 'black !important',
    marginBottom: 5,
    '&:before': {
      content: '""',
      position: 'absolute',
      left: -5,
      width: 5,
      height: '100%',
    },
    '&:hover': {
      color: '#fff !important',
      backgroundColor: '#545454 !important',
    },
  },
  batchActionButton: {
    borderWidth: '1px 1px 1px 5px !important',
    borderColor: 'black !important',
    position: 'relative',
    backgroundColor: '#ddd !important',
    outline: 'none !important',
    border: '1px solid black !important',
    padding: '4px 10px !important',
    marginLeft: '10px !important',
    color: 'black !important',
    marginBottom: 5,
    '&:before': {
      content: '""',
      position: 'absolute',
      left: -5,
      width: 5,
      height: '100%',
    },
    '&:hover': {
      color: '#fff !important',
      backgroundColor: '#545454 !important',
    },
  },
  batchModifyBtnClr: {
    borderWidth: '1px 1px 1px 5px !important',
    borderColor: 'green !important',
  },
  batchRemoveBtnClr: {
    borderWidth: '1px 1px 1px 5px !important',
    borderColor: 'red !important',
  },
  productHeader: {
    marginTop: '5px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  addDetailButton: {
    border: '1px solid black !important',
    borderWidth: '1px 1px 1px 5px !important',
    borderColor: 'green !important',
    padding: '4px 10px !important',
  },
  productId: {
    width: '15%',
  },
  w5: {
    width: '5%',
  },
  w40: {
    width: '40%',
  },
  w20: {
    width: '20%',
  },
  w10: {
    width: '10%',
  },
  Returned: {
    width: '300px',
    marginRight: '15px',
  },
  OrderItemSection: {
    overflow: 'auto',
    height: '19rem',
  },
  clearIcon: {
    float: 'right',

  },
  displayExtra: {
    display: 'none',
  },
});
