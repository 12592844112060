import React from 'react';

// Librairies
import { ButtonBase, Grid } from '@mui/material';
import { Box } from '@mui/system';
import clsx from 'clsx';

// Components
import Input from '../Input';
import Select from '../Select';

// Styles
import { useStyles } from './style';

const CancelRebill = () => {
  const classes = useStyles();
  return (
    <div>
      <div style={{ height: '5vh' }} />
      <div>
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            classes={{ root: clsx(classes.gridWrapper, 'fullWidth') }}
            container
            spacing={2}
            columns={16}
          >
            <Grid
              classes={{
                root: clsx(classes.gridWrapper, 'flexRowAlignCenter'),
              }}
              item
              sm={16}
              md={8}
            >
              <div className={classes.label}>Cancel / Rebill Mode</div>
              <Select options={[]} onChange={() => null} width="100%" required />
            </Grid>
            <Grid
              classes={{
                root: clsx(classes.gridWrapper, 'flexRowAlignCenter'),
              }}
              item
              sm={16}
              md={8}
            >
              <div className={classes.label}>Route Code</div>
              <Select options={[]} onChange={() => null} width="100%" required />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            classes={{ root: clsx(classes.gridWrapper, 'fullWidth') }}
            container
            spacing={2}
            columns={16}
          >
            <Grid
              classes={{
                root: clsx(classes.gridWrapper, 'flexRowAlignCenter'),
              }}
              item
              sm={16}
              md={16}
            >
              <div className={classes.label}>Cancel Comment #1</div>
              <Input onChange={() => null} style={{ width: '100%' }} />
            </Grid>
            <Grid
              classes={{
                root: clsx(classes.gridWrapper, 'flexRowAlignCenter'),
              }}
              item
              sm={16}
              md={16}
            >
              <div className={classes.label}>Cancel Comment #2</div>
              <Input onChange={() => null} style={{ width: '100%' }} />
            </Grid>
          </Grid>
        </Box>
        <div className="flexRow">
          <span className="takeTheRest" />
          <ButtonBase className={classes.closeButton}> Begin Cancel / Rebill </ButtonBase>
        </div>
      </div>
    </div>
  );
};

export default CancelRebill;
