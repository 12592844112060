/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Autocomplete,
  Button,
  Checkbox,
  Divider,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Close, Settings } from '@mui/icons-material';
import FormControl from '@mui/material/FormControl';
import { useStyles } from './style';
import CustomInput from '../bulkTransactionCustomInput/customInputsRecords';

function pad(n: any) {
  return n !== -10 ? `0${n}` : n;
}
function splitdash(str: any) {
  let output = str;
  let aux = [];
  if (str?.search('-') !== -1) {
    aux = str?.split('-');
    output = aux?.[aux?.length - 1];
  }
  return { name: output, prefix: str?.split('-')[0] };
}
const FormatList = (list:any) => {
  const options :any = Object.entries(list)?.map((val: any) => ({
    value: val[0],
    label: val[1].replaceAll('&nbsp;', ''),
  }));
  return options;
};

const setOptionFormat = (stepData: any) =>
  (stepData || [{ value: '', label: 'No Data Available' }]);
const GaugeTransactionTankStep = ({
  productsData,
  values,
  handleInputChange,
  handleSelectChange,
  valuesToSend,
  stateValues,
  handleInputProductChange,
  screenID,
  visible,
  prefixProducts,
  resetPrefixProducts,
  prefix,
  product,
  prefixFromSupplier,
  removeFormFields,
  itemKey,
  products,
  review,
  transactionMode,
}: any) => {
  const classes = useStyles();
  const [productsList, setProductsList] = useState<any>(products);
  const [Loading, setLoading] = useState(true);
  const [FirstRow, setFirstRow] = useState<any>([]);
  const [SecondRow, setSecondRow] = useState<any>([]);
  const [ThirdRow, setThirdRow] = useState<any>([]);
  const selectProducts = async () => {
    try {
      setLoading(true);
      const response: any = await axios.post(
        `${process.env.REACT_APP_API_URL}synajax/procajax`,
        {
          date: 1675889552524,
          queueid: 'TTG',
          reqid: 'id-k7ugkt1yc7',
          data: {
            cmd: 'transtankgauge',
            termid: valuesToSend?.term_id,
            carrier: valuesToSend?.carrier,
            supplier: valuesToSend?.supplier_no,
            customer: valuesToSend?.cust_no,
            account: valuesToSend?.acct_no,
            destination: valuesToSend?.destination_no,
            trans_id: valuesToSend?.trans_id,
            uom: valuesToSend?.carrier,
            baseUOMList: 'B:G,C:L,G:G,I:L,K:K,L:L,MT:K,P:P,T:P',
            prefix: itemKey,
            newdetail: 'n',
            gaugefieldlist: null,
            tankgroup: valuesToSend?.[itemKey]?.tank_id,
            tankid: valuesToSend?.[itemKey]?.tank_id,
            mtmode: 'gauge',
            group_id: 'Admin',
            screen_id: 'WORKFLOW-039',
          },
          tagid: null,
          jqxhr: null,
        },
      );
      setFirstRow(response.data?.[0]?.genGaugeDetail?.fieldRec);
      setSecondRow(response.data?.[0]?.genGaugeDetail?.genGaugeRangeDetail?.openingRangeDetail);
      setThirdRow(response.data?.[0]?.genGaugeDetail?.genGaugeRangeDetail?.closingRangDetail);
      setLoading(false);
    } catch (e: any) {
      console.log('error', e);
    }
  };
  useEffect(() => {
    selectProducts();
  }, [valuesToSend?.[itemKey]?.tank_id]);
  return Loading ? <Typography>Loading... </Typography> : itemKey ? (
    <div className={classes.container}>
      <div className={classes.tableContainer}>
        <Typography fontFamily="inherit" color="#6172A7">
          Products
        </Typography>
        <Close onClick={() => removeFormFields(itemKey)} />
      </div>
      <Divider sx={{ color: 'blue' }} />
      <div className={classes.innerContainer}>
        <div className={classes.subContainer}>
          <Grid item xs={2} sm={4} md={4} className={classes.product}>
            <Typography
              fontSize="12px"
              fontFamily="inherit"
              sx={{
                borderBottom: '1px solid #6172A7',
                padding: '0px 5px',
                whiteSpace: 'nowrap',
                color: '#003399',
              }}
            >
              Tank
            </Typography>
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-end',
            }}
            >
              <Settings fontSize="small" style={{ border: '1px solid #ADADAD' }} />
              <Select
                id="demo-simple-select"
                labelId="demo-simple-select-label"
                value={
                      FirstRow?.tank_id?.[0]?.selected
                          }
                name="tank_id"
                disabled={review}
                          // label={stepData?.fieldID}
                onChange={(e: any) => handleInputProductChange('tank_id', e, itemKey)}
                size="small"
                sx={{
                // product id field is wider than the other fields so we need to specify the condition for width
                  width: '90%',
                  margin: '0px 5px',
                  borderRadius: '0px',
                  marginTop: '10px',
                  '& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select':
                              {
                                padding: '0px 8px',
                                border: '1px solid #E4E4E4',
                              },
                }}
              >
                {FormatList(FirstRow?.tank_id?.[0]?.list)?.map((item: any) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </Grid>
          <Grid item xs={2} sm={4} md={4} className={classes.product}>
            <Typography
              fontSize="12px"
              fontFamily="inherit"
              sx={{
                borderBottom: '1px solid #6172A7',
                padding: '0px 5px',
                whiteSpace: 'nowrap',
                color: '#003399',
              }}
            >
              Product
            </Typography>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={
                            valuesToSend?.[itemKey]?.prod_id || FirstRow?.prod_id?.value
                          }
              name="prod_id"
              disabled={review || FirstRow?.prod_id?.['data-attr']?.search('readonly') !== -1}
                          // label={stepData?.fieldID}
              onChange={(e: any) => handleInputProductChange('prod_id', e, itemKey)}
              size="small"
              sx={{
                // product id field is wider than the other fields so we need to specify the condition for width
                width: '100%',
                margin: '0px 5px',
                borderRadius: '0px',
                marginTop: '10px',
                '& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select':
                              {
                                padding: '0px 8px',
                                border: '1px solid #E4E4E4',
                              },
              }}
            >
              {setOptionFormat(FirstRow?.prod_id?.options)?.map((item: any) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label.replaceAll('&nbsp;', '')}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={2} sm={4} md={4} className={classes.product}>
            <Typography
              fontSize="12px"
              fontFamily="inherit"
              sx={{
                borderBottom: '1px solid #6172A7',
                padding: '0px 5px',
                whiteSpace: 'nowrap',
                color: '#003399',
              }}
            >
              API Table
            </Typography>
            <div style={{ padding: '0px 0px' }}>
              <CustomInput
                type="input"
                label=""
                val={FirstRow?.api}
                required
                name="api"
                defaultValue=""
                attr={['num']}
                value={
                      prefixProducts?.[`${itemKey}-api`
                      ] || FirstRow?.api?.value
                            }
                isBatch={false}
                onChange={handleInputChange}
                size="100%"
                placeholder=""
                isDisabled={review || FirstRow?.api?.['data-attr']?.search('readonly') !== -1}
                customKey={itemKey}
                transaction
                prefix={itemKey}
                funNamePrefix={`${itemKey}-api`}
              />
            </div>
          </Grid>
          <Grid item xs={2} sm={4} md={4} className={classes.product}>
            <Typography
              fontSize="12px"
              fontFamily="inherit"
              sx={{
                borderBottom: '1px solid #6172A7',
                padding: '0px 5px',
                whiteSpace: 'nowrap',
                color: '#003399',
              }}
            >
              Unit of Measure
            </Typography>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={
                            valuesToSend?.[itemKey]?.uom || FirstRow?.uom?.value
                          }
              name="uom"
              disabled={review || FirstRow?.uom?.['data-attr']?.search('readonly') !== -1}
                          // label={stepData?.fieldID}
              onChange={(e: any) => handleInputProductChange('uom', e, itemKey)}
              size="small"
              sx={{
                // product id field is wider than the other fields so we need to specify the condition for width
                width: '100%',
                margin: '0px 5px',
                borderRadius: '0px',
                marginTop: '10px',
                '& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select':
                              {
                                padding: '0px 8px',
                                border: '1px solid #E4E4E4',
                              },
              }}
            >
              {setOptionFormat(FirstRow?.uom?.options)?.map((item: any) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label.replaceAll('&nbsp;', '')}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={2} sm={4} md={4} className={classes.product}>
            <Typography
              fontFamily="inherit"
              fontSize="12px"
              sx={{
                borderBottom: '1px solid black',
                padding: '0px 5px',
                whiteSpace: 'nowrap',
                color: '#003399',
              }}
            >
              {' Total Gross (Gal)'}
            </Typography>
            <div style={{ padding: '0px 0px' }}>
              <CustomInput
                type="input"
                label=""
                val={FirstRow?.gross}
                name="gross"
                defaultValue=""
                attr={['num']}
                value={
                              prefixProducts?.[`${itemKey}-gross`
                              ] || FirstRow?.gross?.value
                            }
                isBatch={false}
                onChange={handleInputChange}
                size="100%"
                placeholder=""
                isDisabled={review || FirstRow?.gross?.['data-attr']?.search('readonly') !== -1}
                customKey={itemKey}
                transaction
                prefix={itemKey}
                funNamePrefix={`${itemKey}-gross`}
              />
            </div>
          </Grid>
          <Grid item xs={2} sm={4} md={4} className={classes.product}>
            <Typography
              fontFamily="inherit"
              fontSize="12px"
              sx={{
                borderBottom: '1px solid black',
                padding: '0px 5px',
                whiteSpace: 'nowrap',
                color: '#003399',
              }}
            >
              {' Total Net (Gal)'}
            </Typography>
            <div style={{ padding: '0px 0px' }}>
              <CustomInput
                type="input"
                label=""
                val={FirstRow?.net}
                name="net"
                defaultValue=""
                attr={['num']}
                value={
                      prefixProducts?.[`${itemKey}-net`
                      ] || FirstRow?.net?.value
                  }
                isBatch={false}
                onChange={handleInputChange}
                size="100%"
                placeholder=""
                isDisabled={review || FirstRow?.net?.['data-attr']?.search('readonly') !== -1}
                customKey={itemKey}
                transaction
                prefix={itemKey}
                funNamePrefix={`${itemKey}-net`}
              />
            </div>
          </Grid>
          <Grid item xs={2} sm={4} md={4} className={classes.product}>
            <Typography
              fontFamily="inherit"
              fontSize="12px"
              sx={{
                borderBottom: '1px solid black',
                padding: '0px 5px',
                whiteSpace: 'nowrap',
                color: '#003399',
              }}
            >
              {' Throughput Gross (Gal)'}
            </Typography>
            <div style={{ padding: '0px 0px' }}>
              <CustomInput
                type="input"
                label=""
                val={FirstRow?.throughput_gross}
                name="throughput_gross"
                defaultValue=""
                attr={['num']}
                value={
                        prefixProducts?.[`${itemKey}-throughput_gross`]
                       || FirstRow?.throughput_gross?.value
  }
                isBatch={false}
                onChange={handleInputChange}
                size="100%"
                placeholder=""
                isDisabled={review || FirstRow?.throughput_gross?.['data-attr']?.search('readonly') !== -1}
                customKey={itemKey}
                transaction
                prefix={itemKey}
                funNamePrefix={`${itemKey}-throughput_gross`}
              />
            </div>
          </Grid>
          <Grid item xs={2} sm={4} md={4} className={classes.product}>
            <Typography
              fontFamily="inherit"
              fontSize="12px"
              sx={{
                borderBottom: '1px solid black',
                padding: '0px 5px',
                whiteSpace: 'nowrap',
                color: '#003399',
              }}
            >
              {' Throughput Net (Gal)'}
            </Typography>
            <div style={{ padding: '0px 0px' }}>
              <CustomInput
                type="input"
                label=""
                val={FirstRow?.throughput_net}
                name="throughput_net"
                defaultValue=""
                attr={['num']}
                value={
                      prefixProducts?.[`${itemKey}-throughput_net`
                      ] || FirstRow?.vcf_to?.value
                            }
                isBatch={false}
                onChange={handleInputChange}
                size="100%"
                placeholder=""
                isDisabled={review || FirstRow?.throughput_net?.['data-attr']?.search('readonly') !== -1}
                customKey={itemKey}
                transaction
                prefix={itemKey}
                funNamePrefix={`${itemKey}-throughput_net`}
              />
            </div>
          </Grid>
        </div>
      </div>
      <Divider sx={{ color: 'blue' }} />
      <div className={classes.innerContainer}>
        {/* {productsList?.prod_id?.length === 0 && (
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value=""
                  // label={stepData?.fieldID}
                  // onChange={(e: any) => inputHandler({ key, name: stepData?.id, value: e?.target?.value })}
              size="small"
              sx={{
                width: '20%',
                borderRadius: '0px',
                marginTop: '5px',
                '& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select':
                      {
                        padding: '2px 8px',
                        border: '1px solid #E4E4E4',
                      },
              }}
            >
              <MenuItem key={uuidv4()} value="" selected>
                No Data Available
              </MenuItem>
            </Select>
            )} */}
        <div className={classes.secondRowSubContainer}>
          <Grid
            item
            sx={{
              width: '100%',
            }}
          >
            <Typography
              fontSize="12px"
              fontFamily="inherit"
              sx={{
                borderBottom: '1px solid #6172A7',
                whiteSpace: 'nowrap',
                color: '#003399',
              }}
            >
              Date
            </Typography>
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-end',
              width: '100%',
            }}
            >
              <Typography
                fontSize="12px"
                width="20%"
                fontFamily="inherit"
              >
                Opening
              </Typography>
              <CustomInput
                dateType
                type="input"
                label=""
                val={SecondRow?.[`${itemKey}-time_opening`]}
                required
                name={`${itemKey}-time_opening`}
                defaultValue=""
                attr={['num']}
                value={
                              prefixProducts?.[itemKey]?.[
                                SecondRow?.[`${itemKey}-time_opening`]
                              ] || SecondRow?.[`${itemKey}-time_opening`]?.value
                            }
                isBatch={false}
                onChange={handleInputChange}
                size="100%"
                placeholder=""
                isDisabled={false}
                customKey={itemKey}
                transaction
                prefix={prefix}
                funNamePrefix={`${itemKey}-time_opening`}
              />
            </div>
          </Grid>
          <Grid item xs={2} sm={4} md={4} className={classes.product}>
            <Typography
              fontSize="12px"
              fontFamily="inherit"
              sx={{
                borderBottom: '1px solid #6172A7',
                padding: '0px 5px',
                whiteSpace: 'nowrap',
                color: '#003399',
              }}
            >
              Temperature (°F)
            </Typography>
            <CustomInput
              type="input"
              label=""
              val={SecondRow?.[`${itemKey}-temperature_opening`]}
              required
              name={`${itemKey}-temperature_opening`}
              defaultValue=""
              attr={['num']}
              value={
                              prefixProducts?.[itemKey]?.[
                                SecondRow?.[`${itemKey}-temperature_opening`]
                              ] || SecondRow?.[`${itemKey}-temperature_opening`]?.value
                            }
              isBatch={false}
              onChange={handleInputChange}
              size="100%"
              placeholder=""
              isDisabled={false}
              customKey={itemKey}
              transaction
              prefix={prefix}
              funNamePrefix={`${itemKey}-temperature_opening`}
            />
          </Grid>
          <Grid item xs={2} sm={4} md={4} className={classes.product}>
            <Typography
              fontSize="12px"
              fontFamily="inherit"
              sx={{
                borderBottom: '1px solid #6172A7',
                padding: '0px 5px',
                whiteSpace: 'nowrap',
                color: '#003399',
              }}
            >
              Ambient Temperature (°F)
            </Typography>
            <CustomInput
              type="input"
              label=""
              val={SecondRow?.[`${itemKey}-ambient_temperature_opening`]}
              name={`${itemKey}-ambient_temperature_opening`}
              defaultValue=""
              attr={['num']}
              value={
                              prefixProducts?.[itemKey]?.[
                                SecondRow?.[`${itemKey}-ambient_temperature_opening`]
                              ] || SecondRow?.[`${itemKey}-ambient_temperature_opening`]?.value
                            }
              isBatch={false}
              onChange={handleInputChange}
              size="100%"
              placeholder=""
              isDisabled={false}
              customKey={itemKey}
              transaction
              prefix={prefix}
              funNamePrefix={`${itemKey}-ambient_temperature_opening`}
            />
          </Grid>
          <Grid item xs={2} sm={4} md={4} className={classes.product}>
            <Typography
              fontSize="12px"
              fontFamily="inherit"
              sx={{
                borderBottom: '1px solid #6172A7',
                padding: '0px 5px',
                whiteSpace: 'nowrap',
                color: '#003399',
              }}
            >
              Gravity / Density (°API)
            </Typography>
            <CustomInput
              type="input"
              label=""
              val={SecondRow?.[`${itemKey}-grav_density_opening`]}
              name={`${itemKey}-grav_density_opening`}
              defaultValue=""
              attr={['num']}
              value={
                              prefixProducts?.[itemKey]?.[
                                SecondRow?.[`${itemKey}-grav_density_opening`]
                              ] || SecondRow?.[`${itemKey}-grav_density_opening`]?.value
                            }
              isBatch={false}
              onChange={handleInputChange}
              size="100%"
              placeholder=""
              isDisabled={false}
              customKey={itemKey}
              transaction
              prefix={prefix}
              funNamePrefix={`${itemKey}-grav_density_opening`}
            />
          </Grid>
          <Grid item xs={2} sm={4} md={4} className={classes.product}>
            <Typography
              fontSize="12px"
              fontFamily="inherit"
              sx={{
                borderBottom: '1px solid #6172A7',
                padding: '0px 5px',
                whiteSpace: 'nowrap',
                color: '#003399',
              }}
            >
              Product Level
            </Typography>
            <CustomInput
              type="input"
              label=""
              val={SecondRow?.[`${itemKey}-prod_level_opening`]}
              name={`${itemKey}-prod_level_opening`}
              defaultValue=""
              attr={['num']}
              value={
                              prefixProducts?.[itemKey]?.[
                                SecondRow?.[`${itemKey}-prod_level_opening`]
                              ] || SecondRow?.[`${itemKey}-prod_level_opening`]?.value
                            }
              isBatch={false}
              onChange={handleInputChange}
              size="100%"
              placeholder=""
              isDisabled={false}
              customKey={itemKey}
              transaction
              prefix={prefix}
              funNamePrefix={`${itemKey}-prod_level_opening`}
            />
          </Grid>
          <Grid item xs={2} sm={4} md={4} className={classes.product}>
            <Typography
              fontFamily="inherit"
              fontSize="12px"
              sx={{
                borderBottom: '1px solid black',
                padding: '0px 5px',
                whiteSpace: 'nowrap',
                color: '#003399',
              }}
            >
              Water Level
            </Typography>
            <div style={{ padding: '0px 0px' }}>
              <CustomInput
                type="input"
                label=""
                val={SecondRow?.[`${itemKey}-water_level_opening`]}
                name={`${itemKey}-water_level_opening`}
                defaultValue=""
                attr={['num']}
                value={
                              prefixProducts?.[itemKey]?.[
                                SecondRow?.[`${itemKey}-water_level_opening`]
                              ] || SecondRow?.[`${itemKey}-water_level_opening`]?.value
                            }
                isBatch={false}
                onChange={handleInputChange}
                size="100%"
                placeholder=""
                isDisabled={false}
                customKey={itemKey}
                transaction
                prefix={prefix}
                funNamePrefix={`${itemKey}-water_level_opening`}
              />
            </div>
          </Grid>
          <Grid item xs={2} sm={4} md={4} className={classes.product}>
            <Typography
              fontFamily="inherit"
              fontSize="12px"
              sx={{
                borderBottom: '1px solid black',
                padding: '0px 5px',
                whiteSpace: 'nowrap',
                color: '#003399',
              }}
            >
              Gross (Gal)
            </Typography>
            <div style={{ padding: '0px 0px' }}>
              <CustomInput
                type="input"
                label=""
                val={SecondRow?.[`${itemKey}-gross_inv_opening`]}
                required
                name={`${itemKey}-gross_inv_opening`}
                defaultValue=""
                attr={['num']}
                value={
                              prefixProducts?.[itemKey]?.[
                                SecondRow?.[`${itemKey}-gross_inv_opening`]
                              ] || SecondRow?.[`${itemKey}-gross_inv_opening`]?.value
                            }
                isBatch={false}
                onChange={handleInputChange}
                size="100%"
                placeholder=""
                isDisabled={false}
                customKey={itemKey}
                transaction
                prefix={prefix}
                funNamePrefix={`${itemKey}-gross_inv_opening`}
              />
            </div>
          </Grid>
          <Grid item xs={2} sm={4} md={4} className={classes.product}>
            <Typography
              fontFamily="inherit"
              fontSize="12px"
              sx={{
                borderBottom: '1px solid black',
                padding: '0px 5px',
                whiteSpace: 'nowrap',
                color: '#003399',
              }}
            >
              Net (Gal)
            </Typography>
            <div style={{ padding: '0px 0px' }}>
              <CustomInput
                type="input"
                label=""
                val={SecondRow?.[`${itemKey}-net_inv_opening`]}
                required
                name={`${itemKey}-net_inv_opening`}
                defaultValue=""
                attr={['num']}
                value={
                              prefixProducts?.[itemKey]?.[
                                SecondRow?.[`${itemKey}-net_inv_opening`]
                              ] || SecondRow?.[`${itemKey}-net_inv_opening`]?.value
                            }
                isBatch={false}
                onChange={handleInputChange}
                size="100%"
                placeholder=""
                isDisabled={false}
                customKey={itemKey}
                transaction
                prefix={prefix}
                funNamePrefix={`${itemKey}-net_inv_opening`}
              />
            </div>
          </Grid>
        </div>
      </div>
      <div className={classes.innerContainer}>
        {/* {productsList?.prod_id?.length === 0 && (
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value=""
                  // label={stepData?.fieldID}
                  // onChange={(e: any) => inputHandler({ key, name: stepData?.id, value: e?.target?.value })}
              size="small"
              sx={{
                width: '20%',
                borderRadius: '0px',
                marginTop: '5px',
                '& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select':
                      {
                        padding: '2px 8px',
                        border: '1px solid #E4E4E4',
                      },
              }}
            >
              <MenuItem key={uuidv4()} value="" selected>
                No Data Available
              </MenuItem>
            </Select>
            )} */}
        <div className={classes.secondRowSubContainer}>
          <Grid
            item
            sx={{
              width: '100%',
            }}
          >
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-end',
              width: '100%',
            }}
            >
              <Typography
                fontSize="12px"
                width="20%"
                fontFamily="inherit"
              >
                Closing
              </Typography>
              <CustomInput
                dateType
                type="input"
                label=""
                val={ThirdRow?.[`${itemKey}-time_closing`]}
                required
                name={`${itemKey}-time_closing`}
                defaultValue=""
                attr={['num']}
                value={
                              prefixProducts?.[itemKey]?.[
                                ThirdRow?.[`${itemKey}-time_closing`]
                              ] || ThirdRow?.[`${itemKey}-time_closing`]?.value
                            }
                isBatch={false}
                onChange={handleInputChange}
                size="100%"
                placeholder=""
                isDisabled={false}
                customKey={itemKey}
                transaction
                prefix={prefix}
                funNamePrefix={`${itemKey}-time_closing`}
              />
            </div>
          </Grid>
          <Grid item xs={2} sm={4} md={4} className={classes.product}>
            <CustomInput
              type="input"
              label=""
              val={ThirdRow?.[`${itemKey}-temperature_closing`]}
              required
              name={`${itemKey}-temperature_closing`}
              defaultValue=""
              attr={['num']}
              value={
                              prefixProducts?.[itemKey]?.[
                                ThirdRow?.[`${itemKey}-temperature_closing`]
                              ] || ThirdRow?.[`${itemKey}-temperature_closing`]?.value
                            }
              isBatch={false}
              onChange={handleInputChange}
              size="100%"
              placeholder=""
              isDisabled={false}
              customKey={itemKey}
              transaction
              prefix={prefix}
              funNamePrefix={`${itemKey}-temperature_closing`}
            />
          </Grid>
          <Grid item xs={2} sm={4} md={4} className={classes.product}>
            <CustomInput
              type="input"
              label=""
              val={ThirdRow?.[`${itemKey}-ambient_temperature_closing`]}
              name={`${itemKey}-ambient_temperature_closing`}
              defaultValue=""
              attr={['num']}
              value={
                              prefixProducts?.[itemKey]?.[
                                ThirdRow?.[`${itemKey}-ambient_temperature_closing`]
                              ] || ThirdRow?.[`${itemKey}-ambient_temperature_closing`]?.value
                            }
              isBatch={false}
              onChange={handleInputChange}
              size="100%"
              placeholder=""
              isDisabled={false}
              customKey={itemKey}
              transaction
              prefix={prefix}
              funNamePrefix={`${itemKey}-ambient_temperature_closing`}
            />
          </Grid>
          <Grid item xs={2} sm={4} md={4} className={classes.product}>
            <CustomInput
              type="input"
              label=""
              val={ThirdRow?.[`${itemKey}-grav_density_closing`]}
              required
              name={`${itemKey}-grav_density_closing`}
              defaultValue=""
              attr={['num']}
              value={
                              prefixProducts?.[itemKey]?.[
                                ThirdRow?.[`${itemKey}-grav_density_closing`]
                              ] || ThirdRow?.[`${itemKey}-grav_density_closing`]?.value
                            }
              isBatch={false}
              onChange={handleInputChange}
              size="100%"
              placeholder=""
              isDisabled={false}
              customKey={itemKey}
              transaction
              prefix={prefix}
              funNamePrefix={`${itemKey}-grav_density_closing`}
            />
          </Grid>
          <Grid item xs={2} sm={4} md={4} className={classes.product}>
            <CustomInput
              type="input"
              label=""
              val={ThirdRow?.[`${itemKey}-prod_level_closing`]}
              name={`${itemKey}-prod_level_closing`}
              defaultValue=""
              attr={['num']}
              value={
                              prefixProducts?.[itemKey]?.[
                                ThirdRow?.[`${itemKey}-prod_level_closing`]
                              ] || ThirdRow?.[`${itemKey}-prod_level_closing`]?.value
                            }
              isBatch={false}
              onChange={handleInputChange}
              size="100%"
              placeholder=""
              isDisabled={false}
              customKey={itemKey}
              transaction
              prefix={prefix}
              funNamePrefix={`${itemKey}-prod_level_closing`}
            />
          </Grid>
          <Grid item xs={2} sm={4} md={4} className={classes.product}>
            <div style={{ padding: '0px 0px' }}>
              <CustomInput
                type="input"
                label=""
                val={ThirdRow?.[`${itemKey}-water_level_closing`]}
                name={`${itemKey}-water_level_closing`}
                defaultValue=""
                attr={['num']}
                value={
                              prefixProducts?.[itemKey]?.[
                                ThirdRow?.[`${itemKey}-water_level_closing`]
                              ] || ThirdRow?.[`${itemKey}-water_level_closing`]?.value
                            }
                isBatch={false}
                onChange={handleInputChange}
                size="100%"
                placeholder=""
                isDisabled={false}
                customKey={itemKey}
                transaction
                prefix={prefix}
                funNamePrefix={`${itemKey}-water_level_closing`}
              />
            </div>
          </Grid>
          <Grid item xs={2} sm={4} md={4} className={classes.product}>
            <div style={{ padding: '0px 0px' }}>
              <CustomInput
                type="input"
                label=""
                val={ThirdRow?.[`${itemKey}-gross_inv_closing`]}
                required
                name={`${itemKey}-gross_inv_closing`}
                defaultValue=""
                attr={['num']}
                value={
                              prefixProducts?.[itemKey]?.[
                                ThirdRow?.[`${itemKey}-gross_inv_closing`]
                              ] || ThirdRow?.[`${itemKey}-gross_inv_closing`]?.value
                            }
                isBatch={false}
                onChange={handleInputChange}
                size="100%"
                placeholder=""
                isDisabled={false}
                customKey={itemKey}
                transaction
                prefix={prefix}
                funNamePrefix={`${itemKey}-gross_inv_closing`}
              />
            </div>
          </Grid>
          <Grid item xs={2} sm={4} md={4} className={classes.product}>
            <div style={{ padding: '0px 0px' }}>
              <CustomInput
                type="input"
                label=""
                val={ThirdRow?.[`${itemKey}-net_inv_closing`]}
                required
                name={`${itemKey}-net_inv_closing`}
                defaultValue=""
                attr={['num']}
                value={
                              prefixProducts?.[itemKey]?.[
                                ThirdRow?.[`${itemKey}-net_inv_closing`]
                              ] || ThirdRow?.[`${itemKey}-net_inv_closing`]?.value
                            }
                isBatch={false}
                onChange={handleInputChange}
                size="100%"
                placeholder=""
                isDisabled={false}
                customKey={itemKey}
                transaction
                prefix={prefix}
                funNamePrefix={`${itemKey}-net_inv_closing`}
              />
            </div>
          </Grid>
        </div>
      </div>
    </div>
  ) : (<></>);
};

export default GaugeTransactionTankStep;
