/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';

// Librairies
import {
  ButtonBase,
  InputBase,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { Settings } from '@mui/icons-material';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';

// Styles
import { useStyles } from './style';

// Utils
import { searchMeterRecords } from '../../utils';
import { setFormData } from '../../redux/actions/workflows/meterEntry';

// Actions

interface IProps {
  handleClickRow?: (row: any) => void;
  openMeterAdjustmentsDialog: (row: any, rowIndex: number) => void;
  data: any[];
  searchText: string;
  tableHeader: any[];
  selectedRecord: any;
  isUpdateDisabled: boolean;
}

const MeterEntryRecordDeatilTable: React.FC<IProps> = ({
  handleClickRow,
  data,
  searchText,
  tableHeader,
  openMeterAdjustmentsDialog,
  isUpdateDisabled,
}) => {
  const classes = useStyles();
  const [tableData, setTableData] = useState(data || []);

  useEffect(() => {
    if (searchText) {
      const filtredRecords = searchMeterRecords(searchText, data, tableHeader);
      setTableData(filtredRecords);
    } else {
      setTableData(data);
    }
  }, [data, searchText]);

  return (
    <div className={clsx('takeTheRest', classes.root)}>
      <Table classes={{ root: classes.tableRoot }}>
        <TableHead>
          <TableRow classes={{ root: classes.headerRow }}>
            <TableCell classes={{ root: classes.headerCell }} />
            {tableHeader?.map(
              (h) =>
                h.type !== 'hidden' && (
                  <TableCell classes={{ root: classes.headerCell }} key={h.id}>
                    {h.header === 'buttons' ? '' : h.header}
                  </TableCell>
                ),
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData?.map((row: any) => (
            <TableRow className={classes.tableRow} key={row.index}>
              <TableCell classes={{ root: classes.bodyCell }}>
                <div className={classes.actionsWrapper}>
                  <ButtonBase
                    className={classes.actionButton}
                    onClick={() => openMeterAdjustmentsDialog(row, row.index)}
                    disabled={isUpdateDisabled}
                  >
                    <Settings fontSize="small" color={isUpdateDisabled ? 'disabled' : 'inherit'} />
                  </ButtonBase>
                </div>
              </TableCell>
              {tableHeader.map((field, i) => (
                <MeterEntryTableCell
                  field={field}
                  row={row}
                  key={i}
                  index={row.index}
                  isUpdateDisabled={isUpdateDisabled}
                />
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

interface IMeterEntryTableCell {
  field: any;
  row: any;
  index: number;
  isUpdateDisabled: boolean;
}

const MeterEntryTableCell: React.FC<IMeterEntryTableCell> = ({
  field,
  row,
  index,
  isUpdateDisabled,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState('');
  const { formData } = useSelector((state: any) => state.Workflows.MeterEntry);

  useEffect(() => {
    if (formData[index]) {
      setInputValue(formData[index][field.id]);
    } else {
      setInputValue(row[field.id]);
    }
  }, [formData, row[field.id]]);

  useEffect(() => {
    if (field.id === 'thruput') {
      setInputValue(formData[index]?.thruput);
    }
  }, [formData, field.id, index]);

  const handleInputBlur = () => {
    const adjustment = formData[index]
      ? Number(formData[index]?.adjustment)
      : Number(row.adjustment);
    const newThruput = Number(inputValue) + Number(adjustment);
    const newData = { end_meter: inputValue, thruput: newThruput };
    dispatch(setFormData(row, newData, index));
  };

  if (field.type === 'hidden') return null;
  return (
    <TableCell
      classes={{ root: classes.bodyCell }}
      key={field.id}
      style={{ backgroundColor: formData[index] ? '#f4fadb' : undefined }}
    >
      {field.type === 'input' ? (
        <InputBase
          value={inputValue}
          className={classes.input}
          disabled={isUpdateDisabled || field?.attr?.includes('readonly')}
          onChange={(e) => setInputValue(e.target.value)}
          onBlur={handleInputBlur}
          type="number"
        />
      ) : field.id === 'thruput' ? (
        inputValue || 0
      ) : (
        inputValue
      )}
    </TableCell>
  );
};

export default React.memo(MeterEntryRecordDeatilTable);
