/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useState } from 'react';

// Librairies
import {
  ButtonBase,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';

// Components
import CheckboxComponent from '../Checkbox';

// Styles
import { useStyles } from './style';

interface IProps {
  handleClickRow: () => void;
  headerItems: any[];
  selectRow?: (row: any, isRowSelected: boolean) => void;
  selectedRows?: any;
  searchText?: string;
  handleSelectAll?: () => void;
  handleUnSelectAll?: () => void;
  tableState: any;
  isUpdateDisabled: boolean;
}

const AutoTankGaugeTable: React.FC<IProps> = ({
  handleClickRow,
  headerItems,
  selectRow,
  selectedRows,
  searchText,
  handleSelectAll,
  handleUnSelectAll,
  tableState,
  isUpdateDisabled,
}) => {
  const classes = useStyles();
  const [processMenuAnchorEl, setProcessMenuAnchorEl] = useState<null | HTMLElement>(null);
  const { tableData } = tableState;

  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setProcessMenuAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setProcessMenuAnchorEl(null);
  };

  const isRowSelected = (row: any) => {
    if (selectedRows) {
      return selectedRows.some((x: any) => x?.tank === row?.tank);
    }
    return false;
  };

  return (
    <>
      <div className={classes.tableWrapper}>
        <Table classes={{ root: classes.tableRoot }}>
          <TableHead>
            <TableRow classes={{ root: classes.headerRow }}>
              {headerItems.map((h) => (h.label === 'menu' ? (
                <TableCell classes={{ root: classes.headerCell }} key={h.key}>
                  <ButtonBase
                    style={{
                      padding: '0px 10px',
                      backgroundColor: '#f0f0f0',
                    }}
                    onClick={handleButtonClick}
                  >
                    Assign
                    {' '}
                    <ArrowDropDown fontSize="small" />
                  </ButtonBase>
                  <AutoTankGaugeMenu
                    anchorEl={processMenuAnchorEl}
                    onClose={handleCloseMenu}
                    handleSelectAll={handleSelectAll}
                    handleUnSelectAll={handleUnSelectAll}
                    isUpdateDisabled={isUpdateDisabled}
                  />
                </TableCell>
              ) : (
                <TableCell classes={{ root: classes.headerCell }} key={h.key}>
                  {h.label}
                </TableCell>
              )))}
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableRoot}>
            {!tableData.length && (
              <TableRow className={classes.tableRow}>
                <TableCell
                  classes={{ root: classes.bodyCellEmpty }}
                  style={{ textAlign: 'center' }}
                  colSpan={Object.keys(headerItems).length}
                >
                  {searchText
                    ? 'No matching records found'
                    : 'No data available in table'}
                </TableCell>
              </TableRow>
            )}
            {tableData?.map((row: any, index: number) => (
              <TableRow
                onClick={() => !isUpdateDisabled && selectRow && selectRow(row, isRowSelected(row))}
                className={classes.tableRow}
                key={index.toFixed()}
              >
                {headerItems.map((cell: any) => (cell.label === 'menu' ? (
                  <TableCell
                    classes={{ root: classes.bodyCell }}
                    key={cell.key}
                  >
                    <CheckboxComponent
                      disabled={isUpdateDisabled}
                      checked={isRowSelected(row)}
                      label=""
                      onChange={() => null}
                      onClick={() => selectRow && selectRow(row, isRowSelected(row))}
                      style={{ marginBottom: 20 }}
                    />
                  </TableCell>
                ) : (
                  <TableCell
                    classes={{ root: classes.bodyCell }}
                    key={cell.key}
                  >
                    {row[cell.key] || '0'}
                  </TableCell>
                )))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </>
  );
};

interface IMenuProps {
  anchorEl: any;
  onClose: () => void;
  handleSelectAll?: () => void;
  handleUnSelectAll?: () => void;
  isUpdateDisabled: boolean;
}

const AutoTankGaugeMenu: React.FC<IMenuProps> = ({
  anchorEl,
  onClose,
  handleSelectAll,
  handleUnSelectAll,
  isUpdateDisabled,
}) => {
  const classes = useStyles();

  const handleSelectFirst = () => {
    if (handleSelectAll) {
      handleSelectAll();
      onClose();
    }
  };

  const handleUnSelect = () => {
    if (handleUnSelectAll) {
      handleUnSelectAll();
      onClose();
    }
  };

  useEffect(
    () => () => {
      if (handleUnSelectAll) {
        handleUnSelectAll();
      }
    },
    [],
  );

  return (
    <Menu
      classes={{ root: classes.root, list: classes.list, paper: classes.paper }}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
    >
      <MenuItem
        classes={{ root: classes.menuItem }}
        onClick={handleSelectFirst}
        disabled={isUpdateDisabled}
      >
        {' '}
        Select All
        {' '}
      </MenuItem>
      <MenuItem
        classes={{ root: classes.menuItem }}
        onClick={handleUnSelect}
        disabled={isUpdateDisabled}
      >
        {' '}
        De-Select All
        {' '}
      </MenuItem>
    </Menu>
  );
};

export default React.memo(AutoTankGaugeTable);
