import {
  LOAD_PRESET_CARDS_DATA,
  LOAD_RACK_STATUS_BAY_RESPONSE,
  LOAD_RACK_STATUS_TABLE,
  PROCESS_RACK_STATUS,
  SET_BAY_ID_FOR_DETAIL,
  UPDATE_RCU_SCREEN,
} from '../../constants/workflows';

const INITIAL_STATE = {
  rackStatus: [],
  savedResponse: {
    type: '',
    title: '',
    text: '',
  },
  presetCardsData: [],
  viewTitle: '',
  ShowBayStatus: [],
  scadTable: {},
  pageImgList: [],
  transportDetailData: [],
  currentBayForDetail: '',
  dittoBayID: '',
  RCUScreen: '',
};

const tankStatusReducer = (state = INITIAL_STATE, { type, payload }: any) => {
  switch (type) {
    case LOAD_RACK_STATUS_TABLE: {
      return {
        ...state,
        rackStatus: payload,
      };
    }
    case LOAD_RACK_STATUS_BAY_RESPONSE: {
      return {
        ...state,
        savedResponse: {
          type: payload?.msgtype,
          title: payload?.msgtype,
          text: payload?.msg,
        },
      };
    }
    case LOAD_PRESET_CARDS_DATA: {
      return {
        ...state,
        presetCardsData: payload?.presetLists,
        viewTitle: payload?.viewTitle,
        ShowBayStatus: payload?.ShowBayStatus,
        scadTable: payload?.scadTable,
        dittoBayID: payload?.dittoBayID,
      };
    }
    case PROCESS_RACK_STATUS: {
      return {
        ...state,
        pageImgList: payload?.pageImgList,
        transportDetailData: payload?.tranportDetailData,
      };
    }
    case SET_BAY_ID_FOR_DETAIL: {
      return {
        ...state,
        currentBayForDetail: payload,
      };
    }
    case UPDATE_RCU_SCREEN: {
      return {
        ...state,
        RCUScreen: payload,
      };
    }
    default:
      return state;
  }
};

export default tankStatusReducer;
