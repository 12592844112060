import { Dispatch } from 'redux';
import Api from '../../api/index';
import { LOAD_REPORT_QUEUE } from '../../constants/workflows';

export const getReportQueue = () => async (dispatch: Dispatch) => {
  const response = await Api.get(
    'workflow017/data?screenID=WORKFLOW-075&reportType=T&mode=queue',
  );
  dispatch({
    type: LOAD_REPORT_QUEUE,
    payload: response.data.data,
  });
  console.log(response.data.data);
};
