import React, { useEffect, useState } from 'react';

// Librairies
import { useDispatch, useSelector } from 'react-redux';

// Components
import WorkflowTableUtils from '../../../components/WorkflowTableUtils';

// Styles
import { useStyles } from './style';

// Actions

// Hooks
import { ITableState, useWorkflowState } from '../../../hooks/useWorkflowState';
import { getChosenFieldAttributes } from '../../../redux/actions/workflows/fieldAttributes';
import FieldAttributesTable from '../../../components/FieldAttributesTable';
import {
  getRecordToShowValue,
  searchRecords,
  sortedTable,
} from '../../../utils';

// Utils
const tableHeader = {
  'Field ID': 'Field ID',
  Default: 'Default',
  Mask: 'Mask',
  'Read Only': 'Read Only',
  Hide: 'Hide',
  Required: 'Required',
  Alpha: 'Alpha',
  Numeric: 'Numeric',
  'No-Space': 'No-Space',
  'No-Negative': 'No-Negative',
  'E-Mail': 'E-Mail',
};
const headerList = [
  { key: 'Field ID', label: 'Field ID' },
  { key: 'Default', label: 'Default' },
  { key: 'Mask', label: 'Mask' },
  { key: 'Read Only', label: 'Read Only' },
  { key: 'Hide', label: 'Hide' },
  { key: 'Required', label: 'Required' },
  { key: 'Alpha', label: 'Alpha' },
  { key: 'Numeric', label: 'Numeric' },
  { key: 'No-Space', label: 'No-Space' },
  { key: 'No-Negative', label: 'No-Negative' },
  { key: 'E-Mail', label: 'E-Mail' },
];

interface IFieldAttributesProps {
  groupScreenState: { [key: string]: any };
}

const FieldAttributes: React.FC<IFieldAttributesProps> = ({
  groupScreenState,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { uigroupid, uiscreenid } = groupScreenState;
  const { fieldAttributesTable } = useSelector(
    (state: any) => state.Workflows.FieldAttributes,
  );
  const [tableState, setTableState] = useState<ITableState>(() => ({
    tableData: [],
    currentPage: 1,
    searchedDataCount: 0,
    recordsNumber: 'Auto',
    searchText: '',
    orderBy: '',
    order: 'asc',
    anchorEl: null,
  }));

  const recordsToShow = getRecordToShowValue(
    tableState.recordsNumber,
    fieldAttributesTable.length,
    18,
  );

  const handleSelectChange = (e: any, option: any) => {
    setTableState({ ...tableState, recordsNumber: option, currentPage: 1 });
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTableState({
      ...tableState,
      searchText: e.target.value,
      currentPage: 1,
    });
  };

  const handleOptionClick = (event: React.MouseEvent<HTMLElement>) => {
    setTableState({ ...tableState, anchorEl: event.currentTarget });
  };

  const handleCloseMenu = () => {
    setTableState({ ...tableState, anchorEl: null });
  };

  const handlePaginationChange = (event: any, targetPage: any) => {
    if (!tableState.searchText) {
      const selectedData = fieldAttributesTable.slice(
        (targetPage - 1) * recordsToShow,
        targetPage * recordsToShow,
      );
      setTableState({
        ...tableState,
        currentPage: targetPage,
        tableData: selectedData,
      });
    } else {
      const selectedData = searchRecords(
        tableState.searchText,
        fieldAttributesTable,
        [],
      ).slice((targetPage - 1) * recordsToShow, targetPage * recordsToShow);
      setTableState({
        ...tableState,
        currentPage: targetPage,
        tableData: selectedData,
      });
    }
  };

  const handleSortTable = (key: string) => {
    const sortedData = sortedTable(fieldAttributesTable, key, tableState.order)
      ?? fieldAttributesTable;
    setTableState({
      ...tableState,
      currentPage: 1,
      searchedDataCount: 0,
      tableData: sortedData.slice(0, recordsToShow),
      orderBy: key,
      order: tableState.order === 'asc' ? 'desc' : 'asc',
    });
  };

  useEffect(() => {
    if (tableState.searchText) {
      const filtredRecords = searchRecords(
        tableState.searchText,
        fieldAttributesTable,
        [],
      );
      setTableState({
        ...tableState,
        searchedDataCount: filtredRecords.length,
        tableData: filtredRecords.slice(0, recordsToShow),
        currentPage: 1,
      });
    } else {
      setTableState({
        ...tableState,
        searchedDataCount: 0,
        tableData: fieldAttributesTable.slice(0, recordsToShow),
      });
    }
  }, [fieldAttributesTable?.length, recordsToShow, tableState.searchText]);

  useEffect(() => {
    if (uigroupid && uiscreenid) {
      dispatch(getChosenFieldAttributes(uiscreenid?.value, uigroupid?.value));
    }
  }, [uigroupid, uiscreenid]);

  return (
    <div>
      <WorkflowTableUtils
        handleSearchChange={handleSearchChange}
        handleSelectChange={handleSelectChange}
        recordsNumber={tableState.recordsNumber}
        searchText={tableState.searchText}
      />
      <FieldAttributesTable
        handleClickRow={() => null}
        visibleColumns={tableHeader}
        allDataCount={fieldAttributesTable.length}
        handlePaginationChange={handlePaginationChange}
        recordsToShow={recordsToShow}
        tableHeader={tableHeader}
        tableState={tableState}
        order={tableState.order}
        orderBy={tableState.orderBy}
        searchText={tableState.searchText}
        sortTable={handleSortTable}
      />
    </div>
  );
};

export default FieldAttributes;
