import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {},
  input: {
    border: '1px solid #dddddd !important',
    borderLeft: '4px solid #555 !important',
    padding: '0px 4px !important',
    borderRadius: '0 !important',
    fontSize: '12px !important',
    fontWeight: '400 !important',
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  border: {
    border: '1px solid #dddddd !important',
  },
  borderRequired: {
    border: '1px solid red !important',
  },
  inputRequired: {
    borderLeft: '4px solid red !important',
    padding: '0px 4px !important',
    borderRadius: '0 !important',
    fontSize: '12px !important',
    fontWeight: '400 !important',
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
});
