import React, { useEffect, useState } from 'react';

// Librairies
import { useDispatch, useSelector } from 'react-redux';
import { ButtonBase, Divider } from '@mui/material';

// Components
import SubHeader from '../../../components/subHeader';
import TmsHeader from '../../../components/header';
import WorkflowTable from '../../../components/WorkflowTable';
import TransactionViewerOptions from '../../../components/TransactionViewerOptions';

// Styles
import OptionsButton from '../../../components/optionsButton';

// Actions
import { useWorkflowState } from '../../../hooks/useWorkflowState';
import WorkflowTableUtils from '../../../components/WorkflowTableUtils';
import {
  clearTransactionViewerDownloadLink,
  clearTransactionViewerExportFile,
  clearTransactionViewerRecordDetail,
  exportTransactionViewerFile,
  getTransactionViewerColumns,
  getTransactionViewerData,
  getTransactionViewerDownloadLink,
  getTransactionViewerFilters,
  getTransactionViewerRecordDetail,
  updateTransactionViewerColumns,
} from '../../../redux/actions/workflows/transactionViewer';

// Styles
import { useStyles } from './style';
import MultiTabsColumnsDialog from '../../../components/ColumnsDialog/MultiTabsColumnsDialog';
import TransactionViewerSeacrhCard from '../../../components/TransactionViewerSearchCrad';
import TransactionViewerDetailDialog from '../../../components/TransactionViewerDetailDialog';
import { isEmpty } from '../../../utils';

const TransactionViewerScreen: React.FC = () => {
  const classes = useStyles();
  const {
    data,
    headerList,
    searchHeader,
    transStat,
    loading,
    visibleColumns,
    hiddenColumns,
    exportFile,
    exportLink,
    columnsList,
    recordDetail,
    filters,
  } = useSelector((state: any) => state.Workflows.TransactionViewer);
  const [transactionViewerState, setTransactionViewerState] = useState(() => ({
    columnsDialogOpen: false,
    searchCardOpen: false,
    transactionViewerDetailDialogOpen: false,
    selectedTransaction: null,
  }));
  const dispatch = useDispatch();
  const {
    recordsToShow,
    tableState,
    handlePaginationChange,
    handleSearchChange,
    handleSelectChange,
    handleSortTable,
    handleCloseMenu,
    handleOptionClick,
  } = useWorkflowState(
    searchHeader,
    data,
    getTransactionViewerData,
    false,
    false,
    16,
  );

  useEffect(() => {
    dispatch(getTransactionViewerColumns());
    dispatch(getTransactionViewerFilters());
  }, []);

  useEffect(() => {
    if (exportFile) {
      dispatch(getTransactionViewerDownloadLink(exportFile));
    }
    return () => {
      dispatch(clearTransactionViewerExportFile());
    };
  }, [exportFile]);

  useEffect(() => {
    if (exportLink) {
      const link = document.createElement('a');
      link.href = exportLink;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    return () => {
      dispatch(clearTransactionViewerDownloadLink());
    };
  }, [exportLink]);

  const handleOpenColumnsDialog = () => {
    setTransactionViewerState({
      ...transactionViewerState,
      columnsDialogOpen: true,
    });
    handleCloseMenu();
  };

  const handleCloseColumnsDialog = () => {
    setTransactionViewerState({
      ...transactionViewerState,
      columnsDialogOpen: false,
    });
  };

  const handleOpenSearchCard = () => {
    setTransactionViewerState({
      ...transactionViewerState,
      searchCardOpen: true,
    });
  };

  const handleCloseSearchCard = () => {
    setTransactionViewerState({
      ...transactionViewerState,
      searchCardOpen: false,
    });
  };

  const handleSubmitNewColumns = (newColumns: { [key: string]: string[] }) => {
    dispatch(updateTransactionViewerColumns(newColumns));
  };

  const handleRestoreDefault = () => {
    dispatch(updateTransactionViewerColumns({}));
  };

  const exportTransactions = () => {
    dispatch(exportTransactionViewerFile());
    handleCloseMenu();
  };

  const openTransactionViewerDetailDialog = (row: any) => {
    dispatch(getTransactionViewerRecordDetail(row?.trans_ref_no, columnsList));
    setTransactionViewerState({
      ...transactionViewerState,
      transactionViewerDetailDialogOpen: true,
      selectedTransaction: row,
    });
  };

  const closeTransactionViewerDetailDialog = () => {
    dispatch(clearTransactionViewerRecordDetail());
    setTransactionViewerState({
      ...transactionViewerState,
      transactionViewerDetailDialogOpen: false,
    });
  };
  return (
    <>
      <TmsHeader />
      <SubHeader title="Transaction Viewer">
        <OptionsButton handleClick={handleOptionClick} />
        <TransactionViewerOptions
          anchorEl={tableState.anchorEl}
          onClose={handleCloseMenu}
          exportTable={exportTransactions}
          openColumnsDialog={handleOpenColumnsDialog}
        />
      </SubHeader>
      {transactionViewerState.searchCardOpen ? (
        <div style={{ position: 'relative' }}>
          <TransactionViewerSeacrhCard
            onApply={() => null}
            onCancel={handleCloseSearchCard}
            onRemove={() => null}
            filters={filters}
          />
        </div>
      ) : null}
      <div className={classes.wrapper}>
        <ButtonBase
          className={classes.modifySearchButton}
          onClick={handleOpenSearchCard}
        >
          Modify Search
        </ButtonBase>
        <Divider className={classes.divider} />
        <div className={classes.transactionBar}>
          <table className="fullWidth">
            <tr>
              {transStat.map((el: string) => (
                <td key={el}>{el}</td>
              ))}
            </tr>
          </table>
        </div>
      </div>
      <WorkflowTableUtils
        handleSearchChange={handleSearchChange}
        handleSelectChange={handleSelectChange}
        recordsNumber={tableState.recordsNumber}
        searchText={tableState.searchText}
      />
      <WorkflowTable
        handleClickRow={openTransactionViewerDetailDialog}
        visibleColumns={visibleColumns.TransHeader || {}}
        allDataCount={data.length}
        handlePaginationChange={handlePaginationChange}
        recordsToShow={recordsToShow}
        tableHeader={visibleColumns.TransHeader || {}}
        tableState={tableState}
        order={tableState.order}
        orderBy={tableState.orderBy}
        searchText={tableState.searchText}
        sortTable={handleSortTable}
        loading={loading}
      />
      <MultiTabsColumnsDialog
        title="Configure Visible Columns"
        onClose={handleCloseColumnsDialog}
        open={transactionViewerState.columnsDialogOpen}
        visibleColumns={visibleColumns}
        hiddenColumns={hiddenColumns}
        submitNewColumns={handleSubmitNewColumns}
        handleRestoreDefault={handleRestoreDefault}
      />
      {!isEmpty(recordDetail) ? (
        <TransactionViewerDetailDialog
          onClose={closeTransactionViewerDetailDialog}
          open={transactionViewerState.transactionViewerDetailDialogOpen}
          selectedTransaction={transactionViewerState.selectedTransaction}
          headerList={headerList}
          recordDetail={recordDetail}
          visibleColumns={visibleColumns}
        />
      ) : null}
    </>
  );
};

export default TransactionViewerScreen;
