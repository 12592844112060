import React from 'react';
import {
  BrowserRouter, Navigate, Route, Routes,
} from 'react-router-dom';
import BatchModeUpdate from '../components/batchModeUpdate';
import LandingPage from '../screens/Landingpage/LandingPage';
import LoginPage from '../screens/LoginPage/LoginPage';
import MaintTableScreen from '../screens/maint-screen/TableScreen';
import BatchModeTerminalProfile from '../screens/TerminalProfile/BatchModeTerminalProfile';
import TermProfileForm from '../screens/TerminalProfile/TerminalForm';
import DetailMaintScreen from '../screens/maint-screen/details-maint-screen';
import CloneRecord from '../screens/maint-screen/CloneRecord';
import CreateRecord from '../screens/maint-screen/createMaintScreen';
import About from '../screens/Workflows/about/index';
import ChangePassword from '../screens/Workflows/ChangePassword';
import PrivateRoutes from './privateRoutes';
import UserSettings from '../screens/userSettings/userSettings';
import GroupsTableView from '../screens/Workflows/groups/groupsTableView';
import ChangeActiveTank from '../screens/Workflows/changeActiveTank';
import RackStatus from '../screens/Workflows/rackStatus/rackStatus';
import GroupsDetailsPage from '../screens/Workflows/groups/groupsDetailPage';
import SystemStartup from '../screens/Workflows/systemStartup';
import TankStatus from '../screens/Workflows/tankStatus/TankStatus';
import NonBulkTransaction from '../screens/Workflows/nonBulkTransaction/nonBulkTransaction';
import BulkTransaction from '../screens/Workflows/bulkTransaction/bulkTransaction';
import OrderMaintenance from '../screens/Workflows/orderMaintenance/OrderMaintenance';
import OrderMaintenanceNewRecord from
  '../screens/Workflows/orderMaintenance/OrderMaintenanceNewRecord';
import SystemUtilityQueue from '../screens/SystemUtilities/systemUtilityQueue';
import ReportQueue from '../screens/Reports/ReportQueue';
import Allocations from '../screens/Workflows/allocations/allocations';
import RackStatusDetail from '../screens/Workflows/rackStatus/rackStatusDetail';
import AuditLogsScreen from '../screens/Workflows/auditLogsScreen';
import RCUDittoScreen from '../screens/Workflows/rcuDitto';
import FileExport from '../screens/Workflows/fileExport';
import DeviceCommunications from '../screens/Workflows/deviceCommunications';
import ManagePrinters from '../screens/Workflows/managePrinters';
import EmcsConfiguration from '../screens/Workflows/emcsConfiguration';
import ViewTraceLogs from '../screens/Workflows/viewTraceLogs';
import ViewTotalizerData from '../screens/Workflows/viewTotalizerData';
import MarqueeManagerScreen from '../screens/Workflows/marqueeManager';
import TrafficScreen from '../screens/Workflows/traffic';
import TankGaugeEntryScreen from '../screens/Workflows/tankGaugeEntry';
import VipScullyCommunicationsScreen from '../screens/Workflows/vipScullyCommunications';
import './style.css';
import FolioFreezeClose from '../screens/Workflows/folioFreezeClose';
import TankStrappingTables from '../screens/Workflows/tankStrappingTables';
import DriversInFacility from '../screens/Workflows/driversInFacility';
import IOMonitorScreen from '../screens/Workflows/IO-monitor';
import MeterEntryScreen from '../screens/Workflows/meterEntry';
import VehiclesInFacility from '../screens/Workflows/vehiclesInFacility';
import MeterEntryRecordDetail from '../screens/Workflows/meterEntryRecordDetail';
import ContractDispatch from '../screens/Workflows/ContractDispatch/ContractDispatch';
import DriversThroughFacilityScreen from '../screens/Workflows/DriversThroughFacility';
import ProductGroupCrossReferenceScreen from '../screens/Workflows/ProductGroupCrossReference';
import PGCRRecordDetail from '../screens/Workflows/PGCRRecordDetail';
import EMCSManagerScreen from '../screens/Workflows/emcsManager';
import Reports from '../screens/Reports/Reports';
import PersonnelInFacility from '../screens/Workflows/PersonnelInFacility';
import ConditionSetScreen from '../screens/Workflows/ConditionSet';
import TileLayout from '../screens/Workflows/tileLayout/tileLayout';
import SiteLayout from './SiteLayout';
import ViewReport from '../screens/Reports/ViewReport';
import CustomReportConfiguration from '../screens/Reports/CustomReportConfiguration';
import AddAthorizedProducts from '../screens/Workflows/addAuthorizedProducts';
import ConditionSetRecordDetail from '../screens/Workflows/ConditionSetRecordDetail';
import CustomReportConfigurationDetail from '../screens/Reports/CustomReportConfigurationDetail';
import TransactionViewerScreen from '../screens/Workflows/TransactionViewer';
import SystemUtilities from '../screens/SystemUtilities/SystemUtilities';
import ViewUtility from '../screens/SystemUtilities/ViewUtility';
import TankStrappingTablesDetail from '../screens/Workflows/TankStrappingTablesDetail';
import TankGaugeEntryRecordDetail from '../screens/Workflows/tankGaugeEntryRecordDetail';
import ContractTransaction from '../screens/Workflows/contractTransaction';
import OrderMaintenanceBatchDelete from '../screens/Workflows/orderMaintenance/OrderMaintenanceBatchDelete';
import OrderMaintenanceBatchModify from '../screens/Workflows/orderMaintenance/OrderMaintenanceBatchModify';
import BulkTransferTank from '../screens/Workflows/bulkTransferTank';
import UserSessionScreen from '../screens/Workflows/userSession';
import { DetailRecordView as UserSessionDetailRecord } from '../screens/Workflows/userSession/detailRecordView';
import DocumentBuilder from '../screens/Workflows/documentBuilder';
import DocumentBuilderDetail from '../screens/Workflows/documentBuilderDetail';
import BulkScheduledTransaction from '../screens/Workflows/bulkScheduledTransaction';
import GaugeTransaction from '../screens/Workflows/gaugeTransaction';
import OrderTransactionEntry from '../screens/Workflows/orderTransactionEntry';
import FieldAttributeSettingsScreen from '../screens/Workflows/FieldAttributeSettings';
import UsersScreen from '../screens/Workflows/users';
import FileImport from '../screens/Workflows/fileImport/Index';

const Main = () => (
  <>
    <div className={`${window.location.pathname.includes('/workflow/tile-layout')
     || window.location.pathname.includes('/') ? 'overflow-hidden' : ''}
      navigationContainer fullHeight flexColumn`}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<PrivateRoutes><LandingPage /></PrivateRoutes>} />
          <Route path="/batch-mode" element={<BatchModeTerminalProfile />} />
          <Route path="/batch-mode-confirm" element={<BatchModeUpdate />} />
          <Route path="/user-settings" element={<UserSettings />} />
          <Route path="/maint/:screenId" element={<MaintTableScreen />} />
          <Route path="termProfileform" element={<TermProfileForm />} />
          <Route path="maint-details" element={<DetailMaintScreen />} />
          <Route path="/clone-record" element={<CloneRecord />} />
          <Route path="/create-record" element={<CreateRecord />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/testComponent" element={<ChangeActiveTank />} />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/workflow/Groups" element={<GroupsTableView />} />
          <Route path="/workflow/audit-logs" element={<AuditLogsScreen />} />
          <Route path="/workflow/rcu-ditto" element={<RCUDittoScreen />} />
          <Route path="/workflow/change-active-tank" element={<ChangeActiveTank />} />
          <Route path="/workflow/rack-status" element={<RackStatus />} />
          <Route path="/workflow/Groups-details" element={<GroupsDetailsPage />} />
          <Route path="/workflow/tank-gauge-entry" element={<TankGaugeEntryScreen />} />
          <Route path="/workflow/System-startup" element={<SystemStartup />} />
          <Route path="/workflow/file-export" element={<FileExport />} />
          <Route path="/workflow/file-import" element={<FileImport />} />
          <Route path="/workflow/folio-freeze-close" element={<FolioFreezeClose />} />
          <Route path="/workflow/device-communications" element={<DeviceCommunications />} />
          <Route path="/workflow/tank-strapping-tables" element={<TankStrappingTables />} />
          <Route path="/workflow/meter-entry" element={<MeterEntryScreen />} />
          <Route path="/workflow/add-authorized-products" element={<AddAthorizedProducts />} />
          <Route path="/workflow/condition-set" element={<ConditionSetScreen />} />
          <Route
            path="/workflow/product-group-cross-reference"
            element={<ProductGroupCrossReferenceScreen />}
          />
          <Route
            path="/workflow/meter-entry/recordDetail/:termID/:folioMonth/:folioNumber/:folioStatus"
            element={<MeterEntryRecordDetail />}
          />
          <Route
            path="/workflow/product-group-cross-reference/recordDetail/:termID/:productGroup"
            element={<PGCRRecordDetail />}
          />
          <Route path="/workflow/marquee-manager" element={<MarqueeManagerScreen />} />
          <Route path="/workflow/vehicles-in-facility" element={<VehiclesInFacility />} />
          <Route path="/workflow/IO-monitor" element={<IOMonitorScreen />} />
          <Route path="/workflow/manage-printers" element={<ManagePrinters />} />
          <Route path="/workflow/drivers-in-facility" element={<DriversInFacility />} />
          <Route path="/workflow/emcs-configuration" element={<EmcsConfiguration />} />
          <Route path="/workflow/view-trace-logs" element={<ViewTraceLogs />} />
          <Route
            path="/workflow/drivers-through-facility"
            element={<DriversThroughFacilityScreen />}
          />
          <Route
            path="/workflow/personnel-in-facility"
            element={<PersonnelInFacility />}
          />
          <Route path="/workflow/traffic" element={<TrafficScreen />} />
          <Route path="/workflow/emcs-manager" element={<EMCSManagerScreen />} />
          <Route path="/workflow/Non-bulk-transcation" element={<NonBulkTransaction />} />
          <Route path="/workflow/bulk-transcation" element={<BulkTransaction />} />
          <Route path="/workflow/report-queue" element={<ReportQueue />} />
          <Route path="/workflow/view-totalizer-data" element={<ViewTotalizerData />} />
          <Route path="/workflow/order-maintenance" element={<OrderMaintenance />} />
          <Route path="/workflow/bulk-scheduled-transaction" element={<BulkScheduledTransaction />} />
          <Route path="/workflow/order-transaction-entry" element={<OrderTransactionEntry />} />
          <Route
            path="/workflow/order-maintenance-details"
            element={<OrderMaintenanceNewRecord />}
          />
          <Route path="/workflow/tank-status" element={<TankStatus />} />
          <Route path="/workflow/non-bulk-transacation" element={<NonBulkTransaction />} />
          <Route path="/workflow/bulk-transacation" element={<BulkTransaction />} />
          <Route path="/workflow/allocations" element={<Allocations />} />
          <Route path="/workflow/rack-status-details" element={<RackStatusDetail />} />
          <Route path="/workflow/system-utility-queue" element={<SystemUtilityQueue />} />
          <Route path="/workflow/contract-dispatch" element={<ContractDispatch />} />
          <Route path="/workflow/view-report" element={<ViewReport />} />
          <Route path="/workflow/contract-transaction" element={<ContractTransaction />} />
          <Route path="/workflow/bulks-transfer-tank" element={<BulkTransferTank />} />
          <Route path="/workflow/gauge-transaction" element={<GaugeTransaction />} />
          <Route path="/workflow/user-session" element={<UserSessionScreen />} />
          <Route path="/workflow/users" element={<UsersScreen />} />
          <Route
            path="/workflow/reports"
            element={<Reports />}
          />
          <Route
            path="/workflow/vip-scully-communications"
            element={<VipScullyCommunicationsScreen />}
          />
          <Route
            path="/workflow/about"
            element={<About />}
          />
          <Route path="*" element={<Navigate to="/" replace />} />
          <Route path="/" element={<SiteLayout />}>
            <Route path="/" element={<PrivateRoutes><LandingPage /></PrivateRoutes>} />
            <Route path="/batch-mode" element={<BatchModeTerminalProfile />} />
            <Route path="/batch-mode-confirm" element={<BatchModeUpdate />} />
            <Route path="/user-settings" element={<UserSettings />} />
            <Route path="/maint/:screenId" element={<MaintTableScreen />} />
            <Route path="termProfileform" element={<TermProfileForm />} />
            <Route path="maint-details" element={<DetailMaintScreen />} />
            <Route path="/clone-record" element={<CloneRecord />} />
            <Route path="/create-record" element={<CreateRecord />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/testComponent" element={<ChangeActiveTank />} />
            <Route path="/change-password" element={<ChangePassword />} />
            <Route path="/workflow/Groups" element={<GroupsTableView />} />
            <Route path="/workflow/audit-logs" element={<AuditLogsScreen />} />
            <Route path="/workflow/rcu-ditto" element={<RCUDittoScreen />} />
            <Route path="/workflow/transaction-viewer" element={<TransactionViewerScreen />} />
            <Route path="/workflow/change-active-tank" element={<ChangeActiveTank />} />
            <Route path="/workflow/rack-status" element={<RackStatus />} />
            <Route path="/workflow/Groups-details" element={<GroupsDetailsPage />} />
            <Route path="/workflow/tank-gauge-entry" element={<TankGaugeEntryScreen />} />
            <Route path="/workflow/field-attribute-settings" element={<FieldAttributeSettingsScreen />} />
            <Route
              path="/workflow/tank-gauge-entry/recordDetail/:termID/:folioMonth/:folioNumber/:folioStatus"
              element={<TankGaugeEntryRecordDetail />}
            />
            <Route path="/workflow/condition-set" element={<ConditionSetScreen />} />
            <Route
              path="/workflow/condition-set/recordDetail/:conditionSetId"
              element={<ConditionSetRecordDetail />}
            />
            <Route
              path="/workflow/condition-set/newRecord"
              element={<ConditionSetRecordDetail />}
            />
            <Route path="/workflow/personnel-in-facility" element={<PersonnelInFacility />} />
            <Route path="/workflow/System-startup" element={<SystemStartup />} />
            <Route path="/workflow/file-export" element={<FileExport />} />
            <Route path="/workflow/folio-freeze-close" element={<FolioFreezeClose />} />
            <Route path="/workflow/device-communications" element={<DeviceCommunications />} />
            <Route path="/workflow/tank-strapping-tables" element={<TankStrappingTables />} />
            <Route
              path="/workflow/product-group-cross-reference"
              element={<ProductGroupCrossReferenceScreen />}
            />
            <Route path="/workflow/meter-entry" element={<MeterEntryScreen />} />
            <Route
              path="/workflow/meter-entry/recordDetail/:termID/:folioMonth/:folioNumber/:folioStatus"
              element={<MeterEntryRecordDetail />}
            />
            <Route path="/workflow/marquee-manager" element={<MarqueeManagerScreen />} />
            <Route path="/workflow/vehicles-in-facility" element={<VehiclesInFacility />} />
            <Route path="/workflow/IO-monitor" element={<IOMonitorScreen />} />
            <Route path="/workflow/manage-printers" element={<ManagePrinters />} />
            <Route path="/workflow/drivers-in-facility" element={<DriversInFacility />} />
            <Route path="/workflow/emcs-configuration" element={<EmcsConfiguration />} />
            <Route path="/workflow/emcs-manager" element={<EMCSManagerScreen />} />
            <Route path="/workflow/view-trace-logs" element={<ViewTraceLogs />} />
            <Route
              path="/workflow/drivers-through-facility"
              element={<DriversThroughFacilityScreen />}
            />
            <Route path="/workflow/traffic" element={<TrafficScreen />} />
            <Route path="/workflow/Non-bulk-transcation" element={<NonBulkTransaction />} />
            <Route path="/workflow/bulk-transcation" element={<BulkTransaction />} />
            <Route path="/workflow/report-queue" element={<ReportQueue />} />
            <Route path="/workflow/view-totalizer-data" element={<ViewTotalizerData />} />
            <Route path="/workflow/order-maintenance" element={<OrderMaintenance />} />
            <Route path="/workflow/order-maintenance-delete" element={<OrderMaintenanceBatchDelete />} />
            <Route path="/workflow/order-maintenance-modify" element={<OrderMaintenanceBatchModify />} />
            <Route
              path="/workflow/order-maintenance-details"
              element={<OrderMaintenanceNewRecord />}
            />
            <Route path="/workflow/tank-status" element={<TankStatus />} />
            <Route path="/workflow/non-bulk-transacation" element={<NonBulkTransaction />} />
            <Route path="/workflow/bulk-transacation" element={<BulkTransaction />} />
            <Route path="/workflow/allocations" element={<Allocations />} />
            <Route path="/workflow/rack-status-details" element={<RackStatusDetail />} />
            <Route path="/workflow/system-utility-queue" element={<SystemUtilityQueue />} />
            <Route path="/workflow/contract-dispatch" element={<ContractDispatch />} />
            <Route path="/workflow/custom-report-configuration" element={<CustomReportConfiguration />} />
            <Route
              path="/workflow/custom-report-configuration-detail"
              element={<CustomReportConfigurationDetail />}
            />
            <Route path="/workflow/user-session" element={<UserSessionScreen />} />
            <Route path="/workflow/users" element={<UsersScreen />} />
            <Route path="/workflow/user-session/recordDetail/:userId" element={<UserSessionDetailRecord />} />
            <Route
              path="/workflow/vip-scully-communications"
              element={<VipScullyCommunicationsScreen />}
            />
            <Route path="/workflow/tile-layout" element={<TileLayout />} />
            <Route path="*" element={<Navigate to="/" replace />} />
            <Route path="/workflow/system-utility" element={<SystemUtilities />} />
            <Route path="/workflow/view-utility" element={<ViewUtility />} />
            <Route path="/workflow/tank-strapping-tables-detail" element={<TankStrappingTablesDetail />} />
            <Route path="/workflow/document-builder" element={<DocumentBuilder />} />

            <Route path="/workflow/document-builder/Detail/:DocumentName" element={<DocumentBuilderDetail />} />
            <Route path="/workflow/document-builder/Clone/:DocumentNameToClone" element={<DocumentBuilderDetail />} />
            <Route path="/workflow/document-builder/New" element={<DocumentBuilderDetail />} />

            <Route path="/workflow/about" element={<About />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  </>
);

export default Main;
