import React from 'react';

// Librairies
import {
  Divider, Typography,
} from '@mui/material';
import clsx from 'clsx';

// Styles
import { useStyles } from './style';

// Components
import DropdownReport from '../DropdownReport';
import Input from '../../../Input';

interface IProps {
    handleChange: (option: any, name: string) => void;
    formData: any;
}
const CustomReportDropdownCard: React.FC<IProps> = ({ handleChange, formData }) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.modalBody}>
        <form action="POST" className={clsx(classes.form, 'flexEnd')}>
          <div className={clsx('flexRow', classes.wrapper)}>
            <DropdownReport
              label="Group Name"
              value={formData?.groupName}
              options={[{ value: 'admin', label: 'Admin: Admin' }, { value: 'admin', label: 'Group UI' }]}
              defaultValue={{ value: 'admin', label: 'Admin: Admin' }}
              width="70%"
              disabled
              style={{ margin: '2px 10px', borderLeft: '2px solid red !important' }}
            />
            <div style={{ width: '50px' }}>{' '}</div>
          </div>
          <div className={clsx('flexRow', classes.wrapper)}>
            <DropdownReport
              label="Select Report to Add"
              onChange={(e: any, option: any) => handleChange(option, 'selectedReport')}
              value={formData?.selectedReport}
              options={[{ value: 'newFolder', label: 'Create New Folder' }, { value: 'report1', label: 'Allocation' }]}
              width="70%"
              style={{ margin: '2px 10px' }}
            />
            <div style={{ width: '50px' }}>{' '}</div>
          </div>
          {formData?.selectedReport === 'newFolder' ? (
            <div className={clsx('flexRow', classes.wrapper)}>
              <Typography
                variant="caption"
                className={classes.label}
              >
                New Folder
              </Typography>
              <Input
                onChange={(e: any) => handleChange(e.target, 'newFolder')}
                name="newFolder"
                value={formData?.newFolder}
                style={{
                  width: '70.2%',
                  marginRight: '8px',
                }}
                required={false}
              />
              <div style={{ width: '50px' }}>
                <button
                  type="button"
                  className={classes.generateButton}
                >
                  Add
                </button>
              </div>
            </div>
          ) : '' }
        </form>
      </div>
      <Divider className={classes.divider} />
    </>
  );
};

export default CustomReportDropdownCard;
