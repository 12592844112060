import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  container: {
    width: '100%',
    boxSizing: 'border-box',
    height: 'auto',
    padding: '3px',
    border: '1px solid #ddd',
    marginBottom: '5px',
    marginTop: '0px !important',
  },
  item: {
    paddingTop: '2px !important',
    paddingBottom: '2px !important',
  },
  stepsContent: {
    width: '100%',
    boxSizing: 'border-box',
    fontSize: '18px',
    gap: 5,
  },
  step: {
    color: '#FFF',
    cursor: 'pointer',
  },
  stepNumber: {
    padding: '20px 1rem',
    fontSize: '24px',
  },
  stepTitle: { marginLeft: 8 },
});
