/* eslint-disable operator-linebreak */
/* eslint-disable camelcase */
import { Dispatch } from 'redux';
import {
  getTankGaugeEntryServerDate,
  getTankGaugeFullDate,
  toMonthName,
} from '../../../utils';
import Api from '../../api/index';
import {
  CLEAR_TANK_GAUGE_ENTRY,
  LOAD_TANK_GAUGE_ENTRY,
  LOAD_TANK_GAUGE_ENTRY_RECORD,
  SET_SELECTED_TANK_GAUGE_RECORD,
  SET_TANK_GAUGE_ENTRY_FORM_HEADER,
  CLEAR_TANK_GAUGE_ENTRY_FORM_HEADER,
  CLEAR_TANK_GAUGE_ENTRY_FORM_DATA,
  CLEAR_TANK_GAUGE_ENTRY_EXPORT_LINK,
  CLEAR_TANK_GAUGE_ENTRY_EXPORT_FILE,
  CLEAR_TANK_GAUGE_RESPONSE_NOTIFICATION,
  SET_TANK_GAUGE_ENTRY_FORM_DATA,
  SET_TANK_GAUGE_RESPONSE_NOTIFICATION,
  SET_GLOBAL_TANK_GAUGE_ENTRY_FORM_DATA,
  SET_TANK_GAUGE_ENTRY_EXPORT_FILE,
  SET_TANK_GAUGE_ENTRY_EXPORT_LINK,
  SET_TANK_GAUGE_ENTRY_MODAL_DATA,
  CLEAR_TANK_GAUGE_ENTRY_MODAL_DATA,
  SET_AUTO_TANK_GAUGE_DATA,
  CLEAR_TANK_GAUGE_ENTRY_RECORD,
  CLEAR_SELECTED_TANK_GAUGE_RECORD,
} from '../../constants/workflows';

// utils
export const getStatusLabel = (status: string) => {
  switch (status) {
    case 'C': {
      return 'Closed';
    }
    case 'F': {
      return 'Frozen';
    }
    case 'E': {
      return 'Frozen EOM';
    }
    case 'M': {
      return 'Closed EOM';
    }
    default:
      return 'Current';
  }
};
const formatDate = (date: string, time: string) => {
  const formattedDate = date
    ? `${date.slice(2, 4)}-${date.slice(4, 6)}-20${date.slice(0, 2)}`
    : '';
  const formattedTime = time ? `${time.slice(0, 2)}:${time.slice(2)}:00` : '';
  return `${formattedDate}  ${formattedTime}`;
};

export const getTankGaugeEntryData = () => async (dispatch: Dispatch) => {
  const response = await Api.get(
    'workflow038/data?userID=tms&screenID=WORKFLOW-038',
  );
  const data = response.data.data.map((x: any) => ({
    ...x,
    fol_mo: toMonthName(x.fol_mo),
    freeze_date: formatDate(x.freeze_date, x.freeze_time),
    open_date: formatDate(x.open_date, x.open_time),
    tt_status: getStatusLabel(x.tt_status),
    folioMonth: x.fol_mo,
    folioStatus: x.tt_status,
  }));
  dispatch({
    type: LOAD_TANK_GAUGE_ENTRY,
    payload: {
      data,
      hiddenColumns: response.data.hiddenColumns,
      visibleColumns: response.data.visibleColumns,
    },
  });
};

export const getNewColumns =
  (columns: string[]) => async (dispatch: Dispatch) => {
    const userID = localStorage.getItem('username');
    await Api.post('workflow038/updatecolumns', {
      userID,
      screenID: 'workflow-038',
      columns,
    });
    window.location.reload();
    dispatch({
      type: CLEAR_TANK_GAUGE_ENTRY,
    });
  };

export const setSelectedTankGaugeRecord = (selectedTankGaugeRecord: any) => ({
  type: SET_SELECTED_TANK_GAUGE_RECORD,
  payload: selectedTankGaugeRecord,
});

export const setFormData =
  (row: any, value: any, fieldId: string, index: number) => (dispatch: Dispatch, getState: any) => {
    const state = getState();
    const { formData } = state.Workflows.TankGaugeEntry;
    let newRow;
    if (formData[index]) {
      newRow = {
        ...formData[index],
        [`${fieldId}-${index}`]: value,
        [`rowedited-${index}`]: 'y',
      };
    } else {
      newRow = { ...row, [`${fieldId}-${index}`]: value };
    }
    dispatch({
      type: SET_TANK_GAUGE_ENTRY_FORM_DATA,
      payload: {
        row: newRow,
        index,
      },
    });
  };

export const setFormHeader =
  (value: any, field?: string) => (dispatch: Dispatch, getState: any) => {
    if (value.change) {
      const state = getState();
      const {
        formData,
        currentRecord: { data },
      } = state.Workflows.TankGaugeEntry;
      switch (field) {
        case 'invDate': {
          const newFormData = data.reduce(
            (acc: any, curr: any, index: number) => {
              if (formData[index]) {
                return {
                  ...acc,
                  [index]: {
                    ...curr,
                    ...formData[index],
                    [`inv_date-${index}`]: getTankGaugeEntryServerDate(
                      value.head_inv_date,
                    ),
                  },
                };
              }
              return {
                ...acc,
                [index]: {
                  ...curr,
                  [`inv_date-${index}`]: getTankGaugeEntryServerDate(
                    value.head_inv_date,
                  ),
                },
              };
            },
            {},
          );
          dispatch({
            type: SET_GLOBAL_TANK_GAUGE_ENTRY_FORM_DATA,
            payload: newFormData,
          });
          break;
        }
        case 'ambientTemperature': {
          const newFormData = data.reduce(
            (acc: any, curr: any, index: number) => {
              if (formData[index]) {
                return {
                  ...acc,
                  [index]: {
                    ...curr,
                    ...formData[index],
                    [`ambient_temperature-${index}`]:
                      value.head_ambient_temperature,
                  },
                };
              }
              return {
                ...acc,
                [index]: {
                  ...curr,
                  [`ambient_temperature-${index}`]:
                    value.head_ambient_temperature,
                },
              };
            },
            {},
          );
          dispatch({
            type: SET_GLOBAL_TANK_GAUGE_ENTRY_FORM_DATA,
            payload: newFormData,
          });
          break;
        }
        default:
          break;
      }
    }
    dispatch({
      type: SET_TANK_GAUGE_ENTRY_FORM_HEADER,
      payload: value,
    });
  };

export const clearTankGaugeEntryField =
  (fields: string[]) => (dispatch: Dispatch, getState: any) => {
    const state = getState();
    const {
      formData,
      currentRecord: { data },
    } = state.Workflows.TankGaugeEntry;
    const newFormData = data.reduce((acc: any, curr: any, index: number) => {
      const result: any = formData[index]
        ? {
          ...curr,
          ...formData[index],
        }
        : {
          ...curr,
          ...formData[index],
        };
      fields.forEach((field) => {
        result[`${field}-${index}`] = '0';
      });
      return {
        ...acc,
        [index]: result,
      };
    }, {});
    dispatch({
      type: SET_GLOBAL_TANK_GAUGE_ENTRY_FORM_DATA,
      payload: newFormData,
    });
  };

export const getAutoTankGaugeData =
  ({ termid, folmo, folno }: any) => async (dispatch: Dispatch) => {
    const payload = {
      data: {
        cmd: 'tankgauge',
        parm: 'atg',
        data: {
          termid,
          termuom: 'G',
          folmo,
          folno,
        },
      },
    };
    const response = await Api.post('synajax/procajax', payload);
    dispatch({
      type: SET_AUTO_TANK_GAUGE_DATA,
      payload: response.data[0],
    });
  };

export const getTankGaugeEntryRecord =
  ({
    termID, folioMonth, folioNumber, folioStatus,
  }: any) => async (dispatch: Dispatch) => {
    const userID = localStorage.getItem('username');
    const response = await Api.get(
      `workflow038/showrecord?termID=${termID}&userID=${userID}&screenID=WORKFLOW-038
      &groupID=Admin&folioMonth=${folioMonth}&folioNumber=${folioNumber}
      &folioStatus=${folioStatus === 'null' ? '' : folioStatus}&lang=en-US`,
    );
    const rowsIndexes: string[] = Object.keys(response.data.data).map(
      (key) => key.split('-')[1],
    );
    const noRedondancerowsIndexes = Array.from(new Set(rowsIndexes)).filter(
      (rowIndex: string) => rowIndex,
    );
    const dataKeys: string[] = Object.keys(response.data.data);
    const tableData = noRedondancerowsIndexes.map((rowIndex: string) => {
      const filtredKeys = dataKeys.filter((key) => key.endsWith(`-${rowIndex}`));
      const rowIndexData = filtredKeys.reduce(
        (acc, curr) => ({
          ...acc,
          index: curr.split('-')[1],
          [curr]: response.data.data[curr],
        }),
        {},
      );
      return rowIndexData;
    });
    dispatch({
      type: LOAD_TANK_GAUGE_ENTRY_RECORD,
      payload: {
        data: tableData,
        fieldList: response.data.fieldList,
        fieldHiddenList: response.data.fieldHiddenList,
        historyModal: response.data.historyModal['Tank Gauge'],
        modalFields: response.data.gaugeModalFields,
        header: {
          head_ambient_temperature: response.data.data.head_ambient_temperature,
          head_inv_date: response.data.data.head_inv_date,
        },
      },
    });
  };

export const saveTankGaugeEntryDetailChanges =
  () => async (dispatch: Dispatch, getState: any) => {
    const state = getState();
    const {
      formData,
      selectedRecord,
      currentRecord: { fieldHiddenList },
    } = state.Workflows.TankGaugeEntry;
    const userID = localStorage.getItem('username');
    const recordValues = {
      term_id: selectedRecord.term_id.trim(),
      fol_mo: selectedRecord.folioMonth,
      fol_no: selectedRecord.fol_no,
      tt_status: selectedRecord.folioStatus,
    };
    const hiddenListKeys = Object.keys(fieldHiddenList);
    const changedHiddenList = Object.keys(formData).reduce((acc, curr) => {
      const filtredHiddenListKeys = hiddenListKeys.filter((key) => key.includes(`-${curr}-`));
      const hiddenListValues = filtredHiddenListKeys.reduce(
        (result, key) => ({
          ...result,
          [`${key.split('-hidden')[0]}`]: fieldHiddenList[key]?.value,
        }),
        {},
      );
      return {
        ...acc,
        ...hiddenListValues,
      };
    }, {});
    const recentDataFields = {
      prevGaugeExtRecords: fieldHiddenList?.previousGaugeExtRecords?.value,
      prevGaugeRecords: fieldHiddenList?.previousGaugeRecords?.value,
    };
    const changedRowValues = Object.keys(formData).reduce((acc, curr: any) => {
      const rowObj = formData[curr];
      const newRowObj = Object.keys(rowObj).reduce((result, key) => {
        let value;
        const fieldData: any = rowObj?.[key]?.['\u0000*\u0000parm'];
        if (fieldData) {
          if (fieldData?.date) {
            const date = getTankGaugeFullDate(fieldData?.date, fieldData?.time);
            value = getTankGaugeEntryServerDate(date);
          } else {
            value = fieldData?.value;
          }
        } else if (rowObj?.[key]?.value) {
          value = rowObj?.[key]?.value;
        } else {
          value = rowObj?.[key];
        }
        return {
          ...result,
          [key]: value,
          [`rowedited-${curr}`]: 'y',
        };
      }, {});
      return {
        ...acc,
        ...newRowObj,
      };
    }, {});
    const form_data = {
      ...recordValues,
      ...changedHiddenList,
      ...recentDataFields,
      ...changedRowValues,
    };
    const dataToSend = {
      UserID: userID,
      lang: 'en-US',
      form_data,
    };
    try {
      const response = await Api.post('workflow038/updaterecord', dataToSend);
      if (response.status === 200) {
        dispatch({
          type: SET_TANK_GAUGE_RESPONSE_NOTIFICATION,
          payload: { type: 'success' },
        });
      }
    } catch (err: any) {
      console.error(err.response.data);
    }
  };

export const autoTankGauge =
  (rows: any[]) => async (dispatch: Dispatch, getState: any) => {
    const state = getState();
    const {
      formData,
      currentRecord: { data },
    } = state.Workflows.TankGaugeEntry;
    const newFormData = { ...formData };
    const tanksIds = rows.map((row: any) => row.tank);
    data.forEach((row: any) => {
      if (tanksIds.includes(row[`tank_id-${row.index}`]?.value)) {
        const newRow: any = {};
        Object.keys(row).forEach((field: any) => {
          if (field === 'index') return;
          const fieldNameWithoutIndex = field.split('-')[0];
          const indexInRows = tanksIds.findIndex(
            (id: string) => id === row[`tank_id-${row.index}`].value,
          );
          newRow[field] =
            rows?.[indexInRows]?.[fieldNameWithoutIndex] || row[field];
          newFormData[row.index] = newRow;
        });
      }
    });
    dispatch({
      type: SET_GLOBAL_TANK_GAUGE_ENTRY_FORM_DATA,
      payload: newFormData,
    });
  };

export const applyTankGaugeEntryDialog =
  (row: any, tankId: any, rowIndex: any) => async (dispatch: Dispatch, getState: any) => {
    const state = getState();
    const {
      currentRecord: { data },
    } = state.Workflows.TankGaugeEntry;
    const changedRow = data.find(
      (field: any) => field[`tank_id-${rowIndex}`]?.value === tankId,
    );
    const formattedRow: any = {};
    Object.keys(row || {}).forEach((element: any) => {
      formattedRow[row[element].serverKey] = row[element].value;
    });
    const newRow: any = {};
    Object.keys(changedRow).forEach((key) => {
      if (key === 'index') return;
      const fieldNameWithoutIndex = key.split('-')[0];
      newRow[key] = formattedRow[fieldNameWithoutIndex] || changedRow[key];
    });
    dispatch({
      type: SET_TANK_GAUGE_ENTRY_FORM_DATA,
      payload: {
        row: newRow,
        index: rowIndex,
      },
    });
  };

export const exportTankGaugeEntryColumnsFile =
  (folioNumber: string, folioMonth: string) => async (dispatch: Dispatch) => {
    const payload = {
      export_table: 'Gauge',
      workflow_screen_id: 'WORKFLOW-038',
      filter_name: '',
      export_data: false,
      file_type: 'xlsx',
      report_type: 'Gauge',
      selected_columns: {
        term_id: 'false',
      },
      spool_all_columns: true,
      filters: {
        table: 'Gauge',
        relation: 'all',
        filter_name: 'equals',
        filters: [
          {
            column: 'fol_no',
            operator: 'equal',
            compare_value: folioNumber,
          },
          {
            column: 'fol_mo',
            operator: 'equal',
            compare_value: folioMonth,
          },
        ],
      },
    };
    const response = await Api.post('exportworkflow/spooldata', payload);
    dispatch({
      type: SET_TANK_GAUGE_ENTRY_EXPORT_FILE,
      payload: response.data.data[0],
    });
  };

export const getTankGaugeEntryColumnsDownloadLink =
  (exportFile: string) => async (dispatch: Dispatch) => {
    let attempts = 0;
    const downloadFn = () => {
      if (attempts === 10) return;
      Api.get(`export/download-file?file=${exportFile}`)
        .then((res: any) => {
          if (res.status === 200) {
            dispatch({
              type: SET_TANK_GAUGE_ENTRY_EXPORT_LINK,
              payload: res.data,
            });
          } else {
            setTimeout(downloadFn, 100);
            attempts += 1;
          }
        })
        .catch(() => {
          setTimeout(downloadFn, 100);
          attempts += 1;
        });
    };
    if (attempts === 10) return;
    downloadFn();
  };

export const getTankGaugeModalData =
  (row: any, index: number) => async (dispatch: Dispatch, getState: any) => {
    const { formData, currentRecord } = getState().Workflows.TankGaugeEntry;
    const payload = {
      data: {
        cmd: 'tankgauge',
        data: '',
        parm: {
          summary: 1,
          extendedsummary: '',
          command: 25,
          readonly: false,
          termid: 'B344',
          termuom: currentRecord?.modalFields?.termuom,
          gross_net_flag: '',
          rangetype: '',
          tankgroup: '',
          throughput_gross: '',
          throughput_net: '',
          tankuom: row?.[`tank_uom-${index}`]?.value,
          tankid: row?.[`tank_id-${index}`]?.value,
          prodid: row?.[`prod_id-${index}`]?.value,
          gross: row?.[`gross_inv-${index}`]?.['\u0000*\u0000parm']
            ? row?.[`gross_inv-${index}`]?.['\u0000*\u0000parm']?.value
            : row?.[`gross_inv-${index}`],
          productlevel: row?.[`prod_level-${index}`]?.['\u0000*\u0000parm']
            ? row?.[`prod_level-${index}`]?.['\u0000*\u0000parm']?.value
            : row?.[`prod_level-${index}`],
          temperature: row?.[`temperature-${index}`]?.['\u0000*\u0000parm']
            ? row?.[`temperature-${index}`]?.['\u0000*\u0000parm']?.value
            : row?.[`temperature-${index}`],
          ambienttemperature: row?.[`ambient_temperature-${index}`]?.[
            '\u0000*\u0000parm'
          ]
            ? row?.[`ambient_temperature-${index}`]?.['\u0000*\u0000parm']
              ?.value
            : row?.[`ambient_temperature-${index}`],
          gravitydensity: row?.[`grav_density-${index}`]?.['\u0000*\u0000parm']
            ? row?.[`grav_density-${index}`]?.['\u0000*\u0000parm']?.value
            : row?.[`grav_density-${index}`],
          waterlevel: row?.[`water_level-${index}`]?.['\u0000*\u0000parm']
            ? row?.[`water_level-${index}`]?.['\u0000*\u0000parm']?.value
            : row?.[`water_level-${index}`],
          linefill: row?.[`line_fill-${index}`]?.['\u0000*\u0000parm']
            ? row?.[`line_fill-${index}`]?.['\u0000*\u0000parm']?.value
            : row?.[`line_fill-${index}`],
          linefill1: row?.[`line_fill1-${index}`]?.['\u0000*\u0000parm']
            ? row?.[`line_fill1-${index}`]?.['\u0000*\u0000parm']?.value
            : row?.[`line_fill1-${index}`],
          linefill2: row?.[`line_fill2-${index}`]?.['\u0000*\u0000parm']
            ? row?.[`line_fill2-${index}`]?.['\u0000*\u0000parm']?.value
            : row?.[`line_fill2-${index}`],
          linefill3: row?.[`line_fill3-${index}`]?.['\u0000*\u0000parm']
            ? row?.[`line_fill3-${index}`]?.['\u0000*\u0000parm']?.value
            : row?.[`line_fill3-${index}`],
          usegross: '',
        },
      },
    };
    const response = await Api.post('synajax/procajax', payload);
    dispatch({
      type: SET_TANK_GAUGE_ENTRY_MODAL_DATA,
      payload: response.data,
    });
  };

export const getTableFreshData =
  (changedRow: any, textInputValue: any, fieldId: string, index: number) => async (dispatch: Dispatch, getState: any) => {
    const { formData, currentRecord } = getState().Workflows.TankGaugeEntry;
    const row = formData[index] ? formData[index] : changedRow;
    row[`${fieldId}-${index}`] = textInputValue;
    const param = {
      rowid: index,
      bottoms: row?.[`bottoms-${index}`]
        ? row?.[`bottoms-${index}`]?.value
        : row?.[`bottoms-${index}`],
      usegross: fieldId === 'gross_inv' ? 'y' : '',
      summary: 1,
      extendedsummary: '',
      command: 25,
      readonly: false,
      termid: 'B344',
      termuom: currentRecord?.modalFields?.termuom,
      tankuom: row?.[`tank_uom-${index}`]
        ? row?.[`tank_uom-${index}`]?.value
        : row?.[`tank_uom-${index}`],
      tankid: row?.[`tank_id-${index}`]
        ? row?.[`tank_id-${index}`]?.value
        : row?.[`tank_id-${index}`],
      tankgroup: '',
      prodid: row?.[`prod_id-${index}`]
        ? row?.[`prod_id-${index}`]?.value
        : row?.[`prod_id-${index}`],
      gross: row?.[`gross_inv-${index}`]?.['\u0000*\u0000parm']
        ? row?.[`gross_inv-${index}`]?.['\u0000*\u0000parm']?.value
        : row?.[`gross_inv-${index}`],
      productlevel: row?.[`prod_level-${index}`]?.['\u0000*\u0000parm']
        ? row?.[`prod_level-${index}`]?.['\u0000*\u0000parm']?.value
        : row?.[`prod_level-${index}`],
      temperature: row?.[`temperature-${index}`]?.['\u0000*\u0000parm']
        ? row?.[`temperature-${index}`]?.['\u0000*\u0000parm']?.value
        : row?.[`temperature-${index}`],
      ambienttemperature: row?.[`ambient_temperature-${index}`]?.[
        '\u0000*\u0000parm'
      ]
        ? row?.[`ambient_temperature-${index}`]?.['\u0000*\u0000parm']?.value
        : row?.[`ambient_temperature-${index}`],
      gravitydensity: row?.[`grav_density-${index}`]?.['\u0000*\u0000parm']
        ? row?.[`grav_density-${index}`]?.['\u0000*\u0000parm']?.value
        : row?.[`grav_density-${index}`],
      waterlevel: row?.[`water_level-${index}`]?.['\u0000*\u0000parm']
        ? row?.[`water_level-${index}`]?.['\u0000*\u0000parm']?.value
        : row?.[`water_level-${index}`],
      linefill: row?.[`line_fill-${index}`]?.['\u0000*\u0000parm']
        ? row?.[`line_fill-${index}`]?.['\u0000*\u0000parm']?.value
        : row?.[`line_fill-${index}`],
      linefill1: row?.[`line_fill1-${index}`]?.['\u0000*\u0000parm']
        ? row?.[`line_fill1-${index}`]?.['\u0000*\u0000parm']?.value
        : row?.[`line_fill1-${index}`],
      linefill2: row?.[`line_fill2-${index}`]?.['\u0000*\u0000parm']
        ? row?.[`line_fill2-${index}`]?.['\u0000*\u0000parm']?.value
        : row?.[`line_fill2-${index}`],
      linefill3: row?.[`line_fill3-${index}`]?.['\u0000*\u0000parm']
        ? row?.[`line_fill3-${index}`]?.['\u0000*\u0000parm']?.value
        : row?.[`line_fill3-${index}`],
    };
    const payload = {
      data: {
        cmd: 'tankgauge',
        data: '',
        parm: param,
      },
    };
    const response = await Api.post('synajax/procajax', payload);
    if (response.data) {
      const { 0: standardData, topModalRes, bottomModalRes } = response.data;
      const mergedResponse = {
        ...standardData,
        ...topModalRes,
        ...bottomModalRes,
      };
      const resultRow = {
        ...row,
        [`grav_density-${index}`]: mergedResponse?.GravityDensity,
        [`temperature-${index}`]: mergedResponse?.temperature,
        [`ambient_temperature-${index}`]: mergedResponse?.ambientTemperature,
        [`bottoms-${index}`]: mergedResponse?.bottoms,
        [`gross_inv-${index}`]: mergedResponse?.grossObservedVolume,
        [`net_inv-${index}`]: mergedResponse?.netStandardVolume,
        [`line_fill-${index}`]: mergedResponse?.lineFill,
        [`line_fill1-${index}`]: mergedResponse?.lineFill_1,
        [`line_fill2-${index}`]: mergedResponse?.lineFill_2,
        [`line_fill3-${index}`]: mergedResponse?.lineFill_3,
      };
      dispatch({
        type: SET_TANK_GAUGE_ENTRY_FORM_DATA,
        payload: {
          row: resultRow,
          index,
        },
      });
    }
  };

export const getModalFreshData =
  (modalState: any, row: any, index: number, serverKey: string) => async (dispatch: Dispatch, getState: any) => {
    const { currentRecord } = getState().Workflows.TankGaugeEntry;
    const payload = {
      data: {
        cmd: 'tankgauge',
        data: '',
        parm: {
          summary: 1,
          extendedsummary: '',
          command: 25,
          readonly: false,
          termid: 'B344',
          throughput_gross: '',
          throughput_net: '',
          gross_net_flag: '',
          termuom: currentRecord?.modalFields?.termuom,
          tankuom: row?.[`tank_uom-${index}`]?.value,
          tankid: row?.[`tank_id-${index}`]?.value,
          tankgroup: '',
          prodid: row?.[`prod_id-${index}`]?.value,
          gross: modalState?.grossObservedVolume?.value,
          productlevel: modalState.productlevel.value,
          temperature: modalState?.temperature?.value,
          ambienttemperature: modalState?.ambientTemperature?.value,
          gravitydensity: modalState?.GravityDensity?.value,
          waterlevel: modalState?.waterlevel?.value,
          linefill: modalState?.lineFill?.value,
          linefill1: modalState?.lineFill_1?.value,
          linefill2: modalState?.lineFill_2?.value,
          linefill3: modalState?.lineFill_3?.value,
          usegross: serverKey === 'gross_inv' ? 'y' : '',
          bottoms: modalState?.bottoms?.value,
          rangetype: '',
          changeFieldId: `${serverKey}-modal`,
        },
      },
    };
    const response = await Api.post('synajax/procajax', payload);
    dispatch({
      type: SET_TANK_GAUGE_ENTRY_MODAL_DATA,
      payload: response.data,
    });
  };

export const clearTankGaugeEntryModalData = () => ({
  type: CLEAR_TANK_GAUGE_ENTRY_MODAL_DATA,
});

export const clearTankGaugeEntryCurrentRecord = () => ({
  type: CLEAR_TANK_GAUGE_ENTRY_RECORD,
});

export const clearTankGaugeEntrySelectedRecord = () => ({
  type: CLEAR_SELECTED_TANK_GAUGE_RECORD,
});

export const clearTankGaugeEntryFormData = () => ({
  type: CLEAR_TANK_GAUGE_ENTRY_FORM_DATA,
});

export const clearFormHeader = () => ({
  type: CLEAR_TANK_GAUGE_ENTRY_FORM_HEADER,
});

export const clearTankGaugeEntryExportFile = () => ({
  type: CLEAR_TANK_GAUGE_ENTRY_EXPORT_FILE,
});

export const clearTankGaugeEntryDownloadLink = () => ({
  type: CLEAR_TANK_GAUGE_ENTRY_EXPORT_LINK,
});

export const clearTankGaugeEntryNotification = () => ({
  type: CLEAR_TANK_GAUGE_RESPONSE_NOTIFICATION,
});
