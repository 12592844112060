import React from 'react';

// Librairies
import { Divider, Menu, MenuItem } from '@mui/material';

// Styles
import { useStyles } from './style';

interface IProps {
  anchorEl: any;
  onClose: () => void;
  handleAddLine: () => void;
  handleStartTms: () => void;
  handleStopTms: () => void;
  openRecordCHangeHistoryDialog: () => void;
  status: string;
}

const SystemStartupOptions: React.FC<IProps> = ({
  anchorEl,
  onClose,
  handleAddLine,
  handleStartTms,
  handleStopTms,
  openRecordCHangeHistoryDialog,
  status,
}) => {
  const classes = useStyles();
  return (
    <Menu
      classes={{ root: classes.root, list: classes.list, paper: classes.paper }}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
    >
      <MenuItem classes={{ root: classes.menuItem }} onClick={handleAddLine}>
        New
      </MenuItem>
      <Divider />
      <MenuItem
        classes={{ root: classes.menuItem }}
        onClick={handleStartTms}
        disabled={status !== 'Stopped'}
      >
        Start TMS
      </MenuItem>
      <MenuItem
        classes={{ root: classes.menuItem }}
        onClick={handleStopTms}
        disabled={status !== 'Running' && status !== 'Problem' && status !== 'Unknown'}
      >
        Stop TMS
      </MenuItem>
      <Divider />
      <MenuItem
        classes={{ root: classes.menuItem }}
        onClick={openRecordCHangeHistoryDialog}
      >
        Record change history...
      </MenuItem>
    </Menu>
  );
};

export default SystemStartupOptions;
