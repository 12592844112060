import {
  GET_USER_LANGUAGE, LOGIN_FAIL, LOGIN_START, LOGIN_SUCCESS, SET_USER_LANGUAGE,
} from '../constants';

type action={
    type:string,
    payload:any
}
const initialState = {
  user: {},
  loading: true,
  isAuthenticated: false,
  Language: 'EN',
};

const LoginReducer = (state: any = initialState, action:action): any => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        data: action.payload,
      };
    case LOGIN_START:
      return {
        ...state,
        loading: true,
        isAuthenticated: false,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        error: action.payload,
      };
    case SET_USER_LANGUAGE:
      return {
        ...state,
        loading: false,
        Language: action.payload,
      };
    case GET_USER_LANGUAGE:
      return {
        ...state,
        loading: false,
        Language: action.payload,
      };
    default:
      return state;
  }
};

export default LoginReducer;
