import React from 'react';

// Librairies
import { Grid } from '@mui/material';

// Components
import Select from '../../../components/Select';

// Styles
import { useStyles } from './style';

// Actions

// Utils

interface IGroupScreenFormProps {
  groupScreenState: { [key: string]: any };
  groupsScreensFields: any[];
  handleGroupScreenFieldChange: (name: string, option: any) => void;
}

const GroupScreenForm: React.FC<IGroupScreenFormProps> = ({
  groupScreenState,
  groupsScreensFields,
  handleGroupScreenFieldChange,
}) => {
  const classes = useStyles();
  return (
    <div>
      <div className="flexColumn" style={{ gap: 5 }}>
        <Grid container rowSpacing={1}>
          {groupsScreensFields?.map((field) => (
            <>
              <Grid item xs={12} md={4}>
                <div className={classes.label}>
                  {field?.['\u0000*\u0000nameID'] === 'uigroupid'
                    ? 'UI Group'
                    : 'UI Screen'}
                </div>
              </Grid>
              <Grid item xs={12} md={8}>
                <Select
                  options={field?.['\u0000*\u0000parm']?.options}
                  width="100%"
                  required
                  value={groupScreenState[field?.['\u0000*\u0000nameID']]}
                  name={field?.['\u0000*\u0000nameID']}
                  onChange={(_, option) => handleGroupScreenFieldChange(
                    field?.['\u0000*\u0000nameID'],
                    option,
                  )}
                />
              </Grid>
            </>
          ))}
        </Grid>
      </div>
    </div>
  );
};

export default GroupScreenForm;
