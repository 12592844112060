import { Dispatch } from 'redux';
import Api from '../../api/index';
import {
  LOAD_VIEW_TRACE_LOGS,
  SET_VIEW_TRACE_LOGS_SEARCH_DATA,
  CLEAR_VIEW_TRACE_LOGS_SEARCH_DATA,
  SET_VIEW_TRACE_LOGS_LOADING,
  CLEAR_VTL_RESPONSE_NOTIFICATION,
  SET_VTL_RESPONSE_NOTIFICATION,
} from '../../constants/workflows';

export const getViewTraceLogsData = () => async (dispatch: Dispatch) => {
  const userID = localStorage.getItem('username');
  const response = await Api.get(
    `workflow048/data?screenID=WORKFLOW-048&userID=${userID}`,
  );
  dispatch({
    type: LOAD_VIEW_TRACE_LOGS,
    payload: response.data,
  });
};

export const searchViewTraceLogs = (searchData: any, remove: boolean) => async (dispatch: Dispatch, getState: any) => {
  if (searchData) {
    const state = getState();
    const userID = localStorage.getItem('username');
    const { customfilterlist } = state.Workflows.ViewTraceLogs;
    dispatch({
      type: SET_VIEW_TRACE_LOGS_LOADING,
      payload: { loading: true },
    });
    const formData = {
      userID,
      screenID: 'WORKFLOW-048',
      maxLines: searchData['Max Lines']?.value || '',
      preset: searchData['Preset Date Time']?.value || '',
      startDate:
          searchData['System Start Date']?.inputValue?.replaceAll('/', '-')
          || '',
      endDate:
          searchData['System End Date']?.inputValue?.replaceAll('/', '-') || '',
      filterid: !remove ? searchData?.Filter?.value : '',
      searchPattern: searchData?.['Search Pattern'] || '',
      publishselectstate: searchData?.Share?.value || '',
      lang: 'en-US',
      updatefilter: 'y',
      triggersearch: 'true',
      customfilterlist,
      deletedcustomfilterlist: remove ? searchData?.Filter?.value : '',
    };
    try {
      const response = await Api.post('workflow048/SearchCriteria', {
        form_data: formData,
      });
      dispatch({
        type: SET_VIEW_TRACE_LOGS_SEARCH_DATA,
        payload: response.data,
      });
      if (response.status === 200) {
        dispatch({
          type: SET_VTL_RESPONSE_NOTIFICATION,
          payload: {
            type: 'success',
            FilterId: searchData?.Filter?.value?.includes(
              'tms:_filter:WORKFLOW-048',
            )
              ? searchData.Filter?.label
              : searchData.Filter?.value,
            request: remove ? 'remove' : 'save',
          },
        });
        const newSearchData = await Api.get(
          `workflow048/data?screenID=WORKFLOW-048&userID=${userID}`,
        );
        dispatch({
          type: LOAD_VIEW_TRACE_LOGS,
          payload: newSearchData.data,
        });
      }
    } catch (err: any) {
      dispatch({
        type: SET_VTL_RESPONSE_NOTIFICATION,
        payload: {
          type: 'failure',
        },
      });
      dispatch({
        type: SET_VIEW_TRACE_LOGS_LOADING,
        payload: { loading: false },
      });
      console.error(err.response.data);
    }
  }
};

export const clearViewTraceLogsSearchData = () => ({
  type: CLEAR_VIEW_TRACE_LOGS_SEARCH_DATA,
});

export const clearViewTraceLogsNotification = () => ({
  type: CLEAR_VTL_RESPONSE_NOTIFICATION,
});
