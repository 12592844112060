import React from 'react';

// Librairies
import { Divider, Menu, MenuItem } from '@mui/material';

// Styles
import { useStyles } from './style';

interface IProps {
  anchorEl: any;
  onClose: () => void;
  openColumnsDialog: () => void;
  exportColumns: () => void;
}

const ProductGroupCrossReferenceOptions: React.FC<IProps> = ({
  anchorEl,
  onClose,
  openColumnsDialog,
  exportColumns,
}) => {
  const classes = useStyles();

  return (
    <Menu
      classes={{ root: classes.root, list: classes.list, paper: classes.paper }}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
    >
      <MenuItem classes={{ root: classes.menuItem }} onClick={openColumnsDialog}>
        Columns...
      </MenuItem>
      <Divider />
      <MenuItem classes={{ root: classes.menuItem }} onClick={exportColumns}>
        Export Columns
      </MenuItem>
    </Menu>
  );
};

export default ProductGroupCrossReferenceOptions;
