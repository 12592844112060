import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    overflow: 'hidden',
  },
  wrapper: {
    position: 'relative',
    padding: '0px 5px',
  },
  infoSection: {
    marginTop: 5,
    display: 'block',
    padding: '2px',
    margin: '0 0 2px',
    height: '25px',
    color: '#333',
    backgroundColor: '#f5f5f5',
    border: '1px solid #ccc',
    fontSize: 13,
    wordBreak: 'break-all',
    wordWrap: 'break-word',
  },
  button: {
    borderWidth: '1px 1px 1px 5px !important',
    borderColor: '#35aa47 !important',
    position: 'relative',
    backgroundColor: '#ddd !important',
    outline: 'none !important',
    border: '1px solid black !important',
    padding: '4px 10px !important',
    color: 'black !important',
    marginBottom: 5,
    '&:before': {
      content: '""',
      position: 'absolute',
      left: -5,
      width: 5,
      height: '100%',
      background: '#35aa47',
    },
    '&:hover': {
      color: '#fff !important',
      backgroundColor: '#545454 !important',
    },
  },
});
