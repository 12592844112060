/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  TextField,
  ButtonBase,
  Select,
  MenuItem,
  Button,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import axios from 'axios';
import ClearIcon from '@mui/icons-material/Clear';
import Autocomplete from '@mui/material/Autocomplete';
import { v4 as uuidv4 } from 'uuid';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useStyles } from './style';
import './customStyle.css';
import OrderMaintenanceRowComponent from './orderMaintenanceStepTableData';
import { submitOrderMaintenanceTransaction } from '../../../redux/actions/workflows/orderMaintenance';

const OrderMaintenanceProductStep = ({
  values,
  handleDetailSelectChange,
  handleBack,
  valuesToSend,
  orderItems,
}: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [uomVal, setUomVal] = useState('');
  const [uomList, setUomList] = useState<any>(null);
  const [tooltipOpen, setTooltipOpen] = useState<any>([]);
  const [validationMsg, setValidationMsg] = useState<any>(
    {
      OrderItemNo: 'Missing product selection',
      OrderQty: 'Missing Order Quantity',
    },
  );

  const [showExtraFields, setShowExtraFields] = useState<any>(false);
  const [itemsRecord, setItemsRecord] = useState<any>([]);
  const [mode, setMode] = useState<any>('new');
  const {
    chainedList,
    responseMsg,
  } = useSelector((state: any) => state.Workflows.OrderMaintenanceReducer);
  const AddNewRow = () => {
    setItemsRecord([
      ...itemsRecord,
      {
        id: uuidv4(),
      },
    ]);
  };
  useEffect(() => {
    // eslint-disable-next-line no-debugger
    axios.get(`${process.env.REACT_APP_API_URL}workflow161/get_uom?screenID=WORKFLOW-161`).then((res) => {
      setUomList(res?.data);
    });
  }, []);
  useEffect(() => {
    if (orderItems[0] !== undefined) {
      setUomVal(orderItems[0]?.UOM);
      setItemsRecord(orderItems);
      setMode('update');
    }
  }, [orderItems]);

  const submitTransaction = () => {
    const final = {
      steps: valuesToSend,
      step4: itemsRecord,
      mode,
    };
    dispatch(submitOrderMaintenanceTransaction(final));
  };
  useEffect(() => {
    if (responseMsg?.status === 200) { navigate('/workflow/order-maintenance'); }
  }, [responseMsg]);
  useEffect(() => {
    const orderQtyMsg = itemsRecord?.filter((item:any) => item?.OrderQty === undefined || item?.OrderQty === '');
    const orderQtyPositiveNumberMsg = itemsRecord?.filter((item:any) => item?.OrderQty !== undefined && item?.OrderQty <= 0);
    const orderItemMsg = itemsRecord?.filter((item:any) => item?.ProdID === undefined || item?.ProdID === '');
    const tempObj1 = { OrderQty: '' };
    const tempObj2 = { OrderItem: '' };
    if (orderQtyMsg?.length > 0) {
      tempObj1.OrderQty = 'Missing Order Quantity';
    }
    if (orderQtyPositiveNumberMsg?.length > 0) {
      tempObj1.OrderQty = 'Order Quantity Must be Grater Than 0';
    }
    if (orderItemMsg?.length > 0) {
      tempObj2.OrderItem = 'Missing Product selection';
    }
    setValidationMsg({ ...tempObj2, ...tempObj1 });
  }, [itemsRecord]);
  const handleAlert = () => {
    setValidationMsg({ OrderQty: '', OrderItem: '' });
  };
  const CancelNewRow = (id:any) => () => {
    setItemsRecord(itemsRecord.filter((address:any) => address.id !== id));
  };
  return (
    <div>
      <div className={classes.productHeader}>
        <ButtonBase
          className={classes.addDetailButton}
          onClick={() => AddNewRow()}
        >
          Add New Detail
        </ButtonBase>
        <ButtonBase
          className={classes.modifySearchButton}
          onClick={() => setShowExtraFields(!showExtraFields)}
        >
          Show/Hide Extra Fields
        </ButtonBase>
        <ButtonBase
          className={classes.modifySearchButton}
        >
          Order Seals...
        </ButtonBase>
        <div className="custom-uom">
          <div>UOM</div>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            className="uom-select"
            onChange={(e) => setUomVal(e.target.value)}
            name="OrderItemNo"
            value={uomVal}
            size="small"
            sx={{
              width: '100%',
              borderRadius: '0px',
              marginTop: '5px',
              '& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select':
                              {
                                padding: '2px 8px',
                                border: '1px solid #E4E4E4',
                              },
            }}
          >
            {
              uomList?.map((item:any) => (
                <MenuItem value={item?.UOM} className="list-item">
                  {item?.UOMDescription}
                </MenuItem>
              ))
              }
          </Select>
        </div>
      </div>

      <div className={classes.OrderItemSection}>
        {
          (validationMsg?.OrderQty !== '' || validationMsg?.OrderItem !== '')
          && (
          <div className="required-alert">
            <div className="icon">
              <div>
                <p>{(validationMsg?.OrderQty !== '' || validationMsg?.OrderItem !== '') && 'Missing required fields'}</p>
                <p>{validationMsg?.OrderQty}</p>
                <p>{validationMsg?.OrderItem}</p>
              </div>
              <ClearIcon className="icon-style" onClick={handleAlert} />
            </div>
          </div>
          )
}
        <table className="orderMaintenance">
          {
                itemsRecord?.map((item:any, index:any) => (
                  <>
                    <OrderMaintenanceRowComponent
                      rowName={`rowDataList[${index}]`}
                      key={`rowList-${index}`}
                      index={index}
                      item={item?.id}
                      classes={classes}
                      CancelNewRow={CancelNewRow}
                      showExtraFields={showExtraFields}
                      items={chainedList?.chain_products_list}
                      contractItems={chainedList?.chain_fields_list?.ContractItem}
                      handleDetailSelectChange={handleDetailSelectChange}
                      itemsRecord={itemsRecord}
                      setItemsRecord={setItemsRecord}
                      values={values}
                      setValidationMsg={setValidationMsg}
                      validationMsg={validationMsg}
                      setTooltipOpen={setTooltipOpen}
                      tooltipOpen={tooltipOpen}
                    />
                  </>

                ))
            }

        </table>
      </div>
      <div className="button-submit">
        <Button
          variant="contained"
          onClick={handleBack}
          sx={{ mt: 1, mr: 1, textTransform: 'none' }}
        >
          Prev Step
        </Button>
        <Button
          variant="contained"
          onClick={() => { submitTransaction(); }}
          sx={{ mt: 1, mr: 1, textTransform: 'none' }}
          disabled={itemsRecord?.length <= 0 || validationMsg?.OrderQty !== '' || validationMsg?.OrderItem !== ''}
        >
          Submit Transaction
        </Button>
      </div>
    </div>
  );
};
export default OrderMaintenanceProductStep;
