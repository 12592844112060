import { Dispatch } from 'redux';
import Api from '../api/index';
import {
  FETCH_ACCOUNTS,
  FETCH_CUSTOMERS,
  FETCH_DESTINATIONS,
  FETCH_SUPPLIERS,
} from '../constants/Vsx';

export const fetchSuppliers = () => async (dispatch: Dispatch) => {
  const response = await Api.get(
    'tablescreen/tablemaintdata?tableID=Supplier&page=1&results_per_page=-1&fields=supplier_no%2Csupplier_no%3ASupplier.supplier_name%20',
  );
  const payload = response.data.data.map(
    (x: any) => x['supplier_no:Supplier.supplier_name'],
  );
  dispatch({
    type: FETCH_SUPPLIERS,
    payload,
  });
};

export const fetchCustomers = (supplierNumber: string) => async (dispatch: Dispatch) => {
  const response = await Api.get(
    `https://api-dev-tf.toptechc1.com/v1/tablescreen/tablemaintdata?
      tableID=Customer&page=1&results_per_page=-1&where=supplier_no%3D
    ${supplierNumber}&fields=cust_no%2Ccust_no%3ACustomer.cust_name`,
  );
  const payload = response.data.data.map(
    (x: any) => x['cust_no:Customer.cust_name'],
  );
  dispatch({
    type: FETCH_CUSTOMERS,
    payload,
  });
};

export const fetchAccounts = (supplierNumber: string, customerNumber: string) => async (dispatch: Dispatch) => {
  const response = await Api.get(
    `https://api-dev-tf.toptechc1.com/v1/tablescreen/tablemaintdata?tableID=Account&page=1&results_per_page=-1
    &where=supplier_no%3D
    ${supplierNumber}%20and%20cust_no%3D${customerNumber}&fields=acct_no%2Cacct_no%3AAccount.acct_name`,
  );
  const payload = response.data.data.map(
    (x: any) => x['acct_no:Account.acct_name'],
  );
  dispatch({
    type: FETCH_ACCOUNTS,
    payload,
  });
};

export const fetchDestinations = (supplierNumber: string, customerNumber: string, accountNumber: string) => async (dispatch: Dispatch) => {
  const response = await Api.get(
    `https://api-dev-tf.toptechc1.com/v1/tablescreen/tablemaintdata?tableID=Destination&page=1&results_per_page=-1&where=supplier_no%3D
    ${supplierNumber}%20and%20cust_no%3D${customerNumber}%20and%20acct_no%3D
    ${accountNumber}&fields=destination_no%2Cdestination_no%3ADestination.destination_name`,
  );
  const payload = response.data.data.map(
    (x: any) => x['destination_no:Destination.destination_name'],
  );
  dispatch({
    type: FETCH_DESTINATIONS,
    payload,
  });
};
