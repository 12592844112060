import { combineReducers } from 'redux';
import SystemUtilities from './systemUtilities';
import SystemUtilityQueue from './systemUtilityQueue';
import ViewSystemUtility from './viewUtility';

export default combineReducers({
  SystemUtilities,
  SystemUtilityQueue,
  ViewSystemUtility,
});
