/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
import React, { useEffect, useRef, useState } from 'react';

// Librairies
import { InputBase } from '@mui/material';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';

// Components
import SubHeader from '../../../components/subHeader';
import TmsHeader from '../../../components/header';
import Select from '../../../components/Select';
import Loader from '../../../components/Loader';

// Styles
import { useStyles } from './style';
import IOMonitorTable from '../../../components/IO-MonitorTable';

// Actions
import {
  fetchIoMonitorData,
  getMonitorsValues,
} from '../../../redux/actions/workflows/ioMonitor';
import { getScreensList } from '../../../redux/actions/ScreenActions';

// Utils
import { getRecordToShowValue, searchRecords } from '../../../utils';
import useWindowDimensions from '../../../utils/windowDimensions';

// Utils
const LeftSideTable = [
  { label: 'menu', key: 'menu' },
  { label: 'Device', key: 'device' },
  { label: 'Function', key: 'func' },
  { label: 'Comment', key: 'comment' },
];

const RightSideTable = [
  { label: 'Device', key: 'device' },
  { label: 'Function', key: 'func' },
  { label: 'Comment', key: 'comment' },
  { label: 'Value', key: 'value' },
];

interface IMonitorProps {
  fetchedIoMonitors: any;
  selectedIoMonitors: any;
  builtIoMonitors?: any;
  selectRow?: (raw: any, isRowSelected: boolean) => void;
  handleSelectFirst20?: (existingItems: any[]) => void;
  handleUnSelectAll?: () => void;
}

const RightSideMonitor: React.FC<IMonitorProps> = ({ builtIoMonitors }) => {
  const classes = useStyles();
  const [tableState, setTableState] = useState<any>(() => ({
    tableData: [],
    currentPage: 1,
    searchedDataCount: 0,
    recordsNumber: 'Auto',
    searchText: '',
    orderBy: '',
    order: 'asc',
  }));
  const recordsToShow = getRecordToShowValue(
    tableState.recordsNumber,
    builtIoMonitors.length,
    18,
  );

  useEffect(() => {
    if (tableState.searchText) {
      const filtredRecords = searchRecords(
        tableState.searchText,
        builtIoMonitors,
        RightSideTable,
      );
      setTableState({
        ...tableState,
        searchedDataCount: filtredRecords.length,
        tableData: filtredRecords.slice(0, recordsToShow),
        currentPage: 1,
      });
    } else {
      setTableState({
        ...tableState,
        searchedDataCount: 0,
        tableData: builtIoMonitors.slice(0, recordsToShow),
      });
    }
  }, [builtIoMonitors, recordsToShow, tableState.searchText]);

  const handleSelectChange = (e: any, option: any) => {
    setTableState({ ...tableState, recordsNumber: option, currentPage: 1 });
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTableState({
      ...tableState,
      searchText: e.target.value,
      currentPage: 1,
    });
  };

  const handleSortTable = (key: string) => {
    setTableState({
      ...tableState,
      orderBy: key,
      order: tableState.order === 'asc' ? 'desc' : 'asc',
    });
  };

  const handlePaginationChange = (event: any, targetPage: any) => {
    if (!tableState.searchText) {
      const selectedData = builtIoMonitors.slice(
        (targetPage - 1) * recordsToShow,
        targetPage * recordsToShow,
      );
      setTableState({
        ...tableState,
        currentPage: targetPage,
        tableData: selectedData,
      });
    } else {
      const selectedData = searchRecords(
        tableState.searchText,
        builtIoMonitors,
        RightSideTable,
      ).slice((targetPage - 1) * recordsToShow, targetPage * recordsToShow);
      setTableState({
        ...tableState,
        currentPage: targetPage,
        tableData: selectedData,
      });
    }
  };

  return (
    <div className={classes.container}>
      <div className={clsx('flexStartSpaceBetweenRow', classes.utilsContainer)}>
        <div className={clsx('flexRow')}>
          <span className={classes.label}>Records :</span>
          <Select
            options={['Auto', '10', '20', '25', '35', '50', 'All']}
            width={200}
            disableClearInput
            value={tableState.recordsNumber}
            onChange={handleSelectChange}
          />
        </div>
        <div className="flexRow">
          <span className={classes.label}>Search :</span>
          <InputBase
            className={classes.searchInput}
            value={tableState.searchText}
            onChange={handleSearchChange}
          />
        </div>
      </div>
      <IOMonitorTable
        handleClickRow={() => null}
        headerItems={RightSideTable}
        searchText={tableState.searchText}
        allDataCount={builtIoMonitors.length}
        handlePaginationChange={handlePaginationChange}
        recordsToShow={recordsToShow}
        tableState={tableState}
        sortTable={handleSortTable}
        order={tableState.order}
        orderBy={tableState.orderBy}
      />
    </div>
  );
};

const LeftSideMonitor: React.FC<IMonitorProps> = ({
  fetchedIoMonitors,
  selectedIoMonitors,
  selectRow,
  handleSelectFirst20,
  handleUnSelectAll,
}) => {
  const classes = useStyles();
  const tableRef = useRef<any>();
  const [tableState, setTableState] = useState<any>(() => ({
    tableData: [],
    currentPage: 1,
    searchedDataCount: 0,
    recordsNumber: 'Auto',
    searchText: '',
    orderBy: '',
    order: 'asc',
  }));
  const recordsToShow = getRecordToShowValue(
    tableState.recordsNumber,
    fetchedIoMonitors.length,
    18,
  );

  const handleSelectChange = (e: any, option: any) => {
    setTableState({ ...tableState, recordsNumber: option, currentPage: 1 });
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTableState({
      ...tableState,
      searchText: e.target.value,
      currentPage: 1,
    });
  };

  useEffect(() => {
    if (tableState.searchText) {
      const filtredRecords = searchRecords(
        tableState.searchText,
        fetchedIoMonitors,
        RightSideTable,
      );
      setTableState({
        ...tableState,
        searchedDataCount: filtredRecords.length,
        tableData: filtredRecords.slice(0, recordsToShow),
        currentPage: 1,
      });
    } else {
      setTableState({
        ...tableState,
        searchedDataCount: 0,
        tableData: fetchedIoMonitors.slice(0, recordsToShow),
      });
    }
  }, [fetchedIoMonitors, recordsToShow, tableState.searchText]);

  const handlePaginationChange = (event: any, targetPage: any) => {
    if (!tableState.searchText) {
      const selectedData = fetchedIoMonitors.slice(
        (targetPage - 1) * recordsToShow,
        targetPage * recordsToShow,
      );
      setTableState({
        ...tableState,
        currentPage: targetPage,
        tableData: selectedData,
      });
    } else {
      const selectedData = searchRecords(
        tableState.searchText,
        fetchedIoMonitors,
        RightSideTable,
      ).slice((targetPage - 1) * recordsToShow, targetPage * recordsToShow);
      setTableState({
        ...tableState,
        currentPage: targetPage,
        tableData: selectedData,
      });
    }
  };

  const handleSortTable = (key: string) => {
    setTableState({
      ...tableState,
      orderBy: key,
      order: tableState.order === 'asc' ? 'desc' : 'asc',
    });
  };

  return (
    <div className={classes.container}>
      <div className={clsx('flexStartSpaceBetweenRow', classes.utilsContainer)}>
        <div className={clsx('flexRow')}>
          <span className={classes.label}>Records :</span>
          <Select
            options={['Auto', '10', '20', '25', '35', '50', 'All']}
            width={200}
            disableClearInput
            value={tableState.recordsNumber}
            onChange={handleSelectChange}
          />
        </div>
        <div className="flexRow">
          <span className={classes.label}>Search :</span>
          <InputBase
            className={classes.searchInput}
            value={tableState.searchText}
            onChange={handleSearchChange}
          />
        </div>
      </div>
      <IOMonitorTable
        handleClickRow={() => null}
        headerItems={LeftSideTable}
        selectedIoMonitors={selectedIoMonitors}
        selectRow={selectRow}
        searchText={tableState.searchText}
        handleSelectFirst20={() => handleSelectFirst20 && handleSelectFirst20(tableState.tableData)}
        handleUnSelectAll={handleUnSelectAll}
        allDataCount={fetchedIoMonitors.length}
        handlePaginationChange={handlePaginationChange}
        recordsToShow={recordsToShow}
        tableState={tableState}
        sortTable={handleSortTable}
        order={tableState.order}
        orderBy={tableState.orderBy}
        tableRef={tableRef}
      />
    </div>
  );
};

const IOMonitorScreen: React.FC = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { height } = useWindowDimensions();
  const { data: fetchedIoMonitors, builtData } = useSelector(
    (state: any) => state?.Workflows?.IoMonitor,
  );
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const rootRef = useRef<any>();

  useEffect(() => {
    dispatch(fetchIoMonitorData());
    dispatch(getScreensList());
  }, []);

  const handleSelectRow = (row: any, isRowSelected: boolean) => {
    if (isRowSelected) {
      const newSelectedRows = selectedRows.filter(
        (x: any) => !(
          x?.comment === row?.comment
            && x?.device === row?.device
            && x?.func === row?.func
        ),
      );
      dispatch(
        getMonitorsValues(
          newSelectedRows.map((x) => Object.values(x).map((y: any) => y.trim())),
        ),
      );
      setSelectedRows(newSelectedRows);
    } else {
      if (selectedRows.length === 20) return;
      dispatch(
        getMonitorsValues(
          [...selectedRows, row].map((x) => Object.values(x).map((y: any) => y.trim())),
        ),
      );
      setSelectedRows([...selectedRows, row]);
    }
  };

  const handleSelectFirst20 = (existingItems: any[]) => {
    setSelectedRows(existingItems.slice(0, 20));
    dispatch(
      getMonitorsValues(
        existingItems
          .slice(0, 20)
          .map((x: any) => Object.values(x).map((y: any) => y.trim())),
      ),
    );
  };

  const handleUnSelectAll = () => {
    setSelectedRows([]);
    dispatch(getMonitorsValues([]));
  };

  return (
    <>
      <TmsHeader />
      <SubHeader title="I/O Monitor">
        <div className="flexRow" />
        <Loader getWorkflowData={fetchIoMonitorData} />
      </SubHeader>
      <div
        className="flexRow fullWidth takeTheRest overFlowYAuto"
        // style={{ height: window.innerHeight - 95 }}
        ref={rootRef}
      >
        <LeftSideMonitor
          fetchedIoMonitors={fetchedIoMonitors}
          selectedIoMonitors={selectedRows}
          selectRow={handleSelectRow}
          handleSelectFirst20={handleSelectFirst20}
          handleUnSelectAll={handleUnSelectAll}
        />
        <RightSideMonitor
          fetchedIoMonitors={fetchedIoMonitors}
          selectedIoMonitors={selectedRows}
          selectRow={handleSelectRow}
          builtIoMonitors={builtData}
        />
      </div>
    </>
  );
};

export default IOMonitorScreen;
