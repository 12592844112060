import React, { useEffect, useState } from 'react';

// Librairies
import {
  Alert, Button, Snackbar,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Components
import SubHeader from '../../../components/subHeader';
import TmsHeader from '../../../components/header';
import WorkflowTable from '../../../components/WorkflowTable';
import OptionsButton from '../../../components/optionsButton';
import ProductGroupCrossReferenceOptions
  from '../../../components/ProductGroupCrossReferenceOptions';

// Actions
import {
  clearProductGroupCrossReferenceColumnsFileName,
  exportProductGroupCrossReferenceColumnsFileName,
  getProductGroupCrossReferenceColumnsDownloadLink,
  getProductGroupCrossReferenceData,
  updateProductGroupCrossReferenceColumns,
  clearProductGroupCrossReferenceColumnsExportLink,
  clearPGCRNotification,
} from '../../../redux/actions/workflows/productGroupCrossReference';
import ColumnsDialog from '../../../components/ColumnsDialog';
import { useWorkflowState } from '../../../hooks/useWorkflowState';
import WorkflowTableUtils from '../../../components/WorkflowTableUtils';

// Utils
const tableHeader = {
  prod_group: 'Product Group',
  'prod_group:ProductGroup.pgp_desc': 'Product Group',
  effect_date: 'Effective Date',
  expire_date: 'Expiration Date',
  product_count: 'Product Count',
  term_id: 'Terminal ID',
};

// Utils
const columnsLabels = {
  prod_group: 'Product Group',
  'prod_group:ProductGroup.pgp_desc':
    'Product Group - Product Group Description',
  effect_date: 'Effective Date',
  expire_date: 'Expiration Date',
  product_count: 'Product Count',
  term_id: 'Terminal ID',
};

const searchHeader = [
  { label: 'Product Group', key: 'prod_group' },
  { label: 'Product Group', key: 'pgp_desc' },
  { label: 'Effective Date', key: 'effect_date' },
  { label: 'Expiration Date', key: 'expire_date' },
  { label: 'Product Count', key: 'product_count' },
  { label: 'Terminal ID', key: 'term_id' },
];

const ProductGroupCrossReferenceScreen: React.FC = () => {
  const {
    data,
    visibleColumns,
    hiddenColumns,
    exportFile,
    exportLink,
    responseNotification,
  } = useSelector((state: any) => state.Workflows.ProductGroupCrossReference);
  const [pgcrState, setPgcrState] = useState<any>(() => ({
    columnsDialogOpen: false,
    snackBarOpen: false,
  }));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    handleCloseMenu,
    handleOptionClick,
    handlePaginationChange,
    handleSearchChange,
    handleSelectChange,
    handleSortTable,
    recordsToShow,
    tableState,
  } = useWorkflowState(searchHeader, data, getProductGroupCrossReferenceData);

  const handleOpenColumnsDialog = () => {
    setPgcrState({ ...pgcrState, columnsDialogOpen: true, anchorEl: null });
  };

  const handleCloseColumnsDialog = () => {
    setPgcrState({ ...pgcrState, columnsDialogOpen: false });
  };

  const exportColumns = () => {
    dispatch(exportProductGroupCrossReferenceColumnsFileName());
    handleCloseMenu();
  };

  useEffect(() => {
    if (responseNotification?.type === 'success') {
      dispatch(getProductGroupCrossReferenceData());
      setPgcrState((prev: any) => ({ ...prev, snackBarOpen: true }));
    }
  }, [responseNotification?.type]);

  useEffect(() => {
    if (exportLink) {
      const link = document.createElement('a');
      link.href = exportLink;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    if (exportFile) {
      dispatch(getProductGroupCrossReferenceColumnsDownloadLink(exportFile));
    }
    return () => {
      dispatch(clearProductGroupCrossReferenceColumnsExportLink());
      dispatch(clearProductGroupCrossReferenceColumnsFileName());
      dispatch(clearPGCRNotification());
    };
  }, [exportLink, exportFile]);

  const handleSubmitNewColumns = (newColumns: string[]) => {
    dispatch(updateProductGroupCrossReferenceColumns(newColumns));
  };

  const handleRestoreDefault = () => {
    dispatch(
      updateProductGroupCrossReferenceColumns([
        'prod_group:ProductGroup.pgp_desc',
        'effect_date',
        'expire_date',
        'product_count',
      ]),
    );
  };

  const handleClickRow = (row: any) => {
    navigate(`recordDetail/${row.term_id.trim()}/${row.prod_group}`);
  };

  const handleCloseSnackBar = () => {
    setPgcrState({ ...pgcrState, snackBarOpen: false });
    dispatch(clearPGCRNotification());
  };

  return (
    <>
      <TmsHeader />
      <SubHeader title="Product Group Cross Reference">
        <OptionsButton handleClick={handleOptionClick} />
        <ProductGroupCrossReferenceOptions
          anchorEl={tableState.anchorEl}
          openColumnsDialog={handleOpenColumnsDialog}
          exportColumns={exportColumns}
          onClose={handleCloseMenu}
        />
      </SubHeader>
      <WorkflowTableUtils
        handleSearchChange={handleSearchChange}
        handleSelectChange={handleSelectChange}
        recordsNumber={tableState.recordsNumber}
        searchText={tableState.searchText}
      />
      <WorkflowTable
        handleClickRow={handleClickRow}
        visibleColumns={visibleColumns}
        allDataCount={data.length}
        handlePaginationChange={handlePaginationChange}
        recordsToShow={recordsToShow}
        tableHeader={tableHeader}
        tableState={tableState}
        order={tableState.order}
        orderBy={tableState.orderBy}
        searchText={tableState.searchText}
        sortTable={handleSortTable}
      />
      <ColumnsDialog
        title="Configure Visible Columns"
        onClose={handleCloseColumnsDialog}
        open={pgcrState.columnsDialogOpen}
        visibleColumns={visibleColumns}
        hiddenColumns={hiddenColumns}
        columnsLabels={columnsLabels}
        submitNewColumns={handleSubmitNewColumns}
        handleRestoreDefault={handleRestoreDefault}
      />
      <Snackbar
        open={pgcrState.snackBarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackBar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseSnackBar} severity="success">
          SUCCESS
          <div>
            GroupProductXRef updated Successfully
            {' '}
            <Button onClick={() => navigate(-1)}>(Review)</Button>
            {' '}
          </div>
        </Alert>
      </Snackbar>
    </>
  );
};

export default ProductGroupCrossReferenceScreen;
