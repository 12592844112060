import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {},
  description: {
    fontSize: '1rem',
  },
  statusHeadingDiv: {
    width: '100%',
    height: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '5px 0px',
  },
  statusHeading: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  divider: {
    marginTop: '0.5em',
    marginBottom: '0.1em',
  },
  fields: {
    fontSize: '0.8rem',
  },
});
