import {
  LOAD_IO_MONITORS,
  CLEAR_IO_MONITORS,
  LOAD_BUILT_IO_MONITORS,
} from '../../constants/workflows';

const INITIAL_STATE = {
  data: [],
  builtData: [],
};

const ioMonitorReducer = (state = INITIAL_STATE, { type, payload }: any) => {
  switch (type) {
    case LOAD_IO_MONITORS: {
      return {
        ...state,
        data: payload,
      };
    }
    case LOAD_BUILT_IO_MONITORS: {
      return {
        ...state,
        builtData: payload,
      };
    }
    case CLEAR_IO_MONITORS: {
      return {
        ...state,
        data: [],
      };
    }
    default:
      return state;
  }
};

export default ioMonitorReducer;
