/* eslint-disable react/require-default-props */
import React from 'react';

// Librairies
import {
  ButtonBase,
  DialogActions,
  DialogContent,
  Divider,
} from '@mui/material';

// Components
import { DialogComponent } from '../Dialog/Dialog.component';
import ChangeHistory from './RecordChangeHistory';

// Styles
import { useStyles } from './style';

interface IProps {
  onClose: () => void;
  open: boolean;
  historyModalData: any;
  tabLabel: string;
  tableHeader?: any;
  searchHeader?: any;
  limitNumber?: number;
  tableTitle?: string;
  defaultRecordsToShow?: number;
}

const WorkflowRecordChangeHistory: React.FC<IProps> = ({
  onClose,
  open,
  historyModalData,
  tabLabel,
  tableHeader,
  searchHeader,
  limitNumber,
  tableTitle,
  defaultRecordsToShow,
}) => {
  const classes = useStyles();
  return (
    <>
      <DialogComponent
        onClose={onClose}
        open={open}
        title="Record Change History..."
      >
        <DialogContent>
          {open ? (
            <ChangeHistory
              historyModalData={historyModalData}
              tabLabel={tabLabel}
              tableHeader={tableHeader}
              searchHeader={searchHeader}
              limitNumber={limitNumber}
              tableTitle={tableTitle}
              defaultRecordsToShow={defaultRecordsToShow}
            />
          ) : null}
        </DialogContent>
        <Divider variant="middle" />
        <DialogActions className={classes.dialogActions}>
          <span>
            History records limited to most recent
            {' '}
            {limitNumber}
          </span>
          <span className="takeTheRest" />
          <ButtonBase onClick={onClose} className={classes.cancelButton}>
            {' '}
            Close
            {' '}
          </ButtonBase>
        </DialogActions>
      </DialogComponent>
    </>
  );
};

export default WorkflowRecordChangeHistory;
