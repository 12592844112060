/* eslint-disable no-nested-ternary */
import { Close } from '@mui/icons-material';
import {
  Box, TextField, Typography,
} from '@mui/material';
import { createPortal } from 'react-dom';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { LoadRecord, LoadTables, SetColumnss } from '../../redux/actions/ScreenActions';
import ControlButton from '../controlButton';

// const itemsFromBackend = [
//   { id: uuid(), content: "First task" },
//   { id: uuid(), content: "Second task" },
//   { id: uuid(), content: "Third task" },
//   { id: uuid(), content: "Fourth task" },
//   { id: uuid(), content: "Fifth task" },
// ];
const dragEl:any = document.getElementById('draggable');

const optionalPortal = (styles:any, element:any) => {
  if (styles.position === 'fixed') {
    return createPortal(
      element,
      dragEl,
    );
  }
  return element;
};
interface myProps {

  handleClose: () => void;
}

const ColumnsScreen = ({ handleClose }:myProps) => {
  const {
    loading, data, columnss, activeScreen, allTableColumns, ScreensList, allLabels,
  } = useSelector((state:any) => state.ScreenReducer);
  const propertyNames = activeScreen?.fields?.split(',') || [];
  console.log(propertyNames);
  console.log('allTableColumns', allTableColumns.filter((item:any) => item.charAt(0) !== '#'));

  const [Visible, setVisible] = useState<any>([]);
  const [searching, setSearching] = useState<boolean>(false);
  const [searchedColumns, setSearchedColumns] = useState<any>([]);
  const columnsFromBackend = {
    [uuid()]: {
      name: 'Requested',
      items: allTableColumns.filter((item:any) => !propertyNames.includes(item) && item.charAt(0) !== '#'),
    },
    [uuid()]: {
      name: 'To do',
      items: propertyNames,
    },
  };
  const [columns, setColumns] = useState(columnsFromBackend);

  const handleSearch = (e:any) => {
    setSearching(e.target.value.length > 0);
    // eslint-disable-next-line no-multi-assign
    const filteredRows = allTableColumns.filter((str:any) => (allLabels?.[str] || str).toLowerCase().includes(e.target.value.toLowerCase()));
    setSearchedColumns(filteredRows);
  };
  useEffect(() => {
    // console.log('searchedColumns', searchedColumns);
  }, [searchedColumns]);
  const dispatch = useDispatch();
  const onDragEnd = (result: any) => {
    if (!result.destination) return;
    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const indexToRemove = sourceItems.findIndex((item) => item === result.draggableId);
      if (indexToRemove !== -1) {
        const [removed] = sourceItems.splice(indexToRemove, 1);
        destItems.splice(destination.index, 0, removed);
        setColumns({
          ...columns,
          [source.droppableId]: {
            ...sourceColumn,
            items: sourceItems,
          },
          [destination.droppableId]: {
            ...destColumn,
            items: destItems,
          },

        });
        setVisible([...Visible, destItems]);
      }
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
    }
  };
  const handleDispatch = async () => {
    await dispatch(LoadTables({
      tableID: activeScreen.tableID,
      page: '1',
      results_per_page: '10',
      where: activeScreen.where || '',
      fields: Object.values(columns)[1].items.join(),
      EmptyRows: activeScreen.EmptyRows,
      redirect: activeScreen.redirect,
      screenId: activeScreen.screenId,
      whereRecord: activeScreen.whereRecord,
      order_by: '',
      keys: activeScreen.keys,
      defaultcolumns: activeScreen.defaultcolumns,
      fieldsConcatenated: activeScreen.fieldsConcatenated,
    }));
    dispatch(LoadRecord({
      tableID: activeScreen.tableID,
      screenID: activeScreen.screenId,
      fields: Object.values(columns)[1].items.join(),
      where: '',
    }));
    handleClose();
  };
  const handelRestore = async () => {
    const screen = ScreensList.filter((item: any) => item.screenId === activeScreen.screenId)[0];
    if (!screen) {
      return;
    }
    const defaultFieldsArray = [...screen?.fields.split(',')];
    const uniqueDefaultFields = new Set(defaultFieldsArray);
    const defaultFields = Array.from(uniqueDefaultFields).join(',');
    await dispatch(LoadTables({
      tableID: activeScreen.tableID,
      page: activeScreen.page,
      results_per_page: activeScreen.results_per_page,
      where: activeScreen.where || '',
      fields: defaultFields,
      EmptyRows: activeScreen.EmptyRows,
      redirect: activeScreen.redirect,
      screenId: activeScreen.screenId,
      whereRecord: activeScreen.whereRecord,
      order_by: '',
      keys: activeScreen.keys,
      defaultcolumns: activeScreen.defaultcolumns,
      fieldsConcatenated: activeScreen.fieldsConcatenated,
    }));
    dispatch(LoadRecord({
      tableID: activeScreen.tableID,
      screenID: activeScreen.screenId,
      fields: defaultFields,
      where: '',
    }));
    handleClose();
  };
  const correctLabel = (label: string):string => {
    if (label === 'Carrier Number') {
      return `${label} - Name`;
    } if (label === 'State') {
      return `${label} - Region Description`;
    }
    return label;
  };
  return (

    <Box
      style={{
        height: '450px',

      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <TextField
          size="small"
          label="search"
          style={{
            alignSelf: 'flex-end',
            margin: '3px 10px',

          }}
          onChange={handleSearch}
        />
      </div>
      <div style={{
        padding: '10px 10px',
      }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '400px',
            width: '98%',
            alignSelf: 'center',
            height: '30px',
            border: '1px solid #d8d8d8',
            boxShadow: 5,
          }}
        >
          <Typography fontWeight="bold">Hidden Column (s)</Typography>
          <Typography fontWeight="bold">Visible Column (s)</Typography>
        </Box>
      </div>

      <div
        style={{ display: 'flex', justifyContent: 'center', height: '100%' }}
      >
        <DragDropContext
          onDragEnd={(result) => onDragEnd(result)}
        >
          {Object.entries(columns).map(([columnId, column]:any, index) => (

            <Droppable droppableId={columnId} key={columnId}>
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={{
                    border: '3px solid #f2f2f2',
                    background: snapshot.isDraggingOver ? '#fff' : '#fff',
                    padding: 4,
                    width: 560,
                    height: 430,
                    marginTop: 10,
                    marginBottom: 10,
                    overflow: 'auto',
                  }}
                >
                  {column.items.filter((item:any) => (!searching ? true : searchedColumns.includes(item))).map((val:any, key:any) => (
                    <Draggable
                      key={val}
                      draggableId={val}
                      index={key}
                    >
                      {(providedChild, snapshotChild) => (
                        <div>
                          {optionalPortal(providedChild.draggableProps.style, (
                            <div
                              ref={providedChild.innerRef}
                              {...providedChild.draggableProps}
                              {...providedChild.dragHandleProps}
                              style={{
                                userSelect: 'none',
                                padding: 5,
                                margin: '0 0 2px 0',
                                minHeight: '1px',
                                border: '1px solid #f2f2f2',
                                backgroundColor: snapshotChild.isDragging
                                  ? '#ffe284'
                                  : '#ffffff',
                                color: '#000',
                                ...providedChild.draggableProps.style,
                              }}
                            >
                              {correctLabel(allLabels?.[val] || val)}
                            </div>))}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>

          ))}
        </DragDropContext>
      </div>
      <div
        style={{
          width: '97%',
          justifyContent: 'space-between',
          display: 'flex',
          gap: '5px',
          padding: '2px 15px',
          marginTop: 20,
        }}
      >
        <Typography fontStyle="italic" fontWeight="normal">
          Note: At least one column must be chosen
        </Typography>
        <div style={{
          display: 'flex',
          gap: 5,

        }}
        >
          <ControlButton
            label="restore default columns"
            color="#852b99"
            disabled={false}
            handleClick={handelRestore}
          />
          <ControlButton
            label="apply"
            color="#00c851"
            disabled={Object.values(columns)[1].items.length === 0}
            handleClick={handleDispatch}
          />
          <ControlButton
            label="cancel"
            color="#ffbb33"
            disabled={false}
            handleClick={handleClose}
          />
        </div>

      </div>
    </Box>
  );
};

export default ColumnsScreen;
