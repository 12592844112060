import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Alert, CircularProgress, Snackbar, Typography,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import Subnavbar from '../../components/Sub-navbar/Subnavbar';
import {
  dissmissConfirmationAlert,
  LoadRecord,
  LoadTables,
  UpdateRecord,
} from '../../redux/actions/ScreenActions';
import FormBodyTerminal from '../../components/formBodyTermDetail/formBodyTerminal';
import FormHeaderTerminal from '../../components/FormHeaderTermDetail/FormHeaderTerminal';
import TmsHeader from '../../components/header';
import ChangeRecordModal from '../../components/changeRecordModal';
import { RecordChangeHistory } from
  '../../components/recordChangeHistoryDialog/RecordChangeHistory';
import EmbeddedSubnavbar from './subNavEmbedded/subNavEmbedded';
import { LoadRecordEmbbeded, UpdateRecordEmbedded } from '../../redux/actions/embbededScreensActions';

const DetailsMaintEmbeddedScreen = (props: any) => {
  const {
    handleClose: handleCloseModal,
    handleEmbeddedClose,
  } = props;

  const location = useLocation();
  const navigate = useNavigate();
  const routeBack: any = location.state;
  const dispatch = useDispatch();
  const {
    recordDetails, parsedValues, activeScreen, openConfirmationAlert, confirmationAlert, config, RecordsList, modifyDep,
  } = useSelector(
    (state: any) => state.EmbbededScreenReducer,
  );
  const [values, setValues] = useState<any>([]);
  const [valuesToSend, setValuesToSend] = useState<any>([]);
  const [Visible, setVisible] = useState(false);
  const [recordHistoryDialogOpen, setRecordHistoryDialogOpen] = useState(false);
  const [isModifiy, setIsModifiy] = useState(false);
  const [Count, setCount] = useState(0);
  const [IschangeRecord, setIschangeRecord] = useState(false);
  const [showOptionalFields, setShowOptionalFields] = useState(false);
  const whereEdit = JSON.stringify(modifyDep);
  const {
    Language,
  } = useSelector((state: any) => state.LoginReducer);
  const handleClose = () => {
    dispatch(dissmissConfirmationAlert());
  };
  const handleShowOptionalFields = () => {
    setShowOptionalFields(!showOptionalFields);
  };

  useEffect(() => {
    if (!IschangeRecord) {
      dispatch(
        LoadRecordEmbbeded({
          tableID: activeScreen.tableID,
          screenID: activeScreen.screenId,
          where: whereEdit,
          modifyDep,
          fields: activeScreen.fields,
          lang: Language,
        }),
      );
    } else {
      dispatch(LoadRecordEmbbeded(
        {
          ...RecordsList[Count],
          tableID: activeScreen.tableID,
          screenID: activeScreen.screenId,
          fields: activeScreen.fields,
        },
      ));
    }
  }, [Count]);

  useEffect(() => {
    const recordIndex = RecordsList.findIndex((a:any) => JSON.stringify(a.modifyDep) === JSON.stringify(modifyDep));
    setCount(recordIndex);
  }, []);

  useEffect(() => {
    setValues(parsedValues);
    setValuesToSend(parsedValues);
  }, [parsedValues]);

  const nextRecord = () => {
    setCount(Count + 1);
    setIschangeRecord(true);
  };
  const previousRecord = () => {
    setCount(Count - 1);
    setIschangeRecord(true);
  };
  const handleCloseConfirm = () => {
    setVisible(!Visible);
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    setValuesToSend({
      ...valuesToSend,
      [name]: value,
    });
    setIsModifiy(true);
  };
  const handleSelectChange = (
    name: any,
    e: React.ChangeEvent<HTMLInputElement>,
    newValue: any,
  ) => {
    console.log('newValue', newValue);
    setValues({
      ...values,
      [name]: newValue,
    });
    setValuesToSend({
      ...valuesToSend,
      [name]: newValue.value,
    });
    setIsModifiy(true);
  };

  console.log('values', values);
  const handleSave = () => {
    const cleanModifyDep = Object.keys(modifyDep).reduce((acc: any, item: any) => (modifyDep[item] !== undefined ? { ...acc, [item]: modifyDep[item] } : { ...acc }), {});

    if (!IschangeRecord) {
      dispatch(
        UpdateRecordEmbedded({
          table: activeScreen.tableID,
          navigate,
          backScreen: routeBack.routeDetails,
          compulsory_data: [modifyDep],
          form_data: { ...valuesToSend, ...cleanModifyDep },
        }),
      );
    } else {
      dispatch(
        UpdateRecordEmbedded({
          table: activeScreen.tableID,
          navigate,
          backScreen: routeBack.routeDetails,
          compulsory_data: [RecordsList[Count].modifyDep],
          form_data: { ...valuesToSend, ...RecordsList[Count].modifyDep },
        }),
      );
    }
  };
  console.log('showOptionalFields', showOptionalFields);
  const handleOpenRecordHistoryDialog = () => {
    setRecordHistoryDialogOpen(true);
  };

  const handleCloseRecordHistoryDialog = () => {
    setRecordHistoryDialogOpen(false);
  };

  const compare = (currentValue: any) => {
    const result = currentValue?.obj['\u0000*\u0000parm']?.options?.filter((e: any) => (e?.value === values[currentValue?.id]));
    return result ? result[0]?.label : '';
  };

  const { _, ...restData } = recordDetails;
  console.log('restData', { ...restData, ...config });

  return recordDetails.length === 0 ? (
    <div
      style={{
        width: '100%',
        height: '90vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress />
    </div>
  ) : (
    <>
      <EmbeddedSubnavbar
        label
        menuOptionType="simpleItems"
        // menuOptions={FilterSaved}
        handleEmbeddedClose={handleEmbeddedClose}
        exportTable={() => { console.log('sdfds'); }}
      />
      <FormHeaderTerminal
        listLength={RecordsList.length}
        count={Count}
        nextRecord={nextRecord}
        previousRecord={previousRecord}
        Visible={Visible}
        options={{ where: JSON.stringify(modifyDep), tableID: activeScreen.tableID }}
        handleCloseConfirm={handleCloseConfirm}
        dataHeader={_}
        routeBack={routeBack}
        handleSelectChange={handleSelectChange}
        handleInputChange={handleInputChange}
        values={values}
        handleSave={handleSave}
        isModifiy={isModifiy}
        compare={compare}
        valuesToSend={valuesToSend}
        handleClose={handleCloseModal}
        isEmbedded
      />
      <FormBodyTerminal
        dataTabs={{ ...restData, ...config }}
        handleSelectChange={handleSelectChange}
        handleInputChange={handleInputChange}
        values={values}
        compare={compare}
        valuesToSend={valuesToSend}
        showOptionalFields={showOptionalFields}
        handleShowOptionalFields={handleShowOptionalFields}
      />
      <RecordChangeHistory
        onClose={handleCloseRecordHistoryDialog}
        open={recordHistoryDialogOpen}
        options={routeBack}
      />
      {/* <ChangeRecordModal options={routeBack} /> */}
      <Snackbar open={openConfirmationAlert} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {confirmationAlert}
        </Alert>
      </Snackbar>
    </>
    // )
  );
};

export default DetailsMaintEmbeddedScreen;
