import {
  LOAD_DRIVERS_IN_FACILITY,
  LOAD_DRIVERS_IN_FACILITY_CARRIERS,
  LOAD_DRIVERS_IN_FACILITY_DRIVERS,
  CLEAR_DRIVERS_IN_FACILITY,
} from '../../constants/workflows';

const INITIAL_STATE = {
  rowkey: '',
  fieldlist: [],
  searchablefields: [],
  detailfunc: '',
  reclist: [],
  expand: true,
  printexport: true,
  modalDriversList: [],
  modalCarriersList: [],
};

const driversInFacilityReducer = (
  state = INITIAL_STATE,
  { type, payload }: any,
) => {
  switch (type) {
    case LOAD_DRIVERS_IN_FACILITY: {
      return {
        ...state,
        ...payload.data,
      };
    }
    case LOAD_DRIVERS_IN_FACILITY_DRIVERS: {
      return {
        ...state,
        modalDriversList: payload.data,
      };
    }
    case LOAD_DRIVERS_IN_FACILITY_CARRIERS: {
      return {
        ...state,
        modalCarriersList: payload.data,
      };
    }
    case CLEAR_DRIVERS_IN_FACILITY: {
      return {
        rowkey: '',
        fieldlist: [],
        searchablefields: [],
        detailfunc: '',
        reclist: [],
        expand: true,
        printexport: true,
        modalDriversList: [],
        modalCarriersList: [],
      };
    }
    default:
      return state;
  }
};

export default driversInFacilityReducer;
