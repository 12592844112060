/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const Menu = ({ scrollVal, menuList, navigateHeaderMenu }: any) => {
  const [clicked, setClicked] = React.useState(false);
  const [selectedScreen, setSelectedScreen] = React.useState<any>(null);

  const handleClick = (e: any, currentScreen: any) => {
    const name = e.target.attributes.getNamedItem('data-id').value;
    if (name === currentScreen) {
      setSelectedScreen(name);
      setClicked(!clicked);
      return;
    }
    setSelectedScreen(null);
    setClicked(false);
  };

  const menuScrollStyles = () => ({
    overflowY: scrollVal,
    maxHeight: scrollVal ? '50vh' : 'auto',
  });

  return (
    <ul
      className="dropdown-menu scroll-bar"
      style={menuScrollStyles()}
    >
      {menuList.map((menu: any) => (
        menu.subMenu ? (
          <li className="dropdown-submenu" key={menu.screenName}>
            <div
              data-id={menu.screenName}
              style={{ cursor: 'pointer' }}
              onClick={(e) => handleClick(e, menu.screenName)}
            >
              {menu.screenName}
              {' '}
              <ArrowForwardIosIcon
                style={{
                  color: '#fff',
                  float: 'right',
                  fontSize: '12px',
                }}
              />
            </div>
            {
            (clicked && selectedScreen === menu.screenName)
            && (
            <Menu
              scrollVal={(menu.subMenu.length > 13 ? 'scroll' : '')}
              menuList={menu.subMenu}
              navigateHeaderMenu={navigateHeaderMenu}
            />
            )
}
          </li>
        )
          : (
            <li key={menu.screenName}>
              <div
                className="menu-links"
                onClick={() => navigateHeaderMenu(menu)}
                style={{ cursor: 'pointer' }}
                tabIndex={-1}
              >
                {menu.screenName}
              </div>
            </li>
          )
      ))}
    </ul>
  );
};

export default Menu;
