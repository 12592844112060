/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useState } from 'react';

// Librairies
import {
  ButtonBase, Divider, InputBase, useMediaQuery,
} from '@mui/material';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

// Components
import SubHeader from '../../../components/subHeader';
import TmsHeader from '../../../components/header';
import OptionsButton from '../../../components/optionsButton';
import Input from '../../../components/Input';
import MeterEntryRecordDetailOptions from '../../../components/MeterEntryRecordDetailOptions';
import MeterEntryrecordDetailTable from '../../../components/MeterEntryrecordDetailTable';

// Styles
import { useStyles } from './style';

// Actions
import {
  applyAdjustments,
  autoTotalize,
  clearFormData,
  clearFormHeader,
  clearMeterEntryDownloadLink,
  clearMeterEntryExportFile,
  exportMeterEntryColumnsFile,
  getMeterEntryColumnsDownloadLink,
  getMeterEntryRecord,
  globalAutoTotalize,
  saveMeterDetailChanges,
  setFormHeader,
} from '../../../redux/actions/workflows/meterEntry';
import { getScreensList } from '../../../redux/actions/ScreenActions';
import DateTimePicker from '../../../components/DateTimePicker';

// Utils
import MeterAdjustmentsDialog from '../../../components/MeterAdjustmentsDialog';
import { getFormattedDate, getFormattedTime, isEmpty } from '../../../utils';
import WorkflowRecordChangeHistory from '../../../components/WorkflowRecordChangeHistotory';
import { searchHeader, tableHeader } from './const';
import ConfirmationDialog from '../../../components/ConfirmDialog';

interface IState {
  searchText: string;
  meterAdjusmentsDialogOpen: boolean;
  selectedRow: any;
  selectedRowIndex: number | null;
  anchorEl: null | HTMLElement;
  recordChangeHistoryDialogOpen: boolean;
  cancelConfirmDialogOpen: boolean;
}

const MeterEntryRecordDetail: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    currentRecord,
    selectedRecord,
    formHeader,
    exportFile,
    exportLink,
    formData,
  } = useSelector((state: any) => state.Workflows.MeterEntry);
  const [meterRecordState, setMeterRecordState] = useState<IState>(() => ({
    searchText: '',
    meterAdjusmentsDialogOpen: false,
    recordChangeHistoryDialogOpen: false,
    selectedRow: null,
    anchorEl: null,
    selectedRowIndex: null,
    cancelConfirmDialogOpen: false,
  }));
  const {
    folioMonth, folioNumber, folioStatus, termID,
  } = useParams();
  const isUpdateDisabled = folioStatus === 'C' || folioStatus === 'M';

  useEffect(() => {
    if (currentRecord?.meterRecords) {
      const updatedRow = currentRecord?.meterRecords[
          meterRecordState.selectedRowIndex as number
      ];
      setMeterRecordState((prev) => ({ ...prev, selectedRow: updatedRow }));
    }
  }, [currentRecord, meterRecordState.selectedRowIndex]);

  const handleOpenMeterAdjustmentsDialog = (row: any, rowIndex: number) => {
    setMeterRecordState({
      ...meterRecordState,
      selectedRow: row,
      meterAdjusmentsDialogOpen: true,
      selectedRowIndex: rowIndex,
    });
  };

  const handleCloseMeterAdjustmentsDialog = () => {
    setMeterRecordState({
      ...meterRecordState,
      meterAdjusmentsDialogOpen: false,
    });
  };

  const handleOpenrecordChangeHistoryDialog = () => {
    setMeterRecordState({
      ...meterRecordState,
      recordChangeHistoryDialogOpen: true,
      anchorEl: null,
    });
  };

  useEffect(
    () => () => {
      dispatch(clearFormData());
      dispatch(clearFormHeader());
    },
    [],
  );

  const handleCloserecordChangeHistoryDialog = () => {
    setMeterRecordState({
      ...meterRecordState,
      recordChangeHistoryDialogOpen: false,
    });
  };

  const handleOptionClick = (event: React.MouseEvent<HTMLElement>) => {
    setMeterRecordState({
      ...meterRecordState,
      anchorEl: event.currentTarget,
    });
  };

  const handleCloseMenu = () => {
    setMeterRecordState({
      ...meterRecordState,
      anchorEl: null,
    });
  };

  const exportColumns = () => {
    if (folioNumber && folioMonth) {
      dispatch(exportMeterEntryColumnsFile(folioNumber, folioMonth));
    }
    handleCloseMenu();
  };

  const fetchGlobalAutoTotalize = () => {
    dispatch(globalAutoTotalize());
    handleCloseMenu();
  };

  const fetchAutoTotalize = () => {
    if (meterRecordState.selectedRow) {
      dispatch(
        autoTotalize(
          meterRecordState.selectedRow,
          meterRecordState.selectedRowIndex as number,
        ),
      );
    }
  };

  const handleApplyAdjustments = (
    adjustmentsSum: number,
    adjustmentsObject: { [key: string]: string },
    endMeter: string,
    autoTotalized: boolean,
  ) => {
    dispatch(
      applyAdjustments(
        meterRecordState.selectedRow,
        adjustmentsSum,
        adjustmentsObject,
        endMeter,
        autoTotalized,
        meterRecordState.selectedRowIndex,
      ),
    );
    handleCloseMeterAdjustmentsDialog();
  };

  useEffect(() => {
    if (exportFile) {
      dispatch(getMeterEntryColumnsDownloadLink(exportFile));
    }
    return () => {
      dispatch(clearMeterEntryExportFile());
    };
  }, [exportFile]);

  useEffect(() => {
    if (exportLink) {
      const link = document.createElement('a');
      link.href = exportLink;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    return () => {
      dispatch(clearMeterEntryDownloadLink());
    };
  }, [exportLink]);

  useEffect(() => {
    dispatch(getScreensList());
    dispatch(
      getMeterEntryRecord({
        folioMonth,
        folioNumber,
        folioStatus,
        termID,
      }),
    );
  }, []);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMeterRecordState({
      ...meterRecordState,
      searchText: e.target.value,
    });
  };

  const handleSave = () => {
    dispatch(saveMeterDetailChanges());
    navigate('/workflow/meter-entry');
  };

  const handleCancel = () => {
    if (!isEmpty(formData) || formHeader?.change) {
      setMeterRecordState({
        ...meterRecordState,
        cancelConfirmDialogOpen: true,
      });
    } else {
      window.location.href = '/workflow/meter-entry';
    }
  };

  const closeCancelConfirmDialog = () => {
    setMeterRecordState({
      ...meterRecordState,
      cancelConfirmDialogOpen: false,
    });
  };

  const closeAgreeCancelConfirmDialog = () => {
    closeCancelConfirmDialog();
    window.location.href = '/workflow/meter-entry';
  };

  return (
    <div className="fullHeight flexColumn">
      <TmsHeader />
      <SubHeader title={currentRecord?.topHeaderValues?.meterScreenTitle}>
        <OptionsButton handleClick={handleOptionClick} />
        <MeterEntryRecordDetailOptions
          isUpdateDisabled={isUpdateDisabled}
          anchorEl={meterRecordState.anchorEl}
          onClose={handleCloseMenu}
          exportColumns={exportColumns}
          fetchGlobalAutoTotalize={fetchGlobalAutoTotalize}
          openRecordChangeHistory={handleOpenrecordChangeHistoryDialog}
        />
      </SubHeader>
      <div>
        <ButtonBase
          className={classes.saveButton}
          onClick={handleSave}
          disabled={isUpdateDisabled || !formHeader?.userInitialsId}
        >
          Save
        </ButtonBase>
        <ButtonBase className={classes.cancleButton} onClick={handleCancel}>
          Cancel
        </ButtonBase>
      </div>
      <Divider className={classes.divider} />
      <MeterEntryForm
        values={currentRecord?.topHeaderValues}
        isUpdateDisabled={isUpdateDisabled}
        formHeader={formHeader}
      />
      <Divider className={classes.divider} />
      <div
        className={clsx('flexStartSpaceBetweenRow', classes.subHeaderContainer)}
      >
        <span className="takeTheRest" />
        <div className="flexRow">
          <Input
            value={meterRecordState.searchText}
            onChange={handleSearchChange}
            placeholder="Search"
            maxLength={100}
            noBorder
            style={{ minWidth: 300 }}
          />
        </div>
      </div>
      <MeterEntryrecordDetailTable
        data={currentRecord?.meterRecords || []}
        searchText={meterRecordState.searchText}
        handleClickRow={() => null}
        tableHeader={currentRecord.meterHeader}
        selectedRecord={selectedRecord}
        openMeterAdjustmentsDialog={handleOpenMeterAdjustmentsDialog}
        isUpdateDisabled={isUpdateDisabled}
      />
      {meterRecordState.meterAdjusmentsDialogOpen ? (
        <MeterAdjustmentsDialog
          onClose={handleCloseMeterAdjustmentsDialog}
          open={meterRecordState.meterAdjusmentsDialogOpen}
          selectedRow={meterRecordState.selectedRow}
          adjustmentModalFeilds={currentRecord.adjustmentModalFeilds}
          fetchAutoTotalize={fetchAutoTotalize}
          applyAdjustments={handleApplyAdjustments}
          selectedRowIndex={meterRecordState.selectedRowIndex}
        />
      ) : null}
      {meterRecordState.recordChangeHistoryDialogOpen ? (
        <WorkflowRecordChangeHistory
          onClose={handleCloserecordChangeHistoryDialog}
          open={meterRecordState.recordChangeHistoryDialogOpen}
          historyModalData={
            currentRecord.historyModal.Meter?.historyList?.[0] || []
          }
          limitNumber={100}
          searchHeader={searchHeader}
          tableHeader={tableHeader}
          tableTitle={currentRecord?.historyModal?.Meter?.tableTitle}
          tabLabel="Meter"
          defaultRecordsToShow={15}
        />
      ) : null}
      <ConfirmationDialog
        cancelLabel="Cancel"
        confirmLabel="Yes"
        handleCloseAgree={closeAgreeCancelConfirmDialog}
        handleCloseCancel={closeCancelConfirmDialog}
        message="You have made changes
        on this page. If you leave without
        saving, your changes will be lost. Do you want to continue?"
        open={meterRecordState.cancelConfirmDialogOpen}
        title="Leave Page"
        helperText="This action cannot be undone."
      />
    </div>
  );
};

const MeterEntryForm: React.FC<{
  values: any;
  isUpdateDisabled: boolean;
  formHeader: any;
}> = ({ values, isUpdateDisabled, formHeader }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const matches = useMediaQuery('(min-width:992px)');
  const [formState, setFormState] = React.useState(() => ({
    dateInput: {
      inputValue: '',
      pickerValue: new Date(),
    },
    userInitials: '',
  }));

  useEffect(() => {
    if (values?.meterDateId_disp?.value || values?.userInitialsId?.value) {
      setFormState({
        dateInput: {
          inputValue: values?.meterDateId_disp?.value || '',
          pickerValue: new Date(values?.meterDateId_disp?.value),
        },
        userInitials: values?.userInitialsId?.value || '',
      });
      const dateValue = new Date(values?.meterDateId_disp?.value);
      const formattedDate = getFormattedDate(dateValue);
      const formattedTime = getFormattedTime(dateValue);
      if (formattedDate && formattedTime) {
        const helpArray = formattedDate?.split('/');
        const [removed] = helpArray?.splice(2, 1);
        helpArray?.splice(0, 0, removed);
        const meterDateIdDate = helpArray?.join('-');
        const meterDateIdTime = formattedTime?.slice(0, 5);
        dispatch(
          setFormHeader({
            meterDateId_disp: `${meterDateIdDate} ${meterDateIdTime}`,
            meterDateId: `${meterDateIdDate} ${meterDateIdTime}`,
            userInitialsId: values?.userInitialsId?.value,
          }),
        );
      }
    }
  }, [values?.meterDateId_disp?.value, values?.userInitialsId?.value]);

  const handleDateTimePckerChange = (value: Date | null) => {
    if (value) {
      const formattedDate = getFormattedDate(value);
      const formattedTime = getFormattedTime(value);
      if (formattedDate && formattedTime) {
        const helpArray = formattedDate?.split('/');
        const [removed] = helpArray?.splice(2, 1);
        helpArray?.splice(0, 0, removed);
        const meterDateIdDate = helpArray?.join('-');
        const meterDateIdTime = formattedTime?.slice(0, 5);
        dispatch(
          setFormHeader({
            meterDateId_disp: `${meterDateIdDate} ${meterDateIdTime}`,
            meterDateId: `${meterDateIdDate} ${meterDateIdTime}`,
            userInitialsId: formState.userInitials,
            change: true,
          }),
        );
        setFormState({
          ...formState,
          dateInput: {
            inputValue: `${formattedDate} ${formattedTime}`,
            pickerValue: value,
          },
        });
      }
    }
  };

  const handleUserInitialsInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setFormState({ ...formState, userInitials: event.target.value });
  };

  const handleUserInitialsBlur = () => {
    dispatch(
      setFormHeader({
        userInitialsId: formState.userInitials,
        meterDateId_disp: formHeader?.meterDateId_disp || '',
        meterDateId: formHeader?.meterDateId || '',
        change: true,
      }),
    );
  };

  return (
    <form
      className={clsx(classes.form, 'flexColumn')}
      style={{ width: matches ? '50%' : undefined }}
    >
      <div
        className={clsx(
          classes.inputContainer,
          matches ? 'flexRowAlignCenter' : 'flexColumn',
        )}
      >
        <div
          className={clsx(matches && 'flexStartEndColumn', classes.inputLabel)}
          style={{ width: '25%' }}
        >
          Date/Time
        </div>
        <DateTimePicker
          handleChange={handleDateTimePckerChange}
          inputValue={formState.dateInput.inputValue}
          pickerValue={formState.dateInput.pickerValue}
          inputStyle={{
            minWidth: matches ? 500 : undefined,
            width: matches ? '75%' : undefined,
          }}
          required
          shouldDisableTime
          disabled={isUpdateDisabled}
        />
      </div>
      <div
        className={clsx(
          classes.inputContainer,
          matches ? 'flexRowAlignCenter' : 'flexColumn',
        )}
      >
        <div
          className={clsx(matches && 'flexStartEndColumn', classes.inputLabel)}
          style={{ width: '25%' }}
        >
          User Initials
        </div>
        <Input
          onBlur={handleUserInitialsBlur}
          onChange={handleUserInitialsInputChange}
          value={formState.userInitials}
          required
          maxLength={3}
          style={{
            minWidth: matches ? 500 : undefined,
            width: matches ? '75%' : undefined,
          }}
          disabled={isUpdateDisabled}
        />
      </div>
    </form>
  );
};

export default MeterEntryRecordDetail;
