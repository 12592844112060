/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react';

// Libraries
import {
  ButtonBase,
  DialogActions,
  Divider,
  InputBase,
  DialogContent,
} from '@mui/material';

// Components
import { DragDropContext, DragStart, DropResult } from 'react-beautiful-dnd';
import { DialogComponent } from '../Dialog/Dialog.component';
import List from './List';

// Styles
import { useStyles } from './style';

// Utils
import {
  deleteFromColumns,
  filterColumns,
  getColumns,
  IColumns,
  makeColumnsFromIds,
  makeDefaultValue,
} from './utils';

interface IProps {
  title?: string;
  onClose: () => void;
  open: boolean;
  visibleColumns: { [key: string]: string };
  hiddenColumns: { [key: string]: string };
  columnsLabels: { [key: string]: string };
  submitNewColumns: (arr: string[]) => void;
  handleRestoreDefault: () => void;
}

const ColumnsDialog: React.FC<IProps> = ({
  onClose,
  open,
  hiddenColumns,
  visibleColumns,
  columnsLabels,
  submitNewColumns,
  handleRestoreDefault,
  title,
}) => {
  const classes = useStyles();
  const [searchText, setSearchText] = useState('');
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
  const [draggingColumnId, setDraggingColumnId] = useState('');
  const [columns, setColumns] = useState<IColumns>({
    visibleColumns: {},
    hiddenColumns: {},
  });

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    window.addEventListener('click', onWindowClick);
    window.addEventListener('keydown', onWindowKeyDown);
    window.addEventListener('touchend', onWindowTouchEnd);
    return () => {
      window.removeEventListener('click', onWindowClick);
      window.removeEventListener('keydown', onWindowKeyDown);
      window.removeEventListener('touchend', onWindowTouchEnd);
    };
  }, []);

  const onWindowKeyDown = (event: KeyboardEvent) => {
    if (event.defaultPrevented) {
      return;
    }

    if (event.key === 'Escape') {
      unselectAll();
    }
  };

  const onWindowClick = (event: MouseEvent) => {
    if (event.defaultPrevented) {
      return;
    }
    unselectAll();
  };

  const onWindowTouchEnd = (event: TouchEvent) => {
    if (event.defaultPrevented) {
      return;
    }
    unselectAll();
  };

  const unselectAll = () => {
    setSelectedColumns([]);
  };

  useEffect(() => {
    if (
      Object.keys(visibleColumns).length !== 0
      || Object.keys(hiddenColumns).length !== 0
    ) {
      setColumns({
        hiddenColumns: makeDefaultValue(hiddenColumns),
        visibleColumns: makeDefaultValue(visibleColumns),
      });
    }
  }, [hiddenColumns, visibleColumns]);

  useEffect(() => {
    const filtredColumns: any = filterColumns(
      makeDefaultValue({ ...visibleColumns, ...hiddenColumns }),
      columnsLabels,
      searchText,
    );
    const newVisibleColumns = getColumns(
      columns.visibleColumns,
      filtredColumns,
    );
    const newHiddenColumns = getColumns(columns.hiddenColumns, filtredColumns);
    setColumns({
      hiddenColumns: newHiddenColumns,
      visibleColumns: newVisibleColumns,
    });
  }, [searchText]);

  const handleDragFields = (result: DropResult) => {
    setDraggingColumnId('');
    if (!result) return;
    if (result?.destination?.droppableId !== result.source.droppableId) {
      if (result?.destination?.droppableId === 'hiddenColumns') {
        if (selectedColumns.length > 1) {
          const newVisibleColumns = deleteFromColumns(
            columns.visibleColumns,
            selectedColumns,
          );
          const newHiddenColumns = {
            ...columns.hiddenColumns,
            ...makeColumnsFromIds(selectedColumns, columns.visibleColumns),
          };
          setColumns({
            hiddenColumns: newHiddenColumns,
            visibleColumns: newVisibleColumns,
          });
          return;
        }
        const newHiddenColumns = {
          ...columns.hiddenColumns,
          [result.draggableId]: columns.visibleColumns[result.draggableId],
        };
        const newVisibleColumns = { ...columns.visibleColumns };
        delete newVisibleColumns[result.draggableId];
        setColumns({
          hiddenColumns: newHiddenColumns,
          visibleColumns: newVisibleColumns,
        });
      } else {
        if (selectedColumns.length > 1) {
          const newVisibleColumns = {
            ...columns.visibleColumns,
            ...makeColumnsFromIds(selectedColumns, columns.hiddenColumns),
          };
          const arrayToReorder = Object.keys(newVisibleColumns);
          const [...removed] = arrayToReorder.splice(
            arrayToReorder.length - selectedColumns.length,
            selectedColumns.length,
          );
          arrayToReorder.splice(
            result?.destination?.index as number,
            0,
            ...removed,
          );
          const resultArray = makeColumnsFromIds(
            arrayToReorder,
            newVisibleColumns,
          );
          const newHiddenColumns = deleteFromColumns(
            columns.hiddenColumns,
            selectedColumns,
          );
          setColumns({
            hiddenColumns: newHiddenColumns,
            visibleColumns: resultArray,
          });
          return;
        }
        const newVisibleColumns = {
          ...columns.visibleColumns,
          [result.draggableId]: columns.hiddenColumns[result.draggableId],
        };
        const arrayToReorder = Object.keys(newVisibleColumns);
        const [removed] = arrayToReorder.splice(arrayToReorder.length - 1, 1);
        arrayToReorder.splice(result?.destination?.index as number, 0, removed);
        const resultArray = makeColumnsFromIds(
          arrayToReorder,
          newVisibleColumns,
        );
        const newHiddenColumns = { ...columns.hiddenColumns };
        delete newHiddenColumns[result.draggableId];
        setColumns({
          hiddenColumns: newHiddenColumns,
          visibleColumns: resultArray,
        });
      }
      return;
    }
    if (
      result?.destination?.droppableId === result.source.droppableId
      && result?.destination?.droppableId === 'visibleColumns'
    ) {
      if (selectedColumns.length > 1) {
        const allColumnsArray = Object.keys(columns.visibleColumns);
        const prevElemInOriginal = allColumnsArray[result.destination.index];
        const newVisibleColumns = deleteFromColumns(
          columns.visibleColumns,
          selectedColumns,
        );
        const arrayToReorder = Object.keys(newVisibleColumns);
        const prevElIndexInNewArray = arrayToReorder.indexOf(prevElemInOriginal);
        let destinationIndex;
        if (result.destination.index > result.source.index) {
          destinationIndex = prevElIndexInNewArray + 1;
        } else {
          destinationIndex = prevElIndexInNewArray;
        }
        arrayToReorder.splice(destinationIndex, 0, ...selectedColumns);
        const resultArray = makeColumnsFromIds(
          arrayToReorder,
          columns.visibleColumns,
        );
        setColumns({
          ...columns,
          visibleColumns: resultArray,
        });
        return;
      }
      const arrayToReorder = Object.keys(columns.visibleColumns);
      const [removed] = arrayToReorder.splice(result.source.index, 1);
      arrayToReorder.splice(result.destination.index, 0, removed);
      const resultArray = makeColumnsFromIds(
        arrayToReorder,
        columns.visibleColumns,
      );
      setColumns({
        ...columns,
        visibleColumns: resultArray,
      });
    }
  };

  const handleApply = () => {
    submitNewColumns(Object.keys(columns.visibleColumns));
    onClose();
  };

  const onDragStart = (start: DragStart) => {
    const id: string = start.draggableId;
    const selected = selectedColumns.find((x) => x === id);
    if (!selected) {
      unselectAll();
    }
    setDraggingColumnId(start.draggableId);
  };

  const toggleSelection = (columnId: string) => {
    const selColumns = selectedColumns;
    const wasSelected: boolean = selColumns.includes(columnId);
    const newColumnsIds: string[] = (() => {
      if (!wasSelected) {
        return [columnId];
      }
      if (selColumns.length > 1) {
        return [columnId];
      }
      return [];
    })();

    setSelectedColumns(newColumnsIds);
  };

  const toggleSelectionInGroup = (columnId: string) => {
    const selCols: string[] = selectedColumns;
    const index: number = selCols.indexOf(columnId);
    if (index === -1) {
      setSelectedColumns([...selCols, columnId]);
      return;
    }
    const shallow: string[] = [...selCols];
    shallow.splice(index, 1);
    setSelectedColumns(shallow);
  };

  const multiSelectTo = (newColumnId: string) => {
    console.log(newColumnId);
  };

  return (
    <DialogComponent title={title || 'Columns'} onClose={onClose} open={open}>
      <DialogContent classes={{ root: classes.dialogBody }}>
        <Columns
          columns={columns}
          columnsLabels={columnsLabels}
          draggingColumnId={draggingColumnId}
          handleDragFields={handleDragFields}
          handleSearchChange={handleSearchChange}
          multiSelectTo={multiSelectTo}
          onDragStart={onDragStart}
          searchText={searchText}
          selectedColumns={selectedColumns}
          toggleSelection={toggleSelection}
          toggleSelectionInGroup={toggleSelectionInGroup}
          leftTitle="Hidden Column (s)"
          rightTitle="Visible Column (s)"
        />
      </DialogContent>
      <Divider variant="middle" />
      <DialogActions>
        <span className={classes.helperText}>
          Note: At least one column must be chosen
        </span>
        <span className="takeTheRest" />
        <ButtonBase
          classes={{ root: classes.restoreButton }}
          onClick={handleRestoreDefault}
        >
          Restore Default Columns
        </ButtonBase>
        <ButtonBase
          classes={{ root: classes.applyButton }}
          onClick={handleApply}
          disabled={!Object.keys(columns.visibleColumns).length}
        >
          Apply
        </ButtonBase>
        <ButtonBase classes={{ root: classes.closeButton }} onClick={onClose}>
          Cancel
        </ButtonBase>
      </DialogActions>
    </DialogComponent>
  );
};

interface IColumnsComponent {
  searchText: string;
  handleSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleDragFields: (result: DropResult) => void;
  onDragStart: (start: DragStart) => void;
  columns: IColumns;
  columnsLabels: { [key: string]: string };
  multiSelectTo: (newColumnId: string) => void;
  toggleSelection: (columnId: string) => void;
  toggleSelectionInGroup: (columnId: string) => void;
  selectedColumns: string[];
  draggingColumnId: string;
  leftTitle: string;
  rightTitle: string;
}

export const Columns: React.FC<IColumnsComponent> = ({
  searchText,
  handleSearchChange,
  handleDragFields,
  onDragStart,
  columns,
  columnsLabels,
  multiSelectTo,
  toggleSelection,
  toggleSelectionInGroup,
  selectedColumns,
  draggingColumnId,
  leftTitle,
  rightTitle,
}) => {
  const classes = useStyles();
  return (
    <>
      <div className="flexRow" style={{ marginBottom: '10px' }}>
        <span className="takeTheRest" />
        <span className={classes.label}>Search :</span>
        <InputBase
          className={classes.searchInput}
          value={searchText}
          onChange={handleSearchChange}
        />
      </div>
      <table className="fullWidth">
        <thead>
          <tr style={{ boxShadow: '0 1px 7px #555' }}>
            <th>
              {leftTitle}
            </th>
            <th>
              {rightTitle}
            </th>
          </tr>
        </thead>
        <div style={{ marginTop: '10px' }} />
        <DragDropContext onDragEnd={handleDragFields} onDragStart={onDragStart}>
          <tbody>
            <tr>
              {Object.keys(columns).map((column) => (
                <td className={classes.listColumn} key={column}>
                  <div className={classes.listContainer}>
                    <List
                      columns={
                        columns[column as 'visibleColumns' | 'hiddenColumns']
                      }
                      columnsLabels={columnsLabels}
                      droppableId={column}
                      multiSelectTo={multiSelectTo}
                      selectionCount={selectedColumns.length}
                      toggleSelection={toggleSelection}
                      toggleSelectionInGroup={toggleSelectionInGroup}
                      selectedColumns={selectedColumns}
                      draggingColumnId={draggingColumnId}
                    />
                  </div>
                </td>
              ))}
            </tr>
          </tbody>
        </DragDropContext>
      </table>
    </>
  );
};

export default ColumnsDialog;
