/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Divider,
  Grid,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Close } from '@mui/icons-material';
import { useStyles } from './style';
import CustomInput from '../customInput';
import api from '../../redux/api';

function pad(n: any) {
  return n !== -10 ? `0${n}` : n;
}
function splitdash(str: any) {
  let output = str;
  let aux = [];
  if (str?.search('-') !== -1) {
    aux = str?.split('-');
    output = aux?.[aux?.length - 1];
  }
  return { name: output, prefix: str?.split('-')[0] };
}

const setOptionFormat = (stepData: any) =>
  (stepData || [{ value: '', label: 'No Data Available' }]);
const SupplierToRecord = ({
  handleInputChange,
  valuesToSend,
  handleInputProductChange,
  prefixProducts,
  resetPrefixProducts,
  prefix,
  prefixFromSupplier,
  removeFormFields,
  itemKey,
  review,
}: any) => {
  const classes = useStyles();
  const [Loading, setLoading] = useState(true);
  const [FirstRow, setFirstRow] = useState<any>([]);
  const [SecondRow, setSecondRow] = useState<any>([]);
  const [baysOptions, setBaysOptions] = useState<any>([]);
  const [OptionsPresets, setOptionsPresets] = useState<any>([]);
  const [OptionsMeters, setOptionsMeters] = useState<any>([]);
  const getBaysformSupplier = async () => {
    try {
      const response = await api.get(`meterproducthierarchy?termid=B344&productfieldid=${valuesToSend?.[itemKey]?.prod_id_to}&type=bay`);
      setBaysOptions(Object.entries(response.data?.[0])?.map((val: any) => ({
        value: val[0],
        label: val[1],
      })));
    } catch (error) {
      console.log('err', error);
    }
  };
  const getPresetsformSupplier = async () => {
    try {
      const response = await api.get(`meterproducthierarchy?termid=B344&productfieldid=${valuesToSend?.[itemKey]?.prod_id_to}&bayfieldid=${valuesToSend?.[itemKey]?.ld_bay}&type=preset`);
      setOptionsPresets(Object.entries(response.data?.[0])?.map((val: any) => ({
        value: val[0],
        label: val[1],
      })));
    } catch (error) {
      console.log('err', error);
    }
  };
  const getMetersformSupplier = async () => {
    try {
      const response = await api.get(`meterproducthierarchy?termid=B344&productfieldid=${valuesToSend?.[itemKey]?.prod_id_to}&bayfieldid=${valuesToSend?.[itemKey]?.ld_bay}&presetfieldid=${valuesToSend?.[itemKey]?.ld_ctl}&type=meter`);
      setOptionsMeters(Object.entries(response.data?.[0])?.map((val: any) => ({
        value: val[0],
        label: val[1],
      })));
    } catch (error) {
      console.log('err', error);
    }
  };
  useEffect(() => {
    getBaysformSupplier();
    getPresetsformSupplier();
    getMetersformSupplier();
  }, [valuesToSend?.[itemKey]?.prod_id_to]);
  const selectProducts = async () => {
    try {
      setLoading(true);
      const response: any = await api.post('synajax/procajax',
        {
          queueid: 'TT',
          data: {
            cmd: 'tanktransfer_new',
            term_id: 'B344   ',
            reason_x: '3150',
            direction: '1',
            supplier_no_to: '',
            uom: 'B',
            baseUOMList: '',
            prefix: '',
            newdetail: 'y',
            fieldlist: null,
            tankDetailListFrom: `[${prefixFromSupplier}]`,
            tankDetailListTo: '[]',
          },
          tagid: null,
          jqxhr: null,
        });
      setFirstRow(response?.data?.[0]?.genBlock?.genFirstRow);
      setSecondRow(response.data?.[0]?.genBlock?.genSecondRow);
      resetPrefixProducts({
        [`${itemKey}-supplier_no_to`]: response?.data?.[0]?.genBlock?.genFirstRow?.fieldList?.supplier_no_to?.value,
        [`${itemKey}-tank_id_to`]: response?.data?.[0]?.genBlock?.genFirstRow?.fieldList?.tank_id_to?.value,
        [`${itemKey}-prod_id_to`]: '',
        [`${itemKey}-temperature_to`]: '',
        [`${itemKey}-grav_density_to`]: '',
        [`${itemKey}-gross_to`]: '',
        [`${itemKey}-grossBase_to`]: '',
        [`${itemKey}-vcf_to`]: '',
        [`${itemKey}-net_to`]: '',
        [`${itemKey}-netBase_to`]: '',
        [`${itemKey}-product_weight_to`]: '',
        [`${itemKey}-product_weightBase_to`]: '',
        [`${itemKey}-ld_bay_to`]: '',
        [`${itemKey}-ld_ctl_to`]: '',
        [`${itemKey}-ld_mtr_to`]: '',
        [`${itemKey}-ERPHandlingType_to`]: '',
        [`${itemKey}-dutytoparty_to`]: '',
        [`${itemKey}-ticket_no_to`]: '',
        [`${itemKey}-prod_type_to`]: '',
        [`${itemKey}-tank_uom_to`]: '',
        prefix: itemKey,
      }, '[{"supplier_no_from":"0000077441","tank_id_from":"0001","prod_id_from":"000005","temperature_from":"60.0000","grav_density_from":"60.0000","gross_from":"15","grossBase_from":"15","vcf_from":"1","net_from":"15","netBase_from":"15","product_weight_from":"","product_weightBase_from":"","ld_bay_from":"","ld_ctl_from":"","ld_mtr_from":"","ERPHandlingType_from":"","dutytoparty_from":"","ticket_no_from":"","prod_type_from":"3","tank_uom_from":"G"}]');
      setLoading(false);
    } catch (e: any) {
      console.log('error', e);
    }
  };
  useEffect(() => {
    selectProducts();
  }, [valuesToSend?.[itemKey]?.supplier_no_to]);
  return Loading ? <Typography>Loading... </Typography> : itemKey ? (
    <div className={classes.container}>
      <div className={classes.tableContainer}>
        <Typography fontFamily="inherit" color="#6172A7">
          Products
        </Typography>
        <Close onClick={() => removeFormFields(itemKey)} />
      </div>
      <Divider sx={{ color: 'blue' }} />
      <div className={classes.innerContainer}>
        <div className={classes.subContainer}>
          <Grid item xs={2} sm={4} md={4} className={classes.product}>
            <Typography
              fontSize="12px"
              fontFamily="inherit"
              sx={{
                borderBottom: '1px solid #6172A7',
                padding: '0px 5px',
                whiteSpace: 'nowrap',
                color: '#003399',
              }}
            >
              Supplier
            </Typography>
            <Select
              id="demo-simple-select"
              labelId="demo-simple-select-label"
              value={
                    FirstRow?.fieldList?.supplier_no_to?.value
                        }
              name="supplier_no_to"
              disabled={review || FirstRow?.fieldList?.supplier_no_to?.['data-attr']?.search('readonly') !== -1}
              onChange={(e: any) => handleInputProductChange('supplier_no_to', e, itemKey)}
              size="small"
              sx={{
                // product id field is wider than the other fields so we need to specify the condition for width
                minWidth: '100%',
                margin: '0px 5px',
                borderRadius: '0px',
                marginTop: '10px',
                '& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select':
                            {
                              padding: '0px 8px',
                              border: '1px solid #E4E4E4',
                            },
              }}
            >
              {setOptionFormat(FirstRow?.fieldList?.supplier_no_to?.options)?.map((item: any) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label.replaceAll('&nbsp;', '')}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={2} sm={4} md={4} className={classes.product}>
            <Typography
              fontSize="12px"
              fontFamily="inherit"
              sx={{
                borderBottom: '1px solid #6172A7',
                padding: '0px 5px',
                whiteSpace: 'nowrap',
                color: '#003399',
              }}
            >
              Tank
            </Typography>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={
                          valuesToSend?.[itemKey]?.tank_id_to || FirstRow?.fieldList?.tank_id_to?.value
                        }
              name="tank_id_to"
              disabled={review || FirstRow?.fieldList?.tank_id_to?.['data-attr']?.search('readonly') !== -1}
              onChange={(e: any) => handleInputProductChange('tank_id_to', e, itemKey)}
              size="small"
              sx={{
                // product id field is wider than the other fields so we need to specify the condition for width
                minWidth: '100%',
                margin: '0px 5px',
                borderRadius: '0px',
                marginTop: '10px',
                '& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select':
                            {
                              padding: '0px 8px',
                              border: '1px solid #E4E4E4',
                            },
              }}
            >
              {setOptionFormat(FirstRow?.fieldList?.tank_id_to?.options)?.map((item: any) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label.replaceAll('&nbsp;', '')}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={2} sm={4} md={4} className={classes.product}>
            <Typography
              fontSize="12px"
              fontFamily="inherit"
              sx={{
                borderBottom: '1px solid #6172A7',
                padding: '0px 5px',
                whiteSpace: 'nowrap',
                color: '#003399',
              }}
            >
              Product
            </Typography>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={
                          valuesToSend?.[itemKey]?.prod_id_to || FirstRow?.fieldList?.prod_id_to?.value
                        }
              name="prod_id_to"
              disabled={review || FirstRow?.fieldList?.prod_id_to?.['data-attr']?.search('readonly') !== -1}
              onChange={(e: any) => handleInputProductChange('prod_id_to', e, itemKey)}
              size="small"
              sx={{
                // product id field is wider than the other fields so we need to specify the condition for width
                minWidth: '100%',
                margin: '0px 5px',
                borderRadius: '0px',
                marginTop: '10px',
                '& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select':
                            {
                              padding: '0px 8px',
                              border: '1px solid #E4E4E4',
                            },
              }}
            >
              {setOptionFormat(FirstRow?.fieldList?.prod_id_to?.options)?.map((item: any) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label.replaceAll('&nbsp;', '')}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={2} sm={4} md={4} className={classes.product}>
            <Typography
              fontFamily="inherit"
              fontSize="12px"
              sx={{
                borderBottom: '1px solid black',
                padding: '0px 5px',
                whiteSpace: 'nowrap',
                color: '#003399',
              }}
            >
              {' Temp. (°F)'}
            </Typography>
            <div style={{ padding: '0px 0px' }}>
              <CustomInput
                type="input"
                label=""
                val={FirstRow?.fieldList?.temperature_to}
                required
                name="temperature_to"
                defaultValue=""
                attr={['num']}
                value={
                            prefixProducts?.[`${itemKey}-temperature_to`
                            ] || FirstRow?.fieldList?.temperature_to?.value
                          }
                isBatch={false}
                onChange={handleInputChange}
                size="100%"
                placeholder=""
                isDisabled={review || FirstRow?.fieldList?.temperature_to?.['data-attr']?.search('readonly') !== -1}
                customKey={itemKey}
                transaction
                prefix={itemKey}
                funNamePrefix={`${itemKey}-temperature_to`}
              />
            </div>
          </Grid>
          <Grid item xs={2} sm={4} md={4} className={classes.product}>
            <Typography
              fontFamily="inherit"
              fontSize="12px"
              sx={{
                borderBottom: '1px solid black',
                padding: '0px 5px',
                whiteSpace: 'nowrap',
                color: '#003399',
              }}
            >
              {' Density (°API)'}
            </Typography>
            <div style={{ padding: '0px 0px' }}>
              <CustomInput
                type="input"
                label=""
                val={FirstRow?.fieldList?.grav_density_to}
                required
                name="grav_density_to"
                defaultValue=""
                attr={['num']}
                value={
                    prefixProducts?.[`${itemKey}-grav_density_to`
                    ] || FirstRow?.fieldList?.grav_density_to?.value
                }
                isBatch={false}
                onChange={handleInputChange}
                size="100%"
                placeholder=""
                isDisabled={review || FirstRow?.fieldList?.grav_density_to?.['data-attr']?.search('readonly') !== -1}
                customKey={itemKey}
                transaction
                prefix={itemKey}
                funNamePrefix={`${itemKey}-grav_density_to`}
              />
            </div>
          </Grid>
          <Grid item xs={2} sm={4} md={4} className={classes.product}>
            <Typography
              fontFamily="inherit"
              fontSize="12px"
              sx={{
                borderBottom: '1px solid black',
                padding: '0px 5px',
                whiteSpace: 'nowrap',
                color: '#003399',
              }}
            >
              {' Gross (Gal)'}
            </Typography>
            <div style={{ padding: '0px 0px' }}>
              <CustomInput
                type="input"
                label=""
                val={FirstRow?.fieldList?.gross_to}
                required
                name="gross_to"
                defaultValue=""
                attr={['num']}
                value={
                      prefixProducts?.[`${itemKey}-gross_to`]
                     || FirstRow?.fieldList?.gross_to?.value
}
                isBatch={false}
                onChange={handleInputChange}
                size="100%"
                placeholder=""
                isDisabled={review || FirstRow?.fieldList?.gross_to?.['data-attr']?.search('readonly') !== -1}
                customKey={itemKey}
                transaction
                prefix={itemKey}
                funNamePrefix={`${itemKey}-gross_to`}
              />
            </div>
          </Grid>
          <Grid item xs={2} sm={4} md={4} className={classes.product}>
            <Typography
              fontFamily="inherit"
              fontSize="12px"
              sx={{
                borderBottom: '1px solid black',
                padding: '0px 5px',
                whiteSpace: 'nowrap',
                color: '#003399',
              }}
            >
              {' VCF'}
            </Typography>
            <div style={{ padding: '0px 0px' }}>
              <CustomInput
                type="input"
                label=""
                val={FirstRow?.fieldList?.vcf_to}
                required
                name="vcf_to"
                defaultValue=""
                attr={['num']}
                value={
                    prefixProducts?.[`${itemKey}-vcf_to`
                    ] || FirstRow?.fieldList?.vcf_to?.value
                          }
                isBatch={false}
                onChange={handleInputChange}
                size="100%"
                placeholder=""
                isDisabled={review || FirstRow?.fieldList?.vcf_to?.['data-attr']?.search('readonly') !== -1}
                customKey={itemKey}
                transaction
                prefix={itemKey}
                funNamePrefix={`${itemKey}-vcf_to`}
              />
            </div>
          </Grid>
          <Grid item xs={2} sm={4} md={4} className={classes.product}>
            <Typography
              fontFamily="inherit"
              fontSize="12px"
              sx={{
                borderBottom: '1px solid black',
                padding: '0px 5px',
                whiteSpace: 'nowrap',
                color: '#003399',
              }}
            >
              {' Net (bbl)'}
            </Typography>
            <div style={{ padding: '0px 0px' }}>
              <CustomInput
                type="input"
                label=""
                val={FirstRow?.fieldList?.net_to}
                required
                name="net_to"
                defaultValue=""
                attr={['num']}
                value={
                    prefixProducts?.[`${itemKey}-net_to`
                    ] || FirstRow?.fieldList?.net_to?.value
                          }
                isBatch={false}
                onChange={handleInputChange}
                size="100%"
                placeholder=""
                isDisabled={review || FirstRow?.fieldList?.net_to?.['data-attr']?.search('readonly') !== -1}
                customKey={itemKey}
                transaction
                prefix={itemKey}
                funNamePrefix={`${itemKey}-net_to`}
              />
            </div>
          </Grid>
          <Grid item xs={2} sm={4} md={4} className={classes.product}>
            <Typography
              fontFamily="inherit"
              fontSize="12px"
              sx={{
                borderBottom: '1px solid black',
                padding: '0px 5px',
                whiteSpace: 'nowrap',
                color: '#003399',
              }}
            >
              {' Weight (lb)'}
            </Typography>
            <div style={{ padding: '0px 0px' }}>
              <CustomInput
                type="input"
                label=""
                val={FirstRow?.fieldList?.netBase_to}
                required
                name="netBase_to"
                defaultValue=""
                attr={['num']}
                value={
                    prefixProducts?.[`${itemKey}-netBase_to`
                    ] || FirstRow?.fieldList?.net_to?.value
                          }
                isBatch={false}
                onChange={handleInputChange}
                size="100%"
                placeholder=""
                isDisabled={review || FirstRow?.fieldList?.netBase_to?.['data-attr']?.search('readonly') !== -1}
                customKey={itemKey}
                transaction
                prefix={itemKey}
                funNamePrefix={`${itemKey}-netBase_to`}
              />
            </div>
          </Grid>
        </div>
      </div>
      <Divider sx={{ color: 'blue' }} />
      <div className={classes.innerContainer}>
        <div className={classes.secondRowSubContainer}>
          <Grid item xs={2} sm={4} md={4} className={classes.product}>
            <Typography
              fontSize="12px"
              fontFamily="inherit"
              sx={{
                borderBottom: '1px solid #6172A7',
                padding: '0px 5px',
                whiteSpace: 'nowrap',
                color: '#003399',
              }}
            >
              Bay
            </Typography>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={
                          valuesToSend?.[itemKey]?.ld_bay || SecondRow?.ld_bay?.value
                        }
              name="ld_bay"
              disabled={review}
              onChange={(e: any) => handleInputProductChange('ld_bay', e, itemKey)}
              size="small"
              sx={{
                // product id field is wider than the other fields so we need to specify the condition for width
                minWidth: '100%',
                margin: '0px 5px',
                borderRadius: '0px',
                marginTop: '10px',
                '& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select':
                            {
                              padding: '0px 8px',
                              border: '1px solid #E4E4E4',
                            },
              }}
            >
              {baysOptions?.map((item: any) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label.replaceAll('&nbsp;', '')}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={2} sm={4} md={4} className={classes.product}>
            <Typography
              fontSize="12px"
              fontFamily="inherit"
              sx={{
                borderBottom: '1px solid #6172A7',
                padding: '0px 5px',
                whiteSpace: 'nowrap',
                color: '#003399',
              }}
            >
              Preset
            </Typography>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={
                          valuesToSend?.[itemKey]?.ld_ctl || SecondRow?.ld_ctl?.value
                        }
              name="ld_ctl"
              disabled={review}
              onChange={(e: any) => handleInputProductChange('ld_ctl', e, itemKey)}
              size="small"
              sx={{
                // product id field is wider than the other fields so we need to specify the condition for width
                minWidth: '100%',
                margin: '0px 5px',
                borderRadius: '0px',
                marginTop: '10px',
                '& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select':
                            {
                              padding: '0px 8px',
                              border: '1px solid #E4E4E4',
                            },
              }}
            >
              {OptionsPresets?.map((item: any) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label.replaceAll('&nbsp;', '')}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={2} sm={4} md={4} className={classes.product}>
            <Typography
              fontSize="12px"
              fontFamily="inherit"
              sx={{
                borderBottom: '1px solid #6172A7',
                padding: '0px 5px',
                whiteSpace: 'nowrap',
                color: '#003399',
              }}
            >
              Meter
            </Typography>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={
                          valuesToSend?.[itemKey]?.ld_mtr || SecondRow?.ld_mtr?.value
                        }
              name="ld_mtr"
              disabled={review}
              onChange={(e: any) => handleInputProductChange('ld_mtr', e, itemKey)}
              size="small"
              sx={{
                // product id field is wider than the other fields so we need to specify the condition for width
                minWidth: '100%',
                margin: '0px 5px',
                borderRadius: '0px',
                marginTop: '10px',
                '& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select':
                            {
                              padding: '0px 8px',
                              border: '1px solid #E4E4E4',
                            },
              }}
            >
              {OptionsMeters?.map((item: any) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label.replaceAll('&nbsp;', '')}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={2} sm={4} md={4} className={classes.product}>
            <Typography
              fontSize="12px"
              fontFamily="inherit"
              sx={{
                borderBottom: '1px solid #6172A7',
                padding: '0px 5px',
                whiteSpace: 'nowrap',
                color: '#003399',
              }}
            >
              ERP HT
            </Typography>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={
                          valuesToSend?.[itemKey]?.ERPHandlingType_to || SecondRow?.ERPHandlingType_to?.value
                        }
              name="ERPHandlingType_to"
              disabled={review}
              onChange={(e: any) => handleInputProductChange('ERPHandlingType_to', e, itemKey)}
              size="small"
              sx={{
                // product id field is wider than the other fields so we need to specify the condition for width
                minWidth: '100%',
                margin: '0px 5px',
                borderRadius: '0px',
                marginTop: '10px',
                '& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select':
                            {
                              padding: '0px 8px',
                              border: '1px solid #E4E4E4',
                            },
              }}
            >
              {setOptionFormat(SecondRow?.ERPHandlingType_to?.options)?.map((item: any) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label.replaceAll('&nbsp;', '')}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={2} sm={4} md={4} className={classes.product}>
            <Typography
              fontSize="12px"
              fontFamily="inherit"
              sx={{
                borderBottom: '1px solid #6172A7',
                padding: '0px 5px',
                whiteSpace: 'nowrap',
                color: '#003399',
              }}
            >
              Duty To Party
            </Typography>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={
                          valuesToSend?.[itemKey]?.dutytoparty_to || SecondRow?.dutytoparty_to?.value
                        }
              name="dutytoparty_to"
              disabled={review}
              onChange={(e: any) => handleInputProductChange('dutytoparty_to', e, itemKey)}
              size="small"
              sx={{
                // product id field is wider than the other fields so we need to specify the condition for width
                minWidth: '100%',
                margin: '0px 5px',
                borderRadius: '0px',
                marginTop: '10px',
                '& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select':
                            {
                              padding: '0px 8px',
                              border: '1px solid #E4E4E4',
                            },
              }}
            >
              {setOptionFormat(SecondRow?.dutytoparty_to?.options)?.map((item: any) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label.replaceAll('&nbsp;', '')}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={2} sm={4} md={4} className={classes.product}>
            <Typography
              fontFamily="inherit"
              fontSize="12px"
              sx={{
                borderBottom: '1px solid black',
                padding: '0px 5px',
                whiteSpace: 'nowrap',
                color: '#003399',
              }}
            >
              Ticket
            </Typography>
            <div style={{ padding: '0px 0px' }}>
              <CustomInput
                type="input"
                label=""
                val={SecondRow?.ticket_no_to}
                required
                name="ticket_no_to"
                defaultValue=""
                attr={['num']}
                value={
                            prefixProducts?.[itemKey]?.[
                              SecondRow?.ticket_no_to
                            ] || SecondRow?.dutytoparty_to?.value
                          }
                isBatch={false}
                onChange={handleInputChange}
                size="100%"
                placeholder=""
                isDisabled={false}
                customKey={itemKey}
                transaction
                prefix={prefix}
                funNamePrefix={`${prefix}-ticket_no_to`}
              />
            </div>
          </Grid>
        </div>
      </div>
    </div>
  ) : (<></>);
};

export default SupplierToRecord;
