import React, { useEffect, useState } from 'react';

// Librairies
import {
  Alert, Button, InputBase, Snackbar,
} from '@mui/material';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Components
import SubHeader from '../../../components/subHeader';
import TmsHeader from '../../../components/header';
import OptionsButton from '../../../components/optionsButton';
import SingleItemOptions from '../../../components/SingleItemOptions';
import Select from '../../../components/Select';
import MeterEntryTable from '../../../components/meterEntryTable';

// Styles
import { useStyles } from './style';

// Actions
import {
  clearMeterEntryNotification,
  getMeterEntryData,
  getNewMeterEntryColumns,
  setSelectedMeterRecord,
} from '../../../redux/actions/workflows/meterEntry';
import { getScreensList } from '../../../redux/actions/ScreenActions';
import { getRecordToShowValue } from '../../../utils';
import ColumnsDialog from '../../../components/ColumnsDialog';

// Utils
const tableHeader = [
  { label: 'Folio Month', key: 'fol_mo' },
  { label: 'Folio Number', key: 'fol_no' },
  { label: 'Folio Status', key: 'tt_status' },
  { label: 'Freeze Date', key: 'freeze_date' },
  { label: 'Number of Meters', key: 'meterNum' },
  { label: 'Terminal', key: 'terminal' },
  { label: 'Open date', key: 'open_date' },
];

// Utils
const columnsLabels = {
  fol_mo: 'Folio Month',
  fol_no: 'Folio Number',
  open_date: 'Open Date',
  freeze_date: 'Freeze Date',
  tt_status: 'Folio Status',
  meterNum: 'Number of Meters',
  terminal: 'Terminal ID',
};

interface ITableState {
  tableData: any[];
  currentPage: number;
  searchedDataCount: number;
  searchText: string;
  recordsNumber: string;
  orderBy: string;
  order: string;
  columnsDialogOpen: boolean;
  snackBarOpen: boolean;
}

const MeterEntryScreen: React.FC = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    data,
    totalRecords,
    hiddenColumns,
    visibleColumns,
    responseNotification,
    selectedRecord,
    totalRecordsWithSearch,
  } = useSelector((state: any) => state.Workflows.MeterEntry);
  const [tableState, setTableState] = useState<ITableState>(() => ({
    tableData: [],
    currentPage: 1,
    searchedDataCount: 0,
    recordsNumber: 'Auto',
    columnsDialogOpen: false,
    snackBarOpen: false,
    searchText: '',
    orderBy: '',
    order: '',
  }));
  const recordsToShow = getRecordToShowValue(
    tableState.recordsNumber,
    data.length,
    18,
  );

  useEffect(() => {
    if (tableState.searchText) {
      dispatch(getMeterEntryData(1, recordsToShow, tableState.searchText));
    } else {
      dispatch(getMeterEntryData(1, recordsToShow));
    }
  }, [tableState.searchText]);

  useEffect(() => {
    setTableState((prevState: ITableState) => ({
      ...prevState,
      tableData: data,
    }));
  }, [data]);

  const handleSortTable = (key: string) => {
    const orderType = !tableState.order || tableState.order === 'desc' ? 'asc' : 'desc';
    setTableState({ ...tableState, orderBy: key, order: orderType });
  };

  const handleSelectChange = (e: any, option: any) => {
    setTableState({ ...tableState, recordsNumber: option, currentPage: 1 });
    dispatch(
      getMeterEntryData(
        1,
        getRecordToShowValue(option, totalRecords, 18),
        tableState.searchText,
      ),
    );
  };

  const handlePaginationChange = (event: any, targetPage: any) => {
    setTableState({ ...tableState, currentPage: targetPage });
    dispatch(
      getMeterEntryData(
        targetPage,
        recordsToShow,
        tableState.searchText ? tableState.searchText : '',
      ),
    );
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTableState({
      ...tableState,
      searchText: e.target.value,
      currentPage: 1,
    });
  };

  useEffect(() => {
    dispatch(getMeterEntryData(tableState.currentPage, recordsToShow));
    dispatch(getScreensList());
  }, []);

  useEffect(() => {
    if (responseNotification?.type === 'success') {
      setTableState((prev) => ({ ...prev, snackBarOpen: true }));
      dispatch(getMeterEntryData(tableState.currentPage, recordsToShow));
    }
  }, [responseNotification?.type]);

  const handleOptionClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenColumnsDialog = () => {
    setTableState({ ...tableState, columnsDialogOpen: true });
    handleCloseMenu();
  };

  const handleCloseColumnsDialog = () => {
    setTableState({ ...tableState, columnsDialogOpen: false });
  };

  const handleClickRow = (row: any) => {
    dispatch(setSelectedMeterRecord(row));
    navigate(
      `recordDetail/${row.term_id.trim()}/${row.folioMonth}/${row.fol_no}/${
        row.status
      }`,
    );
  };

  const handleSubmitNewColumns = (newColumns: string[]) => {
    dispatch(getNewMeterEntryColumns(newColumns));
  };

  const handleRestoreDefault = () => {
    dispatch(
      getNewMeterEntryColumns([
        'fol_mo',
        'fol_no',
        'open_date',
        'freeze_date',
        'tt_status',
        'meterNum',
      ]),
    );
  };

  const handleCloseSnackBar = () => {
    setTableState({ ...tableState, snackBarOpen: false });
    dispatch(clearMeterEntryNotification());
  };

  return (
    <>
      <TmsHeader />
      <SubHeader title="Meter Entry">
        <OptionsButton handleClick={handleOptionClick} />
        <SingleItemOptions
          anchorEl={anchorEl}
          onClose={handleCloseMenu}
          openDialog={handleOpenColumnsDialog}
          itemLabel="Columns ..."
        />
      </SubHeader>
      <div
        className={clsx('flexStartSpaceBetweenRow', classes.subHeaderContainer)}
      >
        <div className={clsx('flexRow')}>
          <span className={classes.label}>Records :</span>
          <Select
            options={['Auto', '10', '20', '25', '35', '50', 'All']}
            width={200}
            value={tableState.recordsNumber}
            onChange={handleSelectChange}
          />
        </div>
        <div className="flexRow">
          <span className={classes.label}>Search :</span>
          <InputBase
            className={classes.searchInput}
            value={tableState.searchText}
            onChange={handleSearchChange}
          />
        </div>
      </div>
      <MeterEntryTable
        handleClickRow={handleClickRow}
        headerItems={tableHeader}
        data={tableState.tableData}
        recordsNumber={recordsToShow}
        totalRecords={totalRecords}
        currentPage={tableState.currentPage}
        handlePaginationChange={handlePaginationChange}
        sortTable={handleSortTable}
        orderBy={tableState.orderBy}
        order={tableState.order}
        visibleColumns={visibleColumns}
        columnsLabels={columnsLabels}
        searchText={tableState.searchText}
        recordsToShow={recordsToShow}
        searchedDataCount={totalRecordsWithSearch}
      />
      <ColumnsDialog
        onClose={handleCloseColumnsDialog}
        open={tableState.columnsDialogOpen}
        visibleColumns={visibleColumns}
        hiddenColumns={hiddenColumns}
        columnsLabels={columnsLabels}
        submitNewColumns={handleSubmitNewColumns}
        handleRestoreDefault={handleRestoreDefault}
      />
      <Snackbar
        open={tableState.snackBarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackBar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseSnackBar} severity="success">
          SUCCESS
          <div>
            Meter Entries Updated:
            {' '}
            {selectedRecord?.folioMonth}
            /
            {selectedRecord?.folioNumber}
            {' '}
            <Button onClick={() => navigate(-1)}>(Review)</Button>
            {' '}
          </div>
        </Alert>
      </Snackbar>
    </>
  );
};

export default MeterEntryScreen;
