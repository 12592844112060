/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import FormControl from '@mui/material/FormControl';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Checkbox, Typography } from '@mui/material';
import './style.css';
import { isObject } from '../../utils/index';

type selectProps = {
  label: string;
  size: any;
  options: any;
  isDisabled: boolean;
  name?: any;
  value?: any;
  defaultValue?: string;
  onChange?: any;
  isOptionEqualToValue?: any;
  helperText?: any;
  key?: any;
  type?: any;
  obj?: any;
  query?: any;
  chain?: any;
  stateValues?: any;
  selectList?: any;
  required?: any;
  isBatch?: any;
  valuesToSend?: any;
  multiple?: boolean;
  margin?: any;
  val?: any;
  datagrid?: boolean;
};

const useStyles = makeStyles({
  input: {
    '& input': {
      cursor: 'pointer !important',
    },
  },
  autoComplete: {
    cursor: 'pointer !important',
  },
});

const CustomDropdown = ({
  type,
  query,
  isBatch,
  valuesToSend,
  required,
  selectList,
  stateValues,
  label,
  obj,
  val,
  size,
  options,
  isDisabled,
  name,
  value,
  helperText,
  onChange,
  isOptionEqualToValue,
  key,
  multiple,
  datagrid,
}: selectProps) => {
  const [dynamicValues, setDynamicValues] = React.useState<any>([]);
  const classes = useStyles();
  const [chainedKeys, setChainedkeys] = React.useState<string[]>(() => {
    if (val?.childsDependency?.length > 0) {
      let chainkeys = val?.childsDependency ?? [];
      if (val?.childsDependency?.includes(name)) {
        const keyIndex = val?.childsDependency?.indexOf(name);
        if (keyIndex !== -1) {
          chainkeys = chainkeys?.splice(keyIndex, 1);
        }
      }
      return chainkeys;
    }
    return [];
  });

  // Intermediate method that updates values for chain type child keys
  // or invoke non-chain type onChange method.
  const InvokeOnChange = (
    fieldName: string, e: any, newValue: any, fieldLabel: string, isChainType = (type === 'chain'),
  ) => {
    if (isChainType) {
      onChange(name, e, newValue, label, chainedKeys);
    } else {
      onChange(name, e, newValue, label, undefined);
    }
  };
  React.useEffect(() => {
    if (['chain'].includes(type)) {
      const chainedFields = val?.query;
      if (selectList && !query.where) {
        setDynamicValues([
          { label: '-- Select --', value: '' },
          ...selectList.map((item: any) => ({
            label: Object.values(item).join(' - '),
            value: Object.values(item)[0],
          })),
        ]);
      } else {
        const whereQuery = (val?.chained?.where || val?.chain?.keys || obj?.['\u0000*\u0000parm']?.chaindatalist)?.map(
          (item: any) => `${Object.keys(item)?.[0]}='${
            valuesToSend[
              (Object.values(item)[0] as string)
                .replace('[', '')
                .replace(']', '')
            ] || Object.values(item)?.[0]
          }'`,
        ) || [''];
        const fetchQuery = `${
          process.env.REACT_APP_API_URL
        }tablescreen/tablemaintdata/?tableID=${query?.table || val?.label}
        &page=1&results_per_page=10000&ShowRecordQuery=1&fields=${([chainedFields || query?.fields])?.join(
    ',',
  )}&where=${whereQuery}`;
        fetch(fetchQuery)
          .then((res) => res.json())
          .then((res) => {
            setDynamicValues(
              res.data.map((item: any) => ({
                label: `${item[(chainedFields[0]).replace('DISTINCT ', '')]}${
                  item[(chainedFields[1] || val?.id)] ? ':' : ''
                }  ${item[(chainedFields[1] || val?.id)] || ''}`,
                value: item[(chainedFields[0] || val?.id).replace('DISTINCT ', '')],
              })),
            );
          });
      }
    }
  }, [type, stateValues]);

  const backgroundColor = React.useMemo(() => {
    if (val && !val.visible) {
      return '#fff9f0';
    }
    if (isDisabled) {
      return '#f8f8f8';
    }
    return '#fff';
  }, [value, isDisabled, val]);

  // UseMemo seems not working but for slow loading issue
  // Adding a faster algorithm
  const optionsObject = React.useMemo(
    () => (type === 'chain' ? dynamicValues : options)?.reduce(
      (acc: any, item: any) => {
        acc[item.value] = { label: item.label, value: item.value };
        return acc;
      },
      [],
    ),
    [dynamicValues, options],
  );

  const [isElementChecked, setIsElementChecked] = React.useState(false);
  const [localChangesMadeDropdown, setlocalChangesMadeDropdown] = React.useState({});

  const handleLocalChanges = (
    _name: any,
    e: any,
    newValue: any,
    _label: string,
  ) => {
    setIsElementChecked(true);
    setlocalChangesMadeDropdown({
      label: _name,
      value: newValue.value,
      class: newValue.class,
      selected: newValue.selected,
    });

    if (isBatch === 'Batch') {
      onChange(name, e, newValue, label);
    } else {
      onChange(name, e, newValue, label);
    }
  };

  const enableDisableLocalChanges = () => {
    setIsElementChecked(!isElementChecked);
    // e parameter is not being used on the BatchModeTerminalProfile.tsx, so I give it undefined for now
    if (isBatch === 'Batch') {
      const existingVal = dynamicValues?.[name]
        ? dynamicValues?.[name]
        : value === '' || value === undefined
          ? { label: '-- Select --', value: '' }
          : isObject(value)
            ? value
            : type === 'weekday'
              ? value
              : optionsObject?.[value] || {
                label: `${!value ? '-- Select --' : value}`,
                value,
              };
      if (existingVal && existingVal?.value !== '') {
        const mValue = isElementChecked === false
          ? { label: name, value: undefined }
          : { label: name, value: existingVal?.value };
        onChange(name, null, mValue, label);
      } else if (existingVal && existingVal?.value === '') {
        onChange(
          name,
          null,
          isElementChecked === false
            ? { label: 'null', value: null }
            : { label: name, value: null },
          label,
        );
        //     onChange(name, undefined, !isElementChecked ? localChangesMadeDropdown : { value: null }, label);
      }
    }
  };

  return (
    <div
      key={key}
      style={{
        // width: '100%',
        flex: 1,
        alignSelf: 'center',
        marginTop: datagrid ? '0px' : '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: 10,
      }}
    >
      {!datagrid && <Typography className="f12" style={{ flex: 1, textAlign: 'right' }}>{label}</Typography>}
      {!['grid'].includes(type)
        && (required === false || type === 'weekday')
        // checked={value !== undefined && (type === 'weekday' && value.length !== 0)}
        && isBatch === 'Batch' && (
          <Checkbox
            checked={value !== undefined}
            onClick={() => enableDisableLocalChanges()}
          />
      )}
      <FormControl
        disabled={isDisabled}
        size="small"
        variant="outlined"
        color={isDisabled ? 'error' : 'info'}
        sx={{ flex: 3 }}
      >
        {/* Value logic works for default values and selection values for single edit and batch edit
            Might need refactoring for better readability
        */}
        <Autocomplete
          className="autocomplete"
          // disablePortal
          disableClearable
          classes={{
            root: classes.autoComplete,
            input: classes.autoComplete,
            inputRoot: classes.autoComplete,
          }}
          value={
            type === 'aftx' && name === 'tt_status' && !isObject(value)
              ? options.find((x: any) => x.value === value) || {
                label: '-- Select --',
                value: '',
              }
              : type === 'termid' ? options?.[0] : type === 'aftx' && name !== 'lang' && !isObject(value) ? options?.length > 0 && !Number.isNaN(value) && value !== undefined ? options?.filter((item: any) => item?.value === value)[0] : { label: '-- Select --', value: '' }
                : ['dualsel01', 'dualselYN'].includes(type) && !isObject(value) ? value !== '' && value !== undefined && options?.length > 0 ? options?.filter((item: any) => item?.value === value)[0] : { label: 'No', value: 'No' }
                  : dynamicValues?.[name]
                    ? dynamicValues?.[name]
                    : (value === '' || value === undefined)
                      ? { label: name === 'state' && dynamicValues.length === 0 ? 'No Data Available' : '-- Select --', value: '' }
                      : isObject(value)
                        ? value?.value === 'null' ? { label: '-- Select --', value: '' } : value
                        : type === 'weekday'
                          ? value
                          : optionsObject?.[value] || {
                            label: `${!value ? '-- Select --' : value}`,
                            value,
                          }
          }
          // isOptionEqualToValue={isOptionEqualToValue}
          disabled={isDisabled}
          disableCloseOnSelect={multiple}
          id="simple-select-autocomplete"
          options={
            dynamicValues?.length > 0
              ? [...dynamicValues, { value: '', label: '-- Select --' }]
              : options || [{ value: '', label: 'No Data Available' }]
          }
          onChange={(e: any, newValue: any) => (isBatch === 'Batch'
            ? handleLocalChanges(name, e, newValue, label)
            : InvokeOnChange(name, e, newValue, label))}
          sx={{ width: size || 1000, color: '#f7f7f7' }}
          getOptionLabel={(option: any) => (isObject(option) ? option.label : option)}
          multiple={multiple}
          isOptionEqualToValue={isOptionEqualToValue}
          defaultValue=""
          renderInput={(params) => (
            <TextField
              {...params}
              className={classes.input}
              label={helperText}
              size="small"
              sx={{
                '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
                  padding: '2px',
                  border: '1px solid #dddddd',
                },
                '& .MuiInputBase-root': {
                  '& > fieldset': {
                    borderColor: required && value === '' ? 'red' : 'gray',
                    color: 'gray',
                    borderLeft: required
                      ? '5px solid #ff3b3b'
                      : '5px solid #555',
                    borderRadius: '0px !important',
                  },
                },
                '& .MuiInputBase-root.Mui-disabled': {
                  '& > fieldset': {
                    borderLeft: required
                      ? '5px solid #ff3b3b'
                      : '5px solid #555',
                    borderRadius: '0px !important',
                  },
                },
                background: backgroundColor,
              }}
            />
          )}
        />
      </FormControl>
    </div>
  );
};
CustomDropdown.defaultProps = {
  name: undefined,
  isBatch: undefined,
  value: undefined,
  defaultValue: undefined,
  isOptionEqualToValue: undefined,
  helperText: undefined,
  val: undefined,
  onChange: onchange,
  key: null,
  multiple: false,
};
export default CustomDropdown;
