import React from 'react';

const ProgressBar = (props:any) => {
  const {
    bgcolor, fillColor, completed, maxlength, alertLineValue,
  } = props;

  const containerStyles:any = {
    height: 20,
    width: '65%',
    backgroundColor: fillColor,
    margin: 0,
    borderColor: 'black',
    border: '1px solid black',
  };

  const fillerStyles:any = {
    height: '100%',
    width: `${completed}%`,
    maxWidth: maxlength,
    backgroundColor: bgcolor,
    borderRadius: 'inherit',
  };

  const alertLineStyles:any = {
    height: '100%',
    width: `${alertLineValue}%`,
    maxWidth: maxlength,
    backgroundColor: 'white',
    borderRadius: 'inherit',
    'border-right': `2px solid ${bgcolor}`,
  };

  return (
    <div style={containerStyles}>
      {alertLineValue
        ? <div style={alertLineStyles} />
        : <div style={fillerStyles} />}
    </div>
  );
};

export default ProgressBar;
