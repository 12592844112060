/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

// Librairies
import clsx from 'clsx';

// Styles
import { useStyles } from './style';

// Utils
import { upperSection, bottomSection } from './utils';

type KeyPressedEvent = string;
export type KeyPressedEventHandler = (event: KeyPressedEvent) => void;
interface IProps {
  keyPress: KeyPressedEventHandler;
}

export const KeyboardComponent: React.FC<IProps> = ({ keyPress }) => {
  const classes = useStyles();
  const handleOnClick = (e : any) => {
    keyPress(e.target.outerText);
  };
  return (
    <div className={classes.root}>
      <table className={classes.table}>
        <tbody>
          <tr className={classes.tr}>
            {upperSection.slice(0, 6).map((item) => (
              <td key={item} className={classes.td}>
                <span className={classes.keybButton} onClick={handleOnClick}>{item}</span>
              </td>
            ))}
          </tr>
          <tr className={classes.tr}>
            {upperSection.slice(6).map((item) => (
              <td key={item} className={classes.td}>
                <span className={classes.keybButton} onClick={handleOnClick}>{item}</span>
              </td>
            ))}
          </tr>
        </tbody>
      </table>
      <table className={classes.table}>
        <tbody>
          <tr className={classes.tr}>
            {bottomSection.slice(0, 7).map((item) => (
              <td
                key={item}
                className={classes.td}
                style={
                  item === 'enter'
                    ? {
                      width: '22%',
                    }
                    : undefined
                }
              >
                <span
                  className={clsx(
                    classes.keybButton,
                    item === 'enter' && classes.enterButton,
                  )}
                  onClick={handleOnClick}
                >
                  {item.toUpperCase()}
                </span>
              </td>
            ))}
          </tr>
          <tr className={classes.tr}>
            {bottomSection.slice(7, 14).map((item) => (
              <td key={item} className={classes.td}>
                <span className={classes.keybButton} onClick={handleOnClick}>{item.toUpperCase()}</span>
              </td>
            ))}
          </tr>
          <tr className={classes.tr}>
            {bottomSection.slice(14, 21).map((item) => (
              <td key={item} className={classes.td}>
                <span className={classes.keybButton} onClick={handleOnClick}>{item.toUpperCase()}</span>
              </td>
            ))}
          </tr>
          <tr className={classes.tr}>
            {bottomSection.slice(21, 28).map((item) => (
              <td key={item} className={classes.td}>
                <span className={classes.keybButton} onClick={handleOnClick}>{item.toUpperCase()}</span>
              </td>
            ))}
          </tr>
          <tr className={classes.tr}>
            {bottomSection.slice(28).map((item) => (
              <td key={item} className={classes.td}>
                <span
                  className={clsx(
                    classes.keybButton,
                    item === 'STOP' && classes.stopButton,
                  )}
                  onClick={handleOnClick}
                >
                  {item.toUpperCase()}
                </span>
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};
