/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Card, TextField, Grid,
} from '@mui/material';

import { createPortal } from 'react-dom';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box/Box';
import { useSelector } from 'react-redux';
import { mutliDragAwareReorder, multiSelectTo as multiSelect } from './utils';
import './style.css';

const dragEl:any = document.getElementById('draggable');

const optionalPortal = (styles:any, element:any) => {
  if (styles.position === 'fixed') {
    return createPortal(
      element,
      dragEl,
    );
  }
  return element;
};

// const entitiesMock:any = {
//   tasks: [
//     { id: '0', title: 'Product 0' },
//     { id: '1', title: 'Product 1' },
//     { id: '2', title: 'Product 2' },

//   ],
//   columnIds: ['todo', 'done'],
//   columns: {
//     todo: {
//       id: 'todo',
//       title: 'To do',
//       taskIds: [
//         '0',
//         '1',
//         '2',
//       ],
//     },
//     done: {
//       id: 'done',
//       title: 'Done',
//       taskIds: [],
//     },
//   },
// };

const COLUMN_ID_DONE:any = 'done';

// https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/button
const PRIMARY_BUTTON_NUMBER = 0;

export const TmsCustomDnD = ({ entitiesMock, saveTerminalProducts, title }:any) => {
  const {
    TerminalProducts,
    AccountProducts,
  } = useSelector((state: any) => state.Workflows.AddAuthProductsReducer);
  const [entities, setEntities] = useState<any>(entitiesMock);
  const [selectedTaskIds, setSelectedTaskIds] = useState<any>(AccountProducts);
  const [draggingTaskId, setDraggingTaskId] = useState<any>(null);
  const [searching, setSearching] = useState<any>('');
  const [searchedColumns, setSearchedColumns] = useState<any>([]);
  const [pageSize, setPageSize] = useState<any>(10);

  const tableColumns:any[] = [
    {
      title: 'Title',
      dataIndex: 'title',
    },
  ];

  /**
   * On window click
   */
  const onWindowClick = useCallback((e) => {
    if (e.defaultPrevented) {
      return;
    }

    setSelectedTaskIds([]);
  }, []);

  /**
   * On window key down
   */
  const onWindowKeyDown = useCallback((e) => {
    if (e.defaultPrevented) {
      return;
    }

    if (e.key === 'Escape') {
      setSelectedTaskIds([]);
    }
  }, []);

  /**
   * On window touch end
   */
  const onWindowTouchEnd = useCallback((e) => {
    if (e.defaultPrevented) {
      return;
    }

    setSelectedTaskIds([]);
  }, []);

  /**
   * Event Listener
   */
  useEffect(() => {
    window.addEventListener('click', onWindowClick);
    window.addEventListener('keydown', onWindowKeyDown);
    window.addEventListener('touchend', onWindowTouchEnd);

    return () => {
      window.removeEventListener('click', onWindowClick);
      window.removeEventListener('keydown', onWindowKeyDown);
      window.removeEventListener('touchend', onWindowTouchEnd);
    };
  }, [onWindowClick, onWindowKeyDown, onWindowTouchEnd]);

  /**
   * Droppable table body
   */
  const DroppableTableBody = ({ columnId, tasks, ...props }:any) => (
    <Droppable
      droppableId={columnId}
    >
      {(provided, snapshot) => (
        <tbody
          ref={provided.innerRef}
          {...props}
          {...provided.droppableProps}
          className={`${props.className} ${
            snapshot.isDraggingOver && columnId === COLUMN_ID_DONE
              ? 'is-dragging-over'
              : ''
          }`}
        />
      )}
    </Droppable>
  );

  /**
   * Draggable table row
   */
  // const DraggableTableRow = ({
  //   index, record, columnId, tasks, ...props
  // }:any) => {
  //   if (!tasks.length) {
  //     return (
  //       <tr className="ant-table-placeholder row-item" {...props}>
  //         <td colSpan={tableColumns.length} className="ant-table-cell">

  //         </td>
  //       </tr>
  //     );
  //   }

  //   const isSelected = selectedTaskIds.some(
  //     (selectedTaskId:any) => selectedTaskId === record.id,
  //   );
  //   const isGhosting = isSelected && Boolean(draggingTaskId) && draggingTaskId !== record.id;

  //   return (
  //     <Draggable
  //       key={props['data-row-key']}
  //       draggableId={props['data-row-key']}
  //       index={index}
  //     >
  //       {(provided, snapshot) => (
  //         <tr
  //           ref={provided.innerRef}
  //           {...props}
  //           {...provided.draggableProps}
  //           {...provided.dragHandleProps}
  //           className={`row-item ${isSelected ? 'row-selected' : ''} ${
  //             isGhosting ? 'row-ghosting' : ''
  //           } ${snapshot.isDragging ? 'row-dragging' : ''}`}
  //         />
  //       )}
  //     </Draggable>
  //   );
  // };

  /**
   * Get tasks
   */
  // Search Products

  const getTasks = (entities:any, id:any) => entities.columns[id].taskIds.map((taskId:any) => entities.tasks.find((item:any) => item.id === taskId));
  const handleSearch = (list:any) => {
    // eslint-disable-next-line no-multi-assign
    const filteredRows = list.filter((str:any) => (str?.title).toLowerCase().includes(searching.toLowerCase()));
    return filteredRows;
  };

  /**
   * On before capture
   */
  const onBeforeCapture = (start:any) => {
    const { draggableId } = start;
    const selected = selectedTaskIds.find((taskId:any) => taskId === draggableId);

    // if dragging an item that is not selected - unselect all items
    if (!selected) {
      setSelectedTaskIds([]);
    }

    setDraggingTaskId(draggableId);
  };

  /**
   * On drag end
   */
  const onDragEnd = (result:any) => {
    const { destination } = result;
    const { source } = result;

    // nothing to do
    if (!destination || result.reason === 'CANCEL') {
      setDraggingTaskId(null);
      return;
    }

    const processed = mutliDragAwareReorder({
      entities,
      selectedTaskIds,
      source,
      destination,
    });

    setEntities(processed.entities);
    saveTerminalProducts(processed.entities);
    setDraggingTaskId(null);
  };

  /**
   * Toggle selection
   */
  const toggleSelection = (taskId:any) => {
    const wasSelected = selectedTaskIds.includes(taskId);

    const newTaskIds:any = (() => {
      // Task was not previously selected
      // now will be the only selected item
      if (!wasSelected) {
        return [taskId];
      }

      // Task was part of a selected group
      // will now become the only selected item
      if (selectedTaskIds.length > 1) {
        return [taskId];
      }

      // task was previously selected but not in a group
      // we will now clear the selection
      return [];
    })();

    setSelectedTaskIds(newTaskIds);
  };

  /**
   * Toggle selection in group
   */
  const toggleSelectionInGroup = (taskId:any) => {
    const index = selectedTaskIds.indexOf(taskId);

    // if not selected - add it to the selected items
    if (index === -1) {
      setSelectedTaskIds([...selectedTaskIds, taskId]);

      return;
    }

    // it was previously selected and now needs to be removed from the group
    const shallow = [...selectedTaskIds];
    shallow.splice(index, 1);

    setSelectedTaskIds(shallow);
  };

  /**
   * Multi select to
   * This behaviour matches the MacOSX finder selection
   */
  const multiSelectTo = (newTaskId:any) => {
    const updated = multiSelect(entities, selectedTaskIds, newTaskId);

    if (updated == null) {
      return;
    }

    setSelectedTaskIds(updated);
  };

  /**
   * On click to row
   * Using onClick as it will be correctly
   * preventing if there was a drag
   */
  const onClickRow = (e:any, record:any) => {
    if (e.defaultPrevented) {
      return;
    }

    if (e.button !== PRIMARY_BUTTON_NUMBER) {
      return;
    }

    // marking the event as used
    e.preventDefault();
    performAction(e, record);
  };

  /**
   * On touch end from row
   */
  const onTouchEndRow = (e:any, record: any) => {
    if (e.defaultPrevented) {
      return;
    }

    // marking the event as used
    // we would also need to add some extra logic to prevent the click
    // if this element was an anchor
    e.preventDefault();
    toggleSelectionInGroup(record.id);
  };

  /**
   * Was toggle in selection group key used
   * Determines if the platform specific toggle selection in group key was used
   */
  const wasToggleInSelectionGroupKeyUsed = (e:any) => {
    const isUsingWindows = navigator.platform.indexOf('Win') >= 0;
    return isUsingWindows ? e.ctrlKey : e.metaKey;
  };

  /**
   * Was multi select key used
   * Determines if the multiSelect key was used
   */
  const wasMultiSelectKeyUsed = (e:any) => e.shiftKey;

  /**
   * Perform action
   */
  const performAction = (e:any, record:any) => {
    if (wasToggleInSelectionGroupKeyUsed(e)) {
      toggleSelectionInGroup(record.id);
      return;
    }

    if (wasMultiSelectKeyUsed(e)) {
      multiSelectTo(record.id);
      return;
    }

    toggleSelection(record.id);
  };

  /**
   * Handle table change
   */
  const handleTableChange = (pagination:any, filters:any, sorter:any) => {
    const { pageSize } = pagination;
    setPageSize(pageSize);
  };

  return (
    <>
      <Card
        className={`c-multi-drag-table ${draggingTaskId ? 'is-dragging' : ''}`}
      >
        <DragDropContext
          onBeforeCapture={onBeforeCapture}
          onDragEnd={onDragEnd}
        >
          <div style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
          >
            <TextField
              size="small"
              label="search"
              style={{
                alignSelf: 'flex-end',
                margin: '3px 10px',
              }}
              onChange={(e:any) => { setSearching(e.target.value); }}
            />
          </div>
          <Grid container>
            <div style={{
              display: 'flex',
              width: '100%',
            }}
            >
              {title?.map((id:any) => (
                <Grid item key={id} xs={12}>
                  <div className="inner-col">
                    <Box sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: 'auto',
                      boxShadow: 10,
                      padding: 1,
                    }}
                    >
                      <Typography fontSize="12px">{id}</Typography>
                      {/* <span>
                      {draggingTaskId && selectedTaskIds.length > 0
                        ? `${selectedTaskIds.length
                        } record(s) are being dragged`
                        : draggingTaskId && selectedTaskIds.length <= 0
                          ? '1 record(s) are being dragged'
                          : ''}
                    </span> */}
                    </Box>
                  </div>
                </Grid>
              ))}
            </div>
            <div style={{
              display: 'flex',
              width: '100%',
            }}
            >
              {entities.columnIds.map((id:any) => (
                <Grid item key={id} xs={12}>
                  <div className="inner-col">
                    {/* <Box sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: 560,
                      boxShadow: 10,
                      padding: 1,
                    }}
                    >
                      <Typography fontSize="12px">{id}</Typography>
                      {/* <span>
                      {draggingTaskId && selectedTaskIds.length > 0
                        ? `${selectedTaskIds.length
                        } record(s) are being dragged`
                        : draggingTaskId && selectedTaskIds.length <= 0
                          ? '1 record(s) are being dragged'
                          : ''}
                    </span> */}
                    {/* </Box> */}
                    <Droppable droppableId={entities.columns[id].id} key={entities.columns[id].id}>
                      {(provided, snapshot) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{
                            border: '3px solid #f2f2f2',
                            background: snapshot.isDraggingOver ? '#fff' : '#fff',
                            padding: 4,
                            width: 'auto',
                            height: 430,
                            marginTop: 10,
                            marginBottom: 10,
                            overflow: 'auto',
                          }}
                        >
                          {(searching !== '' ? handleSearch(getTasks(entities, id)) : getTasks(entities, id)).map((val:any, key:any) => (
                            <Draggable
                              key={val.id}
                              draggableId={val.id}
                              index={key}
                            >
                              {(providedChild, snapshotChild) => (
                                <div>
                                  {optionalPortal(providedChild.draggableProps.style, (
                                    <div
                                      ref={providedChild.innerRef}
                                      {...providedChild.draggableProps}
                                      {...providedChild.dragHandleProps}
                                      style={{
                                        userSelect: 'none',
                                        padding: 5,
                                        margin: '0 0 2px 0',
                                        minHeight: '1px',
                                        border: '1px solid #f2f2f2',
                                        backgroundColor: snapshotChild.isDragging || selectedTaskIds?.includes(val?.id)
                                          ? '#ffe284'
                                          : '#ffffff',
                                        color: '#000',
                                        ...providedChild.draggableProps.style,
                                      }}
                                      onClick={(e:any) => onClickRow(e, val)}
                                      onTouchEnd={(e:any) => onTouchEndRow(e, val)}
                                    >
                                      {val.title}
                                    </div>))}
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>

                    {/* <Table
                      dataSource={getTasks(entities, id)}
                      columns={id}
                      rowKey="id"
                      components={{
                        body: {
                        // Custom tbody
                          wrapper: (val:any) => DroppableTableBody({
                            columnId: entities.columns[id].id,
                            tasks: getTasks(entities, id),
                            ...val,
                          }),
                          // Custom td
                          row: (val:any) => DraggableTableRow({
                            tasks: getTasks(entities, id),
                            ...val,
                          }),
                        },
                      }}
                    // Set props on per row (td)
                      onRow={(record:any, index) => ({
                        index,
                        record,
                        onClick: (e) => onClickRow(e, record),
                        onTouchEnd: (e) => onTouchEndRow(e, record),
                      })}
                      onChange={handleTableChange}
                    /> */}
                  </div>
                </Grid>
              ))}
            </div>
          </Grid>
          <br />
        </DragDropContext>
      </Card>
    </>
  );
};
