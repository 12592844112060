import { SET_DEFAULT_TILE_LAYOUT, GET_DEFAULT_TILE_LAYOUT } from '../../constants/workflows';
import {
  START_LOADING, STOP_LOADING, SET_MESSAGE, DISSMISS_CONFIRMATION_ALERT,
} from '../../constants/Screens';

const INITIAL_STATE = {
  loading: false,
  confirmationAlert: '',
  openConfirmationAlert: false,
  alertStatus: '',
  data: {
    userID: localStorage.getItem('username'),
    cmd: 'tilelayout',
    parm: {
      action: '',
      cmd: 'tilelayout',
      layout: '',
      scale: '',
      frames: [],
    },
  },
};

const TileLayoutReducer = (state = INITIAL_STATE, action:any) => {
  switch (action.type) {
    case SET_DEFAULT_TILE_LAYOUT: {
      return {
        ...state,
        data: action.payload,
      };
    }
    case GET_DEFAULT_TILE_LAYOUT: {
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          parm: {
            ...state.data.parm,
            layout: action.payload.OptionValue.layout,
            scale: action.payload.OptionValue.scale,
            frames: JSON.parse(action.payload.OptionValue.frames),
          },
        },
      };
    }
    case START_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case STOP_LOADING: {
      return {
        ...state,
        loading: false,
      };
    }
    case SET_MESSAGE: {
      return {
        ...state,
        confirmationAlert: action.payload.message,
        openConfirmationAlert: action.payload.openAlter,
        alertStatus: action.payload.alertStatus,
      };
    }
    case DISSMISS_CONFIRMATION_ALERT: {
      return {
        ...state,
        openConfirmationAlert: false,
        confirmationAlert: '',
        alertStatus: '',
      };
    }
    default:
      return state;
  }
};

export default TileLayoutReducer;
