/* eslint-disable object-curly-newline */
/* eslint-disable max-len */
/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';

// Librairies
import {
  FormControl,
  Grid,
  InputBase,
  InputLabel,
  Paper,
  Select,
  Typography,
  MenuItem,
  Divider,
  Stack,
} from '@mui/material';

// Styles
import { ArrowDownward } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { useStyles } from './style';
import HoverTooltip from '../HoverTooltip';
import ConfirmationModalCommand from '../ConfirmationModal';
import { sendCommandRackStatus, setCurrentBay } from '../../../redux/actions/workflows/rackStatus';
import SmartLinkPop from '../../SmartLinkPop';

interface IProps {
    bayNumberDescription: string;
    bayOpt: string[];
    linkCustomer: any;
    linkCarrier: any;
    linkDriver: any;
    linkSupplier: any;
    bayStatusStyle: string;
    bayStatus: any;
    presetlist: any;
    bayID: string;
    bayNumber: string;
}

const RackStatusCard: React.FC<IProps> = ({
  bayNumberDescription,
  bayOpt,
  linkCustomer,
  linkDriver,
  linkSupplier,
  linkCarrier,
  bayStatusStyle,
  bayStatus,
  presetlist,
  bayID,
  bayNumber,
}) => {
  const classes = useStyles();
  const history = useNavigate();
  const dispatch = useDispatch();
  const [optionCommand, setOptionCommand] = useState<string>('');
  const [Visible, setVisible] = useState<boolean>(false);
  function cssToObj(css: string) {
    const obj: any = {};
    const s = css.toLowerCase().replace(/-(.)/g, (_m, g) => g.toUpperCase()).replace(/;\s?$/g, '').split(/:|;/g);
    for (let i = 0; i < s.length; i += 2) obj[s[i].replace(/\s/g, '')] = s[i + 1].replace(/^\s+|\s+$/g, '');
    return obj;
  }
  const procBayCommandModal = (e: any) => {
    console.log(e.target.value, bayID);
    setOptionCommand(e.target.value);
    setVisible(true);
  };
  const procBayCommand = (lrcid: string, localcommand: string) => {
    const queueid = `CMD-${lrcid}`;
    const packet = {
      queueid,
      reqid: `id-o${Math.random() * 10}uxk1b0hlb`,
      data: {
        cmd: 'cmdreq',
        parm: { lrcid, command: localcommand },
        data: null,
      },
    };
    dispatch(sendCommandRackStatus(packet));
    setOptionCommand('');
  };
  const openDetail = (bayNoID: string) => {
    dispatch(setCurrentBay(bayNoID, history));
  };
  return (
    <>
      <ConfirmationModalCommand
        Visible={Visible}
        bayID={bayID}
        bayNumberDescription={bayNumberDescription}
        command={optionCommand}
        setShowModal={setVisible}
        procBayCommand={procBayCommand}
      />
      <Grid item lg={3} xs={12} sm={6} md={6}>
        <Paper>
          <Grid container p={1}>
            <Grid item xs={1}>
              <div
                onClick={() => history('/workflow/rack-status-details')}
                style={{ cursor: 'pointer' }}
              >
                <ArrowDownward onClick={() => openDetail(bayNumber)} />
              </div>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1" className={classes.description}>
                {bayNumberDescription}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <FormControl sx={{ width: '100%' }}>
                <InputLabel id="demo-simple-select-label">Options</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Options"
                  size="small"
                  value=""
                  onChange={procBayCommandModal}
                >
                  {bayOpt.map((item: string) => <MenuItem key={item} value={item}>{_.upperFirst(item) || ''}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Divider className={classes.divider} />
            </Grid>
            <Grid item xs={12}>
              <div className={classes.statusHeadingDiv} style={cssToObj(bayStatusStyle)}>
                <Typography variant="h2" className={classes.statusHeading}>{bayStatus}</Typography>
              </div>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={5}>
              <Grid container>
                <Grid item xs={4}>
                  <Typography variant="body2" className={classes.fields}>
                    Driver:
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <SmartLinkPop
                    toolTipMessage={linkDriver?.toolTipMessage.replaceAll(/<br ?\/?>/ig, '\n') || ''}
                    toolTipTitle={linkDriver?.toolTipTitle}
                    label={linkDriver?.label}
                    title={linkDriver?.toolTipTitle}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container>
                <Grid item xs={4}>
                  <Typography variant="body2" className={classes.fields}>
                    Supplier:
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <SmartLinkPop
                    toolTipMessage={linkSupplier?.toolTipMessage.replaceAll(/<br ?\/?>/ig, '\n') || ''}
                    toolTipTitle={linkSupplier?.toolTipTitle}
                    label={linkSupplier?.label}
                    title={linkSupplier?.toolTipTitle}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={5}>
              <Grid container>
                <Grid item xs={4}>
                  <Typography variant="body2" className={classes.fields}>
                    Carrier:
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <SmartLinkPop
                    toolTipMessage={linkCarrier?.toolTipMessage.replaceAll(/<br ?\/?>/ig, '\n') || ''}
                    toolTipTitle={linkCarrier?.toolTipTitle}
                    label={linkCarrier?.label}
                    title={linkCarrier?.toolTipTitle}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container>
                <Grid item xs={4}>
                  <Typography variant="body2" className={classes.fields}>
                    Customer:
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <SmartLinkPop
                    toolTipMessage={linkCustomer?.toolTipMessage.replaceAll(/<br ?\/?>/ig, '\n') || ''}
                    toolTipTitle={linkCustomer?.toolTipTitle}
                    label={linkCustomer?.label}
                    title={linkCustomer?.toolTipTitle}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider className={classes.divider} />
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body2" className={classes.fields}>
                Presets:
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <div style={{ overflow: 'auto', minHeight: '23px', maxHeight: '23px', maxWidth: '400px' }}>
                <Grid container spacing={1}>
                  {presetlist && presetlist.map((preset: any) => (
                    <Grid item xs={12 / (presetlist.length > 2 ? 3 : 2)}>
                      <div key={preset?.presetDescription} style={{ ...cssToObj(preset?.style), textAlign: 'center', maxWidth: '95%', border: '0.5px solid black', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '0.8rem' }}>
                        {preset?.presetDescription}
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>
  );
};

export default RackStatusCard;
