/* eslint-disable react/require-default-props */
import React from 'react';

// Librairies
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// Components
import Input from '../Input';

interface IProps {
  handleChange: (value: Date | null) => void;
  pickerValue: Date | null;
  inputValue: string;
  inputStyle?: React.CSSProperties;
  required?: boolean;
}

const CustomDatePicker: React.FC<IProps> = ({
  handleChange,
  pickerValue,
  inputValue,
  inputStyle,
  required,
}) => {
  const [dateAnchorEl, setDateAnchorEl] = React.useState<null | HTMLElement>(
    null,
  );
  const [datePickerOpen, setDatePickerOpen] = React.useState(false);
  const handleDateInputClick = (event: React.MouseEvent<HTMLElement>) => {
    setDateAnchorEl(event.currentTarget);
    setDatePickerOpen(true);
  };
  const handleCloseDateTimePicker = () => {
    setDateAnchorEl(null);
    setDatePickerOpen(false);
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        inputFormat="yyyy/MM/dd"
        PopperProps={{ anchorEl: dateAnchorEl }}
        onChange={handleChange}
        value={pickerValue}
        renderInput={() => <></>}
        componentsProps={{
          actionBar: {
            actions: ['today'],
          },
        }}
        open={datePickerOpen}
        onClose={handleCloseDateTimePicker}
      />
      <Input
        onChange={() => null}
        value={inputValue}
        onClick={handleDateInputClick}
        style={inputStyle}
        required={required}
      />
    </LocalizationProvider>
  );
};

export default CustomDatePicker;
