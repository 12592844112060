/* eslint-disable react/no-array-index-key */
/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
import React from 'react';

// Librairies
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import clsx from 'clsx';
import { ArrowDropUp, ArrowDropDown, UnfoldMore } from '@mui/icons-material';

// Styles
import { useStyles } from './style';

// components
import Pagination from '../Pagination';
import TableItemsText from '../TableItemsText';
import { sortedTable } from '../../utils';

interface IProps {
  handleClickRow: (row?: any) => void;
  allDataCount: any;
  searchText?: string;
  recordsToShow: number;
  visibleColumns?: any;
  tableState: any;
  handlePaginationChange: (event: any, targetPage: any) => void;
  tableHeader: { [key: string]: string };
  orderBy?: string;
  order?: string;
  noPagination?: boolean;
  sortTable?: (key: string) => void;
  style?: React.CSSProperties;
}

const RecordChangeHistoryTable: React.FC<IProps> = ({
  handleClickRow,
  allDataCount,
  searchText,
  recordsToShow,
  visibleColumns,
  tableState,
  handlePaginationChange,
  tableHeader,
  orderBy,
  order,
  sortTable,
  noPagination,
  style,
}) => {
  const classes = useStyles();
  const { currentPage, searchedDataCount, tableData } = tableState;
  const countPages = Math.ceil(
    (searchText ? searchedDataCount : allDataCount) / recordsToShow,
  );

  const handleSortClick = (key: string) => (event: React.MouseEvent<unknown>) => {
    if (sortTable) {
      sortTable(key);
    }
  };

  return (
    <>
      <div className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table classes={{ root: classes.tableRoot }} style={style}>
            <TableHead>
              <TableRow classes={{ root: classes.headerRow }}>
                <TableCell classes={{ root: classes.headerCell }}>
                  <TableSortLabel
                    active={orderBy === 'user_id'}
                    onClick={handleSortClick('user_id')}
                    IconComponent={() => (order === 'asc' && orderBy === 'user_id' ? (
                      <ArrowDropUp />
                    ) : order === 'desc' && orderBy === 'user_id' ? (
                      <ArrowDropDown />
                    ) : (
                      <UnfoldMore />
                    ))}
                    classes={{ root: classes.sortLabel }}
                  >
                    User
                  </TableSortLabel>
                </TableCell>
                <TableCell classes={{ root: classes.headerCell }}>
                  <TableSortLabel
                    active={orderBy === 'change_datetime'}
                    onClick={handleSortClick('change_datetime')}
                    IconComponent={() => (order === 'asc' && orderBy === 'change_datetime' ? (
                      <ArrowDropUp />
                    ) : order === 'desc' && orderBy === 'change_datetime' ? (
                      <ArrowDropDown />
                    ) : (
                      <UnfoldMore />
                    ))}
                    classes={{ root: classes.sortLabel }}
                  >
                    Audit Date
                  </TableSortLabel>
                </TableCell>
                {Object.keys(visibleColumns).map((key) => (
                  <TableCell classes={{ root: classes.headerCell }} key={key}>
                    <TableSortLabel
                      active={orderBy === key}
                      onClick={handleSortClick(key)}
                      IconComponent={() => (order === 'asc' && orderBy === key ? (
                        <ArrowDropUp />
                      ) : order === 'desc' && orderBy === key ? (
                        <ArrowDropDown />
                      ) : (
                        <UnfoldMore />
                      ))}
                      classes={{ root: classes.sortLabel }}
                    >
                      {tableHeader[key]}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!tableData.length && (
                <TableRow className={classes.tableRow}>
                  <TableCell
                    classes={{ root: classes.bodyCellEmpty }}
                    style={{ textAlign: 'center' }}
                    colSpan={Object.keys(tableHeader).length}
                  >
                    {searchText
                      ? 'No matching records found'
                      : 'No data available in table'}
                  </TableCell>
                </TableRow>
              )}
              {sortedTable(tableData, orderBy, order)?.map((row, index) => {
                const newValueObj = row?.new_value;
                const oldValueObj = row?.old_value;
                return (
                  <TableRow
                    onClick={() => handleClickRow(row)}
                    className={classes.tableRow}
                    key={index}
                  >
                    <TableCell classes={{ root: classes.bodyCell }}>
                      {row.user_id}
                    </TableCell>
                    <TableCell classes={{ root: classes.bodyCell }}>
                      {row.change_datetime}
                    </TableCell>
                    {Object.keys(tableHeader).map((key: string) => {
                      const valueChanged: boolean = String(newValueObj?.[key])?.trim()
                          !== String(oldValueObj?.[key])?.trim() && row?.old_value;
                      return (
                        <TableCell
                          key={key}
                          classes={{ root: classes.bodyCell }}
                        >
                          <div style={{ display: 'flex', gap: 5 }}>
                            <span
                              className={
                                valueChanged ? classes.newValueStyle : undefined
                              }
                            >
                              {newValueObj?.[key]}
                            </span>
                            {valueChanged && (
                              <span
                                className={
                                  valueChanged
                                    ? classes.oldValueStyle
                                    : undefined
                                }
                              >
                                {oldValueObj?.[key]}
                              </span>
                            )}
                          </div>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </div>
      {!noPagination ? (
        <div className={clsx(classes.footerWrapper, 'flexRowAlignCenter')}>
          {!tableData.length ? (
            <div className={classes.showingText}>
              {' '}
              No records available
              {' '}
              {searchText
                ? `(filtered from ${allDataCount} total entries)`
                : null}
              {' '}
            </div>
          ) : (
            <TableItemsText
              allDataCount={allDataCount}
              countPages={countPages}
              currentPage={currentPage}
              recordsToShow={recordsToShow}
              searchText={searchText}
              searchedDataCount={searchedDataCount}
            />
          )}
          <span className="takeTheRest" />
          <Pagination
            count={countPages}
            onChange={handlePaginationChange}
            pageNumber={currentPage}
          />
        </div>
      ) : null}
    </>
  );
};

export default React.memo(RecordChangeHistoryTable);
