import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    paddingRight: '4px',
    paddingLeft: '4px',
  },
  viewOf: {
    borderColor: '#e5e5e5',
    backgroundColor: '#e5e5e5',
    padding: '5px',
    fontSize: '0.8rem',
  },
});
