import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {},
  dilog: {
    maxHeight: 'none',
  },
  closeButton: {
    color: 'black !important',
    textShadow: 'none !important',
    backgroundColor: '#ffbb33 !important',
    border: '1px solid transparent !important',
    padding: '6px 10px !important',
  },
  label: {
    paddingTop: '5px !important',
    paddingRight: '5px !important',
    fontSize: '12px !important',
    fontWeight: '400 !important',
    color: 'black !important',
  },
  tableCell: {
    fontSize: '12px !important',
    fontWeight: '400 !important',
    color: 'black !important',
  },
  wrapper: { justifyContent: 'flex-end' },
  dialogBody: { minHeight: 200 },
  applyButton: {
    color: 'black !important',
    textShadow: 'none !important',
    backgroundColor: '#00C851 !important',
    border: '1px solid transparent !important',
    padding: '6px 10px !important',
    '&:hover': {
      backgroundColor: '#007E33 !important',
      color: '#fff !important',
    },
    '&:disabled': {
      color: '#555 !important',
      backgroundColor: '#bcbcbc !important',
      border: '1px solid transparent',
    },
  },
  headerTitle: {
    fontSize: 12,
    fontWeight: 'bold',
    color: 'black',
  },
  headerValue: {
    fontSize: 12,
    fontWeight: 'normal',
    color: 'black',
  },
  headerWrapper: {
    paddingBottom: 20,
    paddingTop: 5,
  },
  divider: {
    marginBottom: '15px !important',
  },
});
