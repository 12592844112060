/* eslint-disable no-useless-escape */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable no-nested-ternary */
/* eslint-disable implicit-arrow-linebreak */
import React, { useEffect, useState } from 'react';

// Libraries
import {
  ButtonBase, DialogActions, Divider, Typography,
} from '@mui/material';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';

// Components
import { DialogComponent } from '../Dialog/Dialog.component';
import Input from '../Input';
import Select from '../Select';

// Styles
import { useStyles } from './style';
import {
  applyTankGaugeEntryDialog,
  getModalFreshData,
} from '../../redux/actions/workflows/tankGaugeEntry';
import MaskInput from '../Input/MaksInput';

interface IProps {
  onClose: () => void;
  open: boolean;
  selectedRow: any;
  selectedRowIndex: number | null;
  modalData: any;
  modalFields: any;
  selectedRecord: any;
}

// Utils
const makeModalStateData = (data: any) => {
  if (!data) return {};
  const result = {
    temperature: {
      serverKey: 'temperature',
      label: 'Temp',
      value: data.temperature,
    },
    GravityDensity: {
      serverKey: 'grav_density',
      label: 'Gravity / Density',
      value: data.GravityDensity,
    },
    ambientTemperature: {
      serverKey: 'ambient_temperature',
      label: 'Amb. Temp',
      value: data.ambientTemperature,
    },
    tsh: {
      serverKey: 'tank_shell',
      label: 'TSh',
      value: data.tsh,
    },
    s_w_pct: {
      serverKey: 's_w_pct',
      label: 'S&W%',
      value: data.s_w_pct,
    },
    grossObservedVolume: {
      serverKey: 'gross_inv',
      label: 'Gross Observed Volume',
      value: data.grossObservedVolume,
    },
    bottoms: {
      serverKey: 'bottoms',
      label: 'Bottoms',
      value: data.bottoms,
    },
    lineFill: {
      serverKey: 'line_fill',
      label: 'Line Fill',
      value: data.lineFill,
    },
    lineFill_1: {
      serverKey: 'line_fill1',
      label: 'Line Fill 1',
      value: data.lineFill_1,
    },
    lineFill_2: {
      serverKey: 'line_fill2',
      label: 'Line Fill 2',
      value: data.lineFill_2,
    },
    lineFill_3: {
      serverKey: 'line_fill3',
      label: 'Line Fill 3',
      value: data.lineFill_3,
    },
  };
  return result;
};

const getIsInputDisabled = (field: any, isUpdateDisabled: boolean) =>
  field.key === 's_w_pct'
  || field.key === 'tsh'
  || field.key === 'bottoms'
  || (isUpdateDisabled
    && field.serverKey !== 'prod_level'
    && field.serverKey !== 'water_level'
    && field.serverKey !== 'gross_inv');

const makeBottomModalData = (bottomRes: any) => ({
  bottoms: bottomRes?.bottoms,
  tov: bottomRes?.totalObservedVolume,
  tov_bottoms: bottomRes?.totalObservedVolumeBottoms,
  water_volume: bottomRes?.freeWaterVolume,
  gov1: bottomRes?.grossObservedVolume1,
  ctsh: bottomRes?.correctionForTemperatureOfShell,
  gov2: bottomRes?.grossObservedVolume2,
  FRCVolume: bottomRes?.floatingRoofAdjustment,
  gov3: bottomRes?.grossObservedVolume3,
  vcf: bottomRes?.correctionForTemperatureOfLiquid,
  FRCCalculatedStdVolume: bottomRes?.grossStandardVolume,
  csw: bottomRes?.CorrectionForSedimentWater,
  nsv: bottomRes?.netStandardVolume,
  linefills: bottomRes?.linefills,
  nsv_linefill: bottomRes?.netStandardVolumeLineFill,
  gravity_density_in_air: bottomRes?.densityInAir,
  FRCCalculatedWeight: bottomRes?.netStandardWeight,
});

const TankGaugeEntryDialog: React.FC<IProps> = ({
  onClose,
  open,
  selectedRow,
  selectedRowIndex,
  modalData,
  modalFields,
  selectedRecord,
}) => {
  const classes = useStyles();
  const [comment, setComment] = useState('');
  const [modalState, setModalState] = useState<any>();
  const [localData, setLocalData] = useState<any>(() => ({
    waterlevel: {
      serverKey: 'water_level',
      label: 'Water Level',
      value: '',
    },
    productlevel: {
      serverKey: 'prod_level',
      label: 'Product Level',
      value: '',
    },
  }));
  const summaryTable = modalFields?.summary;
  const dispatch = useDispatch();
  const { formData } = useSelector(
    (state: any) => state.Workflows.TankGaugeEntry,
  );
  const isUpdateDisabled = selectedRecord?.folioStatus === 'C' || selectedRecord?.folioStatus === 'M';
  const leftSideData = modalState
    ? Object.keys(modalState)
      .slice(0, 7)
      .map((key) => ({ key, ...modalState[key] }))
    : [];
  const rightSideData = modalState
    ? Object.keys(modalState)
      .slice(7)
      .map((key) => ({ key, ...modalState[key] }))
    : [];
  const bottomModalData: any = makeBottomModalData(modalData?.bottomModalRes);

  useEffect(() => {
    if (formData?.[selectedRowIndex as number]) {
      const formDataValue = formData?.[selectedRowIndex as number];
      const productlevelValue = formDataValue?.[
        `prod_level-${selectedRowIndex}`
      ]?.['\u0000*\u0000parm']
        ? formDataValue?.[`prod_level-${selectedRowIndex}`]?.[
          '\u0000*\u0000parm'
        ]?.value
        : formDataValue?.[`prod_level-${selectedRowIndex}`];
      const waterlevelValue = formDataValue?.[
        `water_level-${selectedRowIndex}`
      ]?.['\u0000*\u0000parm']
        ? formDataValue?.[`water_level-${selectedRowIndex}`]?.[
          '\u0000*\u0000parm'
        ]?.value
        : formDataValue?.[`water_level-${selectedRowIndex}`];
      setLocalData({
        waterlevel: {
          serverKey: 'water_level',
          label: 'Water Level',
          value: waterlevelValue,
        },
        productlevel: {
          serverKey: 'prod_level',
          label: 'Product Level',
          value: productlevelValue,
        },
      });
    } else {
      const productlevelValue = selectedRow?.[`prod_level-${selectedRowIndex}`]?.['\u0000*\u0000parm']
        ?.value;
      const waterlevelValue = selectedRow?.[`water_level-${selectedRowIndex}`]?.['\u0000*\u0000parm']
        ?.value;
      setLocalData({
        waterlevel: {
          serverKey: 'water_level',
          label: 'Water Level',
          value: waterlevelValue,
        },
        productlevel: {
          serverKey: 'prod_level',
          label: 'Product Level',
          value: productlevelValue,
        },
      });
    }
  }, []);

  useEffect(() => {
    const formattedData: any = makeModalStateData(modalData?.topModalRes);
    setModalState({
      ...modalState,
      ...formattedData,
    });
  }, [modalData?.topModalRes]);

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setModalState({
      ...modalState,
      [event.target.name]: {
        ...modalState[event.target.name],
        value: event.target.value,
      },
    });
  };

  const handleChangeLocalDataInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocalData({
      ...localData,
      [event.target.name]: {
        ...localData[event.target.name],
        value: event.target.value,
      },
    });
  };

  const handleInputBlur = (serverKey: string) => {
    dispatch(
      getModalFreshData(
        { ...modalState, ...localData },
        selectedRow,
        Number(selectedRowIndex),
        serverKey,
      ),
    );
  };

  const handleApply = () => {
    dispatch(
      applyTankGaugeEntryDialog(
        { ...modalState, ...localData },
        selectedRow[`tank_id-${selectedRowIndex}`]?.value,
        selectedRowIndex,
      ),
    );
    onClose();
  };

  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value);
  };

  return (
    <DialogComponent
      title={`Analytical and Observed Data: ${
        selectedRow[`tank_id-${selectedRowIndex}`]?.value
      }`}
      onClose={onClose}
      open={open}
      maxWidth="lg"
      className={classes.dilog}
    >
      <div className={classes.dialogBody}>
        <Divider variant="middle" style={{ marginTop: 7 }} />
        <div
          className="flexRow"
          style={{ padding: '0px 10px', paddingTop: 15, paddingBottom: 20 }}
        >
          <div
            className={clsx('flexRow', classes.wrapper)}
            style={{ width: '50%' }}
          >
            <Typography className={classes.label}>Tank</Typography>
            <Select
              options={[]}
              style={{ width: '75%' }}
              value={selectedRow[`tank_id-${selectedRowIndex}`]?.label}
              disabled
            />
          </div>
        </div>
        <Divider variant="middle" className={classes.divider} />
        <div style={{ padding: '0px 10px' }}>
          <div
            className="flexRow fullWidth"
            style={{ height: '30vh', overflowY: 'auto' }}
          >
            <div style={{ width: '50%' }}>
              <div
                className="flexRow"
                style={{ paddingBottom: 10 }}
              >
                <div className={clsx('flexRow fullWidth', classes.wrapper)}>
                  <Typography className={classes.label}>
                    Product Level
                  </Typography>
                  <MaskInput
                    onChange={handleChangeLocalDataInput}
                    onBlur={() => handleInputBlur('prod_level')}
                    value={localData?.productlevel?.value}
                    style={{ width: '75%' }}
                    name="productlevel"
                    mask={"99'99\"99"}
                  />
                </div>
              </div>
              <div
                className="flexRow"
                style={{ paddingBottom: 10 }}
              >
                <div className={clsx('flexRow fullWidth', classes.wrapper)}>
                  <Typography className={classes.label}>
                    Water Level
                  </Typography>
                  <MaskInput
                    onChange={handleChangeLocalDataInput}
                    onBlur={() => handleInputBlur('water_level')}
                    value={localData?.waterlevel?.value}
                    style={{ width: '75%' }}
                    name="waterlevel"
                    mask={"99'99\"99"}
                  />
                </div>
              </div>
              {leftSideData.map((field: any, index: number) => (
                <div
                  className="flexRow"
                  style={{ paddingBottom: 10 }}
                  key={index.toFixed()}
                >
                  <div className={clsx('flexRow fullWidth', classes.wrapper)}>
                    <Typography className={classes.label}>
                      {field.label}
                    </Typography>
                    {field.serverKey === 'prod_level'
                    || field.serverKey === 'water_level' ? (
                      <MaskInput
                        onChange={handleChangeInput}
                        onBlur={() => handleInputBlur(field.serverKey)}
                        value={field.value}
                        style={{ width: '75%' }}
                        name={field.key}
                        disabled={getIsInputDisabled(field, isUpdateDisabled)}
                        mask={"99'99\"99"}
                      />
                      ) : (
                        <Input
                          onChange={handleChangeInput}
                          onBlur={() => handleInputBlur(field.serverKey)}
                          value={field.value}
                          style={{ width: '75%' }}
                          type="number"
                          name={field.key}
                          disabled={getIsInputDisabled(field, isUpdateDisabled)}
                        />
                      )}
                  </div>
                </div>
              ))}
            </div>
            <div style={{ width: '50%' }}>
              {rightSideData.map((field: any, index: number) => (
                <div
                  className="flexRow"
                  style={{ paddingBottom: 10 }}
                  key={index.toFixed()}
                >
                  <div className={clsx('flexRow fullWidth', classes.wrapper)}>
                    <Typography className={classes.label}>
                      {field.label}
                    </Typography>
                    <Input
                      onBlur={() => handleInputBlur(field.serverKey)}
                      onChange={handleChangeInput}
                      value={field.value}
                      style={{ width: '75%' }}
                      type="number"
                      name={field.key}
                      disabled={getIsInputDisabled(field, isUpdateDisabled)}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
          <Divider variant="middle" />
          <div style={{ overflowY: 'auto', height: '40vh' }}>
            <table className="fullWidth" style={{ borderCollapse: 'collapse' }}>
              <thead>
                <tr style={{ textAlign: 'left' }}>
                  {summaryTable.headers.map((header: string) => (
                    <th style={{ borderBottom: '1pt solid black' }}>
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {summaryTable?.data?.map((value: any, index: number) => {
                  const key = Object.keys(value)?.[0];
                  const calculatedData = value?.[key];
                  const symbol: any = Object.values(
                    summaryTable?.symbol?.[index] || {},
                  )?.[0];
                  const unit = summaryTable.unit[index];
                  const unitValue = unit === 'empty' ? '' : bottomModalData?.[key];
                  const volume = summaryTable.voliume[index];
                  const volumeValue = volume === 'empty'
                    ? ''
                    : `${volume[Object.keys(volume)[1]]}${
                      bottomModalData?.[key]
                    }`;
                  return (
                    <tr key={key}>
                      <td className={classes.tableCell}>{calculatedData}</td>
                      <td className={classes.tableCell}>{symbol}</td>
                      <td className={classes.tableCell}>{unitValue}</td>
                      <td className={classes.tableCell}>{volumeValue}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div
          className={clsx('flexRow', classes.wrapper)}
          style={{ margin: '5px 5px' }}
        >
          <Typography className={classes.label}>Comment</Typography>
          <Input
            value={comment}
            style={{ width: '75%' }}
            onChange={handleCommentChange}
          />
        </div>
      </div>
      <DialogActions>
        <ButtonBase
          classes={{ root: classes.applyButton }}
          onClick={handleApply}
          disabled={isUpdateDisabled}
        >
          Apply
        </ButtonBase>
        <ButtonBase classes={{ root: classes.closeButton }} onClick={onClose}>
          Close
        </ButtonBase>
      </DialogActions>
    </DialogComponent>
  );
};

export default TankGaugeEntryDialog;
