import { SET_ACTIVE_STEP } from '../../constants/workflows';

const INITIAL_STATE = {
  activeStep: '',
  EditingStep: '',
};

const ContractTransactionReducer = (state = INITIAL_STATE, { type, payload }: any) => {
  switch (type) {
    case SET_ACTIVE_STEP: {
      return {
        ...state,
        activeStep: payload?.activeStep,
        EditingStep: payload?.EditingStep,
      };
    }
    default:
      return state;
  }
};

export default ContractTransactionReducer;
