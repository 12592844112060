import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  wrapper: {
    padding: '0px 10px',
    marginBottom: 10,
  },
  searchInput: {
    fontWeight: '400 !important',
    fontSize: '12px !important',
    lineHeight: '1.42857143 !important',
    padding: '4px 4px',
    color: '#333333',
    backgroundColor: '#fff',
    border: '1px solid #e5e5e5',
    height: 33,
    width: 200,
  },
  subHeaderContainer: {
    padding: '0px 10px',
    marginBottom: 5,
  },
  label: {
    marginRight: 5,
    fontSize: 12,
    fontWeight: 400,
    display: 'flex',
    alignItems: 'center',
  },
  closeButton: {
    borderWidth: '1px 1px 1px 5px !important',
    borderColor: '#ffb848 !important',
    color: 'black !important',
    textShadow: 'none !important',
    backgroundColor: '#ddd !important',
    border: '1px solid transparent !important',
    padding: '4px 10px !important',
    '&:hover': {
      color: '#fff !important',
      backgroundColor: '#545454 !important',
    },
  },
  actionsWrapper: {
    margin: 5,
    marginLeft: 30,
    gap: 15,
    display: 'flex',
  },
  saveButton: {
    verticalAlign: 'middle',
    textAlign: 'center',
    backgroundColor: '#00C851 !important',
    color: 'black !important',
    padding: '4px 10px !important',
    border: '1px solid transparent',
    marginLeft: '5px !important',
    '&:hover': {
      backgroundColor: '#007E33 !important',
      color: 'white !important',
    },
    '&:disabled': {
      color: '#555 !important',
      backgroundColor: '#bcbcbc !important',
      border: '1px solid transparent !important',
      cursor: 'not-allowed',
    },
  },
  cancleButton: {
    color: 'black !important',
    textShadow: 'none !important',
    backgroundColor: '#ffbb33 !important',
    padding: '4px 10px !important',
    marginLeft: '10px !important',
    border: '1px solid transparent',
    '&:hover': {
      backgroundColor: '#FF8800 !important',
      color: 'white !important',
    },
  },
  divider: {
    marginTop: '10px !important',
    marginBottom: '5px !important',
  },
  inputLabel: {
    fontSize: 12,
    color: 'black',
    fontWeight: 400,
  },
  inputContainer: {
    gap: 5,
  },
  form: {
    gap: 5,
    padding: '0px 5px',
  },
  padding: {
    paddingLeft: 145,
  },
});
