import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Alert, Button, Snackbar } from '@mui/material';

interface IUserSessionSnackbar {
  snackBarOpen: boolean;
  onClose: VoidFunction;
}

export const UserSessionSnackbar: React.FC<IUserSessionSnackbar> = ({ snackBarOpen, onClose }) => {
  const { responseNotification } = useSelector((state: any) => state.Workflows.ConditionSet);
  const navigate = useNavigate();
  const userSessionWasNotDeleted = responseNotification.title !== 'DELETE_CONDITION_SET_SUCCESS';
  return (
    <Snackbar
      open={snackBarOpen}
      autoHideDuration={3000}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <Alert onClose={onClose} severity="success">
        SUCCESS
        <div>
          Condition Set updated Successfully
          {' '}
          {userSessionWasNotDeleted ? (
            <Button onClick={() => navigate(-1)}>(Review)</Button>
          ) : null}
          {' '}
        </div>
      </Alert>
    </Snackbar>
  );
};
