/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react';

// Libraries
import {
  ButtonBase,
  DialogActions,
  Divider,
  DialogContent,
  Box,
  Tabs,
  Tab,
} from '@mui/material';

// Components
import { DragStart, DropResult } from 'react-beautiful-dnd';
import { DialogComponent } from '../Dialog/Dialog.component';
import { Columns } from '.';

// Styles
import { useStyles } from './style';

// Utils
import {
  deleteFromColumns,
  filterColumns,
  getColumns,
  makeColumnsFromIds,
  makeDefaultValue,
  makeMultiTabsDefaultValue,
} from './utils';
import { isEmpty } from '../../utils';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const TabsLabels = {
  TransHeader: 'Header',
  TransProducts: 'Products',
  TransComponents: 'Components',
  TransComments: 'Comments',
  TransEDI: 'EDI',
};

interface IProps {
  title?: string;
  onClose: () => void;
  open: boolean;
  visibleColumns: any;
  hiddenColumns: any;
  submitNewColumns: (columns: { [key: string]: string[] }) => void;
  handleRestoreDefault: () => void;
}

const MultiTabsColumnsDialog: React.FC<IProps> = ({
  onClose,
  open,
  hiddenColumns,
  visibleColumns,
  submitNewColumns,
  handleRestoreDefault,
  title,
}) => {
  const classes = useStyles();
  const [searchText, setSearchText] = useState('');
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
  const [draggingColumnId, setDraggingColumnId] = useState('');
  const [tabsState, setTabsState] = useState(() => ({
    tabValue: 0,
    tabSection: 'TransHeader',
  }));
  const [columns, setColumns] = useState<any>({
    visibleColumns: {
      TransComments: {},
      TransComponents: {},
      TransEDI: {},
      TransHeader: {},
      TransProducts: {},
    },
    hiddenColumns: {
      TransComments: {},
      TransComponents: {},
      TransEDI: {},
      TransHeader: {},
      TransProducts: {},
    },
  });

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    window.addEventListener('click', onWindowClick);
    window.addEventListener('keydown', onWindowKeyDown);
    window.addEventListener('touchend', onWindowTouchEnd);
  }, []);

  const handleChangeTabs = (event: React.SyntheticEvent, newValue: number) => {
    const tabSection = Object.keys(columns.visibleColumns)[newValue];
    setSearchText('');
    setTabsState({ tabValue: newValue, tabSection });
  };

  const onWindowKeyDown = (event: KeyboardEvent) => {
    if (event.defaultPrevented) {
      return;
    }

    if (event.key === 'Escape') {
      unselectAll();
    }
  };

  const onWindowClick = (event: MouseEvent) => {
    if (event.defaultPrevented) {
      return;
    }
    unselectAll();
  };

  const onWindowTouchEnd = (event: TouchEvent) => {
    if (event.defaultPrevented) {
      return;
    }
    unselectAll();
  };

  const unselectAll = () => {
    setSelectedColumns([]);
  };

  useEffect(() => {
    if (!isEmpty(visibleColumns) || !isEmpty(hiddenColumns)) {
      setColumns({
        hiddenColumns: makeMultiTabsDefaultValue(hiddenColumns),
        visibleColumns: makeMultiTabsDefaultValue(visibleColumns),
      });
    }
  }, [hiddenColumns, visibleColumns]);

  useEffect(() => {
    const filtredColumns: any = filterColumns(
      makeDefaultValue({
        ...visibleColumns[tabsState.tabSection],
        ...hiddenColumns[tabsState.tabSection],
      }),
      {
        ...(visibleColumns[tabsState.tabSection] as any),
        ...(hiddenColumns[tabsState.tabSection] as any),
      },
      searchText,
    );
    const newVisibleColumns = getColumns(
      columns.visibleColumns[tabsState.tabSection],
      filtredColumns,
    );
    const newHiddenColumns = getColumns(
      columns.hiddenColumns[tabsState.tabSection],
      filtredColumns,
    );
    setColumns((prev: any) => ({
      hiddenColumns: {
        ...prev.hiddenColumns,
        [tabsState.tabSection]: newHiddenColumns,
      },
      visibleColumns: {
        ...prev.visibleColumns,
        [tabsState.tabSection]: newVisibleColumns,
      },
    }));
  }, [searchText]);

  const handleDragFields = (result: DropResult) => {
    setDraggingColumnId('');
    if (!result) return;
    if (result?.destination?.droppableId !== result.source.droppableId) {
      if (result?.destination?.droppableId === 'hiddenColumns') {
        if (selectedColumns.length > 1) {
          const newVisibleColumns = deleteFromColumns(
            columns.visibleColumns[tabsState.tabSection],
            selectedColumns,
          );
          const newHiddenColumns = {
            ...columns.hiddenColumns[tabsState.tabSection],
            ...makeColumnsFromIds(
              selectedColumns,
              columns.visibleColumns[tabsState.tabSection],
            ),
          };
          setColumns({
            hiddenColumns: {
              ...columns.hiddenColumns,
              [tabsState.tabSection]: newHiddenColumns,
            },
            visibleColumns: {
              ...columns.visibleColumns,
              [tabsState.tabSection]: newVisibleColumns,
            },
          });
          return;
        }
        const newHiddenColumns = {
          ...columns.hiddenColumns[tabsState.tabSection],
          [result.draggableId]:
            columns.visibleColumns[tabsState.tabSection][result.draggableId],
        };
        const newVisibleColumns = {
          ...columns.visibleColumns[tabsState.tabSection],
        };
        delete newVisibleColumns[result.draggableId];
        setColumns({
          hiddenColumns: {
            ...columns.hiddenColumns,
            [tabsState.tabSection]: newHiddenColumns,
          },
          visibleColumns: {
            ...columns.visibleColumns,
            [tabsState.tabSection]: newVisibleColumns,
          },
        });
      } else {
        if (selectedColumns.length > 1) {
          const newVisibleColumns = {
            ...columns.visibleColumns[tabsState.tabSection],
            ...makeColumnsFromIds(
              selectedColumns,
              columns.hiddenColumns[tabsState.tabSection],
            ),
          };
          const arrayToReorder = Object.keys(newVisibleColumns);
          const [...removed] = arrayToReorder.splice(
            arrayToReorder.length - selectedColumns.length,
            selectedColumns.length,
          );
          arrayToReorder.splice(
            result?.destination?.index as number,
            0,
            ...removed,
          );
          const resultArray = makeColumnsFromIds(
            arrayToReorder,
            newVisibleColumns,
          );
          const newHiddenColumns = deleteFromColumns(
            columns.hiddenColumns[tabsState.tabSection],
            selectedColumns,
          );
          setColumns({
            hiddenColumns: {
              ...columns.hiddenColumns,
              [tabsState.tabSection]: newHiddenColumns,
            },
            visibleColumns: {
              ...columns.visibleColumns,
              [tabsState.tabSection]: resultArray,
            },
          });
          return;
        }
        const newVisibleColumns = {
          ...columns.visibleColumns[tabsState.tabSection],
          [result.draggableId]:
            columns.hiddenColumns[tabsState.tabSection][result.draggableId],
        };
        const arrayToReorder = Object.keys(newVisibleColumns);
        const [removed] = arrayToReorder.splice(arrayToReorder.length - 1, 1);
        arrayToReorder.splice(result?.destination?.index as number, 0, removed);
        const resultArray = makeColumnsFromIds(
          arrayToReorder,
          newVisibleColumns,
        );
        const newHiddenColumns = {
          ...columns.hiddenColumns[tabsState.tabSection],
        };
        delete newHiddenColumns[result.draggableId];
        setColumns({
          hiddenColumns: {
            ...columns.hiddenColumns,
            [tabsState.tabSection]: newHiddenColumns,
          },
          visibleColumns: {
            ...columns.visibleColumns,
            [tabsState.tabSection]: resultArray,
          },
        });
      }
      return;
    }
    if (
      result?.destination?.droppableId === result.source.droppableId
      && result?.destination?.droppableId === 'visibleColumns'
    ) {
      if (selectedColumns.length > 1) {
        const allColumnsArray = Object.keys(
          columns.visibleColumns[tabsState.tabSection],
        );
        const prevElemInOriginal = allColumnsArray[result.destination.index];
        const newVisibleColumns = deleteFromColumns(
          columns.visibleColumns[tabsState.tabSection],
          selectedColumns,
        );
        const arrayToReorder = Object.keys(newVisibleColumns);
        const prevElIndexInNewArray = arrayToReorder.indexOf(prevElemInOriginal);
        let destinationIndex;
        if (result.destination.index > result.source.index) {
          destinationIndex = prevElIndexInNewArray + 1;
        } else {
          destinationIndex = prevElIndexInNewArray;
        }
        arrayToReorder.splice(destinationIndex, 0, ...selectedColumns);
        const resultArray = makeColumnsFromIds(
          arrayToReorder,
          columns.visibleColumns[tabsState.tabSection],
        );
        setColumns({
          ...columns,
          visibleColumns: {
            ...columns.visibleColumns,
            [tabsState.tabSection]: resultArray,
          },
        });
        return;
      }
      const arrayToReorder = Object.keys(
        columns.visibleColumns[tabsState.tabSection],
      );
      const [removed] = arrayToReorder.splice(result.source.index, 1);
      arrayToReorder.splice(result.destination.index, 0, removed);
      const resultArray = makeColumnsFromIds(
        arrayToReorder,
        columns.visibleColumns[tabsState.tabSection],
      );
      setColumns({
        ...columns,
        visibleColumns: {
          ...columns.visibleColumns,
          [tabsState.tabSection]: resultArray,
        },
      });
    }
  };

  const handleApply = () => {
    const newColumns = Object.keys(columns.visibleColumns).reduce((acc, curr) => ({
      ...acc,
      [curr]: Object.keys(columns.visibleColumns[curr]),
    }), {});
    submitNewColumns(newColumns);
    onClose();
  };

  const onDragStart = (start: DragStart) => {
    const id: string = start.draggableId;
    const selected = selectedColumns.find((x) => x === id);
    if (!selected) {
      unselectAll();
    }
    setDraggingColumnId(start.draggableId);
  };

  const toggleSelection = (columnId: string) => {
    const selColumns = selectedColumns;
    const wasSelected: boolean = selColumns.includes(columnId);
    const newColumnsIds: string[] = (() => {
      if (!wasSelected) {
        return [columnId];
      }
      if (selColumns.length > 1) {
        return [columnId];
      }
      return [];
    })();

    setSelectedColumns(newColumnsIds);
  };

  const toggleSelectionInGroup = (columnId: string) => {
    const selCols: string[] = selectedColumns;
    const index: number = selCols.indexOf(columnId);
    if (index === -1) {
      setSelectedColumns([...selCols, columnId]);
      return;
    }
    const shallow: string[] = [...selCols];
    shallow.splice(index, 1);
    setSelectedColumns(shallow);
  };

  const multiSelectTo = (newColumnId: string) => {
    console.log(newColumnId);
  };

  return (
    <DialogComponent title={title || 'Columns'} onClose={onClose} open={open}>
      <DialogContent classes={{ root: classes.dialogBody }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={tabsState.tabValue}
            onChange={handleChangeTabs}
            aria-label="basic tabs example"
          >
            {Object.keys(visibleColumns).map((key) => (
              <Tab
                key={key}
                label={(TabsLabels as any)[key]}
                {...a11yProps(0)}
                style={{ textTransform: 'none' }}
              />
            ))}
          </Tabs>
        </Box>
        {Object.keys(visibleColumns).map((key, index) =>
          (index === tabsState.tabValue ? (
            <div
              role="tabpanel"
              hidden={tabsState.tabValue !== index}
              id={`simple-tabpanel-${index}`}
              key={key}
              aria-labelledby={`simple-tab-${index}`}
            >
              <Columns
                columns={{
                  hiddenColumns: columns.hiddenColumns[tabsState.tabSection],
                  visibleColumns: columns.visibleColumns[tabsState.tabSection],
                }}
                columnsLabels={{
                  ...(visibleColumns[tabsState.tabSection] as any),
                  ...(hiddenColumns[tabsState.tabSection] as any),
                }}
                draggingColumnId={draggingColumnId}
                handleDragFields={handleDragFields}
                handleSearchChange={handleSearchChange}
                multiSelectTo={multiSelectTo}
                onDragStart={onDragStart}
                searchText={searchText}
                selectedColumns={selectedColumns}
                toggleSelection={toggleSelection}
                toggleSelectionInGroup={toggleSelectionInGroup}
                leftTitle="Hidden Column (s)"
                rightTitle="Visible Column (s)"
              />
            </div>
          ) : null))}
      </DialogContent>
      <Divider variant="middle" />
      <DialogActions>
        <span className={classes.helperText}>
          Note: At least one column must be chosen
        </span>
        <span className="takeTheRest" />
        <ButtonBase
          classes={{ root: classes.restoreButton }}
          onClick={handleRestoreDefault}
        >
          Restore Default Columns
        </ButtonBase>
        <ButtonBase
          classes={{ root: classes.applyButton }}
          onClick={handleApply}
          disabled={!Object.keys(columns.visibleColumns).length}
        >
          Apply
        </ButtonBase>
        <ButtonBase classes={{ root: classes.closeButton }} onClick={onClose}>
          Cancel
        </ButtonBase>
      </DialogActions>
    </DialogComponent>
  );
};

export default MultiTabsColumnsDialog;
