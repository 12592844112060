/* eslint-disable max-len */
import { CircularProgress, Divider, Grid } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TmsHeader from '../../../components/header';
import SubHeader from '../../../components/subHeader';
import DynamicSearch from '../../../components/RackStatus/DynamicSearch';
import RackStatusBody from '../../../components/RackStatus/RackStatusBody';
import { getRackStatus, sendCommandRackStatus } from '../../../redux/actions/workflows/rackStatus';
import RackStatusOptionsButton from '../../../components/RackStatus/RackStatusOptionsButton';
import OptionsButton from '../../../components/optionsButton';
import ConfirmationModalCommand from '../../../components/RackStatus/ConfirmationModal';
import Loader from '../../../components/Loader';
import { getScreensList } from '../../../redux/actions/ScreenActions';

const RackStatus = () => {
  // eslint-disable-line
  const [Values, setValues] = useState<any>([]);
  const [filterTxt, setFilterTxt] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [Visible, setVisible] = useState<boolean>(false);
  const [optionCommand, setOptionCommand] = useState<string>('');
  const commandToSend = useRef<string>('');
  const dispatch = useDispatch();
  const { rackStatus } = useSelector((state: any) => state?.Workflows?.RackStatus);
  useEffect(() => {
    dispatch(getRackStatus());
    dispatch(getScreensList());
  }, []);
  useEffect(() => {
    if (rackStatus.length !== 0) {
      setValues(rackStatus);
    }
  }, [rackStatus]);
  const handleFilter = (e: any) => {
    setFilterTxt(e.target.value);
    setValues(rackStatus.filter((item: any) => item.bayStatus.includes(e.target.value.toUpperCase()) || item.bayID.includes(e.target.value.toUpperCase())
    || (item.presetlist && item.presetlist.find((pre: any) => pre.presetDescription.includes(e.target.value.toUpperCase())))));
  };
  const handleAnchorClose = () => {
    setAnchorEl(null);
  };
  const handleOpenDialog = (type:string) => {
    const messageList = {
      start: 'Start',
      stop: 'Stop',
      restart: 'Restart',
      drain: 'Drain',
      enable: 'Enable',
      disable: 'Disable',
      open: 'Open',
      togglebaytrace: 'Toggle Trace On/Off',
      togglebaydebug: 'Toggle Debug On/Off',
      stopallbays: 'Stop All Load Bays',
      startallbays: 'Start All Load Bays',
      drainallbays: 'Drain All Load Bays',
      pumprestoreall: 'Restore All Pumps',
      restartallbays: 'Restart All Load Bays',
    };
    setOptionCommand(messageList[type as keyof typeof messageList]);
    commandToSend.current = type;
    setVisible(true);
    setAnchorEl(null);
  };
  const handleOptionClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const procBayCommand = (lcrid: string, localcommand: string) => {
    const queueid = 'CMD-BAY00';
    const packet = {
      queueid,
      reqid: `id-o${Math.random() * 10}uxk1b0hlb`,
      data: {
        cmd: 'cmdreq',
        parm: { lrcid: 'BAY00', command: commandToSend.current },
        data: null,
      },
    };
    dispatch(sendCommandRackStatus(packet));
    setOptionCommand('');
    commandToSend.current = '';
  };
  return (
    rackStatus.length === 0 ? (
      <div
        style={{
          width: '100%',
          height: '90vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </div>
    ) : (
      <>
        <TmsHeader />
        <SubHeader title="Rack Status">
          <Loader getWorkflowData={getRackStatus} />
          <OptionsButton handleClick={handleOptionClick} />
          <RackStatusOptionsButton
            anchorEl={anchorEl}
            onClose={handleAnchorClose}
            handleOpenDialog={handleOpenDialog}
          />
        </SubHeader>
        <Grid container>
          <Grid item xs={12} lg={6} />
          <Grid item xs={12} lg={6}>
            <DynamicSearch
              current={Values.length}
              total={rackStatus.length}
              handleFilter={handleFilter}
              filterTxt={filterTxt}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{
              marginTop: '0.5em',
              marginBottom: '0.4em',
            }}
            />
          </Grid>
        </Grid>
        <RackStatusBody rackStatus={Values} />
        <ConfirmationModalCommand
          Visible={Visible}
          bayID={optionCommand}
          bayNumberDescription={optionCommand}
          command=""
          setShowModal={setVisible}
          procBayCommand={procBayCommand}
        />
      </>
    )
  );
};

export default RackStatus;
