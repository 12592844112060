import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  cancelButton: {
    color: 'black !important',
    textShadow: 'none !important',
    backgroundColor: '#ffbb33 !important',
    padding: '4px 10px !important',
    marginLeft: '10px !important',
    border: '1px solid transparent',
    '&:hover': {
      backgroundColor: '#FF8800 !important',
      color: 'white !important',
    },
  },
  dialogActions: {
    justifyContent: 'space-between',
    padding: '10px 20px',
  },
  wrapper: {
    padding: '0px 10px',
    marginBottom: 10,
  },
  searchInput: {
    fontWeight: '400 !important',
    fontSize: '12px !important',
    lineHeight: '1.42857143 !important',
    padding: '4px 4px',
    color: '#333333',
    backgroundColor: '#fff',
    border: '1px solid #e5e5e5',
    height: 33,
    width: 200,
  },
  subHeaderContainer: {
    padding: '0px 10px',
    marginBottom: 5,
  },
  label: {
    marginRight: 5,
    fontSize: 12,
    fontWeight: 400,
    display: 'flex',
    alignItems: 'center',
  },
  tab: {
    textTransform: 'none',
  },
});
