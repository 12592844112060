/* eslint-disable no-unused-expressions */
/* eslint-disable max-len */
import { makeStyles } from '@mui/styles';
import React from 'react';
import './style.css';

interface IProps {
  fractionsheader: string,
  firstGridRowOne: any;
  secondGrid: any;
  fractionsGrid: any;
  firstGridRowtwo: any;
}
const useStyles = makeStyles({
  customtable: {
    boxSizing: 'border-box',
    borderSpacing: '0px',
    backgroundColor: 'transparent',
    borderCollapse: 'collapse',
    marginBottom: '1px',
    marginRight: '1px',
    borderRadius: '0px',
  },
  customth: {
    boxSizing: 'border-box',
    padding: '0px',
    borderWidth: '1px 1px 2px',
    borderStyle: 'solid',
    borderColor:
'rgb(221, 221, 221) rgb(221, 221, 221) rgb(170, 170, 170)',
    borderImage: 'initial',
    fontWeight: 'bold',
    height: '22px',
    width: '34px',
    textAlign: 'center',
    borderRadius: '0px',
  },
  customInput: {
    boxSizing: 'border-box',
    margin: '0px',
    font: 'inherit',
    fontFamily: 'inherit',
    outline: 'none',
    appearance: 'none',
    display: 'block',
    width: '100%',
    backgroundImage: 'none',
    transition:
                      'border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s',
    color: 'rgb(51, 51, 51)',
    backgroundColor: 'rgb(255, 255, 255)',
    boxShadow: 'none',
    fontSize: '12px',
    lineHeight: 1.42857,
    height: 'auto',
    border: '1px solid transparent',
    padding: '0px',
    textAlign: 'center',
    borderRadius: '0px',
    fontWeight: 400,
  },
  tankcontainerdiv: {
    boxSizing: 'border-box',
    outline: 'none',
    appearance: 'none',
    flexFlow: 'column wrap',
    minHeight: '300px',
    display: 'flex',
    alignContent: 'flex-start',
    overflowX: 'scroll',
    height: 'calc(100vh - 300px)',
    borderRadius: '0px',
    borderCollapse: 'collapse',
    textAlign: 'center',
  },
  customtd: {
    boxSizing: 'border-box',
    border: '1px solid rgb(221, 221, 221)',
    height: '22px',
    padding: '0px',
    textAlign: 'center',
    borderRadius: '0px',
  },
  customtdhead: {
    boxSizing: 'border-box',
    cursor: 'pointer',
    border: '1px solid rgb(221, 221, 221)',
    height: '22px',
    padding: '0px 5px',
    textAlign: 'right',
    borderRadius: '0px',
  },
});
const TankStrappingFirstGrid: React.FC<IProps> = ({
  firstGridRowOne, firstGridRowtwo, fractionsheader, secondGrid, fractionsGrid,
}) => {
  const classes = useStyles();
  return (
    <>
      <div>
        <table
          className={`tankstrapfractions ${classes.customtable}`}
        >
          <tbody style={{ boxSizing: 'border-box' }}>
            {firstGridRowOne.map((row: any) => (
              <th
                className={`levelother ${classes.customth}`}
              >
                {row}
              </th>
            ))}
          </tbody>
          <tr>
            {firstGridRowtwo.map((row: any, index: number) => {
              if (index === 0) {
                return (
                  <th className="levelother volume_header">GALLONS</th>
                );
              } if (row.mode === 'readonly') {
                return (
                  <td className="levelvalue fraction">
                    <div id={row.id}>{row.value}</div>
                  </td>
                );
              }
              return (
                <td style={{ boxSizing: 'border-box' }} className="levelvalue fraction">
                  <input
                    id={row.id}
                    name={row.id}
                    className={`form-control nosizehelper ${classes.customInput} ${row.inputClass} ${row.unsaved}`}
                    type="text"
                    maxLength={20}
                    value={row.value}
                    data-type="input"
                  />
                </td>
              );
            })}
          </tr>
        </table>
        <div className="gaugeheightgroup">
          <div className="gaugeheightcontainer">
            <h4>Gauge Height Chart</h4>
            <table className="tankinfogroupcontainer">
              <tbody>
                <tr>
                  <td>
                    <div className="tankcontainergroup">
                      <div
                        className={`tankcontainer ${classes.tankcontainerdiv}`}
                      >
                        {secondGrid.map((row: any) => (
                          <table
                            className={`tankstraplevel ${classes.customtable}`}
                          >
                            <tbody style={{ boxSizing: 'border-box' }}>
                              <tr style={{ boxSizing: 'border-box' }}>
                                <th
                                  className={`level_header ${classes.customth}`}
                                >
                                  {row.levelHeading}

                                </th>
                                <th
                                  className={`volume_header ${classes.customth}`}
                                >
                                  {row.volumeHeading}

                                </th>
                              </tr>
                              <tr className="" style={{ boxSizing: 'border-box' }}>
                                <td
                                  className={`levelone levelheight interpolate start ${classes.customtd}`}
                                  data-interpolate={row.dataInterpolate}
                                  data-height={row.dataHeight}
                                >
                                  {row.curLevel}
                                </td>
                                <td className="levelvalue">
                                  {row.mode === 'readonly' ? (
                                    <input
                                      id={row.id}
                                      name={row.id}
                                      className={`form-control nosizehelper ${classes.customInput}`}
                                      type="text"
                                      value={row.value}
                                      data-type="input"
                                      readOnly
                                    />
                                  )
                                    : (
                                      <input
                                        id={row.id}
                                        name={row.id}
                                        className={`form-control nosizehelper ${row.inputClass} ${row.unsaved} ${classes.customInput}`}
                                        type="text"
                                        maxLength={Number(row.maxLength)}
                                        value={row.value}
                                        data-type="input"
                                      />
                                    )}
                                </td>
                              </tr>
                              {row.secondRow.map((item: any) => (item.isset === 'edit' ? (
                                <tr>
                                  <td
                                    className={`levelother levelheight ${item.class} ${classes.customtdhead}`}
                                    data-interpolate={item.interpolationIndex}
                                    data-height={item.height}
                                  >
                                    {item.levelStr}
                                  </td>
                                  <td
                                    className={`levelvalue ${classes.customtd}`}
                                  >
                                    {item.value}
                                  </td>
                                </tr>
                              ) : (
                                <tr>
                                  <td className="noborder">
                                    <td className="noborder" />
                                  </td>
                                </tr>
                              )))}
                            </tbody>
                          </table>
                        ))}
                      </div>
                    </div>
                  </td>
                  <td>
                    <h4>{fractionsheader}</h4>
                    <div className="fractionscontainer">
                      <table className={`tankstraplevel ${classes.customtable}`}>
                        <tbody style={{ boxSizing: 'border-box' }}>
                          <tr>
                            <th className={`level_header ${classes.customtable}`}>{fractionsGrid.levelHeading}</th>
                            <th className={`volume_header ${classes.customtable}`}>{fractionsGrid.volumeHeading}</th>
                          </tr>
                          {fractionsGrid.secondRow.map((item: any) => (item.isset !== '' ? (
                            <tr>
                              <td className={`levelother levelheight ${item.class}`} data-interpolate={item.interpolationIndex} data-height={item.height}>
                                {item.levelStr}
                              </td>
                              <td className="levelvalue">
                                {item.value}
                              </td>
                            </tr>
                          ) : (
                            <tr>
                              <td className="noborder">
                                <td className="noborder" />
                              </td>
                            </tr>
                          )))}
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default TankStrappingFirstGrid;
