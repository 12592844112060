import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  'input-xs': { fontSize: '10px', padding: '1px 5px', height: '20px' },
  'form-control-inline': { minWidth: '0', width: 'auto', display: 'inline' },
  report: {
    minWidth: '500px',
    background: '#dddddd',
    backgroundImage:
        'radial-gradient(\n      rgba(221, 221, 221, 0) 0%,\n      rgba(221, 221, 221, 0) 29%,\n      #ffffff 31%,\n      #ffffff 100%\n    ),\n    radial-gradient(\n      rgba(0, 0, 0, 0) 0%,\n      rgba(0, 0, 0, 0) 29%,\n      rgba(0, 0, 0, 0.15) 31%,\n      rgba(0, 0, 0, 0.15) 100%\n    ),\n    radial-gradient(\n      rgba(221, 221, 221, 0) 0%,\n      rgba(221, 221, 221, 0) 29%,\n      #ffffff 31%,\n      #ffffff 100%\n    ),\n    radial-gradient(\n      rgba(0, 0, 0, 0) 0%,\n      rgba(0, 0, 0, 0) 29%,\n      rgba(0, 0, 0, 0.15) 31%,\n      rgba(0, 0, 0, 0.15) 100%\n    )',
    backgroundPosition: 'left top, left 1px, right top, right 1px',
    backgroundRepeat: 'repeat-y',
    backgroundSize: '40px 40px',
    padding: '0 40px',
    margin: '0 auto',
    fontFamily: 'Courier New',
  },
  reportpagebody: {
    border: '1px solid #cfe9c9',
    borderRadius: '10px',
    padding: '0.1px 20px',
    backgroundColor: '#ffffff',
    backgroundImage:
        'linear-gradient(\n    to bottom,\n    #ffffff 0px,\n    #ffffff 59px,\n    #cfe9c9 60px,\n    #eff7ed 61px,\n    #eff7ed 119px,\n    #cfe9c9 120px\n  )',
    backgroundSize: '100% 120px',
    overflow: 'hidden',
    fontFamily: 'Courier New',
  },
  reportline: {
    fontSize: '12px', height: '20px', whiteSpace: 'pre', fontFamily: 'Courier New',
  },
  'printquickjump button': { padding: '2px 4px' },
  spacer: {
    height: '5px',
    width: '100%',
    fontSize: '0',
    margin: '0',
    padding: '0',
    border: '0',
    display: 'block',
  },
  systemgreybtn: {
    color: 'rgb(187, 187, 187)',
    background: 'black',
    width: '55px',
    font: '10px / 14.2857px "Open Sans"',
    height: '15px',
    marginRight: '2px',
  },
  printjumpquick: {
    paddingTop: '0',
    padding: '3px',
    maxHeight: '1000px',
  },
  printarea: {
    display: 'block',
    height: '90vh',
    overflow: 'auto',
  },
});
