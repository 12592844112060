import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  tableRoot: {
    '&:before': {
      content: '"\u200C"',
      display: 'block',
      lineHeight: '8px',
      boxSizing: 'border-box',
    },
  },
  tableRow: {
    boxSizing: 'border-box',
    '&:hover': {
      backgroundColor: '#F5F5F5',
    },
  },
  tableCell: {
    border: '1px solid #ddd',
  },
});
