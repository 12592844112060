import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {},
  td: {
    boxSizing: 'border-box',
    padding: '0px',
    borderRadius: '0px',
    borderCollapse: 'collapse',
    color: 'rgb(85, 85, 85)',
  },
  presetsubtitle: {
    boxSizing: 'border-box',
    outline: 'none',
    appearance: 'none',
    fontSize: '60%',
    color: 'rgb(153, 153, 153)',
    textAlign: 'center',
    borderRadius: '0px',
  },
  presetsubdata: {
    boxSizing: 'border-box',
    outline: 'none',
    appearance: 'none',
    border: '1px solid rgb(170, 170, 170)',
    margin: '0px 5px 5px',
    borderRadius: '3px',
    padding: '2px',
    fontSize: '0.9rem',
  },
  presetrow1: {
    boxSizing: 'border-box',
    outline: 'none',
    appearance: 'none',
    fontFamily: 'Orbitron, monospace',
    textAlign: 'right',
    paddingRight: '5px',
    borderRadius: '0px',
  },
  span: {
    boxSizing: 'border-box',
    borderRadius: '0px',
  },
});
