/* eslint-disable quote-props */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useState } from 'react';

// Librairies
import {
  Alert,
  Box,
  ButtonBase,
  Divider,
  IconButton,
  Tab,
  Tabs,
  useMediaQuery,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

// Components
import { DropResult, DragStart } from 'react-beautiful-dnd';
import { isBefore } from 'date-fns';
import SubHeader from '../../../components/subHeader';
import TmsHeader from '../../../components/header';
import OptionsButton from '../../../components/optionsButton';
import Input from '../../../components/Input';
import DatePicker from '../../../components/DatePicker';

// Styles
import { useStyles } from './style';

// Actions
import { getScreensList } from '../../../redux/actions/ScreenActions';

// Utils
import { getPGCRDateFromString, getPGCRFormattedDate, isEmpty } from '../../../utils';
import {
  getPGCRRecord,
  removePGCR,
  setPGCRFormHeader,
  updatePGCR,
} from '../../../redux/actions/workflows/productGroupCrossReference';
import SingleItemOptions from '../../../components/SingleItemOptions';
import {
  IColumns,
  makeDefaultValue,
  getColumns,
  deleteFromColumns,
  makeColumnsFromIds,
  filterColumns,
} from '../../../components/ColumnsDialog/utils';
import { Columns } from '../../../components/ColumnsDialog';
import ConfirmationDialog from '../../../components/ConfirmDialog';
import PGCRRecordChangeHistory from '../../../components/PGCRRecordChangeHistotory';

interface IState {
  anchorEl: null | HTMLElement;
  recordChangeHistoryDialogOpen: boolean;
}

const formatHeaderValues = (showFields: any[]) => showFields.reduce(
  (acc, curr) => ({
    ...acc,
    [curr.id]: curr,
  }),
  {},
);

const columnsLabels = {
  '000007': '000007 - PBOB BASE',
  '000040': '000040 - JET-A BASE',
  '000100': '000100 - TRANSMIX BASE-A',
  '000180': '000180 - ULSD BASE',
  '000ETH': '000ETH - ETHANOL BASE',
  '001180': '001180 - ULSD',
  '001181': '001181 - ULSD DYED',
  '001211': '001211 - MIDGRADE ETH 10% ',
  '001221': '001221 - REGULAR ETH 10%',
  '001231': '001231 - PREMIUM ETH 10% ',
  '001280': '001280 - ULSDTXAD',
  '001ETH': '001ETH - ETHANOL',
  '001ETO': '001ETO - ETHANOL OFFLOAD',
  '002040': '002040 - JET-A',
  '102231': '102231 - PREMIUM ETH 10% TOP TIER',
  '002041': '002041 - JET-A WITH ADDITIVE',
  '002100': '002100 - TRANSMIX',
  '002180': '002180 - ULSD',
  '002211': '002211 - MIDGRADE ETH 10%',
  '002221': '002221 - REGULAR ETH 10%',
  '002231': '002231 - PREMIUM ETH 10%',
  '002280': '002280 - ULSDTXAD',
  '002281': '002281 - DYED ULSDTXAD',
  '002ETH': '002ETH - ETHANOL',
  '003180': '003180 - ULSD',
  '102211': '102211 - MIDGRADE ETH 10% TOP TIER',
  '003181': '003181 - ULSD DYED',
  '003211': '003211 - MIDGRADE ETH 10 PCT',
  '003221': '003221 - REGULAR ETH 10%',
  '003231': '003231 - PREMIUM ETH 10%',
  '003280': '003280 - ULSDTXAD',
  '003281': '003281 - DYED ULSDTXAD',
  '008040': '008040 - JETA  JET FUEL',
  '008041': '008041 - JET A ADDITIZED',
  '102221': '102221 - REGULAR ETH 10% TOP TIER',
  '014041': '014041 - JET-A WITH ADDITIVE',
  EASADD: 'EASADD - METHYL CARBITOL EASTERN',
  GENAD2: 'GENAD2 - HiTEC 6590c',
  LUBAD3: 'LUBAD3 - HITEC 4155AS DS ADD',
  'PRIST ': 'PRIST  - METHYL CARBITOL',
  REDDYE: 'REDDYE - UNISOL LIQUID RED BK 50',
  'TXLED ': 'TXLED  - GREEN PLUS A',
  '000005': '000005 - CBOB SUB GRADE',
  '002181': '002181 - DYED ULSD',
  '000080': '000080 - DATA INTEGRITY FAILURE',
  '000081': '000081 - DATA INTEGRITY FAILURE',
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const TabPanel = (props: any) => {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
};

const PGCRRecordDetail: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [cancelConfirmDialogOpen, setCancelConfirmDialogOpen] = useState<boolean>(false);
  const [removeConfirmDialogOpen, setRemoveConfirmDialogOpen] = useState<boolean>(false);
  const [showWarningMessage, setShowWarningMessage] = useState(() => ({
    show: false,
    userRemoveIt: false,
  }));
  const { termID, productGroup } = useParams();
  const navigate = useNavigate();
  const { currentRecord, formHeader } = useSelector(
    (state: any) => state.Workflows.ProductGroupCrossReference,
  );
  const [recordState, setRecordState] = useState<IState>(() => ({
    recordChangeHistoryDialogOpen: false,
    anchorEl: null,
  }));
  const [columns, setColumns] = useState<IColumns>({
    visibleColumns: {},
    hiddenColumns: {},
  });

  useEffect(() => {
    dispatch(getScreensList());
    dispatch(getPGCRRecord({ termID, productGroup }));
  }, []);

  useEffect(() => {
    if (!isEmpty(formHeader)) {
      const effectiveDate = new Date(formHeader?.effect_date);
      const expireDate = new Date(formHeader?.expire_date);
      if (isBefore(expireDate, effectiveDate) && !showWarningMessage.userRemoveIt) {
        setShowWarningMessage((prev: any) => ({ ...prev, show: true }));
      } else {
        setShowWarningMessage((prev: any) => ({ ...prev, show: false }));
      }
    }
  }, [formHeader]);

  const headerValues = currentRecord?.showFields
    ? formatHeaderValues(currentRecord?.showFields)
    : {};

  const handleOpenrecordChangeHistoryDialog = () => {
    setRecordState({
      ...recordState,
      recordChangeHistoryDialogOpen: true,
      anchorEl: null,
    });
  };

  const openCancelConfirmDialog = () => {
    if (formHeader?.change) {
      setCancelConfirmDialogOpen(true);
    } else {
      window.location.href = '/workflow/product-group-cross-reference';
    }
  };

  const closeCancelConfirmDialog = () => {
    setCancelConfirmDialogOpen(false);
  };

  const closeAgreeCancelConfirmDialog = () => {
    closeCancelConfirmDialog();
    window.location.href = '/workflow/product-group-cross-reference';
  };

  const openRemoveConfirmDialog = () => {
    setRemoveConfirmDialogOpen(true);
  };

  const closeRemoveConfirmDialog = () => {
    setRemoveConfirmDialogOpen(false);
  };

  const closeAgreeRemoveConfirmDialog = () => {
    dispatch(removePGCR(Object.keys(currentRecord?.groupProducts), formHeader));
    navigate('/workflow/product-group-cross-reference');
  };

  const handleCloserecordChangeHistoryDialog = () => {
    setRecordState({
      ...recordState,
      recordChangeHistoryDialogOpen: false,
    });
  };

  const handleOptionClick = (event: React.MouseEvent<HTMLElement>) => {
    setRecordState({
      ...recordState,
      anchorEl: event.currentTarget,
    });
  };

  const handleCloseMenu = () => {
    setRecordState({
      ...recordState,
      anchorEl: null,
    });
  };

  const handleSave = () => {
    dispatch(
      updatePGCR(
        Object.keys(columns.visibleColumns),
        Object.keys(currentRecord?.groupProducts),
        formHeader,
      ),
    );
    navigate('/workflow/product-group-cross-reference');
  };

  return (
    <div className="fullHeight flexColumn">
      <TmsHeader />
      <SubHeader title="Product Group Cross Reference: Detail">
        <OptionsButton handleClick={handleOptionClick} />
        <SingleItemOptions
          anchorEl={recordState.anchorEl}
          itemLabel="Record Change History..."
          onClose={handleCloseMenu}
          openDialog={handleOpenrecordChangeHistoryDialog}
        />
      </SubHeader>
      <div>
        <ButtonBase className={classes.saveButton} onClick={handleSave}>
          Save
        </ButtonBase>
        <ButtonBase
          className={classes.cancleButton}
          onClick={openCancelConfirmDialog}
        >
          Cancel
        </ButtonBase>
        <ButtonBase
          className={classes.removeButton}
          onClick={openRemoveConfirmDialog}
        >
          Remove
        </ButtonBase>
      </div>
      <Divider className={classes.divider} />
      {showWarningMessage.show ? (
        <Alert
          severity="warning"
          icon={false}
          className={classes.alert}
          action={(
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setShowWarningMessage({ userRemoveIt: true, show: false });
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
              )}
        >
          <strong className={classes.warningTitle}>Warning!</strong>
          {' '}
          <span className={classes.warningMessage}>Effective Date is AFTER Expiration Date</span>
        </Alert>
      ) : null}
      <PGCRForm values={headerValues} formValues={formHeader} />
      <div>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 1 }}>
          <Tabs aria-label="basic tabs example">
            <Tab label="Products" {...a11yProps(0)} className={classes.tab} />
          </Tabs>
        </Box>
        <TabPanel value={0} index={0}>
          <ProductsComponent
            columns={columns}
            setColumns={setColumns}
            visibleColumns={currentRecord?.groupProducts || {}}
            hiddenColumns={currentRecord?.terminalProducts || {}}
            formValues={formHeader}
          />
        </TabPanel>
      </div>
      <ConfirmationDialog
        cancelLabel="Cancel"
        confirmLabel="Yes"
        handleCloseAgree={closeAgreeCancelConfirmDialog}
        handleCloseCancel={closeCancelConfirmDialog}
        message="You have made changes
        on this page. If you leave without
        saving, your changes will be lost. Do you want to continue?"
        open={cancelConfirmDialogOpen}
        title="Leave Page"
        helperText="This action cannot be undone."
      />
      <ConfirmationDialog
        cancelLabel="Cancel"
        confirmLabel="Yes"
        handleCloseAgree={closeAgreeRemoveConfirmDialog}
        handleCloseCancel={closeRemoveConfirmDialog}
        message="You are about to remove this record. Do you want to continue?"
        open={removeConfirmDialogOpen}
        title="Remove Entry"
        helperText="This action cannot be undone."
      />
      <PGCRRecordChangeHistory
        historyModalData={currentRecord?.historyModal || {}}
        onClose={handleCloserecordChangeHistoryDialog}
        open={recordState.recordChangeHistoryDialogOpen}
        limitNumber={25}
      />
    </div>
  );
};

const PGCRForm: React.FC<{ values: any; formValues: any }> = ({
  values,
  formValues,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const matches = useMediaQuery('(min-width:992px)');
  const [formState, setFormState] = React.useState(() => ({
    term_id: '',
    prod_group: '',
    effect_date: {
      inputValue: '',
      pickerValue: new Date(),
    },
    expire_date: {
      inputValue: '',
      pickerValue: new Date(),
    },
  }));

  useEffect(() => {
    const formattedEffectDate = values?.effect_date?.value
      && getPGCRDateFromString(values?.effect_date?.value?.trim());
    const formattedExpireDate = values?.expire_date?.value
      && getPGCRDateFromString(values?.expire_date?.value?.trim());
    setFormState({
      term_id: values?.term_id?.value?.trim() || '',
      prod_group: values?.prod_group?.value?.trim() || '',
      effect_date: {
        inputValue: formattedEffectDate || '',
        pickerValue: formattedEffectDate
          ? new Date(formattedEffectDate)
          : new Date(),
      },
      expire_date: {
        inputValue: formattedExpireDate || '',
        pickerValue: formattedExpireDate
          ? new Date(formattedExpireDate)
          : new Date(),
      },
    });
    dispatch(
      setPGCRFormHeader({
        term_id: values?.term_id?.key?.trim() || '',
        prod_group: values?.prod_group?.key?.trim() || '',
        effect_date: formattedEffectDate || '',
        expire_date: formattedExpireDate || '',
        change: false,
      }),
    );
  }, [
    values?.term_id?.value,
    values?.prod_group?.value,
    values?.effect_date?.value,
    values?.expire_date?.value,
  ]);

  const handleDatePickerChange = (value: Date | null, name: string) => {
    if (value) {
      const formattedDate = getPGCRFormattedDate(value);
      if (formattedDate) {
        dispatch(
          setPGCRFormHeader({
            ...formValues,
            change: true,
            [name]: formattedDate,
          }),
        );
        setFormState({
          ...formState,
          [name]: {
            inputValue: `${formattedDate}`,
            pickerValue: value,
          },
        });
      }
    }
  };

  const handleTextInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setFormState({ ...formState, [event.target.name]: event.target.value });
  };

  return (
    <form className={clsx(classes.form, 'flexColumn')}>
      <div
        className={clsx(
          classes.inputContainer,
          matches ? 'flexRowAlignCenter' : 'flexColumn',
        )}
      >
        <div
          className={clsx(matches && 'flexStartEndColumn', classes.inputLabel)}
          style={{ width: '25%' }}
        >
          Terminal ID
        </div>
        <Input
          onChange={handleTextInputChange}
          value={formState.term_id}
          name="term"
          disabled
          style={{
            minWidth: matches ? 500 : undefined,
            width: matches ? '75%' : undefined,
          }}
        />
      </div>
      <div
        className={clsx(
          classes.inputContainer,
          matches ? 'flexRowAlignCenter' : 'flexColumn',
        )}
      >
        <div
          className={clsx(matches && 'flexStartEndColumn', classes.inputLabel)}
          style={{ width: '25%' }}
        >
          Product Group
        </div>
        <Input
          onChange={handleTextInputChange}
          value={formState.prod_group}
          name="prod_group"
          disabled
          style={{
            minWidth: matches ? 500 : undefined,
            width: matches ? '75%' : undefined,
          }}
        />
      </div>
      <div
        className={clsx(
          classes.inputContainer,
          matches ? 'flexRowAlignCenter' : 'flexColumn',
        )}
      >
        <div
          className={clsx(matches && 'flexStartEndColumn', classes.inputLabel)}
          style={{ width: '25%' }}
        >
          Effective Date
        </div>
        <DatePicker
          handleChange={(value) => handleDatePickerChange(value, 'effect_date')}
          inputValue={formState.effect_date?.inputValue}
          pickerValue={formState.effect_date?.pickerValue}
          inputStyle={{
            minWidth: matches ? 500 : undefined,
            width: matches ? '75%' : undefined,
          }}
          required
        />
      </div>
      <div
        className={clsx(
          classes.inputContainer,
          matches ? 'flexRowAlignCenter' : 'flexColumn',
        )}
      >
        <div
          className={clsx(matches && 'flexStartEndColumn', classes.inputLabel)}
          style={{ width: '25%' }}
        >
          Expiration Date
        </div>
        <DatePicker
          handleChange={(value) => handleDatePickerChange(value, 'expire_date')}
          inputValue={formState.expire_date?.inputValue}
          pickerValue={formState.expire_date?.pickerValue}
          inputStyle={{
            minWidth: matches ? 500 : undefined,
            width: matches ? '75%' : undefined,
          }}
          required
        />
      </div>
    </form>
  );
};

interface IProductsComponent {
  columns: IColumns;
  setColumns: React.Dispatch<React.SetStateAction<IColumns>>;
  visibleColumns: { [key: string]: string };
  hiddenColumns: { [key: string]: string };
  formValues: any;
}

export const ProductsComponent: React.FC<IProductsComponent> = ({
  columns,
  setColumns,
  hiddenColumns,
  visibleColumns,
  formValues,
}) => {
  const [searchText, setSearchText] = useState('');
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
  const [draggingColumnId, setDraggingColumnId] = useState('');
  const dispatch = useDispatch();

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    if (
      Object.keys(hiddenColumns).length !== 0
      || Object.keys(visibleColumns).length !== 0
    ) {
      setColumns({
        hiddenColumns: makeDefaultValue(hiddenColumns),
        visibleColumns: makeDefaultValue(visibleColumns),
      });
    }
  }, [hiddenColumns, visibleColumns]);

  useEffect(() => {
    window.addEventListener('click', onWindowClick);
    window.addEventListener('keydown', onWindowKeyDown);
    window.addEventListener('touchend', onWindowTouchEnd);
  }, []);

  const onWindowKeyDown = (event: KeyboardEvent) => {
    if (event.defaultPrevented) {
      return;
    }

    if (event.key === 'Escape') {
      unselectAll();
    }
  };

  const onWindowClick = (event: MouseEvent) => {
    if (event.defaultPrevented) {
      return;
    }
    unselectAll();
  };

  const onWindowTouchEnd = (event: TouchEvent) => {
    if (event.defaultPrevented) {
      return;
    }
    unselectAll();
  };

  const unselectAll = () => {
    setSelectedColumns([]);
  };

  useEffect(() => {
    const filtredColumns: any = filterColumns(
      makeDefaultValue({ ...visibleColumns, ...hiddenColumns }),
      columnsLabels,
      searchText,
    );
    const newVisibleColumns = getColumns(
      columns.visibleColumns,
      filtredColumns,
    );
    const newHiddenColumns = getColumns(columns.hiddenColumns, filtredColumns);
    setColumns({
      hiddenColumns: newHiddenColumns,
      visibleColumns: newVisibleColumns,
    });
  }, [searchText]);

  const handleDragFields = (result: DropResult) => {
    setDraggingColumnId('');
    if (!result) return;
    dispatch(
      setPGCRFormHeader({
        ...formValues,
        change: true,
      }),
    );
    if (result?.destination?.droppableId !== result.source.droppableId) {
      if (result?.destination?.droppableId === 'hiddenColumns') {
        if (selectedColumns.length > 1) {
          const newHiddenColumns = {
            ...columns.hiddenColumns,
            ...makeColumnsFromIds(selectedColumns, columns.visibleColumns),
          };
          const arrayToReorder = Object.keys(newHiddenColumns);
          const [...removed] = arrayToReorder.splice(
            arrayToReorder.length - selectedColumns.length,
            selectedColumns.length,
          );
          arrayToReorder.splice(
            result?.destination?.index as number,
            0,
            ...removed,
          );
          const resultArray = makeColumnsFromIds(
            arrayToReorder,
            newHiddenColumns,
          );
          const newVisibleColumns = deleteFromColumns(
            columns.visibleColumns,
            selectedColumns,
          );
          setColumns({
            hiddenColumns: resultArray,
            visibleColumns: newVisibleColumns,
          });
          return;
        }
        const newHiddenColumns = {
          ...columns.hiddenColumns,
          [result.draggableId]: columns.visibleColumns[result.draggableId],
        };
        const arrayToReorder = Object.keys(newHiddenColumns);
        const [removed] = arrayToReorder.splice(arrayToReorder.length - 1, 1);
        arrayToReorder.splice(result?.destination?.index as number, 0, removed);
        const resultArray = makeColumnsFromIds(
          arrayToReorder,
          newHiddenColumns,
        );
        const newVisibleColumns = { ...columns.visibleColumns };
        delete newVisibleColumns[result.draggableId];
        setColumns({
          hiddenColumns: resultArray,
          visibleColumns: newVisibleColumns,
        });
      } else {
        if (selectedColumns.length > 1) {
          const newVisibleColumns = {
            ...columns.visibleColumns,
            ...makeColumnsFromIds(selectedColumns, columns.hiddenColumns),
          };
          const arrayToReorder = Object.keys(newVisibleColumns);
          const [...removed] = arrayToReorder.splice(
            arrayToReorder.length - selectedColumns.length,
            selectedColumns.length,
          );
          arrayToReorder.splice(
            result?.destination?.index as number,
            0,
            ...removed,
          );
          const resultArray = makeColumnsFromIds(
            arrayToReorder,
            newVisibleColumns,
          );
          const newHiddenColumns = deleteFromColumns(
            columns.hiddenColumns,
            selectedColumns,
          );
          setColumns({
            hiddenColumns: newHiddenColumns,
            visibleColumns: resultArray,
          });
          return;
        }
        const newVisibleColumns = {
          ...columns.visibleColumns,
          [result.draggableId]: columns.hiddenColumns[result.draggableId],
        };
        const arrayToReorder = Object.keys(newVisibleColumns);
        const [removed] = arrayToReorder.splice(arrayToReorder.length - 1, 1);
        arrayToReorder.splice(result?.destination?.index as number, 0, removed);
        const resultArray = makeColumnsFromIds(
          arrayToReorder,
          newVisibleColumns,
        );
        const newHiddenColumns = { ...columns.hiddenColumns };
        delete newHiddenColumns[result.draggableId];
        setColumns({
          hiddenColumns: newHiddenColumns,
          visibleColumns: resultArray,
        });
      }
      return;
    }
    if (result?.destination?.droppableId === result.source.droppableId) {
      if (result?.destination?.droppableId === 'visibleColumns') {
        if (selectedColumns.length > 1) {
          const allColumnsArray = Object.keys(columns.visibleColumns);
          const prevElemInOriginal = allColumnsArray[result.destination.index];
          const newVisibleColumns = deleteFromColumns(
            columns.visibleColumns,
            selectedColumns,
          );
          const arrayToReorder = Object.keys(newVisibleColumns);
          const prevElIndexInNewArray = arrayToReorder.indexOf(prevElemInOriginal);
          let destinationIndex;
          if (result.destination.index > result.source.index) {
            destinationIndex = prevElIndexInNewArray + 1;
          } else {
            destinationIndex = prevElIndexInNewArray;
          }
          arrayToReorder.splice(destinationIndex, 0, ...selectedColumns);
          const resultArray = makeColumnsFromIds(
            arrayToReorder,
            columns.visibleColumns,
          );
          setColumns({
            ...columns,
            visibleColumns: resultArray,
          });
          return;
        }
        const arrayToReorder = Object.keys(columns.visibleColumns);
        const [removed] = arrayToReorder.splice(result.source.index, 1);
        arrayToReorder.splice(result.destination.index, 0, removed);
        const resultArray = makeColumnsFromIds(
          arrayToReorder,
          columns.visibleColumns,
        );
        setColumns({
          ...columns,
          visibleColumns: resultArray,
        });
      } else {
        if (selectedColumns.length > 1) {
          const allColumnsArray = Object.keys(columns.hiddenColumns);
          const prevElemInOriginal = allColumnsArray[result.destination.index];
          const newHiddenColumns = deleteFromColumns(
            columns.hiddenColumns,
            selectedColumns,
          );
          const arrayToReorder = Object.keys(newHiddenColumns);
          const prevElIndexInNewArray = arrayToReorder.indexOf(prevElemInOriginal);
          let destinationIndex;
          if (result.destination.index > result.source.index) {
            destinationIndex = prevElIndexInNewArray + 1;
          } else {
            destinationIndex = prevElIndexInNewArray;
          }
          arrayToReorder.splice(destinationIndex, 0, ...selectedColumns);
          const resultArray = makeColumnsFromIds(
            arrayToReorder,
            columns.hiddenColumns,
          );
          setColumns({
            ...columns,
            hiddenColumns: resultArray,
          });
          return;
        }
        const arrayToReorder = Object.keys(columns.hiddenColumns);
        const [removed] = arrayToReorder.splice(result.source.index, 1);
        arrayToReorder.splice(result.destination.index, 0, removed);
        const resultArray = makeColumnsFromIds(
          arrayToReorder,
          columns.hiddenColumns,
        );
        setColumns({
          ...columns,
          hiddenColumns: resultArray,
        });
      }
    }
  };

  const onDragStart = (start: DragStart) => {
    const id: string = start.draggableId;
    const selected = selectedColumns.find((x) => x === id);
    if (!selected) {
      unselectAll();
    }
    setDraggingColumnId(start.draggableId);
  };

  const toggleSelection = (columnId: string) => {
    const selColumns = selectedColumns;
    const wasSelected: boolean = selColumns.includes(columnId);
    const newColumnsIds: string[] = (() => {
      if (!wasSelected) {
        return [columnId];
      }
      if (selColumns.length > 1) {
        return [columnId];
      }
      return [];
    })();

    setSelectedColumns(newColumnsIds);
  };

  const toggleSelectionInGroup = (columnId: string) => {
    const selCols: string[] = selectedColumns;
    const index: number = selCols.indexOf(columnId);
    if (index === -1) {
      setSelectedColumns([...selCols, columnId]);
      return;
    }
    const shallow: string[] = [...selCols];
    shallow.splice(index, 1);
    setSelectedColumns(shallow);
  };

  const multiSelectTo = (newColumnId: string) => {
    console.log(newColumnId);
  };

  return (
    <div>
      <Columns
        leftTitle="Terminal Products"
        rightTitle="Group Products"
        columns={columns}
        columnsLabels={columnsLabels}
        draggingColumnId={draggingColumnId}
        handleDragFields={handleDragFields}
        handleSearchChange={handleSearchChange}
        multiSelectTo={multiSelectTo}
        onDragStart={onDragStart}
        searchText={searchText}
        selectedColumns={selectedColumns}
        toggleSelection={toggleSelection}
        toggleSelectionInGroup={toggleSelectionInGroup}
      />
    </div>
  );
};

export default PGCRRecordDetail;
