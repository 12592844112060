import React, { useState } from 'react';

// Librairies
import { Divider, Menu, MenuItem } from '@mui/material';
import Api from '../../redux/api/index';
// Styles
import { useStyles } from './style';
import ConfirmationDialog from '../ConfirmDialog';

interface IProps {
  anchorEl: any;
  onClose: () => void;
  openDriversThroughFacilityOptionsDialog: () => void;
}

const DriversThroughFacilityOptions: React.FC<IProps> = ({
  anchorEl,
  onClose,
  openDriversThroughFacilityOptionsDialog,
}) => {
  const classes = useStyles();
  const [confirmationModal, setConfirmationModal] = useState(false);
  const shutDownOption = () => {
    Api.post(
      'synajax/procajax',
      {
        date: (new Date()).getTime(),
        queueid: 'SHUTDOWN',
        reqid: Date.now().toString(36) + Math.random().toString(36),
        data: {
          cmd: 'dtf',
          dtfaction: 'shutdown',
          parm: null,
        },
        tagid: null,
        jqxhr: null,
      },
    ).then((response: any) => {
      console.log(response);
    }).catch((err: any) => console.log(err));
  };
  const handleDriversThroughFacilityOptionClick = () => {
    openDriversThroughFacilityOptionsDialog();
    onClose();
  };
  const handleCloseConfirmation = () => {
    setConfirmationModal(false);
  };
  const handleYesConfirmation = () => {
    setConfirmationModal(false);
    shutDownOption();
  };
  const confirmationDialogOpen = () => {
    setConfirmationModal(true);
  };

  return (
    <>
      <Menu
        classes={{ root: classes.root, list: classes.list, paper: classes.paper }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={onClose}
      >
        <MenuItem classes={{ root: classes.menuItem }} onClick={handleDriversThroughFacilityOptionClick}>
          DTF Options
        </MenuItem>
        <Divider />
        <MenuItem classes={{ root: classes.menuItem }} onClick={confirmationDialogOpen}>
          Emergency Shut Down
        </MenuItem>
        <Divider />
        <MenuItem classes={{ root: classes.menuItem }}>
          Export Visible Columns (CSV)
        </MenuItem>
        <MenuItem classes={{ root: classes.menuItem }}>
          Export Visible Columns (XLSX)
        </MenuItem>
        <MenuItem classes={{ root: classes.menuItem }}>
          Print To PDF
        </MenuItem>
      </Menu>
      <ConfirmationDialog
        open={confirmationModal}
        handleCloseAgree={handleYesConfirmation}
        message="Are you sure to shut down?"
        handleCloseCancel={handleCloseConfirmation}
        title="Confirmation"
        cancelLabel="No"
        confirmLabel="Yes"
      />
    </>
  );
};

export default DriversThroughFacilityOptions;
