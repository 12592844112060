import React, { useEffect, useState } from 'react';

// Librairies
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Components
import SubHeader from '../../../components/subHeader';
import TmsHeader from '../../../components/header';
import WorkflowTable from '../../../components/WorkflowTable';
import WorkflowTableUtils from '../../../components/WorkflowTableUtils';
import ColumnsDialog from '../../../components/ColumnsDialog';
import { IUserSessionState } from '../../../redux/reducers/workflows/userSession';
import { UserSessionSnackbar } from './userSessionSnackbar';

// Styles

// Actions
import {
  getUserSessionData,
  clearUserSessionNotification,
} from '../../../redux/actions/workflows/userSession';
import { useWorkflowState } from '../../../hooks/useWorkflowState';

// Utils
export const tableHeader = {
  UserId: 'User ID',
  UserName: 'User Name',
  LastLogin: 'Last Login',
  ActiveSessions: 'Active Sessions',
};

const searchHeader = [
  { label: 'User ID', key: 'UserId' },
  { label: 'User Name', key: 'UserName' },
  { label: 'Last Login', key: 'LastLogin' },
  { label: 'Active Sessions', key: 'ActiveSessions' },
];

const UserSessionScreen: React.FC = () => {
  const {
    data, visibleColumns, hiddenColumns, responseNotification,
  } = useSelector<any, IUserSessionState>((state: any) => state.Workflows.UserSession);
  const dispatch = useDispatch();
  const [columnsDialogOpen, setColumnsDialogOpen] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const navigate = useNavigate();
  const {
    recordsToShow,
    tableState,
    handlePaginationChange,
    handleSearchChange,
    handleSelectChange,
    handleSortTable,
    handleCloseMenu,
  } = useWorkflowState(searchHeader, data, getUserSessionData);

  useEffect(() => {
    if (responseNotification.type === 'success') {
      dispatch(getUserSessionData());
      setSnackBarOpen(true);
    }
  }, [responseNotification.type]);

  const handleCloseSnackBar = () => {
    setSnackBarOpen(false);
    dispatch(clearUserSessionNotification());
  };

  const handleOpenColumnsDialog = () => {
    setColumnsDialogOpen(true);
    handleCloseMenu();
  };

  const handleCloseColumnsDialog = () => {
    setColumnsDialogOpen(false);
  };

  const handleSubmitNewColumns = (newColumns: string[]) => {
    // dispatch(updateConditionSetColumns(newColumns));
  };

  const handleRestoreDefault = () => {
    // dispatch(updateConditionSetColumns(Object.keys(tableHeader)));
  };

  const handleClickRow = (row: any) => {
    navigate(`recordDetail/${row.UserId.trim()}`);
  };

  const goToNewRecord = () => {
    // navigate('newRecord');
  };

  return (
    <>
      <TmsHeader />
      <SubHeader title="User Session" />
      <WorkflowTableUtils
        handleSearchChange={handleSearchChange}
        handleSelectChange={handleSelectChange}
        recordsNumber={tableState.recordsNumber}
        searchText={tableState.searchText}
      />
      <WorkflowTable
        handleClickRow={handleClickRow}
        visibleColumns={visibleColumns}
        allDataCount={data.length}
        handlePaginationChange={handlePaginationChange}
        recordsToShow={recordsToShow}
        tableHeader={tableHeader}
        tableState={tableState}
        order={tableState.order}
        orderBy={tableState.orderBy}
        searchText={tableState.searchText}
        sortTable={handleSortTable}
      />
      <ColumnsDialog
        title="Configure Visible Columns"
        onClose={handleCloseColumnsDialog}
        open={columnsDialogOpen}
        visibleColumns={visibleColumns}
        hiddenColumns={hiddenColumns}
        columnsLabels={tableHeader}
        submitNewColumns={handleSubmitNewColumns}
        handleRestoreDefault={handleRestoreDefault}
      />
      <UserSessionSnackbar snackBarOpen={snackBarOpen} onClose={handleCloseSnackBar} />
    </>
  );
};

export default UserSessionScreen;
