import React, { useEffect, useState } from 'react';

// Librairies
import { useDispatch, useSelector } from 'react-redux';

// Components
import { InputBase } from '@mui/material';
import clsx from 'clsx';
import SubHeader from '../../../components/subHeader';
import TmsHeader from '../../../components/header';
import OptionsButton from '../../../components/optionsButton';
import TrafficOptions from '../../../components/TrafficOptions';
import ConfirmationDialog from '../../../components/ConfirmDialog';
import TrafficTable from '../../../components/TrafficTable';

// Styles
import { useStyles } from './style';
import TrafficPriorityDialog from '../../../components/TrafficPriorityDialog';

// Actions
import { getTrafficData } from '../../../redux/actions/workflows/traffic';
import { getScreensList } from '../../../redux/actions/ScreenActions';
import TrafficDialog from '../../../components/TrafficDialog';

const TrafficScreen: React.FC = () => {
  const classes = useStyles();
  const { data, headers } = useSelector(
    (state: any) => state.Workflows.Traffic,
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [suspendConfirmDialogOpen, setSuspendConfirmDialogOpen] = useState<boolean>(false);
  const [unsuspendConfirmDialogOpen, setUnsuspendConfirmDialogOpen] = useState<boolean>(false);
  const [trafficPriorityDialogOpen, setTrafficPriorityDialogOpen] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [trafficDialogOpen, setTrafficDialogOpen] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTrafficData());
    dispatch(getScreensList());
  }, []);

  const handleOptionClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenTrafficDialog = (row: any) => {
    setSelectedRow(row);
    setTrafficDialogOpen(true);
    handleCloseMenu();
  };
  const handleCloseTrafficDialog = () => {
    setTrafficDialogOpen(false);
  };

  const handleOpenSuspendConfirmDialog = () => {
    setSuspendConfirmDialogOpen(true);
    handleCloseMenu();
  };
  const handleCloseSuspendConfirmDialog = () => {
    setSuspendConfirmDialogOpen(false);
  };

  const handleOpenUnsuspendConfirmDialog = () => {
    setUnsuspendConfirmDialogOpen(true);
    handleCloseMenu();
  };
  const handleCloseUnsuspendConfirmDialog = () => {
    setUnsuspendConfirmDialogOpen(false);
  };

  const handleOpenTrafficPriorityDialog = () => {
    setTrafficPriorityDialogOpen(true);
    handleCloseMenu();
  };
  const handleCloseTrafficPriorityDialog = () => {
    setTrafficPriorityDialogOpen(false);
  };

  return (
    <div className={classes.root}>
      <TmsHeader />
      <SubHeader title="Traffic">
        <OptionsButton handleClick={handleOptionClick} />
        <TrafficOptions
          anchorEl={anchorEl}
          onClose={handleCloseMenu}
          openSuspendDialog={handleOpenSuspendConfirmDialog}
          openTrafficPriority={handleOpenTrafficPriorityDialog}
          openUnsuspendDialog={handleOpenUnsuspendConfirmDialog}
        />
      </SubHeader>
      <div className={clsx('flexRow', classes.wrapper)}>
        <span className="takeTheRest" />
        <div>
          <InputBase
            className={classes.input}
            placeholder="Search"
            value={searchText}
            onChange={handleSearchChange}
          />
          <span className={classes.helperText}>Viewing 0 of 0</span>
        </div>
      </div>
      <TrafficTable data={data} handleClickRow={handleOpenTrafficDialog} searchText={searchText} />
      <ConfirmationDialog
        open={suspendConfirmDialogOpen}
        handleCloseAgree={handleCloseSuspendConfirmDialog}
        message="Are you sure to suspend the traffic?"
        handleCloseCancel={handleCloseSuspendConfirmDialog}
        title="Suspend Traffic"
        cancelLabel="Cancel"
        confirmLabel="Yes"
      />
      <ConfirmationDialog
        open={unsuspendConfirmDialogOpen}
        handleCloseAgree={handleCloseUnsuspendConfirmDialog}
        message="Are you sure to unsuspend the traffic?"
        handleCloseCancel={handleCloseUnsuspendConfirmDialog}
        title="Unsuspend Traffic"
        cancelLabel="Cancel"
        confirmLabel="Yes"
      />
      <TrafficPriorityDialog
        onClose={handleCloseTrafficPriorityDialog}
        open={trafficPriorityDialogOpen}
      />
      <TrafficDialog
        onClose={handleCloseTrafficDialog}
        open={trafficDialogOpen}
        selectedRow={selectedRow}
      />
    </div>
  );
};

export default TrafficScreen;
