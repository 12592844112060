import React from 'react';

// Librairies
import { Divider, Menu, MenuItem } from '@mui/material';

// Styles
import { useStyles } from './style';

interface IProps {
  anchorEl: any;
  onClose: () => void;
  openDriversInFacilityOptionsDialog: () => void;
}

const DriversInFacilityOptions: React.FC<IProps> = ({
  anchorEl,
  onClose,
  openDriversInFacilityOptionsDialog,
}) => {
  const classes = useStyles();

  const handleDriversInFacilityOptionClick = () => {
    openDriversInFacilityOptionsDialog();
    onClose();
  };

  return (
    <Menu
      classes={{ root: classes.root, list: classes.list, paper: classes.paper }}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
    >
      <MenuItem classes={{ root: classes.menuItem }} onClick={handleDriversInFacilityOptionClick}>
        Driver In Facility Options
      </MenuItem>
      <Divider />
      <MenuItem classes={{ root: classes.menuItem }}>
        Export Visible Columns (CSV)
      </MenuItem>
      <MenuItem classes={{ root: classes.menuItem }}>
        Export Visible Columns (XLSX)
      </MenuItem>
      <MenuItem classes={{ root: classes.menuItem }}>
        Print To PDF
      </MenuItem>
    </Menu>
  );
};

export default DriversInFacilityOptions;
