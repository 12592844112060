import {
  LOAD_DRIVERS_THROUGH_FACILITY,
  CLEAR_DRIVERS_THROUGH_FACILITY,
  LOAD_DRIVERS_THROUGH_FACILITY_OPTION,
  GET_DRIVERS_DTF,
  GET_CARRIERS_DTF,
} from '../../constants/workflows';

const INITIAL_STATE = {
  dtfList: [],
  dtfStatusInfo: [],
  bayInfoList: [],
  parkingInfoList: [],
  dtfbay: [],
  dtfdispatch: [],
  dtfroutecode: [],
  dtfdrivers: [],
  dtfcarriers: [],
};

const driversThroughFacilityReducer = (
  state = INITIAL_STATE,
  { type, payload }: any,
) => {
  switch (type) {
    case LOAD_DRIVERS_THROUGH_FACILITY: {
      return {
        dtfList: payload.dtfList,
        dtfStatusInfo: payload.dtfStatusInfo,
        bayInfoList: payload.bayInfoList,
        parkingInfoList: payload.parkingInfoList,
      };
    }
    case LOAD_DRIVERS_THROUGH_FACILITY_OPTION: {
      return {
        ...state,
        dtfbay: payload.bay,
        dtfdispatch: payload.dispatch,
        dtfroutecode: payload.route_cd,
      };
    }
    case GET_DRIVERS_DTF: {
      return {
        ...state,
        dtfdrivers: payload,
      };
    }
    case GET_CARRIERS_DTF: {
      return {
        ...state,
        dtfcarriers: payload,
      };
    }
    case CLEAR_DRIVERS_THROUGH_FACILITY: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
};

export default driversThroughFacilityReducer;
