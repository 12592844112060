/* eslint-disable no-param-reassign */
import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import TmsHeader from '../../../components/header';
import VerticalLinearStepper from '../../../components/verticalStepper/verticalStepper';
import {
  GetTransactionSchema,
  getScreensList,
} from '../../../redux/actions/ScreenActions';
import { useStyles } from './style';
import contractTransactionStructure from './stepperStructure.json';
import currentSchema from './currentStepDetails.json';
import { TransactionTemplate } from '../../../components/transactionTemplate';
import NonBulkTransactionMenu from './nonBulkTransactionMenu';

const formatProductListObject = (obj: any) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null) {
      obj[key] = '';
    }
  });
  return obj;
};
const NonBulkTransaction = () => {
  const classes = useStyles();
  const [values, setValues] = useState<any>({ term_id: { value: 'B344', label: 'B344: TOPTECH (LONGWOOD, FL)' } });
  const [openTemplate, setopenTemplate] = useState(false);
  const [openSubmit, setopenSubmit] = useState(false);
  const [valuesToSend, setValuesToSend] = useState<any>({ term_id: 'B344' });
  const [templateList, settemplateList] = useState([]);
  const [ProductsToSend, setProductsToSend] = useState<any>([]);
  const [templateValue, settemplateValue] = useState('');
  const [contractidOptions, setcontractidOptions] = useState([]);
  const [contractMode, setcontractMode] = useState('');
  const [visible, setVisible] = useState(false);
  const [prefixProducts, setprefixProducts] = useState<any>({});
  const [prefix, setprefix] = useState('');
  const [products, setProducts] = useState<any>([]);
  const { loading, stepperData } = useSelector(
    (state: any) => state.ScreenReducer,
  );
  const addProducts = () => {
    setProducts([...products, { id: uuidv4() }]);
  };
  const loadStepProducts = (item: any) => {
    let productstest: any = [];
    const prodid = uuidv4();
    productstest = [...productstest, { id: prodid, [prodid]: item }];
    setProducts([...products, { id: prodid, item }]);
    return { id: prodid, [prodid]: item };
  };
  const handleTemplate = (val: any) => {
    settemplateValue(val?.newValue?.label || val?.newValue);
  };
  const removeFormFields = (i: any) => {
    const deleteProd: any = [...products];
    delete prefixProducts?.[i];
    setProducts(deleteProd.filter((obj: any) => obj.id !== i));
  };
  const onClose = () => {
    setopenTemplate(false);
  };
  const onCloseSubmit = () => {
    setopenSubmit(false);
  };
  const onOpen = () => {
    setopenTemplate(true);
  };
  const onOpenSubmit = () => {
    setopenSubmit(true);
  };
  const startNewTransaction = () => {
    setValuesToSend({});
    setValues({});
    setprefix('');
    setProducts([]);
    setprefixProducts({});
  };
  // transaction template section
  const LoadContractTransaction = (templateSteps: any) => {
    const loadedthreeStepsvalues = templateSteps?.other_steps
      .flat(1)
      .map((item: any) => ({ key: [item.id], value: item.value || '' }))
      .reduce(
        (obj:any, item: any) => Object.assign(obj, {
          [item.key]: item.value?.search('-') > 0
            ? {
              value: item.value?.split('-')?.[0]?.replace(/\s+/g, ''),
              label: `${item.value?.split('-')?.[0]} - ${item.value?.split('-')?.[1]}`,
            } : item.value?.split('-')?.[0],
        }),
        {},
      );
    const loadedthreeStepsvaluesToSend = templateSteps?.other_steps
      .flat(1)
      .map((item: any) => ({ key: [item.id], value: item.value || '' }))
      .reduce(
        (obj:any, item: any) => Object.assign(obj, {
          [item.key]: item.value?.split('-')?.[0]?.replace(/\s+/g, ''),
        }),
        {},
      );
    setValuesToSend(loadedthreeStepsvaluesToSend);
    setValues(loadedthreeStepsvalues);
    const loadedProductStepProd = templateSteps?.loadValues?.productdetail
      .map((val: any, key: any) => loadStepProducts(val))
      .reduce(
        (obj:any, item:any) => Object.assign(obj, { [item.id]: item[item.id] }),
        {},
      );
  };
  const resetPrefixProducts = (option: any, key: any) => {
    const data: any = prefixProducts;
    data[key] = option;
    setprefixProducts(data);
  };
  const getTransactionTemplate = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}manualtrans/showstep?step=S4&screenID=WORKFLOW-041&manualTransferMode=nonbulk`,
      );
      console.log('non bulk transaction', response.data);
      settemplateList(response.data.templateList);
    } catch (err) {
      console.log('error', err);
    }
  };
  const getTransactionTemplateByValue = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}manualtrans/showstep?step=S4&screenID=WORKFLOW-041&manualTransferMode=nonbulk&action=load&tname=${templateValue}`,
      );
      LoadContractTransaction(response.data);
    } catch (err) {
      console.log('error', err);
    }
  };
  const deleteContractTransactionTemplate = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}manualtrans/deletetemp?action=remove&mtmode=nonbulk&tname=${templateValue}&screenID=WORKFLOW-041`,
      );
    } catch (err) {
      console.log('error', err);
    }
  };
  const saveContractTransactionTemplate = async () => {
    try {
      const response: any = await axios.post(
        `${process.env.REACT_APP_API_URL}manualtrans/submit`,
        {
          manualTransferMode: 'contract',
          userID: 'tms',
          term_id: 'B344 ',
          route_cd: valuesToSend?.route_cd,
          uom: valuesToSend?.uom,
          action: 'save',
          tname: templateValue,
          screenID: 'WORKFLOW-041',
          driver: valuesToSend?.driver,
          carrier: valuesToSend?.carrier,
          shippingmethodid: valuesToSend?.shippingmethodid,
          truck: valuesToSend?.truck,
          trailer1: valuesToSend?.trailer1,
          trailer2: valuesToSend?.trailer2,
          supplier_no: valuesToSend?.supplier_no,
          cust_no: valuesToSend?.cust_no,
          acct_no: valuesToSend?.acct_no,
          destination_no: valuesToSend?.destination_no,
          dest_splc_code: valuesToSend?.dest_splc_code,
          trans_id: valuesToSend?.trans_id,
          reason: valuesToSend?.trans_id,
          dutytoparty: valuesToSend?.dutytoparty,
          doc_no: valuesToSend?.doc_no,
          folio_mo: valuesToSend?.folio_mo,
          folio_no: valuesToSend?.folio_no,
          loadstartdatetime: valuesToSend?.loadstartdatetime,
          loadstopdatetime: valuesToSend?.loadstopdatetime,
          entrydatetime: valuesToSend?.entrydatetime,
          exitdatetime: valuesToSend?.exitdatetime,
          thirdpartyref: valuesToSend?.thirdpartyref,
          po_num: valuesToSend?.po_num,
          host_po_num: valuesToSend?.host_po_num,
          release_no: valuesToSend?.release_no,
          comment1: valuesToSend?.comment1,
          comment2: valuesToSend?.comment2,
          ticket_no: false,
          batch_no: valuesToSend?.batch_no,
          seal1: valuesToSend?.seal1,
          seal2: valuesToSend?.seal2,
          productdetail: Object.values(ProductsToSend),
        },
      );
    } catch (e: any) {
      console.log('error', e);
    }
  };

  const getSupCustAccDestForContract = async (contract:any) => {
    try {
      if (valuesToSend?.contractid !== '') {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}tablescreen/tablemaintdata/?tableID=Contract%20%20%20%20%20%20&page=1&results_per_page=15%20%20%20%20&fields=ContractNumber,Description,SupplierNo:SupplierNo=supplier_no:Supplier.supplier_name,CustNo:CustNo=cust_no:Customer.cust_name,EffectiveDate&where=ContractNumber=${contract?.value}&search=&order_by=&lang=null`,
        );
        const ContractItemResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}tablescreen/tablemaintdata/?tableID=ContractItem%20%20%20%20%20%20&page=1&results_per_page=15%20%20%20%20&fields=ContractItemNumber&where=ContractNumber=${contract?.value}&search=&order_by=&lang=null`,
        );
        setValuesToSend({
          ...valuesToSend,
          contractid: contract,
          cust_no:
            response.data?.data?.[0]?.[
              'CustNo:CustNo=cust_no:Customer.cust_name'
            ]?.value,
          supplier_no:
            response.data?.data?.[0]?.[
              'SupplierNo:SupplierNo=supplier_no:Supplier.supplier_name'
            ]?.value,
          ContractItemNumber:
            ContractItemResponse.data?.data?.[0]?.ContractItemNumber,
          acct_no: '',
        });
        setValues({
          ...values,
          contractid: contract,
          cust_no:
            response.data?.data?.[0]?.[
              'CustNo:CustNo=cust_no:Customer.cust_name'
            ],
          supplier_no:
            response.data?.data?.[0]?.[
              'SupplierNo:SupplierNo=supplier_no:Supplier.supplier_name'
            ],
          ContractItemNumber:
            ContractItemResponse.data?.data?.[0]?.ContractItemNumber,
          acct_no: '',
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  // get Folio month and Folio number default values
  const getFoliocurrentData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}tablescreen/tablemaintdata/?tableID=FolioStatus%20%20%20%20%20%20&page=1&results_per_page=15%20%20%20%20&fields=fol_mo,open_date,fol_no&where=&search=&order_by=&lang=null`,
      );
      setValuesToSend({ ...valuesToSend, folio_mo: response.data?.data?.[0]?.fol_mo?.value, folio_no: response.data?.data?.[0]?.fol_no?.value });
      setValues({
        ...values,
        folio_mo: {
          value: response.data?.data?.[0]?.fol_mo?.value,
          label: `${response.data?.data?.[0]?.fol_mo?.value} : ${new Date(response.data?.data?.[0]?.open_date?.label?.trim())?.toLocaleString('default', {
            month: 'short',
            year: 'numeric',
          })}`,
        },
        folio_no: { value: response.data?.data?.[0]?.open_date?.value, label: response.data?.data?.[0]?.tt_status?.value },
      });
    } catch (error) {
      console.log('err', error);
    }
  };
  const getContractIdOptionsByContractMode = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}manualtrans/showstep?step=S2&screenID=WORKFLOW-119&manualTransferMode=contract&contractmode=${valuesToSend.contractmode}`,
      );
      const contractIdOptions: any = Object.entries(response.data.current_step.contractChain[1].list).map(
        (item: any) => ({
          value: item[0],
          label: item[1]?.replaceAll('&nbsp;', ''),
        }),
      );
      setcontractidOptions(contractIdOptions);
    } catch (err:any) {
      console.log('error:', err);
    }
  };
  console.log('values', valuesToSend, values);
  React.useEffect(() => {
    getContractIdOptionsByContractMode();
  }, [values.contractmode]);
  // useEffect(() => {
  //   getSupCustAccDestForContract();
  // }, [valuesToSend?.contractid]);
  const changeValueSubProducts = async (key: any) => {
    try {
      const response: any = await axios.post(
        `${process.env.REACT_APP_API_URL}synajax/procajax`,
        {
          queueid: 'TP',
          data: {
            cmd: 'proddetailchange',
            changeid: 'temperature',
            numcomponents: '1',
            termid: valuesToSend?.term_id || 'B344',
            carrier: valuesToSend?.carrier || '',
            supplier: valuesToSend?.supplier_no || '',
            customer: valuesToSend?.cust_no || '',
            account: valuesToSend?.acct_no || '',
            destination: valuesToSend?.destination || '',
            trans_id: valuesToSend?.trans_id?.value || '',
            uom: 'B',
            baseUOMList: 'B:G,C:L,G:G,I:L,K:K,L:L,MT:K,P:P,T:P',
            contractmode: valuesToSend?.contractmode || '',
            contractid: valuesToSend?.contractmode || '',
            prefix: prefix || '',
            newdetail: valuesToSend?.newdetail || 'Y',
            prodfieldlist: formatProductListObject(prefixProducts?.[key]),
            vehlist: [],
            tankgroup: valuesToSend?.tankgroup || 'y',
            mtmode: 'bulk',
            bayid: valuesToSend?.[key]?.bay_id || '',
            prodid: valuesToSend?.[key]?.prod_id || '',
            weightTransaction: false,
            groupID: 'Admin',
          },
          tagid: null,
          jqxhr: null,
        },
      );
      if (prefix) {
        resetPrefixProducts(response.data?.[0]?.proddetail, key);
      }

      // setSubProducts(response.data.);
    } catch (e: any) {
      console.log('error', e);
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    key: any,
    subKey: string,
    pref: any,
    fullNamePrefix: string,
  ) => {
    const { name, value } = e.target;
    const newData = {
      ...prefixProducts,
      [key]: {
        ...prefixProducts[key],
        [fullNamePrefix]: value,
        prefix: pref,
      },
    };
    if (key) {
      if (subKey) {
        setValuesToSend({
          ...valuesToSend,
          [key]: {
            ...valuesToSend[key],
            component: {
              ...valuesToSend[key]?.component,
              [subKey]: {
                ...valuesToSend[key]?.component?.[subKey],
                [name]: value,
                prefix: pref,
                contractid: valuesToSend?.contractid,
                ContractItem: valuesToSend?.ContractItemNumber?.value,
              },
            },
          },
        });
      } else {
        setValuesToSend({
          ...valuesToSend,
          [key]: {
            ...valuesToSend[key],
            [name]: value,
            prefix: pref,
            contractid: valuesToSend?.contractid,
            ContractItem: valuesToSend?.ContractItemNumber?.value,
          },
        });
      }
      setProductsToSend({ ...ProductsToSend, [key]: valuesToSend[key] });
      setprefixProducts(newData);
      setprefix(pref);
      setTimeout(() => {
        changeValueSubProducts(key);
      }, 2000);
    } else {
      const inputedData = {
        ...valuesToSend,
        [name]: value,
      };
      setValuesToSend(inputedData);
    }
  };
  const handleSelectChange = (
    label: any,
    e: React.ChangeEvent<HTMLInputElement>,
    newValue: any,
    key: any,
  ) => {
    setValues({
      ...values,
      [key]: {
        ...[key],
        [label]: newValue.value,
        prefix: label?.slice(0, label?.indexOf('-')),
      },
    });
    setValuesToSend({
      ...valuesToSend,
      [key]: {
        ...[key],
        [label]: newValue.value,
        prefix: label?.slice(0, label?.indexOf('-')),
      },
    });
    if (label?.search('SPR_') > 0) {
      setprefixProducts({
        ...prefixProducts,
        [key]: {
          ...[key],
          [label]: newValue.value,
          prefix: label?.slice(0, label?.indexOf('-')),
        },
      });
    }
  };
  const handleRecordSelectChange = (
    label: any,
    e: React.ChangeEvent<HTMLInputElement>,
    newValue: any,
    key: any,
  ) => {
    if (label === 'contractid') {
      getSupCustAccDestForContract(newValue);
    } else if (label === 'supplier_no') {
      setValuesToSend({
        ...valuesToSend,
        [label?.name || label]: newValue?.value || label?.newValue,
        cust_no: '',
        acct_no: '',
        destination_no: '',
      });
      setValues({
        ...values,
        [label?.name || label]: newValue || label?.newValue,
        cust_no: '',
        acct_no: '',
        destination_no: '',
      });
    } else {
      setValues({
        ...values,
        [label?.name || label]: newValue || label?.newValue,
      });
      setValuesToSend({
        ...valuesToSend,
        [label?.name || label]: newValue?.value || label?.newValue,
      });
    }
  };
  // handle input for other steps
  const handleOtherStepsChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, value } = e.target;
    setValuesToSend({
      ...valuesToSend,
      [name]: value,
    });
  };
  // change values on sub products
  const handleInputProductChange = (
    name: any,
    e: React.ChangeEvent<HTMLInputElement>,
    key: any,
  ) => {
    const { value } = e.target;
    const newData = {
      ...prefixProducts,
      [key]: {
        ...prefixProducts[key],
        [name]: value,
        prefix: name?.slice(0, name?.indexOf('-')),
      },
    };
    setValues({
      ...values,
      [key]: {
        ...values[key],
        [name]: value,
        prefix: name?.slice(0, name?.indexOf('-')),
        contractid: values?.contractid?.value,
        ContractItem: values?.ContractItemNumber?.value,
      },
    });
    setValuesToSend({
      ...valuesToSend,
      [key]: {
        ...valuesToSend[key],
        [name]: value,
        prefix: name?.slice(0, name?.indexOf('-')),
        contractid: values?.contractid?.value,
        ContractItem: values?.ContractItemNumber?.value,
      },
    });
    if (name?.search('SPR_') > 0) {
      setprefixProducts({
        ...prefixProducts,
        [key]: {
          ...[key],
          [name]: value,
          prefix: name?.slice(0, name?.indexOf('-')),
        },
      });
      setprefix(name?.slice(0, name?.indexOf('-')));
      changeValueSubProducts(key);
    }
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getScreensList());
    getTransactionTemplate();
    dispatch(
      GetTransactionSchema({
        screenId: 'workflow041',
        tableId: 'nonbulk',
        screenIdTable: 'WORKFLOW-041',
        manualTransferMode: 'nonbulk',
        step: 2,
      }),
    );
    getFoliocurrentData();
  }, []);
  return loading ? (
    <div
      style={{
        width: '100%',
        height: '90vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress />
    </div>
  ) : (
    <>
      <TmsHeader />
      <div className="sub-nav-container">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h3 id="pagetitlebar_title" className="page-title">
            Non-Bulk Transaction
          </h3>
        </div>
        <NonBulkTransactionMenu
          onOpen={onOpen}
          setVisible={setVisible}
          visible={visible}
          visibleCount={[{ step: 1, count: 7 }, { step: 2, count: 7 }, { step: 3, count: 7 }, { step: 4, count: 17 }]}
        />
      </div>
      <VerticalLinearStepper
        data={contractTransactionStructure}
        onOpenSubmit={onOpenSubmit}
        values={values}
        valuesToSend={valuesToSend}
        handleSelectChange={handleSelectChange}
        handleInputChange={handleInputChange}
        handleInputProductChange={handleInputProductChange}
        handleOtherStepsChange={handleOtherStepsChange}
        isBulkTransaction
        visible={visible}
        currentSchema={currentSchema}
        stepperTitles={[
          'Transport Information',
          'Entity / Transaction Information',
          'Date / Type / Comment',
          'Products',
        ]}
        screenID="WORKFLOW-041"
        prefixProducts={prefixProducts}
        resetPrefixProducts={resetPrefixProducts}
        prefix={prefix}
        handleRecordSelectChange={handleRecordSelectChange}
        products={products}
        addProducts={addProducts}
        removeFormFields={removeFormFields}
        ProductStepReviewState={[
          { id: 'prod_id', label: 'Product ID' },
          { id: 'product', label: 'Product Name' },
          { id: 'gross', label: 'Gross' },
          { id: 'net', label: 'Net' },
          { id: 'temperature', label: 'Temperature' },
          { id: 'gravity', label: 'Gravity / Density' },
          { id: 'vehicle', label: 'Vehicle' },
          { id: 'compid', label: 'Compartment' },
        ]}
        onCloseSubmit={onCloseSubmit}
        openSubmit={openSubmit}
        startNewTransaction={startNewTransaction}
        contractidOptions={contractidOptions}
        transactionMode="nonbulk"
      />
      <TransactionTemplate
        onClose={onClose}
        open={openTemplate}
        templateList={templateList}
        handleTemplate={handleTemplate}
        templateValue={templateValue}
        getTransactionTemplate={getTransactionTemplateByValue}
        deleteContractTransactionTemplate={deleteContractTransactionTemplate}
        saveContractTransactionTemplate={saveContractTransactionTemplate}
      />
    </>
  );
};

export default NonBulkTransaction;
