/* eslint-disable react/no-array-index-key */
/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
import React from 'react';

// Librairies
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { ArrowDropDown, ArrowDropUp, UnfoldMore } from '@mui/icons-material';
import clsx from 'clsx';

// Styles
import { useStyles } from './style';

// components
import Pagination from '../Pagination';
import TableItemsText from '../TableItemsText';

// Utils
const tableHeader = {
  fol_mo: 'Folio Month',
  fol_no: 'Folio Number',
  open_date: 'Open Date',
  freeze_date: 'Freeze Date',
  tt_status: 'Folio Status',
  no_of_gauges: 'Number of Gauges',
  'term_id:TerminalProfile.name': 'Terminal ID - Name',
};

type column =
  | 'fol_mo'
  | 'fol_no'
  | 'open_date'
  | 'freeze_date'
  | 'tt_status'
  | 'no_of_gauges'
  | 'term_id:TerminalProfile.name';

interface IProps {
  handleClickRow: (row: any) => void;
  allDataCount: any;
  searchText?: string;
  recordsToShow: number;
  visibleColumns?: any;
  tableState: any;
  handlePaginationChange: (event: any, targetPage: any) => void;
  sortTable?: (key: string) => void;
  orderBy?: string;
  order?: string;
}

const TankGaugeEntryTable: React.FC<IProps> = ({
  handleClickRow,
  allDataCount,
  searchText,
  recordsToShow,
  visibleColumns,
  tableState,
  handlePaginationChange,
  sortTable,
  orderBy,
  order,
}) => {
  const classes = useStyles();
  const { currentPage, searchedDataCount, tableData } = tableState;
  const countPages = Math.ceil(
    (searchText ? searchedDataCount : allDataCount) / recordsToShow,
  );

  const handleSortClick = (key: string) => (event: React.MouseEvent<unknown>) => {
    if (sortTable) {
      sortTable(key);
    }
  };

  return (
    <div className={classes.root}>
      <Table classes={{ root: classes.tableRoot }}>
        <TableHead>
          <TableRow classes={{ root: classes.headerRow }}>
            {Object.keys(visibleColumns).map((h) => (
              <TableCell classes={{ root: classes.headerCell }} key={h}>
                <TableSortLabel
                  active={orderBy === h}
                  onClick={handleSortClick(h)}
                  IconComponent={() => (order === 'asc' && orderBy === h ? (
                    <ArrowDropUp />
                  ) : order === 'desc' && orderBy === h ? (
                    <ArrowDropDown />
                  ) : (
                    <UnfoldMore />
                  ))}
                  classes={{ root: classes.sortLabel }}
                >
                  {tableHeader[h as column]}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData?.map((row: any, index: number) => (
            <TableRow
              onClick={() => handleClickRow(row)}
              className={classes.tableRow}
              key={index}
            >
              {Object.keys(visibleColumns).map((field) => (
                <TableCell classes={{ root: classes.bodyCell }} key={field}>
                  {row[field] || row.term_id}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className={clsx(classes.footerWrapper, 'flexRowAlignCenter')}>
        <TableItemsText
          allDataCount={allDataCount}
          countPages={countPages}
          currentPage={currentPage}
          recordsToShow={recordsToShow}
          searchText={searchText}
          searchedDataCount={searchedDataCount}
        />
        <span className="takeTheRest" />
        <Pagination
          count={countPages}
          onChange={handlePaginationChange}
          pageNumber={currentPage}
        />
      </div>
    </div>
  );
};

export default React.memo(TankGaugeEntryTable);
