import React from 'react';

// Librairies
import { ButtonBase } from '@mui/material';

// Components
import Select from '../Select';

// Styles
import { useStyles } from './style';

const Documents = () => {
  const classes = useStyles();
  return (
    <div>
      <div className="flexRow">
        <span className="takeTheRest" />
        <div className="flexRowAlignCenter">
          <div className={classes.label}>Route Code</div>
          <Select options={[]} onChange={() => null} required />
          <ButtonBase className={classes.regenerateButton}> Regenerate </ButtonBase>
        </div>
      </div>
      <div style={{ marginTop: '5vh' }} />
      <div>
        <h3 style={{ marginLeft: '40%' }}>
          <br />
          No Documents Found
        </h3>
      </div>
    </div>
  );
};

export default Documents;
