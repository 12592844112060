import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components
import { RcuCard } from '../../../components/RcuCard/RcuCard.component';
import SubHeader from '../../../components/subHeader';
import TmsHeader from '../../../components/header';
import { useLogger } from '../../../hooks/useLogger';
import { RcuKeyPressedHandler, useRcuKeyboard } from '../../../hooks/useRcuKeyboard';
import { getInitialData } from '../../../redux/actions/workflows/rcuDitto';

import { RcuDittoState } from '../../../redux/reducers/workflows/rcuDitto';

// Styles
import { useStyles } from './style';

const RCUDittoScreen: React.FC = () => {
  const classes = useStyles();
  const { log } = useLogger('RCUDittoScreen');
  const { RCUBayList } = useSelector<any, RcuDittoState>((state) => state.Workflows.RcuDitto);
  const dispatch = useDispatch();
  useEffect(() => {
    log('onLoad');
    dispatch(getInitialData());
  }, []);
  const bayId : RcuKeyPressedHandler = (e) => {
    log('onKeyPressed', e);
  };
  const rcuKeyboardHook = useRcuKeyboard(bayId);

  log('render', RCUBayList);
  return (
    <div className={classes.root}>
      <TmsHeader />
      <SubHeader title="RCU Ditto" />
      <Grid container>
        {
          RCUBayList.map((bay, index) => (
            <Grid key={bay.id} item lg={2.4} xs={6}>
              <RcuCard
                index={index}
                bodyContent={bay.screenData}
                bayId={bay.id}
                bayName={bay.name}
                rcuKeyboardHook={rcuKeyboardHook}
              />
            </Grid>
          ))
        }
      </Grid>
    </div>
  );
};

export default RCUDittoScreen;
