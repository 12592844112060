import React, { useEffect, useState } from 'react';

// Librairies
import { useDispatch, useSelector } from 'react-redux';

// Components
import TmsHeader from '../../../components/header';
import SubHeader from '../../../components/subHeader';
import DeviceCommunicationsOptions from '../../../components/DeviceCommunicationsOptions';
import DeviceCommunicationsDialog from '../../../components/deviceCommunicationsDialog';
import OptionsButton from '../../../components/optionsButton';
import DeviceCommunicationsTable from '../../../components/deviceCommunicationsTable';

// Styles
import { useStyles } from './Style';

// Actions
import { getDeviceCommunications } from '../../../redux/actions/workflows/deviceCommunications';
import { getScreensList } from '../../../redux/actions/ScreenActions';

const SystemStartupOptions: React.FC = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const { data, modalList, modalListResponse } = useSelector(
    (state: any) => state?.Workflows?.DeviceCommunications,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDeviceCommunications());
    dispatch(getScreensList());
  }, []);

  const handleOptionClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenDialog = (row: any) => {
    setSelectedRow(row);
    setDialogOpen(true);
    handleCloseMenu();
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  return (
    <div className={classes.root}>
      <TmsHeader />
      <SubHeader title="Device Communications">
        <OptionsButton handleClick={handleOptionClick} />
        <DeviceCommunicationsOptions
          openDialog={handleOpenDialog}
          anchorEl={anchorEl}
          onClose={handleCloseMenu}
        />
      </SubHeader>
      <DeviceCommunicationsTable handleClickRow={handleOpenDialog} data={data} />
      <DeviceCommunicationsDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        modalList={modalList}
        selectedRow={selectedRow}
      />
    </div>
  );
};

export default SystemStartupOptions;
