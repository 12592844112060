import {
  LOAD_PERSONNEL_IN_FACILITY,
  CLEAR_PERSONNEL_IN_FACILITY,
} from '../../constants/workflows';

const INITIAL_STATE = {
  data: [],
};

const personnelInFacilityReducer = (
  state = INITIAL_STATE,
  { type, payload }: any,
) => {
  switch (type) {
    case LOAD_PERSONNEL_IN_FACILITY: {
      return {
        ...state,
        data: payload.data,
      };
    }
    case CLEAR_PERSONNEL_IN_FACILITY: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
};

export default personnelInFacilityReducer;
