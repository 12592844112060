import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import { useStyles } from '../../../components/ConditionSetOptions/style';
import OptionsButton from '../../../components/optionsButton/index';

interface IProps {
  onReleaseNotesSelected: VoidFunction;
}

export const AboutOptionsMenu: React.FC<IProps> = ({ onReleaseNotesSelected }) => {
  const classes = useStyles();
  const [anchorElement, setAnchorElement] = React.useState<null | HTMLElement>(null);
  const handleOptionOpen: React.MouseEventHandler<HTMLElement> = (event) => {
    setAnchorElement(event.currentTarget);
  };
  const handleOptionsClose: VoidFunction = () => {
    setAnchorElement(null);
  };
  const handleReleaseNotesClick = () => {
    handleOptionsClose();
    onReleaseNotesSelected();
  };

  const isOpen = Boolean(anchorElement);
  return (
    <>
      <OptionsButton handleClick={handleOptionOpen} />
      <Menu
        id="about-options-menu"
        open={isOpen}
        onClose={handleOptionsClose}
        anchorEl={anchorElement}
      >
        <MenuItem
          classes={{ root: classes.menuItem }}
          onClick={handleReleaseNotesClick}
        >
          Release Notes
        </MenuItem>
      </Menu>
    </>
  );
};
