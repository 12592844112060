import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    overflowX: 'scroll',
  },
  tableRoot: {
    minWidth: 2000,
  },
  headerCell: {
    borderBottom: 'none !important',
    padding: '5px 9px !important',
    fontWeight: 'bold !important',
    border: '1px solid #fff',
    backgroundColor: '#fff !important',
    color: '#222 !important',
    whiteSpace: 'nowrap',
    fontSize: '90% !important',
  },
  bodyCell: {
    borderBottom: 'none !important',
    padding: '5px 9px !important',
    whiteSpace: 'nowrap',
  },
  headerRow: {
    boxShadow: '0 4px 6px -3px #555',
  },
  bodyCellEmpty: {
    borderBottom: 'none !important',
    padding: '5px 9px !important',
    width: 30,
  },
});
