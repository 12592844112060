import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  closeButton: {
    borderWidth: '1px 1px 1px 5px !important',
    borderColor: '#ffb848 !important',
    color: 'black !important',
    textShadow: 'none !important',
    backgroundColor: '#ddd !important',
    border: '1px solid transparent !important',
    padding: '4px 10px !important',
    '&:hover': {
      color: '#fff !important',
      backgroundColor: '#545454 !important',
    },
  },
  saveButton: {
    verticalAlign: 'middle',
    textAlign: 'center',
    backgroundColor: '#00C851 !important',
    color: 'black !important',
    padding: '4px 10px !important',
    border: '1px solid transparent',
    marginLeft: '5px !important',
    '&:hover': {
      backgroundColor: '#007E33 !important',
      color: 'white !important',
    },
    '&:disabled': {
      color: '#555 !important',
      backgroundColor: '#bcbcbc !important',
      border: '1px solid transparent !important',
      cursor: 'not-allowed',
    },
  },
  cancleButton: {
    color: 'black !important',
    textShadow: 'none !important',
    backgroundColor: '#ffbb33 !important',
    padding: '4px 10px !important',
    marginLeft: '10px !important',
    border: '1px solid transparent',
    '&:hover': {
      backgroundColor: '#FF8800 !important',
      color: 'white !important',
    },
  },
  removeButton: {
    color: 'black !important',
    textShadow: 'none !important',
    backgroundColor: '#ff4444 !important',
    padding: '4px 10px !important',
    marginLeft: '10px !important',
    border: '1px solid transparent',
    '&:hover': {
      backgroundColor: '#CC0000 !important',
      color: 'white !important',
    },
  },
  divider: {
    marginTop: '10px !important',
    marginBottom: '5px !important',
  },
});
