import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DocumentBuilderTableMenu from '../../../components/documentBuilderTableMenu';
import TmsHeader from '../../../components/header';
import SubHeader from '../../../components/subHeader';
import OptionsButton from '../../../components/optionsButton';
import WorkflowTable from '../../../components/WorkflowTable';
import WorkflowTableUtils from '../../../components/WorkflowTableUtils';
import { useWorkflowState } from '../../../hooks/useWorkflowState';
import { getScreensList } from '../../../redux/actions/ScreenActions';
import { getDocumentBuilderTableData } from '../../../redux/actions/workflows/documentBuilder';

const tableHeader = {
  DocName: 'Document',
  DocType: 'Doc Type',
  DocVersion: 'Document Version',
  ServerName: 'Server Name',
  RouteCode: 'Route Code',
};

const searchHeader = [
  // { label: 'DOCUMENT_BUILDER_TERMINAL_ID', key: 'DOCUMENT_BUILDER_TERMINAL_ID' },
  { label: 'Document', key: 'DocName' },
  { label: 'Doc Type', key: 'doc_type' },
  { label: 'Document Version', key: 'doc_version' },
  { label: 'Server Name', key: 'server_name' },
  { label: 'Route Code', key: 'route_code' },
];

const DocumentBuilder = () => {
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getScreensList());
  }, []);

  const {
    headerList,
    data,
    hiddenColumns,
    visibleColumns,
  } = useSelector(
    (state: any) => state?.Workflows?.DocumentBuilder,
  );

  const {
    recordsToShow,
    tableState,
    handlePaginationChange,
    handleSearchChange,
    handleSelectChange,
    handleSortTable,
  } = useWorkflowState(searchHeader, data, getDocumentBuilderTableData);

  const handleRowClick = (e: any) => {
    navigate(`Detail/${e.DocName.trim()}`);
  };

  const handleOptionClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElement(null);
  };

  return (
    <>
      <TmsHeader />
      <SubHeader title="Document Builder">
        <OptionsButton handleClick={handleOptionClick} />
        <DocumentBuilderTableMenu
          anchorEl={anchorElement}
          onClose={handleCloseMenu}
          openCreateScreen={() => navigate('New')}
          openColumnsDialog={() => null}
        />
      </SubHeader>
      <WorkflowTableUtils
        handleSearchChange={handleSearchChange}
        handleSelectChange={handleSelectChange}
        recordsNumber={tableState.recordsNumber}
        searchText={tableState.searchText}
      />
      <WorkflowTable
        handleClickRow={(e: any) => handleRowClick(e)}
        // Todo: add DocName to the response
        visibleColumns={{ DocName: 'DocName', ...visibleColumns }}
        allDataCount={data.length}
        handlePaginationChange={handlePaginationChange}
        recordsToShow={recordsToShow}
        tableHeader={tableHeader}
        tableState={tableState}
        order={tableState.order}
        orderBy={tableState.orderBy}
        searchText={tableState.searchText}
        sortTable={handleSortTable}
      />
    </>
  );
};

export default DocumentBuilder;
