/* eslint-disable quotes */
export const business = [
  {
    screenName: "Account",
    url: "",
    subMenu: [
      {
        screenName: "Account EDI",
        url: "maint/MAINT-003",
        subMenu: null,
      },
      {
        screenName: "Account Loading Type",
        url: "maint/MAINT-004",
        subMenu: null,
      },
      {
        screenName: "Account Products",
        url: "maint/MAINT-005",
        subMenu: null,
      },
      {
        screenName: "Accounts",
        url: "maint/MAINT-002",
        subMenu: null,
      },
      {
        screenName: "ATX6",
        url: "maint/MAINT-017",
        subMenu: null,
      },
    ],
  },
  {
    screenName: "Add Authorized Products",
    url: "workflow/add-authorized-products",
    subMenu: null,
  },
  {
    screenName: "Addresses",
    url: "maint/MAINT-224",
    subMenu: null,
  },
  {
    screenName: "Allocations",
    url: "/",
    subMenu: [
      {
        screenName: "Allocation",
        url: "workflow/allocations",
        subMenu: null,
      },
      {
        screenName: "Allocation Warning/Denial",
        url: "maint/MAINT-011",
        subMenu: null,
      },
      {
        screenName: "Daily Liftings",
        url: "/",
        subMenu: null,
      },
      {
        screenName: "Required Allocation",
        url: "maint/MAINT-111",
        subMenu: null,
      },
      {
        screenName: "Temporary Liftings (RecViewer)",
        url: "maint/MAINT-142",
        subMenu: null,
      },
    ],
  },
  {
    screenName: "Class of Trade",
    url: "",
    subMenu: [
      {
        screenName: "Class of Trade (Major)",
        url: "maint/MAINT-033",
        subMenu: null,
      },
      {
        screenName: "Class of Trade (Minor)",
        url: "maint/MAINT-034",
        subMenu: null,
      },
    ],
  },
  {
    screenName: "Carrier",
    url: "/",
    subMenu: [
      {
        screenName: "Carrier Cross-Ref",
        url: "maint/MAINT-030",
        subMenu: null,
      },
      {
        screenName: "Carrier Mail",
        url: "maint/MAINT-012",
        subMenu: null,
      },
      {
        screenName: "Carrier-Vehicle-Supplier Cross Reference",
        url: "maint/MAINT-013",
        subMenu: null,
      },
      {
        screenName: "Carriers",
        url: "maint/MAINT-029",
        subMenu: null,
      },
      {
        screenName: "Driver Carrier Cross-Reference",
        url: "maint/MAINT-051",
        subMenu: null,
      },
      {
        screenName: "Driver Cross-Reference",
        url: "maint/MAINT-053",
        subMenu: null,
      },
      {
        screenName: "Driver Mail",
        url: "maint/MAINT-007",
        subMenu: null,
      },
      {
        screenName: "Driver Safety Violations",
        url: "maint/MAINT-213",
        subMenu: null,
      },
      {
        screenName: "Drivers",
        url: "maint/MAINT-052",
        subMenu: null,
      },
      {
        screenName: "Other Mail",
        url: "maint/MAINT-027",
        subMenu: null,
      },
      {
        screenName: "SAP Storage Location",
        url: "maint/MAINT-114",
        subMenu: null,
      },
      {
        screenName: "Vehicle Groups",
        url: "maint/MAINT-165",
        subMenu: null,
      },
      {
        screenName: "Vehicle Identification (NEDAP)",
        url: "maint/MAINT-229",
        subMenu: null,
      },
      {
        screenName: "Vehicle Maximum Weights",
        url: "maint/MAINT-232",
        subMenu: null,
      },
      {
        screenName: "Vehicles",
        url: "maint/MAINT-163",
        subMenu: null,
      },
    ],
  },
  {
    screenName: "Contract",
    url: "",
    subMenu: [
      {
        screenName: "Contract Item",
        url: "maint/MAINT-174",
        subMenu: null,
      },
      {
        screenName: "Contract Item Reserved",
        url: "maint/MAINT-175",
        subMenu: null,
      },
      {
        screenName: "Contracts",
        url: "maint/MAINT-173",
        subMenu: null,
      },
      {
        screenName: "Host-Contract Cross-Reference",
        url: "maint/MAINT-202",
        subMenu: null,
      },
      {
        screenName: "Load-ID List",
        url: "maint/MAINT-228",
        subMenu: null,
      },
    ],
  },
  {
    screenName: "Customer",
    url: "",
    subMenu: [
      {
        screenName: "Customer EDI",
        url: "maint/MAINT-038",
        subMenu: null,
      },
      {
        screenName: "Customer Loading Type",
        url: "maint/MAINT-039",
        subMenu: null,
      },
      {
        screenName: "Customer Products",
        url: "maint/MAINT-041",
        subMenu: null,
      },
      {
        screenName: "Customers",
        url: "maint/MAINT-040",
        subMenu: null,
      },
    ],
  },
  {
    screenName: "Delivery",
    url: "/",
    subMenu: [
      {
        screenName: "Delivery",
        url: "maint/MAINT-238",
        subMenu: null,
      },
      {
        screenName: "Delivery Special Product Description",
        url: "maint/MAINT-044",
        subMenu: null,
      },
    ],
  },
  {
    screenName: "Destination",
    url: "/",
    subMenu: [
      {
        screenName: "Destination EDI",
        url: "maint/MAINT-046",
        subMenu: null,
      },
      {
        screenName: "Destination Products",
        url: "maint/MAINT-048",
        subMenu: null,
      },
      {
        screenName: "Destination SPLC",
        url: "maint/MAINT-049",
        subMenu: null,
      },
      {
        screenName: "Destinations",
        url: "maint/MAINT-047",
        subMenu: null,
      },
    ],
  },
  {
    screenName: "ERP Handling Type",
    url: "maint/MAINT-209",
    subMenu: null,
  },
  {
    screenName: "Handling Type",
    url: "maint/MAINT-069",
    subMenu: null,
  },
  {
    screenName: "Handling Type Percentage",
    url: "maint/MAINT-201",
    subMenu: null,
  },
  {
    screenName: "Locations",
    url: "maint/MAINT-219",
    subMenu: null,
  },
  {
    screenName: "Messages",
    url: "maint/MAINT-221",
    subMenu: null,
  },
  {
    screenName: "Partner List",
    url: "maint/MAINT-222",
    subMenu: null,
  },
  {
    screenName: "Seller ID Cross-Reference",
    url: "maint/MAINT-119",
    subMenu: null,
  },
  {
    screenName: "Shipment",
    url: "/",
    subMenu: [
      {
        screenName: "Shipment Errors",
        url: "maint/MAINT-120",
        subMenu: null,
      },
    ],
  },
  {
    screenName: "Supplier",
    url: "maint/MAINT-120",
    subMenu: [
      {
        screenName: "Supplier Configuration",
        url: "maint/MAINT-126",
        subMenu: null,
      },
      {
        screenName: "Supplier EDI",
        url: "maint/MAINT-127",
        subMenu: null,
      },
      {
        screenName: "Supplier Loading Type",
        url: "maint/MAINT-130",
        subMenu: null,
      },
      {
        screenName: "Supplier Products",
        url: "maint/MAINT-128",
        subMenu: null,
      },
      {
        screenName: "Suppliers",
        url: "maint/MAINT-129",
        subMenu: null,
      },
    ],
  },
  {
    screenName: "Order",
    url: "/",
    subMenu: [
      {
        screenName: "Order Maintenance",
        url: "workflow/order-maintenance",
        subMenu: null,
      },
      {
        screenName: "Order Seals",
        url: "maint/MAINT-118",
        subMenu: null,
      },
    ],
  },
  {
    screenName: "Tax Status",
    url: "maint/MAINT-212",
    subMenu: null,
  },
];

export const product = [
  {
    screenName: "Hazard Rules",
    url: "maint/MAINT-217",
    subMenu: null,
  },
  {
    screenName: "Product Compatibility Table",
    url: "maint/MAINT-093",
    subMenu: null,
  },
  {
    screenName: "Product Description by Language",
    url: "maint/MAINT-095",
    subMenu: null,
  },
  {
    screenName: "Product Group Description",
    url: "maint/MAINT-096",
    subMenu: null,
  },
  {
    screenName: "Product Messages",
    url: "maint/MAINT-223",
    subMenu: null,
  },
  {
    screenName: "Product Quality",
    url: "maint/MAINT-099",
    subMenu: null,
  },
  {
    screenName: "Products",
    url: "maint/MAINT-010",
    subMenu: null,
  },
  {
    screenName: "Misc. Settings",
    url: "/",
    subMenu: [
      {
        screenName: "API 54Y Products",
        url: "maint/MAINT-204",
        subMenu: null,
      },
      {
        screenName: "Blend Owner Reference",
        url: "maint/MAINT-023",
        subMenu: null,
      },
      {
        screenName: "Product Cross-Reference",
        url: "maint/MAINT-103",
        subMenu: null,
      },
      {
        screenName: "Product Group Cross Reference",
        url: "workflow/product-group-cross-reference",
        subMenu: null,
      },
      {
        screenName: "Product Special Descriptions",
        url: "maint/MAINT-101",
        subMenu: null,
      },
      {
        screenName: "Product Special Description (AGD)",
        url: "maint/MAINT-100",
        subMenu: null,
      },
      {
        screenName: "Product Substitution",
        url: "maint/MAINT-102",
        subMenu: null,
      },
      {
        screenName: "Secure Product Password",
        url: "maint/MAINT-117",
        subMenu: null,
      },
      {
        screenName: "Secure Products by Driver",
        url: "maint/MAINT-116",
        subMenu: null,
      },
      {
        screenName: "Tank Batch",
        url: "maint/MAINT-098",
        subMenu: null,
      },
      {
        screenName: "Terminal Product Cross-Reference (COT Major)",
        url: "maint/MAINT-059",
        subMenu: null,
      },
      {
        screenName: "Terminal Product Cross-Reference (COT Minor)",
        url: "maint/MAINT-145",
        subMenu: null,
      },
      {
        screenName: "Terminal Product Cross-Reference",
        url: "maint/MAINT-205",
        subMenu: null,
      },
      {
        screenName: "Truck Blended Products",
        url: "maint/MAINT-153",
        subMenu: null,
      },
    ],
  },
  {
    screenName: "ULSD",
    url: "",
    subMenu: [
      {
        screenName: "ULSD Compliance Summary",
        url: "maint/MAINT-157",
        subMenu: null,
      },
      {
        screenName: "ULSD Handling Type",
        url: "maint/MAINT-158",
        subMenu: null,
      },
      {
        screenName: "ULSD Product Attribute",
        url: "maint/MAINT-159",
        subMenu: null,
      },
      {
        screenName: "ULSD Product Grade",
        url: "maint/MAINT-160",
        subMenu: null,
      },
    ],
  },
];

export const operations = [
  {
    screenName: "Contract Dispatch",
    url: "workflow/contract-dispatch",
    subMenu: null,
  },
  {
    screenName: "Drivers in Facility",
    url: "workflow/drivers-in-facility",
    subMenu: null,
  },
  {
    screenName: "Drivers Through Facility",
    url: "workflow/drivers-through-facility",
    subMenu: null,
  },
  {
    screenName: "End of Day",
    url: "",
    subMenu: [
      {
        screenName: "Folio Freeze/Close",
        url: "workflow/folio-freeze-close",
        subMenu: null,
      },
      {
        screenName: "Gauges",
        url: "maint/MAINT-066",
        subMenu: null,
      },
      {
        screenName: "Meter Entry",
        url: "workflow/meter-entry",
        subMenu: null,
      },
      {
        screenName: "Meter Readings",
        url: "maint/MAINT-077",
        subMenu: null,
      },
      {
        screenName: "Product Balancing",
        url: "maint/MAINT-113",
        subMenu: null,
      },
      {
        screenName: "Tank Gauge Entry",
        url: "workflow/tank-gauge-entry",
        subMenu: null,
      },
      {
        screenName: "View Totalizer Data",
        url: "workflow/view-totalizer-data",
        subMenu: null,
      },
    ],
  },
  {
    screenName: "Gate Access",
    url: "maint/MAINT-065",
    subMenu: null,
  },
  {
    screenName: "Load ID Dispatch",
    url: "/",
    subMenu: null,
  },
  {
    screenName: "Load Rack",
    url: "/",
    subMenu: null,
  },
  {
    screenName: "Rack Status",
    url: "workflow/rack-status",
    subMenu: null,
  },
  {
    screenName: "Railcar",
    url: "/",
    subMenu: [
      {
        screenName: "Rail Outage",
        url: "maint/MAINT-104",
        subMenu: null,
      },
      {
        screenName: "Rail Station",
        url: "maint/MAINT-105",
        subMenu: null,
      },
    ],
  },
  {
    screenName: "RCU Ditto",
    url: "workflow/rcu-ditto",
    subMenu: null,
  },
  {
    screenName: "Personnel In Facility",
    url: "workflow/personnel-in-facility",
    subMenu: null,
  },
  {
    screenName: "Personnel In Facility(Manual)",
    url: "maint/MAINT-236",
    subMenu: null,
  },
  {
    screenName: "Tank",
    url: "/",
    subMenu: [
      {
        screenName: "Change Active Tank",
        url: "workflow/change-active-tank",
        subMenu: null,
      },
      {
        screenName: "Product Owner Reference",
        url: "maint/MAINT-097",
        subMenu: null,
      },
      {
        screenName: "Tank Status",
        url: "workflow/tank-status",
        subMenu: null,
      },
      {
        screenName: "Tank Strapping Tables",
        url: "workflow/tank-strapping-tables",
        subMenu: null,
      },
      {
        screenName: "Tanks",
        url: "maint/MAINT-139",
        subMenu: null,
      },
    ],
  },
  {
    screenName: "Traffic Control",
    url: "/",
    subMenu: [
      {
        screenName: "Bay Groups",
        url: "maint/MAINT-230",
        subMenu: null,
      },
      {
        screenName: "Bay Group Component",
        url: "maint/MAINT-231",
        subMenu: null,
      },
      {
        screenName: "Traffic",
        url: "workflow/traffic",
        subMenu: null,
      },
      {
        screenName: "Preferred Options",
        url: "maint/MAINT-233",
        subMenu: null,
      },
      {
        screenName: "Preferred Schedule",
        url: "maint/MAINT-234",
        subMenu: null,
      },
      {
        screenName: "Marquee Manager",
        url: "workflow/marquee-manager",
        subMenu: null,
      },
      {
        screenName: "Traffic Tracking",
        url: "maint/MAINT-235",
        subMenu: null,
      },
      {
        screenName: "Truck Staging",
        url: "maint/MAINT-154",
        subMenu: null,
      },
    ],
  },
  {
    screenName: "Transaction Viewer",
    url: "/",
    subMenu: null,
  },
  {
    screenName: "Vehicles in Facility",
    url: "workflow/vehicles-in-facility",
    subMenu: null,
  },
  {
    screenName: "VRU",
    url: "/",
    subMenu: [
      {
        screenName: "Flow Authorization Group",
        url: "maint/MAINT-060",
        subMenu: null,
      },
      {
        screenName: "Flow Authorization Status",
        url: "maint/MAINT-061",
        subMenu: null,
      },
      {
        screenName: "Flow Monitor",
        url: "maint/MAINT-062",
        subMenu: null,
      },
      {
        screenName: "VRU Flow Settings",
        url: "maint/MAINT-168",
        subMenu: null,
      },
      {
        screenName: "VRU Settings",
        url: "maint/MAINT-172",
        subMenu: null,
      },
    ],
  },
];

export const manualTransaction = [
  {
    screenName: "Bulk Movement",
    url: "/",
    subMenu: [
      {
        screenName: "Virtual Meter",
        url: "maint/MAINT-167",
        subMenu: null,
      },
    ],
  },
  {
    screenName: "Bulk Transaction",
    url: "workflow/bulk-transacation",
    subMenu: null,
  },
  {
    screenName: "Bulk Transfer - Tanks",
    url: "/",
    subMenu: null,
  },
  {
    screenName: "Contract Transaction",
    url: "/",
    subMenu: null,
  },
  {
    screenName: "Guage Transaction",
    url: "/",
    subMenu: null,
  },
  {
    screenName: "Non-Bulk Transaction",
    url: "workflow/non-bulk-transacation",
    subMenu: null,
  },
  {
    screenName: "Reason Codes",
    url: "maint/MAINT-106",
    subMenu: null,
  },
  {
    screenName: "Reason Cross-Reference",
    url: "maint/MAINT-107",
    subMenu: null,
  },
];

export const documents = [
  {
    screenName: "BOL Text",
    url: "",
    subMenu: [
      {
        screenName: "BOL Messages",
        url: "maint/MAINT-024",
        subMenu: null,
      },
      {
        screenName: "BOL Messages by State",
        url: "maint/MAINT-122",
        subMenu: null,
      },
      {
        screenName: "BOL Product Destination Messages",
        url: "maint/MAINT-025",
        subMenu: null,
      },
      {
        screenName: "BOL Product Messages",
        url: "maint/MAINT-026",
        subMenu: null,
      },
      {
        screenName: "Hazard Messages",
        url: "maint/MAINT-070",
        subMenu: null,
      },
      {
        screenName: "Regulatory Text (Account)",
        url: "maint/MAINT-108",
        subMenu: null,
      },
      {
        screenName: "Regulatory Text (Load ID)",
        url: "maint/MAINT-109",
        subMenu: null,
      },
      {
        screenName: "Regulatory Text (Order)",
        url: "maint/MAINT-110",
        subMenu: null,
      },
    ],
  },
  {
    screenName: "Document Builder",
    url: "workflow/document-builder",
    subMenu: null,
  },
  {
    screenName: "Document Definition",
    url: "maint/MAINT-079",
    subMenu: null,
  },
  {
    screenName: "EMCS",
    url: "",
    subMenu: [
      {
        screenName: "Condition Set",
        url: "workflow/condition-set",
        subMenu: null,
      },
      {
        screenName: "EMCS Configuration",
        url: "workflow/emcs-configuration",
        subMenu: null,
      },
      {
        screenName: "EMCS Manager",
        url: "workflow/emcs-manager",
        subMenu: null,
      },
    ],
  },
];

export const inventory = [
  {
    screenName: "Stocks",
    url: "maint/MAINT-124",
    subMenu: null,
  },
  {
    screenName: "Stock Balance",
    url: "maint/MAINT-125",
    subMenu: null,
  },
  {
    screenName: "Tank Balance",
    url: "maint/MAINT-136",
    subMenu: null,
  },
  {
    screenName: "Tank Dips",
    url: "maint/MAINT-137",
    subMenu: null,
  },
  {
    screenName: "Tank Stocks",
    url: "maint/MAINT-138",
    subMenu: null,
  },
];

export const utilities = [
  {
    screenName: "Communications",
    url: "/",
    subMenu: [
      {
        screenName: "Device Communications",
        url: "workflow/device-communications",
        subMenu: null,
      },
      {
        screenName: "VIP Scully Communications",
        url: "workflow/vip-scully-communications",
        subMenu: null,
      },
    ],
  },
  {
    screenName: "Log Viewer",
    url: "/",
    subMenu: null,
  },
  {
    screenName: "Process Status",
    url: "/",
    subMenu: null,
  },
  {
    screenName: "Server Health",
    url: "/",
    subMenu: null,
  },
  {
    screenName: "System Utility",
    url: "/",
    subMenu: [
      {
        screenName: "System Utility",
        url: "maint/MAINT-045",
        subMenu: null,
      },
      {
        screenName: "System Utility-Queue",
        url: "workflow/system-utility-queue",
        subMenu: null,
      },
      {
        screenName: "Force Kill a Bay",
        url: "workflow/system-utility",
        subMenu: null,
      },
      {
        screenName: "Gate Open",
        url: "workflow/system-utility",
        subMenu: null,
      },
      {
        screenName: "Regenerate Folio",
        url: "workflow/system-utility",
        subMenu: null,
      },
      {
        screenName: "Reset The Printer",
        url: "workflow/system-utility",
        subMenu: null,
      },
      {
        screenName: "Restart BAY1",
        url: "workflow/system-utility",
        subMenu: null,
      },
      {
        screenName: "Restart BAY2",
        url: "workflow/system-utility",
        subMenu: null,
      },
      {
        screenName: "Restart BAY3",
        url: "workflow/system-utility",
        subMenu: null,
      },
      {
        screenName: "Restart BAY4",
        url: "workflow/system-utility",
        subMenu: null,
      },
      {
        screenName: "Restart BAY5",
        url: "workflow/system-utility",
        subMenu: null,
      },
      {
        screenName: "Restart BAY6",
        url: "workflow/system-utility",
        subMenu: null,
      },
      {
        screenName: "Restart Rcudittoserver",
        url: "workflow/system-utility",
        subMenu: null,
      },
      {
        screenName: "Set Fully Load",
        url: "workflow/system-utility",
        subMenu: null,
      },
      {
        screenName: "Trigger backup",
        url: "workflow/system-utility",
        subMenu: null,
      },
      {
        screenName: "Trigger tms-snapshot",
        url: "workflow/system-utility",
        subMenu: null,
      },
      {
        screenName: "UNFREEZE BAYS",
        url: "workflow/system-utility",
        subMenu: null,
      },
      {
        screenName: "_Force Stop Load Simulation",
        url: "workflow/system-utility",
        subMenu: null,
      },
      {
        screenName: "_Graceful  Stop Load Simulation",
        url: "workflow/system-utility",
        subMenu: null,
      },
      {
        screenName: "_Load Simulation Status",
        url: "workflow/system-utility",
        subMenu: null,
      },
      {
        screenName: "_Start Load Simulation",
        url: "workflow/system-utility",
        subMenu: null,
      },
    ],
  },
  {
    screenName: "Tile Layout",
    url: "workflow/tile-layout",
    subMenu: null,
  },
  {
    screenName: "User Session",
    url: "/",
    subMenu: null,
  },
];

export const settings = [
  {
    screenName: "About",
    url: "/",
    subMenu: null,
  },
  {
    screenName: "Change Password",
    url: "change-password",
    subMenu: null,
  },
  {
    screenName: "Field Attributes",
    url: "/",
    subMenu: null,
  },
  {
    screenName: "File Export",
    url: "workflow/file-export",
    subMenu: null,
  },
  {
    screenName: "File Import",
    url: "/",
    subMenu: [
      {
        screenName: "File Import (Upload)",
        url: "/workflow/file-import",
        subMenu: null,
      },
      {
        screenName: "File Import Select",
        url: "/",
        subMenu: null,
      },
      {
        screenName: "File Import Status",
        url: "/",
        subMenu: null,
      },
    ],
  },
  {
    screenName: "Queue Manager",
    url: "/",
    subMenu: null,
  },
  {
    screenName: "Terminal",
    url: "/",
    subMenu: [
      {
        screenName: "Access Holiday Schedule",
        url: "maint/MAINT-166",
        subMenu: null,
      },
      {
        screenName: "Account Product Pricing",
        url: "maint/MAINT-171",
        subMenu: null,
      },
      {
        screenName: "Admin Area Prod Reg Text",
        url: "maint/MAINT-006",
        subMenu: null,
      },
      {
        screenName: "AGD Reference Number",
        url: "maint/MAINT-008",
        subMenu: null,
      },
      {
        screenName: "Alarm",
        url: "maint/MAINT-009",
        subMenu: null,
      },
      {
        screenName: "Card",
        url: "maint/MAINT-028",
        subMenu: null,
      },
      {
        screenName: "Communication Config",
        url: "maint/MAINT-031",
        subMenu: null,
      },
      {
        screenName: "Conversion Table (BSW/mA)",
        url: "maint/MAINT-032",
        subMenu: null,
      },
      {
        screenName: "Country",
        url: "maint/MAINT-035",
        subMenu: null,
      },
      {
        screenName: "Currency Codes",
        url: "maint/MAINT-037",
        subMenu: null,
      },
      {
        screenName: "Currency Conversion Rates",
        url: "maint/MAINT-036",
        subMenu: null,
      },
      {
        screenName: "Dangerous Goods",
        url: "maint/MAINT-042",
        subMenu: null,
      },
      {
        screenName: "ERP Seals",
        url: "maint/MAINT-215",
        subMenu: null,
      },
      {
        screenName: "Excise Duty",
        url: "maint/MAINT-055",
        subMenu: null,
      },
      {
        screenName: "Excise Duty Group",
        url: "maint/MAINT-056",
        subMenu: null,
      },
      {
        screenName: "Facility Access",
        url: "maint/MAINT-001",
        subMenu: null,
      },
      {
        screenName: "Folio",
        url: "maint/MAINT-064",
        subMenu: null,
      },
      {
        screenName: "Folio Override",
        url: "maint/MAINT-063",
        subMenu: null,
      },
      {
        screenName: "Folio Skip Days",
        url: "maint/MAINT-164",
        subMenu: null,
      },
      {
        screenName: "Generic Cross-Reference",
        url: "maint/MAINT-068",
        subMenu: null,
      },
      {
        screenName: "Geo Unit",
        url: "maint/MAINT-067",
        subMenu: null,
      },
      {
        screenName: "Host Interface",
        url: "/",
        subMenu: [
          {
            screenName: "Maintenance Routing TMS",
            url: "maint/MAINT-076",
            subMenu: null,
          },
          {
            screenName: "Mtr to Host Translate",
            url: "maint/MAINT-081",
            subMenu: null,
          },
          {
            screenName: "TABS Supplier Cross-Reference",
            url: "maint/MAINT-133",
            subMenu: null,
          },
          {
            screenName: "TEAS Maint",
            url: "maint/MAINT-141",
            subMenu: null,
          },
          {
            screenName: "Xlat Actuals",
            url: "maint/MAINT-170",
            subMenu: null,
          },
        ],
      },
      {
        screenName: "Jurisdiction",
        url: "maint/MAINT-073",
        subMenu: null,
      },
      {
        screenName: "Maintenance Exclusion",
        url: "maint/MAINT-043",
        subMenu: null,
      },
      {
        screenName: "Printer Configuration",
        url: "maint/MAINT-148",
        subMenu: null,
      },
      {
        screenName: "Routing Control",
        url: "maint/MAINT-112",
        subMenu: null,
      },
      {
        screenName: "Seazonal Zone",
        url: "maint/MAINT-115",
        subMenu: null,
      },
      {
        screenName: "Shipping Condition",
        url: "maint/MAINT-121",
        subMenu: null,
      },
      {
        screenName: "States",
        url: "maint/MAINT-123",
        subMenu: null,
      },
      {
        screenName: "System Parameters",
        url: "maint/MAINT-203",
        subMenu: null,
      },
      {
        screenName: "Tag Events",
        url: "maint/MAINT-134",
        subMenu: null,
      },
      {
        screenName: "Tags",
        url: "maint/MAINT-135",
        subMenu: null,
      },
      {
        screenName: "Tank View Configuration",
        url: "maint/MAINT-156",
        subMenu: null,
      },
      {
        screenName: "Tax",
        url: "maint/MAINT-140",
        subMenu: null,
      },
      {
        screenName: "Terminal Cross-Reference",
        url: "maint/MAINT-144",
        subMenu: null,
      },
      {
        screenName: "Terminal EDI",
        url: "maint/MAINT-143",
        subMenu: null,
      },
      {
        screenName: "Terminal EDI Stats",
        url: "maint/MAINT-206",
        subMenu: null,
      },
      {
        screenName: "Terminal Groups",
        url: "maint/MAINT-207",
        subMenu: null,
      },
      {
        screenName: "Terminal Profile",
        url: "maint/MAINT-146",
        subMenu: null,
      },
      {
        screenName: "Tolerance",
        url: "maint/MAINT-149",
        subMenu: null,
      },
      {
        screenName: "TopHAT Control",
        url: "maint/MAINT-150",
        subMenu: null,
      },
      {
        screenName: "TopHAT Terminal Profile",
        url: "maint/MAINT-147",
        subMenu: null,
      },
      {
        screenName: "Transaction Code Definition",
        url: "maint/MAINT-151",
        subMenu: null,
      },
      {
        screenName: "Transaction Cross Reference",
        url: "maint/MAINT-152",
        subMenu: null,
      },
      {
        screenName: "Travel Time",
        url: "maint/MAINT-227",
        subMenu: null,
      },
      {
        screenName: "Truck Tolerance",
        url: "maint/MAINT-155",
        subMenu: null,
      },
      {
        screenName: "Unit of Measure",
        url: "maint/MAINT-161",
        subMenu: null,
      },
      {
        screenName: "Variable Line Fill",
        url: "maint/MAINT-162",
        subMenu: null,
      },
    ],
  },
];

export const admin = [
  {
    screenName: "Purge/Archive",
    url: "/",
    subMenu: [
      {
        screenName: "Purge/Archive Database Tables",
        url: "maint/MAINT-014",
        subMenu: null,
      },
      {
        screenName: "Purge/Archive System Folders",
        url: "maint/MAINT-015",
        subMenu: null,
      },
      {
        screenName: "Purge/Archive Options",
        url: "maint/MAINT-016",
        subMenu: null,
      },
      {
        screenName: "Purge/Archive Configuration",
        url: "maint/MAINT-208",
        subMenu: null,
      },
    ],
  },
  {
    screenName: "Custom Report Configuration",
    url: "workflow/custom-report-configuration",
    subMenu: null,
  },
  {
    screenName: "Email Groups",
    url: "maint/MAINT-054",
    subMenu: null,
  },
  {
    screenName: "Field Mask",
    url: "maint/MAINT-058",
    subMenu: null,
  },
  {
    screenName: "Groups",
    url: "workflow/Groups",
    subMenu: null,
  },
  {
    screenName: "ISO Languages",
    url: "maint/MAINT-072",
    subMenu: null,
  },
  {
    screenName: "Manage Printers",
    url: "workflow/manage-printers",
    subMenu: null,
  },
  {
    screenName: "Number Range",
    url: "maint/MAINT-210",
    subMenu: null,
  },
  {
    screenName: "System Start-Up",
    url: "workflow/System-startup",
    subMenu: null,
  },
  {
    screenName: "System Status",
    url: "maint/MAINT-132",
    subMenu: null,
  },
  {
    screenName: "Users",
    url: "/",
    subMenu: null,
  },
];

export const reporting = [
  {
    screenName: "Report Queue",
    url: "workflow/report-queue",
    subMenu: null,
  },
  {
    screenName: "View Audit Logs",
    url: "/",
    subMenu: null,
  },
  {
    screenName: "View Trace Logs",
    url: "workflow/view-trace-logs",
    subMenu: null,
  },
  {
    screenName: "Reporting (Custom)",
    url: "/",
    subMenu: [
      {
        screenName: "SampleJorge",
        url: "/",
        subMenu: [
          {
            screenName: "Driver List JORGE",
            url: "/",
            subMenu: null,
          },
        ],
      },
      {
        screenName: "SampleJorge2",
        url: "/",
        subMenu: [
          {
            screenName: "Allocation Report JORGE",
            url: "/",
            subMenu: null,
          },
        ],
      },
      {
        screenName: "SampleJorge3",
        url: "/",
        subMenu: [
          {
            screenName: "Additive Report by JORGE",
            url: "/",
            subMenu: null,
          },
        ],
      },
      {
        screenName: "TMSTEst",
        url: "/",
        subMenu: [
          {
            screenName: "Account Activity Report",
            url: "workflow/reports",
            subMenu: null,
          },
          {
            screenName: "Allocation Liftings Report TRACE",
            url: "/",
            subMenu: null,
          },
        ],
      },
    ],
  },
  {
    screenName: "Allocation Reports",
    url: "/",
    subMenu: [
      {
        screenName: "Allocation Liftings (warn & denial)",
        url: "workflow/reportsreports",
        subMenu: null,
      },
      {
        screenName: "Allocation Liftings Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Allocation Over Percentage",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Allocations Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Allocations Report (warn & denial)",
        url: "workflow/reports",
        subMenu: null,
      },
    ],
  },
  {
    screenName: "Balancing Reports",
    url: "/",
    subMenu: [
      {
        screenName: "Additive Injection Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Additive Injection Report (Alternate)",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Additive Injection Report (Meter Totalizers)",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Additive Injection Report (PulseFeedback)",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Additive Mass Balance Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Additive Report by Meter",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Additive Report by Product",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Dye Injection Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Inventory Balance Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Itemized Balancing Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "MTD Stock Summary (Bulk Units) - BPIMS",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Meter Reconciliation Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Non Bulk Product Movement Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Physical Meter Reconciliation Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Pipeline Balance Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Prime Idle Tank - BPIMS",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Product Balancing Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Product Summary Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Tank Capacity Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Tank Inventory Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Tank Inventory Report (Product)",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Tank Inventory Report (Product) - BPIMS",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Tank Inventory Report - BPIMS",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Tank Stock Balance Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Tank Stock Balance Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Terminal Balance Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Terminal Balance Report -  BPIMS",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Undo Prime Idle - BPIMS",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Volumetric Additive Reconciliation Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Volumetric Additive Reconciliation Report with Dye",
        url: "workflow/reports",
        subMenu: null,
      },
    ],
  },
  {
    screenName: "Database Listings",
    url: "/",
    subMenu: [
      {
        screenName: "Account Listing",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "BOL Listing",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Blanket Orders Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Card List (Drivers)",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Carrier Expiration Listing",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Carrier List",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Consist Report (Alternate)",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Contract Listing Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Customer List",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Daily Driver Log",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Destination Listing",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Dispatch Order Listing",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Driver Expiration Listing",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Driver List",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Driver Safety Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Driver Safety Report (All Expired VTT Violations)",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Driver Safety Report (Expired VTT Violations)",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Drivers with Secured Product List",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "EDI Comparison",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Extended Tank Listing Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Extended Tank Listing Report (Barrels)",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Group Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Group and User Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Health Status Listing",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Multiload Register Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Open Orders Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Pending Load Confirm Listing",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Product Blend Ratio Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Product List",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Real-Time Stock Listing",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Secured Products Listing",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Supplier List",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Trailer Listing",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "User Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Vehicle Expiration Listing",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Vehicle Overweight Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Vehicle Supplier Xref Listing",
        url: "workflow/reports",
        subMenu: null,
      },
    ],
  },
  {
    screenName: "EBV Reports",
    url: "/",
    subMenu: [
      {
        screenName: "EBV Report - Form 30 (Non-EU)",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "EBV Report - Form 35 (EU)",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "EBV Report - Form 36 (Bio)",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "EBV Stock Report",
        url: "workflow/reports",
        subMenu: null,
      },
    ],
  },
  {
    screenName: "EMCS Reports",
    url: "/",
    subMenu: [
      {
        screenName: "EMCS Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Transaction ARC Requests",
        url: "workflow/reports",
        subMenu: null,
      },
    ],
  },
  {
    screenName: "Railcar Reports",
    url: "/",
    subMenu: [
      {
        screenName: "Railcar Listing Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Railcar Loading Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Railcar Staging Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Railyard Track Report",
        url: "workflow/reports",
        subMenu: null,
      },
    ],
  },
  {
    screenName: "Stock Reports",
    url: "/",
    subMenu: [
      {
        screenName: "Bulk Product Movement Report w/522",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Bulk Stock Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Bulk Stock Report (Bulk Units)",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Daily Inventory Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "End of Month Supplier Product Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "MTD Pipeline Summary Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "MTD Stock Summary",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "MTD Stock Summary (Bulk Units)",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "MTD Stock Summary - BPIMS",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Real Time Stock Balance Report",
        url: "workflow/reports",
        subMenu: null,
      },
    ],
  },
  {
    screenName: "TMS Configuration Reports",
    url: "",
    subMenu: [
      {
        screenName: "Batch Tracking Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Drivers in Facility Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Meter Creep Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Meter Totalizers",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Nist Log Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Opto Detail Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Preset / Meter Calibration Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "SOX Report - BPA",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "System Access Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "System Readiness - Roger Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Vehicles in Facility Report",
        url: "workflow/reports",
        subMenu: null,
      },
    ],
  },
  {
    screenName: "Tax Reports",
    url: "",
    subMenu: [
      {
        screenName: "Tax Status Report (Alternate)",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Tax Status by Supplier (Air)",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Tax Status by Supplier (Vacuum)",
        url: "workflow/reports",
        subMenu: null,
      },
    ],
  },
  {
    screenName: "Transaction Exports",
    url: "workflow/reports",
    subMenu: [
      {
        screenName: "Transaction Export (Driver Gate Access)",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Transaction Export (Normal)",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Transaction Export (Quoted Output)",
        url: "workflow/reports",
        subMenu: null,
      },
    ],
  },
  {
    screenName: "Transaction Reports",
    url: "/",
    subMenu: [
      {
        screenName: "Account Activity Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Account Activity Report (Mass in Vacuum)",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Audit Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "BOL Detail Report (Alternate)",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Bay Load Detail Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Book Tank Balance Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Bulk Shipping Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Bulk Transaction Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Bulk Transaction Report (Bulk Units)",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Bulk Transaction Report (Mass in Vacuum)",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Cancel/Rebill Audit Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Carrier Activity Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Carrier Activity Report (Mass in Vacuum)",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Class of Trade Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Contract Transaction Listing Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Customer Activity Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Customer Activity Report (Alternate)",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Customer Activity Report (Mass in Vacuum)",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Daily Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Driver Activity Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Hourly Lifting Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Left on Truck Detail Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Manual Transaction Control Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Meter Detail Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Meter Detail Report (Mass in Vacuum)",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Own Consumption Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "PQM Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Pedimento Transaction Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Preset Detail Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Preset Detail Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Preset Product Auth Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Product Blend Out of Spec Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Product Detail Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Product Detail Report (Mass in Vacuum)",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Pull Inventory Positions",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Rack Activity Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Rack Activity Report (Alternate)",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Rack Activity Report (Mass in Vacuum)",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Rack Activity Summary Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Rack Activity Summary Report (Alternate)",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Rack Activity Summary Report (Mass in Vacuum)",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Salable Product Detail Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Shipping Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Shipping Report With Components",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Tank Detail Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Tank Gauge Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Transaction Error Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "VRU Outage Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Weight Activity Report",
        url: "workflow/reports",
        subMenu: null,
      },
    ],
  },
  {
    screenName: "ULSD Reports",
    url: "workflow/reports",
    subMenu: [
      {
        screenName: "Compliance Summary  by Folio/Designation",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Compliance Summary  by Supplier/Designation",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Compliance Summary by Folio/Supplier",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "D&T Facility Compliance Calculation Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "D&T Handoff Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Diesel Product Listing Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Driver Events Report",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Folio Report",
        url: "workflow/reports",
        subMenu: null,
      },
    ],
  },
  {
    screenName: "Zytax",
    url: "/",
    subMenu: [
      {
        screenName: "Zytax Data Build Report (genzytax)",
        url: "workflow/reports",
        subMenu: null,
      },
      {
        screenName: "Zytax Data Build Report (new - tmszytax)",
        url: "workflow/reports",
        subMenu: null,
      },
    ],
  },
];
