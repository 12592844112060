import React from 'react';
import {
  Box, Modal, Paper, Tab,
} from '@mui/material';
import Slide from '@mui/material/Slide';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import EmbeddedNavigator from '../../screens/embeddedNavigator/embeddedNavigator';
import {
  LoadEmbbededTables,
  SwitchEmbeddedNavigation,
  LoadRecordEmbbeded,
} from '../../redux/actions/embbededScreensActions';
import CustomField from '../CustomField/CustomField';

const FormBodyTerminal = (props: any) => {
  const {
    dataTabs,
    handleSelectChange,
    handleInputChange,
    values,
    valuesToSend,
    showOptionalFields,
    handleShowOptionalFields,
  } = props;
  const { ScreensList, activeScreen, whereDetailCondition } = useSelector(
    (state: any) => state.ScreenReducer,
  );
  const Ids = Object.entries(dataTabs)
    .map((elt: any) => elt[1].map((element: any) => [element?.id, element?.valForm]))
    .flat();
  const [indice, setIndice] = React.useState('0');

  const [modalOpen, setModalOpen] = React.useState(false);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setIndice(newValue);
  };
  const embeddedStyle = {
    display: 'flex',
    alignItems: 'flex-end',
    width: '100%',
  };

  const dispatch = useDispatch();

  // show optional fields fix
  React.useEffect(() => {
    handleShowOptionalFields(true);
  }, []);

  const handleClickTab = (tab: any) => {
    handleShowOptionalFields(true);
    if (!tab?.[1]?.embbeded) {
      return;
    }
    const screen = ScreensList.filter(
      (item: any) => item.screenId === tab[0],
    )[0];

    if (!screen) {
      return;
    }

    (async () => {
      const fields = Array.from(new Set([...screen?.fields?.split(',')])).join(
        ',',
      );
      const embeddedQuery = await screen.keys
        .split(',')
        .map((val: any, key: any) => (activeScreen.keys.split(',').includes(val) ? val : null))
        .filter((el: any) => el !== null);
      const QueryDependingValues = embeddedQuery.reduce(
        (acc: any, item: any) => ({
          ...acc,
          [item]: JSON.parse(whereDetailCondition)[item],
        }),
        {},
      );
      const FinalEmbeddedQuery: any = await Object.keys(QueryDependingValues)
        .map((item: any) => `${item}='${QueryDependingValues[item]}'`)
        .join(' AND ');
      await dispatch(
        LoadEmbbededTables({
          tableID: screen.tableId,
          screenId: screen.screenId,
          page: 1,
          results_per_page: '10',
          fields: screen.fields ? fields : '',
          fieldsConcatenated: screen.fieldsConcatenated,
          order_by: '',
          where: FinalEmbeddedQuery,
          keys: screen.keys || '',
          dependentKeyEmbedded: activeScreen.keys,
          data: whereDetailCondition,
        }),
      );
      await dispatch(
        LoadRecordEmbbeded({
          tableID: screen.tableId,
          screenID: screen.screenId,
          fields: screen.fields || '',
          where: '',
        }),
      );
      await dispatch(SwitchEmbeddedNavigation('tableScreen'));
      setModalOpen(true);
    })();
  };
  const handleCloseEmbedded = () => {
    setModalOpen(false);
    setIndice('0');
  };
  return (
    <>
      <Box
        className="flexColumn takeTheRest"
        sx={{ width: '99.5%', typography: 'body1', overflow: 'hidden' }}
      >
        <TabContext value={indice}>
          <Box
            sx={{ borderBottom: 1, borderColor: 'divider', minHeight: 'auto' }}
          >
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              sx={{ minHeight: 'auto' }}
            >
              {Object.entries(dataTabs).map((tabInfo: any, idx: any) => (
                <Tab
                  className="f12"
                  label={tabInfo[0]}
                  sx={{
                    minHeight: 'auto',
                    padding: '8px',
                    backgroundColor:
                      tabInfo[1]?.filter((emb: any) => emb?.embbeded === true)
                        .length === 1
                        ? '#e6f1ff'
                        : '#f6f6f6',
                    textTransform: 'none',
                  }}
                  value={idx.toString()}
                  onClick={() => handleClickTab(dataTabs[tabInfo[0]])}
                />
              ))}
            </TabList>
          </Box>
          <Box sx={{
            overflowX: 'hidden', overflowY: 'auto', paddingBottom: '10px', flexGrow: 1,
          }}
          >
            {Object.entries(dataTabs).map((tabInfo: any, index: any) => tabInfo[1].map((val: any) => (
              <TabPanel sx={{ padding: '0px' }} value={index.toString()}>
                {!val?.visible
                  && !showOptionalFields
                  && val?.type !== 'grid' ? (
                    <></>
                  ) : (
                    <CustomField
                      val={val}
                      values={values}
                      valuesToSend={valuesToSend}
                      handleInputChange={handleInputChange}
                      handleSelectChange={handleSelectChange}
                      size="100%"
                    />
                  )}
              </TabPanel>
            )))}
          </Box>
        </TabContext>
      </Box>
      <Modal style={embeddedStyle} open={modalOpen}>
        <Slide in={modalOpen} direction="up">
          <Paper style={{ width: '100%', height: '70vh', overflowY: 'scroll' }}>
            <EmbeddedNavigator
              handleCloseEmbedded={handleCloseEmbedded}
              handleClose={() => dispatch(SwitchEmbeddedNavigation('tableScreen'))}
            />
          </Paper>
        </Slide>
      </Modal>
    </>
  );
};

export default FormBodyTerminal;
