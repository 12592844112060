/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';

// Librairies
import { Grid, InputBase, Typography } from '@mui/material';
import { ArrowUpward } from '@mui/icons-material';
// import Api from '../../../../redux/api/index';
// Styles
import { useNavigate } from 'react-router-dom';
import RackStatusTable from '../rackStatusTable/rackStatusTable';
// import ConfirmationDialog from '../../../ConfirmDialog';

interface IProps {
  scadTable: any;
}

const RackStatusSCADTable: React.FC<IProps> = ({ scadTable }) => {
  const history = useNavigate();
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <RackStatusTable
            headCells={[
              'Driver',
              'Carrier',
              'Supplier',
              'Customer',
              'Account',
              'Destination',
              'Order',
              'Loading',
            ]}
            rows={[scadTable?.values]}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default RackStatusSCADTable;
