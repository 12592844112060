import { Dispatch } from 'redux';
import Api from '../../api/index';
import {
  LOAD_DRIVERS_IN_FACILITY,
  LOAD_DRIVERS_IN_FACILITY_DRIVERS,
  LOAD_DRIVERS_IN_FACILITY_CARRIERS,
} from '../../constants/workflows';

export const getDriversInFacilityData = () => async (dispatch: Dispatch) => {
  const response = await Api.get(
    'workflow021/table?screenID=WORKFLOW-021&termID=B344&tableID=driverinfacility',
  );
  dispatch({
    type: LOAD_DRIVERS_IN_FACILITY,
    payload: response.data,
  });
};

export const exportFile = (mode: string) => async () => {
  await Api.get(
    `workflow021/ExportFile?screenID=WORKFLOW-021&termID=B344&tableID=DriverInFacility&mode=${mode}`,
  );
};

export const getDriversList = () => async (dispatch: Dispatch) => {
  const response = await Api.get(
    'workflow021/optionModalDropdownDriver?screenID=WORKFLOW-021&where=B344',
  );
  dispatch({
    type: LOAD_DRIVERS_IN_FACILITY_DRIVERS,
    payload: response.data,
  });
};

export const getCarriersList = (driver: string) => async (dispatch: Dispatch) => {
  const response = await Api.get(
    `workflow021/optionModalDropdownCarrier?screenID=WORKFLOW-021&term_id=B344&driver=${driver}`,
  );
  dispatch({
    type: LOAD_DRIVERS_IN_FACILITY_CARRIERS,
    payload: response.data,
  });
};
