import {
  Alert,
  CircularProgress,
  Collapse,
  IconButton,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ControlButton from '../../../components/controlButton';
import CustomInput from '../../../components/customInput';
import GroupsDetailTable from '../../../components/groupsTable/groupsTable';
import TmsHeader from '../../../components/header';
import { getScreensList } from '../../../redux/actions/ScreenActions';
import SubHeader from '../../../components/subHeader';
import OptionsButton from '../../../components/optionsButton';
import SingleItemOptions from '../../../components/SingleItemOptions';
import GroupDetailOptions from '../../../components/groupDetailOptions';
import {
  applyAdjustments,
  autoTotalize,
  clearFormData,
  clearFormHeader,
  clearMeterEntryDownloadLink,
  clearMeterEntryExportFile,
  exportMeterEntryColumnsFile,
  getMeterEntryColumnsDownloadLink,
  getMeterEntryRecord,
  globalAutoTotalize,
  saveMeterDetailChanges,
  setFormHeader,
} from '../../../redux/actions/workflows/meterEntry';
import Api from '../../../redux/api/index';
import {
  SET_METER_ENTRY_EXPORT_FILE,
} from '../../../redux/constants/workflows';

interface ITableState {
  tableData: any[];
  currentPage: number;
  searchedDataCount: number;
  searchText: string;
  recordsNumber: string;
  orderBy: string;
  order: string;
  columnsDialogOpen: boolean;
  snackBarOpen: boolean;
}
const GroupsDetailsPage = () => {
  const [clone, setClone] = useState<any>(false);
  const [Values, setValues] = useState([]);
  const [open, setOpen] = useState<any>(false);
  // const [exportOption, setExportOption] = useState({
  //   type: '', spool: false,
  // });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const {
    exportFile, exportLink,
  } = useSelector((state: any) => state.Workflows.MeterEntry);
  const [tableState, setTableState] = useState<ITableState>(() => ({
    tableData: [],
    currentPage: 1,
    searchedDataCount: 0,
    recordsNumber: 'Auto',
    columnsDialogOpen: false,
    snackBarOpen: false,
    searchText: '',
    orderBy: '',
    order: '',
  }));
  const location = useLocation();
  const history = useNavigate();
  const GroupName: any = location.state;
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getScreensList());
  }, [dispatch]);
  const { loading, ScreensList } = useSelector(
    // eslint-disable-next-line comma-dangle
    (state: any) => state.ScreenReducer
  );
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleOpenColumnsDialog = () => {
    setTableState({ ...tableState, columnsDialogOpen: true });
    handleCloseMenu();
  };
  const handleOptionClick = (event: React.MouseEvent<HTMLElement>) => {
    handleOpenColumnsDialog();
    setAnchorEl(event.currentTarget);
  };

  const exportColumns = async (exportOptions: any) => {
    console.log(exportOptions);
    const payload = {
      export_table: 'UIGroup',
      workflow_screen_id: 'WORKFLOW-056',
      filter_name: '',
      // export_data: false,
      file_type: exportOptions.type,
      report_type: 'UIGroup',
      selected_columns: {
        GroupName: 'true',
        ScreenID: 'true',
        access_c: 'true',
        access_r: 'true',
        access_u: 'true',
        access_d: 'true',
        access_x: 'true',
      },
      spool_all_columns: exportOptions.spool,
    };
    const response = await Api.post('exportworkflow/spooldata', payload);
    dispatch({
      type: SET_METER_ENTRY_EXPORT_FILE,
      payload: response?.data?.data?.[0],
    });
  };
  useEffect(() => {
    if (exportFile) {
      dispatch(getMeterEntryColumnsDownloadLink(exportFile));
    }
    return () => {
      dispatch(clearMeterEntryExportFile());
    };
  }, [exportFile]);

  useEffect(() => {
    if (exportLink) {
      const link = document.createElement('a');
      link.href = exportLink;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    return () => {
      dispatch(clearMeterEntryDownloadLink());
    };
  }, [exportLink]);
  return (
    <>
      <TmsHeader />
      <SubHeader title=" Groups: Detail">
        <OptionsButton handleClick={handleOptionClick} />
        <GroupDetailOptions
          clone={clone}
          setClone={setClone}
          setOpen={setOpen}
          exportColumns={exportColumns}
          // exportOption={exportOption}
          // setExportOption={setExportOption}
          anchorEl={anchorEl}
          onClose={handleCloseMenu}
        />
      </SubHeader>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          paddingLeft: '10px',
          gap: 10,
        }}
      >
        <ControlButton
          label="Remove"
          color="red"
          disabled={false}
          handleClick={() => console.log('Remove')}
        />
        <ControlButton
          label="Save"
          color="#00c851"
          disabled={false}
          handleClick={() => console.log('save')}
        />
        <ControlButton
          label="Cancel"
          color="#ffbb33"
          disabled={false}
          handleClick={() => history('/workflow/Groups')}
        />
      </div>
      <Collapse in={open} style={{ minHeight: 50 }}>
        <Alert
          severity="warning"
          action={(
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          )}
          sx={{ mb: 2 }}
        >
          <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
            <Typography style={{ fontWeight: 'bold', color: 'orange' }}>
              Warning!
            </Typography>
            {' '}
            You must change the key fields before saving!
          </div>
        </Alert>
      </Collapse>
      <CustomInput
        label="Group Name"
        name="group"
        defaultValue={GroupName}
        value={GroupName}
        size={1500}
        isDisabled={!clone}
        type="text"
      />
      {loading ? (
        <CircularProgress />
      ) : (
        <GroupsDetailTable rows={ScreensList} />
      )}
    </>
  );
};

export default GroupsDetailsPage;
