/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-nested-ternary */
export const isObject = (o: any) => typeof o === 'object' && !Array.isArray(o) && o !== null;

export const getCellBackgroundColor = (
  orderBy: string | undefined,
  key: string,
  index: number,
): string | undefined => (orderBy === key ? (index % 2 !== 0 ? '#fafafa' : '#f1f1f1') : undefined);

export const concatenateFields = (
  fields: string[],
  fieldsConcatenated: string,
) => {
  const fieldsConcatenatedArray = fieldsConcatenated.split(',');
  const fieldsConcatenatedArraySplitColon = fieldsConcatenatedArray.map(
    (field: string) => field.split(':')[0],
  );
  const fieldsConcatenatedArraySplitEqual = fieldsConcatenatedArraySplitColon.map(
    (field: string) => field.split('=')[0],
  );
  const fieldsConcatenatedObject = fieldsConcatenatedArraySplitEqual.reduce(
    (acc: any, item: any, index: number) => ({
      ...acc,
      [item]: fieldsConcatenatedArray[index],
    }),
    {},
  );
  const concatenatedFields = fields.map(
    (field: string) => fieldsConcatenatedObject?.[field] || field,
  );
  return concatenatedFields.join(',');
};

export const removeFromObject = (obj: any, key: string) => {
  const result = Object.keys(obj).reduce(
    (acc: any, item: any) => (item === key ? { ...acc } : { ...acc, [item]: obj[item] }),
    {},
  );
  return result;
};

export const adaptFilter = (filter: any) => {
  const filterAdapter: any = {
    '<': '<',
    '=': '=',
    '!=': '!=',
    '>': '>',
    like: 'like',
    'not like': 'not like',
  };
  const payload = filter?.map((item: any) => ({
    field: item.ColumnName,
    condition: filterAdapter[item.Compare.toLowerCase()],
    value: item.CompareValue,
  })) || [];
  return payload;
};

// Remove all fields from obj1 that are not in obj2
export const deduplicateFields = (obj1: any, obj2: any) => {
  const obj1Keys = Object.keys(obj1);
  const obj2Keys = Object.keys(obj2);
  const obj2KeysFiltered = obj2Keys.filter(
    (item: any) => !obj1Keys.includes(item),
  );
  const obj2KeysFilteredObj = obj2KeysFiltered.reduce(
    (acc: any, item: any) => ({ ...acc, [item]: obj2[item] }),
    {},
  );
  return { ...obj2KeysFilteredObj };
};

const searchChangeHistory = (record: any, searchText: string, key: any) => String(record?.new_value?.[key])
  ?.toLowerCase()
  ?.includes(searchText?.toLowerCase())
  || String(record?.old_value?.[key])
    ?.toLowerCase()
    ?.includes(searchText?.toLowerCase())
  || String(record?.user_id)?.toLowerCase()?.includes(searchText?.toLowerCase())
  || String(record?.change_datetime)
    ?.toLowerCase()
    ?.includes(searchText?.toLowerCase());

const searchNormal = (record: any, searchText: string, key: any) => String(record[key])?.toLowerCase()?.includes(searchText?.toLowerCase());

export const getRecordToShowValue = (
  recordsNumber: string | number | undefined,
  allRecordsCount: number,
  autoValue: number,
) => (recordsNumber === 'Auto' || !recordsNumber
  ? autoValue
  : recordsNumber === 'All'
    ? allRecordsCount
    : Number(recordsNumber));

export const searchRecords = (
  searchText: string,
  records: any[],
  tableHeader: any[],
  isChangeHistory?: boolean,
) => records.filter((record: any) => tableHeader
  .map((x) => x.key)
  .some((x) => {
    if (searchText.includes('|')) {
      const words = searchText.split('|');
      return words.some((word: string) => {
        if (isChangeHistory) {
          return searchChangeHistory(record, word, x);
        }
        return searchNormal(record, word, x);
      });
    }
    if (isChangeHistory) {
      return searchChangeHistory(record, searchText, x);
    }
    return searchNormal(record, searchText, x);
  }));

export const sortedTable = (data: any[], orderBy?: string, order?: string) => {
  if (!orderBy || !order) return data;
  return data.sort((a, b) => {
    const orderResult: number = String(a[orderBy]).localeCompare(b[orderBy]);
    return order === 'asc' ? orderResult : -orderResult;
  });
};
export const unsetChildValuesOfChain = (
  chainedKeys: string[],
  newValues: any,
  newValuesToSend: any,
) => {
  const resultValues = newValues;
  const resultValuesToSend = newValuesToSend;

  try {
    // Chained dropdowns behavior:
    // Checks if child keys of chained dropdowns should have unset/unselected value
    if (chainedKeys && chainedKeys?.length > 0) {
      let shouldUnsetChainValue = false;
      chainedKeys?.map((key: string) => {
        shouldUnsetChainValue = newValues[key]?.value !== '' && newValues[key]?.value !== undefined;
        if (shouldUnsetChainValue) {
          delete resultValues[key];
          delete resultValuesToSend[key];
        }
        return true;
      });
    }
    return { resultValues, resultValuesToSend };
  } catch (error: any) {
    console.log('handleSelectChange chain error', chainedKeys, error);
    return { resultValues, resultValuesToSend };
  }
};
export const searchMeterRecords = (
  searchText: string,
  records: any[],
  tableHeader: any[],
) => records.filter((record: any) => tableHeader
  .map((x) => x.id)
  .some((x) => String(record[x])?.toLowerCase()?.includes(searchText?.toLowerCase())));

export const searchTankGaugeRecords = (
  searchText: string,
  records: any[],
  tableHeader: any[],
) => records.filter((record: any, index) => tableHeader
  .map((header) => `${header.id}-${index}`)
  .some((header) => {
    const field = record[header];
    if (field?.['\u0000*\u0000parm']) {
      if (field?.['\u0000*\u0000parm']?.date) {
        const date = getTankGaugeFullDate(
          field?.['\u0000*\u0000parm']?.date,
          field?.['\u0000*\u0000parm']?.time,
        );
        return String(date)
          ?.toLowerCase()
          ?.includes(searchText?.toLowerCase());
      }
      return String(field?.['\u0000*\u0000parm']?.value)
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase());
    }
    if (header?.includes('tank_id')) {
      return String(field?.label)
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase());
    }
    return String(field?.value)
      ?.toLowerCase()
      ?.includes(searchText?.toLowerCase());
  }));

export const toMonthName = (monthNumber: string) => {
  if (!monthNumber) return null;
  const date = new Date();
  date.setMonth(Number(monthNumber) - 1);
  return date.toLocaleString('en-US', {
    month: 'long',
  });
};

export const getDateFromString = (date: string) => {
  if (!date) return null;
  const year = `20${date.slice(0, 2)}`;
  const month = date.slice(2, 4);
  const day = date.slice(4);
  return `${month}-${day}-${year}`;
};

export const getViewTraceLogsDateFromString = (date: string) => {
  if (!date) return null;
  return date.replaceAll('-', '/');
};

export const getPGCRDateFromString = (date: string) => {
  if (!date) return null;
  const year = `20${date.slice(0, 2)}`;
  const month = date.slice(2, 4);
  const day = date.slice(4);
  return `${year}/${month}/${day}`;
};

export const getTimeFromString = (time: string) => {
  if (!time) return null;
  const hours = time.slice(0, 2);
  const minutes = time.slice(2);
  return `${hours}:${minutes}:00`;
};

export const getTankGaugeFullDate = (date: string, time: string) => {
  const newDate = getPGCRDateFromString(date);
  const newTime = getTimeFromString(time);
  return `${newDate} ${newTime}`;
};

export const getFullDate = (date: string, time: string) => {
  const newDate = getDateFromString(date);
  const newTime = getTimeFromString(time);
  return `${newDate} ${newTime}`;
};

export const getViewTraceLogsFullDate = (date: string, time: string) => {
  const newDate = getViewTraceLogsDateFromString(date);
  const newTime = getTimeFromString(time);
  return `${newDate} ${newTime}`;
};

export const getFormattedDate = (value: Date | null) => value?.toLocaleDateString('en-US', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
});

export const getTankGaugeEntryServerDate = (value: string | null) => {
  if (!value) return '';
  return value.replaceAll('/', '-').slice(0, -3);
};

export const getPGCRFormattedDate = (value: Date | null) => {
  const formattedDate = value?.toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
  if (!formattedDate) return null;
  const helpArray = formattedDate?.split('/');
  return `${helpArray[2]}/${helpArray[0]}/${helpArray[1]}`;
};

export const getFormattedTime = (value: Date | null) => value?.toLocaleTimeString('en-US', {
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  hour12: false,
});

export const isEmpty = (value: object | string) => value === undefined
  || value === null
  || (typeof value === 'object' && Object.keys(value).length === 0)
  || (typeof value === 'string' && value.trim().length === 0);

export const formatToServerDate = (dateTime: string) => {
  if (!dateTime) return null;
  const retrevedDate = dateTime.split(' ')[0]?.split('-');
  const year = retrevedDate[2].slice(2);
  const month = retrevedDate[0];
  const day = retrevedDate[1];
  return `${year}${month}${day}`;
};
