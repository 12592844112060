import {
  LOAD_DRIVERS_CONTRACT_DISPATCH,
  LOAD_CARRIERS_CONTRACT_DISPATCH,
  LOAD_SHIPPING_METHOD_CONTRACT_DISPATCH,
  LOAD_VEHICLE0_CONTRACT_DISPATCH,
  LOAD_VEHICLE1_CONTRACT_DISPATCH,
  LOAD_VEHICLE2_CONTRACT_DISPATCH,
  LOAD_ROUTE_CODE_CONTRACT_DISPATCH,
  LOAD_PRODUCT_GROUP_CONTRACT_DISPATCH,
  LOAD_LOADING_STATUS_CONTRACT_DISPATCH,
  CLEAR_CONTRACT_DISPATCH,
  LOAD_STAGING_BAY_CONTRACT_DISPATCH,
} from '../../constants/workflows';

const INITIAL_STATE = {
  contractdrivers: [],
  contractcarriers: [],
  contractshippingmethod: {},
  contractvehicl0: [],
  contractvehicl1: [],
  contractvehicl2: [],
  contractroutecode: [],
  contractproductgroup: [],
  contractloadingstatus: [],
  contractstagingbay: [],
};

const contractDispatchReducer = (
  state = INITIAL_STATE,
  { type, payload }: any,
) => {
  switch (type) {
    case LOAD_DRIVERS_CONTRACT_DISPATCH: {
      return {
        ...state,
        contractdrivers: payload,
      };
    }
    case LOAD_CARRIERS_CONTRACT_DISPATCH: {
      return {
        ...state,
        contractcarriers: payload,
      };
    }
    case LOAD_SHIPPING_METHOD_CONTRACT_DISPATCH: {
      return {
        ...state,
        contractshippingmethod: payload,
      };
    }
    case LOAD_VEHICLE0_CONTRACT_DISPATCH: {
      return {
        ...state,
        contractvehicl0: payload,
      };
    }
    case LOAD_VEHICLE1_CONTRACT_DISPATCH: {
      return {
        ...state,
        contractvehicl1: payload,
      };
    }
    case LOAD_VEHICLE2_CONTRACT_DISPATCH: {
      return {
        ...state,
        contractvehicl2: payload,
      };
    }
    case LOAD_STAGING_BAY_CONTRACT_DISPATCH: {
      return {
        ...state,
        contractstagingbay: payload,
      };
    }
    case LOAD_ROUTE_CODE_CONTRACT_DISPATCH: {
      return {
        ...state,
        contractroutecode: payload,
      };
    }
    case LOAD_PRODUCT_GROUP_CONTRACT_DISPATCH: {
      return {
        ...state,
        contractproductgroup: payload,
      };
    }
    case LOAD_LOADING_STATUS_CONTRACT_DISPATCH: {
      return {
        ...state,
        contractloadingstatus: payload,
      };
    }
    case CLEAR_CONTRACT_DISPATCH: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
};

export default contractDispatchReducer;
