/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useState } from 'react';

// Librairies
import {
  ButtonBase,
  Divider,
  Grid,
  InputBase,
  useMediaQuery,
} from '@mui/material';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

// Components
import SubHeader from '../../../components/subHeader';
import TmsHeader from '../../../components/header';
import OptionsButton from '../../../components/optionsButton';
import Input from '../../../components/Input';
import TankGaugeEntryRecordDetailOptions from '../../../components/TankGaugeEntryRecordDetailOptions';
import CheckboxComponent from '../../../components/Checkbox';
import TankGaugeEntryrecordDetailTable from '../../../components/TankGaugeEntryrecordDetailTable';
import DateTimePicker from '../../../components/DateTimePicker';
import WorkflowRecordChangeHistory from '../../../components/WorkflowRecordChangeHistotory';

// Styles
import { useStyles } from './style';

// Actions
import {
  clearTankGaugeEntryFormData,
  clearFormHeader,
  clearTankGaugeEntryDownloadLink,
  clearTankGaugeEntryExportFile,
  exportTankGaugeEntryColumnsFile,
  getTankGaugeEntryColumnsDownloadLink,
  getTankGaugeEntryRecord,
  saveTankGaugeEntryDetailChanges,
  setFormHeader,
  getTankGaugeModalData,
  clearTankGaugeEntryField,
  getAutoTankGaugeData,
  clearTankGaugeEntryCurrentRecord,
} from '../../../redux/actions/workflows/tankGaugeEntry';
import { getScreensList } from '../../../redux/actions/ScreenActions';

// Utils
import {
  getFormattedTime,
  getPGCRFormattedDate,
  getTankGaugeFullDate,
  isEmpty,
} from '../../../utils';
import TankGaugeEntryDialog from '../../../components/TankGageEntryDialog';
import AutoTankGaugeDialog from '../../../components/AutoTankGaugeDialog';
import ConfirmationDialog from '../../../components/ConfirmDialog';

interface IState {
  searchText: string;
  selectedRow: any;
  selectedRowIndex: number | null;
  anchorEl: null | HTMLElement;
  recordChangeHistoryDialogOpen: boolean;
  tankGaugeEntryDialogOpen: boolean;
  autoTankGaugeDialogOpen: boolean;
  showFields: boolean;
  cancelConfirmDialogOpen: boolean;
  updateTankGaugeChecked: boolean;
}

const TankGaugeEntryRecordDetail: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    folioMonth, folioNumber, folioStatus, termID,
  } = useParams();
  const {
    currentRecord,
    selectedRecord,
    exportFile,
    exportLink,
    modalData,
    formData,
  } = useSelector((state: any) => state.Workflows.TankGaugeEntry);
  const isUpdateDisabled = selectedRecord?.folioStatus === 'C' || selectedRecord?.folioStatus === 'M';
  const [tankGaugeRecordState, setTankGaugeRecordState] = useState<IState>(
    () => ({
      searchText: '',
      recordChangeHistoryDialogOpen: false,
      tankGaugeEntryDialogOpen: false,
      selectedRow: null,
      anchorEl: null,
      selectedRowIndex: null,
      autoTankGaugeDialogOpen: false,
      showFields: false,
      cancelConfirmDialogOpen: false,
      updateTankGaugeChecked: false,
    }),
  );
  const headerFormValues = {
    head_ambient_temperature:
      currentRecord?.header?.head_ambient_temperature?.['\u0000*\u0000parm'],
    head_inv_date: currentRecord?.header?.head_inv_date?.['\u0000*\u0000parm'],
  };

  const exportColumns = () => {
    if (folioNumber && folioMonth) {
      dispatch(exportTankGaugeEntryColumnsFile(folioNumber, folioMonth));
    }
    handleCloseMenu();
  };

  useEffect(() => {
    if (exportLink) {
      const link = document.createElement('a');
      link.href = exportLink;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    if (exportFile) {
      dispatch(getTankGaugeEntryColumnsDownloadLink(exportFile));
    }
    return () => {
      dispatch(clearTankGaugeEntryExportFile());
      dispatch(clearTankGaugeEntryDownloadLink());
    };
  }, [exportFile, exportLink]);

  useEffect(
    () => () => {
      dispatch(clearTankGaugeEntryCurrentRecord());
      dispatch(clearTankGaugeEntryFormData());
      dispatch(clearFormHeader());
    },
    [],
  );

  useEffect(() => {
    if (currentRecord?.meterRecords) {
      const updatedRow = currentRecord?.meterRecords[
          tankGaugeRecordState.selectedRowIndex as number
      ];
      setTankGaugeRecordState((prev) => ({ ...prev, selectedRow: updatedRow }));
    }
  }, [currentRecord, tankGaugeRecordState.selectedRowIndex]);

  const handleOpenRecordChangeHistoryDialog = () => {
    setTankGaugeRecordState({
      ...tankGaugeRecordState,
      recordChangeHistoryDialogOpen: true,
      anchorEl: null,
    });
  };

  const handleCloserecordChangeHistoryDialog = () => {
    setTankGaugeRecordState({
      ...tankGaugeRecordState,
      recordChangeHistoryDialogOpen: false,
    });
  };

  const handleCloseTankGaugeEntryDialog = () => {
    setTankGaugeRecordState({
      ...tankGaugeRecordState,
      tankGaugeEntryDialogOpen: false,
    });
  };

  const handleOptionClick = (event: React.MouseEvent<HTMLElement>) => {
    setTankGaugeRecordState({
      ...tankGaugeRecordState,
      anchorEl: event.currentTarget,
    });
  };

  const handleCloseMenu = () => {
    setTankGaugeRecordState({
      ...tankGaugeRecordState,
      anchorEl: null,
    });
  };

  useEffect(() => {
    dispatch(getScreensList());
    dispatch(
      getTankGaugeEntryRecord({
        folioMonth,
        folioNumber,
        folioStatus,
        termID,
      }),
    );
  }, []);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTankGaugeRecordState({
      ...tankGaugeRecordState,
      searchText: e.target.value,
    });
  };

  const handleSave = () => {
    dispatch(saveTankGaugeEntryDetailChanges());
    navigate('/workflow/tank-gauge-entry');
  };

  const handleCancel = () => {
    if (!isEmpty(formData)) {
      setTankGaugeRecordState({
        ...tankGaugeRecordState,
        cancelConfirmDialogOpen: true,
      });
    } else {
      window.location.href = '/workflow/tank-gauge-entry';
    }
  };

  const handleOpenModalData = (row: any, index: number) => {
    dispatch(getTankGaugeModalData(row, index));
    setTankGaugeRecordState({
      ...tankGaugeRecordState,
      tankGaugeEntryDialogOpen: true,
      anchorEl: null,
      selectedRow: row,
      selectedRowIndex: index,
    });
  };

  const handleClearField = (fields: string[]) => {
    dispatch(clearTankGaugeEntryField(fields));
    setTankGaugeRecordState({
      ...tankGaugeRecordState,
      anchorEl: null,
    });
  };

  const openAutoTankGaugeDialog = () => {
    dispatch(
      getAutoTankGaugeData({
        termid: selectedRecord?.term_id.trim(),
        folmo: selectedRecord?.folioMonth,
        folno: selectedRecord?.fol_no,
      }),
    );
    setTankGaugeRecordState({
      ...tankGaugeRecordState,
      autoTankGaugeDialogOpen: true,
      anchorEl: null,
    });
  };

  const refreshAutoTankGaugeDialog = () => {
    dispatch(
      getAutoTankGaugeData({
        termid: selectedRecord?.term_id.trim(),
        folmo: selectedRecord?.folioMonth,
        folno: selectedRecord?.fol_no,
      }),
    );
  };

  const closeAutoTankGaugeDialog = () => {
    setTankGaugeRecordState({
      ...tankGaugeRecordState,
      autoTankGaugeDialogOpen: false,
    });
  };

  const handleShowHideFields = () => {
    setTankGaugeRecordState({
      ...tankGaugeRecordState,
      showFields: !tankGaugeRecordState.showFields,
    });
  };

  const closeCancelConfirmDialog = () => {
    setTankGaugeRecordState({
      ...tankGaugeRecordState,
      cancelConfirmDialogOpen: false,
    });
  };

  const closeAgreeCancelConfirmDialog = () => {
    closeCancelConfirmDialog();
    window.location.href = '/workflow/tank-gauge-entry';
  };

  const handleChangeCheckBox = () => {
    setTankGaugeRecordState({
      ...tankGaugeRecordState,
      updateTankGaugeChecked: !tankGaugeRecordState.updateTankGaugeChecked,
    });
  };

  return (
    <div className="fullHeight flexColumn">
      <TmsHeader />
      <SubHeader
        title={
          selectedRecord
          && `Tank Gauge Entry : Detail - ${selectedRecord?.tt_status} ${selectedRecord?.fol_mo} ${selectedRecord?.fol_no}`
        }
      >
        <OptionsButton handleClick={handleOptionClick} />
        <TankGaugeEntryRecordDetailOptions
          anchorEl={tankGaugeRecordState.anchorEl}
          onClose={handleCloseMenu}
          exportColumns={exportColumns}
          openRecordChangeHistoryDialog={handleOpenRecordChangeHistoryDialog}
          handleClearField={handleClearField}
          openAutoTankGaugeDialog={openAutoTankGaugeDialog}
          isUpdateDisabled={isUpdateDisabled}
        />
      </SubHeader>
      <div>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <ButtonBase
              className={classes.saveButton}
              onClick={handleSave}
              disabled={isUpdateDisabled}
            >
              Save
            </ButtonBase>
            <ButtonBase className={classes.cancleButton} onClick={handleCancel}>
              Cancel
            </ButtonBase>
          </Grid>
          <Grid item xs={8}>
            <CheckboxComponent
              checked={tankGaugeRecordState.updateTankGaugeChecked}
              label="Update Tank Records"
              onChange={() => null}
              onClick={handleChangeCheckBox}
              disabled={isUpdateDisabled}
            />
          </Grid>
        </Grid>
      </div>
      <Divider className={classes.divider} />
      <TankGaugeEntryForm
        values={headerFormValues}
        selectedRecord={selectedRecord}
      />
      <div className={clsx(classes.showHideRecordsContainer)}>
        <ButtonBase
          className={classes.showHideRecords}
          onClick={handleShowHideFields}
        >
          Show / Hide Extra Fields
        </ButtonBase>
      </div>
      <div
        className={clsx('flexStartSpaceBetweenRow', classes.subHeaderContainer)}
      >
        <span className="takeTheRest" />
        <div className="flexRow">
          <InputBase
            className={classes.searchInput}
            value={tankGaugeRecordState.searchText}
            onChange={handleSearchChange}
            placeholder="Search"
          />
          <span className={classes.helperText}>Viewing 0 of 0</span>
        </div>
      </div>
      <TankGaugeEntryrecordDetailTable
        data={currentRecord}
        searchText={tankGaugeRecordState.searchText}
        handleOpenModalData={handleOpenModalData}
        showFields={tankGaugeRecordState.showFields}
      />
      {tankGaugeRecordState.recordChangeHistoryDialogOpen ? (
        <WorkflowRecordChangeHistory
          onClose={handleCloserecordChangeHistoryDialog}
          open={tankGaugeRecordState.recordChangeHistoryDialogOpen}
          historyModalData={currentRecord.historyModal?.historyList?.[0] || []}
          limitNumber={25}
          searchHeader={Object.keys(
            currentRecord?.historyModal?.tableHeaders || [],
          ).map((key) => ({
            key,
            label: currentRecord?.historyModal?.tableHeaders[key],
          }))}
          tableHeader={currentRecord?.historyModal?.tableHeaders || {}}
          tableTitle={currentRecord?.historyModal?.tableTitle || ''}
          tabLabel="Tank Gauge"
          defaultRecordsToShow={15}
        />
      ) : null}
      {tankGaugeRecordState.tankGaugeEntryDialogOpen ? (
        <TankGaugeEntryDialog
          onClose={handleCloseTankGaugeEntryDialog}
          open={tankGaugeRecordState.tankGaugeEntryDialogOpen}
          selectedRow={tankGaugeRecordState.selectedRow}
          selectedRowIndex={tankGaugeRecordState.selectedRowIndex}
          modalData={modalData}
          modalFields={currentRecord.modalFields || {}}
          selectedRecord={selectedRecord}
        />
      ) : null}
      {tankGaugeRecordState.autoTankGaugeDialogOpen ? (
        <AutoTankGaugeDialog
          onClose={closeAutoTankGaugeDialog}
          open={tankGaugeRecordState.autoTankGaugeDialogOpen}
          refreshAutoTankGaugeDialog={refreshAutoTankGaugeDialog}
          isUpdateDisabled={isUpdateDisabled}
        />
      ) : null}
      <ConfirmationDialog
        cancelLabel="Cancel"
        confirmLabel="Yes"
        handleCloseAgree={closeAgreeCancelConfirmDialog}
        handleCloseCancel={closeCancelConfirmDialog}
        message="You have made changes
        on this page. If you leave without
        saving, your changes will be lost. Do you want to continue?"
        open={tankGaugeRecordState.cancelConfirmDialogOpen}
        title="Leave Page"
        helperText="This action cannot be undone."
      />
    </div>
  );
};

const TankGaugeEntryForm: React.FC<{ values: any; selectedRecord: any }> = ({
  values,
  selectedRecord,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const matches = useMediaQuery('(min-width:992px)');
  const [formState, setFormState] = React.useState(() => ({
    head_inv_date: {
      inputValue: '',
      pickerValue: new Date(),
    },
    head_ambient_temperature: '',
  }));
  const isUpdateDisabled = selectedRecord?.folioStatus === 'C' || selectedRecord?.folioStatus === 'M';

  useEffect(() => {
    if (values?.head_inv_date || values?.head_ambient_temperature) {
      const date = getTankGaugeFullDate(
        values?.head_inv_date.date,
        values?.head_inv_date.time,
      );
      setFormState({
        head_inv_date: {
          inputValue: date || '',
          pickerValue: new Date(date),
        },
        head_ambient_temperature: values?.head_ambient_temperature?.value || '',
      });
      dispatch(
        setFormHeader({
          head_inv_date: date,
          head_ambient_temperature: values?.head_ambient_temperature.value,
        }),
      );
    }
  }, [values?.head_inv_date, values?.head_ambient_temperature]);

  const handleDateTimePckerChange = (value: Date | null) => {
    if (value) {
      const formattedDate = getPGCRFormattedDate(value);
      const formattedTime = getFormattedTime(value);
      if (formattedDate && formattedTime) {
        dispatch(
          setFormHeader(
            {
              head_inv_date: `${formattedDate} ${formattedTime}`,
              head_ambient_temperature: formState.head_ambient_temperature,
              change: true,
            },
            'invDate',
          ),
        );
        setFormState({
          ...formState,
          head_inv_date: {
            inputValue: `${formattedDate} ${formattedTime}`,
            pickerValue: value,
          },
        });
      }
    }
  };

  const handleAmbientTemperatureInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setFormState({
      ...formState,
      head_ambient_temperature: event.target.value,
    });
  };

  const handleUserInitialsBlur = () => {
    dispatch(
      setFormHeader(
        {
          head_ambient_temperature: formState.head_ambient_temperature,
          head_inv_date: formState.head_inv_date.inputValue,
          change: true,
        },
        'ambientTemperature',
      ),
    );
  };

  return (
    <form
      className={clsx(classes.form, 'flexColumn')}
      style={{ width: matches ? '50%' : undefined }}
    >
      <div
        className={clsx(
          classes.inputContainer,
          matches ? 'flexRowAlignCenter' : 'flexColumn',
        )}
      >
        <div
          className={clsx(matches && 'flexStartEndColumn', classes.inputLabel)}
          style={{ width: '25%' }}
        >
          Date/Time All
        </div>
        <DateTimePicker
          handleChange={handleDateTimePckerChange}
          inputValue={formState.head_inv_date.inputValue}
          pickerValue={formState.head_inv_date.pickerValue}
          inputStyle={{
            minWidth: matches ? 500 : undefined,
            width: matches ? '75%' : undefined,
          }}
          disabled={isUpdateDisabled}
          required
          shouldDisableTime
        />
      </div>
      <div
        className={clsx(
          classes.inputContainer,
          matches ? 'flexRowAlignCenter' : 'flexColumn',
        )}
      >
        <div
          className={clsx(matches && 'flexStartEndColumn', classes.inputLabel)}
          style={{ width: '25%' }}
        >
          Ambient Temperature All
        </div>
        <Input
          onBlur={handleUserInitialsBlur}
          onChange={handleAmbientTemperatureInputChange}
          value={formState.head_ambient_temperature}
          type="number"
          style={{
            minWidth: matches ? 500 : undefined,
            width: matches ? '75%' : undefined,
          }}
        />
      </div>
    </form>
  );
};

export default TankGaugeEntryRecordDetail;
