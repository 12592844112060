/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
import { Dispatch } from 'redux';
import { formatToServerDate, getFullDate, toMonthName } from '../../../utils';
import Api from '../../api/index';
import {
  CLEAR_METER_ENTRY,
  LOAD_METER_ENTRY,
  LOAD_METER_ENTRY_RECORD,
  SET_SELECTED_METER_RECORD,
  SET_METER_ENTRY_FORM_DATA,
  SET_METER_ENTRY_FORM_HEADER,
  CLEAR_METER_ENTRY_FORM_DATA,
  CLEAR_METER_ENTRY_FORM_HEADER,
  SET_METER_ENTRY_EXPORT_FILE,
  CLEAR_METER_ENTRY_EXPORT_FILE,
  SET_METER_ENTRY_EXPORT_LINK,
  CLEAR_METER_ENTRY_EXPORT_LINK,
  CLEAR_METER_ENTRY_RESPONSE_NOTIFICATION,
  SET_METER_ENTRY_RESPONSE_NOTIFICATION,
  GLOBALLY_SET_METER_ENTRY_FORM_DATA,
  SET_METER_ENTRY_TOTALIZE_DATA,
  CLEAR_METER_ENTRY_TOTALIZE_DATA,
} from '../../constants/workflows';
import { getStatusLabel } from './tankGaugeEntry';

// utils
export const findChangedRowIndex = (row: any, elem: any) => {
  const arr = ['ld_bay', 'ld_ctl', 'ld_mtr', 'm_product'];
  return arr.every((x) => row[x] === elem[x]);
};

export const getMeterEntryData =
  (currentPage = 1, recordsPerPage = 10, searchText = '') =>
    async (dispatch: Dispatch) => {
      const response = await Api.get(
        `workflow056/table?termID=B344&userID=tms&screenID=WORKFLOW-056&currentPage=${currentPage}
    &totalRecordPerPage=${recordsPerPage}${
  searchText && '&Search='
}${searchText}`,
      );
      const data = Object.values(response.data.data);
      const totalRecordsWithSearch = data.pop();
      const totalRecords = data.pop();
      const formattedData = data.map((elem: any) => ({
        ...elem,
        fol_mo: toMonthName(elem.fol_mo),
        freeze_date: getFullDate(elem.freeze_date, elem.freeze_time),
        open_date: getFullDate(elem.open_date, elem.open_time),
        tt_status: getStatusLabel(elem.tt_status),
        status: elem.tt_status,
        folioMonth: elem.fol_mo,
      }));
      dispatch({
        type: LOAD_METER_ENTRY,
        payload: {
          data: formattedData,
          totalRecords,
          totalRecordsWithSearch,
          hiddenColumns: response.data.hiddenColumns,
          visibleColumns: response.data.visibleColumns,
        },
      });
    };

export const setSelectedMeterRecord = (selectedMeterRecord: any) => ({
  type: SET_SELECTED_METER_RECORD,
  payload: selectedMeterRecord,
});

export const setFormData =
  (row: any, newData: any, index: number) =>
    (dispatch: Dispatch, getState: any) => {
      const state = getState();
      const { formData } = state.Workflows.MeterEntry;
      let newRow;
      if (formData[index]) {
        newRow = {
          ...formData[index],
          ...newData,
        };
      } else {
        newRow = { ...row, ...newData };
      }
      dispatch({
        type: SET_METER_ENTRY_FORM_DATA,
        payload: {
          row: newRow,
          index,
        },
      });
    };

export const setFormHeader = (data: any) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_METER_ENTRY_FORM_HEADER,
    payload: data,
  });
};

export const getMeterEntryRecord =
  ({
    termID, folioMonth, folioNumber, folioStatus,
  }: any) =>
    async (dispatch: Dispatch) => {
      const response = await Api.get(
        `workflow056/showrecord?screenID=workflow-056&termID=${termID}
      &folioMonth=${folioMonth}&folioNumber=${folioNumber}&folioStatus=${folioStatus}`,
      );
      const meterRecords = response.data.meterRecords.map(
        (record: any, index: number) => ({ ...record, index }),
      );
      dispatch({
        type: LOAD_METER_ENTRY_RECORD,
        payload: { ...response.data, meterRecords },
      });
    };

export const getNewMeterEntryColumns =
  (columns: string[]) => async (dispatch: Dispatch) => {
    const userID = localStorage.getItem('username');
    await Api.post('workflow056/updatecolumns', { userID, columns });
    window.location.reload();
    dispatch({
      type: CLEAR_METER_ENTRY,
    });
  };

export const clearFormData = () => ({
  type: CLEAR_METER_ENTRY_FORM_DATA,
});

export const clearFormHeader = () => ({
  type: CLEAR_METER_ENTRY_FORM_HEADER,
});

export const saveMeterDetailChanges =
  () => async (dispatch: Dispatch, getState: any) => {
    const state = getState();
    const {
      selectedRecord,
      formData,
      formHeader,
      currentRecord: { meterHeader },
    } = state.Workflows.MeterEntry;
    const formattedOpenDate = formatToServerDate(selectedRecord?.open_date);
    const formattedFreezeDate = formatToServerDate(selectedRecord?.freeze_date);
    const meterHeaderSecondPart = {
      term_id: selectedRecord?.term_id,
      fol_mo: selectedRecord?.folioMonth,
      fol_no: selectedRecord?.folioNumber,
      tt_status: selectedRecord?.status,
      terminal: selectedRecord?.terminal,
      open_date: formattedOpenDate,
      open_time: selectedRecord?.open_time,
      freeze_date: formattedFreezeDate,
      freeze_time: selectedRecord?.freeze_time,
      meterNum: selectedRecord?.meterNum,
    };
    const changedRows = Object.keys(formData).reduce((acc, curr) => {
      const rowObj = formData[curr];
      const newRowObj = Object.keys(rowObj).reduce(
        (a, c) => ({
          ...a,
          [`${c}-${curr}`]: rowObj[c]?.value ? rowObj[c]?.value : rowObj[c],
          [`rowedited-${curr}`]: 'y',
        }),
        {},
      );
      return {
        ...acc,
        ...newRowObj,
      };
    }, {});
    const dataToSend = {
      ...formHeader,
      ...meterHeaderSecondPart,
      ...changedRows,
      origrec: meterHeader?.pop()?.value,
    };
    try {
      const response = await Api.post('workflow056/storeMeterEntryData', {
        form_data: dataToSend,
      });
      if (response.status === 200) {
        dispatch({
          type: SET_METER_ENTRY_RESPONSE_NOTIFICATION,
          payload: { type: 'success' },
        });
      }
    } catch (err: any) {
      dispatch({
        type: SET_METER_ENTRY_RESPONSE_NOTIFICATION,
        payload: { type: 'success' },
      });
    }
  };

export const exportMeterEntryColumnsFile =
  (folioNumber: string, folioMonth: string) => async (dispatch: Dispatch) => {
    const payload = {
      export_table: 'Meter',
      workflow_screen_id: 'WORKFLOW-056',
      filter_name: '',
      export_data: false,
      file_type: 'xlsx',
      report_type: 'Meter',
      selected_columns: {
        term_id: 'false',
      },
      spool_all_columns: true,
      filters: {
        table: 'Meter',
        relation: 'all',
        filter_name: 'equals',
        filters: [
          {
            column: 'folio_no',
            operator: 'equal',
            compare_value: folioNumber,
          },
          {
            column: 'folio_mo',
            operator: 'equal',
            compare_value: folioMonth,
          },
        ],
      },
    };
    const response = await Api.post('exportworkflow/spooldata', payload);
    dispatch({
      type: SET_METER_ENTRY_EXPORT_FILE,
      payload: response.data.data[0],
    });
  };

export const getMeterEntryColumnsDownloadLink =
  (exportFile: string) => async (dispatch: Dispatch) => {
    let attempts = 0;
    const downloadFn = () => {
      if (attempts === 10) return;
      Api.get(`export/download-file?file=${exportFile}`)
        .then((res: any) => {
          if (res.status === 200) {
            dispatch({
              type: SET_METER_ENTRY_EXPORT_LINK,
              payload: res.data,
            });
          } else {
            setTimeout(downloadFn, 100);
            attempts += 1;
          }
        })
        .catch(() => {
          setTimeout(downloadFn, 100);
          attempts += 1;
        });
    };
    if (attempts === 10) return;
    downloadFn();
  };

export const globalAutoTotalize =
  () => async (dispatch: Dispatch, getState: any) => {
    const {
      currentRecord: { meterRecords: data },
    } = getState().Workflows.MeterEntry;
    const allRowsArray = data.map((field: any, index: number) => ({
      rowIndex: String(index),
      termId: field.term_id.trim(),
      bay: field.ld_bay.trim(),
      preset: field.ld_ctl.trim(),
      meter: field.ld_mtr.trim(),
      totalizer: '',
    }));
    const payload = {
      data: {
        cmd: 'meterentry',
        parm: {
          isGlobal: true,
          cmd: 'meterentry',
          command: 'meterentry',
          allRowsArray,
        },
      },
    };
    const response = await Api.post('synajax/procajax', payload);
    const returnData = response.data[0].allRowsArray;
    const formData: any = {};
    data.forEach((field: any, index: number) => {
      formData[index] = {
        ...field,
        end_meter: returnData[index].totalizer || '',
        thruput: returnData[index].totalizer || '',
      };
    });
    dispatch({
      type: GLOBALLY_SET_METER_ENTRY_FORM_DATA,
      payload: formData,
    });
  };

export const autoTotalize =
  (row: any, index: number) =>
    async (dispatch: Dispatch, getState: any) => {
      const {
        formData,
        currentRecord: { meterRecords: data },
      } = getState().Workflows.MeterEntry;
      const allRowsArray = [
        {
          rowIndex: String(index),
          termId: row.term_id.trim(),
          bay: row.ld_bay.trim(),
          preset: row.ld_ctl.trim(),
          meter: row.ld_mtr.trim(),
          totalizer: '',
        },
      ];
      const payload = {
        data: {
          cmd: 'meterentry',
          parm: {
            isGlobal: false,
            cmd: 'meterentry',
            command: 'meterentry',
            allRowsArray,
          },
        },
      };
      const response = await Api.post('synajax/procajax', payload);
      const newEndMeterValue = response.data[0].allRowsArray[0].totalizer || '';
      // let newCurrentMeterRecord: any;
      // if (formData[index]) {
      //   newCurrentMeterRecord = {
      //     ...formData[index],
      //     end_meter: newEndMeterValue,
      //   };
      // } else {
      //   newCurrentMeterRecord = {
      //     ...data[index as number],
      //     end_meter: newEndMeterValue,
      //   };
      // }
      dispatch({
        type: SET_METER_ENTRY_TOTALIZE_DATA,
        payload: newEndMeterValue,
      });
    };

export const applyAdjustments =
  (
    row: any,
    adjustmentsSum: number,
    adjustmentsObject: { [key: string]: string },
    endMeter: string,
    autoTotalized: boolean,
    rowIndex: any,
  ) =>
    (dispatch: Dispatch, getState: any) => {
      const { formData } = getState().Workflows?.MeterEntry;
      const newRow = formData[rowIndex] ? { ...formData[rowIndex] } : { ...row };
      newRow.adjustment = String(adjustmentsSum);
      newRow.thruput = !autoTotalized
        ? adjustmentsSum + Number(endMeter)
        : Number(endMeter);
      newRow.end_meter = endMeter;
      if (adjustmentsObject) {
        Object.keys(adjustmentsObject).forEach((key) => {
          newRow[key] = adjustmentsObject[key];
        });
      }
      dispatch({
        type: SET_METER_ENTRY_FORM_DATA,
        payload: {
          row: newRow,
          index: rowIndex,
        },
      });
    };

export const clearMeterEntryExportFile = () => ({
  type: CLEAR_METER_ENTRY_EXPORT_FILE,
});

export const clearMeterEntryDownloadLink = () => ({
  type: CLEAR_METER_ENTRY_EXPORT_LINK,
});

export const clearMeterEntryNotification = () => ({
  type: CLEAR_METER_ENTRY_RESPONSE_NOTIFICATION,
});

export const clearMeterEntryTotalizeData = () => ({
  type: CLEAR_METER_ENTRY_TOTALIZE_DATA,
});
