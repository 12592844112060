import {
  LOAD_PRODUCT_GROUP_CROSS_REFERENCE,
  CLEAR_PRODUCT_GROUP_CROSS_REFERENCE,
  SET_PGCR_EXPORT_FILE,
  CLEAR_PGCR_EXPORT_FILE,
  SET_PGCR_EXPORT_LINK,
  CLEAR_PGCR_EXPORT_LINK,
  LOAD_PGCR_RECORD,
  CLEAR_PGCR_FORM_HEADER,
  SET_PGCR_FORM_HEADER,
  SET_PGCR_RESPONSE_NOTIFICATION,
  CLEAR_PGCR_RESPONSE_NOTIFICATION,
} from '../../constants/workflows';

const INITIAL_STATE = {
  data: [],
  headerList: {},
  currentRecord: {},
  formHeader: {},
  hiddenColumns: {},
  visibleColumns: {},
  exportFile: '',
  exportLink: '',
  responseNotification: {},
};

const productGroupCrossReferenceReducer = (state = INITIAL_STATE, { type, payload }: any) => {
  switch (type) {
    case LOAD_PRODUCT_GROUP_CROSS_REFERENCE: {
      return {
        ...state,
        data: payload.data,
        headerList: payload.headerList,
        hiddenColumns: payload.hiddenColumns,
        visibleColumns: payload.visibleColumns,
      };
    }
    case CLEAR_PRODUCT_GROUP_CROSS_REFERENCE: {
      return {
        ...state,
        data: [],
        headerList: {},
        hiddenColumns: {},
        visibleColumns: {},
      };
    }
    case SET_PGCR_FORM_HEADER: {
      return {
        ...state,
        formHeader: {
          ...state.formHeader,
          ...payload,
        },
      };
    }
    case CLEAR_PGCR_FORM_HEADER: {
      return {
        ...state,
        formHeader: {},
      };
    }
    case LOAD_PGCR_RECORD: {
      return {
        ...state,
        currentRecord: payload,
      };
    }
    case SET_PGCR_RESPONSE_NOTIFICATION: {
      return {
        ...state,
        responseNotification: payload,
      };
    }
    case CLEAR_PGCR_RESPONSE_NOTIFICATION: {
      return {
        ...state,
        responseNotification: {},
      };
    }
    case SET_PGCR_EXPORT_FILE: {
      return {
        ...state,
        exportFile: payload,
      };
    }
    case CLEAR_PGCR_EXPORT_FILE: {
      return {
        ...state,
        exportFile: '',
      };
    }
    case SET_PGCR_EXPORT_LINK: {
      return {
        ...state,
        exportLink: payload,
      };
    }
    case CLEAR_PGCR_EXPORT_LINK: {
      return {
        ...state,
        exportLink: '',
      };
    }
    default:
      return state;
  }
};

export default productGroupCrossReferenceReducer;
