export const LOAD_SYSTEM_STARTUP = 'LOAD_SYSTEM_STARTUP';
export const LOAD_SYSTEM_STARTUP_OPTIONS = 'LOAD_SYSTEM_STARTUP_OPTIONS';
export const SET_SYSTEM_STARTUP_FORM_DATA = 'SET_SYSTEM_STARTUP_FORM_DATA';
export const SET_SYSTEM_STARTUP_DATA = 'SET_SYSTEM_STARTUP_DATA';
export const SET_SYSTEM_STARTUP_RCH = 'SET_SYSTEM_STARTUP_RCH';
export const SET_SYSTEM_STARTUP_STATUS = 'SET_SYSTEM_STARTUP_STATUS';
export const CLEAR_SYSTEM_STARTUP_STATUS = 'CLEAR_SYSTEM_STARTUP_STATUS';
export const CLEAR_SYSTEM_STARTUP_FORM_DATA = 'CLEAR_SYSTEM_STARTUP_FORM_DATA';
export const CLEAR_SYSTEM_STARTUP = 'CLEAR_SYSTEM_STARTUP';
export const CLEAR_SYSTEM_STARTUP_RCH = 'CLEAR_SYSTEM_STARTUP_RCH';
