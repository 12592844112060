import React from 'react';
import { Box, SelectChangeEvent, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { v4 as uuidv4 } from 'uuid';
import CustomDropdown from '../customDropdown';
import CustomInput from '../customInput';
import Plcconfig from '../plcconfigComponent/plcconfig';
import CustomRcuscreen from '../customTextArea/customTextArea';
import Weekday from '../customDropdown/Weekday';
import LoadDataGrid from '../LoadDataGrid/LoadDataGrid';
import CustomField from '../CustomField/CustomField';

const CloneRecordBody = (props: any) => {
  const {
    dataTabs, handleSelectChange, handleInputChange, values, compare, valuesToSend,
  } = props;
  console.log('dataTabs====', props);
  const Ids = Object.entries(dataTabs)
    .map((elt: any) => elt[1].map((element: any) => [element.id, element.valForm]))
    .flat();
  const mapData = new Map(Ids);
  const arrayToObject = Object.fromEntries(mapData);
  const [indice, setIndice] = React.useState('0');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setIndice(newValue);
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1', paddingBottom: '30px' }}>
      <TabContext value={indice}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            {Object.keys(dataTabs).map((tabInfo: any, idx: any) => (
              <Tab label={tabInfo} value={idx.toString()} />
            ))}
          </TabList>
        </Box>
        {Object.entries(dataTabs).map((tabInfo: any, index: any) => tabInfo[1].map((val: any) => (
          <TabPanel sx={{ padding: '0px' }} value={index.toString()}>
            <CustomField
              val={val}
              values={values}
              valuesToSend={valuesToSend}
              handleInputChange={handleInputChange}
              handleSelectChange={handleSelectChange}
              size={1000}
            />
          </TabPanel>
        )))}
      </TabContext>
    </Box>
  );
};

export default CloneRecordBody;
