import { Dispatch } from 'redux';
import Api from '../../api/index';
import { STOP_LOADING } from '../../constants/Screens';
import {
  LOAD_EMCS_CONFIGURATION, UPDATE_EMCS_CONFIGURATION, DISSMISS_EMCS_CONFIGURATION_ALERT,
} from '../../constants/workflows';

export const updateEMCSConfiguration = (emcsConfig: unknown) => async (dispatch: Dispatch) => {
  try {
    const response = await Api.post(
      'workflow137/saveconfiguration',
      { form_data: emcsConfig },
    );

    dispatch({
      type: UPDATE_EMCS_CONFIGURATION,
      payload: response.data,
    });
  } catch (error: any) {
    console.log('updateEMCSConfiguration', error);
  }
};

export const getEMCSConfiguration = () => async (dispatch: Dispatch) => {
  try {
    const response = await Api.get('workflow137/loadconfig');

    dispatch({
      type: LOAD_EMCS_CONFIGURATION,
      payload: response.data,
    });
    dispatch({ type: STOP_LOADING });
  } catch (error: any) {
    console.log('getEMCSConfiguration', error);
  }
};

export const dissmissEMCSConfigurationAlert = () => async (dispatch: any) => {
  try {
    dispatch({ type: DISSMISS_EMCS_CONFIGURATION_ALERT, payload: false });
  } catch (error: any) {
    console.log('dissmissEMCSConfigurationAlert', error);
  }
};
