import React, { useEffect, useState } from 'react';

// Libraries
import {
  ButtonBase, DialogActions, Divider, Typography,
} from '@mui/material';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { getFormattedDate, getFormattedTime } from '../../utils';

// Components
import { DialogComponent } from '../Dialog/Dialog.component';

// Styles
import { useStyles } from './style';
import Select from '../Select';
import { getCarriersList, getDriversList } from '../../redux/actions/workflows/driversInFacility';
import Api from '../../redux/api/index';
import { getCarriers, getDrivers } from '../../redux/actions/workflows/DriversThroughFacility';
import CustomDateTimePicker from '../DateTimePicker';

interface IProps {
  onClose: () => void;
  open: boolean;
  modalActions:any[];
  modalShipping:any[];
  modalBay:any[];
  modalDispatch:any[];
  modalRouteCode:any[];
}

const DriversThroughFacilityDialog: React.FC<IProps> = ({
  onClose,
  open,
  modalActions,
  modalShipping,
  modalBay,
  modalDispatch,
  modalRouteCode,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { reclist, modalDriversList, modalCarriersList } = useSelector(
    (state: any) => state.Workflows.DriversInFacility,
  );
  const { dtfdrivers, dtfcarriers } = useSelector(
    (state: any) => state.Workflows.DriversThroughFacility,
  );
  const [modalState, setModalState] = useState(() => ({
    action: modalActions[0],
    terminalId: { label: '', value: '' },
    driver: { name: '', driver_no: '' },
    carrier: { name: '', carr_no: '' },
    shippingMethod: { value: '', shippingMethod: '' },
    vehicle1: { name: '', vehicle1: '' },
    vehicle2: { name: '', vehicle2: '' },
    vehicle3: { name: '', vehicle3: '' },
    dispatch: { value: '', label: '' },
    bay: { label: '', value: '', selected: false },
    route_code: { label: '', value: '', selected: false },
  }));
  const [parkDateTime, setparkDateTime] = useState<any>({});

  const handleOptionChange = (option: any, name: string) => {
    setModalState({ ...modalState, [name]: option });
  };

  useEffect(() => {
    dispatch(getDrivers(modalState.terminalId.value));
  }, [modalState.terminalId.value]);

  useEffect(() => {
    dispatch(getCarriers(modalState.terminalId.value, modalState.driver.driver_no));
  }, [modalState.driver.driver_no]);

  const handleDateTimePckerChange = (value: Date | null) => {
    const formattedDate = getFormattedDate(value);
    const formattedTime = getFormattedTime(value);
    setparkDateTime({
      inputValue: `${formattedDate} ${formattedTime}`,
      pickerValue: value,
    });
  };

  return (
    <DialogComponent
      title="Driver Through Facility Options"
      onClose={onClose}
      open={open}
    >
      <div className={classes.dialogBody}>
        <div className={clsx('flexRow', classes.wrapper)}>
          <Typography className={classes.label}>Action</Typography>
          <Select
            onChange={(e, option) => handleOptionChange(option, 'action')}
            value={modalState.action}
            options={modalActions}
            width="75%"
            style={{ margin: '2px 10px' }}
          />
        </div>
        <Divider variant="middle" />
        <div className={clsx('flexRow', classes.wrapper)}>
          <Typography className={classes.label}>Terminal ID</Typography>
          <Select
            onChange={(e, option) => handleOptionChange(option, 'terminalId')}
            value={modalState.terminalId}
            options={[
              { label: 'B344 : TOPTECH (LONGWOOD, FL)', value: 'B344' },
            ]}
            width="75%"
            style={{ margin: '2px 10px' }}
          />
        </div>
        <div className={clsx('flexRow', classes.wrapper)}>
          <Typography className={classes.label}>Driver</Typography>
          <Select
            onChange={(e, option) => handleOptionChange(option, 'driver')}
            value={modalState.driver}
            options={dtfdrivers}
            width="75%"
            style={{ margin: '2px 10px' }}
            getOptionLabel={(option) => (option.driver_no && option.name
              ? `${option.driver_no} : ${option.name}`
              : '')}
          />
        </div>
        {modalState.action.value === 'force-entry' || modalState.action.value === 'force-exit' ? (
          <>
            {' '}
            <div className={clsx('flexRow', classes.wrapper)}>
              <Typography className={classes.label}>Carrier</Typography>
              <Select
                onChange={(e, option) => handleOptionChange(option, 'carrier')}
                value={modalState.carrier}
                options={dtfcarriers}
                width="75%"
                style={{ margin: '2px 10px' }}
                getOptionLabel={(option) => (option.carr_no && option.name
                  ? `${option.carr_no} : ${option.name}`
                  : '')}
              />
            </div>
            <div className={clsx('flexRow', classes.wrapper)}>
              <Typography className={classes.label}>Shipping Method</Typography>
              <Select
                onChange={(e, option) => handleOptionChange(option, 'shippingMethod')}
                value={modalState.shippingMethod}
                options={modalShipping}
                width="75%"
                style={{ margin: '2px 10px' }}
                getOptionLabel={(option) => (option.shippingMethod && option.value
                  ? `${option.value} : ${option.shippingMethod}`
                  : '')}
              />
            </div>
            <div className={clsx('flexRow', classes.wrapper)}>
              <Typography className={classes.label}>Vehicle #1</Typography>
              <Select
                onChange={(e, option) => handleOptionChange(option, 'vehicle1')}
                value={modalState.vehicle1}
                options={[]}
                width="75%"
                style={{ margin: '2px 10px' }}
                getOptionLabel={(option) => (option.vehicle1 && option.name
                  ? `${option.vehicle1} : ${option.name}`
                  : '')}
              />
            </div>
            <div className={clsx('flexRow', classes.wrapper)}>
              <Typography className={classes.label}>Vehicle #2</Typography>
              <Select
                onChange={(e, option) => handleOptionChange(option, 'vehicle2')}
                value={modalState.vehicle2}
                options={[]}
                width="75%"
                style={{ margin: '2px 10px' }}
                getOptionLabel={(option) => (option.vehicle2 && option.name
                  ? `${option.vehicle2} : ${option.name}`
                  : '')}
              />
            </div>
            <div className={clsx('flexRow', classes.wrapper)}>
              <Typography className={classes.label}>Vehicle #3</Typography>
              <Select
                onChange={(e, option) => handleOptionChange(option, 'vehicle3')}
                value={modalState.vehicle3}
                options={[]}
                width="75%"
                style={{ margin: '2px 10px' }}
                getOptionLabel={(option) => (option.vehicle3 && option.name
                  ? `${option.vehicle3} : ${option.name}`
                  : '')}
              />
            </div>
          </>
        ) : ' '}
        {modalState.action.value === 'dispatch' ? (
          <>
            {' '}
            <div className={clsx('flexRow', classes.wrapper)}>
              <Typography className={classes.label}>Dispatch</Typography>
              <Select
                onChange={(e, option) => handleOptionChange(option, 'dispatch')}
                value={modalState.dispatch}
                options={modalDispatch}
                width="75%"
                style={{ margin: '2px 10px' }}
                getOptionLabel={(option) => (option.label && option.value
                  ? `${option.value} : ${option.label}`
                  : '')}
              />
            </div>
          </>
        ) : ' '}
        {modalState.action.value === 'force-stage' ? (
          <>
            {' '}
            <div className={clsx('flexRow', classes.wrapper)}>
              <Typography className={classes.label}>Bay</Typography>
              <Select
                onChange={(e, option) => handleOptionChange(option, 'bay')}
                value={modalState.bay}
                options={modalBay}
                width="75%"
                style={{ margin: '2px 10px' }}
                getOptionLabel={(option) => (option.label && option.value
                  ? `${option.label}`
                  : '')}
              />
            </div>
          </>
        ) : ' '}
        {modalState.action.value === 'change-park-time' ? (
          <>
            {' '}
            <div className={clsx('flexRow', classes.wrapper)}>
              <Typography className={classes.label}>Parking Added Date/Time</Typography>
              <CustomDateTimePicker
                handleChange={(value) => handleDateTimePckerChange(value)}
                inputValue={parkDateTime?.inputValue}
                pickerValue={parkDateTime?.pickerValue}
                inputStyle={{ width: '75%', marginRight: '10px' }}
              />
            </div>
          </>
        ) : ' '}
        {modalState.action.value === 'print-bol' ? (
          <>
            {' '}
            <div className={clsx('flexRow', classes.wrapper)}>
              <Typography className={classes.label}>Route Code</Typography>
              <Select
                onChange={(e, option) => handleOptionChange(option, 'route_code')}
                value={modalState.route_code}
                options={modalRouteCode}
                width="75%"
                style={{ margin: '2px 10px' }}
                getOptionLabel={(option) => (option.label && option.value
                  ? `${option.label}`
                  : '')}
              />
            </div>
          </>
        ) : ' '}
      </div>
      <Divider variant="middle" />
      <DialogActions>
        <ButtonBase classes={{ root: classes.applyButton }} disabled>
          Apply
        </ButtonBase>
        <ButtonBase classes={{ root: classes.closeButton }} onClick={onClose}>
          Close
        </ButtonBase>
      </DialogActions>
    </DialogComponent>
  );
};

export default DriversThroughFacilityDialog;
