import React from 'react';
import { useSelector } from 'react-redux';
import WorkflowTable from '../../../components/WorkflowTable/index';
import WorkflowTableUtils from '../../../components/WorkflowTableUtils/index';
import { useWorkflowState } from '../../../hooks/useWorkflowState';
import { AboutPageState } from '../../../redux/reducers/workflows/aboutPage';

const tableHeader = {
  time: 'Install Time',
  user: 'User',
  version: 'Product Version',
  action: 'Action',
  package: 'Package',
};
const searchHeader = [
  { label: tableHeader.time, key: 'time' },
  { label: tableHeader.user, key: 'user' },
  { label: tableHeader.version, key: 'version' },
  { label: tableHeader.action, key: 'action' },
  { label: tableHeader.package, key: 'package' },
];
export const UpgradeHistory: React.FC = () => {
  const { data } = useSelector<any, AboutPageState>((state) => state.Workflows.AboutPage);
  const {
    recordsToShow,
    tableState,
    handlePaginationChange,
    handleSearchChange,
    handleSelectChange,
    handleSortTable,
  } = useWorkflowState(searchHeader, data.installedPackages, null);

  const handleClickRow = () => {
    // do nothing
  };
  return (
    <>
      <h4>Installed Packages / Upgrade History</h4>
      <WorkflowTableUtils
        handleSearchChange={handleSearchChange}
        handleSelectChange={handleSelectChange}
        recordsNumber={tableState.recordsNumber}
        searchText={tableState.searchText}
      />
      <WorkflowTable
        sortTable={handleSortTable}
        handleClickRow={handleClickRow}
        handlePaginationChange={handlePaginationChange}
        visibleColumns={tableHeader}
        allDataCount={data.installedPackages.length}
        recordsToShow={recordsToShow}
        tableHeader={tableHeader}
        tableState={tableState}
        order={tableState.order}
        orderBy={tableState.orderBy}
        searchText={tableState.searchText}
      />
    </>
  );
};
