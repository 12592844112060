import React from 'react';

// Librairies
import { Divider, Menu, MenuItem } from '@mui/material';

// Styles
import { useStyles } from './style';

interface IProps {
  anchorEl: any;
  onClose: () => void;
  exportColumns: () => void;
  fetchGlobalAutoTotalize: () => void;
  openRecordChangeHistory: () => void;
  isUpdateDisabled: boolean;
}

const MeterEntryRecordDetailOptions: React.FC<IProps> = ({
  anchorEl,
  onClose,
  exportColumns,
  fetchGlobalAutoTotalize,
  openRecordChangeHistory,
  isUpdateDisabled,
}) => {
  const classes = useStyles();
  return (
    <Menu
      classes={{ root: classes.root, list: classes.list, paper: classes.paper }}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
    >
      <MenuItem
        classes={{ root: classes.menuItem }}
        onClick={fetchGlobalAutoTotalize}
        disabled={isUpdateDisabled}
      >
        Global Auto Totalize
      </MenuItem>
      <Divider />
      <MenuItem classes={{ root: classes.menuItem }} onClick={exportColumns}>
        Export Columns
      </MenuItem>
      <MenuItem
        classes={{ root: classes.menuItem }}
        onClick={openRecordChangeHistory}
      >
        Record Change History...
      </MenuItem>
    </Menu>
  );
};

export default MeterEntryRecordDetailOptions;
