/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';

// Librairies
import { Grid, InputBase, Typography } from '@mui/material';
import { ArrowUpward } from '@mui/icons-material';
// import Api from '../../../../redux/api/index';
// Styles
import { useNavigate } from 'react-router-dom';
import { useStyles } from './style';
// import ConfirmationDialog from '../../../ConfirmDialog';

interface IProps {
  ShowBayStatus: any
}

const RackStatusHeader: React.FC<IProps> = ({ ShowBayStatus }) => {
  const classes = useStyles();
  const history = useNavigate();
  function cssToObj(css: string) {
    const obj: any = {};
    const s = css.toLowerCase().replace(/-(.)/g, (_m, g) => g.toUpperCase()).replace(/;\s?$/g, '').split(/:|;/g);
    for (let i = 0; i < s.length; i += 2) obj[s[i].replace(/\s/g, '')] = s[i + 1].replace(/^\s+|\s+$/g, '');
    return obj;
  }
  const style = ShowBayStatus?.bayStatusStyle && {
    ...cssToObj(ShowBayStatus?.bayStatusStyle),
  };
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <div className={classes.root} style={style}>
            <div
              onClick={() => history('/workflow/rack-status')}
              className={classes.arrow}
            >
              <ArrowUpward />
            </div>
            <Typography variant="h4" className={classes.heading}>{ShowBayStatus?.bayStatus}</Typography>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default RackStatusHeader;
