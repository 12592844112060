/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
import { InputBase } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import ReactInputMask from 'react-input-mask';
import { useStyles } from './style';

interface IMaskInputProps {
  value?: any;
  style?: React.CSSProperties;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  required?: boolean;
  type?: string;
  disabled?: boolean;
  noBorder?: boolean;
  placeholder?: string;
  name?: string;
  maxLength?: number;
  minLength?: number;
  mask: string;
}

const MaskInput = (props: IMaskInputProps) => {
  const {
    mask,
    onChange,
    value,
    name,
    disabled,
    maxLength,
    minLength,
    noBorder,
    onBlur,
    placeholder,
    required,
    style,
    type,
  } = props;
  const classes = useStyles();

  return (
    <ReactInputMask
      mask={mask}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      placeholder={placeholder}
      disabled={disabled}
    >
      {(maskProps: any) => (
        <InputBase
          {...maskProps}
          classes={{
            root: required
              ? clsx(
                classes.inputRequired,
                !noBorder && !value ? classes.borderRequired : classes.border,
              )
              : noBorder
                ? classes.simpleInput
                : classes.input,
          }}
          style={{
            ...style,
            backgroundColor: disabled ? '#F4F4F4' : undefined,
          }}
          type={type || 'text'}
          name={name}
          componentsProps={{ input: { maxLength, minLength } }}
          disabled={disabled}
          endAdornment={
            maxLength ? (
              <span>
                {(value as string)?.length}
                /
                {maxLength}
              </span>
            ) : null
          }
        />
      )}
    </ReactInputMask>
  );
};

export default MaskInput;
