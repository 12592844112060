import React from 'react';

// Librairies
import {
  ButtonBase, Divider,
} from '@mui/material';

// Styles
import { useStyles } from './style';

const ButtonsHeader: React.FC = () => {
  const classes = useStyles();
  return (
    <>
      <div>
        <ButtonBase
          className={classes.saveButton}
        >
          Save
        </ButtonBase>
        <ButtonBase className={classes.cancleButton}>
          Cancel
        </ButtonBase>
        <ButtonBase className={classes.removeButton}>
          Remove
        </ButtonBase>
      </div>
      <Divider className={classes.divider} />
    </>
  );
};

export default ButtonsHeader;
