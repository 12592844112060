import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {},
  firstGroup: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
  },
  inputGroup: {
    display: 'flex',
    gap: 5,
    marginBottom: 5,
  },
  header: {
    width: '100%',
    backgroundColor: '#eee',
    margin: '5px 0',
    padding: '5px 0',
  },
  wrapper: { padding: '5px 10px 5px 10px', justifyContent: 'flex-end' },
  paper: {
    margin: '0 !important',
    width: '100% !important',
    zIndex: '5 !important',
  },
  modalBody: {
    padding: '5px 10px 0 10px',
  },
  title: {
    padding: '0 5px 0 5px',
    fontSize: 22,
    letterSpacing: -1,
    display: 'block',
    color: '#666',
    margin: '0 0 5px 0',
    fontWeight: 100,
  },
  modalHeader: {
    backgroundColor: '#d2d2d2',
    padding: 15,
    borderBottom: '1px solid #e5e5e5',
    display: 'flex',
  },
  modalTitle: {
    margin: 0,
    fontSize: 18,
    fontWeight: 400,
  },
  form: {},
  label: {
    width: '25%',
    textAlign: 'right',
    paddingTop: 5,
    paddingRight: 5,
    fontWeight: 400,
    fontSize: 12,
    lineHeight: 1.42857143,
  },
  modalActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 5,
    marginTop: 0,
    padding: 10,
    borderTop: '1px solid #e5e5e5',
  },
  modalPrinter: {
    borderTop: '0px solid #e5e5e5',
  },
  generateButton: {
    appearance: 'button',
    background:
      'rgb(221, 221, 221) none repeat scroll 0% 0% / auto padding-box border-box',
    borderTop: '1px solid rgb(53, 170, 71)',
    borderRight: '1px solid rgb(53, 170, 71)',
    borderBottom: '1px solid rgb(53, 170, 71)',
    borderLeft: '5px solid rgb(53, 170, 71)',
    font: '12px / 17.1429px "Open Sans"',
    padding: '4px 10px',
    cursor: 'pointer',
  },
  translate: {
    transform: 'translate(-8px,2px)',
  },
  small: {
    caretColor: 'rgb(119, 119, 119)',
    color: 'rgb(119, 119, 119)',
    columnRuleColor: 'rgb(119, 119, 119)',
    perspectiveOrigin: '0px 0px',
    pointerEvents: 'all',
    textDecoration: 'none solid rgb(119, 119, 119)',
    textEmphasisColor: 'rgb(119, 119, 119)',
    textSizeAdjust: '100%',
    transformOrigin: '0px 0px',
    border: '0px none rgb(119, 119, 119)',
    font: '13.5px / 13.5px "Open Sans"',
    outline: 'rgb(119, 119, 119) none 0px',
    transform: 'translateY(9px)',
  },
  input: {
    width: '100%',
  },
  divider: {
    marginTop: '10px !important',
    marginBottom: '5px !important',
  },
});
