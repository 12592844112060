import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {},
  gridWrapper: {
    margin: '0px !important',
    padding: '0px !important',
  },
  papperContent: {
    padding: '5px 10px 0 10px',
    maxHeight: 500,
    overflow: 'auto',
  },
  header: {
    width: '100%',
    backgroundColor: '#eee',
    margin: '5px 0',
    padding: '5px 0',
  },
  paper: {
    margin: '0px 5px !important',
    position: 'absolute',
    width: '99% !important',
    zIndex: '5 !important',
  },
  modalBody: {
    padding: '5px 10px 0 10px',
  },
  title: {
    padding: '0 5px 0 5px',
    fontSize: 22,
    letterSpacing: -1,
    display: 'block',
    color: '#666',
    margin: '0 0 5px 0',
    fontWeight: 100,
  },
  modalHeader: {
    backgroundColor: '#d2d2d2',
    padding: 15,
    borderBottom: '1px solid #e5e5e5',
  },
  modalTitle: {
    margin: 0,
    fontSize: 18,
    fontWeight: 400,
  },
  label: {
    width: '25%',
    textAlign: 'right',
    paddingTop: 5,
    paddingRight: 5,
    fontWeight: 400,
    fontSize: 12,
    lineHeight: 1.42857143,
  },
  modalActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 5,
    marginTop: 0,
    padding: 10,
    borderTop: '1px solid #e5e5e5',
  },
  applyButton: {
    color: 'black !important',
    textShadow: 'none !important',
    backgroundColor: '#00C851 !important',
    border: '1px solid transparent !important',
    padding: '6px 10px !important',
    '&:hover': {
      backgroundColor: '#007E33 !important',
      color: '#fff !important',
    },
    '&:disabled': {
      color: '#555 !important',
      backgroundColor: '#bcbcbc !important',
      border: '1px solid transparent !important',
      cursor: 'not-allowed',
    },
  },
  cancleButton: {
    color: 'black !important',
    textShadow: 'none !important',
    backgroundColor: '#ffbb33 !important',
    border: '1px solid transparent !important',
    padding: '6px 10px !important',
    '&:hover': {
      backgroundColor: '#FF8800 !important',
      color: '#fff !important',
    },
    '&:disabled': {
      color: '#555 !important',
      backgroundColor: '#bcbcbc !important',
      border: '1px solid transparent !important',
      cursor: 'not-allowed',
    },
  },
  removeButton: {
    color: 'black !important',
    textShadow: 'none !important',
    backgroundColor: '#CC0000 !important',
    border: '1px solid transparent !important',
    padding: '6px 10px !important',
    '&:hover': {
      backgroundColor: '#CC0000 !important',
      color: '#fff !important',
    },
    '&:disabled': {
      color: '#555 !important',
      backgroundColor: '#bcbcbc !important',
      border: '1px solid transparent !important',
      cursor: 'not-allowed',
    },
  },
  checkBox: {
    padding: '0px !important',
  },
  controlButton: {
    color: '#fff !important',
    backgroundColor: '#aaa',
    borderColor: '#285e8e !important',
    fontWeight: 400,
    fontSize: 12,
    padding: '0px 2px !important',
    minHeight: '23px !important',
    '&:hover': {
      backgroundColor: 'black !important',
      color: 'white !important',
    },
  },
  checkBoxLabel: {
    fontWeight: '400 !important',
    fontSize: '12px !important',
    height: '21px !important',
    paddingRight: '2px !important',
    display: 'flex !important',
    alignItems: 'center !important',
  },
  addRuleButton: {
    backgroundColor: 'black !important',
    color: 'white !important',
    fontWeight: '400 !important',
    fontSize: '12px !important',
    minHeight: '20px !important',
    paddingRight: '6px !important',
  },
  addGroupButton: {
    backgroundColor: 'blue !important',
    color: 'white !important',
    fontWeight: '400 !important',
    fontSize: '12px !important',
    minHeight: '20px !important',
    paddingRight: '6px !important',
  },
  formControl: {
    color: '#333 !important',
    backgroundColor: '#e6e6e6 !important',
    border: 'solid 1px #adadad !important',
    margin: '0px !important',
  },
  subContainer: {
    listStyle: 'none',
    padding: '0 0 0 15px',
    margin: 0,
  },
  groupHeader: {
    padding: '10px',
    paddingBottom: '6px',
    border: '1px solid #b6c8f5',
    background: 'rgba(239, 239, 239, 0.5)',
  },
  ruleBuilderContainer: {
    position: 'relative',
    margin: '4px 0',
    borderRadius: '5px',
    padding: '5px',
    border: '1px solid #EEE',
    background: 'rgba(255, 255, 255, 0.9)',
    '&:before': {
      content: '""',
      position: 'absolute',
      borderRadius: '0 0 0 4px',
      top: '-5px',
      height: 'calc(50% + 14px)',
      borderWidth: '0 0 2px 2px',
      left: '-10px',
      width: '10px',
      // height: 'calc(50% + 4px)',
      borderColor: '#CCC',
      borderStyle: 'solid',
    },
  },
  deleteGroupButton: {
    backgroundColor: 'brown !important',
    color: 'black !important',
    fontWeight: '400 !important',
    fontSize: '12px !important',
    minHeight: '20px !important',
    paddingRight: '6px !important',
  },
  deleteRuleButton: {
    backgroundColor: 'yellow !important',
    color: 'black !important',
    fontWeight: '400 !important',
    fontSize: '12px !important',
    minHeight: '20px !important',
    paddingRight: '6px !important',
  },
  basicFiltersTable: {
    fontSize: '14px',
    background: '#fff',
    width: '95%',
    borderCollapse: 'collapse',
    textAlign: 'left',
    margin: '20px',
  },
  basicFilterRow: {
    borderColor: 'transparent',
    borderLeft: '1px',
    borderRight: '1px',
  },
  basicFilterTh: {
    fontSize: '14px',
    fontWeight: 'normal',
    color: '#039',
    borderBottom: '2px solid #8c8c8c',
    padding: '10px 8px',
  },
  basicFilterFirstTd: {
    padding: '10px 8px',
    textAlign: 'right',
  },
  basicFilterTd: {
    fontSize: '14px',
    padding: '10px 8px',
    textAlign: 'center',
  },
});
