import { Dispatch } from 'redux';
import Api from '../../api/index';
import {
  LOAD_CARRIERS_CONTRACT_DISPATCH,
  LOAD_DRIVERS_CONTRACT_DISPATCH,
  LOAD_LOADING_STATUS_CONTRACT_DISPATCH,
  LOAD_PRODUCT_GROUP_CONTRACT_DISPATCH,
  LOAD_ROUTE_CODE_CONTRACT_DISPATCH,
  LOAD_SHIPPING_METHOD_CONTRACT_DISPATCH,
  LOAD_STAGING_BAY_CONTRACT_DISPATCH,
  LOAD_VEHICLE0_CONTRACT_DISPATCH,
  LOAD_VEHICLE1_CONTRACT_DISPATCH,
  LOAD_VEHICLE2_CONTRACT_DISPATCH,
} from '../../constants/workflows';

const splitKeyValue = (obj:any) => {
  const keys = Object.keys(obj);
  const res = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < keys.length; i++) {
    res.push({
      value: keys[i],
      label: obj[keys[i]].replace(/&nbsp;/g, ''),
    });
  }
  return res;
};

export const getDrivers = (termId:string) => async (dispatch: Dispatch) => {
  const response = await Api.get(`/workflow069/get-dropdown-driver?termId=${termId}`);
  dispatch({
    type: LOAD_DRIVERS_CONTRACT_DISPATCH,
    payload: response.data,
  });
};

export const getCarriers = (termId:string, driver:string) => async (dispatch: Dispatch) => {
  // eslint-disable-next-line max-len
  const response = await Api.get(`/workflow069/get-dropdowns-hierarchy?type=carrier&termId=${termId}&driverNo=${driver}`);
  dispatch({
    type: LOAD_CARRIERS_CONTRACT_DISPATCH,
    payload: splitKeyValue(response.data),
  });
};

export const getShippingMethod = () => async (dispatch: Dispatch) => {
  // eslint-disable-next-line max-len
  const response = await Api.get('/workflow069/get-dropdowns-hierarchy?type=shippingmethod');
  dispatch({
    type: LOAD_SHIPPING_METHOD_CONTRACT_DISPATCH,
    payload: splitKeyValue(response.data),
  });
};

// eslint-disable-next-line max-len
export const getVehicle0 = (termId:string, driver:string, carrier:string) => async (dispatch: Dispatch) => {
  // eslint-disable-next-line max-len
  const response = await Api.get(`/workflow069/get-dropdowns-hierarchy?type=carrier&termId=${termId}&driverNo=${driver}&carrierNo=${carrier}`);
  dispatch({
    type: LOAD_VEHICLE0_CONTRACT_DISPATCH,
    payload: response.data.data,
  });
};

// eslint-disable-next-line max-len
export const getVehicle1 = (termId:string, driver:string, carrier:string, vehicle0:string) => async (dispatch: Dispatch) => {
  // eslint-disable-next-line max-len
  const response = await Api.get(`/workflow069/get-dropdowns-hierarchy?type=carrier&termId=${termId}&driverNo=${driver}&carrierNo=${carrier}&vehicle0=${vehicle0}`);
  dispatch({
    type: LOAD_VEHICLE1_CONTRACT_DISPATCH,
    payload: response.data.data,
  });
};

// eslint-disable-next-line max-len
export const getVehicle2 = (termId:string, driver:string, carrier:string, vehicle0:string, vehicle1:string) => async (dispatch: Dispatch) => {
  // eslint-disable-next-line max-len
  const response = await Api.get(`/workflow069/get-dropdowns-hierarchy?type=carrier&termId=${termId}&driverNo=${driver}&carrierNo=${carrier}&vehicle0=${vehicle0}&vehicle1=${vehicle1}`);
  dispatch({
    type: LOAD_VEHICLE2_CONTRACT_DISPATCH,
    payload: response.data.data,
  });
};

export const getStagingBay = (termId:string) => async (dispatch: Dispatch) => {
  const response = await Api.get(`/workflow069/get-dropdown-data?type=stagingbay&termId=${termId}`);
  dispatch({
    type: LOAD_STAGING_BAY_CONTRACT_DISPATCH,
    payload: response.data.data,
  });
};

export const getRouteCode = (termId:string) => async (dispatch: Dispatch) => {
  const response = await Api.get(`/workflow069/get-dropdown-data?type=routecode&termId=${termId}`);
  dispatch({
    type: LOAD_ROUTE_CODE_CONTRACT_DISPATCH,
    payload: response.data,
  });
};

export const getProductGroup = (termId:string) => async (dispatch: Dispatch) => {
  // eslint-disable-next-line max-len
  const response = await Api.get(`/workflow069/get-dropdown-data?type=productgroup&termId=${termId}`);
  dispatch({
    type: LOAD_PRODUCT_GROUP_CONTRACT_DISPATCH,
    payload: response.data,
  });
};

export const getLoadingStatus = (termId:string) => async (dispatch: Dispatch) => {
  // eslint-disable-next-line max-len
  const response = await Api.get(`/workflow069/get-dropdown-data?type=driver_request_loading_status&termId=${termId}`);
  dispatch({
    type: LOAD_LOADING_STATUS_CONTRACT_DISPATCH,
    payload: response.data,
  });
};
