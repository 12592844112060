import { Dispatch } from 'redux';
import Api from '../../api/index';
import { LOAD_MARQUEE_MANAGER } from '../../constants/workflows';

export const getMarqueeManagerData = () => async (dispatch: Dispatch) => {
  const response = await Api.get(
    'workflow027/data?screenID=WORKFLOW-027',
  );
  dispatch({
    type: LOAD_MARQUEE_MANAGER,
    payload: response.data,
  });
};
