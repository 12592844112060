import {
  LOAD_DOCUMENT_BUILDER_TABLE,
  LOAD_DOCUMENT_BUILDER_DETAILS,
  EXPORT_DOCUMENT_BUILDER_DETAILS_FILE,
  CLEAR_DOCUMENT_BUILDER_DETAILS_FILE,
} from '../../constants/workflows';

const INITIAL_STATE = {
  headerList: [],
  data: [],
  hiddenColumns: [],
  visibleColumns: [],
  docBuilderData: {},
  fileBlob: null,
  fileName: 'download',
};

const DocumentBuilder = (
  state = INITIAL_STATE,
  { type, payload }: any,
) => {
  switch (type) {
    case LOAD_DOCUMENT_BUILDER_TABLE: {
      return {
        ...state,
        headerList: payload.headerList,
        data: payload.data,
        hiddenColumns: payload.hiddenColumns,
        visibleColumns: payload.visibleColumns,
      };
    }
    case LOAD_DOCUMENT_BUILDER_DETAILS: {
      return {
        ...state,
        docBuilderData: payload,
      };
    }
    case EXPORT_DOCUMENT_BUILDER_DETAILS_FILE: {
      return {
        ...state,
        fileBlob: payload.data,
        fileName: payload.fileName,
      };
    }
    case CLEAR_DOCUMENT_BUILDER_DETAILS_FILE: {
      return {
        ...state,
        fileBlob: null,
      };
    }
    default:
      return state;
  }
};

export default DocumentBuilder;
