/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

// Styles
import { useStyles } from './style';

interface ILoaderProps {
  getWorkflowData: any;
}
interface IState {
  timer: number;
  refresh: boolean;
}

const Loader: React.FC<ILoaderProps> = ({ getWorkflowData }) => {
  const [timerState, setTimerState] = useState<IState>(() => ({
    timer: 25,
    refresh: false,
  }));
  const [intervalId, setIntervalId] = useState<any>();
  const dispatch = useDispatch();
  const classes = useStyles();
  useEffect(() => {
    setIntervalId(setInterval(() => {
      getWorkflowData();
    }, 4000));
    return () => clearInterval(intervalId);
  }, []);
  useEffect(() => {
    if (timerState.timer === 0 && getWorkflowData) {
      dispatch(getWorkflowData());
      setIntervalId(setInterval(() => {
        getWorkflowData();
      }, 4000));
    }
    if (timerState.refresh) {
      setTimeout(() => {
        setTimerState((prev: IState) => ({
          ...prev,
          // eslint-disable-next-line no-nested-ternary
          timer: prev.refresh === false ? 25 : (prev.timer === 0 ? 25 : prev.timer - 1),
          refresh: prev.timer === 0 ? false : prev.refresh,
        }));
      }, 1000);
      clearInterval(intervalId);
    }
  }, [timerState.timer, timerState.refresh]);
  return (
    <div
      className={timerState.refresh ? classes.refreshContainer : classes.container}
      onClick={() => setTimerState({ ...timerState, refresh: !timerState.refresh })}
    >
      <img
        src="/assets/images/cloader.gif"
        alt="loader"
        width={30}
        style={{ marginRight: 2 }}
      />
      {timerState.refresh ? (
        <>
          Resume Auto-Refresh 00:00:
          {timerState.timer < 10 ? 0 : null}
          {timerState.timer}
        </>
      ) : null}
    </div>
  );
};

export default Loader;
