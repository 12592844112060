/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';

// Librairies
import clsx from 'clsx';
import {
  Alert,
  ButtonBase,
  Divider,
  Grid,
  Snackbar,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Components
import TmsHeader from '../../../components/header';
import SubHeader from '../../../components/subHeader';
import OptionsButton from '../../../components/optionsButton';
import EmcsConfigurationMenu from '../../../components/EmcsConfigurationMenu';
import CustomInput from '../../../components/emcsConfigurationCustomInput/index';
import ConfirmationModalLoseChanges
  from '../../../components/emcsConfigurationConfirmationModalLoseChanges/index';
import {
  updateEMCSConfiguration, getEMCSConfiguration, dissmissEMCSConfigurationAlert,
} from '../../../redux/actions/workflows/emcsConfiguration';

// Styles
import { useStyles } from './style';
import Select from '../../../components/Select';
import { getScreensList } from '../../../redux/actions/ScreenActions';

const EmcsConfiguration: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [IsModify, setIsModify] = React.useState<boolean>(false);
  const [Values, setValues] = React.useState<any>([]);
  const [ValuesToSend, setValuesToSend] = React.useState<any>([]);
  const [VisibleConfirm, setVisibleConfirm] = React.useState<boolean>(false);
  const {
    emcsConfig, emcsUpdateResult, emcsOpenAlert,
  } = useSelector((state: any) => state?.Workflows?.EmcsConfigurationReducer);

  React.useEffect(() => {
    dispatch(getScreensList());
    dispatch(getEMCSConfiguration());
  }, []);

  React.useEffect(() => {
    let oldValues = { ...Values };
    let oldValuesToSend = { ...ValuesToSend };
    emcsConfig?.forEach((element: any) => {
      if (element?.type === 'input') {
        let existingVal = null;
        let existingValToSend = null;
        existingVal = element?.value;
        existingValToSend = element?.value;

        oldValues = { ...oldValues, [element?.id]: existingVal };
        oldValuesToSend = { ...oldValuesToSend, [element?.id]: existingValToSend };
      }

      if (element?.type === 'aftx') {
        let existingVal = null;
        let existingValToSend = null;
        const selectedOption = element?.options?.filter((option: any) => option?.value === element?.value)[0];
        existingVal = { label: selectedOption.label, value: selectedOption.value };
        existingValToSend = element?.value;

        oldValues = { ...oldValues, [element?.id]: existingVal };
        oldValuesToSend = { ...oldValuesToSend, [element?.id]: existingValToSend };
      }

      if (element?.type === 'dualselYN') {
        let existingVal = null;
        let existingValToSend = null;
        const selectedOption = element?.options?.filter((option: any) => option?.value === element?.value)[0];
        existingVal = { label: selectedOption.label, value: selectedOption.value };
        existingValToSend = element?.value;

        oldValues = { ...oldValues, [element?.id]: existingVal };
        oldValuesToSend = { ...oldValuesToSend, [element?.id]: existingValToSend };
      }
    });

    setValues({ ...oldValues });
    setValuesToSend({ ...oldValuesToSend });
  }, [emcsConfig]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues({
      ...Values,
      [name]: value,
    });
    setValuesToSend({
      ...ValuesToSend,
      [name]: value,
    });
    setIsModify(true);
  };
  const handleSelectChange = (
    label: any,
    e: React.ChangeEvent<HTMLInputElement>,
    newValue: any,
  ) => {
    setValues({
      ...Values,
      [label]: newValue,
    });
    setValuesToSend({
      ...ValuesToSend,
      [label]: newValue.value,
    });
    setIsModify(true);
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOptionClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleLoseConfirm = () => {
    setVisibleConfirm(!VisibleConfirm);
  };

  const handleCloseModalYes = ():void => {
    setValues({});
    setValuesToSend({});
    window.location.reload();
  };

  const handleCloseSnackbar = () => {
    dispatch(dissmissEMCSConfigurationAlert());
  };

  const handleSave = () => {
    if (Object.keys(ValuesToSend ?? [])?.length > 0) {
      dispatch(updateEMCSConfiguration(ValuesToSend));
      setIsModify(false);
    }
  };

  const handleCancel = () => {
    if (Object.keys(Values ?? [])?.length > 0
    && IsModify) {
      setVisibleConfirm(true);
    } else {
      window.location.reload();
    }
  };

  const dataConfig = [
    {
      id: 'AADName',
      label: 'AAD Name',
      type: 'input',
      disabled: false,
      required: false,
      validate: '',
      options: null,
    },
    {
      id: 'ARCRequestMode',
      label: 'ARC Request Mode',
      type: 'dropdown',
      disabled: false,
      required: false,
      validate: '',
      options: [
        { label: 'Per Transaction', value: 'ARC_PER_TRANSACTION' },
        { label: 'Per Product', value: 'ARC_PER_PRODUCT' }],
    },
    {
      id: 'ArcRequestServerTrac',
      label: 'Arc Request Server Trac',
      type: 'dropdown',
      disabled: false,
      required: false,
      validate: '',
      options: [
        { label: 'Yes', value: 'Y' },
        { label: 'No', value: 'N' }],
    },
    {
      id: 'AuthorityDispatchNr',
      label: 'Authority Dispatch Number',
      type: 'input',
      disabled: false,
      required: false,
      validate: '',
      options: null,
    },
    {
      id: 'BYPASS',
      label: 'Bypass',
      type: 'dropdown',
      disabled: false,
      required: false,
      validate: '',
      options: [
        { label: 'Yes', value: 'Y' },
        { label: 'No', value: 'N' }],
    },
    {
      id: 'Delivery Place',
      label: 'DeliveryPlace',
      type: 'input',
      disabled: false,
      required: false,
      validate: '',
      options: null,
    },
    {
      id: 'DISPATCHTIMEOUT',
      label: 'Dispatch Timeout',
      type: 'input',
      disabled: false,
      required: false,
      attr: ['noneg'],
      validate: '',
      options: null,
    },
    {
      id: 'EADName',
      label: 'EAD Name',
      type: 'input',
      disabled: false,
      required: false,
      validate: '',
      options: null,
    },
    {
      id: 'ebi Test Indicator',
      label: 'ebi Test Indicator',
      type: 'input',
      disabled: false,
      required: false,
      validate: '',
      options: null,
    },
    {
      id: 'ExcludeGroup1',
      label: 'Exclude Group 1',
      type: 'input',
      disabled: false,
      required: false,
      validate: '',
      options: null,
    },
    {
      id: 'Exempt_CountryNum',
      label: 'Exempt Country Number',
      type: 'input',
      disabled: false,
      required: false,
      validate: '',
      options: null,
    },
    {
      id: 'Exempt_Country1',
      label: 'Exempt Country 1',
      type: 'input',
      disabled: false,
      required: false,
      validate: '',
      options: null,
    },
    {
      id: 'Exempt_Country2',
      label: 'Exempt Country 2',
      type: 'input',
      disabled: false,
      required: false,
      validate: '',
      options: null,
    },
    {
      id: 'PrintDisplayDelay',
      label: 'Print Display Delay',
      type: 'input',
      disabled: false,
      required: false,
      attr: ['noneg'],
      validate: '',
      options: null,
    },
    {
      id: 'PRINTEAD',
      label: 'Print EAD',
      type: 'dropdown',
      disabled: false,
      required: false,
      validate: '',
      options: [
        { label: 'Yes', value: 'Y' },
        { label: 'No', value: 'N' }],
    },
    {
      id: 'ResendOnError',
      label: 'Resend On Error',
      type: 'dropdown',
      disabled: false,
      required: false,
      validate: '',
      options: [
        { label: 'Yes', value: 'Y' },
        { label: 'No', value: 'N' }],
    },
    {
      id: 'STATUS',
      label: 'Status',
      type: 'input',
      disabled: true,
      required: false,
      validate: '',
      options: null,
    },
    {
      id: 'SubmitCountLimit',
      label: 'Submit Count Limit',
      type: 'input',
      disabled: false,
      required: false,
      validate: '',
      options: null,
    },
    {
      id: 'TIMEOUT',
      label: 'Timeout',
      type: 'input',
      disabled: false,
      required: false,
      attr: ['noneg'],
      validate: '',
      options: null,
    },
    {
      id: 'TMSDOC',
      label: 'TMS Doc',
      type: 'dropdown',
      disabled: false,
      required: false,
      validate: '',
      options: [
        { label: 'Yes', value: 'Y' },
        { label: 'No', value: 'N' }],
    },
    {
      id: 'WAIVERCODE',
      label: 'Waiver Code',
      type: 'input',
      disabled: true,
      required: false,
      validate: '',
      options: null,
    },
    {
      id: 'wsaAddress',
      label: 'wsa Address',
      type: 'input',
      disabled: false,
      required: false,
      validate: '',
      options: null,
    },
    {
      id: 'wsaTo',
      label: 'wsa To',
      type: 'input',
      disabled: false,
      required: false,
      validate: '',
      options: null,
    },
    {
      id: 'EMAIL',
      label: 'Email',
      type: 'input',
      attr: ['email'],
      disabled: false,
      required: false,
      validate: '',
      options: null,
    },
  ];

  return (
    <div className={clsx(classes.root, 'flexColumn')}>
      <TmsHeader />
      <SubHeader title="EMCS Configuration">
        <OptionsButton handleClick={handleOptionClick} />
        <EmcsConfigurationMenu anchorEl={anchorEl} onClose={handleCloseMenu} />
      </SubHeader>
      <div className={clsx('takeTheRest', classes.container)}>
        <div className={classes.header}>
          <ButtonBase
            className={classes.saveButton}
            disabled={Object.keys(Values ?? [])?.length === 0}
            onClick={handleSave}
          >
            Save
          </ButtonBase>
          <ButtonBase
            className={classes.cancleButton}
            onClick={handleCancel}
          >
            Cancel
          </ButtonBase>
        </div>
        <Divider className={classes.divider} />
        <div>
          {dataConfig?.map((field: any) => {
            if (field?.id === '') { return <></>; }

            return (
              ['input'].includes(field?.type) ? (
                <Grid key={field?.id} className={clsx('flexRow', 'fullWidth', classes.wrapper)}>
                  <Grid
                    xs={4}
                    width="25%"
                    className={clsx('flexColumn', classes.inputWrapper)}
                  >
                    <label className={classes.labelWrapper}>
                      {` ${field?.label} `}
                    </label>
                  </Grid>
                  <Grid xs={8} width="75%">
                    <CustomInput
                      required={false}
                      name={field?.id}
                      attr={field?.attr ?? []}
                      value={Values[field?.id]}
                      onChange={handleInputChange}
                      size="100%"
                      maxLength={255}
                      inputProps={{
                        maxLength: 255,
                      }}
                      placeholder={field?.label}
                      isDisabled={field?.disabled}
                      validate={field?.validate}
                    />
                  </Grid>
                </Grid>
              ) : (
                <Grid key={field?.id} className={clsx('flexRow', 'fullWidth', classes.wrapper)}>
                  <Grid
                    xs={4}
                    width="25%"
                    className={clsx('flexColumn', classes.inputWrapper)}
                  >
                    <label className={classes.labelWrapper}>
                      {` ${field?.label} `}
                    </label>
                  </Grid>
                  <Grid xs={8} width="75%">
                    <Select
                      disableClearInput
                      options={field?.options}
                      value={Values[field?.id] !== undefined ? Values[field?.id] : { label: '-- Select --', value: '' }}
                      onChange={
                        (e: any, newValue: any) => handleSelectChange(field?.id, e, newValue)
                      }
                    />
                  </Grid>
                </Grid>
              )
            );
          })}
        </div>
        <ConfirmationModalLoseChanges
          Visible={VisibleConfirm}
          handleCloseConfirm={handleLoseConfirm}
          handleCloseModalYes={handleCloseModalYes}
        />
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={emcsOpenAlert}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity={emcsUpdateResult?.type === 'success' ? 'success' : 'error'}
          >
            {emcsUpdateResult?.msg}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
};

export default EmcsConfiguration;
