/* eslint-disable max-len */
/* eslint-disable react/require-default-props */
import {
  Autocomplete, Grid, TextField, Typography,
} from '@mui/material';
import React from 'react';

// Styles
import { useStyles } from './style';

interface ISelectProps {
  label: string;
  options: any[];
  width?: number | string;
  value?: any;
  style?: React.CSSProperties;
  onChange?: (e: any, option: any) => void;
  disableClearInput?: boolean;
  getOptionLabel?: (option: any) => string;
  name?: string;
  placeholder?: string;
  disabled?: boolean;
  defaultValue?: any;
}

const WorkflowReportDropdown: React.FC<ISelectProps> = ({
  label,
  options,
  width,
  value,
  style,
  onChange,
  disableClearInput,
  getOptionLabel,
  name,
  disabled,
  placeholder,
  defaultValue,
}) => {
  const classes = useStyles();
  return (
    <>
      <Grid container>
        <Grid item lg={3} xs={12} md={3} />
        <Grid item lg={1} xs={12} md={1}>
          <div className={classes.centerText}>
            <Typography variant="caption" align="right" className={classes.label}>{label}</Typography>
          </div>
        </Grid>
        <Grid item lg={8} xs={12} md={8}>
          <Autocomplete
            getOptionLabel={getOptionLabel}
            disableClearable={disableClearInput || false}
            disabled={disabled}
            style={{ width, ...style }}
            value={value}
            onChange={onChange}
            defaultValue={defaultValue}
            classes={{ root: classes.root, input: classes.autoCompleteInput }}
            renderInput={(params: any) => (
              <TextField
                classes={{ root: classes.input }}
                placeholder={placeholder || '-- Select --'}
                name={name}
                {...params}
              />
            )}
            options={options}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default WorkflowReportDropdown;
