import React, { useState } from 'react';

// Librairies
import {
  ButtonBase, Divider,
} from '@mui/material';

// Styles
import { useStyles } from './style';
import CustomReportOptionsTable from '../CustomReportOptionsTable';

const CustomReportTreeTableCard: React.FC = () => {
  const classes = useStyles();
  return (
    <>
      <div>
        <CustomReportOptionsTable />
      </div>
    </>
  );
};

export default CustomReportTreeTableCard;
