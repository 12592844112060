import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Footer from '../components/Footer/Footer';

const SiteLayout = () => {
  const location = useLocation();

  return (
    <>
      <Outlet />
      {location.pathname !== '/workflow/tile-layout' && (
        <>
          <span className="takeTheRest" />
          <Footer />
        </>
      )}
    </>
  );
};

export default SiteLayout;
