/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-globals */
import React, { useEffect, useMemo } from 'react';
import {
  ClickAwayListener,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';

type inputType = {
  size?: any;
  type?: any;
  maxLength?: number;
  placeholder?: string;
  isDisabled: boolean;
  name?: string;
  value?: any;
  onChange?: any;
  inputProps?: any;
  error?: boolean;
  helperText?: string;
  key?: any;
  required?: boolean;
  validate?: string;
  val?: any;
  attr?: any;
  noAwayListener?: boolean;
  isNumber?: boolean;
  isBatch?: any;
  datagrid?: boolean;
};
const CustomInput = (props: inputType) => {
  const {
    size,
    placeholder,
    isDisabled,
    type,
    isBatch,
    maxLength,
    name,
    value,
    onChange,
    inputProps,
    error,
    helperText,
    required,
    attr,
    key,
    validate,
    val,
    noAwayListener,
    isNumber,
    datagrid,
  } = props;
  const [open, setOpen] = React.useState(false);
  const [inFocus, setInFocus] = React.useState(false);
  const [isValidEmail, setIsValidEmail] = React.useState(true);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    if (required || !isValidEmail) {
      setOpen(true);
    }
  };

  const [InputSize, setInputSize] = React.useState('');
  const handleBlur = () => {
    setInFocus(false);

    if (attr?.includes('email')) {
      // \. is needed on regex to present dot else it means anything
      // eslint-disable-next-line
      const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (value === '' || value.match(mailformat)) {
        setIsValidEmail(true);
      } else {
        setIsValidEmail(false);
      }
    }
  };

  const validationTooltipTitle = () => (
    !isValidEmail
      ? 'Must be e-mail format'
      : 'This field is required');

  const backgroundColor = useMemo(() => {
    if (val && !val.visible) {
      return '#fff9f0';
    }
    if (isDisabled) {
      return '#f8f8f8';
    }
    return '#fff';
  }, [value, isDisabled, val]);

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div
        key={key}
        style={{
          display: 'flex',
          alignItems: 'center',
          width: !noAwayListener ? size : 'none',
          flex: 3,
        }}
      >
        <Tooltip
          PopperProps={{
            disablePortal: true,
            color: 'red',
          }}
          onClose={handleTooltipClose}
          open={open}
          placement="top"
          arrow
          color="red"
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={validationTooltipTitle()}
        >
          <TextField
            className="textfield"
            name={name}
            value={value === 'null' || value === null ? '' : value}
            onChange={(e: any) => {
              onChange(e);
              setInputSize(e.target.value);
            }}
            onFocus={() => setInFocus(true)}
            onBlur={handleBlur}
            placeholder={placeholder}
            size="small"
            onClick={handleTooltipOpen}
            type={
                    isNumber || attr?.includes('num')
                      ? 'text'
                      : attr?.includes('secure')
                        ? 'password'
                        : 'type'
                  }
            inputProps={inputProps}
            disabled={isDisabled}
            sx={{
              '& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
                padding: '4px 8px',
                border: '1px solid transparant',
              },
              '& .MuiInputBase-root': {
                '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                        {
                          display: 'inline',
                        },
                '& input[type=number]': {
                  MozAppearance: 'none',
                },
                '& > fieldset': {
                  borderColor: (required && value === '')
                          || !isValidEmail
                    ? 'red'
                    : 'gray',
                  borderLeft: required
                    ? '5px solid red'
                    : '5px solid #555',
                  borderRadius: '0px !important',
                },
              },
              '& .MuiInputBase-root.Mui-disabled': {
                '& > fieldset': {
                  borderColor:
                          (required && value === '') || !isValidEmail
                            ? 'red'
                            : 'transparent',
                  borderLeft: required
                    ? '5px solid red'
                    : '5px solid #555',
                  borderRadius: '0px !important',
                  color: '#000',
                },
              },
              width: size,
              background: backgroundColor,
            }}
            error={
                    attr?.includes('noneg') && isNaN(value) && value !== ''
                  }
            color={
                    (required && value === '') || !isValidEmail
                      ? 'error'
                      : 'primary'
                  }
            helperText={
                    attr?.includes('noneg') && isNaN(value) && value !== ''
                      ? 'can not be negative, must be numeric'
                      : null
                  }
          />
        </Tooltip>
        {inFocus && inputProps?.maxLength > 0 && value?.length > 0 && (
          <Typography
            style={{
              marginLeft: -48,
              fontSize: 12,
              color: 'gray',
              zIndex: 2,
            }}
          >
            {`${value?.length} / ${inputProps?.maxLength}`}
          </Typography>
        )}
      </div>
    </ClickAwayListener>
  );
};
CustomInput.defaultProps = {
  name: null,
  value: null,
  type: null,
  inputProps: {},
  maxLength: null,
  size: null,
  error: false,
  helperText: null,
  placeholder: null,
  key: null,
  attr: null,
  required: false,
  validate: '',
  val: null,
  isBatch: undefined,
  noAwayListener: false,
  onChange: onchange,
  isNumber: false,
  datagrid: false,
};
export default CustomInput;
