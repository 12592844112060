import React, { useRef } from 'react';

// Librairies
import { Typography } from '@mui/material';

// Styles
import { useStyles } from './style';

interface IProps {
  tableHeaders: any;
  tableTitle: string;
  historyList: any;
}

export const TableData: React.FC<IProps> = ({
  tableHeaders,
  tableTitle,
  historyList,
}) => {
  const classes = useStyles();
  const headerRef = useRef<any>(null);
  const dataRef = useRef<any>(null);

  const handleScroll = () => {
    const scrollPosition = headerRef.current.scrollTop;
    dataRef.current.scrollTop = scrollPosition;
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          margin: 10,
        }}
      >
        <Typography style={{ color: 'gray' }}>{tableTitle || ''}</Typography>
      </div>
      <div style={{ padding: '10px 20px 25px 10px' }}>
        <div
          style={{
            float: 'left',
            top: '35px',
            overflowX: 'scroll',
            overflowY: 'auto',
            height: 'calc(40vh - 150px)',
            width: '245px',
          }}
          ref={headerRef}
          onScroll={handleScroll}
        >
          <table style={{ width: '100%' }}>
            <thead>
              <tr>
                <th>
                  <div
                    style={{
                      height: '20px',
                      textAlign: 'right',
                      whiteSpace: 'nowrap',
                      backgroundColor: '#e0ebff',
                    }}
                  >
                    Users:
                  </div>
                </th>
              </tr>
              <tr>
                <th>
                  <div
                    style={{
                      height: '20px',
                      textAlign: 'left',
                      float: 'left',
                      paddingLeft: '5px',
                      whiteSpace: 'nowrap',
                      backgroundColor: '#e0ebff',
                    }}
                  >
                    Fields
                  </div>
                  <div
                    style={{
                      height: '20px',
                      textAlign: 'right',
                      whiteSpace: 'nowrap',
                      backgroundColor: '#e0ebff',
                    }}
                  >
                    Date:
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(tableHeaders || {}).map((h) => (
                <tr>
                  <td
                    style={{
                      borderBottom: '1px solid #d2d2d2',
                      height: '23px',
                      whiteSpace: 'nowrap',
                      padding: '0 2px',
                    }}
                  >
                    {tableHeaders[h]}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div
          style={{
            overflowX: 'scroll',
            overflowY: 'hidden',
            height: 'calc(40vh - 150px)',
            marginLeft: '250px',
          }}
          ref={dataRef}
        >
          <table>
            <tbody>
              <tr>
                {historyList?.map((item: any) => {
                  const newValueObj = item?.new_value;
                  const oldValueObj = item?.old_value;
                  return (
                    <td
                      style={{
                        verticalAlign: 'top',
                        border: '1px solid #cccce6',
                      }}
                    >
                      <table>
                        <thead>
                          <tr>
                            <th>
                              <div
                                style={{
                                  height: '20px',
                                  padding: '0px 5px 0px 5px',
                                  whiteSpace: 'nowrap',
                                  backgroundColor: '#e0ebff',
                                }}
                              >
                                {item.user_id}
                              </div>
                              <div
                                style={{
                                  height: '20px',
                                  padding: '0px 5px 0px 5px',
                                  whiteSpace: 'nowrap',
                                  backgroundColor: '#e0ebff',
                                  borderBottom: '1px solid #d2d2d2',
                                }}
                              >
                                {item.change_datetime}
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.keys(tableHeaders || {}).map((key) => {
                            const valueChanged: boolean = newValueObj?.[key]?.trim()
                                !== oldValueObj?.[key]?.trim() && item?.old_value;
                            return (
                              <tr>
                                <td>
                                  <div style={{ display: 'flex', gap: 5 }}>
                                    <span
                                      className={
                                        valueChanged
                                          ? classes.newValueStyle
                                          : undefined
                                      }
                                    >
                                      {newValueObj?.[key]}
                                    </span>
                                    {valueChanged && (
                                      <span
                                        className={
                                          valueChanged
                                            ? classes.oldValueStyle
                                            : undefined
                                        }
                                      >
                                        {oldValueObj?.[key]}
                                      </span>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </td>
                  );
                })}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
