/* eslint-disable react/function-component-definition */
import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import OptionsButton from '../../../components/optionsButton';
import {
  openFilterModal,
} from '../../../redux/actions/ScreenActions';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export default function OrderMaintenanceMenu({
  type, openColumnsDialog, crudx, recordData,
}:any) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOptionClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleBackButton = () => {
    navigate('/workflow/order-maintenance-details');
    handleClose();
  };
  const handleBookManualTransaction = () => {
    if (recordData?.RecType === 'I') {
      navigate('/workflow/bulk-scheduled-transaction', recordData);
      handleClose();
    } else if (recordData?.RecType === 'O') {
      navigate('/workflow/order-transaction-entry', recordData);
      handleClose();
    } else {
      handleClose();
    }
  };

  return (
    <div>
      <OptionsButton handleClick={handleOptionClick} />
      { type === 'main'
        ? (
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              'aria-labelledby': 'demo-customized-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            { crudx.access_c === 'Y'
              ? (
                <MenuItem onClick={handleBackButton} disableRipple>
                  New
                </MenuItem>
              ) : ''}
            <Divider />
            <MenuItem
              onClick={async () => {
                dispatch(openFilterModal());
                handleClose();
              }}
              disableRipple
            >
              filter...
            </MenuItem>
            <MenuItem onClick={openColumnsDialog} disableRipple>
              Columns...
            </MenuItem>
          </StyledMenu>
        )
        : (
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              'aria-labelledby': 'demo-customized-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose} disableRipple disabled>
              Authorize Order
            </MenuItem>
            <MenuItem onClick={handleClose} disableRipple disabled>
              Cancel Order
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleClose} disableRipple>
              View Transaction(s)
            </MenuItem>
            {recordData?.OrderStatus === 'A' && (
            <MenuItem onClick={handleBookManualTransaction} disableRipple>
              Book Manual Transaction
            </MenuItem>
            )}
            <Divider />
            <MenuItem onClick={handleClose} disableRipple>
              Clone Order
            </MenuItem>
            <MenuItem onClick={handleClose} disableRipple>
              Order Seals
            </MenuItem>
            <MenuItem onClick={handleClose} disableRipple>
              Record Change History
            </MenuItem>
            <MenuItem onClick={handleClose} disableRipple>
              Show Optional Fields
            </MenuItem>
          </StyledMenu>
        )}

    </div>

  );
}
