import {
  LOAD_EMCS_MANAGER,
  CLEAR_EMCS_MANAGER,
  SET_EMCS_MANAGER_EXPORT_FILE,
  SET_EMCS_MANAGER_EXPORT_LINK,
  CLEAR_EMCS_MANAGER_EXPORT_FILE,
  CLEAR_EMCS_MANAGER_EXPORT_LINK,
} from '../../constants/workflows';

const INITIAL_STATE = {
  data: [],
  mode: '',
  exportFile: '',
  exportLink: '',
};

const emcsManagerReducer = (state = INITIAL_STATE, { type, payload }: any) => {
  switch (type) {
    case LOAD_EMCS_MANAGER: {
      return {
        ...state,
        data: payload.data,
        mode: payload.mode,
      };
    }
    case SET_EMCS_MANAGER_EXPORT_FILE: {
      return {
        ...state,
        exportFile: payload,
      };
    }
    case CLEAR_EMCS_MANAGER_EXPORT_FILE: {
      return {
        ...state,
        exportFile: '',
      };
    }
    case SET_EMCS_MANAGER_EXPORT_LINK: {
      return {
        ...state,
        exportLink: payload,
      };
    }
    case CLEAR_EMCS_MANAGER_EXPORT_LINK: {
      return {
        ...state,
        exportLink: '',
      };
    }
    case CLEAR_EMCS_MANAGER: {
      return {
        ...state,
        data: [],
        mode: '',
      };
    }
    default:
      return state;
  }
};

export default emcsManagerReducer;
