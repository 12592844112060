import { ActionCreator, Dispatch } from 'redux';
import api from '../../api/index';
import { GET_ABOUT_PAGE_DATA } from '../../constants/workflows';

export interface InstalledPackage {
  action: string;
  package: string;
  time: string;
  user: string;
  version: string;
}

export interface ReleaseInfo {
  product: string;
  versions: ReleaseNotesVersion;
}
interface ReleaseNotesVersion {
  [versionNumber: string] : ReleaseChanges
}
interface ReleaseChanges {
  ['Advisory:']: Array<ReleaseDescription>;
  'Fixed:': Array<ReleaseDescription>;
  'New:': Array<ReleaseDescription>;
}
export interface ReleaseDescription {
  ref: string;
  text: string;
}
export interface AboutDataResponse {
  /**
   * Installed packages list
   */
  // eslint-disable-next-line camelcase
  install_pkg_list: Array<InstalledPackage>;
  /**
   * Product Version. array of strings:
   * [0]: product name
   * [1]: version
   * [2]: build number
   * [3]: date
   * [4]: certificate
   */
  // eslint-disable-next-line camelcase
  product_version: Array<string>;
  /**
   * Release information. Contains product name and an array of versions for history of chanages
   */
  relInfo: ReleaseInfo;
}

export const getAboutScreenData = () => async (dispatch: Dispatch) => {
  const response = await api
    .get<AboutDataResponse>('workflow202/about?screenID=WORKFLOW-202&groupID=Admin');
  const d = dispatch({
    type: GET_ABOUT_PAGE_DATA,
    payload: {
      installedPackages: response.data.install_pkg_list,
      productVersion: {
        product: response.data.product_version[0],
        version: response.data.product_version[1],
        build: response.data.product_version[2],
        time: response.data.product_version[3],
        certificateNumber: response.data.product_version[4],
      },
      releaseInfo: response.data.relInfo,
    },
  });
};
