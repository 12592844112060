/* eslint-disable max-len */
/* eslint-disable react/jsx-one-expression-per-line */
import * as React from 'react';
import ReactDOM from 'react-dom';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import ControlButton from '../../controlButton';
import useWindowDimensions from '../../../utils/windowDimensions';

const style = {
  position: 'absolute' as const,
  left: '20%',
  top: '10%',
  width: '50%',
  height: 170,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
};

interface IProps {
  Visible: boolean,
  setShowModal: any,
  option?: any;
  command: string;
  procBayCommand: any;
  bayNumberDescription: string;
  bayID: string;
}
const ConfirmationModalCommand: React.FC<IProps> = ({
  Visible,
  setShowModal,
  command,
  bayNumberDescription,
  procBayCommand,
  bayID,
}) => {
  const { activeSreen, ScreensList } = useSelector((state: any) => state.ScreenReducer);
  const {
    IsReportEdit,
  } = useSelector(
    (state: any) => state?.Reports?.Reports,
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  const handleClose = () => {
    setShowModal(false);
  };
  const handleDelete = () => {
    setShowModal(false);
    procBayCommand(bayID, command);
  };
  const messageList = {
    start: 'Start',
    stop: 'Stop',
    restart: 'Restart',
    drain: 'Drain',
    enable: 'Enable',
    disable: 'Disable',
    open: 'Open',
    togglebaytrace: 'Toggle Trace On/Off',
    togglebaydebug: 'Toggle Debug On/Off',
    stopallbays: 'Stop All Load Bays',
    startallbays: 'Start All Load Bays',
    drainallbays: 'Drain All Load Bays',
    pumprestoreall: 'Restore All Pumps',
    restartallbays: 'Restart All Load Bays',
  };

  return ReactDOM.createPortal(
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={Visible}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 460,
      }}
    >
      <Fade in={Visible}>
        <Box sx={style}>
          <div style={{ padding: 10, backgroundColor: '#d2d2d2' }}>
            <Typography>{bayID}</Typography>
          </div>
          <div style={{
            height: 'auto', padding: '25px 5px',
          }}
          >
            <Typography>{ messageList[command as keyof typeof messageList] || '' } {bayNumberDescription}?</Typography>
          </div>
          <Divider />
          <div style={{
            height: 'auto', padding: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center',
          }}
          >
            {/* <Typography color="red">This action cannot be undone.</Typography> */}
            <Grid container>
              <Grid item xs={10} />
              <Grid item xs={2}>
                <div style={{
                  height: 'auto', display: 'flex', gap: 5, alignItems: 'flex-end', alignSelf: 'center',
                }}
                >
                  <ControlButton
                    label="Yes"
                    color="#00c851"
                    disabled={false}
                    handleClick={handleDelete}
                  />
                  <ControlButton
                    label="Cancel"
                    color="#ffbb33"
                    disabled={false}
                    handleClick={handleClose}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </Box>
      </Fade>
    </Modal>,
    document.getElementById('draggable') as HTMLElement,
  );
};
export default ConfirmationModalCommand;
