import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import { add, format } from 'date-fns';
import CustomDropdown from '../customDropdown';
import CustomInput from '../customInput';
import Plcconfig from '../plcconfigComponent/plcconfig';
import CustomRcuscreen from '../customTextArea/customTextArea';
import ConfigOptions from '../ConfigOptions/ConfigOptions';
import Weekday from '../customDropdown/Weekday';
import LoadDataGrid from '../LoadDataGrid/LoadDataGrid';

const CustomField = (props: any) => {
  const {
    val,
    isBatch,
    handleInputChange,
    handleSelectChange,
    values,
    valuesToSend,
    size,
    margin,
    headerDisabled,
    tableId,
    datagrid,
  } = props;
  const { recordDetails, activeScreen } = useSelector(
    (state: any) => state.ScreenReducer,
  );
  if (!val?.type) {
    return <></>;
  }

  if (val?.type === 'grid') {
    return (
      <LoadDataGrid
        id={val?.id}
        isBatch={isBatch}
        values={values}
        onChangeInput={handleInputChange}
        onChangeSelect={handleSelectChange}
      />
    );
  }

  if (
    [
      'aftx',
      'tlist',
      'baygroupbay',
      'country',
      'state',
      'dualsel01',
      'routecode',
      'dualselYN',
      'aftx:inven_units',
      'chain',
      'emailgroup',
      'faxgroup',
      'dualselGN',
      'transval',
      'termid',
      'product',
      'language',
      'carrier',
      'accessprofile',
      'dualselAI',
    ].includes(val?.type)
  ) {
    const tabOpt = val?.obj?.['\u0000*\u0000parm'];
    let defVal;
    if (tabOpt?.options !== undefined) {
      defVal = tabOpt?.options[0];
    } else if (tabOpt?.nothingselected === null) {
      defVal = tabOpt?.selecttext;
    } else {
      defVal = tabOpt?.nothingselected;
    }

    return (
      <CustomDropdown
        key={uuidv4()}
        val={val}
        isBatch={isBatch}
        label={val?.label}
        name={val?.id}
        defaultValue={defVal}
        isDisabled={headerDisabled || val?.attr?.includes('readonly')}
        value={(!values[val?.id] && val.id === 'active_enable') ? { value: '20Y//', label: 'Yes' } : (values[val?.id] || val?.param?.['\u0000*\u0000parm']?.value)}
        isOptionEqualToValue={(option: any, value: any) => option?.label === value}
        onChange={handleSelectChange}
        size={size}
        margin={margin}
        options={
          // val?.obj['\u0000*\u0000parm'].nothingselected === null
          val?.obj?.['\u0000*\u0000parm'].options || val?.param?.options
        }
        // : Array.of({ label: val?.obj['\u0000*\u0000parm'].nothingselected })}
        // options={val?.obj['\u0000*\u0000parm'].options}
        type={val?.type}
        obj={val?.obj || val?.param}
        query={val?.query}
        required={val?.required || val?.attr?.includes('required')}
        selectList={val?.selectList}
        chain={val?.chain}
        stateValues={values}
        valuesToSend={valuesToSend}
        datagrid={datagrid}
      />
    );
  }
  if (val?.attr?.includes('noneg') && val?.type === 'time') {
    return (
      <CustomInput
        timeType
        isBatch={isBatch}
        type={val?.type}
        label={activeScreen.screenId !== 'MAINT-001' ? val?.label : ''}
        required={val?.required}
        name={val?.id}
        defaultValue={val?.valForm}
        attr={val?.attr}
        value={values[val.id]}
        onChange={handleInputChange}
        size={size}
        maxLength={val?.obj?.['\u0000*\u0000parm'].maxLength}
        inputProps={{
          maxLength: val?.obj?.['\u0000*\u0000parm'].maxLength,
        }}
        placeholder={activeScreen.screenId === 'MAINT-001' ? val?.label : val.helpertext}
        isDisabled={headerDisabled || val?.attr?.includes('readonly') || val.id === 'access_profile'}
        validate={val?.validate}
        margin={margin}
        datagrid={datagrid}
      />
    );
  }
  if (
    (val?.attr?.includes('noneg') && val?.type === 'date')
    || val?.obj?.['\u0000*\u0000parm'].mode === 'date' || val?.type === 'date'
  ) {
    return (
      <CustomInput
        dateType
        type="date"
        isBatch={isBatch}
        label={val?.label}
        required={val?.required}
        name={val?.id}
        defaultValue={values[val.id]}
        attr={val?.attr}
        value={(!values[val.id] && val?.id === 'auth_expr_dt') ? format(add(new Date(), {
          days: 1,
        }), 'yyyy-MM-dd') : values[val.id] || format(new Date(), 'yyyy-MM-dd')}
        onChange={handleInputChange}
        size={size}
        maxLength={val?.obj?.['\u0000*\u0000parm'].maxLength}
        inputProps={{
          maxLength: val?.obj?.['\u0000*\u0000parm'].maxLength,
        }}
        placeholder={val.helpertext}
        isDisabled={headerDisabled || val?.attr?.includes('readonly') || val.id === 'access_profile'}
        validate={val?.validate}
        margin={margin}
        datagrid={datagrid}
      />
    );
  }
  if (val?.type === 'weekday') {
    return (
      <Weekday
        key={uuidv4()}
        label={val?.label}
        isBatch={isBatch}
        name={val?.id}
        value={values?.[val?.id]}
        isOptionEqualToValue={(option: any, value: any) => option.label === value}
        onChange={handleInputChange}
        size={size}
        options={val?.obj['\u0000*\u0000parm'].options}
        isDisabled={headerDisabled || val?.attr?.includes('readonly')}
        type={val?.type}
        obj={val?.obj}
        query={val?.query}
        selectList={val?.selectList}
        chain={val?.chain}
        stateValues={values}
        valuesToSend={valuesToSend}
        margin={margin}
      />
    );
  }
  if (val.type === 'configopt') {
    return (
      <ConfigOptions
        name={val?.id}
        value={values[val?.id]}
        setValue={handleInputChange}
      />
    );
  }
  if (val.type === 'input') {
    const errorCondition = `${/^\d*$/.test(values[val.id])}` === 'false'
      || values[val.id].length - val?.obj['\u0000*\u0000parm'].maxLength > 0;
    if (val.mask) {
      return (
        <CustomInput
          // error={errorCondition}
          phone={val?.id !== 'pct_oxygen' && val.mask}
          required={val?.required}
          isBatch={isBatch}
          mask={val.mask.replaceAll('0', '9')}
          label={val.label}
          name={val.id}
          value={values[val.id]}
          onChange={handleInputChange}
          size={size}
          attr={val?.attr}
          type={val.type}
          maxLength={val?.obj['\u0000*\u0000parm'].maxLength}
          inputProps={{
            maxLength: val?.obj['\u0000*\u0000parm'].maxLength,
          }}
          placeholder={val.mask}
          // helperText={errorCondition ? 'Must be Numeric, Cannot be negative' : ''}
          isDisabled={headerDisabled || val?.attr?.includes('readonly') || val.id === 'access_profile'}
          validate={val?.validate}
          margin={margin}
          datagrid={datagrid}
        />
      );
    }
    return (
      <CustomInput
        label={val?.label}
        name={val?.id}
        defaultValue={val?.valForm}
        isBatch={isBatch}
        required={val?.required}
        onChange={handleInputChange}
        value={values[val.id]}
        attr={val?.attr}
        size={size}
        type="text"
        placeholder={val?.helpertext}
        isDisabled={tableId === 'Driver' && val?.id === 'name' ? false
          : headerDisabled || val?.attr?.includes('readonly') || val.id === 'access_profile'}
        // helperText={errorCondition ? 'Must be Numeric, Cannot be negative' : ''}
        inputProps={{
          maxLength: val?.obj['\u0000*\u0000parm'].maxLength,
        }}
        validate={val?.validate}
        margin={margin}
        datagrid={datagrid}
      />
    );
  }
  if (val.type === 'numberwheel') {
    return (
      <CustomInput
        numericValue
        label={val?.label}
        name={val?.id}
        defaultValue={val?.default}
        isBatch={isBatch}
        required={val?.required}
        onChange={handleInputChange}
        value={values[val.id]}
        attr={val?.attr}
        size={size}
        type={val?.type}
        maxLength={val?.obj['\u0000*\u0000parm'].maxLength}
        placeholder={val.helpertext}
        isDisabled={headerDisabled || val?.attr?.includes('readonly') || val.id === 'access_profile'}
        inputProps={{
          maxLength: val?.obj['\u0000*\u0000parm'].maxLength,
        }}
        validate={val?.validate}
        margin={margin}
        datagrid={datagrid}
      />
    );
  }
  if (val?.attr?.includes('noneg') && ['date', 'time'].includes(val.type)) {
    return (
      <CustomInput
        label={val?.label}
        name={val?.id}
        defaultValue={val?.default}
        isBatch={isBatch}
        onChange={handleInputChange}
        required={val?.required}
        value={values[val.id]}
        attr={val?.attr}
        size={size}
        type={val?.type}
        maxLength={val?.obj['\u0000*\u0000parm'].maxLength}
        inputProps={{
          maxLength: val?.obj['\u0000*\u0000parm'].maxLength,
        }}
        placeholder={val.helpertext}
        isDisabled={headerDisabled || val?.attr?.includes('readonly') || val.id === 'access_profile'}
        validate={val?.validate}
        margin={margin}
        datagrid={datagrid}
      />
    );
  }
  if (val?.type === 'plcconfig') {
    return (
      <Plcconfig
        label={val?.label}
        name={val?.id}
        defaultValue={val?.default}
        onChange={handleInputChange}
        required={val?.required}
        value={values[val.id]}
        size={size}
        type={val?.type}
        options={val?.obj}
        // maxLength={val?.obj['\u0000*\u0000parm'].maxLength}
        // inputProps={{
        //   maxLength: val?.obj['\u0000*\u0000parm'].maxLength,
        // }}
        placeholder={val.helpertext}
        isDisabled={headerDisabled || val?.attr?.includes('readonly') || val.id === 'access_profile'}
      />
    );
  }
  if (val?.type === 'rcuscreen') {
    return (
      <CustomRcuscreen
        label={val?.label}
        name={val?.id}
        defaultValue={val?.default}
        onChange={handleInputChange}
        required={val?.required}
        value={values[val.id]}
        size={size}
        type={val?.type}
        // maxLength={val?.obj['\u0000*\u0000parm'].maxLength}
        // inputProps={{
        //   maxLength: val?.obj['\u0000*\u0000parm'].maxLength,
        // }}
        placeholder={val.helpertext}
        isDisabled={headerDisabled || val?.attr?.includes('readonly') || val.id === 'access_profile'}
      />
    );
  }
  return (
    <CustomInput
      label={val?.label}
      required={val?.required}
      name={val?.id}
      isBatch={isBatch}
      defaultValue={val?.default}
      value={values[val?.id]}
      onChange={handleInputChange}
      size={size}
      isDisabled={headerDisabled || val?.attr?.includes('readonly') || val.id === 'access_profile'}
      placeholder={val?.helpertext}
      maxLength={val?.obj?.['\u0000*\u0000parm'].maxLength}
      inputProps={{
        maxLength: val?.obj?.['\u0000*\u0000parm'].maxLength,
      }}
      validate={val?.validate}
      type="text"
      margin={margin}
      datagrid={datagrid}
    />
  );
};

export default CustomField;
