import React from 'react';
import { useParams } from 'react-router-dom';

export const DetailRecordView: React.FC = () => {
  const { userId } = useParams();
  const message = `Detailed User Session Screen for id ${userId}`;
  return (
    <h2>
      {message}
    </h2>
  );
};
