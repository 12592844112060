import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Search } from '@mui/icons-material';
import './HomeSearchInput.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import { getScreensList, LoadRecord, LoadTables } from '../../redux/actions/ScreenActions';
import useWindowDimensions from '../../utils/windowDimensions';
import { currentReport } from '../../redux/actions/reports/reports';
import { currentUtility } from '../../redux/actions/systemUtilities/systemUtilities';
import ConfirmationModalLoseChanges from '../Reports/Workflows/ConfirmationModal';

const SmartNavSearch = (props:any) => {
  const {
    isModifiy,
    setVisibleConfirm,
    setRecentScreen,
  } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = React.useState<any>({});
  const [showModal, setShowModal] = React.useState(false);
  const { height, width } = useWindowDimensions();
  const { ScreensList, loading } = useSelector((state: any) => state.ScreenReducer);
  const {
    IsReportEdit,
  } = useSelector(
    (state: any) => state?.Reports?.Reports,
  );
  return (
    <>
      <ConfirmationModalLoseChanges
        Visible={showModal}
        setShowModal={setShowModal}
        option={selectedOption}
      />
      <Autocomplete
        freeSolo
        disableClearable
        id="free-solo-2-demo"
        placeholder="Good Afternoon. How Can I help you"
        options={ScreensList}
        onChange={async (event: any, option: any) => {
          if (isModifiy) {
            setVisibleConfirm(true);
            setRecentScreen(option);
          } else {
            if (IsReportEdit) {
              // eslint-disable-next-line no-alert
              setSelectedOption(option);
              setShowModal(true);
              return;
            }
            if (option.type === 'Utility') {
              dispatch(currentUtility({
                reportId: option.reportId,
                heading: option.label,
                progName: option.progName,
              }));
              navigate('/workflow/system-utility');
              return;
            }
            if (option.type === 'WorkflowsReport') {
              dispatch(currentReport({
                reportId: option.reportid,
                heading: option.label,
                progName: option.prog_name,
              }));
              navigate('/workflow/reports');
              return;
            }
            if (option.type === 'Workflows') {
              navigate(`${option.path}`);
              return;
            }
            navigate('/home');

            const fields = Array.from(new Set([...option.fields.split(',')])).join(',');
            dispatch(
              LoadTables({
                tableID: option.tableId,
                page: '1',
                results_per_page: `${Math.ceil(height / 60)}`,
                fields: option.fields ? fields : '',
                fieldsConcatenated: option.fieldsConcatenated,
                order_by: '',
                where: option.screenConfiguration || '',
                redirect: `/maint/${option.screenId}`,
                screenId: option.screenId,
                keys: ScreensList.filter((item:any) => item.screenId === option.screenId)[0].keys || '',
                defaultcolumns: ScreensList.filter((item:any) => item.screenId === option.screenId)[0].defaultcolumns || '',
                navigate,
              }),
            );
            dispatch(LoadRecord({
              tableID: option.tableId,
              screenID: option.screenId,
              fields: option.fields || '',
              where: '',
            }));
          }
        }}
        sx={{ width: '300px' }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Good Afternoon. How Can I help you"
            size="small"
            color="info"
            sx={{
              backgroundColor: '#ececec',
              paddingLeft: '10px',
            }}
            variant="standard"
          />
        )}
      />
    </>
  );
};

export default SmartNavSearch;
