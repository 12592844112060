/* eslint-disable react/require-default-props */
import { Autocomplete, TextField } from '@mui/material';
import React from 'react';

// Styles
import { useStyles } from './style';

interface ISelectProps {
  options: any[];
  width?: number | string;
  value?: any;
  style?: React.CSSProperties;
  onChange?: (e: any, option: any) => void;
  disableClearInput?: boolean;
  getOptionLabel?: (option: any) => string;
  name?: string;
  placeholder?: string;
  disabled?: boolean;
  defaultValue?: any;
  color?: string;
}

const ContractDispatchDropdown: React.FC<ISelectProps> = ({
  options,
  width,
  value,
  style,
  onChange,
  disableClearInput,
  getOptionLabel,
  name,
  disabled,
  placeholder,
  defaultValue,
  color,
}) => {
  const classes = useStyles();
  return (
    <Autocomplete
      getOptionLabel={getOptionLabel}
      disableClearable={disableClearInput || false}
      disabled={disabled}
      style={{ width, ...style }}
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      classes={{
        root: classes.root,
        input: color !== 'red' ? classes.autoCompleteInput : classes.autoCompleteInput1,
      }}
      renderInput={(params: any) => (
        <TextField
          classes={{ root: classes.input }}
          placeholder={placeholder || '-- Select --'}
          name={name}
          {...params}
        />
      )}
      options={options}
    />
  );
};

export default ContractDispatchDropdown;
