import React, { useEffect, useState } from 'react';

// Librairies
import { InputBase } from '@mui/material';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';

// Components
import SubHeader from '../../../components/subHeader';
import TmsHeader from '../../../components/header';
import VehiclesInFacilityTable from '../../../components/vehiclesInFacilityTable';

// Actions
import { getVehiclesInFacilityData } from '../../../redux/actions/workflows/VehiclesInFacility';
import { getScreensList } from '../../../redux/actions/ScreenActions';

// Styles
import { useStyles } from './style';

// Utils
const tableHeader = [
  { label: '', key: 'button' },
  { label: 'Carrier', key: 'carrier_name' },
  { label: 'Vehicle Identification', key: 'vehicle_id' },
  { label: 'Vehicle Type', key: 'veh_type' },
  { label: 'License Number', key: 'license_plate' },
  { label: 'Entry Date / Time', key: 'entry_datetime' },
  { label: 'Driver', key: 'driver' },
];

const VehiclesInFacility: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [orderBy, setOrderBy] = useState<string>('');
  const [order, setOrder] = useState<string>('asc');
  const { data } = useSelector((state: any) => state.Workflows.VehiclesInFacility);

  useEffect(() => {
    dispatch(getVehiclesInFacilityData());
    dispatch(getScreensList());
  }, []);

  const tableHeaderItems = React.useMemo(() => {
    if (data?.headerList?.length > 0) {
      return data?.headerList?.map((item: any) => ({
        ...item,
        key: item?.id,
        label: item?.header,
      }));
    }
    return [];
  }, [data]);

  const handleSortTable = (key: string) => {
    setOrderBy(key);
    setOrder(order === 'asc' ? 'desc' : 'asc');
  };

  return (
    <>
      <TmsHeader />
      <SubHeader title="Vehicles in Facility" />
      <div
        className={clsx('flexStartSpaceBetweenRow', classes.subHeaderContainer)}
      >
        <div />
        <div className="flexRow">
          <InputBase className={classes.input} placeholder="Search" />
          <span className={classes.helperText}>{`Viewing 0 of ${data?.data?.length ?? 0}`}</span>
        </div>
      </div>
      <VehiclesInFacilityTable
        handleClickRow={() => null}
        headerItems={tableHeader}
        data={data?.data}
        showPagination={false}
        showVisibleEntries={false}
        order={order}
        orderBy={orderBy}
        sortTable={handleSortTable}
      />
    </>
  );
};

export default VehiclesInFacility;
