import { GetWorkflow70InitData } from '../../actions/workflows/rcuDitto';
import { GET_INIT_DATA_RCU_DITTO } from '../../constants/workflows';

export interface RcuDittoState {
  screenTitle: string;
  RCUBayList: Array<{
    id: string;
    name: string;
    screenData: string;
  }>;
  bayIDList: Array<string>;
}

const INITIAL_STATE: RcuDittoState = {
  screenTitle: '',
  RCUBayList: [],
  bayIDList: [],
};

const rcuDitto = (state = INITIAL_STATE, { type, payload }: any) => {
  switch (type) {
    case GET_INIT_DATA_RCU_DITTO: {
      const data = payload as GetWorkflow70InitData;
      return {
        ...state,
        screenTitle: data.screenTitle,
        RCUBayList: data.baysdittolist.map((bay) => ({
          id: bay.bayid,
          name: bay.bayName,
          screenData: bay.screendata,
        })),
        bayIDList: data.bayIDList,
      };
    }
    default:
      return state;
  }
};

export default rcuDitto;
