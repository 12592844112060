import { SAVE_PRODUCTS_ADD_AUTH_PRODS } from '../../constants/workflows';

const INITIAL_STATE = {
  TerminalProducts: [],
  AccountProducts: [],
};

const AddAuthProductsReducer = (state = INITIAL_STATE, { type, payload }: any) => {
  switch (type) {
    case SAVE_PRODUCTS_ADD_AUTH_PRODS: {
      return {
        ...state,
        TerminalProducts: payload.TerminalProducts,
        AccountProducts: payload.AccountProducts,
      };
    }
    default:
      return state;
  }
};

export default AddAuthProductsReducer;
