import {
  GENERATE_REPORT_RESPONSE,
  GET_REPORT_CUSTOMERS,
  GET_REPORT_FOLIOMONTH,
  GET_REPORT_FOLIONUMBER,
  GET_REPORT_PRODUCTS,
  LOAD_REPORT_FIELDS,
  LOAD_REPORT_SCREEN,
  SET_FORM_EDIT_STATE,
} from '../../constants/workflows';

const INITIAL_STATE = {
  fields: [],
  savedResponse: {},
  reportId: '',
  heading: '',
  progName: '',
  IsReportEdit: false,
};

const reports = (
  state = INITIAL_STATE,
  { type, payload }: any,
) => {
  switch (type) {
    case LOAD_REPORT_FIELDS: {
      return {
        ...state,
        fields: payload,
      };
    }
    case GET_REPORT_CUSTOMERS: {
      const data = state.fields.map((item :any) => {
        if (item.name === 'rptfield_C') {
          return payload;
        }
        return item;
      });
      return {
        ...state,
        fields: data,
      };
    }
    case GET_REPORT_FOLIOMONTH: {
      const data = state.fields.map((item :any) => {
        if (item.name === 'rptfield_F_month_F'
        || item.name === 'rptfield_F_month_j'
        || item.name === 'rptfield_F_month_i') {
          return {
            ...item,
            options: payload.options,
            defaultValue: payload.defaultValue,
            key: payload.key,
          };
        }
        return item;
      });
      return {
        ...state,
        fields: data,
      };
    }
    case GET_REPORT_FOLIONUMBER: {
      const data = state.fields.map((item :any) => {
        if (item.name === 'rptfield_F_num_F'
        || item.name === 'rptfield_F_num_j'
        || item.name === 'rptfield_F_num_i') {
          return {
            ...item,
            options: payload.options,
            defaultValue: payload.defaultValue,
            key: payload.key,
          };
        }
        return item;
      });
      return {
        ...state,
        fields: data,
      };
    }
    case GENERATE_REPORT_RESPONSE: {
      return {
        ...state,
        savedResponse: payload,
      };
    }
    case LOAD_REPORT_SCREEN: {
      return {
        ...state,
        reportId: payload?.reportId,
        heading: payload?.heading,
        progName: payload?.progName,
      };
    }
    case GET_REPORT_PRODUCTS: {
      const data = state.fields.map((item :any) => {
        if (item.name === 'rptfield_p') {
          return payload;
        }
        return item;
      });
      return {
        ...state,
        fields: data,
      };
    }
    case SET_FORM_EDIT_STATE: {
      return {
        ...state,
        IsReportEdit: payload,
      };
    }
    default:
      return state;
  }
};

export default reports;
