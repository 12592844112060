import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  subNavContainer: {
    height: '40px',
    textAlign: 'start',
    margin: '5px 0px 0px 0px',
    backgroundColor: '#eeeeee',
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: ' 5px',
  },
  titleText: {
    padding: '0 5px 0 5px',
    fontSize: '22px',
    letterSpacing: '-1px',
    color: '#666',
    margin: '0 0 5px 0',
    fontWeight: '100',
  },
  layoutName: {
    fontSize: '14px',
    letterSpacing: ' 0px',
    fontWeight: '300',
    color: '#888',
  },
  flexContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    marginTop: '2px',
    height: 'calc(100vh - 40px)',
  },
  divBox: {
    width: '100%',
    margin: '5px',
  },
  triggerButton: {
    position: 'fixed',
    cursor: 'pointer',
    // top:' 5px',
    left: 'calc(50% - 40px)',
    border: '1px solid #717171',
    background: '#205081',
    boxShadow: ' 0 4px 5px #6f6f6f',
    padding: '4px 10px',
    color: 'white',
    fontSize: '12px',
  },
  layoutDrawerContainer: {
    position: 'absolute',
    top: '28px',
    right: '80px',
    color: '#000',
    backgroundColor: 'white',
    zIndex: '4',
    minWidth: '200px',
    overflow: 'auto',
    border: '1px solid #aaa',
    marginTop: '10px',
    padding: '5px',
    cursor: 'pointer',
  },
  navDropdown: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    padding: '0px 5px',
    height: '40px',
    cursor: 'pointer',
    justifyContent: 'center',
  },
  activeLayout: {
    background: '#205081',
    padding: '3px',
  },
  layoutSection: {
    '&:hover': {
      background: '#56a8d9',
      padding: '3px',
    },
  },
  layoutA: {
    height: '50px',
    margin: '2px',
    backgroundColor: '#cdc7c7',
    width: '50%',
  },
  layoutB1: {
    height: '50px',
    margin: '2px',
    backgroundColor: '#cdc7c7',
    width: '33.33%',
  },
  layoutB2: {
    height: '50px',
    margin: '2px',
    backgroundColor: '#cdc7c7',
    width: '77%',
  },
  layoutC: {
    height: '100px',
    margin: '2px',
    backgroundColor: '#cdc7c7',
    width: '50%',
  },
  layoutD: {
    height: '50px',
    margin: '2px',
    backgroundColor: '#cdc7c7',
    width: '100%',
  },
  layoutE: {
    height: '100px',
    margin: '2px',
    backgroundColor: '#cdc7c7',
    width: '33.33%',
  },
  optionUi: {
    position: 'absolute',
    zIndex: 1,
    width: 'max-content',
    listStyle: 'none',
    right: 5,
    fontSize: '12px',
    top: 21,
  },
  optionLi: {
    width: '160px',
    border: '1px solid',
    padding: '5px 5px',
    background: 'white',
  },
  activeScale: {
    background: '#205081',
    color: 'white !important',
  },
});
