/* eslint-disable react/require-default-props */
import { Autocomplete, TextField, Typography } from '@mui/material';
import React from 'react';

// Styles
import { useStyles } from './style';

interface ISelectProps {
  label: string;
  options: any[];
  width?: number | string;
  value?: any;
  style?: React.CSSProperties;
  onChange?: (e: any, option: any) => void;
  disableClearInput?: boolean;
  getOptionLabel?: (option: any) => string;
  name?: string;
  placeholder?: string;
  disabled?: boolean;
  defaultValue?: any;
}

const WorkflowDropdown: React.FC<ISelectProps> = ({
  label,
  options,
  width,
  value,
  style,
  onChange,
  disableClearInput,
  getOptionLabel,
  name,
  disabled,
  placeholder,
  defaultValue,
}) => {
  const classes = useStyles();
  return (
    <>
      <Typography variant="caption" className={classes.label}>{label}</Typography>
      <Autocomplete
        getOptionLabel={getOptionLabel}
        disableClearable={disableClearInput || false}
        disabled={disabled}
        style={{ width, ...style }}
        value={value}
        onChange={onChange}
        defaultValue={defaultValue}
        classes={{ root: classes.root, input: classes.autoCompleteInput }}
        renderInput={(params: any) => (
          <TextField
            classes={{ root: classes.input }}
            placeholder={placeholder || '-- Select --'}
            name={name}
            {...params}
          />
        )}
        options={options}
      />
    </>
  );
};

export default WorkflowDropdown;
