/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable implicit-arrow-linebreak */
import React from 'react';

// Libraries
import { createPortal } from 'react-dom';

// Components
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
} from 'react-beautiful-dnd';

// Styles
import { useStyles } from './style';

const primaryButton = 0;

const dragEl: any = document.getElementById('draggable');
export const optionalPortal = (styles: any, element: any) => {
  if (styles.position === 'fixed') {
    return createPortal(element, dragEl);
  }
  return element;
};

interface IProps {
  columnsLabels: { [key: string]: string };
  field: string;
  index: number;
  isSelected: boolean;
  isGhosting: boolean;
  selectionCount: number;
  draggingColumnId: string;
  toggleSelection: (columnId: string) => void;
  toggleSelectionInGroup: (columnId: string) => void;
  multiSelectTo: (columnId: string) => void;
}

const keyCodes = {
  enter: 13,
  escape: 27,
  arrowDown: 40,
  arrowUp: 38,
  tab: 9,
};

const Column: React.FC<IProps> = ({
  field,
  index,
  columnsLabels,
  isGhosting,
  isSelected,
  multiSelectTo,
  toggleSelection,
  toggleSelectionInGroup,
  draggingColumnId,
}) => {
  const classes = useStyles();

  const getItemStyle = (providedChild: any, snapshotChild: any) => ({
    border: '1px solid #f2f2f2',
    opacity: isGhosting ? 0.8 : '',
    fontSize: 12,
    fontWeight: '400',
    width: '95%',
    backgroundColor: isSelected ? '#ffe284' : (snapshotChild.isDragging ? '#ffe284' : '#ffffff'),
    display: isSelected && draggingColumnId && !snapshotChild.isDragging ? 'none' : undefined,
    color: '#000',
    ...providedChild.draggableProps.style,
  });

  const onKeyDown = (
    event: any,
    provided: DraggableProvided,
    snapshot: DraggableStateSnapshot,
  ) => {
    if (event.defaultPrevented) {
      return;
    }

    if (snapshot.isDragging) {
      return;
    }

    if (event.keyCode !== keyCodes.enter) {
      return;
    }
    event.preventDefault();
  };
  const onClick = (event: any) => {
    if (event.defaultPrevented) {
      return;
    }

    if (event.button !== primaryButton) {
      return;
    }
    event.preventDefault();
    performAction(event);
  };

  const onTouchEnd = (event: any) => {
    if (event.defaultPrevented) {
      return;
    }
    event.preventDefault();
  };

  const wasToggleInSelectionGroupKeyUsed = (
    event: MouseEvent | KeyboardEvent,
  ) => {
    const isUsingWindows = navigator.platform.indexOf('Win') >= 0;
    return isUsingWindows ? event.ctrlKey : event.metaKey;
  };

  const wasMultiSelectKeyUsed = (event: MouseEvent | KeyboardEvent) =>
    event.shiftKey;

  const performAction = (event: MouseEvent | KeyboardEvent) => {
    if (wasToggleInSelectionGroupKeyUsed(event)) {
      toggleSelectionInGroup(field);
      return;
    }

    if (wasMultiSelectKeyUsed(event)) {
      multiSelectTo(field);
      return;
    }

    toggleSelection(field);
  };

  return (
    <Draggable key={field} draggableId={field} index={index}>
      {(providedChild, snapshotChild) => (
        <>
          {optionalPortal(
            providedChild.draggableProps.style,
            <div
              onKeyDown={(event: any) =>
                onKeyDown(event, providedChild, snapshotChild)}
              onClick={onClick}
              onTouchEnd={onTouchEnd}
              className={classes.field}
              ref={providedChild.innerRef}
              {...providedChild.draggableProps}
              {...providedChild.dragHandleProps}
              style={getItemStyle(providedChild, snapshotChild)}
            >
              {columnsLabels?.[field]}
            </div>,
          )}
        </>
      )}
    </Draggable>
  );
};

export default Column;
