import React, { useEffect, useState } from 'react';

// Components
// eslint-disable-next-line object-curly-newline
import { Alert, Snackbar, AlertTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { useDispatch, useSelector } from 'react-redux';
import { savedResponseClear } from '../../../redux/actions/workflows/rackStatus';

export const ResponseAlert = () => {
  const [play, setPlay] = useState(false);
  const dispatch = useDispatch();
  const [openConfirmationAlert, setOpenConfirmationAlert] = useState<boolean>(false);
  const { savedResponse } = useSelector((state: any) => state?.Workflows?.RackStatus);
  const handleClose = () => {
    setOpenConfirmationAlert(false);
    dispatch(savedResponseClear());
  };
  useEffect(() => {
    if (savedResponse?.type) {
      setOpenConfirmationAlert(true);
    }
  }, [savedResponse]);
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={openConfirmationAlert}
        onClose={handleClose}
        autoHideDuration={!play ? 6000 : null}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        ClickAwayListenerProps={{ onClickAway: () => {} }}
      >
        <Alert
          severity={savedResponse?.type}
          action={(
            <>
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setPlay((pause) => !pause);
                }}
              >
                {play ? (
                  <PlayArrowIcon fontSize="inherit" />
                ) : (
                  <PauseIcon fontSize="inherit" />
                )}
              </IconButton>
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  handleClose();
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            </>
          )}
        >
          <AlertTitle>{savedResponse?.title}</AlertTitle>
          {savedResponse?.text}
        </Alert>
      </Snackbar>
    </>
  );
};
