import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    padding: '0px 10px',
  },
  tableRoot: {},
  headerCell: {
    borderBottom: 'none !important',
    padding: '0px !important',
  },
  headerRow: {
    boxShadow: '0 1px 7px #555',
  },
  bodyCell: {
    borderBottom: 'none !important',
    padding: '8px 0px !important',
  },
  bodyCellEmpty: {
    borderBottom: 'none !important',
    padding: '8px 7px !important',
  },
  tableRow: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#fbf8e9 !important',
    },
  },
  footerWrapper: { marginTop: 5 },
  showingText: { fontSize: 12, fontWeight: 400, color: 'black' },
  paginationContainer: {
    marginTop: 5,
  },
  actionButton: {
    border: 'solid 1px #ddd !important',
    padding: '0px 1px !important',
    '&:hover': {
      backgroundColor: '#e6e6e6 !important',
      borderColor: '#adadad !important',
    },
  },
  actionsWrapper: {
    display: 'flex',
    gap: 10,
  },
  detailGroup: {
    marginLeft: '10%',
    marginBottom: '5px',
    border: '1px dashed #aeaeae',
    padding: '4px 5px',
  },
  stepInfo: {
    boxShadow: '1px 2px 9px #e6f3fd',
    width: '55vw',
    marginLeft: 0,
    borderBottom: '1px solid #dedede',
    borderCollapse: 'collapse',
    margin: '0 auto',
    marginRight: '2vw',
    fontSize: 12,
  },
  fontBold: {
    fontWeight: 'bold',
  },
  prodInfo: {
    width: '85%',
    marginLeft: '5vw',
    marginTop: '10px',
    marginBottom: '10px',
  },
  prodStatusValue: {
    backgroundColor: ' #95E695',
    padding: '1px 5px',
    width: '125px',
    textAlign: 'center',
  },
  prodInfoCell: { borderBottom: '1px solid #111' },
  sortLabel: {
    display: 'flex !important',
    justifyContent: 'space-between !important',
  },
});
