/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState } from 'react';

// Librairies
import { useDispatch, useSelector } from 'react-redux';

// Components
import {
  Alert, Snackbar, AlertTitle, IconButton, CircularProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { useNavigate } from 'react-router-dom';
import TmsHeader from '../../../components/header';
import SubHeader from '../../../components/subHeader';
// import Loader from '../../../components/Loader';

// Styles
import { useStyles } from './style';

const ViewUtility: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [openConfirmationAlert, setOpenConfirmationAlert] = useState(true);
  const [play, setPlay] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);
  const [modalType, setModalType] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const {
    data,
    seqno,
  } = useSelector(
    (state: any) => state?.SystemUtilities?.ViewSystemUtility,
  );
  const { loading, stepperData } = useSelector(
    (state: any) => state.ScreenReducer,
  );
  const handleClose = () => {
    setOpenConfirmationAlert(false);
    navigate(-1);
  };
  // This function for Buttons to go to Page
  const goToPageBtn = (pageNo: any) => {
    // eslint-disable-next-line no-unused-expressions
    document!.getElementById(`page${pageNo}`)!.scrollIntoView({
      behavior: 'smooth',
    });
  };
  // This function for Input field to go to PAge
  const goToPage = (e: any) => {
    console.log(e.target.value);
    // eslint-disable-next-line no-unused-expressions
    document!.getElementById(`page${e.target.value}`)!.scrollIntoView({
      behavior: 'smooth',
    });
  };
  return loading ? (
    <div
      style={{
        width: '100%',
        height: '90vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress />
    </div>
  ) : (
    <>
      <TmsHeader />
      <SubHeader title="View System Utility" />
      {!data?.type ? (
        <>
          <div className={classes.printjumpquick}>
            {data.length !== 0 ? data?.groupIndexList.map((currIndex: number) => {
              if (data?.numPages < 4) {
                return (
                  <button
                    type="button"
                    className={classes.systemgreybtn}
                    onClick={() => goToPageBtn(1)}
                  >
                    Page
                    {' '}
                    {1}
                  </button>
                );
              }
              const btns = [currIndex, currIndex + 1, currIndex + 2].map((pagebtn: number) => (
                <>
                  <button
                    type="button"
                    className={classes.systemgreybtn}
                    onClick={() => goToPageBtn(pagebtn)}
                  >
                    Page
                    {' '}
                    {pagebtn}
                  </button>
                </>
              ));
              btns.push(<span>----</span>);
              return (
                <>
                  {btns}
                </>
              );
            }) : '' }
            <div
              style={{ display: 'inline-block', fontSize: '12px' }}
            >
              Go To Page
              <input type="text" onKeyUp={goToPage} />
            </div>
          </div>
          {!data.type ? (
            <div className={classes.printarea}>
              {data.length !== 0 ? data?.rpt.map((rptpage: any, index: any) => (
                <div id={`page${index + 1}`} className="reportpage">
                  {rptpage.length !== 0 ? (
                    <pre className={classes.report}>
                      <div
                        className={classes.reportpagebody}
                        style={{ height: rptpage.length === 0 ? '30' : rptpage.length * 20 }}
                      >
                        {rptpage.map((item: any) => (
                          <div className={classes.reportline}>{item === '&nbsp;' ? ' ' : item}</div>
                        ))}
                      </div>
                    </pre>
                  ) : ''}
                  <div className={classes.spacer} />
                </div>
              )) : '' }
            </div>
          ) : '' }
        </>
      ) : '' }
      {data.type ? (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={openConfirmationAlert}
          onClose={handleClose}
          autoHideDuration={!play ? 6000 : null}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          ClickAwayListenerProps={{ onClickAway: () => {} }}
        >
          <Alert
            severity={data?.type}
            action={(
              <>
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setPlay((pause) => !pause);
                  }}
                >
                  {play ? <PlayArrowIcon fontSize="inherit" /> : <PauseIcon fontSize="inherit" />}
                </IconButton>
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              </>
            )}
          >
            <AlertTitle>{data?.title}</AlertTitle>
            {data?.msg}
          </Alert>
        </Snackbar>
      ) : '' }
    </>
  );
};

export default ViewUtility;
