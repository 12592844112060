import React from 'react';

// Libraries
import {
  ButtonBase,
  DialogActions,
  Divider,
  TextareaAutosize,
  Typography,
} from '@mui/material';
import clsx from 'clsx';

// Components
import { DialogComponent } from '../Dialog/Dialog.component';

// Styles
import { useStyles } from './style';

interface IProps {
  onClose: () => void;
  open: boolean;
  returnedText: string | undefined;
}

const SystemStartupDialog: React.FC<IProps> = ({
  onClose,
  open,
  returnedText,
}) => {
  const classes = useStyles();
  return (
    <DialogComponent title="Condition" onClose={onClose} open={open}>
      <div className={clsx('flexRow', classes.wrapper)}>
        <Typography className={classes.label}>Details</Typography>
        {/* <TextareaAutosize
          className={classes.textArea}
          disabled
          value={returnedText || ''}
        /> */}
      </div>
      <Divider variant="middle" />
      <DialogActions>
        <ButtonBase classes={{ root: classes.closeButton }} onClick={onClose}>
          Close
        </ButtonBase>
      </DialogActions>
    </DialogComponent>
  );
};

export default SystemStartupDialog;
