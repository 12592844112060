type LoggingMethod = (...data: Array<any>) => void;
interface ILogger {
  log: LoggingMethod;
  logError: LoggingMethod;
}
/**
 * hook used to simplify logging to our app by centralizing a logging mechanism.
 * @param loggerName the source of logging messages.
 */
export const useLogger = (loggerName: string) : ILogger => {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const log = (...data: Array<any>) => {
  };
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const logError : LoggingMethod = (...data: Array<any>) => {
  };

  return {
    log,
    logError,
  };
};
