import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    paddingLeft: '10px',
    paddingBottom: '5px',
    overflow: 'auto',
  },
  tableRoot: {
    '&:before': {
      content: '"\u200C"',
      display: 'block',
      lineHeight: '8px',
    },
  },
  tableBody: {
    '&:before': {
      content: '"-"',
      display: 'block',
      lineHeight: '10px',
      color: 'transparent',
    },
  },
  tableWrapper: {},
  headerCell: {
    borderBottom: 'none !important',
    padding: '5px 9px !important',
    fontWeight: 'bold !important',
    border: '1px solid #fff',
    backgroundColor: '#fff !important',
    color: '#222 !important',
    whiteSpace: 'nowrap',
    fontSize: '90% !important',
  },
  headerRow: {
    boxShadow: '0 1px 7px #555',
  },
  bodyCell: {
    borderBottom: 'none !important',
    padding: '5px 9px !important',
    whiteSpace: 'nowrap',
  },
  bodyCellEmpty: {
    borderBottom: 'none !important',
    padding: '8px 7px !important',
    backgroundColor: '#9696961a !important',
  },
  tableRow: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#fbf8e9 !important',
    },
  },
  footerWrapper: { marginTop: 5, paddingLeft: 10, paddingRight: 5 },
  showingText: { fontSize: 12, fontWeight: 400, color: 'black' },
  paginationContainer: {
    marginTop: 5,
  },
  sortLabel: {
    display: 'flex !important',
    justifyContent: 'space-between !important',
  },
  loadingLabel: {
    margin: '0px 9px',
    fontWeight: 'bold',
    fontStyle: 'italic',
    color: 'red',
  },
});
