/* eslint-disable max-len */
/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';

// Styles
import { useStyles } from './style';

const CustomReportFolderTree: React.FC = () => {
  const classes = useStyles();
  const [collapse, setCollapse] = useState(true);
  return (
    <>
      <div>
        <ol className={classes.sortable}>
          <li>
            <div className={classes.menuDiv}>
              <button type="button" className={classes.collapsebtn} onClick={() => setCollapse(!collapse)}>{collapse ? '-' : '+'}</button>
              <span className={classes.itemTitle}>
                <input type="text" className={classes.folderName} value="TMSTEst" />
              </span>
              <span>
                <button type="button">x</button>
              </span>
            </div>
            {collapse && (
            <ol className={classes.sortable1}>
              <li>
                <div className={classes.menuDiv}>
                  <button type="button" className={classes.collapsebtn}>+</button>
                  <span className={classes.itemTitle}>
                    <input type="text" className={classes.folderName} value="Allocation Report By Jorge" />
                  </span>
                  <span>
                    <button type="button">x</button>
                  </span>
                </div>
              </li>
            </ol>
            )}
          </li>
        </ol>
      </div>
    </>
  );
};

export default CustomReportFolderTree;
