/* eslint-disable operator-linebreak */
import { Dispatch } from 'redux';
import { getChilds } from '../../../components/TransactionViewerSearchCrad';
import { isEmpty } from '../../../utils';
import Api from '../../api/index';
import {
  LOAD_ALL_TRANSACTION_VIEWER_DATA,
  LOAD_TRANSACTION_VIEWER,
  SET_TRANSACTION_VIEWER_LOADING,
  LOAD_TRANSACTION_VIEWER_COLUMNS,
  CLEAR_TRANSACTION_VIEWER,
  CLEAR_TRANSACTION_VIEWER_EXPORT_FILE,
  CLEAR_TRANSACTION_VIEWER_EXPORT_LINK,
  SET_TRANSACTION_VIEWER_EXPORT_FILE,
  SET_TRANSACTION_VIEWER_EXPORT_LINK,
  SET_TRANSACTION_VIEWER_RECORD_DETAIL,
  CLEAR_TRANSACTION_VIEWER_RECORD_DETAIL,
  SET_TRANSACTION_VIEWER_FILTERS,
  CLEAR_TRANSACTION_VIEWER_FILTERS,
} from '../../constants/workflows';

// utils
const getHeaderList = (headerList: any[]) => headerList.reduce(
  (acc: any, curr: any) => ({
    ...acc,
    [Object.keys(curr)?.[0]]: curr[Object.keys(curr)?.[0]],
  }),
  {},
);

const makeBasicTabFilters = (selectedFields: any) => Object.values(selectedFields).map(
  (field: any) => `${field.field.value}:${field.condition.value}:${field.value}`,
);

const getSearchHeader = (headerList: any[]) => headerList.map((elem: any) => ({
  key: Object.keys(elem)?.[0],
  label: elem[Object.keys(elem)?.[0]],
}));

export const getTransactionViewerData = () => async (dispatch: Dispatch) => {
  const userID = localStorage.getItem('username');
  try {
    // getFirst 50 row
    const response = await Api.get(
      `workflow191/data?screenID=workflow-140&groupID=Admin&userID=${userID}`,
    );
    const headerList = getHeaderList(response.data.headerList);
    const searchHeader = getSearchHeader(response.data.headerList);
    dispatch({
      type: LOAD_TRANSACTION_VIEWER,
      payload: {
        data: response.data.data,
        headerList,
        searchHeader,
        transStat: response.data.transStat,
        encodedTableData: response.data.encodedTableData,
      },
    });
    // get rest of data with lazy load request
    if (response.data.encodedTableData) {
      dispatch({
        type: SET_TRANSACTION_VIEWER_LOADING,
      });
      const payload = {
        queueid: 'DT',
        data: {
          cmd: 'datatable',
          mode: 'transview',
          dtid: 'translist',
          data: response.data.encodedTableData,
        },
        tagid: null,
        jqxhr: null,
      };
      const allDataRes = await Api.post('synajax/procajax', payload);
      dispatch({
        type: LOAD_ALL_TRANSACTION_VIEWER_DATA,
        payload: {
          data: [...response.data.data, ...allDataRes.data[0].data],
          transStat: allDataRes.data[0].transStat,
        },
      });
    }
  } catch (err) {
    throw new Error('Requets failed');
  }
};

export const getTransactionViewerColumns = () => async (dispatch: Dispatch) => {
  const userID = localStorage.getItem('username');
  try {
    const response = await Api.get(
      `workflow191/getcolumnslist?screenID=workflow-191&groupID=Admin&userID=${userID}`,
    );
    dispatch({
      type: LOAD_TRANSACTION_VIEWER_COLUMNS,
      payload: response.data,
    });
  } catch (err) {
    throw new Error('Requets failed');
  }
};

export const getTransactionViewerRecordDetail =
  (refid: string, cols: any) => async (dispatch: Dispatch) => {
    const rowindex = Number(String(refid).slice(-6)) - 1;
    const payload = {
      queueid: 'DT',
      data: {
        cmd: 'transref',
        rowindex,
        refid,
        cols,
      },
      tagid: null,
      jqxhr: null,
    };
    try {
      const response = await Api.post('synajax/procajax', payload);
      dispatch({
        type: SET_TRANSACTION_VIEWER_RECORD_DETAIL,
        payload: response.data[0],
      });
    } catch (err: any) {
      console.error(err.response.data);
    }
  };

export const updateTransactionViewerColumns =
  (columns: { [key: string]: string[] }) => async (dispatch: Dispatch) => {
    const userID = localStorage.getItem('username');
    await Api.post('workflow191/updatecolumns', { userID, columns });
    window.location.reload();
    dispatch({
      type: CLEAR_TRANSACTION_VIEWER,
    });
  };

export const applySearch = (data: any) => async (dispatch: Dispatch) => {
  const userID = localStorage.getItem('username');
  const basicTabFilter = makeBasicTabFilters(data.basicFilters.selectedFields);
  const requestData = {
    form_data: {
      userID,
      groupID: 'Admin',
      screenID: 'WORKFLOW-191',
      queryLimit: data?.headerForm?.resultLimit?.value || '',
      filterid: data?.headerForm?.filterId?.value || '',
      searchtab: data?.tabValue,
      updatefilter: data?.headerForm?.share?.value || '',
      lang: 'en-US',
      basicTabFilter,
      advanceTabFilter: {
        condition: 'AND',
        rules: [
          {
            id: 'h.transaction_date',
            field: 'h.transaction_date',
            type: 'date',
            input: 'text',
            operator: 'equal',
            value: '2023-02-01',
          },
          {
            condition: 'AND',
            rules: [
              {
                id: 'p.prod_id',
                field: 'p.prod_id',
                type: 'string',
                input: 'text',
                operator: 'equal',
                value: 'sadas',
              },
            ],
            not: true,
          },
          {
            id: 'm.comment',
            field: 'm.comment',
            type: 'string',
            input: 'text',
            operator: 'equal',
            value: 'dad',
          },
        ],
        not: false,
        valid: true,
      },
      publishselectstate: '',
      deletedcustomfilterlist: 'tms:_filter:WORKFLOW-191:tms',
      customfilterlist: 'sfsadasdasddasdasdasdasdasdasdasdasdasdasdasd==',
    },
  };
};

export const exportTransactionViewerFile = () => async (dispatch: Dispatch) => {
  const response = await Api.get('workflow191/ExportFile', {
    params: {
      screenID: 'WORKFLOW-191',
      termID: 'B344',
      tableID: 'trans-view',
      mode: 'xlsx',
      transactionDate: '221124',
      type: 'export',
    },
  });
  dispatch({
    type: SET_TRANSACTION_VIEWER_EXPORT_FILE,
    payload: response.data.data[0],
  });
};

export const getTransactionViewerDownloadLink =
  (exportFile: string) => async (dispatch: Dispatch) => {
    let attempts = 0;
    const downloadFn = () => {
      if (attempts === 10) return;
      Api.get(`export/download-file?file=${exportFile}`)
        .then((res: any) => {
          if (res.status === 200) {
            dispatch({
              type: SET_TRANSACTION_VIEWER_EXPORT_LINK,
              payload: res.data,
            });
          } else {
            setTimeout(downloadFn, 100);
            attempts += 1;
          }
        })
        .catch(() => {
          setTimeout(downloadFn, 100);
          attempts += 1;
        });
    };
    if (attempts === 10) return;
    downloadFn();
  };

export const getTransactionViewerFilters = () => async (dispatch: Dispatch) => {
  const userID = localStorage.getItem('username');
  const response = await Api.get(
    `workflow191/getfilter?screenID=workflow-191&groupID=Admin&userID=${userID}`,
  );
  dispatch({
    type: SET_TRANSACTION_VIEWER_FILTERS,
    payload: response.data.filterFields,
  });
};

export const clearTransactionViewerExportFile = () => ({
  type: CLEAR_TRANSACTION_VIEWER_EXPORT_FILE,
});

export const clearTransactionViewerFilters = () => ({
  type: CLEAR_TRANSACTION_VIEWER_FILTERS,
});

export const clearTransactionViewerDownloadLink = () => ({
  type: CLEAR_TRANSACTION_VIEWER_EXPORT_LINK,
});

export const clearTransactionViewerRecordDetail = () => ({
  type: CLEAR_TRANSACTION_VIEWER_RECORD_DETAIL,
});
