import React, { useEffect, useState } from 'react';

// Libraries
import {
  ButtonBase, DialogActions, Divider, Typography,
} from '@mui/material';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';

// Components
import { DialogComponent } from '../Dialog/Dialog.component';

// Styles
import { useStyles } from './style';
import Select from '../Select';
import { getCarriersList } from '../../redux/actions/workflows/driversInFacility';

interface IProps {
  onClose: () => void;
  open: boolean;
  modalDriversList: any[];
  modalCarriersList: any[];
}

const DriversInFacilityDialog: React.FC<IProps> = ({
  onClose,
  open,
  modalCarriersList,
  modalDriversList,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [modalState, setModalState] = useState(() => ({
    action: '',
    terminalId: { label: '', value: '' },
    driver: { name: '', driver_no: '' },
    carrier: { name: '', carr_no: '' },
  }));

  const handleOptionChange = (option: any, name: string) => {
    setModalState({ ...modalState, [name]: option });
  };

  useEffect(() => {
    if (modalState.driver.driver_no) {
      dispatch(getCarriersList(modalState.driver.driver_no));
    }
  }, [modalState.driver.driver_no]);

  return (
    <DialogComponent
      title="Driver In Facility Options"
      onClose={onClose}
      open={open}
    >
      <div className={classes.dialogBody}>
        <div className={clsx('flexRow', classes.wrapper)}>
          <Typography className={classes.label}>Action</Typography>
          <Select
            value={modalState.action}
            options={[]}
            width="75%"
            style={{ margin: '2px 10px' }}
          />
        </div>
        <Divider variant="middle" />
        <div className={clsx('flexRow', classes.wrapper)}>
          <Typography className={classes.label}>Terminal ID</Typography>
          <Select
            onChange={(e, option) => handleOptionChange(option, 'terminalId')}
            value={modalState.terminalId}
            options={[
              { label: 'B344 : TOPTECH (LONGWOOD, FL)', value: 'B344' },
            ]}
            width="75%"
            style={{ margin: '2px 10px' }}
          />
        </div>
        <div className={clsx('flexRow', classes.wrapper)}>
          <Typography className={classes.label}>Driver</Typography>
          <Select
            onChange={(e, option) => handleOptionChange(option, 'driver')}
            value={modalState.driver}
            options={modalDriversList}
            width="75%"
            style={{ margin: '2px 10px' }}
            getOptionLabel={(option) => (option.driver_no && option.name
              ? `${option.driver_no} : ${option.name}`
              : '')}
          />
        </div>
        <div className={clsx('flexRow', classes.wrapper)}>
          <Typography className={classes.label}>Carrier</Typography>
          <Select
            onChange={(e, option) => handleOptionChange(option, 'carrier')}
            value={modalState.carrier}
            options={modalCarriersList}
            width="75%"
            style={{ margin: '2px 10px' }}
            getOptionLabel={(option) => (option.carr_no && option.name
              ? `${option.carr_no} : ${option.name}`
              : '')}
          />
        </div>
      </div>
      <Divider variant="middle" />
      <DialogActions>
        <ButtonBase classes={{ root: classes.applyButton }} disabled>
          Apply
        </ButtonBase>
        <ButtonBase classes={{ root: classes.closeButton }} onClick={onClose}>
          Close
        </ButtonBase>
      </DialogActions>
    </DialogComponent>
  );
};

export default DriversInFacilityDialog;
