import {
  LOAD_EMCS_CONFIGURATION, UPDATE_EMCS_CONFIGURATION, DISSMISS_EMCS_CONFIGURATION_ALERT,
} from '../../constants/workflows';

const INITIAL_STATE = {
  emcsConfig: [],
  emcsUpdateResult: {},
  emcsOpenAlert: false,
};

const emcsConfigurationReducer = (state = INITIAL_STATE, { type, payload }: any) => {
  switch (type) {
    case LOAD_EMCS_CONFIGURATION: {
      return {
        ...state,
        emcsConfig: payload,
        emcsUpdateResult: {},
      };
    }
    case UPDATE_EMCS_CONFIGURATION: {
      return {
        ...state,
        emcsUpdateResult: payload,
        emcsOpenAlert: true,
      };
    }
    case DISSMISS_EMCS_CONFIGURATION_ALERT: {
      return {
        ...state,
        emcsOpenAlert: payload,
      };
    }
    default:
      return state;
  }
};

export default emcsConfigurationReducer;
