import { LOAD_TANK_STATUS } from '../../constants/workflows';

const INITIAL_STATE = {
  tanks: [],
};

const tankStatusReducer = (state = INITIAL_STATE, { type, payload }: any) => {
  switch (type) {
    case LOAD_TANK_STATUS: {
      return {
        ...state,
        tanks: payload,
      };
    }
    default:
      return state;
  }
};

export default tankStatusReducer;
