/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';

// Librairies
import { useSelector } from 'react-redux';

// Components
import TmsHeader from '../../../components/header';
import SubHeader from '../../../components/subHeader';
import Loader from '../../../components/Loader';

// Actions
import ButtonsHeader from '../../../components/TankStrappingTableButtonHeader';
import TankStrappingTablesBody from '../../../components/TankStrappingTablesBody';
import OptionsButton from '../../../components/optionsButton';
import TankStrappingTablesOptions from '../../../components/TankStrappingTablesOptions';
import TankStrappingTablesOptionsDialog from '../../../components/TankStrappingTablesOptionsDialog';

const TankStrappingTablesDetail = () => {
  const [formData, setFormData] = useState<any>({});
  const { data, detailsData } = useSelector(
    (state: any) => state?.Workflows?.TankStrapping,
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const handleChange = (option: any, name: string) => {
    setFormData({
      ...formData,
      [name]: option.value,
    });
  };
  const handleOptionClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleAnchorClose = () => {
    setAnchorEl(null);
  };
  const handleOpenDialog = (type: string) => {
    setOpenDialog(true);
    handleAnchorClose();
  };
  const handleCloseOpenDialog = () => {
    setOpenDialog(false);
  };
  return (
    <>
      <TmsHeader />
      <SubHeader title={`Tank Strapping Tables : Detail -  ${detailsData?.heading}`}>
        <OptionsButton handleClick={handleOptionClick} />
        <TankStrappingTablesOptions
          anchorEl={anchorEl}
          onClose={handleAnchorClose}
          handleOpenDialog={handleOpenDialog}
        />
      </SubHeader>
      <ButtonsHeader />
      <TankStrappingTablesBody />
      <TankStrappingTablesOptionsDialog
        onClose={handleCloseOpenDialog}
        open={openDialog}
        formData={formData}
        setFormData={setFormData}
      />
    </>
  );
};

export default TankStrappingTablesDetail;
