import React, { useEffect, useState } from 'react';

// Component
// Librairies
import {
  Paper,
  TextField,
  InputAdornment,
  Typography,
  Grid,
} from '@mui/material';
import clsx from 'clsx';

// Styles
import { useDispatch } from 'react-redux';
import { useStyles } from './style';
import DropdownReport from '../DropdownReport';
import DateTimePickerReport from '../DateTimePickerReport';

// Components
import Input from '../../../Input';
import CounterField from '../CounterField';
import { formInEdit } from '../../../../redux/actions/reports/reports';

interface ICardReportProps {
  heading: string;
  binary: string;
  fields: any;
  formData: any;
  setFormData: any;
  generateReport: any;
}
const CardReport: React.FC<ICardReportProps> = ({
  heading, binary, fields, formData, setFormData, generateReport,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [count, setCount] = useState<number>(0);
  // handleChange for Form Data
  const handleChange = (option: any, name: any) => {
    dispatch(formInEdit(true));
    setFormData({
      ...formData,
      [name]: option.value,
    });
  };

  return (
    <Paper elevation={3} className={classes.paper}>
      <div className={classes.modalHeader}>
        <h4 className={classes.modalTitle}>
          {heading}
          {' '}
          -
          {' '}
        </h4>
        <span className={classes.small}>
          (
          {binary}
          )
        </span>
      </div>
      <div className={classes.modalBody}>
        <form action="POST" className={clsx(classes.form, 'flexEnd')}>
          {/* this is for folio Month and Folio Number Dependent Dropdown */}
          {fields.map((item: any) => {
            if (item?.name === 'rptfield_F_month_F'
            || item?.name === 'rptfield_F_num_F'
            || item?.name === 'rptfield_F_month_j'
            || item?.name === 'rptfield_F_num_j'
            || item?.name === 'rptfield_F_month_i'
            || item?.name === 'rptfield_F_num_i') {
              return (
                <>
                  <div className={clsx('flexRow', classes.wrapper)}>
                    <DropdownReport
                      key={item?.key}
                      onChange={(e, option) => handleChange(option, item?.name)}
                      label={item.label}
                      options={item?.options}
                      defaultValue={item?.defaultValue}
                      width="100%"
                      // style={{ margin: '2px 10px' }}
                    />
                  </div>
                </>
              );
            }
            if (item?.name === 'rptfield_p') {
              return (
                <>
                  <div className={clsx('flexRow', classes.wrapper)}>
                    <DropdownReport
                      onChange={(e, option) => handleChange(option, item?.name)}
                      label={item.label}
                      options={item?.options}
                      defaultValue={item?.defaultValue}
                      width="75%"
                      style={{ margin: '2px 10px' }}
                    />
                  </div>
                </>
              );
            }
            // this for Text Field
            if (item?.name === 'rptfield_5') {
              return (
                <div className={clsx('flexRow', classes.wrapper)}>
                  <Grid container>
                    <Grid item lg={3} xs={12} md={3} />
                    <Grid item lg={1} xs={12} md={1}>
                      <div className={classes.centerText}>
                        <Typography
                          variant="caption"
                          className={classes.label}
                          align="right"
                        >
                          {item?.label}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item lg={8} xs={12} md={8}>
                      <Input
                        onChange={(e: any) => handleChange(e.target, item?.name)}
                        name={item?.name}
                        style={{
                          width: '100%',
                          marginRight: '8px',
                        }}
                        required={false}
                      />
                    </Grid>
                  </Grid>
                </div>
              );
            }
            // All Date Time Fields
            if (
              item?.name === 'rptfield_Z_start' || item?.name === 'rptfield_Z_end'
              || item?.name === 'rptfield_z') {
              return (
                <div className={clsx('flexRow', classes.wrapper)}>
                  <Grid container>
                    <Grid item lg={3} xs={12} md={3} />
                    <Grid item lg={1} xs={12} md={1}>
                      <div className={classes.centerText}>
                        <Typography
                          variant="caption"
                          className={classes.label}
                          align="right"
                        >
                          {item?.label}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item lg={8} xs={12} md={8}>
                      <DateTimePickerReport
                        handleChange={handleChange}
                        name={item?.name}
                      />
                    </Grid>
                  </Grid>
                </div>
              );
            }
            if (
              item?.name === 'rptfield_e'
              || item?.name === 'rptfield_D'
              || item?.name === 'rptfield_E') {
              return (
                <>
                  <div className={clsx('flexRow', classes.wrapper)}>
                    <CounterField
                      name={item?.name}
                      label={item?.label}
                      classes={classes}
                      handleChange={handleChange}
                      maxLength={item?.max}
                      step={item?.step}
                      min={item?.min}
                    />
                  </div>
                </>
              );
            }
            if (Array.isArray(item) && item.length === 0) {
              return <hr />;
              // this for Printer Selection Dropdown field
            } if (item?.name === 'rptfield_R') {
              return (
                <>
                  <hr className={classes.modalPrinter} />
                  <div className={clsx('flexRow', classes.wrapper)}>
                    <DropdownReport
                      label={item.label}
                      defaultValue={item?.options?.[0] || { value: '', label: '' }}
                      onChange={(e, option) => handleChange(option, item?.name)}
                      options={item?.options}
                      width="100%"
                      // style={{ margin: '2px 10px' }}
                    />
                  </div>
                </>
              );
            } if (item?.name === 'rptfield_YM_month'
            || item?.name === 'rptfield_P'
            ) {
              // This for Year Month and PRoduct Dropdown
              return (
                <>
                  <div className={clsx('flexRow', classes.wrapper)}>
                    <DropdownReport
                      label={item.label}
                      defaultValue={
                        item?.options?.[(new Date()).getMonth()] || { value: '', label: '' }
                      }
                      onChange={(e, option) => handleChange(option, item?.name)}
                      options={item?.options}
                      width="100%"
                      // style={{ margin: '2px 10px' }}
                    />
                  </div>
                </>
              );
            } if (item?.name === 'rptfield_YM_year' || item.name === 'rptfield_FY') {
              return (
                <>
                  <div className={clsx('flexRow', classes.wrapper)}>
                    <DropdownReport
                      label={item.label}
                      defaultValue={
                        item?.options?.at(-1) || { value: '', label: '' }
                      }
                      onChange={(e, option) => handleChange(option, item?.name)}
                      options={item?.options}
                      width="100%"
                      // style={{ margin: '2px 10px' }}
                    />
                  </div>
                </>
              );
            }
            // This for All General Dropdowns
            return (
              <div className={clsx('flexRow', classes.wrapper)}>
                <DropdownReport
                  label={item.label}
                  onChange={(e, option) => handleChange(option, item?.name)}
                  defaultValue={item?.options?.[0] || { value: '', label: '' }}
                  options={item.options}
                  width="100%"
                  // style={{ margin: '2px 10px' }}
                />
              </div>
            );
          })}
        </form>
      </div>
      <div className={classes.modalActions}>
        <button
          type="button"
          className={classes.generateButton}
          onClick={generateReport}
        >
          <svg
            className={classes.translate}
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 1536 1536"
          >
            <g transform="translate(1536 0) scale(-1 1)">
              <path
                fill="currentColor"
                // eslint-disable-next-line max-len
                d="M1152 672v192q0 13-9.5 22.5T1120 896H768v192q0 14-9 23t-23 9q-12 0-24-10L393 791q-9-9-9-23t9-23l320-320q9-9 23-9q13 0 22.5 9.5T768 448v192h352q13 0 22.5 9.5t9.5 22.5zm160 96q0-148-73-273t-198-198t-273-73t-273 73t-198 198t-73 273t73 273t198 198t273 73t273-73t198-198t73-273zm224 0q0 209-103 385.5T1153.5 1433T768 1536t-385.5-103T103 1153.5T0 768t103-385.5T382.5 103T768 0t385.5 103T1433 382.5T1536 768z"
              />
            </g>
          </svg>
          Generate Report
        </button>
      </div>
    </Paper>
  );
};

export default CardReport;
