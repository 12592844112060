import React, { useEffect, useRef } from 'react';

// Libraries
import {
  ButtonBase, DialogActions, Divider, TextField, Typography,
} from '@mui/material';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';

// Components
import JoditEditor from 'jodit-react';
import DropdownReport from '../DropdownReport';
import { DialogComponent } from '../Dialog/Dialog.component';
import Input from '../../../Input';

// Styles
import { useStyles } from './style';
import Select from '../../../Select';
import { getEmailGroup, getPrinters } from '../../../../redux/actions/reports/viewReport';

interface IProps {
  onClose: () => void;
  open: boolean;
  modalRouteCode:any[];
  type:string,
  setFormData: any;
  formData: any;
  handleSubmitModal: any;
  reportName: any;
}

const ViewReportOptionDialog: React.FC<IProps> = ({
  onClose,
  open,
  modalRouteCode,
  type,
  setFormData,
  formData,
  handleSubmitModal,
  reportName,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    emailGroup,
  } = useSelector(
    (state: any) => state?.Reports?.ViewReports,
  );
  const handleOptionChange = (option: any, name: string) => {
    setFormData({ ...formData, [name]: option.value });
  };
  useEffect(() => {
    dispatch(getPrinters());
    dispatch(getEmailGroup());
  }, [open]);
  function ValidateEmail(input: string) {
    const validRegex = /^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4},?)+$/;
    if (input.match(validRegex)) {
      return true;
    }
    return false;
  }
  return (
    <DialogComponent
      title={type}
      reportDescription={reportName}
      onClose={onClose}
      open={open}
    >
      <div className={classes.dialogBody}>
        {type === 'Print Report' ? (
          <>
            {' '}
            <div className={clsx('flexRow', classes.wrapper)}>
              <Typography className={classes.label}>Printer Selection</Typography>
              <Select
                onChange={(e, option) => handleOptionChange(option, 'routecode')}
                options={modalRouteCode}
                value={modalRouteCode.find((item: any) => item.value === formData.routecode)}
                width="75%"
                style={{ margin: '2px 10px' }}
                getOptionLabel={(option) => (option.label && option.value
                  ? `${option.label}`
                  : '')}
              />
            </div>
          </>
        ) : ' '}
        {type === 'Email Report' ? (
          <>
            {' '}
            <div className={clsx('flexRow', classes.wrapper)}>
              <DropdownReport
                label="Email Group"
                onChange={(e: any, option: any) => handleOptionChange(option, 'group_name')}
                value={formData?.group_name}
                options={emailGroup}
                width="77%"
                style={{ margin: '2px 10px' }}
              />
            </div>
            {formData?.group_name === 'Create New Group' ? (
              <>
                <Divider variant="middle" />
                <Typography
                  className={classes.subheading}
                >
                  Enter Email Address(es) and optionally a Group Name
                </Typography>
                <div className={clsx('flexRow', classes.wrapper)}>
                  <Typography className={classes.label}>Email Address(es)</Typography>
                  <textarea
                    style={{ margin: '2px 10px', width: '73%' }}
                    onChange={(e: any) => handleOptionChange(e.target, 'email_list')}
                  />
                </div>
                <div className={clsx('flexRow', classes.wrapper)}>
                  <Typography className={classes.label}>Email Group Name</Typography>
                  <Input
                    onChange={(e: any) => handleOptionChange(e.target, 'email_group_name')}
                    name="newFolder"
                    value={formData?.newFolder}
                    style={{
                      width: '73.8%',
                      marginRight: '8px',
                    }}
                    required={false}
                  />
                </div>
              </>
            ) : ''}
          </>
        ) : ' '}
        {type === 'Comment' ? (
          <>
            {' '}
            <div style={{ display: 'block' }}>
              <JoditEditor
                value={formData?.comment}
                onChange={(newContent: any) => setFormData({ ...formData, comment: newContent })}
              />
            </div>
            <div className={classes.actionbtn}>
              <Divider variant="middle" />
              <ButtonBase
                classes={{ root: classes.applyButton }}
                disabled={!formData?.comment}
                onClick={handleSubmitModal}
                style={{ marginRight: '5px' }}
              >
                Submit Comment
              </ButtonBase>
              <ButtonBase classes={{ root: classes.closeButton }} onClick={onClose}>
                Close
              </ButtonBase>
            </div>
          </>
        ) : ' '}
      </div>
      {type !== 'Comment' ? (
        <>
          <Divider variant="middle" />
          <DialogActions style={{ overflowY: 'auto' }}>
            {type === 'Print Report' ? (
              <ButtonBase
                classes={{ root: classes.applyButton }}
                disabled={!formData.routecode}
                onClick={handleSubmitModal}
              >
                Print
              </ButtonBase>
            ) : ''}
            {type === 'Email Report' ? (
              <ButtonBase
                classes={{ root: classes.applyButton }}
                disabled={formData?.group_name !== 'Create New Group'
                || !ValidateEmail(formData?.email_list)}
                onClick={handleSubmitModal}
              >
                Send Email
              </ButtonBase>
            ) : ''}
            <ButtonBase classes={{ root: classes.closeButton }} onClick={onClose}>
              Close
            </ButtonBase>
          </DialogActions>
        </>
      ) : ''}
    </DialogComponent>
  );
};

export default ViewReportOptionDialog;
