import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    maxWidth: '100%',
    borderRadius: '5px',
    marginBottom: '6px',
  },
  heading: {
    fontWeight: 'bold',
  },
  arrow: {
    cursor: 'pointer',
    position: 'absolute',
    left: '20px',
  },
});
