import { Dispatch } from 'redux';
import Api from '../../api/index';
import { LOAD_CUSTOM_REPORT_CONFIGURATION_TABLE_DATA } from '../../constants/workflows';

export const getCustomReport = () => async (dispatch: Dispatch) => {
  const response = await Api.get(
    'workflow017/data?screenID=WORKFLOW-075&reportType=C&mode=queue',
  );
  dispatch({
    type: LOAD_CUSTOM_REPORT_CONFIGURATION_TABLE_DATA,
    payload: response.data.data,
  });
  console.log(response.data.data);
};
