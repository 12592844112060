/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-globals */
import React, { useEffect, useMemo } from 'react';
import InputMask from 'react-input-mask';
import {
  Button,
  Checkbox,
  ClickAwayListener,
  Fab,
  Input,
  InputAdornment,
  InputBase,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useSelector } from 'react-redux';
import MaskInput from './MaskInput';

type inputType = {
  size?: any;
  label: string;
  type?: any;
  maxLength?: number;
  placeholder?: string;
  isDisabled: boolean;
  name?: string;
  value?: any;
  onChange?: any;
  inputProps?: any;
  error?: boolean;
  helperText?: string;
  defaultValue?: string;
  numericValue?: boolean;
  phone?: boolean;
  timeType?: boolean;
  dateType?: boolean;
  mask?: any;
  maskChar?: any;
  key?: any;
  required?: boolean;
  validate?: string;
  val?: any;
  attr?: any;
  style?: any;
  labelStyle?: any;
  inputStyle?: any;
  noAwayListener?: boolean;
  isNumber?: boolean;
  margin?: any;
  isBatch?: any;
  datagrid?: boolean;
  display?: any;
};
const CustomInput = (props: inputType) => {
  const {
    size,
    margin,
    label,
    placeholder,
    isDisabled,
    type,
    isBatch,
    maxLength,
    name,
    value,
    onChange,
    inputProps,
    error,
    helperText,
    required,
    defaultValue,
    numericValue,
    phone,
    timeType,
    dateType,
    attr,
    mask,
    maskChar,
    key,
    validate,
    val,
    style,
    labelStyle,
    inputStyle,
    noAwayListener,
    isNumber,
    datagrid,
    display,
  } = props;
  const [count, setCount] = React.useState(0);
  const [valueDate, setValueDate] = React.useState<Date | null>(null);
  const [open, setOpen] = React.useState(false);
  const [inFocus, setInFocus] = React.useState(false);
  const [isValidEmail, setIsValidEmail] = React.useState(true);
  const [datePickerOpen, setDatePickerOpen] = React.useState(false);
  const [pickerDate, setPickerDate] = React.useState(() => {
    if (value) {
      const dateFormatted = `${value.slice(2, 4)}/${value.slice(
        4,
        6,
      )}/20${value.slice(0, 2)}`;
      return new Date(dateFormatted);
    }
    return new Date();
  });
  const [dateAnchorEl, setDateAnchorEl] = React.useState<null | HTMLElement>(
    null,
  );
  useEffect(() => {
    if (dateType && value) {
      const dateFormatted = `${value.slice(2, 4)}/${value.slice(
        4,
        6,
      )}/20${value.slice(0, 2)}`;
      setPickerDate(new Date(dateFormatted));
    }
  }, [value]);

  const handleDateInputClick = (event: React.MouseEvent<HTMLInputElement>) => {
    setDateAnchorEl(event.currentTarget);
    setDatePickerOpen(true);
  };

  const handleOpenDatePicker = () => {
    setDatePickerOpen(true);
  };

  const handleCloseDatePicker = () => {
    setDateAnchorEl(null);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    if (required || !isValidEmail) {
      setOpen(true);
    }
  };

  const add = () => {
    setCount(count + 1);
  };
  const { activeScreen } = useSelector((state: any) => state.ScreenReducer);
  const subtract = () => count > 0 && setCount(count - 1);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCount(e.target.value.length);
  };
  const [InputSize, setInputSize] = React.useState('');
  const handleBlur = () => {
    setInFocus(false);
    // Added !attr if attr property is missing then dont format
    if (name === 'pct_oxygen') {
      onChange({
        target: {
          value: isNaN(value) ? '' : `00000${value.toString()[0]}`,
          name,
        },
      });
      return;
    }

    if (name === 'loads_per_sample') {
      onChange({
        target: {
          value: isNaN(value) ? '' : value.toString().padStart(4, '0'),
          name,
        },
      });
      return;
    }

    if (
      validate === 'numzf'
      && !attr?.includes('noneg')
      && !name?.includes('adjustment')
      && !attr?.includes('alphanum')
      && !name?.includes('weight')
      && !attr
    ) {
      onChange({
        target: {
          value: !isNaN(value)
            ? value
            : ''.padStart(inputProps?.maxLength || 0, '0'),
          name,
        },
      });
    } else if (validate === 'anumzf' && !attr?.includes('alphanum')) {
      onChange({
        target: {
          value: !isNaN(value)
            ? String(value).padStart(inputProps?.maxLength || 0, '0')
            : ''.padStart(inputProps?.maxLength || 0, '0'),
          name,
        },
      });
    } else if (validate === 'anumzf' && attr?.includes('alphanum')) {
      onChange({
        target: {
          value,
          name,
        },
      });
    }

    // Validate
    if (attr?.includes('email')) {
      // \. is needed on regex to present dot else it means anything
      // eslint-disable-next-line
      const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (value === '' || value.match(mailformat)) {
        setIsValidEmail(true);
      } else {
        setIsValidEmail(false);
      }
    }
  };

  const validationTooltipTitle = () => (validate?.includes('numzf') && required
    ? 'This field is required\n can not be negative'
    : !isValidEmail
      ? 'Must be e-mail format'
      : 'This field is required');

  const backgroundColor = useMemo(() => {
    if (val && !val.visible) {
      return '#fff9f0';
    }
    if (isDisabled) {
      return '#f8f8f8';
    }
    return '#fff';
  }, [value, isDisabled, val]);

  return (
    <div
      style={
        style || {
          width: '100%',
          alignSelf: 'center',
          marginTop: margin !== '0px' ? '10px' : '0px',
          display: display ? 'column' : 'flex',
          alignItems: 'center',
          gap: 10,
          justifyContent: 'flex-end',
        }
      }
    >
      {!datagrid && (
        <Typography
          className="f12"
          style={{ flex: 1, textAlign: 'right', ...(labelStyle || null) }}
        >
          {label}
        </Typography>
      )}
      {(() => {
        if (timeType) {
          return (
            <div
              style={
                margin !== '0px'
                  ? { flexWrap: 'nowrap', display: 'flex', flex: 3 }
                  : {
                    // width: '100%',
                    marginTop: datagrid ? '0px' : '10px',
                    display: 'flex',
                    gap: 10,
                    flex: 3,
                  }
              }
            >
              <TextField
                className="textfield"
                name={name}
                inputProps={{ step: 1 }}
                value={value && `${value.slice(0, 2)}:${value.slice(2, 4)}`}
                defaultValue={value}
                onChange={(e: any) => {
                  onChange({
                    target: {
                      name,
                      value: `${e.target.value.slice(
                        0,
                        2,
                      )}${e.target.value.slice(3, 5)}`,
                    },
                  });
                }}
                placeholder={placeholder}
                size="small"
                color={required ? 'error' : 'primary'}
                type={type}
                style={{
                  color: '#f7f7f7',
                }}
                disabled={isDisabled}
                sx={{
                  '& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
                    padding: '2px 8px',
                    border: '1px solid #E4E4E4',
                  },
                  '& .MuiInputBase-root': {
                    '& > fieldset': {
                      borderColor:
                        required && value === '' ? 'error' : 'primary',
                      borderLeft: required ? '5px solid red' : '5px solid #555',
                      borderRadius: '0px !important',
                    },
                  },

                  width: size,
                  '& input[type="time"]::-webkit-calendar-picker-indicator': {
                    display: 'inlineBlock',
                    background: isDisabled ? '#f8f8f8' : 'transparent',
                    // border: required ? '1px solid red' : 'transparent',
                    bottom: 0,
                    color: 'transparent',
                    cursor: 'pointer',
                    height: 'auto',
                    left: 0,
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    width: size,
                  },
                }}
                // error={error}
                // helperText={name}
              />
            </div>
          );
        }
        // if (dateType) {
        //   return (
        //     <div
        //       style={
        //         margin !== '0px'
        //           ? { flexWrap: 'nowrap', display: 'flex', flex: 3 }
        //           : {
        //             // width: '100%',
        //             marginTop: datagrid ? '0px' : '10px',
        //             display: 'flex',
        //             gap: 10,
        //             flex: 3,
        //           }
        //       }
        //     >
        //       <LocalizationProvider dateAdapter={AdapterDateFns}>
        //         <DatePicker
        //           label="Custom input"
        //           PopperProps={{ anchorEl: dateAnchorEl }}
        //           onOpen={() => setDatePickerOpen(true)}
        //           onClose={() => setDatePickerOpen(false)}
        //           value={pickerDate}
        //           open={datePickerOpen}
        //           onChange={(newValue:any) => {
        //             const newValueFormatted = newValue?.toLocaleDateString();
        //             onChange({
        //               target: {
        //                 name,
        //                 value: `${newValueFormatted?.slice(
        //                   8,
        //                 )}${newValueFormatted?.slice(3, 5)}${newValueFormatted?.slice(
        //                   0,
        //                   2,
        //                 )}`,
        //               },
        //             });
        //           }}
        //           renderInput={() => <></>}
        //         />
        //         <TextField
        //           onClick={handleDateInputClick}
        //           className="textfield"
        //           name={name}
        //           value={
        //             value
        //             && `${value.slice(2, 4)}/${value.slice(
        //               4,
        //               6,
        //             )}/20${value.slice(0, 2)}`
        //           }
        //           defaultValue={defaultValue}
        //           onChange={(e: any) => {
        //             onChange({
        //               target: {
        //                 name,
        //                 value: `${e.target.value.slice(
        //                   2,
        //                   4,
        //                 )}${e.target.value.slice(5, 7)}${e.target.value.slice(
        //                   8,
        //                   10,
        //                 )}`,
        //               },
        //             });
        //           }}
        //           placeholder={placeholder}
        //           size="small"
        //           // type={type}
        //           style={{
        //             color: '#f7f7f7',
        //             borderRadius: '0px !important',
        //           }}
        //           color={required && value === '' ? 'error' : 'primary'}
        //           disabled={isDisabled}
        //           sx={{
        //             '& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
        //               padding: '4px 8px',
        //               border: '1px solid #E4E4E4',
        //             },
        //             width: size,
        //             borderLeft: '5px solid #555',
        //             '& .MuiOutlinedInput-root': {
        //               borderRadius: '0px !important',
        //             },
        //             borderRadius: '0px !important',
        //             '& input[type="date"]::-webkit-calendar-picker-indicator': {
        //               display: 'inlineBlock',
        //               background: isDisabled ? '#f8f8f8' : 'transparent',
        //               '& .MuiInputBase-root': {
        //                 borderRadius: '0px !important',
        //                 '& > fieldset': {
        //                   borderColor:
        //                     required && value === '' ? 'error' : 'transparent',
        //                 },
        //               },
        //               bottom: 0,
        //               color: 'transparent',
        //               cursor: 'pointer',
        //               height: 'auto',
        //               left: 0,
        //               position: 'absolute',
        //               borderLeft: '5px solid #555',
        //               right: 0,
        //               top: 0,
        //               width: size,
        //             },
        //             '& input[type="date"]:disabled': {
        //               borderLeft: '5px solid #555',
        //             },
        //           }}
        //         />
        //         {/* <InputBase value="slkdlmsqdklsqmkdms" onClick={handleDateInputClick} /> */}
        //       </LocalizationProvider>
        //     </div>
        //   );
        // }
        if (dateType) {
          return (
            <div
              style={
                margin !== '0px'
                  ? { flexWrap: 'nowrap', display: 'flex', flex: 3 }
                  : {
                    // width: '100%',
                    marginTop: datagrid ? '0px' : '10px',
                    display: 'flex',
                    gap: 10,
                    flex: 3,
                  }
              }
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Custom input"
                  PopperProps={{ anchorEl: dateAnchorEl }}
                  onOpen={() => setDatePickerOpen(true)}
                  onClose={() => setDatePickerOpen(false)}
                  value={pickerDate}
                  inputFormat="yyyy-MM-dd"
                  open={datePickerOpen}
                  onChange={(newValue: any) => {
                    const newValueFormatted = newValue?.toISOString().split('T')[0];
                    onChange({
                      target: {
                        name,
                        value: newValueFormatted,
                      },
                    });
                  }}
                  renderInput={() => <></>}
                />
                <TextField
                  onClick={handleDateInputClick}
                  className="textfield"
                  name={name}
                  value={
                    value?.search('-') > 0 ? value?.replaceAll('-', '/') : value
                  }
                  defaultValue={defaultValue}
                  onChange={(e: any) => {
                    onChange({
                      target: {
                        name,
                        value: e.target.value,
                      },
                    });
                  }}
                  placeholder={placeholder}
                  size="small"
                  // type={type}
                  style={{
                    color: '#f7f7f7',
                    borderRadius: '0px !important',
                  }}
                  color={required && value === '' ? 'error' : 'primary'}
                  disabled={isDisabled}
                  sx={{
                    '& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input':
                      {
                        padding: '4px 8px',
                        border: '1px solid #E4E4E4',
                      },
                    width: size,
                    borderLeft: required ? '5px solid red' : '5px solid #555',
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '0px !important',
                    },
                    borderRadius: '0px !important',
                    '& input[type="date"]::-webkit-calendar-picker-indicator': {
                      display: 'inlineBlock',
                      background: isDisabled ? '#f8f8f8' : 'transparent',
                      '& .MuiInputBase-root': {
                        borderRadius: '0px !important',
                        '& > fieldset': {
                          borderColor:
                            required && value === '' ? 'error' : 'transparent',
                        },
                      },
                      bottom: 0,
                      color: 'transparent',
                      cursor: 'pointer',
                      height: 'auto',
                      left: 0,
                      position: 'absolute',
                      borderLeft: required ? '5px solid red' : '5px solid #555',
                      right: 0,
                      top: 0,
                      width: size,
                    },
                    '& input[type="date"]:disabled': {
                      borderLeft: '5px solid #555',
                    },
                  }}
                />
                {/* <InputBase value="slkdlmsqdklsqmkdms" onClick={handleDateInputClick} /> */}
              </LocalizationProvider>
            </div>
          );
        }
        if (numericValue) {
          return (
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <div style={{ flexWrap: 'nowrap', display: 'flex', flex: 3 }}>
                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  placement="top"
                  arrow
                  color="red"
                  onClose={handleTooltipClose}
                  open={open}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title="Add"
                >
                  <TextField
                    className="textfield"
                    name={name}
                    value={
                      name === 'EOB_Timer'
                        ? count.toString().padStart(3, '0')
                        : name === 'FS_Timer'
                          ? count.toString().padStart(3, '0')
                          : name === 'unauthorized_elapsed_time'
                            ? count.toString().padStart(4, '0')
                            : count
                    }
                    defaultValue={defaultValue}
                    onChange={handleChange}
                    placeholder={placeholder}
                    size="small"
                    type={type}
                    inputProps={inputProps}
                    style={{
                      color: '#f7f7f7',
                      background: isDisabled ? '#f8f8f8' : 'transparent',
                      // border: required ? '1px solid red' : 'transparent',
                    }}
                    color={required && value === '' ? 'error' : 'primary'}
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Fab size="small" color="primary" variant="extended">
                            <RemoveIcon onClick={subtract} />
                          </Fab>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <Fab size="small" color="primary" variant="extended">
                            <AddIcon onClick={add} />
                          </Fab>
                        </InputAdornment>
                      ),
                    }}
                    disabled={isDisabled}
                    sx={{
                      '& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
                        padding: '4px 8px',
                        border: '1px solid #E4E4E4',
                      },
                      width: size,
                      '& .MuiInputBase-root': {
                        '& > fieldset': {
                          borderColor:
                            required && value === '' ? 'red' : '#e8e8e8',
                        },
                      },
                    }}
                    error={error}
                    // helperText={name}
                  />
                </Tooltip>
              </div>
            </ClickAwayListener>
          );
        }
        if (phone) {
          return (
            <MaskInput
              value={value}
              onChange={onChange}
              size={size}
              mask={mask}
              name={name}
            />
          );
        }
        return (
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <div
              key={key}
              style={{
                display: 'flex',
                alignItems: 'center',
                width: !noAwayListener ? size : 'none',
                flex: 3,
              }}
            >
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                  color: 'red',
                }}
                onClose={handleTooltipClose}
                open={open}
                placement="top"
                arrow
                color="red"
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={validationTooltipTitle()}
              >
                <TextField
                  className="textfield"
                  name={name}
                  value={value === 'null' || value === null ? '' : value}
                  defaultValue={defaultValue}
                  onChange={(e: any) => {
                    onChange(e);
                    setInputSize(e.target.value);
                  }}
                  onFocus={() => setInFocus(true)}
                  onBlur={handleBlur}
                  placeholder={placeholder}
                  size="small"
                  onClick={handleTooltipOpen}
                  type={
                    isNumber || attr?.includes('num')
                      ? 'text'
                      : attr?.includes('secure')
                        ? 'password'
                        : 'type'
                  }
                  inputProps={inputProps}
                  disabled={isDisabled}
                  sx={{
                    '& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
                      padding: '4px 8px',
                      border: '1px solid transparant',
                    },
                    '& .MuiInputBase-root': {
                      '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                        {
                          display:
                            isNumber || attr?.includes('num')
                              ? 'none'
                              : 'inline',
                        },
                      '& input[type=number]': {
                        MozAppearance:
                          isNumber || attr?.includes('num')
                            ? 'textfield'
                            : 'none',
                      },
                      '& > fieldset': {
                        borderColor:
                          (validate?.includes('anumzf')
                            && !attr?.includes('alphanum')
                            && isNaN(value)
                            && required)
                          || (required && value === '')
                          || !isValidEmail
                            ? 'red'
                            : 'gray',
                        borderLeft: required && !display
                          ? '5px solid red'
                          : !required && !display ? '5px solid #555'
                            : required && display ? '1px solid red'
                              : '1px solid black',
                        borderRadius: '0px !important',
                      },
                    },
                    '& .MuiInputBase-root.Mui-disabled': {
                      '& > fieldset': {
                        borderColor:
                          (required && value === '') || !isValidEmail
                            ? 'red'
                            : 'transparent',
                        borderLeft: required
                          ? '5px solid red'
                          : '5px solid #555',
                        borderRadius: '0px !important',
                        // backgroundColor: '#f8f8f8',
                        color: '#000',
                      },
                    },
                    width: size,
                    background: backgroundColor,
                    // borderLeft: '5px #000 solid',
                  }}
                  error={
                    attr?.includes('noneg') && isNaN(value) && value !== ''
                  }
                  color={
                    (required && value === '') || !isValidEmail
                      ? 'error'
                      : 'primary'
                  }
                  helperText={
                    attr?.includes('noneg') && isNaN(value) && value !== ''
                      ? 'can not be negative, must be numeric'
                      : null
                  }
                />
              </Tooltip>
              {inFocus && inputProps?.maxLength > 0 && value?.length > 0 && (
                <Typography
                  style={{
                    marginLeft: -48,
                    fontSize: 12,
                    color: 'gray',
                    zIndex: 2,
                  }}
                >
                  {`${value?.length} / ${inputProps?.maxLength}`}
                </Typography>
              )}
            </div>
          </ClickAwayListener>
        );
      })()}
    </div>
  );
};
CustomInput.defaultProps = {
  name: null,
  value: null,
  type: null,
  inputProps: {},
  maxLength: null,
  size: null,
  error: false,
  helperText: null,
  defaultValue: null,
  numericValue: false,
  phone: false,
  timeType: false,
  dateType: false,
  placeholder: null,
  mask: null,
  maskChar: null,
  key: null,
  attr: null,
  required: false,
  validate: '',
  val: null,
  style: null,
  labelStyle: null,
  isBatch: undefined,
  inputStyle: null,
  noAwayListener: false,
  onChange: onchange,
  isNumber: false,
  margin: null,
  datagrid: false,
  display: undefined,
};
export default CustomInput;
