import {
  LOAD_FIELD_ATTRIBUTES_GROUP_SCREEN,
  LOAD_FIELD_ATTRIBUTES_TABLE,
} from '../../constants/workflows';

const INITIAL_STATE = {
  groupsScreensFields: [],
  fieldAttributesTable: [],
};

const fieldAttributesReducer = (
  state = INITIAL_STATE,
  { type, payload }: any,
) => {
  switch (type) {
    case LOAD_FIELD_ATTRIBUTES_GROUP_SCREEN: {
      return {
        ...state,
        groupsScreensFields: payload,
      };
    }
    case LOAD_FIELD_ATTRIBUTES_TABLE: {
      return {
        ...state,
        fieldAttributesTable: payload,
      };
    }
    default:
      return state;
  }
};

export default fieldAttributesReducer;
