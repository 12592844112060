import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {},
  closeButton: {
    color: 'black !important',
    textShadow: 'none !important',
    backgroundColor: '#ffbb33 !important',
    border: '1px solid transparent !important',
    padding: '6px 10px !important',
    marginRight: '15px !important',
  },
  applyButton: {
    color: 'black !important',
    textShadow: 'none !important',
    backgroundColor: '#00C851 !important',
    border: '1px solid transparent !important',
    padding: '6px 10px !important',
    '&:hover': {
      backgroundColor: '#007E33 !important',
      color: '#fff !important',
    },
    '&:disabled': {
      color: '#555 !important',
      backgroundColor: '#bcbcbc !important',
      border: '1px solid transparent !important',
      cursor: 'not-allowed',
    },
  },
  restoreButton: {
    color: 'white !important',
    textShadow: 'none !important',
    backgroundColor: '#852b99 !important',
    border: '1px solid transparent !important',
    padding: '6px 10px !important',
    '&:hover': {
      border: '1px solid #461652 !important',
    },
  },
  label: {
    paddingTop: '5px !important',
    fontSize: '12px !important',
    fontWeight: '400 !important',
    color: 'black !important',
  },
  textArea: {
    resize: 'vertical',
    height: '80px !important',
    width: '75% !important',
    margin: '2px 10px !important',
  },
  wrapper: { padding: '5px 10px 5px 10px', justifyContent: 'flex-end' },
  dialogBody: { minHeight: 200, paddingTop: '10px !important' },
  searchInput: {
    fontWeight: '400 !important',
    fontSize: '12px !important',
    lineHeight: '1.42857143 !important',
    padding: '4px 4px',
    color: '#333333',
    backgroundColor: '#fff',
    border: '1px solid #e5e5e5',
    height: 33,
    width: 200,
  },
  listColumn: {
    borderLeft: '1px solid #ffffff',
    verticalAlign: 'top',
    padding: '5px 9px',
    whiteSpace: 'nowrap',
    borderRight: '1px solid #ffffff',
    borderTop: '1px solid #ffffff',
    width: '50%',
  },
  listContainer: {
    height: '486.35px',
    border: '1px solid #d8d8d8',
    width: '100%',
    margin: '0px',
    padding: '3px',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  field: {
    lineHeight: '30px',
    margin: '2px',
    backgroundColor: '#ffffff',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    border: '1px solid #eee',
  },
  helperText: {
    fontSize: 12,
    fontWeight: 400,
    fontStyle: 'italic',
    marginLeft: 10,
  },
});
