import { Dispatch } from 'redux';
import Api from '../../api';
import { CLEAR_USER_SESSION_NOTIFICATION, LOAD_USER_SESSION } from '../../constants/workflows';

export const getUserSessionData = () => async (dispatch: Dispatch) => {
  const userID = localStorage.getItem('username');
  try {
    const response = await Api.get(
      `workflow201/data?screenID=WORKFLOW-201&userID=${userID}`,
    );

    dispatch({
      type: LOAD_USER_SESSION,
      payload: {
        data: response.data.data,
        headerList: response.data.headerList,
        hiddenColumns: response.data.hiddenColumns,
        visibleColumns: response.data.visibleColumns,
      },
    });
  } catch (e: any) {
    if (e.response.status !== 404) {
      throw e;
    }
    dispatch({
      type: LOAD_USER_SESSION,
      payload: {
        data: [
          {
            UserId: 'a demonstration data',
            UserName: 'ZDEMO',
            LastLogin: new Date().toDateString(),
            ActiveSessions: 1,
          },
          {
            UserId: 'b demonstration data',
            UserName: 'YDEMO',
            LastLogin: new Date().toDateString(),
            ActiveSessions: 2,
          },
        ],
        headerList: {
          UserId: 'User ID',
          UserName: 'User Name',
          LastLogin: 'Last Login',
          ActiveSessions: 'Active Sessions',
        },
        hiddenColumns: {},
        visibleColumns: {
          UserId: 'UserId',
          UserName: 'UserName',
          LastLogin: 'LastLogin',
          ActiveSessions: 'ActiveSessions',
        },
      },
    });
  }
};
export const clearUserSessionNotification = () => ({
  type: CLEAR_USER_SESSION_NOTIFICATION,
});
