import React, { useState } from 'react';

// Librairies
import { Divider, Menu, MenuItem } from '@mui/material';
// import Api from '../../../../redux/api/index';
// Styles
import { useStyles } from './style';
// import ConfirmationDialog from '../../../ConfirmDialog';

interface IProps {
  anchorEl: any;
  onClose: () => void;
  handleOpenDialog: (type: string) => void;
}

const TankStrappingTablesOptions: React.FC<IProps> = ({
  anchorEl,
  onClose,
  handleOpenDialog,
}) => {
  const classes = useStyles();
  const handleViewReportOptionClick = () => {
    onClose();
  };

  return (
    <>
      <Menu
        classes={{ root: classes.root, list: classes.list, paper: classes.paper }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={onClose}
      >
        <MenuItem
          classes={{ root: classes.menuItem }}
          onClick={() => handleOpenDialog('chartComments')}
        >
          Chart Comments ...
        </MenuItem>
        <Divider />
        <MenuItem
          classes={{ root: classes.menuItem }}
          onClick={() => handleOpenDialog('exportColumns')}
        >
          Export Columns
        </MenuItem>
        <MenuItem
          classes={{ root: classes.menuItem }}
          onClick={() => handleOpenDialog('recordChange')}
        >
          Record Change History ...
        </MenuItem>
      </Menu>
    </>
  );
};

export default TankStrappingTablesOptions;
