/* eslint-disable react/require-default-props */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-fragments */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';

// Librairies
import {
  ButtonBase,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import clsx from 'clsx';
import {
  Settings,
  Remove,
  Add,
  ArrowDropDown,
  ArrowDropUp,
  UnfoldMore,
} from '@mui/icons-material';

// Styles
import { useStyles } from './style';

const prodCols: { label: string; key: string }[] = [
  { label: 'Product Name', key: 'prod_name' },
  { label: 'Gross', key: 'gross' },
  { label: 'Vehicle', key: 'short_name' },
  { label: 'Compartment', key: 'mode' },
  { label: 'Preset Status', key: 'preset' },
];

interface IProps {
  handleClickRow: () => void;
  openDriversInFacilityDialog: () => void;
  tableData: any[];
  tableHeader: { label: string; key: string }[];
  searchText?: string;
  orderBy?: string;
  order?: string;
  sortTable?: (key: string) => void;
}

const sortedTable = (data: any[], orderBy?: string, order?: string) => {
  if (!orderBy || !order) return data;
  return data.sort((a, b) => {
    const orderResult: number = String(a[orderBy]).localeCompare(b[orderBy]);
    return order === 'asc' ? orderResult : -orderResult;
  });
};

const DriversInFacilityTable: React.FC<IProps> = ({
  handleClickRow,
  openDriversInFacilityDialog,
  tableData,
  tableHeader,
  searchText,
  order,
  orderBy,
  sortTable,
}) => {
  const classes = useStyles();
  const [collapsedIndexes, setCollapsedIndexes] = useState<number[]>([]);

  const onCollapse = (index: number) => {
    if (collapsedIndexes.includes(index)) {
      setCollapsedIndexes(collapsedIndexes.filter((x: number) => x !== index));
    } else {
      setCollapsedIndexes([...collapsedIndexes, index]);
    }
  };

  const handleSortClick = (key: string) => (event: React.MouseEvent<unknown>) => {
    if (sortTable) {
      sortTable(key);
    }
  };

  return (
    <div className={classes.root}>
      <Table classes={{ root: classes.tableRoot }}>
        <TableHead>
          <TableRow classes={{ root: classes.headerRow }}>
            <TableCell classes={{ root: classes.headerCell }}> </TableCell>
            {tableHeader.map((h) => (
              <TableCell classes={{ root: classes.headerCell }} key={h.key}>
                <TableSortLabel
                  active={orderBy === h.key}
                  onClick={handleSortClick(h.key)}
                  IconComponent={() => (order === 'asc' && orderBy === h.key ? (
                    <ArrowDropUp />
                  ) : order === 'desc' && orderBy === h.key ? (
                    <ArrowDropDown />
                  ) : (
                    <UnfoldMore />
                  ))}
                  classes={{ root: classes.sortLabel }}
                >
                  {h.label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {!tableData.length && (
            <TableRow className={classes.tableRow}>
              <TableCell
                classes={{ root: classes.bodyCellEmpty }}
                style={{ textAlign: 'center' }}
                colSpan={tableHeader.length + 1}
              >
                {searchText
                  ? 'No matching records found'
                  : 'No data available in table'}
              </TableCell>
            </TableRow>
          )}
          {sortedTable(tableData, orderBy, order)?.map((record, index) => (
            <React.Fragment key={index}>
              <TableRow onClick={handleClickRow} className={classes.tableRow}>
                <TableCell classes={{ root: classes.bodyCell }}>
                  <div className={classes.actionsWrapper}>
                    <ButtonBase
                      className={classes.actionButton}
                      onClick={() => onCollapse(index)}
                    >
                      {collapsedIndexes.includes(index) ? (
                        <Remove fontSize="small" />
                      ) : (
                        <Add fontSize="small" />
                      )}
                    </ButtonBase>
                    <ButtonBase
                      className={classes.actionButton}
                      onClick={openDriversInFacilityDialog}
                    >
                      <Settings fontSize="small" />
                    </ButtonBase>
                  </div>
                </TableCell>
                {tableHeader.map((field) => (
                  <TableCell
                    classes={{ root: classes.bodyCell }}
                    key={field.key}
                  >
                    {record[field.key]}
                  </TableCell>
                ))}
              </TableRow>
              {collapsedIndexes.includes(index) ? (
                <TableRow className={classes.tableRow}>
                  <TableCell classes={{ root: classes.bodyCell }} colSpan={8}>
                    <div className={classes.detailGroup}>
                      <span>Active</span>
                      <table className={classes.stepInfo}>
                        <tbody>
                          <tr>
                            <td className={classes.fontBold}>Supplier</td>
                            <td>{record?.supplier}</td>
                            <td />
                            <td className={classes.fontBold}>Customer</td>
                            <td>{record?.customer}</td>
                          </tr>
                          <tr>
                            <td className={classes.fontBold}>Account</td>
                            <td>{record?.account}</td>
                            <td />
                            <td className={classes.fontBold}>Destination</td>
                            <td />
                          </tr>
                        </tbody>
                      </table>
                      <table
                        className={classes.prodInfo}
                        style={{ borderSpacing: 0 }}
                      >
                        <thead>
                          <tr>
                            {prodCols.map((col) => (
                              <th
                                className={classes.prodInfoCell}
                                key={col.key}
                              >
                                {col.label}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {Object.values(record.detail.prodlist)?.map(
                            (prod: any, ind) => (
                              <tr key={ind}>
                                {prodCols.map((col, i) => (
                                  <td
                                    key={i}
                                    className={clsx(
                                      col.key === 'preset'
                                        && classes.prodStatusValue,
                                    )}
                                  >
                                    {prod[col.key]}
                                  </td>
                                ))}
                              </tr>
                            ),
                          )}
                        </tbody>
                      </table>
                      <span>Active</span>
                      <table className={classes.stepInfo}>
                        <tbody>
                          <tr>
                            <td className={classes.fontBold}>Supplier</td>
                            <td>0000000371 - TTE MARKETING & SUPPLY</td>
                            <td />
                            <td className={classes.fontBold}>Customer</td>
                            <td>0000140018 - SUNOCO</td>
                          </tr>
                          <tr>
                            <td className={classes.fontBold}>Account</td>
                            <td>0002034043 - SE40537-EAGLE PASS TX</td>
                            <td />
                            <td className={classes.fontBold}>Destination</td>
                            <td />
                          </tr>
                        </tbody>
                      </table>
                      <table
                        className={classes.prodInfo}
                        style={{ borderSpacing: 0 }}
                      >
                        <thead>
                          <tr>
                            {prodCols.slice(0, 4).map((col) => (
                              <th
                                className={classes.prodInfoCell}
                                key={col.key}
                              >
                                {col.label}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>001180 - ULSD </td>
                            <td>0000020000 </td>
                            <td>8900 </td>
                            <td>3</td>
                          </tr>
                          <tr>
                            <td>001180 - ULSD </td>
                            <td>0000020000 </td>
                            <td>8900 </td>
                            <td>3</td>
                          </tr>
                          <tr>
                            <td>001180 - ULSD </td>
                            <td>0000020000 </td>
                            <td>8900 </td>
                            <td>3</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </TableCell>
                </TableRow>
              ) : null}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default React.memo(DriversInFacilityTable);
