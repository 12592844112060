/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Close, Menu, Person, Search,
} from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import './styles.css';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import HomeSearch from '../HomeSearchInput/HomeSearchInput';
import SmartNavSearch from '../HomeSearchInput/SmartSearchNavBar';
import {
  LoadRecord,
  LoadTables,
  SetRecentScreens,
  StartLoading,
  StopLoading,
} from '../../redux/actions/ScreenActions';
import useWindowDimensions from '../../utils/windowDimensions';
import MenuItems from './Menu';
import { currentReport } from '../../redux/actions/reports/reports';
import { currentUtility } from '../../redux/actions/systemUtilities/systemUtilities';
import {
  manualTransaction,
  business,
  product,
  operations,
  documents,
  inventory,
  utilities,
  settings,
  admin,
  reporting,
} from './menuData.js';
import ConfirmationModal from '../confirmationModal';

const TmsHeader = (props:any) => {
  const {
    checkNavigation,
    setCheckNavigation,
    confirmModal,
    setConfirmModal,
    nextScreen,
    setNextScreen,
    // setShowMenus,
    isModifiy,
    setVisibleConfirm,
    setScreen,
    setRecentScreen,
  } = props;
  const navigate = useNavigate();

  const [isManualTransactionOpen, setIsManualTransactionOpen] = useState(false);
  const [isBusinessOpen, setIsBusinessOpen] = useState(false);
  const [isProductOpen, setIsProductOpen] = useState(false);
  const [isOperationsOpen, setIsOperationsOpen] = useState(false);
  const [isDocumentsOpen, setIsDocumentsOpen] = useState(false);
  const [isInventoryOpen, setIsInventoryOpen] = useState(false);
  const [isUtilitiesOpen, setIsUtilitiesOpen] = useState(false);
  const [isAdminOpen, setIsAdminOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isReportingOpen, setIsReportingOpen] = useState(false);

  const [screenUrl, setScreenUrl] = useState<any>(null);

  const [isSearching, setisSearching] = useState(false);
  const [open, setOpen] = useState(false);
  const [isDrawerOpen, setisDrawerOpen] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const { height, width } = useWindowDimensions();
  const { loading, recentList, ScreensList } = useSelector(
    (state: any) => state.ScreenReducer,
  );
  const { Language, isAuthenticated } = useSelector(
    (state: any) => state.LoginReducer,
  );
  const recent = () => {
    if (isModifiy) {
      setVisibleConfirm(true);
      setRecentScreen('/home');
    } else {
      navigate('/home');
    }
  };
  const dispatch = useDispatch();

  useEffect(() => {
    const { search } = window.location;
    const queryParams = new URLSearchParams(search);
    const url = queryParams.get('url');
    if (url) {
      dispatch(StartLoading());
      setScreenUrl(url);
    }
  }, []);
  const navigateHeaderMenu = async (selectedMenu: any) => {
    if (isModifiy) {
      setVisibleConfirm(true);
      setScreen(selectedMenu);
    } else {
      if (confirmModal) {
        setCheckNavigation(true);
        setNextScreen(selectedMenu);
        return;
      }
      if (ScreensList) {
        const optionArray = await ScreensList.filter(
          (screen: any) => (screen.screenname
            ? screen.screenname
            : screen.label
              ? screen.label
              : screen.title
                ? screen.title
                : '') === selectedMenu.screenName,
        );

        if (optionArray.length === 0) {
          navigate(selectedMenu.url);
          return;
        }
        try {
          const option = optionArray[0];
          if (option.type === 'Utility') {
            dispatch(
              currentUtility({
                reportId: option.reportId,
                heading: option.label,
                progName: option.progName,
              }),
            );
            navigate('/workflow/system-utility');
            return;
          }
          if (option.type === 'WorkflowsReport') {
            dispatch(
              currentReport({
                reportId: option.reportid,
                heading: option.label,
                progName: option.prog_name,
              }),
            );
            navigate('/workflow/reports');
            return;
          }
          if (option.type === 'Workflows') {
            navigate(`${option.path}`);
            return;
          }
          const fields = Array.from(new Set([...option.fields.split(',')])).join(
            ',',
          );
          dispatch(SetRecentScreens({ options: option }));
          dispatch(
            LoadTables({
              tableID: option.tableId,
              page: '1',
              results_per_page: `${Math.ceil(height / 60)}`,
              fields: option.fields ? fields : '',
              fieldsConcatenated: option.fieldsConcatenated,
              order_by: '',
              where: option.screenConfiguration || '',
              redirect: `/maint/${option.screenId}`,
              screenId: option.screenId,
              keys: option.keys || '',
              defaultcolumns: option.defaultcolumns || '',
              lang: Language,
              labels: option.labels || '',
              navigate,
            }),
          );
          dispatch(
            LoadRecord({
              tableID: option.tableId,
              screenID: option.screenId,
              fields: option.fields || '',
              where: '',
              lang: Language,
            }),
          );
        } catch (error) {
          //
        }
      }
    }
  };

  useEffect(() => {
    if (!confirmModal && !checkNavigation && nextScreen) {
      navigateHeaderMenu(nextScreen);
    }
  }, [confirmModal, checkNavigation]);

  const recentNavigate = async (option:any) => {
    if (isModifiy) {
      setVisibleConfirm(true);
      setRecentScreen(option);
    } else {
      if (option.type === 'Workflows') {
        navigate(`${option.path}`);
        return;
      }
      const fields = Array.from(new Set([...option.fields.split(',')])).join(',');
      dispatch(
        LoadTables({
          tableID: option.tableId,
          page: '1',
          results_per_page: `${Math.ceil(height / 60)}`,
          fields: option.fields ? fields : '',
          fieldsConcatenated: option.fieldsConcatenated,
          order_by: '',
          where: option.screenConfiguration || '',
          redirect: `/maint/${option.screenId}`,
          screenId: option.screenId,
          keys: option.keys || '',
          defaultcolumns: option.defaultcolumns || '',
          lang: Language,
          navigate,
        }),
      );
      dispatch(
        LoadRecord({
          tableID: option.tableId,
          screenID: option.screenId,
          fields: option.fields || '',
          where: '',
          lang: Language,
        }),
      );
    }
  };

  const checkMaint = async () => {
    if (ScreensList && screenUrl) {
      const screen = await ScreensList.filter((item: any) => screenUrl.search(item?.screenId) > 0);
      try {
        if (screen) {
          const option = screen[0];
          const fields = Array.from(new Set([...option.fields.split(',')])).join(
            ',',
          );
          dispatch(SetRecentScreens({ options: option }));
          dispatch(StopLoading());
          dispatch(
            LoadTables({
              tableID: option.tableId,
              page: '1',
              results_per_page: `${Math.ceil(height / 60)}`,
              fields: option.fields ? fields : '',
              fieldsConcatenated: option.fieldsConcatenated,
              order_by: '',
              where: option.screenConfiguration || '',
              redirect: `/maint/${option.screenId}`,
              screenId: option.screenId,
              keys: option.keys || '',
              defaultcolumns: option.defaultcolumns || '',
              lang: Language,
              labels: option.labels || '',
              navigate,
            }),
          );
          dispatch(
            LoadRecord({
              tableID: option.tableId,
              screenID: option.screenId,
              fields: option.fields || '',
              where: '',
              lang: Language,
            }),
          );
        }
      } catch (error) {
        //
      }
    }
  };

  useEffect(() => {
    if (ScreensList.length && screenUrl) {
      checkMaint();
    }
  }, [ScreensList]);

  return (
    <>
      <Box className="nav-header">
        <div className="menu-section">
          <div
            className="Recent-tool"
            onMouseEnter={() => setisDrawerOpen(true)}
            onClick={() => recent()}
          >
            <Typography fontSize={10} color="#fff">
              Recent
            </Typography>
            <ArrowDropDownIcon style={{ color: '#fff' }} />
          </div>
          <div className="Logo-mobile" onClick={() => recent()}>
            <Typography
              style={{
                fontSize: '22px',
                fontWeight: 600,
                color: '#fff',
              }}
            >
              TMS
            </Typography>
            <Typography
              style={{
                fontSize: '12px',
                fontWeight: 300,
                color: '#fff',
              }}
            >
              8.0.0
            </Typography>
          </div>
          <div
            className="dropdown"
            onMouseEnter={() => setIsBusinessOpen(true)}
            onMouseLeave={() => setIsBusinessOpen(false)}
          >
            <div className="nav-dropdown btn btn-default dropdown-toggle">
              <Typography fontSize={10} color="#fff">
                Business
              </Typography>
              <ArrowDropDownIcon style={{ color: '#fff' }} />
            </div>
            {isBusinessOpen && (
              <MenuItems
                scrollVal=""
                menuList={business}
                navigateHeaderMenu={navigateHeaderMenu}
              />
            )}
          </div>
          <div
            className="dropdown"
            onMouseEnter={() => setIsProductOpen(true)}
            onMouseLeave={() => setIsProductOpen(false)}
          >
            <div className="nav-dropdown btn btn-default dropdown-toggle">
              <Typography fontSize={10} color="#fff">
                Product
              </Typography>
              <ArrowDropDownIcon style={{ color: '#fff' }} />
            </div>
            {isProductOpen && (
              <MenuItems
                scrollVal=""
                menuList={product}
                navigateHeaderMenu={navigateHeaderMenu}
              />
            )}
          </div>
          <div
            className="dropdown"
            onMouseEnter={() => setIsOperationsOpen(true)}
            onMouseLeave={() => setIsOperationsOpen(false)}
          >
            <div className="nav-dropdown btn btn-default dropdown-toggle">
              <Typography fontSize={10} color="#fff">
                Operations
              </Typography>
              <ArrowDropDownIcon style={{ color: '#fff' }} />
            </div>
            {isOperationsOpen && (
              <MenuItems
                scrollVal=""
                menuList={operations}
                navigateHeaderMenu={navigateHeaderMenu}
              />
            )}
          </div>
          <div
            className="dropdown"
            onMouseEnter={() => setIsManualTransactionOpen(true)}
            onMouseLeave={() => setIsManualTransactionOpen(false)}
          >
            <div className="nav-dropdown btn btn-default dropdown-toggle">
              <Typography fontSize={10} color="#fff">
                Manual Transactions
              </Typography>
              <ArrowDropDownIcon style={{ color: '#fff' }} />
            </div>
            {isManualTransactionOpen && (
              <MenuItems
                scrollVal=""
                menuList={manualTransaction}
                navigateHeaderMenu={navigateHeaderMenu}
              />
            )}
          </div>
          <div
            className="dropdown"
            onMouseEnter={() => setIsDocumentsOpen(true)}
            onMouseLeave={() => setIsDocumentsOpen(false)}
          >
            <div className="nav-dropdown btn btn-default dropdown-toggle">
              <Typography fontSize={10} color="#fff">
                Documents
              </Typography>
              <ArrowDropDownIcon style={{ color: '#fff' }} />
            </div>
            {isDocumentsOpen && (
              <MenuItems
                scrollVal=""
                menuList={documents}
                navigateHeaderMenu={navigateHeaderMenu}
              />
            )}
          </div>
          <div
            className="dropdown"
            onMouseEnter={() => setIsInventoryOpen(true)}
            onMouseLeave={() => setIsInventoryOpen(false)}
          >
            <div className="nav-dropdown btn btn-default dropdown-toggle">
              <Typography fontSize={10} color="#fff">
                Inventory
              </Typography>
              <ArrowDropDownIcon style={{ color: '#fff' }} />
            </div>
            {isInventoryOpen && (
              <MenuItems
                scrollVal=""
                menuList={inventory}
                navigateHeaderMenu={navigateHeaderMenu}
              />
            )}
          </div>
          <div
            className="dropdown"
            onMouseEnter={() => setIsUtilitiesOpen(true)}
            onMouseLeave={() => setIsUtilitiesOpen(false)}
          >
            <div className="nav-dropdown btn btn-default dropdown-toggle">
              <Typography fontSize={10} color="#fff">
                Utilities
              </Typography>
              <ArrowDropDownIcon style={{ color: '#fff' }} />
            </div>
            {isUtilitiesOpen && (
              <MenuItems
                scrollVal=""
                menuList={utilities}
                navigateHeaderMenu={navigateHeaderMenu}
              />
            )}
          </div>
          <div
            className="dropdown"
            onMouseEnter={() => setIsSettingsOpen(true)}
            onMouseLeave={() => setIsSettingsOpen(false)}
          >
            <div className="nav-dropdown btn btn-default dropdown-toggle">
              <Typography fontSize={10} color="#fff">
                Settings
              </Typography>
              <ArrowDropDownIcon style={{ color: '#fff' }} />
            </div>
            {isSettingsOpen && (
              <MenuItems
                scrollVal=""
                menuList={settings}
                navigateHeaderMenu={navigateHeaderMenu}
              />
            )}
          </div>
          <div
            className="dropdown"
            onMouseEnter={() => setIsAdminOpen(true)}
            onMouseLeave={() => setIsAdminOpen(false)}
          >
            <div className="nav-dropdown btn btn-default dropdown-toggle">
              <Typography fontSize={10} color="#fff">
                Admin
              </Typography>
              <ArrowDropDownIcon style={{ color: '#fff' }} />
            </div>
            {isAdminOpen && (
              <MenuItems
                scrollVal=""
                menuList={admin}
                navigateHeaderMenu={navigateHeaderMenu}
              />
            )}
          </div>
          <div
            className="dropdown"
            onMouseEnter={() => setIsReportingOpen(true)}
            onMouseLeave={() => setIsReportingOpen(false)}
          >
            <div className="nav-dropdown btn btn-default dropdown-toggle">
              <Typography fontSize={10} color="#fff">
                Reporting
              </Typography>
              <ArrowDropDownIcon style={{ color: '#fff' }} />
            </div>
            {isReportingOpen && (
              <MenuItems
                scrollVal=""
                menuList={reporting}
                navigateHeaderMenu={navigateHeaderMenu}
              />
            )}
          </div>
        </div>
        <div className="tools">
          {isSearching && <SmartNavSearch isModifiy={isModifiy} setVisibleConfirm={setVisibleConfirm} setRecentScreen={setRecentScreen} />}
          {loading ? (
            <CircularProgress color="secondary" />
          ) : !isSearching ? (
            <Search
              style={{ color: '#fff' }}
              onClick={() => setisSearching(!isSearching)}
            />
          ) : (
            <Close
              style={{ color: '#fff', marginLeft: '15px' }}
              onClick={() => setisSearching(!isSearching)}
            />
          )}

          {isAuthenticated && (
            <Link to="/user-settings" style={{ textDecoration: 'none' }}>
              <Person style={{ color: '#fff' }} />
            </Link>
          )}
        </div>
        <div className="mobile-menu" onClick={() => setOpen(!open)}>
          {!open ? (
            <Menu style={{ color: '#fff' }} />
          ) : (
            <Close style={{ color: '#fff' }} />
          )}
        </div>
      </Box>
      {open && (
        <div className="menu-section-mobile">
          <div className="nav-dropdown-mobile">
            <Typography>Business</Typography>
            <ArrowDropDownIcon style={{ color: '#fff' }} />
          </div>
          <div className="nav-dropdown-mobile">
            <Typography>Product</Typography>
            <ArrowDropDownIcon style={{ color: '#fff' }} />
          </div>
          <div className="nav-dropdown-mobile">
            <Typography>Operations</Typography>
            <ArrowDropDownIcon style={{ color: '#fff' }} />
          </div>
          <div className="nav-dropdown-mobile">
            <Typography>Manual Transactions</Typography>
            <ArrowDropDownIcon style={{ color: '#fff' }} />
          </div>
          <div className="nav-dropdown-mobile">
            <Typography>Documents</Typography>
            <ArrowDropDownIcon style={{ color: '#fff' }} />
          </div>
          <div className="nav-dropdown-mobile">
            <Typography>Inventory</Typography>
            <ArrowDropDownIcon style={{ color: '#fff' }} />
          </div>
          <div className="nav-dropdown-mobile">
            <Typography>Utilities</Typography>
            <ArrowDropDownIcon style={{ color: '#fff' }} />
          </div>
          <div className="nav-dropdown-mobile">
            <Typography>Settings</Typography>
            <ArrowDropDownIcon style={{ color: '#fff' }} />
          </div>
          <div className="nav-dropdown-mobile">
            <Typography>Admin</Typography>
            <ArrowDropDownIcon style={{ color: '#fff' }} />
          </div>
          <div className="nav-dropdown-mobile">
            <Typography>Reporting</Typography>
            <ArrowDropDownIcon style={{ color: '#fff' }} />
          </div>
          <div className="nav-dropdown-mobile">
            <Link to="/user-settings" style={{ textDecoration: 'none' }}>
              <Person style={{ color: '#fff' }} />
            </Link>
            <ArrowDropDownIcon style={{ color: '#fff' }} />
          </div>
        </div>
      )}
      {isDrawerOpen && (
        <div
          className="recent-drawer-container"
          onMouseLeave={() => setisDrawerOpen(false)}
        >
          <div className="recent-list-header">
            <Typography>Recent Used Links</Typography>
          </div>
          <div className="recent-list" onClick={() => recent()}>
            <Typography>Home</Typography>
          </div>
          {recentList?.map((option: any) => (
            <div
              className="recent-list"
              key={uuidv4() as any}
              onClick={() => recentNavigate(option)}
            >
              <Typography>{option.label}</Typography>
            </div>
          ))}
        </div>
      )}
      {/* <ConfirmationModal
        title="Leave Page"
        description="You have made changes on this page. If you leave without saving, your changes will be lost. Do you want to continue?"
        Visible={confirmModal}
        handleCloseConfirm={handleCloseConfirm}
      /> */}
    </>
  );
};

export default TmsHeader;
