import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  wrapper: {
    padding: '0px 10px',
    marginBottom: 10,
  },
  root: {
    padding: '0px 10px',
  },
  headerRow: {
    boxShadow: '0 4px 6px -3px #555',
  },
  bodyCell: {
    borderBottom: 'none !important',
    padding: '8px 0px !important',
  },
  headerCell: {
    borderBottom: 'none !important',
    padding: '0px !important',
  },
  bodyCellEmpty: {
    borderBottom: 'none !important',
    padding: '8px 7px !important',
  },
  tableRow: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#fbf8e9 !important',
    },
  },
  searchInput: {
    fontWeight: '400 !important',
    fontSize: '12px !important',
    lineHeight: '1.42857143 !important',
    padding: '4px 4px',
    color: '#333333',
    backgroundColor: '#fff',
    border: '1px solid #e5e5e5',
    height: 33,
    width: 200,
  },
  subHeaderContainer: {
    padding: '0px 10px',
    marginBottom: 5,
  },
  headerStepper: {
    padding: 2,
    display: 'flex',
    height: '8vh',
    marginBottom: 5,
  },
  stepperNavigator: {
    width: '100%',
    display: 'flex',
    backgroundColor: '#2096cd',
    margin: 2,
    height: '61.43px',
    cursor: 'pointer',
  },
  stepperNavigatorSuccess: {
    width: '100%',
    display: 'flex',
    backgroundColor: '#01a101',
    margin: 2,
    height: '61.43px',
    cursor: 'pointer',
  },

  stepperNavigatorDisabled: {
    width: '100%',
    display: 'flex',
    backgroundColor: '#ccc',
    margin: 2,
    height: '61.43px',
    cursor: 'pointer',
  },
  stepNumber: {
    backgroundColor: '#1b7fad',
    color: '#fff',
    width: '10%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  stepNumberSucess: {
    backgroundColor: '#006900',
    color: '#fff',
    width: '10%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  stepNumberDisabled: {
    backgroundColor: 'gray',
    color: '#fff',
    width: '10%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  stepLabel: {
    color: '#fff',
    display: 'block',
    paddingTop: 10,
    paddingLeft: 10,
  },
  controler: {
    width: '99%',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 10,
  },
});
