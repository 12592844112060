import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    padding: '0px 10px',
  },
  tableRoot: {
  },
  headerCell: {
    borderBottom: 'none !important',
    padding: '0px !important',
  },
  headerRow: {
    boxShadow: '0 4px 6px -3px #555',
  },
  bodyCell: {
    borderBottom: 'none !important',
    padding: '8px 0px !important',
  },
  bodyCellEmpty: {
    borderBottom: 'none !important',
    padding: '8px 7px !important',
  },
  tableRow: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#fbf8e9 !important',
    },
  },
  actionButton: {
    border: 'solid 1px #ddd !important',
    padding: '0px 1px !important',
    '&:hover': {
      backgroundColor: '#e6e6e6 !important',
      borderColor: '#adadad !important',
    },
  },
  actionsWrapper: {
    display: 'flex',
    gap: 10,
  },
  trafficDisabled: {
    backgroundColor: '#FFEB99 !important',
  },
  trafficEnabled: {
    backgroundColor: '#95E695 !important',
  },
});
