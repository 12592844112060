/* eslint-disable react/require-default-props */
import React, { MutableRefObject, RefObject, useRef } from 'react';

// Librairies
import {
  ButtonBase,
  Grid,
  IconButton,
  Paper,
  TextareaAutosize,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import clsx from 'clsx';

// Components
import Select from '../Select';

// Styles
import { useStyles } from './style';
import DateTimePicker from '../DateTimePicker';

interface IPros {
  onApply: () => void;
  onCancel: () => void;
  onRemove: () => void;
  searchData: any[];
  handleDateTimePckerChange: (name: string, value: Date | null) => void;
  handleSelectChange: (name: string, option: any) => void;
  handleInputChange: (name: string, value: string) => void;
  handleChangeFilterText?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  searchState: any;
  selectFilterText: string;
  handleAddSelectValue?: () => void;
  closeSearchCard: () => void;
}

const ViewTraceLogsSearchCard: React.FC<IPros> = ({
  onCancel,
  onApply,
  onRemove,
  searchData,
  handleDateTimePckerChange,
  handleInputChange,
  handleSelectChange,
  searchState,
  handleChangeFilterText,
  selectFilterText,
  handleAddSelectValue,
  closeSearchCard,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [focused, setFocused] = React.useState(false);
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const leftSideData = searchData.slice(0, 5);
  const rightSideData = searchData.slice(5);
  const showAdd = Boolean(selectFilterText)
    && !leftSideData[0]?.options?.find(
      (filter: any) => filter.value && filter.value === selectFilterText,
    );

  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  const handleTextAreaChange = (label: string, value: string) => {
    if (value.length > 1024) return;
    handleInputChange(label, value);
  };

  return (
    <Paper elevation={3} className={classes.paper}>
      <div className={clsx('flexRow', classes.modalHeader)}>
        <h4 className={classes.modalTitle}>Search Criteria</h4>
        <span className="takeTheRest" />
        <IconButton onClick={closeSearchCard}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </div>
      <div className={classes.modalBody}>
        <form action="POST" className={clsx(classes.form, 'flexSpaceAround')}>
          <Grid container columns={{ sm: 6, xs: 6, md: 12 }}>
            <Grid item xs={6}>
              <div className={classes.firstGroup}>
                {leftSideData.map((field: any) => (
                  <div
                    className={clsx(
                      classes.inputGroup,
                      matches && 'flexColumn',
                    )}
                    key={field?.nameId}
                  >
                    <div
                      className={clsx(
                        classes.label,
                        matches && classes.labelColumn,
                      )}
                    >
                      {field?.label}
                    </div>
                    <div style={{ flexGrow: 1 }}>
                      {field?.isDate ? (
                        <DateTimePicker
                          handleChange={(value) => handleDateTimePckerChange(field?.label, value)}
                          inputValue={
                            searchState[field.label as string]?.inputValue
                          }
                          pickerValue={
                            searchState[field.label as string]?.pickerValue
                          }
                          inputStyle={{ width: '100%' }}
                          shouldDisableTime
                        />
                      ) : (
                        <Select
                          options={field?.options}
                          onChange={(e, option) => handleSelectChange(field?.label, option)}
                          width="100%"
                          addable={field?.label === 'Filter'}
                          handleChangeFilterText={handleChangeFilterText}
                          filterTextValue={selectFilterText}
                          onAdd={handleAddSelectValue}
                          showAdd={showAdd}
                          value={searchState[field?.label]}
                          disableClearInput
                        />
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.firstGroup}>
                {rightSideData.map((field: any) => (
                  <div
                    className={clsx(
                      classes.inputGroup,
                      matches && 'flexColumn',
                    )}
                    key={field?.nameId}
                  >
                    <div
                      className={clsx(
                        classes.label,
                        matches && classes.labelColumn,
                      )}
                    >
                      {field?.label}
                    </div>
                    <div style={{ flexGrow: 1 }}>
                      {field?.type === 'text' ? (
                        <div>
                          {/* <TextareaAutosize
                            onChange={(e) => handleTextAreaChange(field?.label, e.target.value)}
                            maxRows={4}
                            value={searchState[field?.label]}
                            placeholder="Pattern Description or Help"
                            className={classes.textArea}
                            onFocus={onFocus}
                            onBlur={onBlur}
                          /> */}
                          {focused ? (
                            <div className={classes.textAreaHelper}>
                              {searchState?.['Search Pattern']?.length || 0}
                              /1024
                            </div>
                          ) : null}
                        </div>
                      ) : (
                        <Select
                          options={field?.options}
                          width="100%"
                          onChange={(e, option) => handleSelectChange(field?.label, option)}
                          value={searchState[field?.label]}
                          disableClearInput
                        />
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </Grid>
          </Grid>
        </form>
      </div>
      <div className={classes.modalActions}>
        <ButtonBase
          className={classes.removeButton}
          onClick={onRemove}
          disabled={
            !searchState?.Filter?.value
            || searchState?.Filter?.label?.includes('[Temporary]')
          }
        >
          Remove
        </ButtonBase>
        <ButtonBase className={classes.applyButton} onClick={onApply}>
          Apply
        </ButtonBase>
        <ButtonBase className={classes.cancleButton} onClick={onCancel}>
          Cancel
        </ButtonBase>
      </div>
    </Paper>
  );
};

export default ViewTraceLogsSearchCard;
