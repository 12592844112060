/* eslint-disable no-plusplus */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';

// Librairies
import { useDispatch, useSelector } from 'react-redux';

// Components
import {
  Alert, Snackbar, AlertTitle, IconButton, CircularProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { pdf } from '@react-pdf/renderer';
import { useNavigate } from 'react-router-dom';
import TmsHeader from '../../../components/header';
import ViewReportOptions from '../../../components/Reports/Workflows/ViewReportOptions';
import SubHeader from '../../../components/subHeader';
import OptionsButton from '../../../components/optionsButton';
// import Loader from '../../../components/Loader';

// Styles
import { useStyles } from './style';
import ViewReportOptionDialog from '../../../components/Reports/Workflows/ViewReportOptionDialog';
import {
  commentSubmit,
  getComment,
  submitPrintReport,
  emailSubmit,
} from '../../../redux/actions/reports/viewReport';
import ViewReportPDFFile from '../../../components/Reports/Workflows/ViewReportPDFFile';

const ViewReport: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmationAlert, setOpenConfirmationAlert] = useState(true);
  const [play, setPlay] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);
  const [modalType, setModalType] = useState<string>('');
  const [formData, setFormData] = useState<any>(
    {
      seqno: '', routecode: '', group_name: 'Create New Group', email_list: '',
    },
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const {
    data, routecode, seqno, comment, reportName, emailGroup,
  } = useSelector(
    (state: any) => state?.Reports?.ViewReports,
  );
  const { loading, stepperData } = useSelector(
    (state: any) => state.ScreenReducer,
  );
  useEffect(() => {
    setFormData({
      ...formData,
      comment,
    });
  }, [comment]);
  const handleAnchorClose = () => {
    setAnchorEl(null);
  };
  const handleOptionClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseOpenDialog = () => {
    setOpenDialog(false);
  };
  const handleOpenDialog = (type:string) => {
    setModalType(type);
    setOpenDialog(true);
    handleAnchorClose();
    if (type === 'Comment') {
      dispatch(getComment(seqno));
    }
  };
  const handleClose = () => {
    setOpenConfirmationAlert(false);
    navigate(-1);
  };
  // This function for Export Report to Txt
  const downloadReportTxt = () => {
    if (data.length !== 0) {
      // eslint-disable-next-line max-len
      const dataToExport = data?.report.map((item: any) => (item === '&nbsp;' ? ' ' : item)).join('\n');
      const blob = new Blob([dataToExport], { type: 'text/csv' });
      const elem = window.document.createElement('a');
      elem.href = window.URL.createObjectURL(blob);
      elem.download = 'Report.txt';
      document.body.appendChild(elem);
      elem.click();
      document.body.removeChild(elem);
    }
  };
  // This function for Export Report to PDF
  const downloadReportPDF = async () => {
    const blob = await pdf(
      <ViewReportPDFFile data={data} />,
    ).toBlob();
    const elem = window.document.createElement('a');
    elem.href = window.URL.createObjectURL(blob);
    elem.download = 'Report.pdf';
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
  };
  // This function for Buttons to go to Page
  const goToPageBtn = (pageNo: any) => {
    // eslint-disable-next-line no-unused-expressions
    document!.getElementById(`page${pageNo}`)!.scrollIntoView({
      behavior: 'smooth',
    });
  };
  // This function for Input field to go to PAge
  const goToPage = (e: any) => {
    console.log(e.target.value);
    // eslint-disable-next-line no-unused-expressions
    document!.getElementById(`page${e.target.value}`)!.scrollIntoView({
      behavior: 'smooth',
    });
  };
  const handleSubmitModal = () => {
    if (modalType === 'Print Report') {
      dispatch(submitPrintReport(seqno, formData.routecode));
    }
    if (modalType === 'Comment') {
      dispatch(commentSubmit(seqno, formData?.comment));
    }
    if (modalType === 'Email Report') {
      // eslint-disable-next-line max-len
      dispatch(emailSubmit(seqno, formData?.group_name, formData?.email_group_name, formData?.email_list));
    }
    handleCloseOpenDialog();
    handleAnchorClose();
  };
  return loading ? (
    <div
      style={{
        width: '100%',
        height: '90vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress />
    </div>
  ) : (
    <>
      <TmsHeader />
      <SubHeader title="View Report">
        <OptionsButton handleClick={handleOptionClick} />
        <ViewReportOptions
          anchorEl={anchorEl}
          onClose={handleAnchorClose}
          downloadReportTxt={downloadReportTxt}
          handleOpenDialog={handleOpenDialog}
          downloadReportPDF={downloadReportPDF}
        />
      </SubHeader>
      {!data?.type ? (
        <>
          <div className={classes.printjumpquick}>
            {data.length !== 0 ? data?.groupIndexList.map((currIndex: number) => {
              const btns: any = [];
              for (let i = currIndex; i <= (currIndex + Number(data?.buttonsPerGroup) || 0); i++) {
                btns.push(
                  <>
                    <button
                      type="button"
                      className={classes.systemgreybtn}
                      onClick={() => goToPageBtn(i)}
                    >
                      Page
                      {' '}
                      {i}
                    </button>
                  </>,
                );
              }
              btns.push(<span>----</span>);
              return (
                <>
                  {btns}
                </>
              );
            }) : '' }
            <div
              style={{ display: 'inline-block', fontSize: '12px' }}
            >
              Go To Page
              <input type="text" onKeyUp={goToPage} />
            </div>
          </div>
          {!data.type ? (
            <div className={classes.printarea}>
              {data.length !== 0 ? data?.rpt.map((rptpage: any, index: any) => (
                <div id={`page${index + 1}`} className="reportpage">
                  {rptpage.length !== 0 ? (
                    <pre className={classes.report}>
                      <div
                        className={classes.reportpagebody}
                        style={{ height: rptpage.length === 0 ? '30' : rptpage.length * 20 }}
                      >
                        {rptpage.map((item: any) => (
                          <div className={classes.reportline}>{item === '&nbsp;' ? ' ' : item}</div>
                        ))}
                      </div>
                    </pre>
                  ) : ''}
                  <div className={classes.spacer} />
                </div>
              )) : '' }
            </div>
          ) : '' }
        </>
      ) : '' }
      <ViewReportOptionDialog
        open={openDialog}
        onClose={handleCloseOpenDialog}
        modalRouteCode={routecode}
        type={modalType}
        setFormData={setFormData}
        formData={formData}
        handleSubmitModal={handleSubmitModal}
        reportName={reportName}
      />
      {data.type ? (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={openConfirmationAlert}
          onClose={handleClose}
          autoHideDuration={!play ? 6000 : null}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          ClickAwayListenerProps={{ onClickAway: () => {} }}
        >
          <Alert
            severity={data?.type}
            action={(
              <>
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setPlay((pause) => !pause);
                  }}
                >
                  {play ? <PlayArrowIcon fontSize="inherit" /> : <PauseIcon fontSize="inherit" />}
                </IconButton>
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              </>
            )}
          >
            <AlertTitle>{data?.title}</AlertTitle>
            {data?.msg}
          </Alert>
        </Snackbar>
      ) : '' }
    </>
  );
};

export default ViewReport;
