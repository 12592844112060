import { Dispatch } from 'redux';
import Api from '../../api/index';
import { START_LOADING, STOP_LOADING } from '../../constants/Screens';
import {
  LOAD_DOCUMENT_BUILDER_TABLE,
  LOAD_DOCUMENT_BUILDER_DETAILS,
  EXPORT_DOCUMENT_BUILDER_DETAILS_FILE,
  CLEAR_DOCUMENT_BUILDER_DETAILS_FILE,
} from '../../constants/workflows';

export const getDocumentBuilderTableData = () => async (dispatch: Dispatch) => {
  dispatch({ type: START_LOADING });
  const response = await Api.get(
    'workflow147/TableData?screenID=WORKFLOW-147&userID=tms&lang=en-US',
  );
  dispatch({
    type: LOAD_DOCUMENT_BUILDER_TABLE,
    payload: {
      headerList: response.data.headerList,
      data: response.data.data,
      hiddenColumns: response.data.hiddenColumns,
      visibleColumns: response.data.visibleColumns,
    },
  });
  dispatch({ type: STOP_LOADING });
};

interface DocumentConfig {
  docName: string,
  isEditMode: boolean,
  isCloneMode: boolean,
}
export const getDocumentBuilderDetails = ({ docName, isEditMode, isCloneMode }: DocumentConfig) => async (dispatch: Dispatch) => {
  dispatch({ type: START_LOADING });

  const response = await Api.post(
    `workflow147/getDetail?docname=${docName}&screenID=Workflow-147&editMode=${isEditMode ? 'y' : 'n'}&cloneDocument=${isCloneMode ? 'y' : 'n'}`,
  );
  dispatch({
    type: LOAD_DOCUMENT_BUILDER_DETAILS,
    payload: response.data.data,
  });
  dispatch({ type: STOP_LOADING });
};

export const exportDoc2PkgDocumentBuilder = (docname: string) => async (dispatch: Dispatch) => {
  dispatch({ type: START_LOADING });

  try {
    const response = await Api.post(
      `workflow147/doc2pkg?docname=${docname}`,
    );
    const contentDisposition = response.headers['content-disposition'];
    let fileName = 'download';
    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(/filename=(.*)/);
      if (fileNameMatch && fileNameMatch.length >= 0) {
        fileName = String(fileNameMatch[1]);
      }
    }

    dispatch({
      type: EXPORT_DOCUMENT_BUILDER_DETAILS_FILE,
      payload: { data: response.data, fileName },
    });
  } catch (error: any) {
    dispatch({ type: STOP_LOADING });
  }

  dispatch({ type: STOP_LOADING });
};

export const clearExportDoc2PkgDocumentBuilder = () => async (dispatch: Dispatch) => {
  dispatch({
    type: CLEAR_DOCUMENT_BUILDER_DETAILS_FILE,
  });
};
