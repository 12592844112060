import React, { useEffect, useState } from 'react';

// Librairies
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Alert, Button, Snackbar } from '@mui/material';

// Components
import SubHeader from '../../../components/subHeader';
import TmsHeader from '../../../components/header';
import OptionsButton from '../../../components/optionsButton';
import TankGaugeEntryOptions from '../../../components/TankGaugeEntryOptions';
import TankGaugeEntryTable from '../../../components/TankGaugeEntryTable';
import ColumnsDialog from '../../../components/ColumnsDialog';

// Actions
import {
  clearTankGaugeEntryNotification,
  getNewColumns,
  getTankGaugeEntryData,
  setSelectedTankGaugeRecord,
} from '../../../redux/actions/workflows/tankGaugeEntry';
import { useWorkflowState } from '../../../hooks/useWorkflowState';
import WorkflowTableUtils from '../../../components/WorkflowTableUtils';

// Utils
const columnsLabels = {
  fol_mo: 'Folio Month',
  fol_no: 'Folio Number',
  open_date: 'Open Date',
  freeze_date: 'Freeze Date',
  tt_status: 'Folio Status',
  no_of_gauges: 'Number of Gauges',
  'term_id:TerminalProfile.name': 'Terminal ID - Name',
};

const searchHeader = [
  { label: 'Folio Month', key: 'fol_mo' },
  { label: 'Folio Number', key: 'fol_no' },
  { label: 'Open Date', key: 'open_date' },
  { label: 'Freeze Date', key: 'freeze_date' },
  { label: 'Folio Status', key: 'tt_status' },
  { label: 'Number of Gauges', key: 'no_of_gauges' },
  { label: 'Terminal ID - Name', key: 'term_id:TerminalProfile.name' },
];

const TankGaugeEntryScreen: React.FC = () => {
  const [columnsDialogOpen, setColumnsDialogOpen] = useState<boolean>(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const {
    data, visibleColumns, hiddenColumns, responseNotification,
  } = useSelector(
    (state: any) => state.Workflows?.TankGaugeEntry,
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    recordsToShow,
    tableState,
    handlePaginationChange,
    handleSearchChange,
    handleSelectChange,
    handleSortTable,
    handleCloseMenu,
    handleOptionClick,
  } = useWorkflowState(
    searchHeader,
    data,
    getTankGaugeEntryData,
    false,
    false,
    18,
    false,
    'Frozen|Current',
  );

  const handleOpenColumnsDialog = () => {
    setColumnsDialogOpen(true);
    handleCloseMenu();
  };

  const handleCloseColumnsDialog = () => {
    setColumnsDialogOpen(false);
  };

  const handleSubmitNewColumns = (newColumns: string[]) => {
    dispatch(getNewColumns(newColumns));
  };

  useEffect(() => {
    if (responseNotification?.type === 'success') {
      setSnackBarOpen(true);
      dispatch(getTankGaugeEntryData());
    }
    return () => {
      dispatch(clearTankGaugeEntryNotification());
    };
  }, [responseNotification?.type]);

  const handleRestoreDefault = () => {
    dispatch(
      getNewColumns([
        'fol_mo',
        'fol_no',
        'open_date',
        'freeze_date',
        'tt_status',
      ]),
    );
  };

  const handleClickRow = (row: any) => {
    dispatch(setSelectedTankGaugeRecord(row));
    navigate(
      `recordDetail/${row.term_id.trim()}/${row.folioMonth}/${row.fol_no}/${
        row.folioStatus
      }`,
    );
  };

  const handleCloseSnackBar = () => {
    setSnackBarOpen(false);
    dispatch(clearTankGaugeEntryNotification());
  };

  return (
    <>
      <TmsHeader />
      <SubHeader title="Tank Gauge Entry">
        <OptionsButton handleClick={handleOptionClick} />
        <TankGaugeEntryOptions
          anchorEl={tableState.anchorEl}
          onClose={handleCloseMenu}
          openDialog={handleOpenColumnsDialog}
        />
      </SubHeader>
      <WorkflowTableUtils
        handleSearchChange={handleSearchChange}
        handleSelectChange={handleSelectChange}
        recordsNumber={tableState.recordsNumber}
        searchText={tableState.searchText}
      />
      <TankGaugeEntryTable
        handleClickRow={handleClickRow}
        allDataCount={data?.length}
        recordsToShow={recordsToShow}
        searchText={tableState.searchText}
        visibleColumns={visibleColumns}
        handlePaginationChange={handlePaginationChange}
        tableState={tableState}
        order={tableState.order}
        orderBy={tableState.orderBy}
        sortTable={handleSortTable}
      />
      <ColumnsDialog
        onClose={handleCloseColumnsDialog}
        title="Configure Visible Columns"
        open={columnsDialogOpen}
        visibleColumns={visibleColumns}
        hiddenColumns={hiddenColumns}
        columnsLabels={columnsLabels}
        submitNewColumns={handleSubmitNewColumns}
        handleRestoreDefault={handleRestoreDefault}
      />
      <Snackbar
        open={snackBarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackBar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseSnackBar} severity="success">
          SUCCESS
          <div>
            Tank Gauge Entries Updated:
            <Button onClick={() => navigate(-1)}>(Review)</Button>
          </div>
        </Alert>
      </Snackbar>
    </>
  );
};

export default TankGaugeEntryScreen;
