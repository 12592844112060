import React from 'react';

// Librairies
import { Menu, MenuItem } from '@mui/material';

// Styles
import { useStyles } from './style';

interface IProps {
  anchorEl: any;
  onClose: () => void;
  manageGroupProcess: (
    controlType: string,
    processId: boolean,
    groupId: boolean
  ) => void;
  status: string;
  removeRow: () => void;
}

const ProcessMenu: React.FC<IProps> = ({
  anchorEl,
  onClose,
  manageGroupProcess,
  status,
  removeRow,
}) => {
  const classes = useStyles();

  const handleProcess = () => {
    if (status === '#FFA07A') {
      manageGroupProcess('startprocess', true, false);
    } else {
      manageGroupProcess('stopprocess', true, false);
    }
    onClose();
  };

  const handleGroup = () => {
    if (status === '#FFA07A') {
      manageGroupProcess('startgroup', false, true);
    } else {
      manageGroupProcess('stopgroup', false, true);
    }
    onClose();
  };

  return (
    <Menu
      classes={{ root: classes.root, list: classes.list, paper: classes.paper }}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
    >
      <MenuItem classes={{ root: classes.menuItem }} onClick={handleProcess}>
        {status === '#FFA07A' ? 'Start Process' : 'Stop Process' }
      </MenuItem>
      <MenuItem classes={{ root: classes.menuItem }} onClick={handleGroup}>
        {status === '#FFA07A' ? 'Start Group' : 'Stop Group' }
      </MenuItem>
      {status === '#FFA07A' ? (
        <MenuItem classes={{ root: classes.menuItem }} onClick={removeRow}>
          Remove
        </MenuItem>
      ) : null}
    </Menu>
  );
};

export default ProcessMenu;
