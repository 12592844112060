import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  refreshContainer: {
    color: ' white',
    backgroundColor: '#333',
    border: '1px solid #66afe9',
    fontSize: 12,
    fontWeight: 400,
    padding: 5,
    cursor: 'pointer',
  },
  container: {
    padding: 5,
    paddingTop: 0,
    cursor: 'pointer',
    '&:active': {
      border: '1px solid #66afe9',
    },
  },
});
