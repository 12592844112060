/* eslint-disable react/no-array-index-key */
/* eslint-disable react/require-default-props */
import * as React from 'react';
import usePagination from '@mui/material/usePagination';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const List = styled('ul')({
  listStyle: 'none',
  padding: 0,
  margin: 0,
  display: 'flex',
});

const useStyles = makeStyles({
  button: {
    border: '1px solid #bbb !important',
    boxSizing: 'border-box',
    display: 'inline-block',
    minWidth: '1.5em',
    padding: '0.5em 1em',
    marginLeft: '2px',
    textAlign: 'center',
    textDecoration: 'none !important',
    cursor: 'pointer',
    color: '#333 !important',
    background: 'white',
    '&:disabled': {
      cursor: 'default',
      color: '#666 !important',
      border: '1px solid transparent',
      background: 'transparent',
      boxShadow: 'none',
      backgroundColor: '#eee !important',
    },
    '&:hover': {
      color: 'white !important',
      border: '1px solid #111',
      background: '#585858',
    },
  },
});

interface IProps {
  count?: number;
  pageNumber?: number;
  onChange?: (event: any, targetPage: any) => void;
}

const PaginationComponent: React.FC<IProps> = ({
  count,
  pageNumber,
  onChange,
}) => {
  const { items } = usePagination({
    count,
    page: pageNumber,
    onChange,
  });
  const classes = useStyles();

  return (
    <nav>
      <List>
        {items.map(({
          page, type, selected, onClick,
        }, index) => {
          let children = null;
          if (type === 'start-ellipsis' || type === 'end-ellipsis') {
            children = '…';
          } else if (type === 'page') {
            children = (
              <button
                type="button"
                className={classes.button}
                onClick={onClick}
                style={{
                  fontWeight: selected ? 'bold' : undefined,
                }}
              >
                {page}
              </button>
            );
          } else {
            children = (
              <button
                type="button"
                className={classes.button}
                disabled={
                  (type === 'previous' && pageNumber === 1)
                  || (type === 'next' && (pageNumber === count || Number.isNaN(count)))
                }
                onClick={onClick}
              >
                {type}
              </button>
            );
          }
          return <li key={index}>{children}</li>;
        })}
      </List>
    </nav>
  );
};

export default PaginationComponent;
