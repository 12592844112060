/* eslint-disable no-param-reassign */
import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import TmsHeader from '../../../components/header';
import VerticalLinearStepper from '../../../components/verticalStepper/verticalStepper';
import {
  GetTransactionSchema,
  getScreensList,
} from '../../../redux/actions/ScreenActions';
import { useStyles } from './style';
import contractTransactionStructure from './stepStructure.json';
import currentSchema from './currentStep.json';
import { TransactionTemplate } from '../../../components/transactionTemplate';
import NonBulkTransactionMenu from '../bulkTransaction/bulkTransactionMenu';
import BulkTransferTankVerticalStepper from '../../../components/bulkTransferTanks/verticalStepper';
import api from '../../../redux/api';

const formatProductListObject = (obj: any) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null) {
      obj[key] = '';
    }
  });
  return obj;
};
const BulkTransferTank = () => {
  const classes = useStyles();
  const [values, setValues] = useState<any>({
    term_id: { value: 'B344', label: 'B344: TOPTECH (LONGWOOD, FL)' },
    shippingmethodid: { value: 'X', label: 'X: Transfer' },
  });
  const [openTemplate, setopenTemplate] = useState(false);
  const [openSubmit, setopenSubmit] = useState(false);
  const [valuesToSend, setValuesToSend] = useState<any>({
    term_id: 'B344',
    shippingmethodid: 'X',
    reason: '3150',
    fromtrans_id: '561',
    totrans_id: '560',
    route_cd: 'LPRNT',
  });
  const [templateList, settemplateList] = useState([]);
  const [ProductsToSend, setProductsToSend] = useState<any>([]);
  const [templateValue, settemplateValue] = useState('');
  const [contractidOptions, setcontractidOptions] = useState([]);
  const [contractMode, setcontractMode] = useState('');
  const [visible, setVisible] = useState(false);
  const [prefixFromSupplier, setprefixFromSupplier] = useState<any>({});
  const [prefixToSupplier, setprefixToSupplier] = useState<any>({});
  const [prefix, setprefix] = useState('');
  const [products, setProducts] = useState<any>([]);
  const [supplierTo, setSupplierTo] = useState<any>([]);
  const { loading, stepperData } = useSelector(
    (state: any) => state.ScreenReducer,
  );
  const addProducts = () => {
    setProducts([...products, { id: uuidv4()?.replaceAll('-', '') }]);
  };
  const addSupplierTo = () => {
    setSupplierTo([...supplierTo, { id: uuidv4()?.replaceAll('-', '') }]);
  };
  const loadStepProducts = (item: any) => {
    let productstest: any = [];
    const prodid = uuidv4();
    productstest = [...productstest, { id: prodid, [prodid]: item }];
    setProducts([...products, { id: prodid, item }]);
    return { id: prodid, [prodid]: item };
  };
  const handleTemplate = (val: any) => {
    settemplateValue(val?.newValue?.label || val?.newValue);
  };
  const removeFormFields = (i: any) => {
    const deleteProd: any = [...products];
    delete prefixFromSupplier?.[i];
    setProducts(deleteProd.filter((obj: any) => obj.id !== i));
  };
  const removeToFields = (i: any) => {
    const deleteProd: any = [...supplierTo];
    delete prefixFromSupplier?.[i];
    setSupplierTo(deleteProd.filter((obj: any) => obj.id !== i));
  };
  const onClose = () => {
    setopenTemplate(false);
  };
  const onCloseSubmit = () => {
    setopenSubmit(false);
  };
  const onOpen = () => {
    setopenTemplate(true);
  };
  const onOpenSubmit = () => {
    setopenSubmit(true);
  };
  const startNewTransaction = () => {
    setValuesToSend({});
    setValues({});
    setprefix('');
    setProducts([]);
    setprefixFromSupplier({});
  };
  // set from trans id and to trans id values on reason code change
  const setFromToTransIdValues = (value: any) => {
    switch (value?.value) {
      case '3150':
        setValues({
          ...values,
          fromtrans_id: { value: '561', label: '561: COMPOUND/REBRAND USED' },
          totrans_id: { value: '560', label: '560: COMPOUND/REBRAND MADE' },
          reason: value,
        });
        setValuesToSend({
          ...valuesToSend,
          fromtrans_id: '561',
          totrans_id: '560',
          reason: value?.value,
        });
        break;
      case '4200':
        setValues({
          ...values,
          fromtrans_id: { value: '561', label: '561: COMPOUND/REBRAND USED' },
          totrans_id: { value: '560', label: '560: COMPOUND/REBRAND MADE' },
          reason: value,
        });
        setValuesToSend({
          ...valuesToSend,
          fromtrans_id: '561',
          totrans_id: '560',
          reason: value?.value,
        });
        break;
      case '5300':
        setValues({
          ...values,
          fromtrans_id: { value: '504', label: '504: STOCK TRANSFER OUT' },
          totrans_id: { value: '503', label: '503: STOCK TRANSFER IN' },
          reason: value,
        });
        setValuesToSend({
          ...valuesToSend,
          fromtrans_id: '504',
          totrans_id: '503',
          reason: value?.value,
        });
        break;
      default:
        break;
    }
  };
  // transaction template section
  const LoadContractTransaction = (templateSteps: any) => {
    const loadedthreeStepsvalues = templateSteps?.other_steps
      .flat(1)
      .map((item: any) => ({ key: [item.id], value: item.value || '' }))
      .reduce(
        (obj: any, item: any) => Object.assign(obj, {
          [item.key]:
              item.value?.search('-') > 0
                ? {
                  value: item.value?.split('-')?.[0]?.replace(/\s+/g, ''),
                  label: `${item.value?.split('-')?.[0]} - ${
                    item.value?.split('-')?.[1]
                  }`,
                }
                : item.value?.split('-')?.[0],
        }),
        {},
      );
    const loadedthreeStepsvaluesToSend = templateSteps?.other_steps
      .flat(1)
      .map((item: any) => ({ key: [item.id], value: item.value || '' }))
      .reduce(
        (obj: any, item: any) => Object.assign(obj, {
          [item.key]: item.value?.split('-')?.[0]?.replace(/\s+/g, ''),
        }),
        {},
      );
    setValuesToSend(loadedthreeStepsvaluesToSend);
    setValues(loadedthreeStepsvalues);
    const loadedProductStepProd = templateSteps?.loadValues?.productdetail
      .map((val: any, key: any) => loadStepProducts(val))
      .reduce(
        (obj: any, item: any) => Object.assign(obj, { [item.id]: item[item.id] }),
        {},
      );
  };
  const resetPrefixProducts = (
    option: any,
    valuesFromTank?: any,
    key?: any,
  ) => {
    if (valuesFromTank) {
      setValuesToSend({
        ...valuesToSend,
        [key]: Object.fromEntries(
          Object.entries(option).map(([k, v]: any) => [
            `${k.split('-')?.[1]}`,
            v?.toString() || '',
          ]),
        ),
      });
      setprefixToSupplier(option);
    } else {
      setValuesToSend({
        ...valuesToSend,
        [key]: Object.fromEntries(
          Object.entries(option).map(([k, v]: any) => [
            `${k.split('-')?.[1]}`,
            v?.toString() || '',
          ]),
        ),
      });
      setprefixFromSupplier(option);
    }
  };
  const getTransactionTemplate = async () => {
    try {
      const response = await api.get(
        'manualtrans/showstep?step=S4&screenID=WORKFLOW-041&manualTransferMode=nonbulk',
      );
      settemplateList(response.data.templateList);
    } catch (err) {
      console.log('error', err);
    }
  };
  const getTransactionTemplateByValue = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}manualtrans/showstep?step=S4&screenID=WORKFLOW-041&manualTransferMode=nonbulk&action=load&tname=${templateValue}`,
      );
      LoadContractTransaction(response.data);
    } catch (err) {
      console.log('error', err);
    }
  };
  const deleteContractTransactionTemplate = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}manualtrans/deletetemp?action=remove&mtmode=nonbulk&tname=${templateValue}&screenID=WORKFLOW-041`,
      );
    } catch (err) {
      console.log('error', err);
    }
  };
  const saveContractTransactionTemplate = async () => {
    try {
      const response: any = await api.post('manualtrans/submit', {
        manualTransferMode: 'contract',
        userID: 'tms',
        term_id: 'B344 ',
        route_cd: valuesToSend?.route_cd,
        uom: valuesToSend?.uom,
        action: 'save',
        tname: templateValue,
        screenID: 'WORKFLOW-041',
        driver: valuesToSend?.driver,
        carrier: valuesToSend?.carrier,
        shippingmethodid: valuesToSend?.shippingmethodid,
        truck: valuesToSend?.truck,
        trailer1: valuesToSend?.trailer1,
        trailer2: valuesToSend?.trailer2,
        supplier_no: valuesToSend?.supplier_no,
        cust_no: valuesToSend?.cust_no,
        acct_no: valuesToSend?.acct_no,
        destination_no: valuesToSend?.destination_no,
        dest_splc_code: valuesToSend?.dest_splc_code,
        trans_id: valuesToSend?.trans_id,
        reason: valuesToSend?.trans_id,
        dutytoparty: valuesToSend?.dutytoparty,
        doc_no: valuesToSend?.doc_no,
        folio_mo: valuesToSend?.folio_mo,
        folio_no: valuesToSend?.folio_no,
        loadstartdatetime: valuesToSend?.loadstartdatetime,
        loadstopdatetime: valuesToSend?.loadstopdatetime,
        entrydatetime: valuesToSend?.entrydatetime,
        exitdatetime: valuesToSend?.exitdatetime,
        thirdpartyref: valuesToSend?.thirdpartyref,
        po_num: valuesToSend?.po_num,
        host_po_num: valuesToSend?.host_po_num,
        release_no: valuesToSend?.release_no,
        comment1: valuesToSend?.comment1,
        comment2: valuesToSend?.comment2,
        ticket_no: false,
        batch_no: valuesToSend?.batch_no,
        seal1: valuesToSend?.seal1,
        seal2: valuesToSend?.seal2,
        productdetail: Object.values(ProductsToSend),
      });
    } catch (e: any) {
      console.log('error', e);
    }
  };

  const getSupCustAccDestForContract = async (contract: any) => {
    try {
      if (valuesToSend?.contractid !== '') {
        const response = await api.get(
          `tablescreen/tablemaintdata/?tableID=Contract%20%20%20%20%20%20&page=1&results_per_page=15%20%20%20%20&fields=ContractNumber,Description,SupplierNo:SupplierNo=supplier_no:Supplier.supplier_name,CustNo:CustNo=cust_no:Customer.cust_name,EffectiveDate&where=ContractNumber=${contract?.value}&search=&order_by=&lang=null`,
        );
        const ContractItemResponse = await api.get(
          `tablescreen/tablemaintdata/?tableID=ContractItem%20%20%20%20%20%20&page=1&results_per_page=15%20%20%20%20&fields=ContractItemNumber&where=ContractNumber=${contract?.value}&search=&order_by=&lang=null`,
        );
        setValuesToSend({
          ...valuesToSend,
          contractid: contract,
          cust_no:
            response.data?.data?.[0]?.[
              'CustNo:CustNo=cust_no:Customer.cust_name'
            ]?.value,
          supplier_no:
            response.data?.data?.[0]?.[
              'SupplierNo:SupplierNo=supplier_no:Supplier.supplier_name'
            ]?.value,
          ContractItemNumber:
            ContractItemResponse.data?.data?.[0]?.ContractItemNumber,
          acct_no: '',
        });
        setValues({
          ...values,
          contractid: contract,
          cust_no:
            response.data?.data?.[0]?.[
              'CustNo:CustNo=cust_no:Customer.cust_name'
            ],
          supplier_no:
            response.data?.data?.[0]?.[
              'SupplierNo:SupplierNo=supplier_no:Supplier.supplier_name'
            ],
          ContractItemNumber:
            ContractItemResponse.data?.data?.[0]?.ContractItemNumber,
          acct_no: '',
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const changeValueSubProducts = async (
    value: any,
    key: string,
    name: string,
    valuesFromTank?: any,
  ) => {
    try {
      const response: any = await api.post('synajax/procajax',
        {
          queueid: 'TDC',
          data: {
            cmd: 'tanktransfer_change',
            changeid: name,
            term_id: 'B344   ',
            reason_x: '3150',
            direction: valuesFromTank ? '1' : '0',
            uom: 'B',
            baseUOMList: '',
            fieldlist: formatProductListObject(value),
            tankDetailListFrom: valuesFromTank || '[]',
            tankDetailListTo: '[]',
            prefix: key || value?.prefix,
          },
          tagid: null,
          jqxhr: null,
        });
      resetPrefixProducts(response.data?.[0]?.fieldList, valuesFromTank, key);
    } catch (e: any) {
      console.log('error', e);
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    key: any,
    subKey: string,
    pref: any,
  ) => {
    const { name, value } = e.target;
    if (key) {
      if (subKey) {
        setValuesToSend({
          ...valuesToSend,
          [key]: {
            ...valuesToSend[key],
            component: {
              ...valuesToSend[key]?.component,
              [subKey]: {
                ...valuesToSend[key]?.component?.[subKey],
                [name]: value,
                prefix: pref,
                contractid: valuesToSend?.contractid,
                ContractItem: valuesToSend?.ContractItemNumber?.value,
              },
            },
          },
        });
      } else {
        setValuesToSend({
          ...valuesToSend,
          [key]: {
            ...valuesToSend[key],
            [name]: value,
            prefix: pref,
            contractid: valuesToSend?.contractid,
            ContractItem: valuesToSend?.ContractItemNumber?.value,
          },
        });
      }
      setProductsToSend({ ...ProductsToSend, [key]: valuesToSend[key] });
    } else {
      const inputedData = {
        ...valuesToSend,
        [name]: value,
      };
      setValuesToSend(inputedData);
    }
    setprefixFromSupplier({
      ...prefixFromSupplier,
      [`${key}-${[name]}`]: value,
    });
    setTimeout(() => {
      changeValueSubProducts(
        {
          ...prefixFromSupplier,
          [`${key}-${[name]}`]: value,
        },
        key,
        name,
        '',
      );
    }, 2000);
  };
  const handleSelectChange = (
    label: any,
    e: React.ChangeEvent<HTMLInputElement>,
    newValue: any,
    key: any,
  ) => {
    setValues({
      ...values,
      [key]: {
        ...[key],
        [label]: newValue.value,
        prefix: label?.slice(0, label?.indexOf('-')),
      },
    });
    setValuesToSend({
      ...valuesToSend,
      [key]: {
        ...[key],
        [label]: newValue.value,
        prefix: label?.slice(0, label?.indexOf('-')),
      },
    });
    if (label?.search('SPR_') > 0) {
      setprefixFromSupplier({
        ...prefixFromSupplier,
        [key]: {
          ...[key],
          [label]: newValue.value,
          prefix: label?.slice(0, label?.indexOf('-')),
        },
      });
    }
  };
  const handleRecordSelectChange = (
    label: any,
    e: React.ChangeEvent<HTMLInputElement>,
    newValue: any,
    key: any,
  ) => {
    if (label === 'contractid') {
      getSupCustAccDestForContract(newValue);
    } else if (label === 'supplier_no') {
      setValuesToSend({
        ...valuesToSend,
        [label?.name || label]: newValue?.value || label?.newValue,
        cust_no: '',
        acct_no: '',
        destination_no: '',
      });
      setValues({
        ...values,
        [label?.name || label]: newValue || label?.newValue,
        cust_no: '',
        acct_no: '',
        destination_no: '',
      });
    } else if (label === 'reason') {
      setFromToTransIdValues(newValue);
    } else {
      setValues({
        ...values,
        [label?.name || label]: newValue || label?.newValue,
      });
      setValuesToSend({
        ...valuesToSend,
        [label?.name || label]: newValue?.value || label?.newValue,
      });
    }
  };
  // handle input for other steps
  const handleOtherStepsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValuesToSend({
      ...valuesToSend,
      [name]: value,
    });
  };
  // change values on sub products
  const handleInputProductChange = (
    name: any,
    e: React.ChangeEvent<HTMLInputElement>,
    key: any,
  ) => {
    const { value } = e.target;
    setValues({
      ...values,
      [key]: {
        ...values[key],
        [name]: value,
        prefix: name?.slice(0, name?.indexOf('-')),
        contractid: values?.contractid?.value,
        ContractItem: values?.ContractItemNumber?.value,
      },
    });
    setValuesToSend({
      ...valuesToSend,
      [key]: {
        ...valuesToSend[key],
        [name]: value,
        prefix: name?.slice(0, name?.indexOf('-')),
        contractid: values?.contractid?.value,
        ContractItem: values?.ContractItemNumber?.value,
      },
    });
    // initialize prefixed values with prefix
    if (name === 'supplier_no_from') {
      setprefix(key);
      setTimeout(() => {
        changeValueSubProducts(
          {
            [`${key}-supplier_no_from`]: value,
            [`${key}-tank_id_from`]: '',
            [`${key}-prod_id_from`]: '',
            [`${key}-temperature_from`]: '',
            [`${key}-grav_density_from`]: '',
            [`${key}-gross_from`]: '',
            [`${key}-grossBase_from`]: '',
            [`${key}-vcf_from`]: '',
            [`${key}-net_from`]: '',
            [`${key}-netBase_from`]: '',
            [`${key}-product_weight_from`]: '',
            [`${key}-product_weightBase_from`]: '',
            [`${key}-ld_bay_from`]: '',
            [`${key}-ld_ctl_from`]: '',
            [`${key}-ld_mtr_from`]: '',
            [`${key}-ERPHandlingType_from`]: '',
            [`${key}-dutytoparty_from`]: '',
            [`${key}-ticket_no_from`]: '',
            [`${key}-prod_type_from`]: '',
            [`${key}-tank_uom_from`]: '',
            prefix: key,
          },
          key,
          name,
          '',
        );
      }, 2000);
    } else if (name?.search('_from') !== -1) {
      setprefix(key);
      setprefixFromSupplier({
        ...prefixFromSupplier,
        [`${key}-${[name]}`]: value,
      });
      setTimeout(() => {
        changeValueSubProducts(
          {
            ...prefixFromSupplier,
            [`${key}-${[name]}`]: value,
          },
          key,
          name,
          '',
        );
      }, 2000);
    } else {
      setprefix(key);
      setprefixFromSupplier({
        ...prefixFromSupplier,
        [`${key}-${[name]}`]: value,
      });
      setTimeout(() => {
        changeValueSubProducts(
          {
            ...prefixToSupplier,
            [`${key}-${[name]}`]: value,
          },
          key,
          name,
          `[${JSON.stringify(valuesToSend?.[products?.[0]?.id])}]`,
        );
      }, 2000);
    }
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getScreensList());
    getTransactionTemplate();
  }, []);
  return loading ? (
    <div
      style={{
        width: '100%',
        height: '90vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress />
    </div>
  ) : (
    <>
      <TmsHeader />
      <div className="sub-nav-container">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h3 id="pagetitlebar_title" className="page-title">
            Bulk Transfer - Tanks
          </h3>
        </div>
        <NonBulkTransactionMenu
          onOpen={onOpen}
          setVisible={setVisible}
          visible={visible}
          visibleCount={[
            { step: 1, count: 7 },
            { step: 2, count: 7 },
            { step: 3, count: 7 },
            { step: 4, count: 17 },
          ]}
        />
      </div>
      <BulkTransferTankVerticalStepper
        data={contractTransactionStructure}
        onOpenSubmit={onOpenSubmit}
        values={values}
        valuesToSend={valuesToSend}
        handleSelectChange={handleSelectChange}
        handleInputChange={handleInputChange}
        handleInputProductChange={handleInputProductChange}
        handleOtherStepsChange={handleOtherStepsChange}
        isBulkTransaction
        visible={visible}
        currentSchema={currentSchema}
        stepperTitles={[
          'Transport Information',
          'Transfer From Information',
          'Transfer To Information',
        ]}
        screenID="WORKFLOW-041"
        prefixToSupplier={prefixToSupplier}
        prefixFromSupplier={prefixFromSupplier}
        resetPrefixProducts={resetPrefixProducts}
        prefix={prefix}
        handleRecordSelectChange={handleRecordSelectChange}
        products={products}
        supplierTo={supplierTo}
        addProducts={addProducts}
        addSupplierTo={addSupplierTo}
        removeFormFields={removeFormFields}
        removeToFields={removeToFields}
        SupplierfromStepReviewState={[
          { id: 'supplier_no_from', label: 'Supplier' },
          { id: 'tank_id_from', label: 'Tank' },
          { id: 'prod_id_from', label: 'Product' },
          { id: 'gross_from', label: 'Gross' },
          { id: 'net_from', label: 'Net' },
          { id: 'product_weight_from', label: 'Wheight' },
          { id: 'temperature_from', label: 'Temperature' },
          { id: 'grav_density_from', label: 'Gravity / Density' },
        ]}
        SupplierToStepReviewState={[
          { id: 'supplier_no_to', label: 'Supplier' },
          { id: 'tank_id_to', label: 'Tank' },
          { id: 'prod_id_to', label: 'Product' },
          { id: 'gross_to', label: 'Gross' },
          { id: 'net_to', label: 'Net' },
          { id: 'product_weight_to', label: 'Wheight' },
          { id: 'temperature_to', label: 'Temperature' },
          { id: 'grav_density_to', label: 'Gravity / Density' },
        ]}
        onCloseSubmit={onCloseSubmit}
        openSubmit={openSubmit}
        startNewTransaction={startNewTransaction}
        contractidOptions={contractidOptions}
        transactionMode="nonbulk"
      />
      <TransactionTemplate
        onClose={onClose}
        open={openTemplate}
        templateList={templateList}
        handleTemplate={handleTemplate}
        templateValue={templateValue}
        getTransactionTemplate={getTransactionTemplateByValue}
        deleteContractTransactionTemplate={deleteContractTransactionTemplate}
        saveContractTransactionTemplate={saveContractTransactionTemplate}
      />
    </>
  );
};

export default BulkTransferTank;
