/* eslint-disable react/no-array-index-key */
/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
import React from 'react';

// Librairies
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import clsx from 'clsx';

// Components
import { ArrowDropDown, ArrowDropUp, UnfoldMore } from '@mui/icons-material';
import Pagination from '../Pagination';

// Styles
import { useStyles } from './style';

interface IProps {
  handleClickRow: (obj: any) => void;
  data?: any[];
  headerItems: any[];
  recordsNumber?: number;
  totalRecords?: number;
  currentPage?: number;
  handlePaginationChange?: (event: any, targetPage: any) => void;
  sortTable?: (key: string) => void;
  orderBy?: string;
  order?: string;
  visibleColumns?: { [key: string]: string };
  showPagination?: boolean;
  showVisibleEntries?: boolean;
}

const sortedTable = (data: any[], orderBy?: string, order?: string) => {
  if (!orderBy || !order) return data;
  return data.sort((a, b) => {
    const orderResult: number = String(a[orderBy]).localeCompare(b[orderBy]);
    return order === 'asc' ? orderResult : -orderResult;
  });
};

const VehiclesInFacilityTable: React.FC<IProps> = ({
  handleClickRow,
  headerItems,
  data = [],
  recordsNumber,
  totalRecords,
  currentPage,
  handlePaginationChange,
  sortTable,
  orderBy,
  order,
  visibleColumns,
  showPagination = true,
  showVisibleEntries = true,
}) => {
  const classes = useStyles();

  const handleSortClick = (key: string) => (event: React.MouseEvent<unknown>) => {
    if (sortTable) {
      sortTable(key);
    }
  };

  return (
    <div className={clsx('takeTheRest', classes.root)}>
      <div className={classes.tableWrapper}>
        <Table classes={{ root: classes.tableRoot }}>
          <TableHead>
            <TableRow classes={{ root: classes.headerRow }}>
              {headerItems.map((h) => (
                <TableCell classes={{ root: classes.headerCell }} key={h.key}>
                  <TableSortLabel
                    active={orderBy === h.key}
                    onClick={handleSortClick(h.key)}
                    IconComponent={() => (order === 'asc' && orderBy === h.key ? (
                      <ArrowDropUp />
                    ) : order === 'desc' && orderBy === h.key ? (
                      <ArrowDropDown />
                    ) : (
                      <UnfoldMore />
                    ))}
                    classes={{ root: classes.sortLabel }}
                  >
                    {h.label === 'button' ? '' : h.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!data.length && (
              <TableRow className={classes.tableRow}>
                <TableCell
                  classes={{ root: classes.bodyCellEmpty }}
                  style={{ textAlign: 'center' }}
                  colSpan={headerItems.length}
                >
                  No matching records found
                </TableCell>
              </TableRow>
            )}
            {sortedTable(data, orderBy, order)?.map((row, index) => (
              <TableRow
                onClick={() => handleClickRow({
                  termID: row.term_id.trim(),
                  folioMonth: row.fol_mo,
                  folioNumber: row.fol_no,
                  folioStatus: row.tt_status,
                })}
                className={classes.tableRow}
                key={index}
              >
                {headerItems.map((field) => (
                  <TableCell
                    classes={{ root: classes.bodyCell }}
                    key={field.key}
                  >
                    {row[field.key]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <div className={clsx(classes.footerWrapper, 'flexRowAlignCenter')}>
        {showVisibleEntries === true
        && (<div className={classes.showingText}>Showing 1 to 14 of 14 entries</div>)}
        <span className="takeTheRest" />
        {showPagination === true && (
        <Pagination
          count={Math.ceil((totalRecords || 0) / (recordsNumber || 1))}
          onChange={handlePaginationChange}
          pageNumber={currentPage}
        />
        )}
      </div>
    </div>
  );
};

export default React.memo(VehiclesInFacilityTable);
