import {
  LOAD_CONDITION_SET,
  CLEAR_CONDITION_SET,
  LOAD_CONDITION_SET_RECORD,
  CLEAR_CONDITION_SET_RECORD,
  SET_CONDITION_SET_FORM_HEADER,
  CLEAR_CONDITION_SET_FORM_HEADER,
  SET_CONDITION_SET_RESPONSE_NOTIFICATION,
  CLEAR_CONDITION_SET_RESPONSE_NOTIFICATION,
} from '../../constants/workflows';

const INITIAL_STATE = {
  data: [],
  headerList: {},
  hiddenColumns: {},
  visibleColumns: {},
  currentRecord: {},
  formHeader: {},
  responseNotification: {},
};

const conditionSetReducer = (state = INITIAL_STATE, { type, payload }: any) => {
  switch (type) {
    case LOAD_CONDITION_SET: {
      return {
        ...state,
        data: payload.data,
        headerList: payload.headerList,
        hiddenColumns: payload.hiddenColumns,
        visibleColumns: payload.visibleColumns,
      };
    }
    case CLEAR_CONDITION_SET: {
      return {
        ...state,
        data: [],
        headerList: {},
      };
    }
    case LOAD_CONDITION_SET_RECORD: {
      return {
        ...state,
        currentRecord: payload,
      };
    }
    case CLEAR_CONDITION_SET_RECORD: {
      return {
        ...state,
        currentRecord: {},
      };
    }
    case SET_CONDITION_SET_RESPONSE_NOTIFICATION: {
      return {
        ...state,
        responseNotification: payload,
      };
    }
    case CLEAR_CONDITION_SET_RESPONSE_NOTIFICATION: {
      return {
        ...state,
        responseNotification: {},
      };
    }
    case SET_CONDITION_SET_FORM_HEADER: {
      return {
        ...state,
        formHeader: {
          ...state.formHeader,
          ...payload,
        },
      };
    }
    case CLEAR_CONDITION_SET_FORM_HEADER: {
      return {
        ...state,
        formHeader: {},
      };
    }
    default:
      return state;
  }
};

export default conditionSetReducer;
