/* eslint-disable react/no-array-index-key */
/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
import React from 'react';

// Librairies
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import clsx from 'clsx';

// Components
import { ArrowDropDown, ArrowDropUp, UnfoldMore } from '@mui/icons-material';
import Pagination from '../Pagination';

// Styles
import { useStyles } from './style';

// Utils
import { sortedTable } from '../../utils';
import TableItemsText from '../TableItemsText';

interface IProps {
  handleClickRow: (row: any) => void;
  data?: any[];
  headerItems: any[];
  recordsNumber?: number;
  totalRecords?: number;
  currentPage?: number;
  handlePaginationChange?: (event: any, targetPage: any) => void;
  sortTable?: (key: string) => void;
  orderBy?: string;
  order?: string;
  searchText?: string;
  visibleColumns: { [key: string]: string };
  columnsLabels: { [key: string]: string };
  recordsToShow: number;
  searchedDataCount: number;
}

const MeterEntryTable: React.FC<IProps> = ({
  handleClickRow,
  headerItems,
  data = [],
  recordsNumber,
  totalRecords,
  currentPage,
  handlePaginationChange,
  sortTable,
  orderBy,
  order,
  visibleColumns,
  columnsLabels,
  searchText,
  recordsToShow,
  searchedDataCount,
}) => {
  const classes = useStyles();
  const countPages = Math.ceil(
    (searchText ? searchedDataCount : (totalRecords || 0)) / recordsToShow,
  );

  const handleSortClick = (key: string) => (event: React.MouseEvent<unknown>) => {
    if (sortTable) {
      sortTable(key);
    }
  };

  return (
    <div className={clsx('takeTheRest', classes.root)}>
      <div className={classes.tableWrapper}>
        <Table classes={{ root: classes.tableRoot }}>
          <TableHead>
            <TableRow classes={{ root: classes.headerRow }}>
              {Object.keys(visibleColumns).map((key) => (
                <TableCell classes={{ root: classes.headerCell }} key={key}>
                  <TableSortLabel
                    active={orderBy === key}
                    onClick={handleSortClick(key)}
                    IconComponent={() => (order === 'asc' && orderBy === key ? (
                      <ArrowDropUp />
                    ) : order === 'desc' && orderBy === key ? (
                      <ArrowDropDown />
                    ) : (
                      <UnfoldMore />
                    ))}
                    classes={{ root: classes.sortLabel }}
                  >
                    {columnsLabels[key]}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!data.length && (
              <TableRow className={classes.tableRow}>
                <TableCell
                  classes={{ root: classes.bodyCellEmpty }}
                  style={{ textAlign: 'center' }}
                  colSpan={headerItems.length}
                >
                  No matching records found
                </TableCell>
              </TableRow>
            )}
            {sortedTable(data, orderBy, order)?.map((row, index) => (
              <TableRow
                onClick={() => handleClickRow(row)}
                className={classes.tableRow}
                key={index}
              >
                {Object.keys(visibleColumns).map((field) => (
                  <TableCell
                    classes={{ root: classes.bodyCell }}
                    key={field}
                  >
                    {row[field]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <div className={clsx(classes.footerWrapper, 'flexRowAlignCenter')}>
        <TableItemsText
          allDataCount={(totalRecords as number)}
          countPages={countPages}
          currentPage={(currentPage as number)}
          recordsToShow={recordsToShow}
          searchText={searchText}
          searchedDataCount={searchedDataCount}
        />
        <span className="takeTheRest" />
        <Pagination
          count={countPages}
          onChange={handlePaginationChange}
          pageNumber={currentPage}
        />
      </div>
    </div>
  );
};

export default React.memo(MeterEntryTable);
