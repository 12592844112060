/* eslint-disable jsx-a11y/no-static-element-interactions */
import clsx from 'clsx';
import React, { useRef, useState } from 'react';
import ResizableDiv from './resizableDiv';
import { useStyles } from './style';

interface DivProps {
  x: number;
  y: number;
  w: number;
  h: number;
}

interface DraggableDivProps {
    key: string;
    parentRef: React.RefObject<HTMLDivElement>;
    elementId: number;
    dimensions: DivProps;
    minWidth: string;
    minHeight: string;
    handleUpdateElement: (id: number, properties: DivProps) => void;
}

const DraggableDiv: React.FC<DraggableDivProps> = ({
  key,
  parentRef,
  elementId,
  dimensions,
  minWidth,
  minHeight,
  handleUpdateElement,
}) => {
  const classes = useStyles();
  const divRef = React.useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState<any>({
    x: 0, y: 0,
  });
  const [size, setSize] = useState<any>({
    w: 200, h: 200,
  });

  const [isResizing, setIsResizing] = useState<boolean>(false);
  const [isDragging, setIsDragging] = useState<boolean>(false);

  // Limit the coordinates to the parent boundaries
  const getInboundX = (x: any, parentRect: any, event: any) => {
    let newX = x;
    if (newX < 0) newX = 0;
    if (newX > parentRect.width - event.target.offsetWidth) {
      newX = parentRect.width - event.target.offsetWidth;
    }
    return newX || 0;
  };
  const getInboundY = (y: any, parentRect: any, event: any) => {
    let newY = y;
    if (newY < 0) newY = 0;
    if (newY > parentRect.height - event.target.offsetHeight) {
      newY = parentRect.height - event.target.offsetHeight;
    }
    return newY || 0;
  };
  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    setIsDragging(true);
    event.preventDefault();

    const parentRect = parentRef.current?.getBoundingClientRect();
    if (!parentRect || isResizing) return;

    let startX = event.clientX;
    let startY = event.clientY;

    const handleMouseMove = (event1: MouseEvent) => {
      const diffX = event1.clientX - startX;
      const diffY = event1.clientY - startY;

      setPosition((prev: any) => ({
        ...prev,
        x: getInboundX(prev.x + diffX, parentRect, event),
        y: getInboundY(prev.y + diffY, parentRect, event),
      }));

      startX = event1.clientX;
      startY = event1.clientY;
    };

    const handleMouseUp = () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
      setIsDragging(false);
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
  };

  React.useEffect(() => {
    // TODO: persist the boxes dimensions on the detail screen
    // if (position && size && elementId) {
    //   handleUpdateElement(elementId, {
    //     x: position.x,
    //     y: position.y,
    //     w: size.w,
    //     h: size.h,
    //   });
    // }
  }, [elementId, handleUpdateElement, position, size]);

  return (
    <ResizableDiv
      key={key}
      ref={divRef}
      onMouseDown={handleMouseDown}
      parentRect={{
        height: parentRef.current?.offsetHeight || 0,
        width: parentRef.current?.offsetWidth || 0,
      }}
      isDragging={isDragging}
      handleSetIsResizing={setIsResizing}
      xCoord={position.x}
      yCoord={position.y}
      widthSize={size.w}
      heightSize={size.h}
      handleSetPosition={setPosition}
      handleSetSize={setSize}
      minWidth={minWidth}
      minHeight={minHeight}
    />
  );
};

export default DraggableDiv;
