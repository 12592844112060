import {
  LOAD_SYSTEM_UTILITY_FIELDS,
  LOAD_UTILITY_SCREEN,
  GET_UTILITY_CUSTOMERS,
  GET_UTILITY_FOLIOMONTH,
  GET_UTILITY_FOLIONUMBER,
  GET_UTILITY_PRODUCTS,
  GENERATE_UTILITY_RESPONSE,
  SET_UTILITY_FORM_EDIT_STATE,
} from '../../constants/workflows';

const INITIAL_STATE = {
  fields: [],
  savedResponse: {},
  reportId: '',
  heading: '',
  progName: '',
  IsReportEdit: false,
};

const reports = (
  state = INITIAL_STATE,
  { type, payload }: any,
) => {
  switch (type) {
    case LOAD_SYSTEM_UTILITY_FIELDS: {
      return {
        ...state,
        fields: payload,
      };
    }
    case GET_UTILITY_CUSTOMERS: {
      const data = state.fields.map((item :any) => {
        if (item.name === 'rptfield_C') {
          return payload;
        }
        return item;
      });
      return {
        ...state,
        fields: data,
      };
    }
    case GET_UTILITY_FOLIOMONTH: {
      const data = state.fields.map((item :any) => {
        if (item.name === 'rptfield_F_month_F'
        || item.name === 'rptfield_F_month_j'
        || item.name === 'rptfield_F_month_i') {
          return {
            ...item,
            options: payload.options,
            defaultValue: payload.defaultValue,
            key: payload.key,
          };
        }
        return item;
      });
      return {
        ...state,
        fields: data,
      };
    }
    case GET_UTILITY_FOLIONUMBER: {
      const data = state.fields.map((item :any) => {
        if (item.name === 'rptfield_F_num_F'
        || item.name === 'rptfield_F_num_j'
        || item.name === 'rptfield_F_num_i') {
          return {
            ...item,
            options: payload.options,
            defaultValue: payload.defaultValue,
            key: payload.key,
          };
        }
        return item;
      });
      return {
        ...state,
        fields: data,
      };
    }
    case GENERATE_UTILITY_RESPONSE: {
      return {
        ...state,
        savedResponse: payload,
      };
    }
    case LOAD_UTILITY_SCREEN: {
      return {
        ...state,
        reportId: payload?.reportId,
        heading: payload?.heading,
        progName: payload?.progName,
      };
    }
    case GET_UTILITY_PRODUCTS: {
      const data = state.fields.map((item :any) => {
        if (item.name === 'rptfield_p') {
          return payload;
        }
        return item;
      });
      return {
        ...state,
        fields: data,
      };
    }
    case SET_UTILITY_FORM_EDIT_STATE: {
      return {
        ...state,
        IsReportEdit: payload,
      };
    }
    default:
      return state;
  }
};

export default reports;
