/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useState } from 'react';

// Librairies
import {
  ButtonBase,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { Settings } from '@mui/icons-material';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';

// Styles
import { useStyles } from './style';

// Utils
import {
  getFormattedTime,
  getPGCRFormattedDate,
  getTankGaugeEntryServerDate,
  getTankGaugeFullDate,
  searchTankGaugeRecords,
} from '../../utils';
import DateTimePicker from '../DateTimePicker';
import Input from '../Input';
import Select from '../Select';
import {
  getTableFreshData,
  setFormData,
} from '../../redux/actions/workflows/tankGaugeEntry';
import MaskInput from '../Input/MaksInput';

const extraFieldsIds = ['bottoms', 'line_fill1', 'line_fill2', 'line_fill3'];

interface IProps {
  data: any;
  searchText: string;
  handleOpenModalData: (row: any, index: number) => void;
  showFields: boolean;
}

const TankGaugeEntryRecordDetailTable: React.FC<IProps> = ({
  data,
  searchText,
  handleOpenModalData,
  showFields,
}) => {
  const classes = useStyles();
  const [tableData, setTableData] = useState(data?.data || []);

  useEffect(() => {
    if (searchText) {
      const filtredRecords = searchTankGaugeRecords(
        searchText,
        data?.data,
        data?.fieldList,
      );
      setTableData(filtredRecords);
    } else {
      setTableData(data?.data);
    }
  }, [data, searchText]);

  return (
    <div className={clsx('takeTheRest', classes.root)}>
      <Table classes={{ root: classes.tableRoot }}>
        <TableHead>
          <TableRow classes={{ root: classes.headerRow }}>
            <TableCell classes={{ root: classes.headerCell }} />
            {data?.fieldList?.map((header: any) => (extraFieldsIds.includes(header.id) && !showFields ? null : (
              <TableCell
                classes={{ root: classes.headerCell }}
                key={header.id}
                style={{
                  backgroundColor: extraFieldsIds.includes(header.id)
                    ? '#c4deff'
                    : undefined,
                }}
              >
                {header.header === 'buttons' ? '' : header.header}
              </TableCell>
            )))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData?.map((row: any, index: number) => (
            <TableRow className={classes.tableRow} key={index.toFixed()}>
              <TableCell classes={{ root: classes.bodyCell }}>
                <div className={classes.actionsWrapper}>
                  <ButtonBase
                    className={classes.actionButton}
                    onClick={() => handleOpenModalData(row, row.index)}
                  >
                    <Settings fontSize="small" />
                  </ButtonBase>
                </div>
              </TableCell>
              {data?.fieldList?.map((innerField: any, i: number) => (extraFieldsIds.includes(innerField.id) && !showFields ? null : (
                <TankGaugeEntryTableCell
                  field={row[`${innerField.id}-${row.index}`]}
                  fieldId={innerField.id}
                  row={row}
                  key={i.toFixed()}
                  index={row.index}
                />
              )))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

interface ITankGaugeEntryTableCell {
  field: any;
  row: any;
  index: number;
  fieldId: string;
}

const TankGaugeEntryTableCell: React.FC<ITankGaugeEntryTableCell> = ({
  field,
  row,
  index,
  fieldId,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [textInputValue, setTextInputValue] = useState('');
  const [selectValue, setSelectValue] = useState<any>(null);
  const [dateInputValue, setDateInputValue] = useState(() => ({
    inputValue: '',
    pickerValue: new Date(),
  }));
  const { formHeader, selectedRecord, formData } = useSelector(
    (state: any) => state.Workflows.TankGaugeEntry,
  );
  const isUpdateDisabled = selectedRecord?.folioStatus === 'C' || selectedRecord?.folioStatus === 'M';

  useEffect(() => {
    if (
      formHeader?.head_inv_date
      && formHeader?.change
      && field?.['\u0000*\u0000parm']?.date
    ) {
      setDateInputValue({
        inputValue: formHeader?.head_inv_date,
        pickerValue: new Date(formHeader?.head_inv_date),
      });
    }
    if (
      formHeader?.head_ambient_temperature
      && formHeader?.change
      && fieldId === 'ambient_temperature'
    ) {
      setTextInputValue(formHeader?.head_ambient_temperature);
    }
  }, [formHeader?.head_inv_date, formHeader?.head_ambient_temperature]);

  useEffect(() => {
    if (formData?.[index]?.[`${fieldId}-${index}`]) {
      if (formData?.[index]?.[`${fieldId}-${index}`]?.['\u0000*\u0000parm']) {
        setTextInputValue(
          formData?.[index]?.[`${fieldId}-${index}`]?.['\u0000*\u0000parm']
            ?.value,
        );
      } else if (formData?.[index]?.[`${fieldId}-${index}`]?.value) {
        setTextInputValue(formData?.[index]?.[`${fieldId}-${index}`]?.value);
      } else {
        setTextInputValue(formData?.[index]?.[`${fieldId}-${index}`]);
      }
    }
  }, [formData[index]]);

  useEffect(() => {
    if (field) {
      if (field?.['\u0000*\u0000parm']) {
        if (fieldId === 'inv_status') {
          const options = [
            { label: 'Physical', value: 'P' },
            { label: 'Memo', value: 'M' },
          ];
          const selectedValue = options.find(
            (option) => option.value === field?.['\u0000*\u0000parm']?.value,
          );
          setSelectValue(selectedValue);
        }
        if (field?.['\u0000*\u0000parm']?.date) {
          const date = getTankGaugeFullDate(
            field?.['\u0000*\u0000parm']?.date,
            field?.['\u0000*\u0000parm']?.time,
          );
          setDateInputValue({
            inputValue: date,
            pickerValue: new Date(date),
          });
        } else {
          setTextInputValue(field?.['\u0000*\u0000parm']?.value);
        }
      } else {
        setTextInputValue(field?.value);
      }
    }
  }, []);

  const handleTextInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setTextInputValue(event.target.value);
  };

  const handleTextInputBlur = () => {
    dispatch(getTableFreshData(row, textInputValue, fieldId, index));
  };

  const handleSelectChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    option: any,
  ) => {
    setSelectValue(option);
    dispatch(setFormData(row, option.value, fieldId, index));
  };

  const handleDateTimePckerChange = (value: Date | null) => {
    if (value) {
      const formattedDate = getPGCRFormattedDate(value);
      const formattedTime = getFormattedTime(value);
      if (formattedDate && formattedTime) {
        setDateInputValue({
          inputValue: `${formattedDate} ${formattedTime}`,
          pickerValue: value,
        });
        dispatch(
          setFormData(
            row,
            getTankGaugeEntryServerDate(`${formattedDate} ${formattedTime}`),
            fieldId,
            index,
          ),
        );
      }
    }
  };

  const getFieldValue = () => {
    if (field?.['\u0000*\u0000parm']) {
      if (fieldId === 'inv_status') {
        const options = [
          { label: 'Physical', value: 'P' },
          { label: 'Memo', value: 'M' },
        ];
        return (
          <Select
            options={options}
            value={selectValue}
            noBorder
            onChange={handleSelectChange}
            disabled={isUpdateDisabled}
          />
        );
      }
      if (field?.['\u0000*\u0000parm']?.date) {
        return (
          <DateTimePicker
            handleChange={handleDateTimePckerChange}
            inputValue={dateInputValue.inputValue}
            pickerValue={dateInputValue.pickerValue}
            noBorder
            inputStyle={{ width: 125 }}
            disabled={isUpdateDisabled}
            shouldDisableTime
          />
        );
      }
      switch (fieldId) {
        case 'prod_level':
        case 'water_level': {
          return (
            <MaskInput
              onBlur={handleTextInputBlur}
              onChange={handleTextInputChange}
              value={textInputValue}
              noBorder
              disabled={isUpdateDisabled}
              style={{ minWidth: 80 }}
              mask={field?.['\u0000*\u0000parm']?.['data-mask']}
            />
          );
        }
        default: return (
          <Input
            onBlur={handleTextInputBlur}
            onChange={handleTextInputChange}
            value={textInputValue}
            noBorder
            disabled={isUpdateDisabled}
            type={fieldId === 'grav_density' ? 'number' : 'text'}
            style={{ minWidth: 80 }}
            error={fieldId === 'grav_density' && Number(textInputValue) > 85}
          />
        );
      }
    }
    switch (fieldId) {
      case 'prod_id': {
        return <span className={classes.smartLink}>{textInputValue}</span>;
      }
      case 'tank_id': {
        return <span className={classes.smartLink}>{field?.label}</span>;
      }
      case 'tank_uom':
      case 'bottoms': {
        return textInputValue;
      }
      default: {
        return (
          <Input
            onBlur={handleTextInputBlur}
            onChange={handleTextInputChange}
            value={textInputValue}
            noBorder
            disabled={isUpdateDisabled}
            style={{ minWidth: 80 }}
            error={fieldId === 'grav_density' && Number(textInputValue) > 85}
          />
        );
      }
    }
  };

  return (
    <TableCell
      classes={{ root: classes.bodyCell }}
      style={{ backgroundColor: formData[index] ? '#f4fadb' : undefined }}
    >
      {getFieldValue()}
    </TableCell>
  );
};

export default React.memo(TankGaugeEntryRecordDetailTable);
