/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
import React, {
  useState, useEffect,
} from 'react';
import {
  Alert,
  Snackbar,
  CircularProgress,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useStyles } from '../../../components/TileLayout/style';
import TmsHeader from '../../../components/header';
import OptionsButton from '../../../components/optionsButton';
import { setDefaultTileLayout, getDefaultTileLayout, dismissedAlert } from '../../../redux/actions/workflows/tileLayout';
import ConfirmationModal from '../../../components/confirmationModal';

const TileLayout = () => {
  const dispatch = useDispatch();
  const {
    data,
    openConfirmationAlert,
    confirmationAlert,
    loading,
    alertStatus,
  } = useSelector((state: any) => state.Workflows.TileLayoutReducer);

  const classes = useStyles();
  const [checkNavigation, setCheckNavigation] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [isDrawerOpen, setisDrawerOpen] = useState(false);
  const [layoutType, setLayoutType] = useState('B');
  const [showMenus, setShowMenus] = useState(false);
  const [options, setOptions] = useState(false);
  const [countPopup, setCountPopup] = useState<boolean>(false);
  const [scaleMenu, setScaleMenu] = useState<boolean>(false);
  const [scaleSize, setScaleSize] = useState<number>(1);
  const [iframesURL, SetiFramesURL] = useState<any>([]);
  const [nextScreen, setNextScreen] = useState<any>({});
  const [userDefault, setUserDefault] = useState<boolean>(false);

  const handleCloseConfirm = () => {
    setConfirmModal(false);
    setCheckNavigation(false);
  };

  const mouseLeaving = () => {
    setTimeout(() => {
      setShowMenus(false);
    }, 2000);
  };

  const changeLayout = (layout: string) => () => {
    setCountPopup(false);
    setLayoutType(layout);
  };

  const getStyleByScale = (scale: number) => ({
    transform: `scale(${scale})`,
    width: `${100 / scale}%`,
    height: `${100 / scale}%`,
    transformOrigin: 'top left',
  });

  const iFrameURLHandler = (index: number) => {
    if (iframesURL[index]) {
      if (iframesURL[index].includes('MAINT')) {
        return `/?url=${iframesURL[index]}&ismaint=${true}`;
      }
      return iframesURL[index];
    }
    return '/';
  };

  useEffect(() => {
    dispatch(getDefaultTileLayout());
    SetiFramesURL([
      '/workflow/rack-status',
      '/workflow/rcu-ditto',
      '/workflow/tank-status',
      '/workflow/drivers-in-facility',
      '/',
    ]);
  }, []);

  useEffect(() => {
    if (data?.parm?.layout?.length) {
      setLayoutType(data?.parm?.layout || 'B');
      setScaleSize(data?.parm?.scale || 1);
      SetiFramesURL(data?.parm?.frames);
    }
  }, [data]);

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    addEventListener('message', (e) => {
      setCountPopup(e.data.countPopup);
      if (e.data.target) {
        const allIframes = document.querySelectorAll('iframe');
        // eslint-disable-next-line radix
        allIframes[parseInt(e.data.target)].src = `/?url=${e.data?.link}&ismaint=${true}`;
        // e.data?.link
      }
    });
    return () => {
      // eslint-disable-next-line no-restricted-globals, @typescript-eslint/no-empty-function
      removeEventListener('message', () => { });
    };
  }, [countPopup]);

  const setDefaultLayoutHandler = async () => {
    setOptions(false);

    const iFrameSource: any[] = [];
    document.querySelectorAll('iframe').forEach((iframe: any) => {
      iFrameSource.push(iframe.contentWindow.location.pathname);
    });
    await dispatch(setDefaultTileLayout(layoutType, scaleSize, iFrameSource));
    setUserDefault(true);
  };

  useEffect(() => {
    if (userDefault) {
      dispatch(getDefaultTileLayout());
      setUserDefault(false);
    }
  }, [userDefault]);

  const handleClose = () => {
    dispatch(dismissedAlert());
  };

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    addEventListener('message', (e) => {
      if (e.data.isModifiy) {
        setConfirmModal(true);
      }
    });

    // eslint-disable-next-line no-restricted-globals, @typescript-eslint/no-empty-function
    return () => { removeEventListener('message', () => { }); };
  }, [confirmModal]);

  return loading ? (
    <div
      style={{
        width: '100%',
        height: '90vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress />
    </div>
  ) : (
    <>
      <div className={classes.subNavContainer} onMouseLeave={mouseLeaving}>
        {showMenus
          ? (
            <TmsHeader
              setShowMenus={setShowMenus}
              confirmModal={confirmModal}
              setConfirmModal={setConfirmModal}
              checkNavigation={checkNavigation}
              setCheckNavigation={setCheckNavigation}
              nextScreen={nextScreen}
              setNextScreen={setNextScreen}
            />
          )
          : (
            <>
              <p className={classes.titleText}>
                Tile Layout
                {' '}
                <span className={classes.layoutName}>{`Layout ${layoutType}`}</span>
              </p>
              <p className={classes.triggerButton} onMouseEnter={() => setShowMenus(true)}>Main Menu</p>
              <div style={{ display: 'flex' }}>
                <div className={classes.navDropdown} onClick={() => { setScaleMenu(!scaleMenu); setisDrawerOpen(false); setOptions(false); }}>
                  <Typography fontSize={10}>Scale</Typography>
                  <ArrowDropDownIcon />
                </div>
                <div className={classes.navDropdown} onClick={() => { setisDrawerOpen(!isDrawerOpen); setOptions(false); setScaleMenu(false); }}>
                  <Typography fontSize={10}>Layout</Typography>
                  <ArrowDropDownIcon />
                </div>
                <div className={classes.navDropdown} style={{ position: 'relative' }}>
                  <OptionsButton handleClick={() => { setOptions(!options); setisDrawerOpen(false); }} />
                  <ul className={classes.optionUi} style={options ? { display: 'block' } : { display: 'none' }} onMouseLeave={() => setOptions(false)}>
                    <li className={classes.optionLi} onClick={setDefaultLayoutHandler}>Set Layout As Default</li>
                  </ul>
                </div>
                {scaleMenu && (
                  <div onMouseLeave={() => setScaleMenu(false)}>
                    <ul className="dropdown-menu pull-right" role="menu">
                      <li onClick={() => setScaleSize(0.25)}>
                        <span style={{ cursor: 'pointer' }} className={scaleSize.toString() === '0.25' ? `${classes.activeScale}` : ''}>25.0</span>
                      </li>
                      <li onClick={() => setScaleSize(0.33)}>
                        <span style={{ cursor: 'pointer' }} className={scaleSize.toString() === '0.33' ? `${classes.activeScale}` : ''}>33.3</span>
                      </li>
                      <li onClick={() => setScaleSize(0.5)}>
                        <span style={{ cursor: 'pointer' }} className={scaleSize.toString() === '0.5' ? `${classes.activeScale}` : ''}>50.0</span>
                      </li>
                      <li onClick={() => setScaleSize(0.66)}>
                        <span style={{ cursor: 'pointer' }} className={scaleSize.toString() === '0.66' ? `${classes.activeScale}` : ''}>66.6</span>
                      </li>
                      <li onClick={() => setScaleSize(0.75)}>
                        <span style={{ cursor: 'pointer' }} className={scaleSize.toString() === '0.75' ? `${classes.activeScale}` : ''}>75.0</span>
                      </li>
                      <li onClick={() => setScaleSize(1)}>
                        <span style={{ cursor: 'pointer' }} className={scaleSize.toString() === '1' ? `${classes.activeScale}` : ''}>100.0</span>
                      </li>
                      <li onClick={() => setScaleSize(1.25)}>
                        <span style={{ cursor: 'pointer' }} className={scaleSize.toString() === '1.25' ? `${classes.activeScale}` : ''}>125.0</span>
                      </li>
                      <li onClick={() => setScaleSize(1.5)}>
                        <span style={{ cursor: 'pointer' }} className={scaleSize.toString() === '1.5' ? `${classes.activeScale}` : ''}>150.0</span>
                      </li>
                    </ul>
                  </div>
                )}
                {isDrawerOpen && (
                  <div className={classes.layoutDrawerContainer} onMouseLeave={() => setisDrawerOpen(false)}>
                    <div className={layoutType === 'A' ? `${classes.layoutSection} ${classes.activeLayout}` : classes.layoutSection} style={{ borderBottom: '2px solid #cdc7c7', marginTop: '2px' }} onClick={changeLayout('A')}>
                      <div style={{ display: 'flex' }}>
                        <span className={classes.layoutA} />
                        <span className={classes.layoutA} />
                      </div>
                      <div style={{ display: 'flex' }}>
                        <span className={classes.layoutA} />
                        <span className={classes.layoutA} />
                      </div>
                    </div>
                    <div className={layoutType === 'B' ? `${classes.layoutSection} ${classes.activeLayout}` : classes.layoutSection} style={{ borderBottom: '2px solid #cdc7c7', marginTop: '2px' }} onClick={changeLayout('B')}>
                      <div style={{ display: 'flex' }}>
                        <span className={classes.layoutB1} />
                        <span className={classes.layoutB1} />
                        <span className={classes.layoutB1} />
                      </div>
                      <div style={{ display: 'flex' }}>
                        <span className={classes.layoutB1} />
                        <span className={classes.layoutB2} />
                      </div>
                    </div>
                    <div className={layoutType === 'C' ? `${classes.layoutSection} ${classes.activeLayout}` : classes.layoutSection} style={{ borderBottom: '2px solid #cdc7c7', marginTop: '2px' }} onClick={changeLayout('C')}>
                      <div style={{ display: 'flex' }}>
                        <span className={classes.layoutC} />
                        <span className={classes.layoutC} />
                      </div>
                    </div>
                    <div className={layoutType === 'D' ? `${classes.layoutSection} ${classes.activeLayout}` : classes.layoutSection} style={{ borderBottom: '2px solid #cdc7c7', marginTop: '2px' }} onClick={changeLayout('D')}>
                      <div style={{ display: 'flex' }}>
                        <span className={classes.layoutD} />
                      </div>
                      <div style={{ display: 'flex' }}>
                        <span className={classes.layoutA} />
                        <span className={classes.layoutA} />
                      </div>
                    </div>
                    <div className={layoutType === 'E' ? `${classes.layoutSection} ${classes.activeLayout}` : classes.layoutSection} style={{ borderBottom: '2px solid #cdc7c7', marginTop: '2px' }} onClick={changeLayout('E')}>
                      <div style={{ display: 'flex' }}>
                        <span className={classes.layoutD} />
                      </div>
                      <div style={{ display: 'flex' }}>
                        <span className={classes.layoutD} />
                      </div>
                    </div>
                    <div className={layoutType === 'F' ? `${classes.layoutSection} ${classes.activeLayout}` : classes.layoutSection} style={{ borderBottom: '2px solid #cdc7c7', marginTop: '2px' }} onClick={changeLayout('F')}>
                      <div style={{ display: 'flex' }}>
                        <span className={classes.layoutE} />
                        <span className={classes.layoutE} />
                        <span className={classes.layoutE} />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}

      </div>
      {
        layoutType === 'A'
        && (
          <>
            <div className={classes.flexContainer}>
              <div className={classes.divBox}>
                <div id="tilebanner" style={countPopup ? { display: 'block' } : { display: 'none' }}>-1-</div>
                <iframe src={iFrameURLHandler(0)} height="100%" width="100%" title="window1" style={getStyleByScale(scaleSize)} />
              </div>
              <div className={classes.divBox}>
                <div id="tilebanner" style={countPopup ? { display: 'block' } : { display: 'none' }}>-2-</div>
                <iframe src={iFrameURLHandler(1)} height="100%" width="100%" title="window2" style={getStyleByScale(scaleSize)} />
              </div>
            </div>
            <div className={classes.flexContainer}>
              <div className={classes.divBox}>
                <div id="tilebanner" style={countPopup ? { display: 'block' } : { display: 'none' }}>-3-</div>
                <iframe src={iFrameURLHandler(2)} height="100%" width="100%" title="window3" style={getStyleByScale(scaleSize)} />
              </div>
              <div className={classes.divBox}>
                <div id="tilebanner" style={countPopup ? { display: 'block' } : { display: 'none' }}>-4-</div>
                <iframe src={iFrameURLHandler(3)} height="100%" width="100%" title="window4" style={getStyleByScale(scaleSize)} />
              </div>
            </div>
          </>
        )
      }
      {
        layoutType === 'B'
        && (
          <>
            <div className={classes.flexContainer}>
              <div className={classes.divBox}>
                <div id="tilebanner" style={countPopup ? { display: 'block' } : { display: 'none' }}>-1-</div>
                <iframe src={iFrameURLHandler(0)} height="100%" title="window1" width="100%" style={getStyleByScale(scaleSize)} />
              </div>
              <div className={classes.divBox}>
                <div id="tilebanner" style={countPopup ? { display: 'block' } : { display: 'none' }}>-2-</div>
                <iframe src={iFrameURLHandler(1)} height="100%" title="window2" width="100%" style={getStyleByScale(scaleSize)} />
              </div>
              <div className={classes.divBox}>
                <div id="tilebanner" style={countPopup ? { display: 'block' } : { display: 'none' }}>-3-</div>
                <iframe src={iFrameURLHandler(2)} height="100%" width="100%" title="window3" style={getStyleByScale(scaleSize)} />
              </div>
            </div>
            <div className={classes.flexContainer}>
              <div className={classes.divBox} style={{ width: '50%' }}>
                <div id="tilebanner" style={countPopup ? { display: 'block' } : { display: 'none' }}>-4-</div>
                <iframe src={iFrameURLHandler(3)} height="100%" width="100%" title="window4" style={getStyleByScale(scaleSize)} />
              </div>
              <div className={classes.divBox}>
                <div id="tilebanner" style={countPopup ? { display: 'block' } : { display: 'none' }}>-5-</div>
                <iframe src={iFrameURLHandler(4)} height="100%" width="100%" title="window5" style={getStyleByScale(scaleSize)} />
              </div>
            </div>
          </>
        )
      }
      {
        layoutType === 'C'
        && (
          <div className={classes.flexContainer}>
            <div className={classes.divBox}>
              <div id="tilebanner" style={countPopup ? { display: 'block' } : { display: 'none' }}>-1-</div>
              <iframe src={iFrameURLHandler(0)} height="100%" title="window1" width="100%" style={getStyleByScale(scaleSize)} />
            </div>
            <div className={classes.divBox}>
              <div id="tilebanner" style={countPopup ? { display: 'block' } : { display: 'none' }}>-2-</div>
              <iframe src={iFrameURLHandler(1)} height="100%" width="100%" title="window2" style={getStyleByScale(scaleSize)} />
            </div>
          </div>
        )
      }
      {
        layoutType === 'D'
        && (
          <>
            <div className={classes.flexContainer}>
              <div className={classes.divBox}>
                <div id="tilebanner" style={countPopup ? { display: 'block' } : { display: 'none' }}>-1-</div>
                <iframe src={iFrameURLHandler(0)} height="100%" title="window1" width="100%" style={getStyleByScale(scaleSize)} />
              </div>
            </div>
            <div className={classes.flexContainer} style={{ overflow: 'hidden' }}>
              <div className={classes.divBox}>
                <div id="tilebanner" style={countPopup ? { display: 'block' } : { display: 'none' }}>-2-</div>
                <iframe src={iFrameURLHandler(1)} height="100%" title="window2" width="100%" style={getStyleByScale(scaleSize)} />
              </div>
              <div className={classes.divBox}>
                <div id="tilebanner" style={countPopup ? { display: 'block' } : { display: 'none' }}>-3-</div>
                <iframe src={iFrameURLHandler(2)} height="100%" title="window3" width="100%" style={getStyleByScale(scaleSize)} />
              </div>
            </div>
          </>
        )
      }
      {
        layoutType === 'E'
        && (
          <>
            <div className={classes.flexContainer}>
              <div className={classes.divBox}>
                <div id="tilebanner" style={countPopup ? { display: 'block' } : { display: 'none' }}>-1-</div>
                <iframe src={iFrameURLHandler(0)} height="100%" title="window1" width="100%" style={getStyleByScale(scaleSize)} />
              </div>
            </div>
            <div className={classes.flexContainer}>
              <div className={classes.divBox}>
                <div id="tilebanner" style={countPopup ? { display: 'block' } : { display: 'none' }}>-1-</div>
                <iframe src={iFrameURLHandler(1)} height="100%" title="window2" width="100%" style={getStyleByScale(scaleSize)} />
              </div>
            </div>
          </>
        )
      }
      {
        layoutType === 'F'
        && (
          <div className={classes.flexContainer}>
            <div className={classes.divBox}>
              <div id="tilebanner" style={countPopup ? { display: 'block' } : { display: 'none' }}>-1-</div>
              <iframe src={iFrameURLHandler(0)} height="100%" title="window1" width="100%" style={getStyleByScale(scaleSize)} />
            </div>
            <div className={classes.divBox}>
              <div id="tilebanner" style={countPopup ? { display: 'block' } : { display: 'none' }}>-2-</div>
              <iframe src={iFrameURLHandler(1)} height="100%" title="window2" width="100%" style={getStyleByScale(scaleSize)} />
            </div>
            <div className={classes.divBox}>
              <div id="tilebanner" style={countPopup ? { display: 'block' } : { display: 'none' }}>-3-</div>
              <iframe src={iFrameURLHandler(2)} height="100%" title="window3" width="100%" style={getStyleByScale(scaleSize)} />
            </div>
          </div>
        )
      }
      <Snackbar
        open={openConfirmationAlert}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={alertStatus}>
          {confirmationAlert}
        </Alert>
      </Snackbar>

      <ConfirmationModal
        title="Leave Page"
        description="You have made changes on this page. If you leave without saving, your changes will be lost. Do you want to continue?"
        Visible={confirmModal && checkNavigation}
        handleCloseConfirm={handleCloseConfirm}
        setCheckNavigation={setCheckNavigation}
        checkNavigation={checkNavigation}
        setConfirmModal={setConfirmModal}
        setShowMenus={setShowMenus}
      />
    </>
  );
};
export default TileLayout;
