/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { CSSProperties } from '@mui/styles';
import React, { Dispatch, SetStateAction } from 'react';
import { useStyles } from './style';

interface resizableDivProps {
    key: any;
    ref: React.RefObject<HTMLDivElement>;
    onMouseDown: (e: React.MouseEvent<HTMLDivElement>) => void;
    isDragging: boolean;
    minWidth: string;
    minHeight: string;
    parentRect: any;
    xCoord: number;
    yCoord: number;
    widthSize: number;
    heightSize: number;
    handleSetPosition: any;
    handleSetSize: any;
    handleSetIsResizing: Dispatch<SetStateAction<boolean>>;
}

const ResizableDiv: React.FC<resizableDivProps> = ({
  key,
  ref,
  onMouseDown,
  isDragging = false,
  minWidth,
  minHeight,
  parentRect,
  xCoord,
  yCoord,
  widthSize,
  heightSize,
  handleSetPosition,
  handleSetSize,
  handleSetIsResizing,
}) => {
  const refBox = React.useRef<HTMLDivElement>(null);
  const refLeft = React.useRef<HTMLDivElement>(null);
  const refTop = React.useRef<HTMLDivElement>(null);
  const refRight = React.useRef<HTMLDivElement>(null);
  const refBottom = React.useRef<HTMLDivElement>(null);

  function KeepInBoundsDimensions(size: number, maxSize: number) {
    return size > maxSize ? maxSize : size;
  }

  // TODO: recalculate new bounds after resizing
  function handleKeepInBoundsX(x: any, parentRectProp: any, event: any) {
    return x;
  }

  // TODO: recalculate new bounds after resizing
  function handleKeepInBoundsY(y: any, parentRectProp: any, event: any) {
    return y;
  }

  // Update local coordinates from the parent container
  React.useEffect(() => {
    const resizableElement = refBox?.current;
    if (resizableElement) {
      resizableElement.style.top = `${yCoord}px`;
      resizableElement.style.left = `${xCoord}px`;
    }
  }, [xCoord, yCoord]);

  // eslint-disable-next-line consistent-return
  React.useEffect(() => {
    const resizableElement = refBox?.current;
    if (resizableElement && !isDragging) {
      const styles = window.getComputedStyle(resizableElement);
      let w = parseInt(styles.width, 10);
      let h = parseInt(styles.height, 10);

      let x = 0;
      let y = 0;

      resizableElement.style.width = `${widthSize}px` || minWidth;
      resizableElement.style.height = `${heightSize}px` || minHeight;

      // Top handlers
      const onMouseMoveTopResize = (event: any) => {
        handleSetIsResizing(true);
        const dy = event.clientY - y;
        h -= dy;
        y = handleKeepInBoundsY(event.clientY, parentRect, event);
        const heightInCanvas = KeepInBoundsDimensions(h, parentRect?.height);
        resizableElement.style.height = `${heightInCanvas}px`;
      };
      const onMouseUpTopResize = (event: any) => {
        document.removeEventListener('mousemove', onMouseMoveTopResize);
        handleSetIsResizing(false);
      };
      const onMouseDownTopResize = (event: any) => {
        y = handleKeepInBoundsY(event.clientY, parentRect, event);
        const styles1 = window.getComputedStyle(resizableElement);
        resizableElement.style.bottom = styles.bottom;
        resizableElement.style.top = '';
        document.addEventListener('mousemove', onMouseMoveTopResize);
        document.addEventListener('mouseup', onMouseUpTopResize);
      };

      // Right handlers
      const onMouseMoveRightResize = (event: any) => {
        handleSetIsResizing(true);
        const dx = event.clientX - x;
        x = handleKeepInBoundsX(event.clientX, parentRect, event);
        w += dx;
        const widthInCanvas = KeepInBoundsDimensions(w, parentRect?.width);
        resizableElement.style.width = `${widthInCanvas}px`;
      };
      const onMouseUpRightResize = (event: any) => {
        document.removeEventListener('mousemove', onMouseMoveRightResize);
        handleSetIsResizing(false);
      };
      const onMouseDownRightResize = (event: any) => {
        x = handleKeepInBoundsX(event.clientX, parentRect, event);
        const styles1 = window.getComputedStyle(resizableElement);
        resizableElement.style.left = styles1.left;
        resizableElement.style.right = '';
        document.addEventListener('mousemove', onMouseMoveRightResize);
        document.addEventListener('mouseup', onMouseUpRightResize);
      };

      // Bottom handlers
      const onMouseMoveBottomResize = (event: any) => {
        handleSetIsResizing(true);
        const dy = event.clientY - y;
        h += dy;
        y = handleKeepInBoundsY(event.clientY, parentRect, event);
        const heightInCanvas = KeepInBoundsDimensions(h, parentRect?.height);
        resizableElement.style.height = `${heightInCanvas}px`;
      };
      const onMouseUpBottomResize = (event: any) => {
        document.removeEventListener('mousemove', onMouseMoveBottomResize);
        handleSetIsResizing(false);
      };
      const onMouseDownBottomResize = (event: any) => {
        y = handleKeepInBoundsY(event.clientY, parentRect, event);
        const styles1 = window.getComputedStyle(resizableElement);
        resizableElement.style.top = styles1.top;
        resizableElement.style.bottom = '';
        document.addEventListener('mousemove', onMouseMoveBottomResize);
        document.addEventListener('mouseup', onMouseUpBottomResize);
      };

      // Left handlers
      const onMouseMoveLeftResize = (event: any) => {
        handleSetIsResizing(true);
        const dx = event.clientX - x;
        x = handleKeepInBoundsX(event.clientX, parentRect, event);
        w -= dx;
        const widthInCanvas = KeepInBoundsDimensions(w, parentRect?.width);
        resizableElement.style.width = `${widthInCanvas}px`;
      };
      const onMouseUpLeftResize = (event: any) => {
        document.removeEventListener('mousemove', onMouseMoveLeftResize);
        handleSetIsResizing(false);
      };
      const onMouseDownLeftResize = (event: any) => {
        x = handleKeepInBoundsX(event.clientX, parentRect, event);
        resizableElement.style.right = styles.right;
        resizableElement.style.left = '';
        document.addEventListener('mousemove', onMouseMoveLeftResize);
        document.addEventListener('mouseup', onMouseUpLeftResize);
      };

      // Mouse down event listeners for all handles
      const resizerRight = refRight.current;
      resizerRight?.addEventListener('mousedown', onMouseDownRightResize);

      const resizerTop = refTop.current;
      resizerTop?.addEventListener('mousedown', onMouseDownTopResize);

      const resizerBottom = refBottom.current;
      resizerBottom?.addEventListener('mousedown', onMouseDownBottomResize);

      const resizerLeft = refLeft.current;
      resizerLeft?.addEventListener('mousedown', onMouseDownLeftResize);

      // update box coordinates
      handleSetPosition({ x, y });
      handleSetSize({ w, h });

      return () => {
        resizerRight?.removeEventListener('mousedown', onMouseDownRightResize);
        resizerTop?.removeEventListener('mousedown', onMouseDownTopResize);
        resizerBottom?.removeEventListener('mousedown', onMouseDownBottomResize);
        resizerLeft?.removeEventListener('mousedown', onMouseDownLeftResize);
      };
    }
  }, []);

  const classes = useStyles();

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      ref={refBox}
      key={key}
      className={classes.resizableBox}
      onMouseDown={onMouseDown}
    >
      <div ref={refLeft} className={classes.resizableLeft} />
      <div ref={refTop} className={classes.resizableTop} />
      <div ref={refRight} className={classes.resizableRight} />
      <div ref={refBottom} className={classes.resizableBottom} />
    </div>
  );
};

export default ResizableDiv;
