import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  tableRoot: {
    '&:before': {
      content: '"\u200C"',
      display: 'block',
      lineHeight: '8px',
    },
  },
  tableWrapper: {
    overflowX: 'auto',
    boxShadow: '0 1px 7px #555',
  },
  headerCell: {
    borderBottom: 'none !important',
    padding: '5px 9px !important',
    fontWeight: 'bold',
    border: '1px solid #fff',
    backgroundColor: '#fff !important',
    color: '#222 !important',
    whiteSpace: 'nowrap',
  },
  headerRow: {
    boxShadow: '0 1px 7px #555',
  },
  bodyCell: {
    borderBottom: 'none !important',
    padding: '5px 9px !important',
    fontWeight: '400 !important',
    fontSize: '12px !important',
    whiteSpace: 'nowrap',
  },
  bodyCellEmpty: {
    borderBottom: 'none !important',
    padding: '8px 7px !important',
  },
  tableRow: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#fbf8e9 !important',
    },
  },
  sortLabel: {
    display: 'flex !important',
    justifyContent: 'space-between !important',
  },
  footerWrapper: { marginTop: 5 },
  showingText: { fontSize: 12, fontWeight: 400, color: 'black' },
  paginationContainer: {
    marginTop: 5,
  },
  root: {
    paddingLeft: '5px !important',
  },
  list: {
    border: '1px solid #aaa !important',
  },
  menuItem: {
    padding: '1px 20px !important',
    fontSize: '12px !important',
  },
  paper: {
    transitionDuration: '0s !important',
    marginLeft: -12,
  },
});
