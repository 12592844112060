import React, { useEffect, useState } from 'react';

// Libraries
import {
  Box,
  ButtonBase,
  DialogActions,
  Divider,
  Tab,
  Tabs,
} from '@mui/material';

// Components
import { DialogComponent } from '../Dialog/Dialog.component';

// Styles
import { useStyles } from './style';
import Detail from './Detail';
import CancelRebill from './CancelRebill';
import Documents from './Documents';

interface IProps {
  onClose: () => void;
  open: boolean;
  selectedTransaction: any;
  headerList: any;
  recordDetail: any;
  visibleColumns: any;
}

const TransactionViewerDetailDialog: React.FC<IProps> = ({
  onClose,
  open,
  selectedTransaction,
  headerList,
  recordDetail,
  visibleColumns,
}) => {
  const [dialogState, setDialogState] = useState(() => ({
    tabValue: 0,
  }));
  const classes = useStyles();

  const handleChangeTabs = (event: React.SyntheticEvent, newValue: number) => {
    setDialogState({ tabValue: newValue });
  };

  return (
    <DialogComponent
      title="Transaction:"
      titleParam={`BOL: ${selectedTransaction?.doc_no}`}
      onClose={onClose}
      open={open}
    >
      <div className={classes.wrapper}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
          <Tabs
            value={dialogState.tabValue}
            onChange={handleChangeTabs}
            aria-label="basic tabs example"
          >
            <Tab label="Detail" style={{ textTransform: 'none' }} />
            <Tab label="Cancel/Rebill" style={{ textTransform: 'none' }} />
            <Tab label="Documents" style={{ textTransform: 'none' }} />
          </Tabs>
        </Box>
        <div
          role="tabpanel"
          id={`simple-tabpanel-${dialogState.tabValue}`}
          aria-labelledby={`simple-tab-${dialogState.tabValue}`}
          className="overFlowXAuto"
        >
          {dialogState.tabValue === 0 ? (
            <Detail
              rowData={recordDetail?.rowdata}
              visibleColumns={visibleColumns}
            />
          ) : null}
          {dialogState.tabValue === 1 ? <CancelRebill /> : null}
          {dialogState.tabValue === 2 ? <Documents /> : null}
        </div>
      </div>
      <Divider variant="middle" />
      <DialogActions>
        <ButtonBase classes={{ root: classes.closeButton }} onClick={onClose}>
          Close
        </ButtonBase>
      </DialogActions>
    </DialogComponent>
  );
};

export default TransactionViewerDetailDialog;
