/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useRef, useState } from 'react';

// Librairies
import { ButtonBase, Popover, TextareaAutosize } from '@mui/material';
import { Check, CloseOutlined } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

// Styles
import { useStyles } from './style';
import {
  getOptionsAndDescriptions,
  setFormData,
} from '../../redux/actions/systemStartup';
import WorkflowTable from '../WorkflowTable';

// Hooks
import { useWorkflowState } from '../../hooks/useWorkflowState';
import WorkflowTableUtils from '../WorkflowTableUtils';

const tableHeader = {
  opt: 'Option',
  desc: 'Description',
};

const searchHeader = [
  { label: 'Option', key: 'opt' },
  { label: 'Description', key: 'desc' },
];

interface IProps {
  title: string;
  contentText: string;
  disabled: boolean;
  command: string;
  field: any;
  row: any;
}

const StystemStartupBodyMenu: React.FC<IProps> = ({
  title,
  contentText,
  disabled,
  command,
  field,
  row,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [contentValue, setContentValue] = useState<string>(contentText);
  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    if (!disabled) {
      setAnchorEl(event.currentTarget);
    }
  };

  return (
    <div className={classes.root}>
      <ButtonBase onClick={handleOpenPopover}>
        <span
          className={clsx(
            !contentValue ? classes.emptyStyle : classes.filledStyle,
          )}
        >
          {contentValue || 'Empty'}
        </span>
      </ButtonBase>
      {anchorEl ? (
        <PopoverComponent
          anchorEl={anchorEl}
          contentText={contentText}
          handleClosePopover={handleClosePopover}
          title={title}
          command={command}
          field={field}
          row={row}
          setContentValue={setContentValue}
        />
      ) : null}
    </div>
  );
};

interface IPopoverComponentProps {
  anchorEl: null | HTMLElement;
  handleClosePopover: () => void;
  title: string;
  contentText: string;
  command: string;
  field: any;
  row: any;
  setContentValue: any;
}

const PopoverComponent: React.FC<IPopoverComponentProps> = ({
  anchorEl,
  handleClosePopover,
  title,
  contentText,
  setContentValue,
  command,
  field,
  row,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState('');
  const newFormDataRow = useRef(null);
  const { formData } = useSelector((state: any) => state.SystemStartupReducer);
  const renderTitle = title === 'Arguments' ? `${command} - ${title}` : title;
  const inputField = row.fields.find((x: any) => x.id === field.id);
  const formInputField = !formData[row.row]
    ? null
    : formData[row.row]?.find((x: any) => x.id === field.id);

  useEffect(() => {
    if (formInputField) {
      setInputValue(formInputField.value);
    } else {
      setInputValue(inputField.value);
    }
  }, [inputField?.value, formInputField?.value]);

  const handleInputBlur = () => {
    const newFields = [...row.fields];
    const changedFieldIndex = newFields.findIndex(
      (x: any) => x.id === field.id,
    );
    const newField = { ...newFields[changedFieldIndex], value: inputValue };
    newFields[changedFieldIndex] = newField;
    const newRow = { ...row, fields: newFields };
    newFormDataRow.current = newRow;
  };

  const handleConfirm = () => {
    if (newFormDataRow.current) {
      setContentValue(inputValue);
      dispatch(setFormData(newFormDataRow.current));
    }
    handleClosePopover();
  };

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClosePopover}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      classes={{ root: classes.popoverRoot, paper: classes.paperRoot }}
    >
      <div className={classes.header}>{renderTitle}</div>
      <div className={classes.wrapper}>
        {/* <TextareaAutosize
          className={classes.textArea}
          value={inputValue}
          onBlur={handleInputBlur}
          onChange={(e) => setInputValue(e.target.value)}
        /> */}
        <ButtonBase className={classes.checkButton} onClick={handleConfirm}>
          <Check className={classes.checkIcon} />
        </ButtonBase>
        <ButtonBase
          className={classes.closeButton}
          onClick={handleClosePopover}
        >
          <CloseOutlined />
        </ButtonBase>
      </div>
      {title === 'Arguments' ? (
        <OptionsTable anchorEl={anchorEl} command={command} />
      ) : null}
    </Popover>
  );
};

interface IOptionsTable {
  anchorEl: null | HTMLElement;
  command: string;
}

const OptionsTable: React.FC<IOptionsTable> = ({ anchorEl, command }) => {
  const { options } = useSelector((state: any) => state.SystemStartupReducer);
  const dispatch = useDispatch();
  const optionsList = options[command]?.usagelist?.filter(
    (x: any) => x.opt && x.desc,
  );

  useEffect(() => {
    if (anchorEl !== null && !options[command]) {
      dispatch(getOptionsAndDescriptions(command));
    }
  }, [anchorEl]);

  const {
    recordsToShow,
    tableState,
    handlePaginationChange,
    handleSearchChange,
    handleSelectChange,
    handleSortTable,
  } = useWorkflowState(searchHeader, optionsList || [], null, false, true, 1);

  return (
    <div style={{ maxWidth: 665 }}>
      <WorkflowTableUtils
        handleSearchChange={handleSearchChange}
        handleSelectChange={handleSelectChange}
        recordsNumber={tableState.recordsNumber}
        searchText={tableState.searchText}
      />
      <WorkflowTable
        handleClickRow={() => null}
        visibleColumns={tableHeader}
        allDataCount={options[command]?.usagelist?.length - 1 || 0}
        handlePaginationChange={handlePaginationChange}
        recordsToShow={recordsToShow}
        tableHeader={tableHeader}
        tableState={tableState}
        order={tableState.order}
        orderBy={tableState.orderBy}
        searchText={tableState.searchText}
        sortTable={handleSortTable}
      />
    </div>
  );
};

export default React.memo(StystemStartupBodyMenu);
