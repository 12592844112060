/* eslint-disable react/require-default-props */
import React from 'react';

// Librairies
import {
  Dialog, IconButton, Paper,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// Styles
import Draggable from 'react-draggable';
import { useDialogStyles } from './dialog.styles';

interface IDialogCompoentProps {
  onClose: () => void;
  open: boolean;
  title: string;
  className?: any;
  reportDescription?: any;
}

const PaperComponent = (props: any) => (
  <Draggable
    handle="#draggable-dialog-title"
    cancel={'[class*="MuiDialogContent-root"]'}
  >
    <Paper {...props} />
  </Draggable>
);

export const DialogComponent: React.FC<IDialogCompoentProps> = ({
  open,
  onClose,
  title,
  children,
  className,
  reportDescription,
}): JSX.Element => {
  const classes = useDialogStyles();
  return (
    <Dialog
      onClose={onClose}
      open={open}
      className={className}
      classes={{ paper: className }}
      maxWidth="md"
      fullWidth
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <div className={classes.modalHeader}>
        <div className={classes.TitleWithCap}>
          <h4 className={classes.modalTitle}>
            {title}
            {' '}
          </h4>
          {reportDescription && (
            <span className={classes.small}>
              {'  '}
              -
              {' '}
              (
              {reportDescription}
              )
            </span>
          )}
        </div>
        <IconButton size="small" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      {children}
    </Dialog>
  );
};
