import { LOAD_TANK_STRAPPING, LOAD_TANK_STRAPPING_DETAILS } from '../../constants/workflows';

const INITIAL_STATE = {
  data: [],
  detailsData: {},
};

const tankStrapping = (
  state = INITIAL_STATE,
  { type, payload }: any,
) => {
  switch (type) {
    case LOAD_TANK_STRAPPING: {
      return {
        ...state,
        data: payload,
      };
    }
    case LOAD_TANK_STRAPPING_DETAILS: {
      return {
        ...state,
        detailsData: payload,
      };
    }
    default:
      return state;
  }
};

export default tankStrapping;
