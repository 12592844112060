/* eslint-disable max-len */
import { Dispatch } from 'redux';
import Api from '../../api/index';
import { START_LOADING, STOP_LOADING } from '../../constants/Screens';
import { LOAD_TANK_STRAPPING, LOAD_TANK_STRAPPING_DETAILS } from '../../constants/workflows';

export const getTankStrappingTableData = () => async (dispatch: Dispatch) => {
  dispatch({ type: START_LOADING });
  const response = await Api.get(
    'workflow098/get-tankstrapping-data?lang=en-US&termId=B344',
  );
  dispatch({
    type: LOAD_TANK_STRAPPING,
    payload: response.data,
  });
  dispatch({ type: STOP_LOADING });
};
export const getTankStrappingDetails = (row: any) => async (dispatch: Dispatch) => {
  dispatch({ type: START_LOADING });
  const response = await Api.get(
    `workflow098/getTankStrappingDetails?tankTableID=${row.tank_id}&tankHeight=${row.tank_strap_height}&screenID=Workflow-098&newTable=n&termID=B344&lang=en-US`,
  );
  dispatch({
    type: LOAD_TANK_STRAPPING_DETAILS,
    payload: response.data.data,
  });
  dispatch({ type: STOP_LOADING });
};
