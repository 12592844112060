import React from 'react';

// Librairies
import { Divider, Menu, MenuItem } from '@mui/material';

// Styles
import { useStyles } from './style';

interface IProps {
  anchorEl: any;
  onClose: () => void;
  exportColumns: () => void;
  openRecordChangeHistoryDialog: () => void;
  handleClearField: (fields: string[]) => void;
  openAutoTankGaugeDialog: () => void;
  isUpdateDisabled: boolean;
}

const TankGaugeEntryRecordDetailOptions: React.FC<IProps> = ({
  anchorEl,
  onClose,
  exportColumns,
  openRecordChangeHistoryDialog,
  handleClearField,
  openAutoTankGaugeDialog,
  isUpdateDisabled,
}) => {
  const classes = useStyles();
  return (
    <Menu
      classes={{ root: classes.root, list: classes.list, paper: classes.paper }}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
    >
      <MenuItem
        classes={{ root: classes.menuItem }}
        onClick={() => handleClearField(['line_fill', 'line_fill1', 'line_fill2', 'line_fill3'])}
        disabled={isUpdateDisabled}
      >
        Clear Line FIlls
      </MenuItem>
      <MenuItem
        classes={{ root: classes.menuItem }}
        onClick={() => handleClearField(['bottoms'])}
        disabled={isUpdateDisabled}
      >
        Clear Tank Bottoms
      </MenuItem>
      <Divider />
      <MenuItem
        classes={{ root: classes.menuItem }}
        onClick={openAutoTankGaugeDialog}
      >
        Auto Tank Gauge ...
      </MenuItem>
      <Divider />
      <MenuItem classes={{ root: classes.menuItem }} onClick={exportColumns}>
        Export Columns (CSV)
      </MenuItem>
      <MenuItem
        classes={{ root: classes.menuItem }}
        onClick={openRecordChangeHistoryDialog}
      >
        Record Change History...
      </MenuItem>
    </Menu>
  );
};

export default TankGaugeEntryRecordDetailOptions;
