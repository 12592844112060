import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { KeyPressedEventHandler } from '../components/KeyboardComponent/Keyboard';
import { RCUDittoKeySend } from '../redux/actions/workflows/rackStatus';
import { useLogger } from './useLogger';

export interface RcuKeyboardHook {
  showingKeyboardIndex: number | null;
  handleOpenKeyboard: (index: number) => void;
  keyPress: KeyPressedEventHandler;
}

type RcuKeyPressedEvent = {
  /**
   * the key that was pressed either with keyboard or on-screen keyboard.
   */
  key: string;
  /**
   * the index of the keyboard that is currently being shown. Will never be null.
   */
  showingKeyboardIndex: number
};
/**
 * a callback for when the window onKeyDown event is fired or the keyboard's key is pressed.
 * When added to a component it will register a onKeyDown event to the window but only fire it when the showing
 * keyboard index is not null.
 * @param {RcuKeyPressedEvent} event the event parameters for when the key event happened.
 */
export type RcuKeyPressedHandler = (event: RcuKeyPressedEvent) => void;

export const useRcuKeyboard = (handler: RcuKeyPressedHandler): RcuKeyboardHook => {
  const [showingKeyboardIndex, setShowingKeyboardIndex] = useState<
    number | null
  >(null);
  const rcuKeyPress = (key: string) => {
    if (showingKeyboardIndex !== null) {
      handler({ key, showingKeyboardIndex });
    }
  };

  function keyMapPress(e: KeyboardEvent) {
    let key = e.key.toLowerCase();

    switch (key) {
      // PAGE-DOWN or DOWN ARROW or RIGHT ARROW
      case 'pagedown':
      case 'arrowdown':
      case 'arrowright':
      case 'uikeyinputdownarrow':
      case 'uikeyinputrightarrow':
        key = 'A';
        break;
      // PAGE-UP or UP ARROW or LEFT ARROW
      case 'pageup':
      case 'arrowup':
      case 'arrowleft':
      case 'home':
      case 'uikeyinputuparrow':
      case 'uikeyinputleftarrow':
        key = 'B';
        break;
      case 'f5':
      case 'end':
      case 'escape':
        key = 'C';
        break;
      case 'f4':
      case 'f7':
      case 'f12':
        key = 'S';
        break;
      case 'enter':
        key = 'D';
        break;
      case 'backspace':
      case 'delete':
        key = 'E';
        break;
      default:
        if ((key >= 'a') && (key <= 'z')) {
          break;
        } else if ((key >= '0') && (key <= '9')) {
          break;
        } else if ((key === '+') || (key === '-') || (key === '#')) {
          break;
        } else {
          key = '';
        }
        break;
    }

    // if the key does not map ... do nothing
    if (key === '') {
      return;
    }

    rcuKeyPress(key);
  }

  useEffect(() => {
    window.addEventListener('keydown', keyMapPress);

    return () => {
      window.removeEventListener('keydown', keyMapPress);
    };
  }, []);

  function xlateKey(key: string) {
    const keyMap = {
      enter: 'D',
      prev: 'B',
      next: 'A',
      exit: 'C',
      stop: 'S',
      clr: 'E',
    };
    // normalize to lower-case for lookup
    let keyVal = key.toLowerCase();
    if (keyMap[keyVal as keyof typeof keyMap]) {
      keyVal = keyMap[keyVal as keyof typeof keyMap];
    } else if (keyVal === '<-') {
      keyVal = '<';
    } else if (keyVal === '->') {
      keyVal = '>';
    } else if (keyVal === '+/-') {
      keyVal = '-';
    }

    return keyVal;
  }

  const keyPress: KeyPressedEventHandler = (e) => {
    rcuKeyPress(xlateKey(e));
  };

  const handleOpenKeyboard = (index: number) => {
    if (String(showingKeyboardIndex)) {
      if (showingKeyboardIndex === index) {
        setShowingKeyboardIndex(null);
      } else {
        setShowingKeyboardIndex(index);
      }
    } else {
      setShowingKeyboardIndex(index);
    }
  };

  return {
    showingKeyboardIndex,
    handleOpenKeyboard,
    keyPress,
  };
};
