import { combineReducers } from 'redux';
import ReportsQueue from './reportsqueue';
import Reports from './reports';
import ViewReports from './viewReports';
import CustomReportConfiguration from './customreportconfiguration';

export default combineReducers({
  ReportsQueue,
  Reports,
  ViewReports,
  CustomReportConfiguration,
});
