/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { useEffect, useState } from 'react';

// Librairies
import {
  ButtonBase,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';

// Components
import { DialogComponent } from '../Dialog/Dialog.component';
import CustomInput from '../customInput';
import { useStyles } from './styles';

// Actions
import {
  setCalculations,
  clearCalculations,
} from '../../redux/actions/RecordActions';
import CustomAddDropdown from '../customAddDropdown';
import ContractAddDropDown from '../transactionTemplateCustomAddDropdown';

type calculationType = 'unittarget' | 'unitpaging' | 'unit1000ths' | 'unitccs';

const TransactionTemplateContent = ({ templateList, handleTemplate, templateValue }:any) => {
  const { unitCalculator } = useSelector((state: any) => state.ScreenReducer);
  const classes = useStyles();

  return (
    <div className="flexColumn">
      <div className={classes.templateContainer}>
        <ContractAddDropDown
          val={{ value: templateValue, options: templateList }}
          isBatch={false}
          label="Template"
          name="template"
          defaultValue={templateValue?.label}
          isDisabled={false}
          value={templateValue}
          isOptionEqualToValue={(option: any, value: any) => option?.label === value}
          onChange={handleTemplate}
          size="70%"
          margin={0}
          options={templateList}
          type="input"
          required={false}
        />
      </div>
    </div>
  );
};

interface IProps {
  onClose: () => void;
  open: boolean;
  templateList: any;
  handleTemplate:any;
  templateValue:any;
  getTransactionTemplate:any;
  deleteContractTransactionTemplate: any;
  saveContractTransactionTemplate: any;
}

export const TransactionTemplate: React.FC<IProps> = ({
  onClose, open, templateList, handleTemplate, templateValue, getTransactionTemplate, deleteContractTransactionTemplate, saveContractTransactionTemplate,
}:any) => {
  const [Values, setValues] = useState({});
  const classes = useStyles();
  const handleLoad = () => {
    getTransactionTemplate();
    onClose();
  };
  const handleSave = () => {
    saveContractTransactionTemplate();
    onClose();
  };
  const handleDelete = () => {
    deleteContractTransactionTemplate();
    onClose();
  };
  return (
    <>
      <DialogComponent onClose={onClose} open={open} title="Transaction Template">
        <DialogContent>
          <TransactionTemplateContent templateList={templateList} handleTemplate={handleTemplate} templateValue={templateValue} />
        </DialogContent>
        <Divider variant="middle" />
        <DialogActions classes={{ root: classes.actions }}>
          <ButtonBase
            sx={{
              color: 'black',
              textShadow: 'none',
              backgroundColor: '#ffbb33',
              border: '1px solid transparent',
              padding: '6px 10px',
            }}
            onClick={onClose}
          >
            Close
          </ButtonBase>
          <ButtonBase
            sx={{
              color: 'black',
              textShadow: 'none',
              backgroundColor: 'red',
              border: '1px solid transparent',
              padding: '6px 10px',
            }}
            onClick={handleDelete}
          >
            Remove
          </ButtonBase>
          <ButtonBase
            sx={{
              color: 'black',
              textShadow: 'none',
              backgroundColor: '#00C851',
              border: '1px solid transparent',
              padding: '6px 10px',
            }}
            onClick={handleSave}
          >
            Save
          </ButtonBase>
          <ButtonBase
            sx={{
              color: 'black',
              textShadow: 'none',
              backgroundColor: '#852b99',
              border: '1px solid transparent',
              padding: '6px 10px',
            }}
            onClick={handleLoad}
          >
            Load
          </ButtonBase>
        </DialogActions>
      </DialogComponent>
    </>
  );
};
