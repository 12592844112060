/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { useEffect, useState } from 'react';

// Librairies
import {
  ButtonBase,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';

// Components
import { DialogComponent } from '../Dialog/Dialog.component';
import CustomInput from '../customInput';
import { useStyles } from './style';

// Actions
import {
  setCalculations,
  clearCalculations,
} from '../../redux/actions/RecordActions';

type calculationType = 'unittarget' | 'unitpaging' | 'unit1000ths' | 'unitccs';

const SubmitTransaction = () => (
  <div>
    <p> Choose "New Transaction' to create another transaction using an empty form. </p>
    <p>Choose 'Reuse Transaction' to create another transaction using the previously entered information.</p>
    <p>Choose Transaction Viewer' to view the transaction/s.</p>
  </div>
);

interface IProps {
  onClose: () => void;
  open: boolean;
  copyTransaction:any;
  startNewTransaction:any;
}

export const SubmitTransactionDialog: React.FC<IProps> = ({
  onClose, open, copyTransaction, startNewTransaction,
}:any) => {
  const [Values, setValues] = useState({});
  const classes = useStyles();
  return (
    <>
      <DialogComponent onClose={onClose} open={open} title="Manual Transaction Next Step">
        <DialogContent>
          <SubmitTransaction />
        </DialogContent>
        <Divider variant="middle" />
        <DialogActions sx={{
          display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0px 20px',
        }}
        >
          <p style={{ textAlign: 'start' }}>Note: You must choose one of these options</p>
          <div style={{
            display: 'flex',
            gap: 5,
          }}
          >
            <ButtonBase
              sx={{
                color: 'white',
                textShadow: 'none',
                backgroundColor: 'blue',
                border: '1px solid transparent',
                padding: '6px 10px',
              }}
              onClick={startNewTransaction}
            >
              New Transaction
            </ButtonBase>
            <ButtonBase
              sx={{
                color: 'white',
                textShadow: 'none',
                backgroundColor: 'green',
                border: '1px solid transparent',
                padding: '6px 10px',
              }}
              onClick={copyTransaction}
            >
              Reuse Transaction
            </ButtonBase>
            <ButtonBase
              sx={{
                color: 'white',
                textShadow: 'none',
                backgroundColor: 'gray',
                border: '1px solid transparent',
                padding: '6px 10px',
              }}
              onClick={() => console.log('not ready yet')}
            >
              Transaction Viewer
            </ButtonBase>
          </div>
        </DialogActions>
      </DialogComponent>
    </>
  );
};
