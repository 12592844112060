import React, { useState } from 'react';

// Librairies
import { Divider, Menu, MenuItem } from '@mui/material';
// import Api from '../../../../redux/api/index';
// Styles
import { useStyles } from './style';
// import ConfirmationDialog from '../../../ConfirmDialog';

interface IProps {
  anchorEl: any;
  onClose: () => void;
  handleOpenDialog: (type: string) => void;
}

const RackStatusOptionsButton: React.FC<IProps> = ({
  anchorEl,
  onClose,
  handleOpenDialog,
}) => {
  const classes = useStyles();
  const handleViewReportOptionClick = () => {
    onClose();
  };

  return (
    <>
      <Menu
        classes={{ root: classes.root, list: classes.list, paper: classes.paper }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={onClose}
      >
        <MenuItem
          classes={{ root: classes.menuItem }}
          onClick={() => handleOpenDialog('stopallbays')}
        >
          Stop All Load Bays
        </MenuItem>
        <MenuItem
          classes={{ root: classes.menuItem }}
          onClick={() => handleOpenDialog('startallbays')}
        >
          Start All Load Bays
        </MenuItem>
        <MenuItem
          classes={{ root: classes.menuItem }}
          onClick={() => handleOpenDialog('drainallbays')}
        >
          Drain All Load Bays
        </MenuItem>
        <Divider />
        <MenuItem
          classes={{ root: classes.menuItem }}
          onClick={() => handleOpenDialog('pumprestoreall')}
        >
          Restore All Pumps
        </MenuItem>
        <Divider />
        <MenuItem
          classes={{ root: classes.menuItem }}
          onClick={() => handleOpenDialog('restartallbays')}
        >
          Restart All Load Bays
        </MenuItem>
      </Menu>
    </>
  );
};

export default RackStatusOptionsButton;
