/* eslint-disable react/self-closing-comp */
/* eslint-disable react/no-danger */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable arrow-body-style */
import React from 'react';
import { useDispatch } from 'react-redux';
import { RcuKeyPressedHandler, useRcuKeyboard } from '../../hooks/useRcuKeyboard';
import { RCUDittoKeySend } from '../../redux/actions/workflows/rackStatus';
import { KeyboardComponent } from '../KeyboardComponent/Keyboard';
import './style.css';

interface IProps {
    RCUScreen: any;
    dittoBayID: string;
}

const RCUDitto:React.FC<IProps> = ({ RCUScreen, dittoBayID }) => {
  const dispatch = useDispatch();

  const onRcuKeyPressed : RcuKeyPressedHandler = (event) => {
    if ((dittoBayID === '') || (dittoBayID === '')) {
      return;
    }
    const payload = {
      queueid: `Q${dittoBayID}`,
      reqid: `id-0y4y${Math.random() * 10}paqcgltg`,
      data: {
        cmd: 'ditto',
        parm: {
          bay: dittoBayID,
          key: event.key,
          screenid: dittoBayID,
          termid: 'B344',
        },
      },
    };
    dispatch(RCUDittoKeySend(payload));
  };

  const { showingKeyboardIndex, handleOpenKeyboard, keyPress } = useRcuKeyboard(onRcuKeyPressed);
  return (
    <>
      <table style={{ width: '100%' }}>
        <tbody>
          <tr>
            <td className="facdetailditto">
              <div id={`device_${dittoBayID}`} style={{ float: 'right' }}>
                <pre id={`screen_${dittoBayID}`} className="rcuscreen" onClick={() => handleOpenKeyboard(1)}>
                  {RCUScreen && RCUScreen.split('\n').map((line: string) => {
                    return (
                      <>
                        <span dangerouslySetInnerHTML={{ __html: line }}></span>
                        <br />
                      </>
                    );
                  })}
                </pre>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      {showingKeyboardIndex === 1 ? <KeyboardComponent keyPress={keyPress} /> : null}
    </>
  );
};

export default RCUDitto;
