import React, { useRef, useState } from 'react';

// Librairies
import { Divider, Menu, MenuItem } from '@mui/material';
// import Api from '../../../../redux/api/index';
// Styles
import { useStyles } from './style';
// import ConfirmationDialog from '../../../ConfirmDialog';

interface IProps {
  anchorEl: any;
  onClose: () => void;
  handleOpenDialog: (type: string) => void;
  Options: any;
  confirmationBeforeProCommand: any;
  setOpenAlarmHistory: any;
}
const messageList = {
  start: 'Start',
  stop: 'Stop',
  restart: 'Restart',
  drain: 'Drain',
  enable: 'Enable',
  disable: 'Disable',
  open: 'Open',
  togglebaytrace: 'Toggle Trace On/Off',
  togglebaydebug: 'Toggle Debug On/Off',
  stopallbays: 'Stop All Load Bays',
  startallbays: 'Start All Load Bays',
  drainallbays: 'Drain All Load Bays',
  pumprestoreall: 'Restore All Pumps',
  restartallbays: 'Restart All Load Bays',
};
const RackStatusDetailsOptionButton: React.FC<IProps> = ({
  anchorEl,
  onClose,
  handleOpenDialog,
  Options,
  setOpenAlarmHistory,
  confirmationBeforeProCommand,
}) => {
  const classes = useStyles();
  const toggle = useRef<string>('none');
  const handleViewReportOptionClick = () => {
    onClose();
  };
  const toggleBackLoadingView = () => {
    const vehicle = document && document.querySelector('#vehicleframe-0') as HTMLElement;
    vehicle.style.display = toggle.current;
    if (toggle.current === 'none') {
      toggle.current = 'block';
    } else {
      toggle.current = 'none';
    }
  };

  const openTraceLogs = (obj: any) => {
    onClose();
    window.open('/workflow/view-trace-logs', '_blank', 'noreferrer');
    localStorage.setItem('pattern', JSON.stringify(obj?.params));
  };

  return (
    <>
      <Menu
        classes={{ root: classes.root, list: classes.list, paper: classes.paper }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={onClose}
      >
        {Options.map((opt: any) => {
          if (opt?.divider) {
            return <Divider />;
          }
          if (opt?.id === 'fs_view_logs') {
            return (
              <MenuItem
                classes={{ root: classes.menuItem }}
                onClick={() => openTraceLogs(opt?.linkUrl)}
              >
                View Logs
              </MenuItem>
            );
          }
          if (opt?.id === 'fs_alarm_history') {
            return (
              <MenuItem
                classes={{ root: classes.menuItem }}
                onClick={() => {
                  onClose();
                  setOpenAlarmHistory(true);
                }}
              >
                Alarm History
              </MenuItem>
            );
          }
          if (opt?.id === 'fs_togglebackview') {
            return (
              <MenuItem
                classes={{ root: classes.menuItem }}
                onClick={() => toggleBackLoadingView()}
              >
                Toggle Back Loading View
              </MenuItem>
            );
          }
          return (
            <MenuItem
              classes={{ root: classes.menuItem }}
              onClick={() => confirmationBeforeProCommand(opt.bayID, opt.option, opt.description)}
            >
              {messageList[opt.option as keyof typeof messageList]}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default RackStatusDetailsOptionButton;
