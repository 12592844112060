import React from 'react';

// Components
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SubHeader from '../../../components/subHeader';
import TmsHeader from '../../../components/header';
import WorkflowTable from '../../../components/WorkflowTable';
import WorkflowTableUtils from '../../../components/WorkflowTableUtils';

// Hooks
import { useWorkflowState } from '../../../hooks/useWorkflowState';

// Actions
import { getTankStrappingDetails, getTankStrappingTableData } from '../../../redux/actions/workflows/tankStrapping';

// Utils
const tableHeader = {
  tank_strap_terminal_id: 'Terminal ID',
  tank_strap_tank_id: 'Tank ID',
  tank_strap_height: 'Height',
  tank_strap_tank_unit: 'Tank Unit',
};

const searchHeader = [
  { label: 'Terminal ID', key: 'tank_strap_terminal_id' },
  { label: 'Tank ID', key: 'tank_strap_tank_id' },
  { label: 'Height', key: 'tank_strap_height' },
  { label: 'Tank Unit', key: 'tank_strap_tank_unit' },
];

const TankStrappingTables: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data } = useSelector(
    (state: any) => state?.Workflows?.TankStrapping,
  );
  const {
    recordsToShow,
    tableState,
    handlePaginationChange,
    handleSearchChange,
    handleSelectChange,
    handleSortTable,
  } = useWorkflowState(searchHeader, data, getTankStrappingTableData);
  const handleRowClick = (e: any) => {
    dispatch(getTankStrappingDetails(e));
    console.log(e);
    navigate('/workflow/tank-strapping-tables-detail');
  };
  return (
    <>
      <TmsHeader />
      <SubHeader title="Tank Strapping Tables" />
      <WorkflowTableUtils
        handleSearchChange={handleSearchChange}
        handleSelectChange={handleSelectChange}
        recordsNumber={tableState.recordsNumber}
        searchText={tableState.searchText}
      />
      <WorkflowTable
        handleClickRow={(e: any) => handleRowClick(e)}
        visibleColumns={tableHeader}
        allDataCount={data.length}
        handlePaginationChange={handlePaginationChange}
        recordsToShow={recordsToShow}
        tableHeader={tableHeader}
        tableState={tableState}
        order={tableState.order}
        orderBy={tableState.orderBy}
        searchText={tableState.searchText}
        sortTable={handleSortTable}
      />
    </>
  );
};

export default TankStrappingTables;
