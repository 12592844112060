import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    paddingLeft: '10px',
    overflowY: 'auto',
  },
  tableRoot: {
  },
  headerCell: {
    borderBottom: 'none !important',
    padding: '0px !important',
  },
  headerRow: {
    boxShadow: '0 1px 7px #555',
  },
  bodyCell: {
    borderBottom: 'none !important',
    padding: '8px 0px !important',
  },
  bodyCellEmpty: {
    borderBottom: 'none !important',
    padding: '8px 7px !important',
  },
  tableRow: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#fbf8e9 !important',
    },
  },
  footerWrapper: { marginTop: 5 },
  showingText: { fontSize: 12, fontWeight: 400, color: 'black' },
  sortLabel: {
    display: 'flex !important',
    justifyContent: 'space-between !important',
  },
});
