import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Components
import {
  Step, StepLabel, Stepper, StepContent, Typography,
} from '@mui/material';
import { useStyles } from './style';
import ContractDispatchDropdown from '../ContractDispatchDropdown';
// Actions
import {
  getCarriers,
  getDrivers,
  getLoadingStatus,
  getProductGroup,
  getRouteCode,
  getShippingMethod,
  getStagingBay,
} from '../../redux/actions/workflows/ContractDispatch';
// libraries
import CustomDateTimePicker from '../DateTimePicker';
import { getFormattedDate, getFormattedTime } from '../../utils';

const ContractDispatchStepper: React.FC = () => {
  // styles
  const classes = useStyles();
  const dispatch = useDispatch();
  // Date Time picker State
  const [parkDateTime, setparkDateTime] = useState<any>(
    {
      inputValue: `${getFormattedDate(new Date())} ${getFormattedTime(new Date())}`,
      pickerValue: new Date(),
    },
  );
  // state from Redux
  const {
    contractdrivers,
    contractcarriers,
    contractshippingmethod,
    contractroutecode,
    contractloadingstatus,
    contractproductgroup,
  } = useSelector(
    (state: any) => state.Workflows.ContractDispatch,
  );
  // Tracking Variable for Current Step
  const [stepActive, setStepActive] = useState<number>(0);
  // Form Data
  const [formData, setFormData] = useState<any>({
    term_id: { value: '', label: '' },
    contract_mode: { value: '', label: '' },
    allocation_override: { value: 'N', label: 'No' },
    ignore_vsx: { value: 'N', label: 'No' },
    driver: { driver_no: '', name: '' },
    carrier: { value: '', label: '' },
    shipping_method: { value: '', label: '' },
    route_code: { route_cd: '', descr: '' },
    staging_bay: '',
    petrolium_gas_group: { prod_group: '', pgp_desc: '' },
    loading_status: {
      appl_name: '', column_id: '', fld_value: '', description: '',
    },
  });
  // Dispatch Items Array
  const [items, setItems] = useState<number[]>([]);

  // Initial Load Data
  useEffect(() => {
    dispatch(getShippingMethod());
  }, []);

  // Data Load When Terminal ID Selected
  useEffect(() => {
    dispatch(getDrivers(formData.term_id.value));
    dispatch(getRouteCode(formData.term_id.value));
    dispatch(getStagingBay(formData.term_id.value));
    dispatch(getLoadingStatus(formData.term_id.value));
    dispatch(getProductGroup(formData.term_id.value));
  }, [formData.term_id]);

  // Carrier Load when
  useEffect(() => {
    dispatch(getCarriers(formData.term_id.value, formData.driver.driver_no));
  }, [formData.driver, formData.term_id]);

  // handle Form Data Change
  const handleChange = (option: any, name: any) => {
    setFormData({
      ...formData,
      [name]: option,
    });
  };

  // Remove ITem When Click
  const removeItem = (pos: number) => {
    const newItem = items.filter((oldpos) => oldpos !== pos);
    setItems(newItem);
  };

  // Add Dispatch Item When Click
  const addItem = () => {
    if (Number.isNaN(items[items.length - 1] + 1)) {
      setItems([...items, 0]);
    } else {
      setItems([...items, items[items.length - 1] + 1]);
    }
  };

  // handle Change date
  const handleDateTimePckerChange = (value: Date | null) => {
    const formattedDate = getFormattedDate(value);
    const formattedTime = getFormattedTime(value);
    setparkDateTime({
      inputValue: `${formattedDate} ${formattedTime}`,
      pickerValue: value,
    });
  };
  return (
    <>
      <div className={classes.Stepperdiv}>
        <div className={classes.sideButton}>
          <button
            type="button"
            className={classes.btn_default}
            onClick={() => setStepActive(0)}
            style={{ display: stepActive === 1 ? 'block' : 'none' }}
          >
            Edit
          </button>
        </div>
        <Stepper orientation="vertical" activeStep={stepActive}>
          <Step>
            <StepLabel
              optional={
                <Typography variant="caption">Driver Dispatch</Typography>
            }
            >
              Step 1
            </StepLabel>
            <StepContent>
              <div className={classes.containerContract}>
                <Typography
                  variant="caption"
                  className={classes.Typo}
                >
                  Terminal ID
                </Typography>
                <ContractDispatchDropdown
                  onChange={(e, option) => handleChange(option, 'term_id')}
                  options={[
                    { label: 'B344 : TOPTECH (LONGWOOD, FL)', value: 'B344' },
                  ]}
                  defaultValue={formData.term_id}
                  name="term_id"
                  width="75%"
                  style={{ margin: '2px 10px', borderLeft: '4px solid red !important' }}
                  color="red"
                />
              </div>
              <div className={classes.containerContract}>
                <Typography
                  variant="caption"
                  className={classes.Typo}
                >
                  Contract Mode
                </Typography>
                <ContractDispatchDropdown
                  onChange={(e, option) => handleChange(option, 'contract_mode')}
                  options={[
                    { value: '1', label: 'TMS Contract' },
                    { value: '2', label: 'Load ID' },
                    { value: '3', label: 'Host Contract Reference No.' },
                  ]}
                  defaultValue={formData.contract_mode}
                  name="contract_mode"
                  width="75%"
                  style={{ margin: '2px 10px', borderLeft: '4px solid red !important' }}
                  color="red"
                />
              </div>
              <div className={classes.containerContract}>
                <Typography
                  variant="caption"
                  className={classes.Typo}
                >
                  Driver
                </Typography>
                <ContractDispatchDropdown
                  onChange={(e, option) => handleChange(option, 'driver')}
                  options={contractdrivers}
                  getOptionLabel={(option) => (option.driver_no && option.name
                    ? `${option.driver_no} : ${option.name}`
                    : '')}
                  defaultValue={formData.driver}
                  name="driver"
                  width="75%"
                  style={{ margin: '2px 10px', borderLeft: '4px solid red !important' }}
                  color="red"
                />
              </div>
              <div className={classes.containerContract}>
                <Typography
                  variant="caption"
                  className={classes.Typo}
                >
                  Carrier
                </Typography>
                <ContractDispatchDropdown
                  onChange={(e, option) => handleChange(option, 'carrier')}
                  options={contractcarriers}
                  defaultValue={formData.carrier}
                  name="carrier"
                  width="75%"
                  style={{ margin: '2px 10px', borderLeft: '4px solid red !important' }}
                  color="black"
                />
              </div>
              <div className={classes.containerContract}>
                <Typography
                  variant="caption"
                  className={classes.Typo}
                >
                  Shipping Method
                </Typography>
                <ContractDispatchDropdown
                  onChange={(e, option) => handleChange(option, 'shipping_method')}
                  options={contractshippingmethod}
                  defaultValue={formData.shipping_method}
                  name="shipping_method"
                  width="75%"
                  style={{ margin: '2px 10px', borderLeft: '4px solid red !important' }}
                  color="black"
                />
              </div>
              <div className={classes.containerContract}>
                <Typography
                  variant="caption"
                  className={classes.Typo}
                >
                  Vehicle 1
                </Typography>
                <ContractDispatchDropdown
                  onChange={handleChange}
                  options={[]}
                  defaultValue={formData.vehicle1}
                  name="vehicle1"
                  width="75%"
                  style={{ margin: '2px 10px', borderLeft: '4px solid red !important' }}
                  color="red"
                />
              </div>
              <div className={classes.containerContract}>
                <Typography
                  variant="caption"
                  className={classes.Typo}
                >
                  Vehicle 2
                </Typography>
                <ContractDispatchDropdown
                  onChange={handleChange}
                  options={[]}
                  defaultValue={formData.vehicle2}
                  name="vehicle2"
                  width="75%"
                  style={{ margin: '2px 10px', borderLeft: '4px solid red !important' }}
                  color="black"
                />
              </div>
              <div className={classes.containerContract}>
                <Typography
                  variant="caption"
                  className={classes.Typo}
                >
                  Vehicle 3
                </Typography>
                <ContractDispatchDropdown
                  onChange={handleChange}
                  options={[]}
                  defaultValue={formData.vehicle3}
                  name="vehicle3"
                  width="75%"
                  style={{ margin: '2px 10px', borderLeft: '4px solid red !important' }}
                  color="black"
                />
              </div>
              <div className={classes.containerContract}>
                <Typography
                  variant="caption"
                  className={classes.Typo}
                >
                  Scheduled Date
                </Typography>
                <CustomDateTimePicker
                  handleChange={(value) => handleDateTimePckerChange(value)}
                  inputValue={parkDateTime?.inputValue}
                  pickerValue={parkDateTime?.pickerValue}
                  inputStyle={{ width: '75%', marginRight: '10px', borderLeft: '4px solid red !important' }}
                />
              </div>
              <div className={classes.containerContract}>
                <Typography
                  variant="caption"
                  className={classes.Typo}
                >
                  Expiration Date
                </Typography>
                <CustomDateTimePicker
                  handleChange={(value) => handleDateTimePckerChange(value)}
                  inputValue={parkDateTime?.inputValue}
                  pickerValue={parkDateTime?.pickerValue}
                  inputStyle={{ width: '75%', marginRight: '10px', borderLeft: '4px solid red !important' }}
                />
              </div>
              <div className={classes.containerContract}>
                <Typography
                  variant="caption"
                  className={classes.Typo}
                >
                  Staging Bay
                </Typography>
                <ContractDispatchDropdown
                  onChange={handleChange}
                  options={[]}
                  defaultValue={formData.staging_bay}
                  name="staging_bay"
                  width="75%"
                  style={{ margin: '2px 10px', borderLeft: '4px solid red !important' }}
                  color="black"
                />
              </div>
              <div className={classes.containerContract}>
                <Typography
                  variant="caption"
                  className={classes.Typo}
                >
                  Route Code
                </Typography>
                <ContractDispatchDropdown
                  onChange={(e, option) => handleChange(option, 'route_code')}
                  options={contractroutecode}
                  getOptionLabel={(option) => (option.route_cd && option.descr
                    ? `${option.route_cd} : ${option.descr}`
                    : '')}
                  defaultValue={formData.route_code}
                  name="route_code"
                  width="75%"
                  style={{ margin: '2px 10px', borderLeft: '4px solid red !important' }}
                  color="black"
                />
              </div>
              <div className={classes.containerContract}>
                <Typography
                  variant="caption"
                  className={classes.Typo}
                >
                  Prefered Loading Status
                </Typography>
                <ContractDispatchDropdown
                  onChange={(e, option) => handleChange(option, 'loading_status')}
                  options={contractloadingstatus}
                  defaultValue={formData.loading_status}
                  getOptionLabel={(option) => (option.description
                    ? option.description
                    : '')}
                  name="loading_status"
                  width="75%"
                  style={{ margin: '2px 10px', borderLeft: '4px solid red !important' }}
                  color="black"
                />
              </div>
              <div className={classes.containerContract}>
                <Typography
                  variant="caption"
                  className={classes.Typo}
                >
                  Liquefied Petroleum Gas Group
                </Typography>
                <ContractDispatchDropdown
                  onChange={(e, option) => handleChange(option, 'petrolium_gas_group')}
                  options={contractproductgroup}
                  defaultValue={formData.petrolium_gas_group}
                  getOptionLabel={(option) => (option.prod_group && option.pgp_desc
                    ? `${option.prod_group} : ${option.pgp_desc}`
                    : '')}
                  name="petrolium_gas_group"
                  width="75%"
                  style={{ margin: '2px 10px', borderLeft: '4px solid red !important' }}
                  color="black"
                />
              </div>
              <div className={classes.containerContract}>
                <Typography
                  variant="caption"
                  className={classes.Typo}
                >
                  Ignore VSX/CSX Check
                </Typography>
                <ContractDispatchDropdown
                  onChange={handleChange}
                  defaultValue={formData.ignore_vsx}
                  options={[{ value: 'N', label: 'No' }, { value: 'Y', label: 'Yes' }]}
                  name="ignore_vsx"
                  width="75%"
                  style={{ margin: '2px 10px', borderLeft: '4px solid red !important' }}
                  color="black"
                />
              </div>
              <div className={classes.containerContract}>
                <Typography
                  variant="caption"
                  className={classes.Typo}
                >
                  Allocation Overide
                </Typography>
                <ContractDispatchDropdown
                  onChange={handleChange}
                  options={[{ value: 'N', label: 'No' }, { value: 'Y', label: 'Yes' }]}
                  defaultValue={formData.allocation_override}
                  name="allocation_override"
                  width="75%"
                  style={{ margin: '2px 10px', borderLeft: '4px solid red !important' }}
                  color="black"
                />
              </div>
              <div className={classes.containerContract}>
                <button
                  type="button"
                  disabled
                  className={classes.disableBtn}
                >
                  Prev Step
                </button>
                <button
                  type="button"
                  onClick={() => setStepActive((step) => step + 1)}
                  className={classes.nextPrevbtn}
                >
                  Next Step
                </button>
              </div>
            </StepContent>
          </Step>
          <Step>
            <StepLabel
              optional={<Typography variant="caption">Dispatch Items</Typography>}
            >
              Step 2
            </StepLabel>
            <StepContent>
              <div style={{ display: stepActive === 1 ? 'block' : 'none' }}>
                <div>
                  <button
                    type="button"
                    onClick={addItem}
                    className={classes.dispatchbtn}
                  >
                    Add Dispatch Item
                  </button>
                </div>
                <div className={classes.containerContract}>
                  <Typography
                    variant="caption"
                    className={classes.Typo}
                  >
                    Water in Vehicle
                  </Typography>
                  <ContractDispatchDropdown
                    onChange={handleChange}
                    options={[{ value: 'No', label: 'No' }, { value: 'Yes', label: 'Yes' }]}
                    value={formData?.allocation_override || 'No'}
                    name="allocation_override"
                    width="40%"
                    style={{ margin: '2px 10px', borderLeft: '4px solid red !important' }}
                    color="black"
                  />
                  <Typography
                    variant="caption"
                    className={classes.Typo}
                  >
                    Last load gasoline
                  </Typography>
                  <ContractDispatchDropdown
                    onChange={handleChange}
                    options={[{ value: 'No', label: 'No' }, { value: 'Yes', label: 'Yes' }]}
                    value={formData?.allocation_override || 'No'}
                    name="allocation_override"
                    width="40%"
                    style={{ margin: '2px 10px', borderLeft: '4px solid red !important' }}
                    color="black"
                  />
                </div>
                <div style={{ overflowX: 'auto' }}>
                  {items.map((index: number) => (
                    <div key={index} className={classes.cross}>
                      <table>
                        <thead>
                          <tr>
                            <td>Contract ID</td>
                            <td>Supplier</td>
                            <td>Customer</td>
                            <td>Account</td>
                            <td>Destination</td>
                            <td>Product ID</td>
                            <td>Order Quantity</td>
                            <td>Unit of Measure</td>
                            <td>Freeform Text</td>
                            <td>Voucher</td>
                            <td>
                              <button
                                type="button"
                                onClick={() => removeItem(index)}
                                style={{ background: 'none', border: 'none' }}
                              >
                                x
                              </button>
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <ContractDispatchDropdown
                                onChange={handleChange}
                                options={[]}
                                name="allocation_override"
                                width="100%"
                                style={{ borderLeft: '4px solid red !important' }}
                                color="black"
                              />
                            </td>
                            <td>
                              <ContractDispatchDropdown
                                onChange={handleChange}
                                options={[]}
                                name="allocation_override"
                                width="100%"
                                style={{ borderLeft: '4px solid red !important' }}
                                color="black"
                              />
                            </td>
                            <td>
                              <ContractDispatchDropdown
                                onChange={handleChange}
                                options={[]}
                                name="allocation_override"
                                width="100%"
                                style={{ borderLeft: '4px solid red !important' }}
                                color="black"
                              />
                            </td>
                            <td>
                              <ContractDispatchDropdown
                                onChange={handleChange}
                                options={[]}
                                name="allocation_override"
                                width="100%"
                                style={{ borderLeft: '4px solid red !important' }}
                                color="black"
                              />
                            </td>
                            <td>
                              <ContractDispatchDropdown
                                onChange={handleChange}
                                options={[]}
                                name="allocation_override"
                                width="100%"
                                style={{ borderLeft: '4px solid red !important' }}
                                color="black"
                              />
                            </td>
                            <td>
                              <ContractDispatchDropdown
                                onChange={handleChange}
                                options={[]}
                                name="allocation_override"
                                width="100%"
                                style={{ borderLeft: '4px solid red !important' }}
                                color="black"
                              />
                            </td>
                            <td>
                              <ContractDispatchDropdown
                                onChange={handleChange}
                                options={[]}
                                name="allocation_override"
                                width="100%"
                                style={{ borderLeft: '4px solid red !important' }}
                                color="black"
                              />
                            </td>
                            <td>
                              <ContractDispatchDropdown
                                onChange={handleChange}
                                options={[]}
                                name="allocation_override"
                                width="50%"
                                style={{ borderLeft: '4px solid red !important' }}
                                color="black"
                              />
                            </td>
                            <td>
                              <ContractDispatchDropdown
                                onChange={handleChange}
                                options={[]}
                                name="allocation_override"
                                width="50%"
                                style={{ borderLeft: '4px solid red !important' }}
                                color="black"
                              />
                            </td>
                            <td>
                              <ContractDispatchDropdown
                                onChange={handleChange}
                                options={[]}
                                name="allocation_override"
                                width="50%"
                                style={{ borderLeft: '4px solid red !important' }}
                                color="black"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ))}
                </div>
                <div className={classes.containerContract}>
                  <button
                    type="button"
                    onClick={() => setStepActive((step) => step - 1)}
                    className={classes.nextPrevbtn}
                  >
                    Prev Step
                  </button>
                  <button
                    type="button"
                    className={classes.disableBtn}
                  >
                    Stage
                  </button>
                </div>
              </div>
            </StepContent>
          </Step>
        </Stepper>
      </div>
    </>
  );
};
export default ContractDispatchStepper;
