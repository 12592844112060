/* eslint-disable operator-linebreak */
import { Dispatch } from 'redux';
import { getDateFromString } from '../../../utils';
import Api from '../../api/index';
import {
  CLEAR_PGCR_EXPORT_FILE,
  CLEAR_PRODUCT_GROUP_CROSS_REFERENCE,
  LOAD_PRODUCT_GROUP_CROSS_REFERENCE,
  SET_PGCR_EXPORT_FILE,
  SET_PGCR_EXPORT_LINK,
  CLEAR_PGCR_EXPORT_LINK,
  LOAD_PGCR_RECORD,
  SET_PGCR_FORM_HEADER,
  SET_PGCR_RESPONSE_NOTIFICATION,
  CLEAR_PGCR_RESPONSE_NOTIFICATION,
} from '../../constants/workflows';

export const getProductGroupCrossReferenceData =
  () => async (dispatch: Dispatch) => {
    const userID = localStorage.getItem('username');
    const response = await Api.get(
      `workflow065/data?screenID=WORKFLOW-065&userID=${userID}`,
    );
    const formattedData = response.data.data.map((elem: any) => ({
      ...elem,
      effect_date: getDateFromString(elem.effect_date),
      expire_date: getDateFromString(elem.expire_date),
    }));
    dispatch({
      type: LOAD_PRODUCT_GROUP_CROSS_REFERENCE,
      payload: {
        data: formattedData,
        hiddenColumns: response.data.hiddenColumns,
        visibleColumns: response.data.visibleColumns,
      },
    });
  };

export const updateProductGroupCrossReferenceColumns =
  (columns: string[]) => async (dispatch: Dispatch) => {
    const userID = localStorage.getItem('username');
    await Api.post('workflow065/updatecolumns', { userID, columns });
    window.location.reload();
    dispatch({
      type: CLEAR_PRODUCT_GROUP_CROSS_REFERENCE,
    });
  };

export const getPGCRRecord =
  ({ termID, productGroup }: any) => async (dispatch: Dispatch) => {
    const response = await Api.get(
      `workflow065/showrecord?termID=${termID}&productGroup=${productGroup}`,
    );
    dispatch({
      type: LOAD_PGCR_RECORD,
      payload: response.data,
    });
  };

export const setPGCRFormHeader = (data: any) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_PGCR_FORM_HEADER,
    payload: data,
  });
};

export const exportProductGroupCrossReferenceColumnsFileName =
  () => async (dispatch: Dispatch) => {
    const payload = {
      export_table: 'GroupProductXRef',
      workflow_screen_id: 'WORKFLOW-065',
      filter_name: '',
      export_data: false,
      file_type: 'xlsx',
      report_type: 'GroupProductXRef',
      selected_columns: {
        term_id: 'false',
      },
      spool_all_columns: true,
    };
    const response = await Api.post('exportworkflow/spooldata', payload);
    dispatch({
      type: SET_PGCR_EXPORT_FILE,
      payload: response.data.data[0],
    });
  };

export const updatePGCR =
  (
    postproductlist: string[],
    previousproductlist: string[],
    productGroupData: any,
  ) => async (dispatch: Dispatch) => {
    const userID = localStorage.getItem('username');
    const formattedEffectDate = productGroupData.effect_date.replaceAll('/', '-');
    const formattedExpireDate = productGroupData.expire_date.replaceAll('/', '-');
    const payload = {
      formaction: 'update',
      user: userID,
      term_id: productGroupData.term_id,
      product_group_data: {
        term_id: productGroupData.term_id,
        prod_group: productGroupData.prod_group,
        effect_date: formattedEffectDate,
        expire_date: formattedExpireDate,
      },
      postproductlist,
      previousproductlist,
    };
    try {
      const response = await Api.post('workflow065/updaterecord', payload);
      if (response.status === 200) {
        dispatch({
          type: SET_PGCR_RESPONSE_NOTIFICATION,
          payload: response.data,
        });
      }
    } catch (err: any) {
      dispatch({
        type: SET_PGCR_RESPONSE_NOTIFICATION,
        payload: err.response.data,
      });
    }
  };

export const removePGCR =
  (
    previousproductlist: string[],
    productGroupData: any,
  ) => async (dispatch: Dispatch) => {
    const userID = localStorage.getItem('username');
    const formattedEffectDate = productGroupData.effect_date.replaceAll('/', '-');
    const formattedExpireDate = productGroupData.expire_date.replaceAll('/', '-');
    const payload = {
      formaction: 'delete',
      user: userID,
      term_id: productGroupData.term_id,
      product_group_data: {
        term_id: productGroupData.term_id,
        prod_group: productGroupData.prod_group,
        effect_date: formattedEffectDate,
        expire_date: formattedExpireDate,
      },
      postproductlist: [],
      previousproductlist,
    };
    try {
      const response = await Api.post('workflow065/updaterecord', payload);
      if (response.status === 200) {
        dispatch({
          type: SET_PGCR_RESPONSE_NOTIFICATION,
          payload: response.data,
        });
      }
    } catch (err: any) {
      dispatch({
        type: SET_PGCR_RESPONSE_NOTIFICATION,
        payload: err.response.data,
      });
    }
  };

export const getProductGroupCrossReferenceColumnsDownloadLink =
  (exportFile: string) => async (dispatch: Dispatch) => {
    let attempts = 0;
    const downloadFn = () => {
      if (attempts === 10) return;
      Api.get(`export/download-file?file=${exportFile}`)
        .then((res: any) => {
          if (res.status === 200) {
            dispatch({
              type: SET_PGCR_EXPORT_LINK,
              payload: res.data,
            });
          } else {
            setTimeout(downloadFn, 100);
            attempts += 1;
          }
        })
        .catch(() => {
          setTimeout(downloadFn, 100);
          attempts += 1;
        });
    };
    if (attempts === 10) return;
    downloadFn();
  };

export const clearProductGroupCrossReferenceColumnsFileName = () => ({
  type: CLEAR_PGCR_EXPORT_FILE,
});

export const clearProductGroupCrossReferenceColumnsExportLink = () => ({
  type: CLEAR_PGCR_EXPORT_LINK,
});

export const clearPGCRNotification = () => ({
  type: CLEAR_PGCR_RESPONSE_NOTIFICATION,
});
