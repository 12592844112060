import {
  LOAD_ORDER_MAINTENANCE,
  SET_SINGLE_ORDER_MAINTENANCE,
  GET_SINGLE_ORDER_MAINTENANCE,
  DISSMISS_CONFIRMATION_ALERT,
  SET_MESSAGE,
  SET_ORDER_MAINTENANCE_FILTER,
  SET_SELECTED_ORDER_MAINTENANCE_BATCH_ROWS,
  SET_ORDER_MAINTENANCE_BATCH_ALERT,
  CLEAR_ORDER_MAINTENANCE,
  LOAD_ORDER_MAINTENANCE_CHAINED_LIST,
  SET_ORDER_MAINTENANCE_RESPONSE_MSG,
} from '../../constants/workflows';

const INITIAL_STATE = {
  data: [],
  totalRecords: 0,
  totalRecordsWithSearch: 0,
  allTableColumns: [],
  columnsLabels: [],
  currentRecord: [],
  hiddenColumns: {},
  visibleColumns: {},
  selectedRecord: null,
  formData: {},
  formHeader: {},
  exportFile: '',
  exportLink: '',
  responseNotification: {},
  openConfirmationAlert: false,
  confirmationAlert: '',
  filterQuery: '',
  crudx: {},
  batchMsg: false,
  chainedList: [],
  orderData: [],
  responseMsg: [],
};

const columnsLabels:{ [key: string]: string } = {
  RecType: 'Rec Type',
  OrderStatus: 'Order Status',
  OrderNo: 'Order No',
  SupplierNo: 'Supplier Number',
  TermID: 'Term ID',
  CustNo: 'Customer Number',
  AcctNo: 'Account Number',
  DestinationNo: 'Destination Number',
  OrderComments: 'Order Comments',
  ScheduledDate: 'Scheduled Date',
  OrderDate: 'Order Date',
  PONumber: 'PO Number',
  ReleaseNo: 'Release Number',
  TotalQty: 'Total Quantity',
  DriverNo: 'Driver Number',
  CarrierNo: 'Carrier Number',
  carrier: 'Carrier',
  TruckNo: 'Truck Number',
  Trailer1: 'Trailer1',
  Trailer2: 'Trailer2',
  Shift: 'Shift',
  DeliverySequenceNo: 'Delivery Sequence Number',
  DisplayComments: 'Display Comments',
  DestSplcCode: 'DestSplcCode',
  LoadSequenceNo: 'Load Sequence Number',
  Processed: 'Processed',
  LoadStartDate: 'Load Start Date',
  LoadStopDate: 'Load Stop Date',
  SupplierRackNo: 'Supplier Rack No',
  PetroexConsignee: 'Petroex Consignee',
  TruckDriver: 'Truck Driver',
  HostOrderNo: 'Host Order Number',
  HostPoNo: 'Host Po Number',
  LimitOrder: 'Limit Order',
  VerifyCarrier: 'Verify Carrier',
  TransID: 'Trans ID',
  ContractNumber: 'Contract Number',
  ExpirationDate: 'Expiration Date',
  TareWeight: 'Tare Weight',
  TransSequenceNo: 'Trans Sequence Number',
  DeliveryDate: 'Delivery Date',
  LoadConfirmed: 'Load Confirmed',
  ValidatePidxConsignee: 'ValidatePidxConsignee',
  ShipmentNo: 'Shipment Number',
  ShipmentDate: 'Shipment Date',
  ETADate: 'ETA Date',
  Freight: 'Freight',
  DeleteFromPicklist: 'Delete From Picklist',
  DutyToNumber: 'Duty To Number',
  BadgeNumber: 'Badge Number',
  MOT: 'MOT',
  TransRefNo: 'Trans Ref Number',
  UserID: 'User ID',
  DateAdded: 'Date Added',
  DateLstMod: 'Date Last Modified',
  DispatchStatus: 'Dispatch Status',
  ProcessException: 'Process Exception',
  ExternalCustomerID: 'External Customer ID',
  GrossWeight: 'Gross Weight',
  DateAuthorized: 'Date Authorized',
  TrainID: 'Train ID',
  ContainerID: 'Container ID',
  seal_processing: 'Seal Processing',
  ShipmentOrigin: 'Shipment Origin',
  EmptyLoad: 'Empty Load',
  ToleranceCheck: 'Tolerance Check',
  INCOTerms: 'INCOTerms',
  TransportArrangement: 'Transport Arrangement',
  DestinationType: 'Destination Type',
  RouteCode: 'Route Code',
  MarkAsComplete: 'Mark As Complete',
  ErrorDescription: 'Error Description',
  OrderItemNo: 'Order Item Number',
  Trailer: 'Trailer',
  Compartment: 'Compartment',
  ProdID: 'Prod ID',
  OrderQty: 'Order Quantity',
  Retained: 'Retained',
  Loaded: 'Loaded',
  Returned: 'Returned',
  TTStatus: 'TTStatus',
  LocationNo: 'LocationNo',
  ERPHandlingType: 'ERP Handling Type',
  CPEAnnualNo: 'CPE Annual Number',
  Tank: 'Tank',
  BatchDate: 'Batch Date',
  BatchTime: 'Batch Time',
  BatchNo: 'Batch Number',
};
const OrderMaintenanceReducer = (state = INITIAL_STATE, { type, payload }: any) => {
  switch (type) {
    case LOAD_ORDER_MAINTENANCE: {
      return {
        ...state,
        data: payload.data,
        totalRecords: payload.totalRecords,
        visibleColumns: payload.visibleColumns,
        hiddenColumns: payload.hiddenColumns,
        allTableColumns: payload.allTableColumns,
        columnsLabels,
        crudx: payload.crudx,
      };
    }
    case LOAD_ORDER_MAINTENANCE_CHAINED_LIST: {
      return {
        ...state,
        chainedList: payload.chainedList,
      };
    }
    case CLEAR_ORDER_MAINTENANCE: {
      return {
        ...state,
        data: [],
        totalRecords: 0,
        visibleColumns: {},
      };
    }
    case SET_SINGLE_ORDER_MAINTENANCE: {
      return {
        ...state,
        currentRecord: payload.tableData,
        orderData: payload.record,
      };
    }
    case SET_ORDER_MAINTENANCE_RESPONSE_MSG: {
      return {
        ...state,
        responseMsg: payload.responseMsg,
      };
    }
    case SET_MESSAGE: {
      return {
        ...state,
        confirmationAlert: payload,
        openConfirmationAlert: true,
      };
    }
    case SET_ORDER_MAINTENANCE_FILTER: {
      return {
        ...state,
        filterQuery: payload.filterQuery,
      };
    }
    case DISSMISS_CONFIRMATION_ALERT: {
      return {
        ...state,
        confirmationAlert: '',
        openConfirmationAlert: false,
      };
    }
    case SET_SELECTED_ORDER_MAINTENANCE_BATCH_ROWS: {
      return {
        ...state,
        selectedRecord: payload,
      };
    }
    case SET_ORDER_MAINTENANCE_BATCH_ALERT: {
      return {
        ...state,
        batchMsg: payload,
      };
    }
    default:
      return state;
  }
};

export default OrderMaintenanceReducer;
