import React, { useEffect, useState } from 'react';

// Librairies
import { InputBase } from '@mui/material';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';

// Components
import SubHeader from '../../../components/subHeader';
import TmsHeader from '../../../components/header';
import OptionsButton from '../../../components/optionsButton';
import DriversInFacilityOptions from '../../../components/DriversInFacilityOptions';
import DriversInFacilityTable from '../../../components/DriversInFacilityTable';
import DriversInFacilityDialog from '../../../components/DriversInFacilityDialog';

// Styles
import { useStyles } from './style';

// Actions
import { getScreensList } from '../../../redux/actions/ScreenActions';
import {
  getDriversInFacilityData,
  getDriversList,
} from '../../../redux/actions/workflows/driversInFacility';

// Utils
import { searchRecords } from '../../../utils';

const tableHeader: { label: string; key: string }[] = [
  { label: 'Driver', key: 'driver_no' },
  { label: 'Carrier', key: 'carrier' },
  { label: 'Entry Date', key: 'entrance_date' },
  { label: 'Stage Date', key: 'staged_date' },
  { label: 'Load Start Date', key: 'load_start_date' },
  { label: 'Driver Status', key: 'status' },
  { label: 'Bay Status', key: 'baystatus' },
];

const DriversInFacility: React.FC = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [orderBy, setOrderBy] = React.useState('');
  const [order, setOrder] = React.useState('');
  const [driversInFacilyDialogOpen, setDriversInFacilyDialogOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const dispatch = useDispatch();
  const { reclist, modalDriversList, modalCarriersList } = useSelector(
    (state: any) => state.Workflows.DriversInFacility,
  );
  const [tableData, setTableData] = useState<any[]>([]);

  useEffect(() => {
    if (searchText) {
      const filtredData = searchRecords(searchText, reclist, tableHeader);
      setTableData(filtredData);
    } else {
      setTableData(reclist);
    }
  }, [searchText, reclist]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const handleSortTable = (key: string) => {
    const orderType = !order || order === 'desc' ? 'asc' : 'desc';
    setOrderBy(key);
    setOrder(orderType);
  };

  useEffect(() => {
    dispatch(getScreensList());
    dispatch(getDriversInFacilityData());
    dispatch(getDriversList());
  }, []);

  const handleOptionClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const openDriversInFacilityOptionsDialog = () => {
    setDriversInFacilyDialogOpen(true);
  };

  const closeDriversInFacilityOptionsDialog = () => {
    setDriversInFacilyDialogOpen(false);
  };

  return (
    <>
      <TmsHeader />
      <SubHeader title="Drivers in Facility">
        <OptionsButton handleClick={handleOptionClick} />
        <DriversInFacilityOptions
          anchorEl={anchorEl}
          onClose={handleCloseMenu}
          openDriversInFacilityOptionsDialog={
            openDriversInFacilityOptionsDialog
          }
        />
      </SubHeader>
      <div
        className={clsx('flexStartSpaceBetweenRow', classes.subHeaderContainer)}
      >
        <div />
        <div className="flexRow">
          <InputBase
            className={classes.input}
            placeholder="Search"
            value={searchText}
            onChange={handleSearchChange}
          />
          <span className={classes.helperText}>
            Viewing
            {' '}
            {tableData?.length || 0}
            {' '}
            of
            {' '}
            {reclist?.length || 0}
          </span>
        </div>
      </div>
      <DriversInFacilityTable
        handleClickRow={() => null}
        openDriversInFacilityDialog={openDriversInFacilityOptionsDialog}
        tableData={tableData}
        tableHeader={tableHeader}
        searchText={searchText}
        sortTable={handleSortTable}
        orderBy={orderBy}
        order={order}
      />
      <DriversInFacilityDialog
        onClose={closeDriversInFacilityOptionsDialog}
        open={driversInFacilyDialogOpen}
        modalDriversList={modalDriversList}
        modalCarriersList={modalCarriersList}
      />
    </>
  );
};

export default DriversInFacility;
