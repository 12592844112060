/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
} from '@mui/material';
import React from 'react';
import { useStyles } from './style';
import SupplierToRecord from './supplierToRecord';

const BulkTransferTankToSupplierStep = ({
  productsData,
  values,
  handleInputChange,
  handleSelectChange,
  valuesToSend,
  stateValues,
  handleInputProductChange,
  screenID,
  visible,
  prefixProducts,
  resetPrefixProducts,
  prefix,
  products,
  addProducts,
  removeFormFields,
  prefixFromSupplier,
  review,
  transactionMode,
}: any) => {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.header}>
        <Button
          variant="contained"
          color="success"
          size="small"
          sx={{ textTransform: 'none' }}
          onClick={addProducts}
          disabled={products?.length}
        >
          Add New Detail
        </Button>
      </div>
      {products.map((product: any, key: any) => (
        <div key={product.id}>
          <SupplierToRecord
            values={values}
            product={product}
            productsData={productsData}
            products={products}
            handleInputChange={handleInputChange}
            handleSelectChange={handleSelectChange}
            valuesToSend={valuesToSend}
            stateValues={stateValues}
            handleInputProductChange={handleInputProductChange}
            screenID={screenID}
            prefixProducts={prefixProducts}
            resetPrefixProducts={resetPrefixProducts}
            prefix={prefix}
            visible={visible}
            removeFormFields={removeFormFields}
            itemKey={product?.id}
            prefixFromSupplier={`${JSON.stringify(valuesToSend?.[prefixFromSupplier])}`}
            review={review}
            transactionMode={transactionMode}
          />
        </div>
      ))}
    </div>
  );
};
export default BulkTransferTankToSupplierStep;
