import { Dispatch } from 'redux';
import axios from 'axios';
import Api from '../../api/index';
import {
  LOAD_ORDER_MAINTENANCE,
  SET_SINGLE_ORDER_MAINTENANCE,
  SET_ORDER_MAINTENANCE_BATCH_ALERT,
  DISSMISS_CONFIRMATION_ALERT,
  SET_ORDER_MAINTENANCE_FILTER,
  LOAD_ORDER_MAINTENANCE_CHAINED_LIST,
  SET_ORDER_MAINTENANCE_RESPONSE_MSG,
} from '../../constants/workflows';
import { START_LOADING, STOP_LOADING } from '../../constants/Screens';

export const getOrderMaintenanceData = (where = '') => async (dispatch: Dispatch) => {
  dispatch({ type: START_LOADING });
  const userID = localStorage.getItem('username');
  const response = await Api.get(
    `workflow161/table?screenID=WORKFLOW-161&groupID=Admin&userID=${userID}&where=${where}`,
  );
  dispatch({
    type: LOAD_ORDER_MAINTENANCE,
    payload: {
      data: response.data.rec,
      totalRecords: response.data.rec.length,
      visibleColumns: response.data.visibleColumns,
      hiddenColumns: response.data.hiddenColumns,
      allTableColumns: response.data.allTableColumns,
      crudx: response.data.crudxRec,
    },
  });
  dispatch({ type: STOP_LOADING });
};

export const getOrderMaintenanceChainedData = (type:any, chained:any) => async (dispatch: Dispatch) => {
  const userID = localStorage.getItem('username');
  const response = await Api.get(
    `workflow161/chained_record?screenID=WORKFLOW-161
    &groupID=Admin&userID=${userID}`, {
      params: {
        chained: JSON.stringify(chained).toString(),
        type,
      },
      headers: {
        accept: '*/*',
      },
    },
  );
  dispatch({
    type: LOAD_ORDER_MAINTENANCE_CHAINED_LIST,
    payload: {
      chainedList: response.data,
    },
  });
};

export const removeOrderMaintenanceRecord = (selectedRecord: any) => async (dispatch: Dispatch) => {
  const userID = localStorage.getItem('username');

  const recList = selectedRecord.map((item: any) => ({
    recType: item.RecType,
    supplierNo: item.SupplierNo,
    orderNo: item.OrderNo,
  }));

  const response = await Api.delete(
    `workflow161/delete?screenID=WORKFLOW-161&groupID=Admin&userID=${userID}&batchAction=maint_batch_delete`,
    {
      params: {
        recList: JSON.stringify(recList).toString(),
      },
      headers: {
        accept: '*/*',
      },
    },
  );
  dispatch({
    type: SET_ORDER_MAINTENANCE_BATCH_ALERT,
    payload: response,
  });
};

export const
  submitOrderMaintenanceTransaction = (valuesToSend:any) => async (dispatch: Dispatch) => {
    dispatch({ type: START_LOADING });
    const userID = localStorage.getItem('username');
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}workflow161/store_rec?screenID=WORKFLOW-161`,
      { userID, valuesToSend },
    );
    dispatch({
      type: SET_ORDER_MAINTENANCE_RESPONSE_MSG,
      payload: {
        responseMsg: res,
      },
    });
    dispatch({ type: STOP_LOADING });
  };

export const getSingleOrderMaintenanceRecord = (keyRec:any) => async (dispatch: Dispatch) => {
  const res = await axios.get(`${process.env.REACT_APP_API_URL}workflow161/show_record?screenID=WORKFLOW-161`, {
    params: {
      keyRec: keyRec !== undefined ? JSON.stringify(keyRec).toString() : false,
    },
  });
  dispatch({
    type: SET_SINGLE_ORDER_MAINTENANCE,
    payload: {
      tableData: res.data.data[0],
      record: res.data.data[1],
    },
  });
};

export const showFilterText = (filterQuery: any) => ({
  type: SET_ORDER_MAINTENANCE_FILTER,
  payload: {
    filterQuery,
  },
});
export const dissmissConfirmationAlert = () => async (dispatch: any) => {
  dispatch({ type: DISSMISS_CONFIRMATION_ALERT, payload: false });
};

export const
  updateOrderMaintenanceColumns = (
    columns: { [key: string]: string[] },
  ) => async (dispatch: Dispatch) => {
    const userID = localStorage.getItem('username');
    await Api.post('workflow161/updatecolumns', { userID, columns });
    window.location.reload();
  };
