/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';

// Librairies
import { useDispatch, useSelector } from 'react-redux';

// Components
import {
  Alert, Snackbar, AlertTitle, IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { useNavigate } from 'react-router-dom';
import TmsHeader from '../../../components/header';
import SubHeader from '../../../components/subHeader';
import Loader from '../../../components/SystemUtilities/Loader';
import UtilityTable from '../../../components/SystemUtilities/WorkflowTable';
import { useWorkflowState } from '../../../hooks/useWorkflowState';
import WorkflowTableUtils from '../../../components/WorkflowTableUtils';
import { savedResponsedata } from '../../../redux/actions/systemUtilities/systemUtilities';
import { viewUtility } from '../../../redux/actions/systemUtilities/viewUtility';
import { getSystemUtilityQueue } from '../../../redux/actions/systemUtilities/systemUtilityQueue';

const tableHeader = {
  submit_date: 'Submit Date',
  sequence_no: 'Reference Number',
  status: 'Status',
  rpt_description: 'Description',
  rpt_parameters: 'Parameters',
  rpt_comment: 'Comment',
};

const searchHeader = [
  { label: 'Submit Date', key: 'submit_date' },
  { label: 'Reference Number', key: 'sequence_no' },
  { label: 'Status', key: 'status' },
  { label: 'Description', key: 'rpt_description' },
  { label: 'Parameters', key: 'rpt_parameters' },
  { label: 'Comment', key: 'rpt_comment' },
];

const SystemUtilityQueue: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openConfirmationAlert, setOpenConfirmationAlert] = useState(true);
  const [play, setPlay] = useState(false);
  const { data } = useSelector(
    (state: any) => state?.SystemUtilities?.SystemUtilityQueue,
  );
  const { savedResponse } = useSelector(
    (state: any) => state?.SystemUtilities?.SystemUtilities,
  );
  const {
    recordsToShow,
    tableState,
    handlePaginationChange,
    handleSearchChange,
    handleSelectChange,
    handleSortTable,
  } = useWorkflowState(searchHeader, data, getSystemUtilityQueue);
  const handleRowClick = (row: any) => {
    dispatch(viewUtility(row?.sequence_no || '', row?.rpt_description));
    navigate('/workflow/view-utility');
  };
  const handleClose = () => {
    setOpenConfirmationAlert(false);
    dispatch(savedResponsedata({}));
  };

  return (
    <>
      <TmsHeader />
      <SubHeader title="System Utility Queue">
        <Loader getWorkflowData={getSystemUtilityQueue} />
      </SubHeader>
      <WorkflowTableUtils
        handleSearchChange={handleSearchChange}
        handleSelectChange={handleSelectChange}
        recordsNumber={tableState.recordsNumber}
        searchText={tableState.searchText}
      />
      <UtilityTable
        handleClickRow={(e: any) => handleRowClick(e)}
        visibleColumns={tableHeader}
        allDataCount={data.length}
        handlePaginationChange={handlePaginationChange}
        recordsToShow={recordsToShow}
        tableHeader={tableHeader}
        tableState={tableState}
        order={tableState.order}
        orderBy={tableState.orderBy}
        searchText={tableState.searchText}
        sortTable={handleSortTable}
      />
      {Object.keys(savedResponse).length !== 0 ? (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={openConfirmationAlert}
          onClose={handleClose}
          autoHideDuration={!play ? 6000 : null}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          ClickAwayListenerProps={{ onClickAway: () => {} }}
        >
          <Alert
            severity={savedResponse?.type}
            action={(
              <>
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setPlay((pause) => !pause);
                  }}
                >
                  {play ? <PlayArrowIcon fontSize="inherit" /> : <PauseIcon fontSize="inherit" />}
                </IconButton>
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              </>
            )}
          >
            <AlertTitle>{savedResponse?.title}</AlertTitle>
            {savedResponse?.text}
          </Alert>
        </Snackbar>
      ) : '' }
    </>
  );
};

export default SystemUtilityQueue;
