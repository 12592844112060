/* eslint-disable react/require-default-props */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-fragments */
/* eslint-disable no-nested-ternary */
import React from 'react';

// Librairies
import {
  ButtonBase,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import {
  Settings,
  ArrowDropDown,
  ArrowDropUp,
  UnfoldMore,
} from '@mui/icons-material';

// Styles
import { useStyles } from './style';

interface IProps {
  handleClickRow: (row: any) => void;
  tableData: any[];
  tableHeader: { label: string; key: string }[];
  searchText?: string;
  orderBy?: string;
  order?: string;
  sortTable?: (key: string) => void;
}

const sortedTable = (data: any[], orderBy?: string, order?: string) => {
  if (!orderBy || !order) return data;
  return data.sort((a, b) => {
    const orderResult: number = String(a[orderBy]).localeCompare(b[orderBy]);
    return order === 'asc' ? orderResult : -orderResult;
  });
};

const PersonnelInFacilityTable: React.FC<IProps> = ({
  handleClickRow,
  tableData,
  tableHeader,
  searchText,
  order,
  orderBy,
  sortTable,
}) => {
  const classes = useStyles();

  const handleSortClick = (key: string) => (event: React.MouseEvent<unknown>) => {
    if (sortTable) {
      sortTable(key);
    }
  };

  return (
    <div className={classes.root}>
      <Table classes={{ root: classes.tableRoot }}>
        <TableHead>
          <TableRow classes={{ root: classes.headerRow }}>
            <TableCell classes={{ root: classes.headerCell }}> </TableCell>
            {tableHeader.map((h) => (
              <TableCell classes={{ root: classes.headerCell }} key={h.key}>
                <TableSortLabel
                  active={orderBy === h.key}
                  onClick={handleSortClick(h.key)}
                  IconComponent={() => (order === 'asc' && orderBy === h.key ? (
                    <ArrowDropUp />
                  ) : order === 'desc' && orderBy === h.key ? (
                    <ArrowDropDown />
                  ) : (
                    <UnfoldMore />
                  ))}
                  classes={{ root: classes.sortLabel }}
                >
                  {h.label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {!tableData.length && (
            <TableRow className={classes.tableRow}>
              <TableCell
                classes={{ root: classes.bodyCellEmpty }}
                style={{ textAlign: 'center' }}
                colSpan={tableHeader.length + 1}
              >
                {searchText
                  ? 'No matching records found'
                  : 'No data available in table'}
              </TableCell>
            </TableRow>
          )}
          {sortedTable(tableData, orderBy, order)?.map((record, index) => (
            <React.Fragment key={index}>
              <TableRow className={classes.tableRow}>
                <TableCell classes={{ root: classes.bodyCell }}>
                  <div className={classes.actionsWrapper}>
                    <ButtonBase
                      className={classes.actionButton}
                      onClick={() => handleClickRow(record)}
                    >
                      <Settings fontSize="small" />
                    </ButtonBase>
                  </div>
                </TableCell>
                {tableHeader.map((field) => (
                  <TableCell
                    classes={{ root: classes.bodyCell }}
                    key={field.key}
                  >
                    {record[field.key]}
                  </TableCell>
                ))}
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default React.memo(PersonnelInFacilityTable);
