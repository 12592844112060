import React, { useEffect, useState } from 'react';
import WorkflowTable from '../../../components/WorkflowTable';
import WorkflowTableUtils from '../../../components/WorkflowTableUtils';
import { useWorkflowState } from '../../../hooks/useWorkflowState';

// Librairies

// Components

// Styles
import { useStyles } from './style';

// Actions

// Utils
const tableHeader = {
  'Field ID': 'Field ID',
  Default: 'Default',
  Mask: 'Mask',
  'Read Only': 'Read Only',
  Hide: 'Hide',
  Required: 'Required',
  Alpha: 'Alpha',
  Numeric: 'Numeric',
  'No-Space': 'No-Space',
  'No-Negative': 'No-Negative',
  'E-Mail': 'E-Mail',
};
const headerList = [
  { key: 'Field ID', label: 'Field ID' },
  { key: 'Default', label: 'Default' },
  { key: 'Mask', label: 'Mask' },
  { key: 'Read Only', label: 'Read Only' },
  { key: 'Hide', label: 'Hide' },
  { key: 'Required', label: 'Required' },
  { key: 'Alpha', label: 'Alpha' },
  { key: 'Numeric', label: 'Numeric' },
  { key: 'No-Space', label: 'No-Space' },
  { key: 'No-Negative', label: 'No-Negative' },
  { key: 'E-Mail', label: 'E-Mail' },
];

interface IConfirmFormProps {
    state: any;
  }

const ConfirmForm: React.FC<IConfirmFormProps> = () => {
  const classes = useStyles();
  const {
    recordsToShow,
    tableState,
    handlePaginationChange,
    handleSearchChange,
    handleSortTable,
    handleOptionClick,
    handleCloseMenu,
    handleSelectChange,
  } = useWorkflowState(headerList, [], null, true);

  return (
    <div>
      <WorkflowTableUtils
        handleSearchChange={handleSearchChange}
        handleSelectChange={handleSelectChange}
        recordsNumber={tableState.recordsNumber}
        searchText={tableState.searchText}
      />
      <WorkflowTable
        handleClickRow={() => null}
        visibleColumns={tableHeader}
        allDataCount={[].length}
        handlePaginationChange={handlePaginationChange}
        recordsToShow={recordsToShow}
        tableHeader={tableHeader}
        tableState={tableState}
        order={tableState.order}
        orderBy={tableState.orderBy}
        searchText={tableState.searchText}
        sortTable={handleSortTable}
      />
    </div>
  );
};

export default ConfirmForm;
