/* eslint-disable no-tabs */
/* eslint-disable max-len */
const style = `
a.smartlink.default { color: #0027ff; box-shadow: inset 0 0 0 white, inset 0 -1px 0 #0027ff78; }
		a.smartlink.black { color: #000; box-shadow: inset 0 0 0 white, inset 0 -1px 0 #0009; }
		a.smartlink.red { color: #f00; box-shadow: inset 0 0 0 white, inset 0 -1px 0 #f009; }
		a.smartlink {
			text-transform: uppercase;
			text-align: center;
			font-family: Courier;
			font-style: italic;
			padding: 3px 2px 0 1px;
			text-decoration: none;
		}
		a.smartlink:hover { box-shadow: none; }
		i.smartlinkicon { cursor: pointer; margin-left: 10px; padding: 0 10px; margin-right: -10px; float: right; }
		i.smartlinkicon:hover { color: blue; }
		a.smartlink::before,
		a.smartlink::after { opacity: 0; -webkit-transition: opacity 0.2s, -webkit-transform 0.2s; transition: opacity 0.2s, transform 0.2s; }
		a.smartlink::before { content: '['; -webkit-transform: translateX(-100%); transform: translateX(-100%); }
		a.smartlink::after { content: ']'; -webkit-transform: translateX(100%); transform: translateX(100%); }
		a.smartlink:hover::before,
		a.smartlink:hover::after { opacity: 1; -webkit-transform: translateX(0); transform: translateX(0); }
    link: { margin-left : 0.5rem }
`;
export default style;
