/* eslint-disable max-len */
import { Dispatch } from 'redux';
import Api from '../../api/index';
import { START_LOADING, STOP_LOADING } from '../../constants/Screens';
import {
  LOAD_PRESET_CARDS_DATA, LOAD_RACK_STATUS_BAY_RESPONSE, LOAD_RACK_STATUS_TABLE, PROCESS_RACK_STATUS, SET_BAY_ID_FOR_DETAIL, UPDATE_RCU_SCREEN,
} from '../../constants/workflows';

export const getRackStatus = () => async (dispatch: Dispatch) => {
  try {
    const response = await Api.get('workflow060/data');
    const data = response.data.data.filter((item: any) => typeof (item) !== 'string');
    dispatch({
      type: LOAD_RACK_STATUS_TABLE,
      payload: data,
    });
  } catch (error) {
    console.error('getTankStatuses error', error);
  }
};

export const sendCommandRackStatus = (packet: any) => async (dispatch: Dispatch) => {
  try {
    // termid is unavailable on the current user, so it's hardcored for now
    // HHL PLC Override button only sends this on the demo site
    const response = await Api.post('synajax/procajax', packet);
    dispatch({
      type: LOAD_RACK_STATUS_BAY_RESPONSE,
      payload: response.data[1] || '',
    });
  } catch (error) {
    console.error('TankStatus sendOptionsButtonQuery error', error);
  }
};

export const savedResponseClear = () => async (dispatch: Dispatch) => {
  try {
    // termid is unavailable on the current user, so it's hardcored for now
    // HHL PLC Override button only sends this on the demo site
    const savedResponse = {
      msgtype: '',
      msg: '',
    };
    dispatch({
      type: LOAD_RACK_STATUS_BAY_RESPONSE,
      payload: savedResponse,
    });
  } catch (error) {
    console.error('TankStatus sendOptionsButtonQuery error', error);
  }
};
export const setCurrentBay = (bayID: string, history: any) => async (dispatch: Dispatch) => {
  try {
    dispatch({
      type: SET_BAY_ID_FOR_DETAIL,
      payload: bayID,
    });
  } catch (error) {
    console.error('setting bay for detail error', error);
  }
};

export const initRackStatusDetail = (bayID: string) => async (dispatch: Dispatch) => {
  try {
    // @ temporary stuff will uncomment when autorefresh implemented
    // dispatch({ type: START_LOADING });
    const response = await Api.get(`workflow060/RackStatusDetail?bayID=${bayID}&termID=B344&lang=ar-AE`);
    dispatch({
      type: LOAD_PRESET_CARDS_DATA,
      payload: response.data.data,
    });
    dispatch({ type: STOP_LOADING });
  } catch (error) {
    console.error('initRackStatusDetail error', error);
  }
};

export const synajaxRackStatusDetail = (bayID: string) => async (dispatch: Dispatch) => {
  try {
    // @ temporary stuff will uncomment when autorefresh implemented
    // dispatch({ type: START_LOADING });
    const response = await Api.post('synajax/procajax', {
      queueid: 'PROG',
      reqid: 'id-0y4y1paqcgltg',
      data: {
        cmd: 'facilitystatusdetail',
        parm: {
          bayid: bayID,
          data: '',
          termid: 'B344',
        },
      },
    });
    dispatch({
      type: PROCESS_RACK_STATUS,
      payload: response.data,
    });
    // dispatch({ type: STOP_LOADING });
  } catch (error) {
    console.error('initRackStatusDetail error', error);
  }
};

export const RCUDittoKeySend = (payload: any) => async (dispatch: Dispatch) => {
  try {
    const response = await Api.post('synajax/procajax', payload);
    dispatch({
      type: UPDATE_RCU_SCREEN,
      payload: response.data.screendata,
    });
  } catch (error) {
    console.error('initRackStatusDetail error', error);
  }
};

export const RCUDittoUpdate = (dittoBayID: string) => async (dispatch: Dispatch) => {
  try {
    const response = await Api.post('synajax/procajax', {
      queueid: `Q${dittoBayID}`,
      reqid: `id-0y4y${Math.random() * 10}paqcgltg`,
      data: {
        cmd: 'ditto',
        parm: {
          bay: dittoBayID,
          screenid: dittoBayID,
          termid: 'B344',
        },
      },
    });
    dispatch({
      type: UPDATE_RCU_SCREEN,
      payload: response.data[0].screendata,
    });
  } catch (error) {
    console.error('RCU UPDATE error', error);
  }
};
