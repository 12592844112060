import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  tableHead: {
    fontWeight: 'bold',
    color: '#000',
    paddingRight: 12,
    paddingLeft: 12,
    paddingTop: 4,
    paddingBottom: 4,
  },
  tableHeadOptional: {
    fontWeight: 'bold',
    color: '#000',
    backgroundColor: '#c4deff',
    paddingRight: 12,
    paddingLeft: 12,
    paddingTop: 4,
    paddingBottom: 4,
  },
  tableRow: {
    '&:last-child td, &:last-child th': { border: 0 },
    '&:hover': {
      background: '#F7EBD0',
    },
    whiteSpace: 'nowrap',
    marginBottom: 4,
  },
  tableCell: {
    paddingRight: 12,
    paddingLeft: 12,
    paddingTop: 4,
    paddingBottom: 4,
  },
  popoverLabel: {
    color: 'blue',
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  popoverHeadStyle: {
    paddingTop: 4,
    paddingBottom: 4,
    paddingRight: 18,
    paddingLeft: 18,
    backgroundColor: 'lightgrey',
    alignItems: 'center',
    justifyContent: 'space-between',
    alignContent: 'center',
    flexDirection: 'row',
    display: 'flex',
  },
  popoverBodyStyle: {
    padding: 18,
    backgroundColor: 'white',
    alignItems: 'center',
    justifyContent: 'center',
  },
  viewingCountLabel: {
    width: 'fit-content',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#E8E8E8',
    padding: 8,
  },
  optionalCheckboxDiv: {
    minWidth: '30%',
    gap: 15,
    display: 'flex',
    alignItems: 'center',
    margin: '0px 10px',
  },
});
