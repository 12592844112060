/* eslint-disable implicit-arrow-linebreak */
import React from 'react';

// Components
import { Droppable } from 'react-beautiful-dnd';

// Styles
import Column from './Column';

interface IProps {
  columns: { [key: string]: { label: string; visible: boolean } };
  columnsLabels: { [key: string]: string };
  droppableId: string;
  selectionCount: number;
  toggleSelection: (columnId: string) => void;
  toggleSelectionInGroup: (columnId: string) => void;
  multiSelectTo: (columnId: string) => void;
  selectedColumns: string[];
  draggingColumnId: string;
}

const List: React.FC<IProps> = ({
  columns,
  columnsLabels,
  droppableId,
  selectedColumns,
  draggingColumnId,
  ...props
}) => (
  <Droppable droppableId={droppableId}>
    {(provided, snapshot) => (
      <div
        {...provided.droppableProps}
        ref={provided.innerRef}
        className="flexColumnAlignCenter fullHeight"
        style={{
          background: snapshot.isDraggingOver ? '#fff' : '#fff',
          overflow: 'auto',
        }}
      >
        {Object.keys(columns).map((field: string, index) => {
          const isSelected = selectedColumns.includes(field);
          const isGhosting: boolean = isSelected
            && Boolean(draggingColumnId)
            && draggingColumnId !== field;
          return (
            columns[field]?.visible && (
              <Column
                columnsLabels={columnsLabels}
                field={field}
                index={index}
                key={field}
                isGhosting={isGhosting}
                isSelected={isSelected}
                draggingColumnId={draggingColumnId}
                {...props}
              />
            )
          );
        })}
        {provided.placeholder}
      </div>
    )}
  </Droppable>
);

export default List;
