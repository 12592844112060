/* eslint-disable react/no-array-index-key */
/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
import React from 'react';

// Librairies
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import clsx from 'clsx';
import { ArrowDropUp, ArrowDropDown, UnfoldMore } from '@mui/icons-material';

// Styles
import { useStyles } from './style';

// components
import Pagination from '../Pagination';
import TableItemsText from '../TableItemsText';
import { sortedTable, getCellBackgroundColor } from '../../utils';

interface IProps {
  handleClickRow: (row?: any) => void;
  allDataCount: any;
  searchText?: string;
  recordsToShow: number;
  visibleColumns?: any;
  tableState: any;
  handlePaginationChange: (event: any, targetPage: any) => void;
  tableHeader: { [key: string]: string };
  orderBy?: string;
  order?: string;
  noPagination?: boolean;
  sortTable?: (key: string) => void;
  style?: React.CSSProperties;
  loading?: boolean;
}

const WorkflowTable: React.FC<IProps> = ({
  handleClickRow,
  allDataCount,
  searchText,
  recordsToShow,
  visibleColumns,
  tableState,
  handlePaginationChange,
  tableHeader,
  orderBy,
  order,
  sortTable,
  noPagination,
  style,
  loading,
}) => {
  const classes = useStyles();
  const { currentPage, searchedDataCount, tableData } = tableState;
  const countPages = Math.ceil(
    (searchText ? searchedDataCount : allDataCount) / recordsToShow,
  );

  const handleSortClick = (key: string) => (event: React.MouseEvent<unknown>) => {
    if (sortTable) {
      sortTable(key);
    }
  };

  return (
    <>
      <div className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table classes={{ root: classes.tableRoot }} style={style}>
            <TableHead data-testid="workflow-table-header">
              <TableRow classes={{ root: classes.headerRow }}>
                {Object.keys(visibleColumns).map((key) => (
                  <TableCell classes={{ root: classes.headerCell }} key={key}>
                    <TableSortLabel
                      active={orderBy === key}
                      onClick={handleSortClick(key)}
                      IconComponent={() => (order === 'asc' && orderBy === key ? (
                        <ArrowDropUp />
                      ) : order === 'desc' && orderBy === key ? (
                        <ArrowDropDown />
                      ) : (
                        <UnfoldMore />
                      ))}
                      classes={{ root: classes.sortLabel }}
                    >
                      {tableHeader[key]}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody data-testid="workflow-table-body">
              {!tableData.length && (
                <TableRow className={classes.tableRow}>
                  <TableCell
                    classes={{ root: classes.bodyCellEmpty }}
                    style={{ textAlign: 'center' }}
                    colSpan={Object.keys(tableHeader).length}
                  >
                    {searchText
                      ? 'No matching records found'
                      : 'No data available in table'}
                  </TableCell>
                </TableRow>
              )}
              {sortedTable(tableData, orderBy, order)?.map((row, index) => (
                <TableRow
                  data-testid={`workflow-table-row-${index}`}
                  onClick={() => handleClickRow(row)}
                  className={classes.tableRow}
                  key={index}
                >
                  {Object.keys(visibleColumns).map((field) => (
                    <TableCell
                      data-testid={`workflow-table-row-${index}-cell-${field}`}
                      classes={{ root: classes.bodyCell }}
                      key={field}
                      style={{
                        backgroundColor: getCellBackgroundColor(
                          orderBy,
                          field,
                          index,
                        ),
                      }}
                    >
                      {row[field]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
      {!noPagination ? (
        <div className={clsx(classes.footerWrapper, 'flexRowAlignCenter')}>
          {!tableData.length ? (
            <div className={classes.showingText}>
              {' '}
              No records available
              {' '}
              {searchText
                ? `(filtered from ${allDataCount} total entries)`
                : null}
              {' '}
            </div>
          ) : (
            <div className="flexRowAlignCenter">
              <TableItemsText
                allDataCount={allDataCount}
                countPages={countPages}
                currentPage={currentPage}
                recordsToShow={recordsToShow}
                searchText={searchText}
                searchedDataCount={searchedDataCount}
              />
              {typeof loading === 'boolean' && loading === true ? (
                <span className={classes.loadingLabel}> LOADING ... </span>
              ) : null}
            </div>
          )}
          <span className="takeTheRest" />
          <Pagination
            count={countPages}
            onChange={handlePaginationChange}
            pageNumber={currentPage}
          />
        </div>
      ) : null}
    </>
  );
};

export default React.memo(WorkflowTable);
