import Api from '../../api/index';
import { GET_DEFAULT_TILE_LAYOUT, SET_DEFAULT_TILE_LAYOUT } from '../../constants/workflows';
import {
  START_LOADING, STOP_LOADING, SET_MESSAGE, DISSMISS_CONFIRMATION_ALERT,
} from '../../constants/Screens';

export const setDefaultTileLayout = (layout: string, scale: number, frames: any[]) => async (dispatch: any) => {
  dispatch({ type: START_LOADING });

  const data = {
    userID: localStorage.getItem('username'),
    cmd: 'tilelayout',
    parm: {
      action: 'tiledefault',
      cmd: 'tilelayout',
      layout,
      scale,
      frames: JSON.stringify(frames).toString(),
    },
  };
  try {
    await Api.post('synajax/procajax', { data });
    dispatch({ type: STOP_LOADING });
    dispatch({
      type: SET_MESSAGE,
      payload: {
        message: 'Default Layout Updated',
        openAlter: true,
        alertStatus: 'success',
      },
    });
  } catch (error) {
    dispatch({ type: STOP_LOADING });
    dispatch({
      type: SET_MESSAGE,
      payload: {
        message: 'Something Went Wrong',
        openAlter: true,
        alertStatus: 'error',
      },
    });
  }

  return {
    type: SET_DEFAULT_TILE_LAYOUT,
    payload: data,
  };
};

export const getDefaultTileLayout = () => async (dispatch: any) => {
  const response = await Api.post('synajax/procajax', {
    data: {
      userID: localStorage.getItem('username'),
      cmd: 'tilelayout',
      parm: {
        action: 'getTileDefault',
        cmd: 'tilelayout',
      },
    },
  });
  dispatch({
    type: GET_DEFAULT_TILE_LAYOUT,
    payload: response.data,
  });
};

export const dismissedAlert = () => ({
  type: DISSMISS_CONFIRMATION_ALERT,
});
