import React, { useEffect, useState } from 'react';

// Librairies
import clsx from 'clsx';
// import { useDispatch, useSelector } from 'react-redux';

// Components
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SubHeader from '../../../components/subHeader';
import TmsHeader from '../../../components/header';

// Styles
import { useStyles } from './style';
import Api from '../../../redux/api/index';
import CardUtilities from '../../../components/SystemUtilities/CardUtilities';
import {
  formInEdit,
  getCustomers,
  getFolioMonth,
  getFolioNumber,
  getProducts,
  getSystemUtilityData,
  savedResponsedata,
} from '../../../redux/actions/systemUtilities/systemUtilities';

const SystemUtilities: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // Creating Payload State which to send when Generate
  const [rptPayload, setrptPayload] = useState<any>({});
  const {
    fields,
    reportId,
    heading,
    progName,
  } = useSelector(
    (state: any) => state?.SystemUtilities?.SystemUtilities,
  );
  // function to get values from Redux and put them in array to look desire format
  const GetState = (state: any) => {
    const updatedState = state.reduce((accum: any, item: any) => {
      if (item.name === 'rptfield_YM_month') {
        return {
          ...accum,
          [item?.name]: item?.options?.[(new Date()).getMonth()]?.value || '',
        };
      } if (item.name === 'rptfield_YM_year' || item.name === 'rptfield_FY') {
        return {
          ...accum,
          [item?.name]: item?.options?.at(-1)?.value || '',
        };
      }
      if (item.name === 'rptfield_0' || item.name === 'rptfield_M') {
        return {
          ...accum,
          [item?.name]: item?.options?.at(0)?.value || '',
        };
      }
      if (item?.name === 'rptfield_F_month_F'
            || item?.name === 'rptfield_F_num_F'
            || item?.name === 'rptfield_F_month_j'
            || item?.name === 'rptfield_F_num_j'
            || item?.name === 'rptfield_F_month_i'
            || item?.name === 'rptfield_F_num_i') {
        return {
          ...accum,
          [item?.name]: item?.defaultValue?.value || '',
        };
      }
      return {
        ...accum,
        [item?.name]: item?.options?.at(0)?.value || '',
      };
    }, {});
    return updatedState;
  };

  // Setting initial Data from Redux in Form State
  const [formData, setFormData] = useState<any>({ ...GetState(fields) });
  // this will run at Mounting to get Report Configuration
  useEffect(() => {
    dispatch(getSystemUtilityData(reportId));
  }, []);
  useEffect(() => {
    dispatch(getSystemUtilityData(reportId));
  }, [reportId]);
  // This will run when any depenedent dropdown changes in redux after getting data from api.
  // This updating the payload State which will be send to Generate Report API
  useEffect(() => {
    setrptPayload({ ...GetState(fields) });
  }, [fields]);
  // This will run when any depenedent dropdown changes in form State
  // This updating the payload State which will be send to Generate Report API
  useEffect(() => {
    setrptPayload({ ...formData });
  }, [formData]);
  // This will run when Supplier dropdown changes which changes customer dropdown
  // after getting data from APi
  useEffect(() => {
    dispatch(getCustomers(formData?.rptfield_S));
  }, [formData?.rptfield_S]);
  // This will run when Terminal Changes then Folio Month dropdown update
  useEffect(() => {
    dispatch(getFolioMonth(formData?.rptfield_T));
    // console.log(rptPayload);
    if ('rptfield_p' in formData) {
      dispatch(getProducts(formData?.rptfield_T));
    }
  }, [formData?.rptfield_T]);
  // This will run when folio month or terminal changes then folio number update
  useEffect(() => {
    dispatch(getFolioNumber(formData?.rptfield_T, formData?.rptfield_F_month_F));
  }, [formData?.rptfield_F_month_F, formData?.rptfield_T]);
  // This will run when folio month or terminal changes then folio number update
  useEffect(() => {
    if (formData?.rptfield_F_month_i) {
      dispatch(getFolioNumber(formData?.rptfield_T, formData?.rptfield_F_month_i));
    }
  }, [formData?.rptfield_F_month_i, formData?.rptfield_T]);
  // This will run when folio month or terminal changes then folio number update
  useEffect(() => {
    if (formData?.rptfield_F_month_j) {
      dispatch(getFolioNumber(formData?.rptfield_T, formData?.rptfield_F_month_j));
    }
  }, [formData?.rptfield_F_month_j, formData?.rptfield_T]);

  // this function is run when Generate Button Clicks
  const executeUtility = async () => {
    // constructing the payload to be send to Generate Report API
    const payload = {
      reportType: 'S',
      reportid: reportId,
      literals: '',
      flags: '',
      ...rptPayload,
    };
    // calling generate Report API
    const response = await Api.post('/report', payload);
    console.log(response);
    // Saving the Response to redux
    dispatch(savedResponsedata(response.data));
    dispatch(formInEdit(false));
    // Naviagting to Report Queue Screen
    navigate('/workflow/system-utility-queue');
  };
  return (
    <>
      <TmsHeader />
      <SubHeader title="System Utility" />
      <div
        className={clsx('flexStartSpaceBetweenRow', classes.subHeaderContainer)}
      >
        <div />
        <div className="flexRow" />
        <CardUtilities
          heading={heading}
          binary={progName}
          fields={fields}
          formData={formData}
          setFormData={setFormData}
          executeUtility={executeUtility}
        />
      </div>
    </>
  );
};

export default SystemUtilities;
