/* eslint-disable no-unused-expressions */
import React from 'react';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { SelectChangeEvent } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import ControlButton from '../controlButton';
import CustomDropdown from '../customDropdown';
import CustomInput from '../customInput';
import './style.css';
import ConfirmationModal from '../confirmationModal';
import CustomField from '../CustomField/CustomField';
import { SwitchEmbeddedNavigation } from '../../redux/actions/embbededScreensActions';

const CloneRecordHeader = (props: any) => {
  const {
    dataHeader,
    routeBack,
    Visible,
    handleCloseConfirm,
    compare,
    options,
    values,
    handleSelectChange,
    handleInputChange,
    handleSave,
    isModifiy,
    valuesToSend,
    embedded,
  } = props;
  const Ids = dataHeader.map((element: any) => [element.id, element.valForm]);
  const mapData = new Map(Ids);
  const arrayToObject = Object.fromEntries(mapData);
  const { recordDetails } = useSelector((state:any) => state?.ScreenReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleCancel = () => {
    embedded ? dispatch(SwitchEmbeddedNavigation('tableScreen')) : navigate(`${options}`);
  };
  const confirmChange = () => {
    handleSave();
  };
  const handleDelete = () => {
    handleCloseConfirm();
  };

  const requiredFields = React.useMemo(() => {
    const fields = Object.keys(recordDetails).reduce((acc:any, tab:any) => {
      if (tab === 'System') {
        return [...acc];
      }
      const tabRequiredFields = recordDetails?.[tab].reduce((acc2:any, field:any) => {
        if (!field.required) {
          return [...acc2];
        }
        return [...acc2, field];
      }, []);
      return [...acc, ...tabRequiredFields];
    }, []);
    return fields;
  }, [recordDetails]);

  const validationFields = React.useMemo(() => {
    const fields = Object.keys(recordDetails).reduce((acc:any, tab:any) => {
      if (tab === 'System') {
        return [...acc];
      }
      const tabValidateFields = recordDetails?.[tab].reduce((acc2:any, field:any) => {
        if (!field.validate) {
          return [...acc2];
        }
        return [...acc2, field];
      }, []);
      return [...acc, ...tabValidateFields];
    }, []);
    return fields;
  }, [recordDetails]);

  const shouldDisableSaveButton = React.useMemo(() => {
    const requiredIsOkay = requiredFields.every((field:any) => values[field.id] !== undefined && values[field.id] !== '');
    // eslint-disable-next-line
    const validateIsOkay = validationFields.every((field: any) => !isNaN(values[field.id]) && (values[field.id] === '' || /^\d+$/.test(values[field.id])));
    return !(requiredIsOkay && validateIsOkay);
  }, [requiredFields, validationFields, values]);

  const requiredValidation = dataHeader.map((item:any) => ({
    // eslint-disable-next-line no-useless-escape, no-restricted-globals
    required: (!item.required || (values[item.id] !== undefined && values[item.id] !== '')) && !(item.attr.includes('noneg') && isNaN(values[item.id]) && /^\d+$/.test(values[item.id])),
  }));

  return (
    <>
      <Paper
        sx={{
          width: '100%',
          height: '8vh',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <div className="headerContainer">
          <div className="controlers">
            {/* <ControlButton
              label="Delete"
              color="red"
              disabled={false}
              handleClick={handleDelete}
            /> */}
            <ControlButton
              label="Save"
              color="#00c851"
              disabled={shouldDisableSaveButton}
              handleClick={confirmChange}
            />
            <ControlButton
              label="Cancel"
              color="#ffbb33"
              disabled={false}
              handleClick={handleCancel}
            />
          </div>
        </div>
      </Paper>

      <div>
        <div className="fields">
          {dataHeader?.map((val: any, key: any) => (
            <div>
              <CustomField
                val={val}
                values={values}
                valuesToSend={valuesToSend}
                handleInputChange={handleInputChange}
                handleSelectChange={handleSelectChange}
                size={1000}
              />
            </div>
          ))}
        </div>
      </div>
      <ConfirmationModal
        Visible={Visible}
        handleCloseConfirm={handleCloseConfirm}
        options={options}
      />
    </>
  );
};

export default CloneRecordHeader;
