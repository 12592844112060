import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    minWidth: 220,
  },
  label: {
    width: '100%',
    paddingTop: 5,
    paddingRight: 5,
    fontWeight: 400,
    fontSize: 12,
    lineHeight: 1.42857143,
    textAlign: 'right',
  },
  widthBorder: {
    minWidth: 300,
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      width: 6,
      height: '99%',
      display: 'block',
      backgroundColor: '#555 !important',
    },
  },
  input: {
    padding: '0 !important',
    '& .MuiOutlinedInput-root': {
      padding: '0 !important',
      borderRadius: 0,
      fontSize: 12,
      fontWeight: 400,
      color: 'black',
    },
  },
  autoCompleteInput: {
    padding: '4.5px 8px 4.5px 6px !important',
    borderLeft: '4px solid #555 !important',
  },
  centerText: {
    textAlign: 'right',
    '@media (max-width: 899px)': {
      textAlign: 'left',
    },
  },
});
