import React, { useState } from 'react';

// Librairies
import { InputBase } from '@mui/material';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

// Components
import SubHeader from '../../../components/subHeader';
import TmsHeader from '../../../components/header';
import OptionsButton from '../../../components/optionsButton';
import MarqueeManagerOptions from '../../../components/MarqueeManagerOptions';
import MarqueeManagerDialog from '../../../components/MarqueeManagerDialog';

// Styles
import { useStyles } from './style';

// Actions
import { getMarqueeManagerData } from '../../../redux/actions/workflows/marqueeManagerOptions';
import { useWorkflowState } from '../../../hooks/useWorkflowState';
import WorkflowTable from '../../../components/WorkflowTable';
import Loader from '../../../components/Loader';

const tableHeader = {
  Message: 'Message',
  'On Screen': 'On Screen',
  'Life Of Message': 'Life Of Message',
  Display: 'Display',
  Rotation: 'Rotation',
  'Time In Queue': 'Time In Queue',
  'Times Displayed': 'Times Displayed',
};

const searchHeader = [
  { label: 'Submit Date', key: 'prod_group' },
  { label: 'Reference Number', key: 'Reference Number' },
  { label: 'Life Of Message', key: 'Life Of Message' },
  { label: 'Display', key: 'Display' },
  { label: 'Rotation', key: 'Rotation' },
  { label: 'Time In Queue', key: 'Time In Queue' },
  { label: 'Times Displayed', key: 'Times Displayed' },
];

const MarqueeManagerScreen: React.FC = () => {
  const classes = useStyles();
  const [marqueeManagerDilogOpen, setMarqueeManagerDilogOpen] = useState<boolean>(false);
  const { data } = useSelector((state: any) => state.Workflows.MarqueeManager);
  const {
    recordsToShow,
    tableState,
    handlePaginationChange,
    handleSearchChange,
    handleSortTable,
    handleOptionClick,
    handleCloseMenu,
  } = useWorkflowState(searchHeader, data, getMarqueeManagerData, true);

  const handleOpenMarqueeManagerDialog = () => {
    setMarqueeManagerDilogOpen(true);
    handleCloseMenu();
  };
  const handleCloseMarqueeManagerDialog = () => {
    setMarqueeManagerDilogOpen(false);
  };

  return (
    <>
      <TmsHeader />
      <SubHeader title="Marquee Manager">
        <div className="flexRow" style={{ gap: 5 }}>
          <Loader getWorkflowData={getMarqueeManagerData} />
          <OptionsButton handleClick={handleOptionClick} />
        </div>
        <MarqueeManagerOptions
          anchorEl={tableState.anchorEl}
          onClose={handleCloseMenu}
          openDialog={handleOpenMarqueeManagerDialog}
        />
      </SubHeader>
      <div className={clsx('flexRow', classes.wrapper)}>
        <span className="takeTheRest" />
        <div>
          <InputBase className={classes.input} placeholder="Search" onChange={handleSearchChange} />
          <span className={classes.helperText}>
            Viewing
            {' '}
            {tableState.tableData?.length || 0}
            {' '}
            of
            {' '}
            {data?.length || 0}
          </span>
        </div>
      </div>
      <WorkflowTable
        handleClickRow={() => null}
        visibleColumns={tableHeader}
        allDataCount={data.length}
        handlePaginationChange={handlePaginationChange}
        recordsToShow={recordsToShow}
        tableHeader={tableHeader}
        tableState={tableState}
        order={tableState.order}
        orderBy={tableState.orderBy}
        searchText={tableState.searchText}
        sortTable={handleSortTable}
        noPagination
      />
      <MarqueeManagerDialog
        onClose={handleCloseMarqueeManagerDialog}
        open={marqueeManagerDilogOpen}
      />
    </>
  );
};

export default MarqueeManagerScreen;
