import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {},
  closeButton: {
    color: 'black !important',
    textShadow: 'none !important',
    backgroundColor: '#ffbb33 !important',
    border: '1px solid transparent !important',
    padding: '6px 10px !important',
    marginRight: '15px !important',
  },
  label: {
    paddingTop: 5,
    fontSize: '12px !important',
    fontWeight: 400,
    color: 'black !important',
  },
  textArea: {
    resize: 'vertical',
    height: '80px !important',
    width: '75% !important',
    margin: '2px 10px !important',
  },
  wrapper: { padding: '5px 10px 5px 10px', justifyContent: 'flex-end' },
  applyButton: {
    color: 'black !important',
    textShadow: 'none !important',
    backgroundColor: '#00C851 !important',
    border: '1px solid transparent !important',
    padding: '6px 10px !important',
    '&:hover': {
      backgroundColor: '#007E33 !important',
      color: '#fff !important',
    },
  },
});
