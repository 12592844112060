import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import ReactDOM from 'react-dom';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dialog, DialogTitle, Paper, PaperProps,
} from '@mui/material';
import Draggable from 'react-draggable';
import { Close } from '@mui/icons-material';
import { CloseFilterModal } from '../../../redux/actions/ScreenActions';
import OrderMaintenanceFilter from './OrderMaintenanceFilter';

const style = {
  position: 'absolute' as const,
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  height: 470,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
};
const PaperComponent = (props: PaperProps) => (
  <Draggable
    handle="#draggable-dialog-title"
    cancel={'[class*="MuiDialogContent-root"]'}
  >
    <Paper {...props} />
  </Draggable>
);
const TransitionsModal = () => {
  const {
    FilterDropdowStatus,
  } = useSelector((state: any) => state.ScreenReducer);
  const dispatch = useDispatch();
  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  const handleClose = () => {
    dispatch(CloseFilterModal());
  };

  return ReactDOM.createPortal(
    <Dialog
      aria-labelledby="draggable-dialog-title"
      open={FilterDropdowStatus}
      PaperComponent={PaperComponent}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 460,
      }}
      PaperProps={{
        style: {
          minHeight: 540,
          minWidth: '70%',
          position: 'absolute',
        },
      }}
    >
      <DialogTitle style={{ cursor: 'move', padding: '0px' }} id="draggable-dialog-title">
        <div
          style={{
            backgroundColor: '#d2d2d2',
            width: '99%',
            padding: '5px 5px 20px 5px',
            position: 'relative',
          }}
        >
          <Typography variant="h6">Filter Search Criteria</Typography>
          <div
            style={{
              position: 'absolute',
              right: '1rem',
              top: '1rem',
            }}
          >
            <button
              style={{
                border: 'none',
                background: 'transparent',
                cursor: 'pointer',
                color: 'red',
              }}
              type="button"
              onClick={handleClose}
            >
              <Close />
            </button>
          </div>
        </div>
      </DialogTitle>
      <OrderMaintenanceFilter handleClose={handleClose} />
    </Dialog>,
    document.getElementById('draggable') as HTMLElement,
  );
};
export default TransitionsModal;
