import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {},
  firstGroup: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
  },
  inputGroup: {
    display: 'flex',
    gap: 5,
    marginBottom: 5,
  },
  header: {
    width: '100%',
    backgroundColor: '#eee',
    margin: '5px 0',
    padding: '5px 0',
  },
  wrapper: { padding: '5px 10px 5px 10px', justifyContent: 'flex-end' },
  paper: {
    margin: '0 !important',
    width: '100% !important',
    zIndex: '0 !important',
  },
  modalBody: {
    padding: '5px 10px 0 10px',
  },
  title: {
    padding: '0 5px 0 5px',
    fontSize: 22,
    letterSpacing: -1,
    display: 'block',
    color: '#666',
    margin: '0 0 5px 0',
    fontWeight: 100,
  },
  modalHeader: {
    backgroundColor: '#d2d2d2',
    padding: 15,
    borderBottom: '1px solid #e5e5e5',
    display: 'flex',
  },
  modalTitle: {
    margin: 0,
    fontSize: 18,
    fontWeight: 400,
  },
  form: {},
  label: {
    width: '25%',
    textAlign: 'right',
    paddingTop: 5,
    paddingRight: 5,
    fontWeight: 400,
    fontSize: 12,
    lineHeight: 1.42857143,
  },
  modalActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 5,
    marginTop: 0,
    padding: 10,
    borderTop: '1px solid #e5e5e5',
  },
  modalPrinter: {
    borderTop: '0px solid #e5e5e5',
  },
  generateButton: {
    appearance: 'button',
    blockSize: '27.1375px',
    borderBlockEndColor: 'rgb(53, 170, 71)',
    borderBlockEndStyle: 'solid',
    borderBlockEndWidth: '1px',
    borderBlockStartColor: 'rgb(53, 170, 71)',
    borderBlockStartStyle: 'solid',
    borderBlockStartWidth: '1px',
    borderInlineEndColor: 'rgb(53, 170, 71)',
    borderInlineEndStyle: 'solid',
    borderInlineEndWidth: '1px',
    borderInlineStartColor: 'rgb(53, 170, 71)',
    borderInlineStartStyle: 'solid',
    borderInlineStartWidth: '5px',
    cursor: 'pointer',
    height: '27.1375px',
    inlineSize: '133.163px',
    paddingBlockEnd: '4px',
    paddingBlockStart: '4px',
    paddingInlineEnd: '10px',
    paddingInlineStart: '10px',
    perspectiveOrigin: '66.575px 13.5625px',
    pointerEvents: 'all',
    textSizeAdjust: '100%',
    touchAction: 'manipulation',
    transformOrigin: '66.5813px 13.5688px',
    userSelect: 'none',
    verticalAlign: 'middle',
    whiteSpace: 'nowrap',
    width: '133.163px',
    background:
      'rgb(221, 221, 221) none repeat scroll 0% 0% / auto padding-box border-box',
    borderTop: '1px solid rgb(53, 170, 71)',
    borderRight: '1px solid rgb(53, 170, 71)',
    borderBottom: '1px solid rgb(53, 170, 71)',
    borderLeft: '5px solid rgb(53, 170, 71)',
    font: '12px / 17.1429px \'Open Sans\'',
    padding: '4px 10px',
  },
  translate: {
    transform: 'translate(-8px,2px)',
  },
  small: {
    caretColor: 'rgb(119, 119, 119)',
    color: 'rgb(119, 119, 119)',
    columnRuleColor: 'rgb(119, 119, 119)',
    perspectiveOrigin: '0px 0px',
    pointerEvents: 'all',
    textDecoration: 'none solid rgb(119, 119, 119)',
    textEmphasisColor: 'rgb(119, 119, 119)',
    textSizeAdjust: '100%',
    transformOrigin: '0px 0px',
    border: '0px none rgb(119, 119, 119)',
    font: '13.5px / 13.5px \'Open Sans\'',
    outline: 'rgb(119, 119, 119) none 0px',
    transform: 'translateY(9px)',
  },
  input: {
    width: '100%',
  },
  sacdTableCell: {
    width: '100%',
    borderCollapse: 'collapse',
    marginBottom: '1px',
    marginRight: '1px',
    border: '1px solid grey',
  },
  td: {
    textAlgin: 'center',
    width: '3em',
  },
  tdinput: {
    boxSizing: 'border-box',
    pointerEvents: 'all',
    textAlign: 'center',
    width: '3em',
  },
  unsaved: {
    backgroundColor: '#f4fadb !important',
    border: 'none',
  },
  row: {
    boxSizing: 'border-box',
    outline: 'none',
    appearance: 'none',
    margin: '0px',
    marginRight: '0px',
    marginLeft: '0px',
    paddingBottom: '5px',
    borderRadius: '0px',
  },
  tankstrapfractions: {
    boxSizing: 'border-box',
    borderSpacing: '0px',
    backgroundColor: 'transparent',
    width: '100%',
    borderCollapse: 'collapse',
    marginBottom: '1px',
    marginRight: '1px',
    borderRadius: '0px',
  },
  levelother: {
    boxSizing: 'border-box',
    padding: '0px',
    borderWidth: '1px 1px 2px',
    borderStyle: 'solid',
    borderColor:
      'rgb(221, 221, 221) rgb(221, 221, 221) rgb(170, 170, 170)',
    borderImage: 'initial',
    fontWeight: 'bold',
    height: '22px',
    textAlign: 'center',
    borderRadius: '0px',
  },
  volume_header: {
    boxSizing: 'border-box',
    padding: '0px',
    borderWidth: '1px 1px 2px',
    borderStyle: 'solid',
    borderColor:
      'rgb(221, 221, 221) rgb(221, 221, 221) rgb(170, 170, 170)',
    borderImage: 'initial',
    fontWeight: 'bold',
    height: '22px',
    textAlign: 'center',
    border: '1px solid rgb(221, 221, 221)',
    minWidth: '90px',
    borderRadius: '0px',
  },
  level_value_fraction: {
    boxSizing: 'border-box',
    padding: '0px',
    border: '1px solid rgb(221, 221, 221)',
    height: '22px',
    borderRadius: '0px',
  },
  form_control_nosizehelper: {
    boxSizing: 'border-box',
    margin: '0px',
    font: 'inherit',
    fontFamily: 'inherit',
    outline: 'none',
    appearance: 'none',
    display: 'block',
    width: '100%',
    backgroundImage: 'none',
    transition:
      'border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s',
    color: 'rgb(51, 51, 51)',
    backgroundColor: 'rgb(255, 255, 255)',
    boxShadow: 'none',
    fontSize: '12px',
    lineHeight: 1.42857,
    height: 'auto',
    border: '1px solid transparent',
    padding: '0px',
    textAlign: 'center',
    borderRadius: '0px',
    fontWeight: 400,
  },
  tankcontainer: {
    boxSizing: 'border-box',
    outline: 'none',
    appearance: 'none',
    flexFlow: 'column wrap',
    minHeight: '300px',
    display: 'flex',
    alignContent: 'flex-start',
    overflowX: 'auto',
    height: 'calc(100vh - 300px)',
    borderRadius: '0px',
    borderCollapse: 'collapse',
    textAlign: 'center',
  },
  tankstraplevel: {
    boxSizing: 'border-box',
    borderSpacing: '0px',
    backgroundColor: 'transparent',
    borderCollapse: 'collapse',
    marginBottom: '1px',
    marginRight: '1px',
    borderRadius: '0px',
  },
  level_header: {
    boxSizing: 'border-box',
    padding: '0px',
    borderWidth: '1px 1px 2px',
    borderStyle: 'solid',
    borderColor:
      'rgb(221, 221, 221) rgb(221, 221, 221) rgb(170, 170, 170)',
    borderImage: 'initial',
    fontWeight: 'bold',
    height: '22px',
    width: '34px',
    textAlign: 'center',
    borderRadius: '0px',
  },
  levelone_levelheight_interpolate: {
    boxSizing: 'border-box',
    cursor: 'pointer',
    border: '1px solid rgb(221, 221, 221)',
    height: '22px',
    padding: '0px 5px',
    textAlign: 'left',
    fontWeight: 'bold',
    borderRadius: '0px',
  },
  tankstrapfield: {
    boxSizing: 'border-box',
    margin: '0px',
    font: 'inherit',
    fontFamily: 'inherit',
    outline: 'none',
    appearance: 'none',
    display: 'block',
    width: '100%',
    backgroundImage: 'none',
    transition:
      'border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s',
    color: 'rgb(51, 51, 51)',
    backgroundColor: 'rgb(255, 255, 255)',
    boxShadow: 'none',
    fontSize: '12px',
    lineHeight: 1.42857,
    height: 'auto',
    border: '1px solid transparent',
    padding: '0px',
    textAlign: 'center',
    borderRadius: '0px',
    fontWeight: 400,
  },
  levelvalue: {
    boxSizing: 'border-box',
    border: '1px solid rgb(221, 221, 221)',
    height: '22px',
    padding: '0px',
    textAlign: 'center',
    borderRadius: '0px',
  },
  headingGauge: {
    boxSizing: 'border-box',
    fontFamily: 'inherit',
    fontWeight: 500,
    lineHeight: 1.1,
    color: 'inherit',
    marginTop: '10px',
    marginBottom: '10px',
    fontSize: '18px',
  },
});
