import React, { useEffect } from 'react';
import {
  DialogContent, DialogTitle, TableCell, TableRow, Table, TableBody,
} from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useSelector } from 'react-redux';
import { DialogComponent } from '../../../components/Dialog/Dialog.component';
import { ReleaseDescription } from '../../../redux/actions/workflows/aboutPage';
import { AboutPageState } from '../../../redux/reducers/workflows/aboutPage';
import { useStyles } from './styles';

interface IReleaseDescriptionRow {
  productVersionValue: ReleaseDescription;
}

const ReleaseDescriptionRow: React.FC<IReleaseDescriptionRow> = ({ productVersionValue }) => {
  const classes = useStyles();
  return (
    <TableRow classes={{ root: classes.tableRow }}>
      <TableCell classes={{ root: classes.tableCell }}>{productVersionValue.ref}</TableCell>
      <TableCell classes={{ root: classes.tableCell }}>{productVersionValue.text}</TableCell>
    </TableRow>
  );
};

interface IReleaseDescriptionTable {
  header: string;
  productVersionValue: Array<ReleaseDescription>;
}

const ReleaseDescriptionTable: React.FC<IReleaseDescriptionTable> = (
  {
    header,
    productVersionValue,
  },
) => {
  const classes = useStyles();
  return (
    <>
      <h5>{`${header}:`}</h5>
      <Table classes={{ root: classes.tableRoot }} size="small">
        <TableBody>
          {
            productVersionValue.map((p) => <ReleaseDescriptionRow productVersionValue={p} />)
          }
        </TableBody>
      </Table>
    </>
  );
};

interface IProps {
  open: boolean;
  onClose: VoidFunction;
}

export const ReleaseNotesDialog: React.FC<IProps> = ({ open, onClose }) => {
  const [selectedTabValue, setSelectedTabValue] = React.useState<string>('');
  const { data } = useSelector<any, AboutPageState>((state) => state.Workflows.AboutPage);
  useEffect(() => {
    if (selectedTabValue === '') {
      const keys = Object.keys(data.releaseInfo.versions);
      if (keys.length > 0) {
        setSelectedTabValue(keys[0]);
      }
    }
  }, [data, selectedTabValue]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTabValue(newValue);
  };
  const map = new Map(Object.entries(data.releaseInfo.versions));
  const productVersion = map.get(selectedTabValue);
  return (
    <DialogComponent
      title="Release Notes"
      onClose={onClose}
      open={open}
    >
      <DialogTitle>{`Product: ${data.releaseInfo.product}`}</DialogTitle>
      <DialogContent>
        <Tabs value={selectedTabValue} onChange={handleChange}>
          {
            Array.from(map.keys()).map((version) => (
              <Tab key={version} label={version} value={version} />
            ))
          }
        </Tabs>
        {productVersion && (
          <div>
            <ReleaseDescriptionTable
              header="New"
              productVersionValue={productVersion['New:']}
            />
            <ReleaseDescriptionTable
              header="Fixed"
              productVersionValue={productVersion['Fixed:']}
            />
            <ReleaseDescriptionTable
              header="Advisory"
              productVersionValue={productVersion['Advisory:']}
            />
          </div>
        )}
      </DialogContent>
    </DialogComponent>
  );
};
