/* eslint-disable max-len */

import { removeFromObject } from '../../utils';
import {
  GET_ALL_EMBBEDED_COLUMNS,
  LOAD_EMBBEDED_RECORD,
  LOAD_EMBBEDED_SCREEN,
  SWITCH_EMBEDDED_NAVIGATION,
  BATCH_MODE_COLUMS_EMBEDDED,
  SET_RECORD_LIST_EMBEDDED,
  SAVE_BATCH_MODE_QUERY_EMBEDDED,
  OPEN_COLUMN_MODAL_EMBEDDED,
  CLOSE_COLUMN_MODAL_EMBEDDED,
  OPEN_FILTER_MODAL_EMBEDDED,
  CLOSE_FILTER_MODAL_EMBEDDED,
  START_LOADING_EXPORT_EMBEDDED,
  STOP_LOADING_EXPORT_EMBEDDED,
  START_LOADING_EMBEDDED,
  STOP_LOADING_EMBEDDED,
  SAVE_FILTER_EMBEDDED,
  DELETE_FILTER_EMBEDDED,
  SET_MESSAGE_EMBEDDED,
  SAVE_SCREEN_FILTER_EMBEDDED,
} from '../constants/Screens';

  type action = {
    type: string;
    payload: any;
    screen?:any;
    cloneForm?:any;
    parsedValues?:any;
    RecordsList?: any;
    labels?: any;
    allTypes?: any;
    message?: any;
    embbededTabsConfig?: any;
    modifyDep?: any;
    allLabels?: any;
    grid?: any;
    screenId?: any;
    unitCalculator?:any;
  };

  type ScreenState = {
    data: [];
    loading: boolean;
    columns:[];
    Selectedcolumnss:string;
    savedFilter:string;
    recordDetails:any;
    activeScreen:any;
    recordsColumns:any,
    allTableColumns:[];
    ColumnsVisibleModal:boolean;
    BatchModeQuery:any
    ScreensList:[]
    changeRecordModal:boolean;
    FilterDropdowStatus:boolean;
    embbededTabsConfig:[];
    embeddedNavigation:string;
    modifyDep: any;
    grid: any;
    exportLoading:any;
    activeFilter: any;
    FilterSaved: any;
  };
const initialState = {
  data: [],
  loading: true,
  columnss: [],
  allTableColumns: [],
  recordsColumns: {},
  recordDetails: [],
  ColumnsVisibleModal: false,
  BatchModeQuery: [],
  clone: null,
  ScreensList: [],
  RecordsList: [],
  changeRecordModal: false,
  FilterDropdowStatus: false,
  activeScreenLabels: [],
  confirmationAlert: '',
  openConfirmationAlert: false,
  embbededTabsConfig: [],
  grid: null,
  exportLoading: false,
  activeFilter: {},
  FilterSaved: {},
  embeddedNavigation: 'tableScreen',
  modifyDep: null,
};

const EmbbededScreenReducer = (
  state: any = initialState,
  action: action,
): ScreenState => {
  switch (action.type) {
    case LOAD_EMBBEDED_SCREEN:
      return {
        ...state,
        data: action.payload,
        activeScreen: action.screen,
      };
    case LOAD_EMBBEDED_RECORD:
      return {
        ...state,
        loading: false,
        recordDetails: action.payload,
        clone: action.cloneForm,
        parsedValues: action.parsedValues,
        activeScreenLabels: action.labels,
        allTypes: action.allTypes,
        embbededTabsConfig: action.embbededTabsConfig,
        modifyDep: action.modifyDep,
        allLabels: action.allLabels,
        grid: action.grid,
        unitCalculator: action.unitCalculator,
      };
    case GET_ALL_EMBBEDED_COLUMNS:
      return {
        ...state,
        loading: false,
        allTableColumns: action.payload,
      };
    case SWITCH_EMBEDDED_NAVIGATION:
      return {
        ...state,
        loading: false,
        embeddedNavigation: action.payload,
      };
    case BATCH_MODE_COLUMS_EMBEDDED:
      return {
        ...state,
        loading: false,
        recordsColumns: action.payload,
      };
    case SET_RECORD_LIST_EMBEDDED:
      return {
        ...state,
        loading: false,
        RecordsList: action.payload,
      };
    case SAVE_BATCH_MODE_QUERY_EMBEDDED:
      return {
        ...state,
        loading: false,
        BatchModeQuery: action.payload,
      };
    case OPEN_COLUMN_MODAL_EMBEDDED:
      return {
        ...state,
        ColumnsVisibleModal: true,
      };
    case CLOSE_COLUMN_MODAL_EMBEDDED:
      return {
        ...state,
        ColumnsVisibleModal: false,
      };
    case OPEN_FILTER_MODAL_EMBEDDED:
      return {
        ...state,
        FilterDropdowStatus: true,
      };
    case CLOSE_FILTER_MODAL_EMBEDDED:
      return {
        ...state,
        FilterDropdowStatus: false,
      };
    case START_LOADING_EXPORT_EMBEDDED:
      return {
        ...state,
        exportLoading: true,

      };
    case STOP_LOADING_EXPORT_EMBEDDED:
      return {
        ...state,
        exportLoading: false,

      };
    case START_LOADING_EMBEDDED:
      return {
        ...state,
        loading: true,
      };
    case STOP_LOADING_EMBEDDED:
      return {
        ...state,
        loading: false,
      };
    case SAVE_FILTER_EMBEDDED:
      return {
        ...state,
        loading: false,
        FilterSaved: { ...state.FilterSaved, [action.screenId]: action.payload },
      };
    case DELETE_FILTER_EMBEDDED:
      return {
        ...state,
        loading: false,
        FilterSaved: removeFromObject(state.FilterSaved, action.payload),
        activeFilter: removeFromObject(state.activeFilter, action.payload),
      };
    case SET_MESSAGE_EMBEDDED:
      return {
        ...state,
        confirmationAlert: action.message,
        openConfirmationAlert: true,
      };
    case SAVE_SCREEN_FILTER_EMBEDDED:
      return {
        ...state,
        activeFilter: { ...state.activeFilter, [action.screenId]: action.payload },
      };
    default:
      return state;
  }
};

export default EmbbededScreenReducer;
