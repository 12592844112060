import { Dispatch } from 'redux';
import Api from '../../api/index';
import { LOAD_TANK_STATUS } from '../../constants/workflows';

export const getTankStatuses = () => async (dispatch: Dispatch) => {
  try {
    const response = await Api.get(
      'workflow101/data',
    );
    dispatch({
      type: LOAD_TANK_STATUS,
      payload: response.data,
    });
  } catch (error) {
    console.log('getTankStatuses error', error);
  }
};

export const sendOptionsButtonQuery = () => async () => {
  try {
    // termid is unavailable on the current user, so it's hardcored for now
    // HHL PLC Override button only sends this on the demo site
    await Api.post(
      'synajax/procajax',
      {
        tagid: 'TS',
        data: {
          cmd: 'tankstatus',
          parm: {
            termid: 'B344',
            command: '18',
          },
          data: 'tvs',
        },
        jqxhr: null,
      },
    );
  } catch (error) {
    console.log('TankStatus sendOptionsButtonQuery error', error);
  }
};
