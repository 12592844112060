import { Dispatch } from 'redux';
import Api from '../../api/index';
import {
  LOAD_DEVICE_COMMUNICATIONS,
  SUBMIT_DEVICE_COMMUNICATIONS,
} from '../../constants/workflows';

export const getDeviceCommunications = () => async (dispatch: Dispatch) => {
  const response = await Api.get(
    'workflow044/data?screenID=WORKFLOW-044&userID=tms&groupID=Admin',
  );
  dispatch({
    type: LOAD_DEVICE_COMMUNICATIONS,
    payload: response.data,
  });
};

export const submitDeviceCommunications = (dialogState: any) => async (dispatch: Dispatch) => {
  const device = {
    userlist: 'responseID',
    line: dialogState.line || dialogState.line.label,
    protocol: dialogState.protocol.value,
    unit: dialogState.unit.value,
    command: dialogState.command,
  };
  const requestPayload = {
    data: {
      cmd: 'syscmd',
      userid: 'tms',
      parm: {
        cmd: 'devicecomm',
        device,
      },
    },
  };
  const response = await Api.post('synajax/procajax', requestPayload);
  dispatch({
    type: SUBMIT_DEVICE_COMMUNICATIONS,
    payload: response.data[0],
  });
};
