import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  inputStyle: {
    display: 'flex',
    alignItems: 'center',
    gap: 20,
    marginBottom: 10,
  },
  clearButton: {
    color: 'black',
    textShadow: 'none',
    backgroundColor: '#00C851',
    border: '1px solid transparent',
    padding: '6px 10px',
  },
  RemoveButton: {
    color: 'black',
    textShadow: 'none',
    backgroundColor: 'red',
    border: '1px solid transparent',
    padding: '6px 10px',
  },
  SaveButton: {
    color: 'black',
    textShadow: 'none',
    backgroundColor: 'green',
    border: '1px solid transparent',
    padding: '6px 10px',
  },
  LoadButton: {
    color: 'black',
    textShadow: 'none',
    backgroundColor: '#852b99',
    border: '1px solid transparent',
    padding: '6px 10px',
  },
  closeButton: {
    color: 'black',
    textShadow: 'none',
    backgroundColor: '#ffbb33',
    border: '1px solid transparent',
    padding: '6px 10px',
  },
  actions: {
    padding: '8px 25px',
  },
  templateContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    gap: 10,
  },
});
