import { Dispatch } from 'redux';
import Api from '../../api/index';
import { LOAD_SYSTEM_UTILITY_QUEUE } from '../../constants/workflows';

export const getSystemUtilityQueue = () => async (dispatch: Dispatch) => {
  const response = await Api.get(
    'report?reportType=S',
  );
  console.log(response);
  dispatch({
    type: LOAD_SYSTEM_UTILITY_QUEUE,
    payload: response?.data?.tableDataList,
  });
};
