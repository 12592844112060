import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { useSelector } from 'react-redux';
import { AboutPageState } from '../../../redux/reducers/workflows/aboutPage';
import { useStyles } from './styles';

const imageStyle: React.CSSProperties = {
  minHeight: '150px',
  maxHeight: '200px',
  width: 'auto',
  display: 'block',
  maxWidth: '100%',
  height: 'auto',
};

export const ServerProductVersion: React.FC = () => {
  const classes = useStyles();
  const { data } = useSelector<any, AboutPageState>((state) => state.Workflows.AboutPage);
  return (
    <Grid2 container>
      <Grid2 xs={6}>
        <img
          alt="Toptech logo"
          src="/assets/images/toptechlogobluefull.png"
          className="img-responsive"
          style={imageStyle}
        />
      </Grid2>
      <Grid2 xs={6}>
        <h4>Server Product Version</h4>
        <Table classes={{ root: classes.tableRoot }} size="small">
          <TableBody>
            <TableRow classes={{ root: classes.tableRow }}>
              <TableCell classes={{ root: classes.tableCell }}>Product</TableCell>
              <TableCell classes={{ root: classes.tableCell }}>{data.productVersion.product}</TableCell>
            </TableRow>
            <TableRow classes={{ root: classes.tableRow }}>
              <TableCell classes={{ root: classes.tableCell }}>Product Version</TableCell>
              <TableCell classes={{ root: classes.tableCell }}>{data.productVersion.version}</TableCell>
            </TableRow>
            <TableRow classes={{ root: classes.tableRow }}>
              <TableCell classes={{ root: classes.tableCell }}>Product Build</TableCell>
              <TableCell classes={{ root: classes.tableCell }}>{data.productVersion.build}</TableCell>
            </TableRow>
            <TableRow classes={{ root: classes.tableRow }}>
              <TableCell classes={{ root: classes.tableCell }}>Product Release Date</TableCell>
              <TableCell classes={{ root: classes.tableCell }}>{data.productVersion.time}</TableCell>
            </TableRow>
            <TableRow classes={{ root: classes.tableRow }}>
              <TableCell classes={{ root: classes.tableCell }}>Certificate Number</TableCell>
              <TableCell classes={{ root: classes.tableCell }}>{data.productVersion.certificateNumber}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid2>
    </Grid2>
  );
};
