/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';

// Styles
import { useStyles } from './style';

interface IProps {
  rowData: any;
  visibleColumns: any;
}

const Detail: React.FC<IProps> = ({ rowData, visibleColumns }) => (
  <div>
    <UpperTable header={rowData?.header} visibleColumns={visibleColumns} />
    <DetailTable rowData={rowData} visibleColumns={visibleColumns} />
  </div>
);

export default Detail;

interface IUpperTableProps {
  header: any;
  visibleColumns: any;
}

const UpperTable: React.FC<IUpperTableProps> = ({ header, visibleColumns }) => {
  const classes = useStyles();

  return (
    <table className={classes.detailUpperTable}>
      <thead>
        <tr>
          {Object.keys(visibleColumns.TransHeader || {}).map((key) => (
            <th key={key} className={classes.upperTableTh}>
              {visibleColumns.TransHeader[key]}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          {Object.keys(visibleColumns.TransHeader || {}).map((key) => (
            <td key={key} className={classes.upperTableTd}>
              {header[key]}
            </td>
          ))}
        </tr>
      </tbody>
    </table>
  );
};

const DetailTable: React.FC<IProps> = ({ rowData, visibleColumns }) => {
  const classes = useStyles();
  const productList = Object.values(rowData.productlist || {});
  const editList = Object.values(rowData.edilist || {});

  return (
    <>
      <table className={classes.detailTable}>
        <thead>
          <tr>
            {Object.keys(visibleColumns?.TransProducts || {}).map((key) => (
              <th key={key} className={classes.detailTableTh}>
                {visibleColumns?.TransProducts[key]}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {productList.map((elem: any) => {
            const componentList = Object.values(elem.componentlist || {});
            return (
              <>
                <tr key={elem?.prod_rec_no}>
                  {Object.keys(visibleColumns?.TransProducts || {}).map(
                    (key) => (
                      <td key={key} className={classes.detailTableTd}>
                        {elem[key]}
                      </td>
                    ),
                  )}
                </tr>
                <tr>
                  <td
                    style={{ padding: '4px 8px 3px 8px' }}
                    colSpan={
                      Object.keys(visibleColumns?.TransComponents || {})?.length
                    }
                  >
                    <table className={classes.innerTable}>
                      <thead>
                        <tr>
                          {Object.keys(
                            visibleColumns?.TransComponents || {},
                          ).map((key) => (
                            <th key={key} className={classes.innerTableTh}>
                              {visibleColumns?.TransComponents?.[key]}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {componentList.map((item: any) => (
                          <tr>
                            {Object.keys(
                              visibleColumns?.TransComponents || {},
                            ).map((key) => (
                              <td key={key} className={classes.innerTableTd}>
                                {item?.[key]}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </td>
                </tr>
              </>
            );
          })}
          <tr>
            <td
              style={{ padding: '4px 8px 3px 8px' }}
              colSpan={Object.keys(visibleColumns?.TransEDI || {})?.length}
            >
              <table className={classes.innerTable}>
                <thead>
                  <tr>
                    {Object.keys(visibleColumns?.TransEDI || {}).map(
                      (key) => (
                        <th key={key} className={classes.editTableTh}>
                          {visibleColumns?.TransEDI?.[key]}
                        </th>
                      ),
                    )}
                  </tr>
                </thead>
                <tbody>
                  {editList.map((item: any) => (
                    <tr>
                      {Object.keys(visibleColumns?.TransEDI || {}).map(
                        (key) => (
                          <td key={key} className={classes.innerTableTd}>
                            {item?.[key]}
                          </td>
                        ),
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};
