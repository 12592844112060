import { LOAD_REPORT_QUEUE } from '../../constants/workflows';

const INITIAL_STATE = {
  data: [],
};

const reports = (
  state = INITIAL_STATE,
  { type, payload }: any,
) => {
  switch (type) {
    case LOAD_REPORT_QUEUE: {
      return {
        ...state,
        data: payload,
      };
    }
    default:
      return state;
  }
};

export default reports;
