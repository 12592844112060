/* eslint-disable operator-linebreak */
import { Dispatch } from 'redux';
import Api from '../../api/index';
import {
  CLEAR_FOLIO_FREEZE_CLOSE,
  LOAD_FOLIO_FREEZE_CLOSE,
} from '../../constants/workflows';

export const getFolioFreezeCloseData = () => async (dispatch: Dispatch) => {
  const response = await Api.get(
    'workflow034/table?userID=tms&screenID=WORKFLOW-034&termID=B344&tableID=FolioFreezeClose',
  );
  const data = Object.values(response.data.data);
  dispatch({
    type: LOAD_FOLIO_FREEZE_CLOSE,
    payload: {
      data,
      hiddenColumns: response.data.hiddenColumns,
      visibleColumns: response.data.visibleColumns,
    },
  });
};

export const getNewFolioFreezeCloseColumns =
  (columns: string[]) => async (dispatch: Dispatch) => {
    const userID = localStorage.getItem('username');
    await Api.post('workflow034/updatecolumns', { userID, columns });
    window.location.reload();
    dispatch({
      type: CLEAR_FOLIO_FREEZE_CLOSE,
    });
  };
