/* eslint-disable max-len */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-undef */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import TmsHeader from '../../../components/header';
import PresetCardBody from '../../../components/RackStatus/PresetCardBody';
import RackStatusHeader from '../../../components/RackStatus/RackStatusHeader';
import RackStatusRCUDitto from '../../../components/RackStatus/RackStatusRCUDitto';
import RackStatusSCADTable from '../../../components/RackStatus/RackStatusSCADTable';
import SubHeader from '../../../components/subHeader';
import OptionsButton from '../../../components/optionsButton';
import { useLogger } from '../../../hooks/useLogger';
import {
  initRackStatusDetail, RCUDittoUpdate, sendCommandRackStatus, synajaxRackStatusDetail,
} from '../../../redux/actions/workflows/rackStatus';
import RackStatusDetailsOptionButton from '../../../components/RackStatus/RackStatusDetailsOptionButton';
import AlarmHistoryModal from '../../../components/RackStatus/AlarmHistoryModal';
import RackStatusDetailConfirmationModal from '../../../components/RackStatus/RackStatusDetailConfirmationModal';
import { ResponseAlert } from '../../../components/RackStatus/ResponseAlert';
import Loader from '../../../components/RackStatus/Loader';

const messageList = {
  start: 'Start',
  stop: 'Stop',
  restart: 'Restart',
  drain: 'Drain',
  enable: 'Enable',
  disable: 'Disable',
  open: 'Open',
  togglebaytrace: 'Toggle Trace On/Off',
  togglebaydebug: 'Toggle Debug On/Off',
  stopallbays: 'Stop All Load Bays',
  startallbays: 'Start All Load Bays',
  drainallbays: 'Drain All Load Bays',
  pumprestoreall: 'Restore All Pumps',
  restartallbays: 'Restart All Load Bays',
};
const RackStatusDetail = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { log } = useLogger('RackStatusDetail');
  const [openAlarmHistory, setOpenAlarmHistory] = useState<boolean>(false);
  const [confirmationVisible, setConfirmationVisible] = useState<boolean>(false);
  const message = useRef<any>('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { loading } = useSelector(
    (state: any) => state.ScreenReducer,
  );
  const {
    presetCardsData, viewTitle, ShowBayStatus, scadTable, pageImgList, transportDetailData, currentBayForDetail, dittoBayID,
  } = useSelector((state: any) => state.Workflows.RackStatus);
  useEffect(() => {
    if (currentBayForDetail) {
      dispatch(initRackStatusDetail(currentBayForDetail));
      dispatch(synajaxRackStatusDetail(currentBayForDetail));
    } else {
      history(-1);
    }

    // @ temporary stuff will change with auto refresh mechanism
    const interval = setInterval(() => {
      dispatch(initRackStatusDetail(currentBayForDetail));
      dispatch(synajaxRackStatusDetail(currentBayForDetail));
    }, 4000);
    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    if (dittoBayID) {
      dispatch(RCUDittoUpdate(dittoBayID));
    }
  }, [dittoBayID]);
  const handleOptionClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleAnchorClose = () => {
    setAnchorEl(null);
  };
  const CloseAlarmHistory = () => {
    setOpenAlarmHistory(false);
  };
  const handleOpenDialog = (type:string) => {
    log('open');
  };
  const confirmationBeforeProCommand = (bayID: string, option: string, description: string) => {
    message.current = {
      title: bayID,
      msg: `${messageList[option as keyof typeof messageList]} ${bayID} - ${description}`,
      bayID,
      option,
      description,
    };
    setConfirmationVisible(true);
    handleAnchorClose();
  };
  const handleCancel = () => {
    setConfirmationVisible(false);
  };
  const procBayCommand = () => {
    log(message.current);
    setConfirmationVisible(false);
    const queueid = `CMD-${message.current.bayID}`;
    const packet = {
      queueid,
      reqid: `id-o${Math.random() * 10}uxk1b0hlb`,
      data: {
        cmd: 'cmdreq',
        parm: {
          lrcid: message.current.bayID,
          command: message.current.option,
        },
        data: null,
      },
    };
    dispatch(sendCommandRackStatus(packet));
  };
  return (loading ? (
    <div
      style={{
        width: '100%',
        height: '90vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress />
    </div>
  ) : (
    <>
      <TmsHeader />
      <SubHeader title={`Rack Status: ${viewTitle}`}>
        <Loader getWorkflowData={() => dispatch(RCUDittoUpdate(dittoBayID))} />
        <OptionsButton handleClick={handleOptionClick} />
        <RackStatusDetailsOptionButton
          anchorEl={anchorEl}
          onClose={handleAnchorClose}
          handleOpenDialog={handleOpenDialog}
          Options={ShowBayStatus?.OptionBtn || []}
          setOpenAlarmHistory={setOpenAlarmHistory}
          confirmationBeforeProCommand={confirmationBeforeProCommand}
        />
      </SubHeader>
      <RackStatusHeader ShowBayStatus={ShowBayStatus} />
      {Object.keys(scadTable).length !== 0 && <RackStatusSCADTable scadTable={scadTable} />}
      <div style={{ overflow: 'auto' }}>
        <RackStatusRCUDitto dittoBayID={dittoBayID} pageImgList={pageImgList} transportDetailData={transportDetailData} />
        <PresetCardBody
          presetStatus={presetCardsData}
          confirmationBeforeProCommand={confirmationBeforeProCommand}
        />
      </div>
      <AlarmHistoryModal title="Preset Alarm History" open={openAlarmHistory} onClose={CloseAlarmHistory} />
      <RackStatusDetailConfirmationModal
        title={message.current?.title}
        message={message.current?.msg}
        Visible={confirmationVisible}
        handleCancel={handleCancel}
        handleYes={procBayCommand}
      />
      <ResponseAlert />
    </>
  )
  );
};

export default RackStatusDetail;
