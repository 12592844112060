/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';

// Librairies
import {
  ButtonBase,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { Settings } from '@mui/icons-material';

// Styles
import { useStyles } from './style';

// Utils
const tableHeader = [
  { label: 'buttons', key: 'buttons' },
  { label: 'Bay', key: 'bay_desc' },
  { label: 'Type', key: 'rtype' },
  { label: 'Max Trucks Loaded/Staged', key: 'truck_count' },
  { label: 'Card In Description', key: 'card_in_desc' },
  { label: 'Card Out Description', key: 'card_out_desc' },
  { label: 'Extended Messaging', key: 'extended_messaging' },
  { label: 'Traffic Name', key: 'process_name' },
  { label: 'Processing', key: 'ProcessingType' },
];
const searchRecords = (searchText: string, records: any[]) =>
  records.filter((record: any) =>
    tableHeader
      .map((x) => x.key)
      .some((x) =>
        record[x]?.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())));

interface IProps {
  handleClickRow?: (row: any) => void;
  data: any[];
  searchText: string;
}

const TrafficTable: React.FC<IProps> = ({
  handleClickRow,
  data,
  searchText,
}) => {
  const classes = useStyles();
  const [tableData, setTableData] = useState(data || []);

  useEffect(() => {
    if (searchText) {
      const filtredRecords = searchRecords(searchText, data);
      setTableData(filtredRecords);
    } else {
      setTableData(data);
    }
  }, [data, searchText]);

  return (
    <div className={classes.root}>
      <Table classes={{ root: classes.tableRoot }}>
        <TableHead>
          <TableRow classes={{ root: classes.headerRow }}>
            {tableHeader.map((h) => (
              <TableCell classes={{ root: classes.headerCell }} key={h.key}>
                {h.label === 'buttons' ? '' : h.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData?.map((row: any, index) => (
            <TableRow className={classes.tableRow} key={index}>
              {tableHeader.map((field) =>
                (field.label === 'buttons' ? (
                  <TableCell classes={{ root: classes.bodyCell }}>
                    <div className={classes.actionsWrapper}>
                      <ButtonBase
                        className={classes.actionButton}
                        onClick={() => handleClickRow && handleClickRow(row)}
                      >
                        <Settings fontSize="small" />
                      </ButtonBase>
                    </div>
                  </TableCell>
                ) : (
                  <TableCell
                    classes={{ root: classes.bodyCell }}
                    className={
                      row[field.key] === 'Traffic Disabled'
                        ? classes.trafficDisabled
                        : row[field.key] === 'Traffic Enabled'
                          ? classes.trafficEnabled
                          : undefined
                    }
                    key={field.key}
                  >
                    {row[field.key] || '- - - - - - -'}
                  </TableCell>
                )))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default React.memo(TrafficTable);
