/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import {
  ButtonBase,
  InputBase,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Alert,
  IconButton,
  Typography,
  Snackbar,
} from '@mui/material';
import {
  Remove,
  Add,
  ArrowDropDown,
  ArrowDropUp,
  UnfoldMore,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
// Styles

import SubHeader from '../../../components/subHeader';
import TmsHeader from '../../../components/header';
import { getScreensList } from '../../../redux/actions/ScreenActions';
import ControlButton from '../../../components/controlButton';
import Select from '../../../components/Select';
import TableItemsText from '../../../components/TableItemsText';
import Pagination from '../../../components/Pagination';
import { getRecordToShowValue, sortedTable } from '../../../utils';
import ConfirmationModal from '../../../components/confirmationModal/index';

// constants
import {
  CLEAR_ORDER_MAINTENANCE,
} from '../../../redux/constants/workflows';

// actions
import {
  dissmissConfirmationAlert,
  removeOrderMaintenanceRecord,
} from '../../../redux/actions/workflows/orderMaintenance';
import OrderMaintenanceTransitionModal from './OrderMaintenanceTransitionModal';
import { useStyles } from './style';

interface ITableState {
  tableData: [];
  currentPage: number;
  searchedDataCount: number;
  searchText: string;
  recordsNumber: string;
  orderBy: string;
  order: string;
  columnsDialogOpen: boolean;
  snackBarOpen: boolean;
}

const OrderMaintenanceBatchDelete = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    totalRecords,
    openConfirmationAlert,
    confirmationAlert,
    selectedRecord,
    totalRecordsWithSearch,
    batchMsg,
  } = useSelector((state: any) => state.Workflows.OrderMaintenanceReducer);
  const [tableState, setTableState] = useState<ITableState>(() => ({
    tableData: [],
    currentPage: 1,
    searchedDataCount: 0,
    recordsNumber: 'Auto',
    columnsDialogOpen: false,
    snackBarOpen: false,
    searchText: '',
    orderBy: '',
    order: '',
  }));
  const [Visible, setVisible] = React.useState(false);
  const recordsToShow = getRecordToShowValue(
    tableState.recordsNumber,
    selectedRecord.length,
    18,
  );
  const countPages = Math.ceil(
    (tableState.searchText ? totalRecordsWithSearch : (totalRecords || 0)) / recordsToShow,
  );

  const handlePaginationChange = (event: any, targetPage: any) => {
    setTableState({ ...tableState, currentPage: targetPage });
  };
  const handleCloseConfirm = () => {
    setVisible(!Visible);
  };

  useEffect(() => {
    setTableState((prevState: ITableState) => ({
      ...prevState,
      tableData: selectedRecord,
    }));
  }, [selectedRecord]);
  const handleSortTable = (key: string) => {
    const orderType = !tableState.order || tableState.order === 'desc' ? 'asc' : 'desc';
    setTableState({ ...tableState, orderBy: key, order: orderType });
  };

  const handleSortClick = (key: string) => (event: React.MouseEvent<unknown>) => {
    if (handleSortTable) {
      handleSortTable(key);
    }
  };
  const handleBackButton = () => {
    navigate('/workflow/order-maintenance');
  };
  const handleRemoveAllButton = () => {
    setVisible(true);
  };
  const handleClose = () => {
    dispatch(dissmissConfirmationAlert());
  };

  useEffect(() => {
    if (batchMsg) {
      dispatch({ type: CLEAR_ORDER_MAINTENANCE });
      navigate('/workflow/order-maintenance');
    }
  }, [batchMsg]);

  const handleSelectChange = (e: any, option: any) => {
    //
  };
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //
  };

  return (
    <>
      <TmsHeader />
      <SubHeader title="Order Maintenance" />
      <Alert
        severity="error"
        sx={{ mb: 2 }}
        style={{ borderLeft: '10px solid red', marginLeft: '10px' }}
      >
        <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
          <Typography style={{ fontWeight: 'bold', color: 'red' }}>
            Warning!
          </Typography>
          The Records Listed below Will be Removed if You Proceed!
        </div>
      </Alert>
      <div style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        gap: '10px',
        padding: '10px',
      }}
      >
        <ControlButton
          color="#ff4444"
          label="Remove All"
          handleClick={handleRemoveAllButton}
          disabled={false}
        />
        <ControlButton
          color="#ffbb33"
          label="Cancel"
          handleClick={handleBackButton}
          disabled={false}
        />

      </div>
      <div style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        paddingLeft: '10px',
      }}
      >
        <h3>
          {' '}
          Records List :
          {' '}
          {selectedRecord.length}
        </h3>
      </div>
      <div
        className={clsx('flexStartSpaceBetweenRow', classes.subHeaderContainer)}
      >
        <div className={clsx('flexRow')}>
          <span className={classes.label}>Records :</span>
          <Select
            options={['Auto', '10', '20', '25', '35', '50', 'All']}
            width={200}
            value={tableState.recordsNumber}
            onChange={handleSelectChange}
          />
        </div>
        <div className="flexRow">
          <span className={classes.label}>Search :</span>
          <InputBase
            className={classes.searchInput}
            value={tableState.searchText}
            onChange={handleSearchChange}
          />
        </div>
      </div>
      <Snackbar
        open={openConfirmationAlert}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={confirmationAlert.includes('successfully') ? 'success' : 'error'}
        >
          {confirmationAlert}
        </Alert>
      </Snackbar>
      <div className={classes.root}>
        <Table classes={{ root: classes.tableRoot }}>
          <TableHead>
            <TableRow classes={{ root: classes.headerRow }}>
              <TableCell classes={{ root: classes.headerCell }}>
                Order Type
              </TableCell>
              <TableCell classes={{ root: classes.headerCell }}>
                Supplier
              </TableCell>
              <TableCell classes={{ root: classes.headerCell }}>
                Order Number
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedTable(tableState.tableData, tableState.orderBy, tableState.order)
              ?.map((row:any, index:number) => (
                <>
                  <TableRow>
                    <TableCell classes={{ root: classes.bodyCell }}>{row.RecType}</TableCell>
                    <TableCell classes={{ root: classes.bodyCell }}>{row.SupplierNo}</TableCell>
                    <TableCell classes={{ root: classes.bodyCell }}>{row.OrderNo}</TableCell>
                  </TableRow>
                </>
              ))}
          </TableBody>
        </Table>
      </div>
      <div className={clsx(classes.footerWrapper, 'flexRowAlignCenter')}>
        <TableItemsText
          allDataCount={(totalRecords as number)}
          countPages={countPages}
          currentPage={(tableState.currentPage as number)}
          recordsToShow={recordsToShow}
          searchText={tableState.searchText}
          searchedDataCount={totalRecordsWithSearch}
        />
        <span className="takeTheRest" />
        <Pagination
          count={countPages}
          onChange={handlePaginationChange}
          pageNumber={tableState.currentPage}
        />
      </div>
      <ConfirmationModal
        Visible={Visible}
        handleCloseConfirm={handleCloseConfirm}
        options="/workflow/order-maintenance"
        removeOrderMaintenanceRecord={removeOrderMaintenanceRecord}
      />
    </>
  );
};

export default OrderMaintenanceBatchDelete;
