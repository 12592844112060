import React, { useEffect, useState } from 'react';

// Librairies
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Alert, Button, Snackbar } from '@mui/material';

// Components
import SubHeader from '../../../components/subHeader';
import TmsHeader from '../../../components/header';
import WorkflowTable from '../../../components/WorkflowTable';

// Styles
import OptionsButton from '../../../components/optionsButton';
import ConditionSetOptions from '../../../components/ConditionSetOptions';

// Actions
import {
  clearConditionSetNotification,
  getConditionSetData,
  updateConditionSetColumns,
} from '../../../redux/actions/workflows/conditionSet';
import { useWorkflowState } from '../../../hooks/useWorkflowState';
import WorkflowTableUtils from '../../../components/WorkflowTableUtils';
import ColumnsDialog from '../../../components/ColumnsDialog';

// Utils
const tableHeader = {
  Description: 'Description',
  ProductFlag: 'Condition Type',
  HandlingTypeCount: 'ERP Handling Type Count',
  ProductCount: 'Product Count',
  ConditionCount: 'ConditionCount',
  ID: 'ID',
};

const searchHeader = [
  { label: 'Description', key: 'Description' },
  { label: 'Condition Type', key: 'ProductFlag' },
  { label: 'ERP Handling Type Count', key: 'HandlingTypeCount' },
  { label: 'Product Count', key: 'ProductCount' },
  { label: 'ConditionCount', key: 'ConditionCount' },
  { label: 'ID', key: 'ID' },
];

const ConditionSetScreen: React.FC = () => {
  const {
    data, visibleColumns, hiddenColumns, responseNotification,
  } = useSelector((state: any) => state.Workflows.ConditionSet);
  const dispatch = useDispatch();
  const [columnsDialogOpen, setColumnsDialogOpen] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const navigate = useNavigate();
  const {
    recordsToShow,
    tableState,
    handlePaginationChange,
    handleSearchChange,
    handleSelectChange,
    handleSortTable,
    handleCloseMenu,
    handleOptionClick,
  } = useWorkflowState(searchHeader, data, getConditionSetData);

  useEffect(() => {
    if (responseNotification?.type === 'success') {
      dispatch(getConditionSetData());
      setSnackBarOpen(true);
    }
  }, [responseNotification?.type]);

  const handleCloseSnackBar = () => {
    setSnackBarOpen(false);
    dispatch(clearConditionSetNotification());
  };

  const handleOpenColumnsDialog = () => {
    setColumnsDialogOpen(true);
    handleCloseMenu();
  };

  const handleCloseColumnsDialog = () => {
    setColumnsDialogOpen(false);
  };

  const handleSubmitNewColumns = (newColumns: string[]) => {
    dispatch(updateConditionSetColumns(newColumns));
  };

  const handleRestoreDefault = () => {
    dispatch(
      updateConditionSetColumns([
        'ProductCount',
        'Description',
        'ProductFlag',
        'HandlingTypeCount',
      ]),
    );
  };

  const handleClickRow = (row: any) => {
    navigate(`recordDetail/${row.ID.trim()}`);
  };

  const goToNewRecord = () => {
    navigate('newRecord');
  };

  return (
    <>
      <TmsHeader />
      <SubHeader title="Condition Set">
        <OptionsButton handleClick={handleOptionClick} />
        <ConditionSetOptions
          anchorEl={tableState.anchorEl}
          onClose={handleCloseMenu}
          openColumnsDialog={handleOpenColumnsDialog}
          goToNewRecord={goToNewRecord}
        />
      </SubHeader>
      <WorkflowTableUtils
        handleSearchChange={handleSearchChange}
        handleSelectChange={handleSelectChange}
        recordsNumber={tableState.recordsNumber}
        searchText={tableState.searchText}
      />
      <WorkflowTable
        handleClickRow={handleClickRow}
        visibleColumns={visibleColumns}
        allDataCount={data.length}
        handlePaginationChange={handlePaginationChange}
        recordsToShow={recordsToShow}
        tableHeader={tableHeader}
        tableState={tableState}
        order={tableState.order}
        orderBy={tableState.orderBy}
        searchText={tableState.searchText}
        sortTable={handleSortTable}
      />
      <ColumnsDialog
        title="Configure Visible Columns"
        onClose={handleCloseColumnsDialog}
        open={columnsDialogOpen}
        visibleColumns={visibleColumns}
        hiddenColumns={hiddenColumns}
        columnsLabels={tableHeader}
        submitNewColumns={handleSubmitNewColumns}
        handleRestoreDefault={handleRestoreDefault}
      />
      <Snackbar
        open={snackBarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackBar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseSnackBar} severity="success">
          SUCCESS
          <div>
            Condition Set updated Successfully
            {' '}
            {responseNotification.title !== 'DELETE_CONDITION_SET_SUCCESS' ? (
              <Button onClick={() => navigate(-1)}>(Review)</Button>
            ) : null}
            {' '}
          </div>
        </Alert>
      </Snackbar>
    </>
  );
};

export default ConditionSetScreen;
