/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';

// Librairies
import { useSelector } from 'react-redux';

// Components
import TmsHeader from '../../../components/header';
import SubHeader from '../../../components/subHeader';
import Loader from '../../../components/Loader';

// Actions
import { getCustomReport } from '../../../redux/actions/reports/customreportconfiguration';
import ButtonsHeader from '../../../components/Reports/Workflows/ButtonsHeader';
import CustomReportDropdownCard from '../../../components/Reports/Workflows/CustomReportDropdownCard';
import CustomReportTreeTableCard from '../../../components/Reports/Workflows/CustomReportTreeTableCard';

const CustomReportConfigurationDetail = () => {
  const [formData, setFormData] = useState<any>({});
  const { data } = useSelector(
    (state: any) => state?.Reports?.CustomReportConfiguration,
  );
  const handleChange = (option: any, name: string) => {
    setFormData({
      ...formData,
      [name]: option.value,
    });
  };

  return (
    <>
      <TmsHeader />
      <SubHeader title="Custom Report Configuration">
        <Loader getWorkflowData={getCustomReport} />
      </SubHeader>
      <ButtonsHeader />
      <CustomReportDropdownCard
        handleChange={handleChange}
        formData={formData}
      />
      <CustomReportTreeTableCard />
    </>
  );
};

export default CustomReportConfigurationDetail;
