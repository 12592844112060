import React from 'react';

// Librairies
import { ButtonBase, Paper, TextareaAutosize } from '@mui/material';
import clsx from 'clsx';

// Components
import Select from '../Select';

// Styles
import { useStyles } from './style';

const ViewTraceLogsSearchCard: React.FC = () => {
  const classes = useStyles();
  return (
    <div>Hello!</div>
    // <Paper elevation={3} className={classes.paper}>
    //   <div className={classes.modalHeader}>
    //     <h4 className={classes.modalTitle}>Audit Search Criteria</h4>
    //   </div>
    //   <div className={classes.modalBody}>
    //     <form action="POST" className={clsx(classes.form, 'flexSpaceAround')}>
    //       <div className={classes.firstGroup}>
    //         <div className={classes.inputGroup}>
    //           <div className={classes.label}>Filter</div>
    //           <div style={{ flexGrow: 1 }}><Select options={[]} /></div>
    //         </div>
    //         <div className={classes.inputGroup}>
    //           <div className={classes.label}>Start Audit Date</div>
    //           <div style={{ flexGrow: 1 }}><Select options={[]} /></div>
    //         </div>
    //         <div className={classes.inputGroup}>
    //           <div className={classes.label}>End Audit Date</div>
    //           <div style={{ flexGrow: 1 }}><Select options={[]} /></div>
    //         </div>
    //         <div className={classes.inputGroup}>
    //           <div className={classes.label}>Max Lines</div>
    //           <div style={{ flexGrow: 1 }}><Select options={[]} /></div>
    //         </div>
    //       </div>
    //       <div className={classes.firstGroup}>
    //         <div className={classes.inputGroup}>
    //           <div className={classes.label}>Share</div>
    //           <div style={{ flexGrow: 1 }}><Select options={[]} /></div>
    //         </div>
    //         <div className={classes.inputGroup}>
    //           <div className={classes.label}>Search Pattern</div>
    //           <div style={{ flexGrow: 1 }}>
    //             <TextareaAutosize
    //               maxRows={4}
    //               style={{ resize: 'vertical', width: '99%', height: '112.5px' }}
    //             />
    //           </div>
    //         </div>
    //       </div>
    //     </form>
    //   </div>
    //   <div className={classes.modalActions}>
    //     <ButtonBase className={classes.removeButton}>Remove</ButtonBase>
    //     <ButtonBase className={classes.applyButton}>Apply</ButtonBase>
    //     <ButtonBase className={classes.cancleButton}>Cancel</ButtonBase>
    //   </div>
    // </Paper>
  );
};

export default ViewTraceLogsSearchCard;
