import React, { useEffect, useState } from 'react';

// Librairies
import { InputBase } from '@mui/material';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';

// Components
import SubHeader from '../../../components/subHeader';
import TmsHeader from '../../../components/header';
import ConfirmDialog from '../../../components/ConfirmDialog';

// Styles
import { useStyles } from './style';

// Actions
import { getScreensList } from '../../../redux/actions/ScreenActions';
import {
  deletePersonnelInFacilityItem,
  getPersonnelInFacilityData,
} from '../../../redux/actions/workflows/personnelInFacility';

// Utils
import { searchRecords } from '../../../utils';
import PersonnelInFacilityTable from '../../../components/PersonnelInFacilityTable';
import Loader from '../../../components/Loader';

const tableHeader: { label: string; key: string }[] = [
  { label: 'Driver', key: 'driver_no' },
  { label: 'security_area', key: 'security_area' },
  { label: 'Bay', key: 'ld_bay' },
  { label: 'Type', key: 'rtype' },
  { label: 'Entry Date / Time', key: 'entry_datetime' },
];

interface ITableState {
  orderBy: string;
  order: string;
  confirmDialogOpen: boolean;
  searchText: string;
  tableData: any[];
  selectedRow: any;
}

const PersonnelInFacility: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { data } = useSelector(
    (state: any) => state.Workflows.PersonnelInFacility,
  );
  const [tableState, setTableState] = useState<ITableState>(() => ({
    orderBy: '',
    order: '',
    confirmDialogOpen: false,
    searchText: '',
    tableData: [],
    selectedRow: null,
  }));

  useEffect(() => {
    dispatch(getScreensList());
    dispatch(getPersonnelInFacilityData());
  }, []);

  useEffect(() => {
    if (tableState.searchText) {
      const filtredData = searchRecords(
        tableState.searchText,
        data,
        tableHeader,
      );
      setTableState((prevState: ITableState) => ({
        ...prevState,
        tableData: filtredData,
      }));
    } else {
      setTableState((prevState: ITableState) => ({
        ...prevState,
        tableData: data,
      }));
    }
  }, [tableState.searchText, data]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTableState((prevState: ITableState) => ({
      ...prevState,
      searchText: e.target.value,
    }));
  };

  const handleSortTable = (key: string) => {
    const orderType = !tableState.order || tableState.order === 'desc' ? 'asc' : 'desc';
    setTableState((prevState: ITableState) => ({
      ...prevState,
      orderBy: key,
      order: orderType,
    }));
  };

  const openConfirmDialog = (row: any) => {
    setTableState((prevState: ITableState) => ({
      ...prevState,
      confirmDialogOpen: true,
      selectedRow: row,
    }));
  };

  const closeConfirmDialog = () => {
    setTableState((prevState: ITableState) => ({
      ...prevState,
      confirmDialogOpen: false,
    }));
  };

  const confirmDeleteClose = () => {
    dispatch(deletePersonnelInFacilityItem(tableState.selectedRow.ID));
    setTableState((prevState: ITableState) => ({
      ...prevState,
      confirmDialogOpen: false,
    }));
  };

  return (
    <>
      <TmsHeader />
      <SubHeader title="Personnel In Facility">
        <Loader getWorkflowData={getPersonnelInFacilityData} />
      </SubHeader>
      <div
        className={clsx('flexStartSpaceBetweenRow', classes.subHeaderContainer)}
      >
        <div />
        <div className="flexRow">
          <InputBase
            className={classes.input}
            placeholder="Search"
            value={tableState.searchText}
            onChange={handleSearchChange}
          />
          <span className={classes.helperText}>
            Viewing
            {' '}
            {tableState.tableData?.length || 0}
            {' '}
            of
            {' '}
            {data?.length || 0}
          </span>
        </div>
      </div>
      <PersonnelInFacilityTable
        handleClickRow={openConfirmDialog}
        tableData={tableState.tableData}
        tableHeader={tableHeader}
        searchText={tableState.searchText}
        sortTable={handleSortTable}
        orderBy={tableState.orderBy}
        order={tableState.order}
      />
      {tableState.selectedRow && tableState.confirmDialogOpen ? (
        <ConfirmDialog
          cancelLabel="Cancel"
          confirmLabel="Yes"
          handleCloseAgree={confirmDeleteClose}
          handleCloseCancel={closeConfirmDialog}
          message={`Are you sure to delete the record: Driver ${
            tableState.selectedRow.ID.split('_')[0]
          } / Security Area ${tableState.selectedRow.security_area}?`}
          open={tableState.confirmDialogOpen}
          title="Confirmation"
        />
      ) : null}
    </>
  );
};

export default PersonnelInFacility;
