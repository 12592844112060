/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable no-nested-ternary */
/* eslint-disable implicit-arrow-linebreak */
import React, { useEffect, useState } from 'react';

// Libraries
import {
  Alert,
  ButtonBase,
  DialogActions,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

// Components
import { DialogComponent } from '../Dialog/Dialog.component';
import Input from '../Input';
import Select from '../Select';

// Styles
import { useStyles } from './style';

interface IProps {
  onClose: () => void;
  open: boolean;
  selectedRow: any;
  selectedRowIndex: any;
  adjustmentModalFeilds: any;
  fetchAutoTotalize: () => void;
  applyAdjustments: (
    adjustmentsSum: number,
    adjustmentsObject: { [key: string]: string },
    endMeter: string,
    autoTotalized: boolean
  ) => void;
}

type reasonType = 'noReasonForAdjustment' | 'noAdjustmentForReason';

const getFormFields = (fields: any) =>
  fields?.reduce((acc: any, curr: any, i: number, arr: any) => {
    if (i % 2 === 0) {
      return [...acc, [curr, arr[i + 1]]];
    }
    return acc;
  }, []);

const getWarningByType = (
  formFields: any[],
  modalState: any,
  type: reasonType,
) =>
  formFields.some(([adjustment, reason]: any) =>
    (type === 'noReasonForAdjustment'
      ? modalState[adjustment.id] && !modalState[reason.id]?.value
      : modalState[reason.id]?.value && !modalState[adjustment.id]));

type OptionType = { value: string; label: string };

const MeterAdjustmentsDialog: React.FC<IProps> = ({
  onClose,
  open,
  selectedRow,
  adjustmentModalFeilds,
  fetchAutoTotalize,
  applyAdjustments,
  selectedRowIndex,
}) => {
  const classes = useStyles();
  const [warningMessage, setWarningMessage] = useState<string | null>(null);
  const [modalState, setModalState] = useState<{
    [key: string]: string | boolean | OptionType;
  }>(() =>
    adjustmentModalFeilds?.adjustmentReasonFields?.[0]?.reduce(
      (acc: any, curr: any) => {
        if (curr.type === 'select') {
          const value = curr?.list?.find(
            (element: any) => element?.value === selectedRow?.[curr?.id],
          );
          return {
            ...acc,
            [curr.id]: value || '',
          };
        }
        return {
          ...acc,
          [curr.id]: selectedRow[curr.id] || '',
        };
      },
      { end_meter: selectedRow?.end_meter, autoTotalized: false },
    ));
  const {
    formData,
    currentRecord: { totalizeData },
  } = useSelector((state: any) => state.Workflows.MeterEntry);

  const formFields = getFormFields(
    adjustmentModalFeilds?.adjustmentReasonFields?.[0],
  );

  useEffect(() => {
    if (formData[selectedRowIndex]) {
      const newModalState = { ...modalState };
      Object.keys(newModalState).forEach((key) => {
        newModalState[key] = formData[selectedRowIndex][key] || newModalState[key];
      });
      setModalState(newModalState);
    } else {
      setModalState((prev) => ({
        ...prev,
        end_meter: selectedRow.end_meter,
      }));
    }
  }, [selectedRow, formData, selectedRowIndex]);

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setModalState({
      ...modalState,
      [event.target.name]: event.target.value.slice(0, 9),
    });
  };

  useEffect(() => {
    if (modalState.autoTotalized) {
      setModalState((prev) => ({ ...prev, end_meter: totalizeData }));
    }
  }, [totalizeData, modalState.autoTotalized]);

  const handleSelectChange = (id: string, option: string) => {
    setModalState({ ...modalState, [id]: option });
  };

  const handleApply = () => {
    const noReasonForAdjustment = getWarningByType(
      formFields,
      modalState,
      'noReasonForAdjustment',
    );
    const noAdjustmentForReason = getWarningByType(
      formFields,
      modalState,
      'noAdjustmentForReason',
    );
    const warnMessage = noReasonForAdjustment
      ? 'Please include reason for adjustment(s)'
      : noAdjustmentForReason
        ? 'Please include adjustment for reason(s)'
        : null;
    setWarningMessage(warnMessage);
    if (!warnMessage) {
      const adjustmentsTable = Object.keys(modalState).filter((elem) =>
        elem.includes('adjustment'));
      const adjustmentsReasonsTable = Object.keys(modalState).filter(
        (elem) => elem.includes('adjustment') || elem.includes('reason'),
      );
      const adjustmentsObject = adjustmentsReasonsTable.reduce(
        (acc, curr) => ({
          ...acc,
          [curr]: modalState[curr],
        }),
        {},
      );
      const adjustmentSum = adjustmentsTable.reduce(
        (acc: any, curr: any) => (acc += Number(modalState[curr])),
        0,
      );
      applyAdjustments(
        adjustmentSum,
        adjustmentsObject,
        String(modalState.end_meter),
        modalState.autoTotalized as boolean,
      );
    }
  };

  const handleAutoTotalize = () => {
    setModalState({ ...modalState, autoTotalized: true });
    fetchAutoTotalize();
  };

  return (
    <DialogComponent title="Meter Adjustments" onClose={onClose} open={open}>
      <div className={classes.dialogBody}>
        <div className={clsx(classes.headerWrapper, 'flexSpaceAround')}>
          <div>
            <div className={classes.headerTitle}>Bay</div>
            <div className={classes.headerValue}>
              {selectedRow?.ld_bay_name || ''}
            </div>
          </div>
          <div>
            <div className={classes.headerTitle}>Preset</div>
            <div className={classes.headerValue}>
              {selectedRow?.ld_ctl_name || ''}
            </div>
          </div>
          <div>
            <div className={classes.headerTitle}>Meter</div>
            <div className={classes.headerValue}>
              {selectedRow?.ld_mtr_name || ''}
            </div>
          </div>
          <div>
            <div className={classes.headerTitle}>Product</div>
            <div className={classes.headerValue}>
              {selectedRow?.m_product_name || ''}
            </div>
          </div>
        </div>
        <Divider variant="middle" />
        <div>
          <div
            className="flexRow"
            style={{ padding: '0px 10px', paddingTop: 15, paddingBottom: 20 }}
          >
            <div
              className={clsx('flexRow', classes.wrapper)}
              style={{ width: '50%' }}
            >
              <Typography className={classes.label}>Beginning</Typography>
              <Input
                onChange={() => null}
                style={{ width: '75%' }}
                type="number"
                value={selectedRow?.begin_meter}
                disabled
              />
            </div>
            <div
              className={clsx('flexRow', classes.wrapper)}
              style={{ width: '50%' }}
            >
              <Typography className={classes.label}>Ending</Typography>
              <Input
                onChange={handleChangeInput}
                style={{ width: '75%' }}
                type="number"
                value={modalState.end_meter as string}
                name="end_meter"
                placeholder="Ending"
                maxLength={9}
                noShowLength
              />
            </div>
          </div>
        </div>
        <Divider variant="middle" className={classes.divider} />
        <div style={{ padding: '0px 10px' }}>
          {formFields?.map(([adjustment, reason]: any) => (
            <div
              className="flexRow"
              style={{ paddingBottom: 10 }}
              key={adjustment.id}
            >
              <div
                className={clsx('flexRow', classes.wrapper)}
                style={{ width: '50%' }}
              >
                <Typography className={classes.label}>
                  {adjustment.label}
                </Typography>
                <Input
                  onChange={handleChangeInput}
                  style={{ width: '75%' }}
                  type="number"
                  placeholder={adjustment.label}
                  name={adjustment.id}
                  value={modalState[adjustment.id] as string}
                  disabled={!modalState.end_meter}
                  maxLength={9}
                  noShowLength
                />
              </div>
              <div
                className={clsx('flexRow', classes.wrapper)}
                style={{ width: '50%' }}
              >
                <Typography className={classes.label}>
                  {reason.label}
                </Typography>
                <Select
                  options={[
                    { value: '', label: '--Select--', selected: false },
                    ...(reason.list || []),
                  ]}
                  onChange={(e: any, option: any) =>
                    handleSelectChange(reason.id, option)}
                  style={{ width: '75%' }}
                  name={reason.id}
                  value={modalState[reason.id]}
                  disabled={!modalState.end_meter}
                  disableClearInput
                />
              </div>
            </div>
          ))}
          {warningMessage ? (
            <Alert
              severity="warning"
              icon={false}
              className={classes.alert}
              action={(
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setWarningMessage(null);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              )}
            >
              <strong className={classes.warningTitle}>Warning!</strong>
              {' '}
              <span className={classes.warningMessage}>{warningMessage}</span>
            </Alert>
          ) : null}
        </div>
      </div>
      <Divider variant="middle" />
      <DialogActions>
        <ButtonBase
          classes={{ root: classes.applyButton }}
          onClick={handleApply}
        >
          Apply
        </ButtonBase>
        <ButtonBase classes={{ root: classes.closeButton }} onClick={onClose}>
          Cancel
        </ButtonBase>
        <ButtonBase
          className={classes.freezeButton}
          onClick={handleAutoTotalize}
        >
          Auto Totalize
        </ButtonBase>
      </DialogActions>
    </DialogComponent>
  );
};

export default MeterAdjustmentsDialog;
