import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {},
  popoverRoot: {
    borderRadius: '0px !important',
  },
  paperRoot: {
    padding: '2px 2px !important',
  },
  header: {
    height: 23,
    paddingTop: 2,
    whiteSpace: 'nowrap',
    backgroundColor: '#d2d2d2',
    borderBottom: '1px solid #b3b3b3',
    borderRadius: 'unset',
    paddingLeft: 15,
  },
  wrapper: { padding: '9px 14px', display: 'flex', alignItems: 'flex-start' },
  textArea: {
    width: '100% !important',
    minWidth: '500px !important',
    border: '1px solid #adadad !important',
    backgroundColor: '#f4f5fc !important',
    resize: 'none',
    height: '120px !important',
  },
  checkButton: {
    backgroundColor: '#337ab7 !important',
    border: 'solid 1px rgb(51, 122, 183) !important',
    marginLeft: '7px !important',
    '&:hover': {
      backgroundColor: '#286090 !important',
    },
  },
  checkIcon: { color: 'white !important' },
  closeButton: {
    marginLeft: '7px !important',
    border: 'solid 1px #ddd !important',
  },
  emptyStyle: {
    color: 'red',
    textDecoration: 'none',
    borderBottom: 'dashed 1px #0088cc',
    fontStyle: 'italic',
  },
  filledStyle: {
    borderBottom: 'dashed 1px #0088cc',
  },
});
