import { Dispatch } from 'redux';
import Api from '../../api/index';
import { LOAD_TRAFFICS } from '../../constants/workflows';

// utils
const formatType = (type: string) => {
  if (!type) return null;
  switch (type) {
    case 'D': {
      return 'Load Bay';
    }
    default:
      return type;
  }
};

const formatProcessingType = (procType: string) => {
  if (!procType) return null;
  switch (procType) {
    case 'traffic_disabled': {
      return 'Traffic Disabled';
    }
    case 'traffic_enabled': {
      return 'Traffic Enabled';
    }
    default:
      return procType;
  }
};

export const getTrafficData = () => async (dispatch: Dispatch) => {
  const response = await Api.get(
    'workflow023/data?screenID=WORKFLOW-023',
  );

  const data = response.data.data.map((x: any) => ({
    ...x,
    rtype: formatType(x.rtype),
    ProcessingType: formatProcessingType(x.ProcessingType),
  }));

  dispatch({
    type: LOAD_TRAFFICS,
    payload: {
      data,
      headers: response.data.headers,
    },
  });
};
