import { Dispatch } from 'redux';
import Api from '../../api/index';
import {
  GET_CARRIERS_DTF, GET_DRIVERS_DTF, LOAD_DRIVERS_THROUGH_FACILITY, LOAD_DRIVERS_THROUGH_FACILITY_OPTION,
} from '../../constants/workflows';

export const getDriversThroughFacilityData = () => async (dispatch: Dispatch) => {
  const response = await Api.get('workflow022/data?terminalid=B344');
  dispatch({
    type: LOAD_DRIVERS_THROUGH_FACILITY,
    payload: response.data.data,
  });
};

export const getDriversThroughFacilityOptionModal = () => async (dispatch: Dispatch) => {
  const response = await Api.get('workflow022/optionModalFields?terminalid=B344');
  dispatch({
    type: LOAD_DRIVERS_THROUGH_FACILITY_OPTION,
    payload: response.data.data,
  });
};

export const getDrivers = (terminalid: string) => async (dispatch: Dispatch) => {
  const response = await Api.get(`workflow021/optionModalDropdownDriver?screenID=WORKFLOW-021&where=${terminalid}`);
  dispatch({
    type: GET_DRIVERS_DTF,
    payload: response.data.data,
  });
};
export const getCarriers = (terminalid: string, driver: string) => async (dispatch: Dispatch) => {
  const response = await Api.get(`workflow021/optionModalDropdownCarrier?screenID=WORKFLOW-021&term_id=${terminalid}&driver=${driver}`);
  dispatch({
    type: GET_CARRIERS_DTF,
    payload: response.data.data,
  });
};
