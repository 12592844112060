/* eslint-disable no-restricted-globals */
import React, { useEffect } from 'react';

type Props = {
 setCountPopup: any,
 countPopup: any,
 popupData: any,
}

const ScreenCount = (props: Props) => {
  const { countPopup, setCountPopup, popupData } = props;

  const spanClicked = (e : any) => {
    parent.postMessage({ target: e.target.value, link: `/maint/${popupData?.linkparams?.screenID}`, countPopup });
    setCountPopup(!countPopup);
  };

  const renderSpans = () => {
    // eslint-disable-next-line no-restricted-globals
    const arr = new Array(parent.window.length).fill(0);
    return arr.map((span, i) => (
      // eslint-disable-next-line react/button-has-type
      <button className="item" onClick={(e) => spanClicked(e)} value={i}>
        -
        {i + 1}
        -
      </button>
    ));
  };

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    parent.postMessage({ countPopup });
  });

  return (
    <div
      id="linktargetframe"
      style={countPopup ? {
        display: 'block', position: 'absolute', zIndex: 1, top: '111px', left: '175px',
      } : { display: 'none' }}
    >
      <h3 id="linktargetframe_title">
        <button type="button" className="close" onClick={() => setCountPopup(false)}>×</button>
        Select Destination Tile
      </h3>
      <div id="linktargetframe_body">
        <span
          className="item"
        >
          <a
            onClick={() => setCountPopup(!countPopup)}
            href={`/?url=/maint/${popupData?.linkparams?.screenID}&ismaint=${true}`}
            target="_blank"
            style={{ textDecoration: 'none', color: 'black' }}
            rel="noreferrer"
          >
            New
          </a>
        </span>
        {renderSpans()}
      </div>
    </div>
  );
};

export default ScreenCount;
