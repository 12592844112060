// /* eslint-disable no-nested-ternary */
import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import { visuallyHidden } from '@mui/utils';
import { useStyles } from './style';

interface Data {
  output: string;
  vipname: string;
  vipid: string;
  command: string;
  status: string;
  description: string;
}

function createData(
  output: string,
  vipname: string,
  vipid: string,
  command: string,
  status: string,
  description: string,
): Data {
  return {
    output,
    vipname,
    vipid,
    command,
    status,
    description,
  };
}

// const rows = [
//   createData('08-24-2022 14:00:24', 'SCULLY', '001', 'Authorize', '', 'ERROR: Sending Message.'),

// ];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'output',
    numeric: false,

    label: 'Output',
  },
  {
    id: 'vipname',
    numeric: false,

    label: 'VIP Name',
  },
  {
    id: 'vipid',
    numeric: false,

    label: 'VIP Id',
  },
  {
    id: 'command',
    numeric: false,

    label: 'Command',
  },
  {
    id: 'status',
    numeric: false,

    label: 'Status',
  },
  {
    id: 'description',
    numeric: false,

    label: 'Description',
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

const EnhancedTableHead = (props: EnhancedTableProps) => {
  const {
    onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort,
  } = props;
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };
  const classes = useStyles();

  return (
    <TableHead>
      <TableRow classes={{ root: classes.headerRow }}>
        {headCells.map((headCell) => (
          <TableCell
            classes={{ root: classes.headerCell }}
            key={headCell.id}
            // align={headCell.numeric ? 'right' : 'left'}
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

interface IProps {
  handleClickRow: () => void;
}

const CommonTable = ({ rows }:any) => {
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('output');
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const classes = useStyles();

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n:any) => n.output);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    console.log('newPage', newPage);
    console.log('setPage', newPage - 1);
    setPage(newPage - 1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ paddingLeft: '5px', paddingRight: '5px' }}>

      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size="small"
        >
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.slice().sort(getComparator(order, orderBy)) */}
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row:any, index:any) => {
                const isItemSelected = isSelected(row.output);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.output)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.output}
                    selected={isItemSelected}
                  >

                    <TableCell
                      classes={{ root: classes.bodyCell }}
                    >
                      {row.output}
                    </TableCell>
                    <TableCell classes={{ root: classes.bodyCell }}>{row.vipname}</TableCell>
                    <TableCell classes={{ root: classes.bodyCell }}>{row.vipid}</TableCell>
                    <TableCell classes={{ root: classes.bodyCell }}>{row.command}</TableCell>
                    <TableCell classes={{ root: classes.bodyCell }}>{row.status}</TableCell>
                    <TableCell classes={{ root: classes.bodyCell }}>{row.description}</TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
            <TableRow
              style={{
                height: (33) * emptyRows,
              }}
            >
              <TableCell colSpan={6} />
            </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box className={classes.boxLine} />
      <div style={{
        justifyContent: 'space-between', display: 'flex', alignItems: 'center', marginLeft: '9px', marginRight: '9px',
      }}
      >
        {(rows.length) ? <Typography variant="caption" className="f12">{`Showing ${page === 0 ? page + 1 : Number(rowsPerPage) * Number(page)} to ${page === 0 ? rowsPerPage : rowsPerPage * page - (rowsPerPage - rows.length)} of ${rows.length} entries`}</Typography> : <Typography variant="caption" className="f12">No records available</Typography> }
        <Pagination count={Math.ceil(rows.length / rowsPerPage)} onChange={handleChangePage} page={page + 1} variant="outlined" shape="rounded" style={{ alignSelf: 'flex-end' }} />
      </div>
    </Box>
  );
};

export default CommonTable;
