export const tableHeader = {
  meter_no: 'Meter Number',
  prod_no: 'Product',
  tt_date: 'Date',
  tt_time: 'Time',
  initials: 'Initials',
  begin_meter: 'Meter Beginning',
  end_meter: 'Meter End',
  adjustment_A1: 'Adjustment A1',
  reason_A1: 'Reason A1',
  adjustment_A2: 'Adjustment A2',
  reason_A2: 'Reason A2',
  adjustment_A3: 'Adjustment A3',
  reason_A3: 'Reason A3',
  adjustment_A4: 'Adjustment A4',
  reason_A4: 'Reason A4',
  adjustment_A5: 'Adjustment A5',
  reason_A5: 'Reason A5',
  thruput: 'Thruput',
  mtd_thruput: 'MTD Thruput',
  ytd_thruput: 'YTD Thruput',
};

export const searchHeader = [
  { key: 'meter_no', label: 'Meter Number' },
  { key: 'prod_no', label: 'Product' },
  { key: 'tt_date', label: 'Date' },
  { key: 'tt_time', label: 'Time' },
  { key: 'initials', label: 'Initials' },
  { key: 'begin_meter', label: 'Meter Beginning' },
  { key: 'end_meter', label: 'Meter End' },
  { key: 'adjustment_A1', label: 'Adjustment A1' },
  { key: 'reason_A1', label: 'Reason A1' },
  { key: 'adjustment_A2', label: 'Adjustment A2' },
  { key: 'reason_A2', label: 'Reason A2' },
  { key: 'adjustment_A3', label: 'Adjustment A3' },
  { key: 'reason_A3', label: 'Reason A3' },
  { key: 'adjustment_A4', label: 'Adjustment A4' },
  { key: 'reason_A4', label: 'Reason A4' },
  { key: 'adjustment_A5', label: 'Adjustment A5' },
  { key: 'thruput', label: 'Thruput' },
  { key: 'mtd_thruput', label: 'MTD Thruput' },
  { key: 'ytd_thruput', label: 'YTD Thruput' },
];
