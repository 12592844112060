/* eslint-disable no-restricted-globals */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';

// Librairies
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {
  MenuItem, Select, Switch, TextField, Typography, Popover,
} from '@mui/material';
import {
  Link as LinkIcon,
} from '@mui/icons-material';
import { Link } from 'react-router-dom';

// Styles
import { useStyles } from './style';

// Components
import ProgressBar from '../tankStatusProgressBar/ProgressBar';
import ScreenCount from './ScreenCount';

const TankStatusTable = ({ rows }: any) => {
  const classes = useStyles();

  const [search, setSearch] = React.useState<string>('');
  const [searching, setSearching] = React.useState(false);
  const [inFocus, setInFocus] = React.useState(false);
  const [ShowOptionalFields, setShowOptionalFields] = React.useState(false);
  const [searchTableData, setSearchTableData] = React.useState([]);
  const [countPopup, setCountPopup] = React.useState<boolean>(false);
  const [popupData, setPopupData] = React.useState<any>();

  const handlePopupData = (data:any) => {
    setPopupData(data);
  };

  // Show optional Fields
  const switchOptionalFields = () => {
    setShowOptionalFields(!ShowOptionalFields);
  };
  // search
  const handleSearch = (searchStr:string) => {
    setSearch(searchStr);
    setSearching(searchStr?.length > 0 ?? false);
    const filteredRows = rows?.list_entry?.filter((item:any) => Object.values(item?.fieldList ?? [])
      .map((e:any) => e.value)
      .some((entry:any) => entry?.toString().toLowerCase().includes(searchStr.toLowerCase())));
    setSearchTableData(filteredRows);
  };

  const SmartLinkLabel = ({
    // eslint-disable-next-line @typescript-eslint/no-shadow
    cellLabel, toolTipTitle, toolTipMessage, linkData, handlePopupData,
  }: any) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    // State sent to maint screen
    // Contains data of a single Product/Tank to show detail
    const targetState = {
      tableID: linkData.linkparams.tableid,
      screenID: linkData.linkparams.screenID,
      where: linkData.linkparams.reckey,
      routeDetails: `/maint/${linkData.linkparams.screenID}/`,
      modifyDep: linkData.linkparams.reckey,
      recordsList: [],
    };

    const hideShowPopup = () => {
      handlePopupData();
      setCountPopup(true);
      setAnchorEl(null);
    };

    return (
      <div>
        <Typography onClick={handleClick} className={classes.popoverLabel}>{cellLabel}</Typography>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          <div style={{ justifyContent: 'center' }}>
            <div className={classes.popoverHeadStyle}>
              <div>{toolTipTitle?.trim()}</div>
              <Link
                // eslint-disable-next-line no-restricted-globals
                to={parent.window.length > 1 ? '' : '/maint-details'}
                state={targetState}
                style={{ textDecoration: 'none' }}
                onClick={hideShowPopup}
                // onClick={(e: any) => console.log('LinktargetState', targetState)}
              >
                <LinkIcon style={{ color: '#fff' }} />
              </Link>
            </div>
            <div className={classes.popoverBodyStyle}>
              {toolTipMessage.split('<br />')?.map((line: string) => (
                <div>{line?.trim()}</div>
              ))}
            </div>
          </div>
        </Popover>
      </div>
    );
  };

  const EnhancedProgressBar = ({
    value, percentValue, percentClass, percentType,
  }: any) => {
    // #e8e8e8 is gray
    // #b7ed42 is green
    // #adbce6 is blue
    const barColor = percentType === null
      ? 'white' : percentType === '1' ? '#b7ed42' : percentType === '2' ? '#e8e8e8' : '#adbce6';
    const barColorByType = percentClass === 'alevels' && percentType !== null
      ? 'red'
      : percentClass === 'wlevels'
        ? 'orange'
        : barColor;
    return (
      <ProgressBar
        alertLineValue={percentType ? percentValue : undefined}
        bgcolor={barColorByType}
        fillColor="white"
        completed={value > 0 ? value : 0}
        maxlength="100%"
      />
    );
  };

  return (
    <>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div className={classes.optionalCheckboxDiv}>
          <Typography>Show / Hide Extra Fields</Typography>
          <Switch value={ShowOptionalFields} onChange={switchOptionalFields} />
        </div>
        <div style={{
          width: 'fit-content',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 4,
        }}
        >
          <TextField
            size="small"
            label="search"
            inputProps={{
              maxLength: 100,
            }}
            value={search}
            onFocus={() => setInFocus(true)}
            onBlur={() => setInFocus(false)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSearch(e.target.value)}
          />
          {inFocus && (
          <Typography
            style={{
              marginLeft: -48,
              marginRight: 8,
              fontSize: 12,
              color: 'gray',
              zIndex: 2,
            }}
          >
            {`${search?.length} / 100`}
          </Typography>
          )}

          <div className={classes.viewingCountLabel}>
            {`Viewing 
              ${(searching ? searchTableData?.length : rows?.list_entry?.length) ?? 0} of 
              ${rows?.list_entry?.length ?? 0}`}
          </div>
        </div>

      </div>
      <TableContainer component={Paper} style={{ height: 650 }}>
        <Table sx={{ minWidth: 650, boxShadow: 12 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHead} />
              {rows?.fieldList?.map((singleHeadCell: any) => (
                (singleHeadCell?.class as string)?.includes('extra') ? ShowOptionalFields
                && (
                <TableCell className={classes.tableHeadOptional}>
                  <Typography>{singleHeadCell?.header}</Typography>
                </TableCell>
                )
                  : (singleHeadCell?.id as string) === 'percentage'
                    ? (
                      <TableCell className={classes.tableHead} style={{ }}>
                        <Typography>{singleHeadCell?.header}</Typography>
                      </TableCell>
                    )
                    : (
                      <TableCell className={classes.tableHead}>
                        <Typography>{singleHeadCell?.header}</Typography>
                      </TableCell>
                    )
              ))}
            </TableRow>
          </TableHead>
          <TableBody sx={{ boxShadow: 12 }}>
            {(searching ? searchTableData : rows?.list_entry)?.map((item: any) => (
              <TableRow className={classes.tableRow}>
                <TableCell
                  align="left"
                  className={classes.tableCell}
                >
                  <Select
                    size="small"
                    variant="standard"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Rows"
                    style={{ width: '50%' }}
                  >
                    <MenuItem className="f12" value={10}> </MenuItem>
                    <MenuItem className="f12" value={15}> </MenuItem>
                  </Select>
                </TableCell>
                {item?.fieldList?.map((singleCell: any) => (
                  (singleCell?.class as string)?.includes('extra')
                  && !(singleCell?.id as string)?.includes('percentage')
                    ? ShowOptionalFields
                  && (
                  <TableCell
                    align="left"
                    className={classes.tableCell}
                  >
                    {singleCell.value}
                  </TableCell>
                  )
                    : (singleCell?.id as string)?.includes('percentage')
                      ? (
                        <TableCell
                          align="left"
                          className={classes.tableCell}
                          style={{
                            width: 240,
                            flexDirection: 'row',
                            flexWrap: 'nowrap',
                            alignItems: 'center',
                          }}
                        >
                          <div style={{
                            flexDirection: 'row',
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                          >
                            <span style={{
                              padding: 5,
                              color: 'black',
                            }}
                            >
                              {`${singleCell?.value > 100 ? '> 100' : singleCell?.value}%`}

                            </span>
                            <EnhancedProgressBar
                              value={singleCell.value}
                              percentValue={singleCell?.percentage_value}
                              percentClass={singleCell?.percentage_class}
                              percentType={singleCell?.prod_type}
                            />
                          </div>
                        </TableCell>
                      )
                      : (singleCell?.class?.includes('product')
                        || singleCell?.class?.includes('tank'))
                        && Object.keys(singleCell?.smartLinkData ?? [])?.length > 0
                        ? (
                          <TableCell
                            align="left"
                            className={classes.tableCell}
                          >
                            <SmartLinkLabel
                              cellLabel={singleCell.value}
                              toolTipTitle={singleCell?.smartLinkData?.toolTipTitle}
                              toolTipMessage={singleCell?.smartLinkData?.toolTipMessage}
                              linkData={singleCell?.smartLinkData}
                              handlePopupData={() => handlePopupData(singleCell?.smartLinkData)}
                            />
                          </TableCell>
                        )
                        : (singleCell?.id as string)?.includes('level_status')
                          ? (
                            <TableCell
                              align="left"
                              className={classes.tableCell}
                              style={{
                                backgroundColor: singleCell?.['background-color'] ?? 'black',
                              }}
                            >
                              <div
                                style={{ color: singleCell?.color ?? 'black' }}
                              >
                                {singleCell.value}
                              </div>
                            </TableCell>
                          )
                          : (
                            <TableCell
                              align="left"
                              className={classes.tableCell}
                            >
                              {singleCell.value}
                            </TableCell>
                          )
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {(parent.window.length > 1) && <ScreenCount popupData={popupData} countPopup={countPopup} setCountPopup={setCountPopup} />}
    </>
  );
};
export default TankStatusTable;
