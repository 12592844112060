/* eslint-disable operator-linebreak */
import { Dispatch } from 'redux';
import Api from '../../api/index';
import {
  CLEAR_CONDITION_SET,
  LOAD_CONDITION_SET_RECORD,
  LOAD_CONDITION_SET,
  SET_CONDITION_SET_FORM_HEADER,
  SET_CONDITION_SET_RESPONSE_NOTIFICATION,
  CLEAR_CONDITION_SET_RESPONSE_NOTIFICATION,
} from '../../constants/workflows';

export const getConditionSetData = () => async (dispatch: Dispatch) => {
  const userID = localStorage.getItem('username');
  const response = await Api.get(
    `workflow140/data?screenID=WORKFLOW-140&userID=${userID}`,
  );
  const formattedData = response.data.data.map((elem: any) => ({
    ...elem,
    ProductFlag: elem.ProductFlag === '0' ? 'Transaction' : 'Product',
  }));
  dispatch({
    type: LOAD_CONDITION_SET,
    payload: {
      data: formattedData,
      headerList: response.data.headerList,
      hiddenColumns: response.data.hiddenColumns,
      visibleColumns: response.data.visibleColumns,
    },
  });
};

export const updateConditionSetColumns =
  (columns: string[]) => async (dispatch: Dispatch) => {
    const userID = localStorage.getItem('username');
    await Api.post('workflow140/updatecolumns', { userID, columns });
    window.location.reload();
    dispatch({
      type: CLEAR_CONDITION_SET,
    });
  };

export const getConditionSetRecord =
  ({ conditionSetId }: any) => async (dispatch: Dispatch) => {
    const response = await Api.get(
      `workflow140/showrecord?termID=B344&conditionSetID=${conditionSetId}`,
    );
    dispatch({
      type: LOAD_CONDITION_SET_RECORD,
      payload: response.data,
    });
  };

export const getConditionSetNewRecord = () => async (dispatch: Dispatch) => {
  const response = await Api.get(
    'workflow140/showrecord?termID=B344&newMode=1',
  );
  dispatch({
    type: LOAD_CONDITION_SET_RECORD,
    payload: response.data,
  });
};

export const setConditionSetFormHeader = (data: any) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_CONDITION_SET_FORM_HEADER,
    payload: data,
  });
};

export const addRecord = (
  productsDest: string[],
  erpHandlingDest: string[],
) => async (dispatch: Dispatch, getState: any) => {
  const { formHeader } = getState().Workflows.ConditionSet;
  const userID = localStorage.getItem('username');
  const payload = {
    formaction: 'update',
    user_id: userID,
    term_id: 'B344',
    new_mode: 1,
    data: {
      Description: formHeader?.Description,
      ProductFlag: Number(formHeader?.ProductFlag?.value),
    },
    post_product_list: productsDest,
    post_erp_list: erpHandlingDest,
  };
  try {
    const response = await Api.post('workflow140/updaterecord', payload);
    if (response.status === 200) {
      dispatch({
        type: CLEAR_CONDITION_SET,
      });
      dispatch({
        type: SET_CONDITION_SET_RESPONSE_NOTIFICATION,
        payload: response.data,
      });
    }
  } catch (err: any) {
    dispatch({
      type: SET_CONDITION_SET_RESPONSE_NOTIFICATION,
      payload: err.response.data,
    });
  }
};

export const updateRecord = (
  productsDest: string[],
  erpHandlingDest: string[],
  conditionSetID: string,
) => async (dispatch: Dispatch, getState: any) => {
  const { formHeader } = getState().Workflows.ConditionSet;
  const userID = localStorage.getItem('username');
  const payload = {
    formaction: 'update',
    user_id: userID,
    term_id: 'B344',
    conditionSetID,
    data: {
      Description: formHeader?.Description,
      ProductFlag: Number(formHeader?.ProductFlag?.value),
    },
    post_product_list: productsDest,
    post_erp_list: erpHandlingDest,
  };
  try {
    const response = await Api.post('workflow140/updaterecord', payload);
    if (response.status === 200) {
      dispatch({
        type: CLEAR_CONDITION_SET,
      });
      dispatch({
        type: SET_CONDITION_SET_RESPONSE_NOTIFICATION,
        payload: response.data,
      });
    }
  } catch (err: any) {
    dispatch({
      type: SET_CONDITION_SET_RESPONSE_NOTIFICATION,
      payload: err.response.data,
    });
  }
};

export const deleteRecord = (conditionSetID: string) => async (dispatch: Dispatch) => {
  const payload = {
    formaction: 'delete',
    conditionSetID,
  };
  try {
    const response = await Api.post('workflow140/updaterecord', payload);
    if (response.status === 200) {
      dispatch({
        type: SET_CONDITION_SET_RESPONSE_NOTIFICATION,
        payload: response.data,
      });
    }
  } catch (err: any) {
    dispatch({
      type: SET_CONDITION_SET_RESPONSE_NOTIFICATION,
      payload: err.response.data,
    });
  }
};

export const clearConditionSetNotification = () => ({
  type: CLEAR_CONDITION_SET_RESPONSE_NOTIFICATION,
});
