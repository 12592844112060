/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable indent */
/* eslint-disable no-irregular-whitespace */
import { Dispatch } from 'redux';
import Api from '../api/index';
import {
  LOAD_SYSTEM_STARTUP,
  LOAD_SYSTEM_STARTUP_OPTIONS,
  SET_SYSTEM_STARTUP_FORM_DATA,
  SET_SYSTEM_STARTUP_DATA,
  SET_SYSTEM_STARTUP_STATUS,
  CLEAR_SYSTEM_STARTUP_STATUS,
  SET_SYSTEM_STARTUP_RCH,
} from '../constants/systemStartup';

export const fetchSystemStartup = () => async (dispatch: Dispatch) => {
  const response = await Api.get('workflow010/data');
  const data = response.data.data.map((item: any) => {
    const fields = item.fields.map((field: any) => {
      if (field.obj) {
        return {
          id: field.id,
          options: field?.obj?.['\u0000*\u0000parm']?.options,
          value: field?.obj?.['\u0000*\u0000parm']?.value,
        };
      }
      return {
        id: field.id,
        value: field.value,
      };
    });
    return {
      row: item.row,
      fields,
    };
  });
  dispatch({
    type: LOAD_SYSTEM_STARTUP,
    payload: {
      data,
      binsList: response.data.binsList,
    },
  });
};

export const getOptionsAndDescriptions = (command: string) => async (dispatch: Dispatch) => {
    const response = await Api.post('synajax/procajax', {
      queueid: 'PROG',
      data: {
        cmd: 'syscmd',
        parm: {
          cmd: 'procusagezmq',
          command,
          options: '',
          tableid: 'manageoptionstable',
        },
      },
    });
    dispatch({
      type: LOAD_SYSTEM_STARTUP_OPTIONS,
      payload: {
        data: response.data[0],
        command,
      },
    });
  };

export const setFormData = (row: any) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_SYSTEM_STARTUP_FORM_DATA,
    payload: {
      row,
    },
  });
};

export const addSystemStartupRow = () => (dispatch: Dispatch, getState: any) => {
    const { data } = getState().SystemStartupReducer;
    const lastRow = data[data.length - 1];
    const rowFields = [...lastRow.fields];
    const newRowNumber = `${Number(lastRow.row) + 1}`;
    const newRowFields = rowFields.map((field: any, index) => {
      if (index === 1) {
        return { ...field, value: '3po_cli' };
      }
      if (index === 2) {
        return { ...field, value: 'E' };
      }
      if (index === 6) {
        return { ...field, value: '0' };
      }
      return { ...field, value: '' };
    });
    const newRow = {
      row: newRowNumber,
      fields: newRowFields,
      isNew: true,
    };
    dispatch({
      type: SET_SYSTEM_STARTUP_DATA,
      payload: { data: [...data, newRow], newRow },
    });
  };

export const saveSystemStartupChanges = () => async (dispatch: Dispatch, getState: any) => {
    const { formData, data } = getState().SystemStartupReducer;
    const userID = localStorage.getItem('username');
    const formDataKeys = Object.keys(formData);
    const dataWithoutChangedRows = data.filter(
      (x: any) => !formDataKeys.includes(x.row),
    );
    const changedRows = formDataKeys.map((key) => ({
      row: key,
      fields: formData[key],
    }));
    const mergerdList = [...dataWithoutChangedRows, ...changedRows];
    const filtredMergedList = mergerdList.filter((x: any) => x.fields);
    const grouplist = filtredMergedList.map((item) => {
      const dataList = item.fields.map((field: any) => field.value);
      return {
        after: [Number(item.row), ...dataList],
      };
    });
    const payload = {
      grouplist,
      userID,
    };
    await Api.post('workflow010/update', payload);
    window.location.reload();
  };

export const getSystemStartupStatus = () => async (dispatch: Dispatch) => {
  const payload = {
    data: {
      cmd: 'managestartup',
      parm: {
        processid: '',
        groupid: '',
        controltype: 'statustms',
      },
    },
  };
  const response = await Api.post('synajax/procajax', payload);
  dispatch({
    type: SET_SYSTEM_STARTUP_STATUS,
    payload: response.data[0],
  });
};

export const startTms = () => async (dispatch: Dispatch, getState: any) => {
  const payload = {
    data: {
      cmd: 'managestartup',
      parm: {
        processid: 'null',
        groupid: '',
        controltype: 'starttms',
      },
      data: null,
    },
    tagid: null,
    jqxhr: null,
  };
  await Api.post('synajax/procajax', payload);
};

export const stopTms = () => async (dispatch: Dispatch, getState: any) => {
  const payload = {
    data: {
      cmd: 'managestartup',
      parm: {
        processid: 'null',
        groupid: '',
        controltype: 'stoptms',
      },
      data: null,
    },
    tagid: null,
    jqxhr: null,
  };
  await Api.post('synajax/procajax', payload);
};

export const removeSystemStartupRow = (row: string) => async (dispatch: Dispatch, getState: any) => {
    const { data } = getState().SystemStartupReducer;
    const filtredData = data.filter((item: any) => item.row !== row);
    dispatch({
      type: SET_SYSTEM_STARTUP_DATA,
      payload: { data: filtredData, newRow: { row, fields: null } },
    });
  };

export const getRecordChangeHistory = () => async (dispatch: Dispatch) => {
  const response = await Api.get('formscreen/get-change-history', {
    params: {
      tableID: 'SystemStartUp',
    },
  });
  dispatch({
    type: SET_SYSTEM_STARTUP_RCH,
    payload: response.data.data.slice(0, 100),
  });
};

export const manageGroupProcess = (processid: string, groupid: string, controltype: string) =>
  async (dispatch: Dispatch) => {
    const payload = {
      data: {
        cmd: 'managestartup',
        parm: { processid, groupid, controltype },
        data: null,
      },
      tagid: null,
      jqxhr: null,
    };
    const response = await Api.post('synajax/procajax', payload);
    if (response.status === 200) {
      dispatch({
        type: CLEAR_SYSTEM_STARTUP_STATUS,
      });
      const res = await Api.post('synajax/procajax', {
        data: {
          cmd: 'managestartup',
          parm: {
            processid: '',
            groupid: '',
            controltype: 'statustms',
          },
        },
      });
      dispatch({
        type: SET_SYSTEM_STARTUP_STATUS,
        payload: res.data[0],
      });
    }
  };
