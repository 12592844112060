import { Dispatch } from 'redux';
import Api from '../../api/index';
import {
  LOAD_USERS_DATA,
} from '../../constants/workflows';

export const getUsersData = () => async (dispatch: Dispatch) => {
  const response = await Api.get(
    'workflow180',
  );
  const formattedData = response.data.data.map((row: any[]) => {
    const colObject = row.reduce((acc, curr) => {
      if (curr.title === 'user_id') return acc;
      return {
        ...acc,
        [curr.title]: curr.data,
      };
    }, {});
    return colObject;
  });
  const fields = Object.keys(formattedData[0]).reduce((acc, curr) => ({
    ...acc,
    [curr]: curr,
  }), {});
  dispatch({
    type: LOAD_USERS_DATA,
    payload: {
      data: formattedData,
      fields,
    },
  });
};
