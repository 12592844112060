import {
  LOAD_EMAIL_GROUP,
  LOAD_SYSTEM_PRINTERS,
  LOAD_VIEW_REPORT,
  LOAD_VIEW_REPORT_COMMENT,
  VIEW_REPORT_SAVE_REPORT_DESCRIPTION,
  VIEW_REPORT_SAVE_SEQ_NO,
} from '../../constants/workflows';

const INITIAL_STATE = {
  data: [],
  routecode: [],
  seqno: '',
  comment: '',
  reportName: '',
  emailGroup: [],
};

const viewReports = (
  state = INITIAL_STATE,
  { type, payload }: any,
) => {
  switch (type) {
    case LOAD_VIEW_REPORT: {
      return {
        ...state,
        data: payload,
      };
    }
    case VIEW_REPORT_SAVE_SEQ_NO: {
      return {
        ...state,
        seqno: payload,
      };
    }
    case VIEW_REPORT_SAVE_REPORT_DESCRIPTION: {
      return {
        ...state,
        reportName: payload,
      };
    }
    case LOAD_SYSTEM_PRINTERS: {
      return {
        ...state,
        routecode: payload,
      };
    }
    case LOAD_EMAIL_GROUP: {
      return {
        ...state,
        emailGroup: payload,
      };
    }
    case LOAD_VIEW_REPORT_COMMENT: {
      return {
        ...state,
        comment: payload,
      };
    }
    default:
      return state;
  }
};

export default viewReports;
