import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Alert, CircularProgress, Snackbar, Typography,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import Subnavbar from '../../components/Sub-navbar/Subnavbar';
import {
  dissmissConfirmationAlert,
  LoadRecord,
  LoadTables,
  UpdateRecord,
} from '../../redux/actions/ScreenActions';
import FormBodyTerminal from '../../components/formBodyTermDetail/formBodyTerminal';
import FormHeaderTerminal from '../../components/FormHeaderTermDetail/FormHeaderTerminal';
import TmsHeader from '../../components/header';
import { RecordChangeHistory } from '../../components/recordChangeHistoryDialog/RecordChangeHistory';
import { UnitCalculatorDialog } from '../../components/unitCalculatorDialog/UnitCalculatorDialog';
import ConfirmationModalLoseChanges from '../../components/confirmationModal/ConfirmationModalLoseChanges';
import EmbeddedSubnavbar from '../embbededScreens/subNavEmbedded/subNavEmbedded';
import AlertUpdate from './AlertUpdate';

const DetailMaintScreen = (props: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const routeBack: any = location.state;
  const dispatch = useDispatch();
  const { reducer } = props;
  const {
    recordDetails,
    parsedValues,
    activeScreen,
    openConfirmationAlert,
    confirmationAlert,
    config,
    data,
    errorCode,
  } = useSelector((state: any) => state?.[reducer || 'ScreenReducer']);

  const [values, setValues] = useState<any>([]);
  const [valuesToSend, setValuesToSend] = useState<any>([]);
  const [RecordList, setRecordList] = useState<any>([]);
  const [Visible, setVisible] = useState(false);
  const [VisibleConfirm, setVisibleConfirm] = useState(false);
  const [VisibleNextPrevious, setVisibleNextPrevious] = useState(false);
  const [Page, setPage] = useState(Number(activeScreen.page));
  const [recordHistoryDialogOpen, setRecordHistoryDialogOpen] = useState(false);
  const [unitCalculatorDialogOpen, setUnitCalculatorDialogOpen] = useState(false);
  const [isModifiy, setIsModifiy] = useState(false);
  const [myscreen, setScreen] = useState<any>('');
  const [recentScreen, setRecentScreen] = useState<any>();
  const [Count, setCount] = useState(0);
  const [IschangeRecord, setIschangeRecord] = useState(false);
  const [IschangeList, setIschangeList] = useState(false);
  const [showOptionalFields, setShowOptionalFields] = useState(true);
  const whereEdit = JSON.stringify(routeBack.modifyDep);
  const { Language } = useSelector((state: any) => state.LoginReducer);
  // getting records list
  useEffect(() => {
    window.parent.postMessage({ isModifiy });
  }, [isModifiy]);
  useEffect(() => {
    const recordsList = data?.data?.map((row: any, key: any) => ({
      key,
      lang: Language,
      where: activeScreen?.keys
        ? JSON.stringify(
          activeScreen?.keys
            .split(',')
            .reduce(
              (acc: any, item: any) => ({ ...acc, [item]: row[item]?.value }),
              {},
            ),
        )
        : null,
    }));
    setRecordList(recordsList);
  }, [Page]);
  const recordsList = data?.data?.map((row: any, key: any) => ({
    key,
    lang: Language,
    where: activeScreen?.keys
      ? JSON.stringify(
        activeScreen?.keys
          .split(',')
          .reduce(
            (acc: any, item: any) => ({ ...acc, [item]: row[item]?.value }),
            {},
          ),
      )
      : null,
  }));
  const HandleServiceCall = () => {
    dispatch(
      LoadTables({
        tableID: activeScreen.tableID,
        page: `${Page}`,
        results_per_page: activeScreen.results_per_page,
        fields: activeScreen.fields,
        order_by: '',
        where: activeScreen.where,
        EmptyRows: activeScreen.EmptyRows,
        redirect: activeScreen.redirect,
        search: activeScreen.search || '',
        screenId: activeScreen.screenId,
        whereRecord: activeScreen.whereRecord,
        keys: activeScreen.keys,
        defaultcolumns: activeScreen.defaultcolumns,
        lang: Language,
        fieldsConcatenated: activeScreen.fieldsConcatenated,
      }),
    );
    dispatch(
      LoadRecord({
        tableID: routeBack.tableID,
        screenID: activeScreen.screenId,
        where: recordsList[Count]?.where,
        fields: activeScreen.fields,
        lang: Language,
      }),
    );
  };
  const handleClose = () => {
    dispatch(dissmissConfirmationAlert());
  };
  const handleCloseModalYes = ():void => {
    setValues({});
    setValuesToSend({});
  };
  const handleShowOptionalFields = () => {
    setShowOptionalFields(!showOptionalFields);
  };
  useEffect(() => {
    if (!activeScreen) {
      navigate('/home');
    } else if (activeScreen) {
      if (!IschangeRecord && !IschangeList) {
        dispatch(
          LoadRecord({
            tableID: routeBack.tableID,
            screenID: routeBack.screenID,
            where: whereEdit,
            fields: activeScreen.fields,
            lang: Language,
          }),
        );
      } else {
        HandleServiceCall();
        setIschangeList(false);
      }
    }
  }, [Count, activeScreen.page]);
  useEffect(() => {
    const recordIndex = routeBack.recordsList.findIndex(
      (a: any) => JSON.stringify(a.modifyDep) === JSON.stringify(routeBack.modifyDep),
    );
    setCount(recordIndex);
  }, []);

  useEffect(() => {
    setValues(parsedValues);
    setValuesToSend(parsedValues);
  }, [parsedValues]);

  const changeNextRecordsList = () => {
    setPage(Page + 1);
    setCount(0);
    setIschangeRecord(true);
  };
  const changePrevRecordsList = () => {
    setPage(Page - 1);
    setCount(routeBack.recordsList.length - 1);
    setIschangeRecord(true);
  };
  const nextRecord = () => {
    if (Count === routeBack.recordsList.length - 1 && Count < data.totalItems - 1) {
      setIschangeList(true);
      changeNextRecordsList();
    } else {
      setCount(Count + 1);
      setIschangeRecord(true);
    }
  };
  const previousRecord = () => {
    if (Count === 0) {
      setIschangeList(true);
      changePrevRecordsList();
    } else {
      setCount(Count - 1);
      setIschangeRecord(true);
    }
  };
  const handleCloseConfirm = () => {
    setVisible(!Visible);
  };
  const handleLoseConfirm = () => {
    setVisibleConfirm(!VisibleConfirm);
  };
  const handleMoveNextPrevious = () => {
    setVisibleNextPrevious(!VisibleNextPrevious);
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    setValuesToSend({
      ...valuesToSend,
      [name]: value,
    });
    setIsModifiy(true);
  };
  const handleSelectChange = (
    name: any,
    e: React.ChangeEvent<HTMLInputElement>,
    newValue: any,
  ) => {
    setValues({
      ...values,
      [name]: newValue,
    });
    setValuesToSend({
      ...valuesToSend,
      [name]: newValue.value,
    });
    setIsModifiy(true);
  };
  const handleSave = (notNavigate = false) => {
    if (!IschangeRecord) {
      dispatch(
        UpdateRecord({
          table: routeBack.tableID,
          navigate,
          backScreen: routeBack.routeDetails,
          compulsory_data: [routeBack.modifyDep],
          form_data: { ...valuesToSend, ...routeBack.modifyDep },
          default_values: parsedValues,
          review: true,
        }),
      );
      setIsModifiy(false);
    } else {
      dispatch(
        UpdateRecord({
          table: routeBack.tableID,
          navigate,
          backScreen: routeBack.routeDetails,
          compulsory_data: [routeBack.recordsList[Count].modifyDep],
          form_data: {
            ...valuesToSend,
            ...routeBack.recordsList[Count].modifyDep,
          },
        }),
      );
      setIsModifiy(false);
    }
  };
  const handleOpenRecordHistoryDialog = () => {
    setRecordHistoryDialogOpen(true);
  };

  const handleCloseRecordHistoryDialog = () => {
    setRecordHistoryDialogOpen(false);
  };

  const handleOpenUnitCalculatorDialog = () => {
    setUnitCalculatorDialogOpen(true);
  };

  const handleCloseUnitCalculatorDialog = () => {
    setUnitCalculatorDialogOpen(false);
  };

  const compare = (currentValue: any) => {
    const result = currentValue?.obj['\u0000*\u0000parm']?.options?.filter(
      (e: any) => e?.value === values[currentValue?.id],
    );
    return result ? result[0]?.label : '';
  };

  const { _, ...restData } = recordDetails;

  return recordDetails.length === 0 ? (
    <div
      style={{
        width: '100%',
        height: '90vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress />
    </div>
  ) : (
    <>
      {!reducer ? (
        <>
          <TmsHeader
            isModifiy={isModifiy}
            setVisibleConfirm={setVisibleConfirm}
            setScreen={setScreen}
            setRecentScreen={setRecentScreen}
          />
          <Subnavbar
            label
            menuOptionType="simpleItems"
            openRecordHistoryDialog={handleOpenRecordHistoryDialog}
            openUnitCalculatorDialog={handleOpenUnitCalculatorDialog}
            handleShowOptionalFields={handleShowOptionalFields}
            showOptionalFields={showOptionalFields}
          />
        </>
      ) : (
        <EmbeddedSubnavbar
          label
          menuOptionType="exportItems"
          // menuOptions={FilterSaved}
          exportTable={() => {
            console.log('sdfds');
          }}
        />
      )}
      {errorCode && <AlertUpdate errorCode={errorCode} HandleServiceCall={HandleServiceCall} />}
      <FormHeaderTerminal
        listLength={routeBack.recordsList.length}
        count={Count}
        page={Page}
        nextRecord={nextRecord}
        previousRecord={previousRecord}
        Visible={Visible}
        VisibleConfirm={VisibleConfirm}
        VisibleNextPrevious={VisibleNextPrevious}
        options={routeBack}
        handleCloseConfirm={handleCloseConfirm}
        handleLoseConfirm={handleLoseConfirm}
        handleMoveNextPrevious={handleMoveNextPrevious}
        dataHeader={_}
        routeBack={routeBack}
        handleSelectChange={handleSelectChange}
        handleInputChange={handleInputChange}
        values={values}
        handleSave={handleSave}
        isModifiy={isModifiy}
        setIsModifiy={setIsModifiy}
        compare={compare}
        valuesToSend={valuesToSend}
        totalRecords={data.totalItems}
        tableId={activeScreen?.tableID}
      />
      <FormBodyTerminal
        dataTabs={{ ...restData, ...config }}
        handleSelectChange={handleSelectChange}
        handleInputChange={handleInputChange}
        values={values}
        compare={compare}
        valuesToSend={valuesToSend}
        showOptionalFields={showOptionalFields}
        handleShowOptionalFields={handleShowOptionalFields}
      />
      <ConfirmationModalLoseChanges
        Visible={VisibleConfirm}
        handleCloseConfirm={handleLoseConfirm}
        handleCloseModalYes={handleCloseModalYes}
        myscreen={myscreen || ''}
        activeScreen={activeScreen.redirect}
        setScreen={setScreen}
        recentScreen={recentScreen}
      />
      <RecordChangeHistory
        onClose={handleCloseRecordHistoryDialog}
        open={recordHistoryDialogOpen}
        options={routeBack}
      />
      <UnitCalculatorDialog
        onClose={handleCloseUnitCalculatorDialog}
        open={unitCalculatorDialogOpen}
      />
      {/* <ChangeRecordModal options={routeBack} /> */}
      <Snackbar
        open={openConfirmationAlert}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={confirmationAlert.includes('successfully') ? 'success' : 'error'}>
          {confirmationAlert.split(',')[0]}
        </Alert>
      </Snackbar>
    </>
    // )
  );
};

export default DetailMaintScreen;
