import React, { useState } from 'react';

// library
import {
  TextField,
  Typography,
  InputAdornment,
  Fab,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';

interface IProps {
    name: string;
    label: string;
    maxLength: number;
    classes: any;
    handleChange: any,
    min: number;
    step: number;
}

const CounterField: React.FC<IProps> = ({
  name,
  label,
  maxLength,
  classes,
  handleChange,
  min,
  step,
}) => {
  const [count, setCount] = useState<number>(min);
  // this for Adding Count for Counter field
  const add = () => {
    if (count !== maxLength) {
      setCount(count + step);
    }
  };
  // this for Subtracting Count for Counter field
  const subtract = () => {
    if (count !== min) {
      setCount(count - step);
    }
  };
  const useStyle = makeStyles({
    centerText: {
      textAlign: 'right',
      '@media (max-width: 899px)': {
        textAlign: 'left',
      },
    },
  });
  const classStyle = useStyle();
  return (
    <>
      <Grid container>
        <Grid item lg={3} xs={12} md={3} />
        <Grid item lg={1} xs={12} md={1}>
          <div className={classStyle.centerText}>
            <Typography
              variant="caption"
              className={classes.label}
              align="right"
            >
              {label}
            </Typography>
          </div>
        </Grid>
        <Grid item lg={8} xs={12} md={8}>
          <TextField
            className="textfield"
            onChange={(e: any) => handleChange(e.target, name)}
            name={name}
            value={count}
            size="small"
            inputProps={{ maxLength }}
            style={{
              color: '#f7f7f7',
              background: 'transparent',
            // border: required ? '1px solid red' : 'transparent',
            }}
        // color={required && value === '' ? 'error' : 'primary'}
        // eslint-disable-next-line react/jsx-no-duplicate-props
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Fab size="small" color="primary" variant="extended">
                    <RemoveIcon onClick={subtract} />
                  </Fab>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <Fab size="small" color="primary" variant="extended">
                    <AddIcon onClick={add} />
                  </Fab>
                </InputAdornment>
              ),
            }}
            sx={{
              '& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
                padding: '1px 2px',
                border: '1px solid #E4E4E4',
              },
              width: '100%',
              '& .MuiInputBase-root': {
                '& > fieldset': {
                // borderColor:
                //  'red',
                  borderLeft: '5px solid red',
                  // marginLeft: '5px',
                  // marginRight: '8px',
                },
              },
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CounterField;
