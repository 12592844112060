/* eslint-disable react/jsx-no-bind */
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { isObject } from '../../utils';
import {
  openColumnModal,
  openFilterModal,
  StopLoading,
  StartLoading,
  StartExporting,
  StopExporting,
} from '../../redux/actions/ScreenActions';

export const getDownloadFilename = (screen: string) => `${screen}-
${moment().format('YYYY-MM-DD_HHmmss')}`;
const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      // eslint-disable-next-line max-len
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

interface menuProp {
  menuOptionType: string;
  openRecordHistoryDialog?: () => void;
  openUnitCalculatorDialog?: () => void;
  handleShowOptionalFields?: any;
  showOptionalFields: any;
}
const CustomizedMenus = (prop: menuProp) => {
  const {
    menuOptionType,
    openRecordHistoryDialog,
    handleShowOptionalFields,
    showOptionalFields,
    openUnitCalculatorDialog,
  } = prop;
  const { exportLoading } = useSelector((state: any) => state.ScreenReducer);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const dispatch = useDispatch();

  const {
    activeScreen, data, recordDetails, unitCalculator, FilterSaved,
  } = useSelector((state: any) => state.ScreenReducer);
  const optionalFields = useCallback(() => Object.entries(recordDetails)
    .map((section: any) => section?.[1])
    .flat()
    .filter((record: any) => !record?.visible), []);
  const otionalFieldsLength = optionalFields()?.length;

  const obj: any = data?.data[0];
  const merged: any = activeScreen?.fieldsConcatenated
    .split(',')
    .reduce((objt: any, key: any) => ({ ...objt, [key]: 'true' }), {});

  const handleRecordChangeHistoryClick = () => {
    if (openRecordHistoryDialog) {
      openRecordHistoryDialog();
    }
    handleClose();
  };
  function handleListKeyDown(event: React.KeyboardEvent) {
    event.preventDefault();
  }
  const handleUnitCalculatorClick = () => {
    if (openUnitCalculatorDialog) {
      openUnitCalculatorDialog();
    }
    handleClose();
  };

  if (data.data.length) {
    Object.keys(data?.data[0]).forEach((key) => {
      if (data.data[0][key] === null) {
        obj[key] = 'null';
      }
    });
  }

  const downloadAllFile = async (type: string, spool: boolean) => {
    let fileName = '';
    const filterAdapter: any = {
      '<': 'less',
      '=': 'equal',
      '!=': 'not_equal',
      '>': 'greater',
      like: 'contains',
      'not like': 'not_contains',
    };
    const filters = FilterSaved?.[activeScreen.screenId]?.filters?.map(
      (item: any) => ({
        column: item.field,
        operator: filterAdapter[item.condition],
        compare_value: item.value,
      }),
    );
    const payload = {
      table: activeScreen.tableID,
      relation:
        FilterSaved?.[activeScreen.screenId]?.match === ' AND ' ? 'all' : 'any',
      filter: filters,
      filter_name: '',
    };

    const filtersPayload = {
      report_type: `${activeScreen.tableID}`,
      export_table: `${activeScreen.tableID}`,
      maint_screen_id: `${activeScreen.screenId}`,
      file_type: `${type}`,
      spool_all_columns: spool,
      selected_columns: merged,
      filter_name: '',
      filters: {
        table: payload.table,
        relation: payload.relation,
        filter_name: '',
        filters: payload.filter,
      },
    };

    const emptyPayload = {
      report_type: `${activeScreen.tableID}`,
      export_table: `${activeScreen.tableID}`,
      maint_screen_id: `${activeScreen.screenId}`,
      file_type: `${type}`,
      spool_all_columns: spool,
      selected_columns: merged,
      filter_name: '',
    };

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}export/spooldata`,
        filters ? filtersPayload : emptyPayload,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      fileName = res?.data?.data?.[0];
    } catch (error: any) {
      throw Error('Error Download File', error);
    }
    const downloadFn = () => {
      dispatch(StartExporting());
      const downloadLink = `${process.env.REACT_APP_API_URL}export/download-file?file=${fileName}`;
      fetch(downloadLink)
        .then((res: any) => {
          if (res.status === 200) {
            const link = document.createElement('a');
            link.href = downloadLink;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            dispatch(StopExporting());
          } else {
            // console.log('retrying 1');
            setTimeout(downloadFn, 100);
          }
        })
        .catch((err) => {
          // console.log('retrying 2');
          setTimeout(downloadFn, 100);
        });
    };
    downloadFn();
  };
  return (
    <div>
      <Button
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="text"
        disableElevation
        size="small"
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        style={{ color: '#000' }}
      >
        <Typography variant="body2" fontWeight="bold" style={{ fontSize: 12, textTransform: 'capitalize' }}>
          Options
        </Typography>
      </Button>
      {(() => {
        switch (menuOptionType) {
          case 'exportItems':
            return (
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onKeyDown={handleListKeyDown}
              >
                <MenuItem
                  className="f12"
                  onClick={() => {
                    navigate('/create-record', { replace: true });
                  }}
                  disableRipple
                >
                  New
                </MenuItem>
                <Divider />
                <MenuItem
                  className="f12"
                  onClick={async () => {
                    dispatch(openFilterModal());
                    handleClose();
                  }}
                  disableRipple
                >
                  {/* <TransitionsModal label="Filter" /> */}
                  Filter
                  <MoreHorizIcon />
                </MenuItem>
                <MenuItem
                  className="f12"
                  onClick={async () => {
                    dispatch(openColumnModal());
                    handleClose();
                  }}
                  disableRipple
                >
                  Columns
                  <MoreHorizIcon />
                </MenuItem>
                <Divider />
                <MenuItem
                  className="f12"
                  disabled={data.data.length === 0 || exportLoading}
                  onClick={async () => {
                    handleClose();
                    await downloadAllFile('csv', true);
                  }}
                  disableRipple
                >
                  Export All Columns (CSV)
                </MenuItem>
                <MenuItem
                  className="f12"
                  disabled={data.data.length === 0 || exportLoading}
                  onClick={async () => {
                    handleClose();
                    await downloadAllFile('xlsx', true);
                  }}
                  disableRipple
                >
                  Export All Columns (XLSX)
                </MenuItem>
                <MenuItem
                  className="f12"
                  disabled={data.data.length === 0 || exportLoading}
                  onClick={async () => {
                    handleClose();
                    await downloadAllFile('csv', false);
                  }}
                  disableRipple
                >
                  Export Visible Columns (CSV)
                </MenuItem>
                <MenuItem
                  className="f12"
                  disabled={data.data.length === 0 || exportLoading}
                  onClick={async () => {
                    handleClose();
                    await downloadAllFile('xlsx', false);
                  }}
                  disableRipple
                >
                  Export Visible Columns (XLSX)
                </MenuItem>
                <MenuItem
                  className="f12"
                  disabled={data.data.length === 0 || exportLoading}
                  onClick={async () => {
                    handleClose();
                    await downloadAllFile('pdf', false);
                  }}
                  disableRipple
                >
                  Export To PDF
                </MenuItem>
              </StyledMenu>
            );
            break;
          case 'simpleItems':
            return (
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onKeyDown={handleListKeyDown}
              >
                <MenuItem
                  className="f12"
                  onClick={() => {
                    navigate('/clone-record', { replace: true });
                  }}
                  disableRipple
                >
                  Clone Record
                </MenuItem>
                {Boolean(unitCalculator?.length) && (
                <MenuItem className="f12" onClick={handleUnitCalculatorClick} disableRipple>
                  Unit Calculator
                </MenuItem>
                )}
                <MenuItem
                  className="f12"
                  disableRipple
                  onClick={handleRecordChangeHistoryClick}
                >
                  Record Change History
                  <MoreHorizIcon />
                </MenuItem>
                <Divider />
                <MenuItem
                  className="f12"
                  disabled={!otionalFieldsLength}
                  disableRipple
                  onClick={() => {
                    handleShowOptionalFields();
                    handleClose();
                  }}
                >
                  {showOptionalFields ? 'Hide' : 'Show'}
                  {' '}
                  Optional Fields
                  {Boolean(otionalFieldsLength) && ` (${otionalFieldsLength || 0})`}
                </MenuItem>
              </StyledMenu>
            );
            break;
          default:
            return null;
            break;
        }
      })()}
    </div>
  );
};
export default CustomizedMenus;
