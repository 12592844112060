import React from 'react';

import { InputBase } from '@mui/material';
import clsx from 'clsx';

import { useStyles } from './style';
import Select from '../Select';

interface IProps {
  recordsNumber: any;
  searchText: any;
  handleSearchChange: any;
  handleSelectChange: any;
}

const WorkflowTableUtils: React.FC<IProps> = ({
  handleSearchChange,
  handleSelectChange,
  recordsNumber,
  searchText,
}) => {
  const classes = useStyles();
  return (
    <div className={clsx('flexStartSpaceBetweenRow', classes.subHeaderContainer)}>
      <div className={clsx('flexRow')}>
        <span className={classes.label}>Records :</span>
        <Select
          options={['Auto', '10', '20', '25', '35', '50', 'All']}
          width={200}
          value={recordsNumber}
          onChange={handleSelectChange}
        />
      </div>
      <div className="flexRow">
        <span className={classes.label}>Search :</span>
        <InputBase
          className={classes.searchInput}
          value={searchText}
          onChange={handleSearchChange}
        />
      </div>
    </div>
  );
};

export default WorkflowTableUtils;
