/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable no-nested-ternary */
/* eslint-disable implicit-arrow-linebreak */
import React, { useState } from 'react';

// Libraries
import {
  ButtonBase, DialogActions, Divider, InputBase,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

// Components
import { DialogComponent } from '../Dialog/Dialog.component';

// Styles
import { useStyles } from './style';
import { useWorkflowState } from '../../hooks/useWorkflowState';
import AutoTankGaugeTable from '../AutoTankGaugeTable';
import { autoTankGauge } from '../../redux/actions/workflows/tankGaugeEntry';

interface IProps {
  onClose: () => void;
  refreshAutoTankGaugeDialog: () => void;
  open: boolean;
  isUpdateDisabled: boolean;
}

const headerList = [
  { key: 'menu', label: 'menu' },
  { key: 'tank', label: 'Tank ID' },
  { key: 'grav_density', label: 'API Gravity/Density' },
  { key: 'tank_status', label: 'Tank Temp.' },
  { key: 'ambient_temperature', label: 'Ambient Temp.' },
  { key: 'prod_level', label: 'Product Level' },
  { key: 'gross_inv', label: 'Gross Observed Volume' },
  { key: 'net_inv', label: 'Net Standard Volume' },
  { key: 'water_level', label: 'Water Level' },
  { key: 'line_fill', label: 'Line Fill' },
  { key: 'line_fill1', label: 'Line Fill 1' },
  { key: 'line_fill2', label: 'Line Fill 2' },
  { key: 'line_fill3', label: 'Line Fill 3' },
];

const AutoTankGaugeDialog: React.FC<IProps> = ({
  onClose,
  open,
  refreshAutoTankGaugeDialog,
  isUpdateDisabled,
}) => {
  const classes = useStyles();
  const { autoTankGaugeData } = useSelector(
    (state: any) => state.Workflows.TankGaugeEntry,
  );
  const dispatch = useDispatch();
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const { tableState, handleSearchChange } = useWorkflowState(
    headerList,
    autoTankGaugeData?.atg || [],
    null,
  );

  const handleSelectRow = (row: any, isRowSelected: boolean) => {
    if (isRowSelected) {
      const newSelectedRows = selectedRows.filter(
        (x: any) => x?.tank !== row?.tank,
      );
      setSelectedRows(newSelectedRows);
    } else {
      setSelectedRows([...selectedRows, row]);
    }
  };

  const handleSelectAllRows = () => {
    setSelectedRows(tableState.tableData);
  };

  const handleUnselectAllRows = () => {
    setSelectedRows([]);
  };

  const handleApply = () => {
    dispatch(autoTankGauge(selectedRows));
    onClose();
  };

  return (
    <DialogComponent
      title="Auto Tank Gauge"
      onClose={onClose}
      open={open}
      maxWidth="xl"
    >
      <div className={classes.dialogBody}>
        <div className="flexRow" style={{ marginBottom: 10 }}>
          <ButtonBase
            className={classes.refrechButton}
            onClick={refreshAutoTankGaugeDialog}
          >
            {' '}
            Refresh
            {' '}
          </ButtonBase>
          <span className="takeTheRest" />
          <div>
            <InputBase
              className={classes.input}
              placeholder="Search"
              onChange={handleSearchChange}
            />
            <span className={classes.helperText}>
              Viewing
              {' '}
              {tableState.tableData?.length || 0}
              {' '}
              of
              {' '}
              {autoTankGaugeData?.atg?.length || 0}
            </span>
          </div>
        </div>
        <AutoTankGaugeTable
          handleClickRow={() => null}
          headerItems={headerList}
          tableState={tableState}
          handleSelectAll={handleSelectAllRows}
          handleUnSelectAll={handleUnselectAllRows}
          searchText={tableState.searchText}
          selectRow={handleSelectRow}
          selectedRows={selectedRows}
          isUpdateDisabled={isUpdateDisabled}
        />
      </div>
      <Divider variant="middle" />
      <DialogActions>
        <ButtonBase
          classes={{ root: classes.applyButton }}
          disabled={!selectedRows.length || isUpdateDisabled}
          onClick={handleApply}
        >
          Apply
        </ButtonBase>
        <ButtonBase classes={{ root: classes.closeButton }} onClick={onClose}>
          Close
        </ButtonBase>
      </DialogActions>
    </DialogComponent>
  );
};

export default AutoTankGaugeDialog;
