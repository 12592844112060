/* eslint-disable no-debugger */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';

// Librairies
import {
  ButtonBase,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';

// Styles
import { useStyles } from './style';

// Components
import ProcessMenu from '../processMenu';
import Select from '../Select';

// Actions
import {
  manageGroupProcess,
  removeSystemStartupRow,
  setFormData,
} from '../../redux/actions/systemStartup';
import SystemStartupPopover from '../systemStartupPopover';
import { isEmpty } from '../../utils';

// Utils
const tableHeader: string[] = [
  'Group',
  'Command',
  'State',
  'Posted Name',
  'Arguments',
  'Notify',
  'Delay',
  'Description',
];

const getStatusColor = (
  startupOrder: any,
  type: any,
  status: string,
  row: string,
): string => {
  if (!startupOrder && !type && !status) return '#A9A9A9';
  const formattedStatus = status?.split(':')?.[0];
  const filteredStartupOrder = startupOrder?.filter((x: any) => !isEmpty(x));
  const filteredType = type?.filter((x: any) => !isEmpty(x));
  switch (formattedStatus) {
    case 'Green':
    case 'Orange': {
      const rowIndexInStartupOrder = filteredStartupOrder?.findIndex(
        (x: string) => x === row,
      );
      if (rowIndexInStartupOrder === -1) {
        return '#32CD32';
      }
      if (
        filteredType?.[rowIndexInStartupOrder] === 'badentry'
        || filteredType?.[rowIndexInStartupOrder] === 'duplicate'
      ) {
        return '#A9A9A9';
      }
      return '#FFA07A';
    }
    case 'Red': {
      return '#FFA07A';
    }
    case 'Grey': {
      return '#A9A9A9';
    }
    default:
      return '#32CD32';
  }
};

const isRowDisabled = (options: any[]): boolean => {
  if (!options) return true;
  const selectedField = options?.find((opt: any) => opt?.selected);
  return selectedField.label === 'Enabled';
};

interface IProps {
  systemStartupData: any[];
  binsList: { label: string; value: string }[];
  systemStatus: any;
  searchText: string;
}

const SystemStartupTable: React.FC<IProps> = ({
  systemStartupData,
  binsList,
  systemStatus,
  searchText,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const displayCellInput = (
    field: any,
    index: number,
    disabled: boolean,
    command: string,
    row: any,
  ) => {
    switch (index) {
      case 1: {
        const selectedValue = binsList?.find(
          (opt: any) => opt?.value === field?.value,
        );
        return (
          <SelectComponent
            options={binsList || []}
            disabled={disabled}
            field={field}
            row={row}
            value={selectedValue}
          />
        );
      }
      case 2: {
        const fieldOptions = field?.options;
        const selectedValue = fieldOptions?.find((opt: any) => opt?.selected);
        return (
          <SelectComponent
            options={fieldOptions || []}
            disabled={disabled}
            field={field}
            row={row}
            value={selectedValue}
          />
        );
      }
      default: {
        return disabled ? (
          <ButtonBase>{field.value || null}</ButtonBase>
        ) : (
          <SystemStartupPopover
            title={tableHeader[index]}
            contentText={field.value}
            disabled={disabled}
            command={command}
            field={field}
            row={row}
          />
        );
      }
    }
  };

  const handleManageGroupProcess = (
    controleType: string,
    selectedRow: any,
    processId: boolean,
    groupId: boolean,
  ) => {
    dispatch(
      manageGroupProcess(
        processId ? selectedRow.row : '',
        groupId ? selectedRow.fields[0].value : '',
        controleType,
      ),
    );
  };

  const removeRow = (row: string) => {
    dispatch(removeSystemStartupRow(row));
  };

  return (
    <div className={clsx(classes.root, 'takeTheRest')}>
      <Table classes={{ root: classes.tableRoot }}>
        <TableHead>
          <TableRow classes={{ root: classes.headerRow }}>
            <TableCell key="process" />
            <TableCell key="status" />
            {tableHeader.map((h) => (
              <TableCell classes={{ root: classes.headerCell }} key={h}>
                {h}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {!systemStartupData.length && (
          <TableRow>
            <TableCell
              classes={{ root: classes.bodyCellEmpty }}
              style={{ textAlign: 'center' }}
              colSpan={Object.keys(tableHeader).length}
            >
              {searchText ? 'No matching records found' : 'No data available in table'}
            </TableCell>
          </TableRow>
          )}
          {systemStartupData?.map((row: any) => (
            <TableRow key={row.row}>
              <TableCell classes={{ root: classes.bodyCellEmpty }}>
                <ProcessMenu
                  status={
                    isEmpty(systemStatus?.details) ? '#32CD32'
                      : getStatusColor(
                        systemStatus?.details?.startuporder,
                        systemStatus?.details?.type,
                        systemStatus?.state,
                        row?.row,
                      )
                  }
                  removeRow={() => {
                    removeRow(row?.row);
                  }}
                  manageGroupProcess={(
                    controlType: string,
                    processId: boolean,
                    groupId: boolean,
                  ) =>
                    handleManageGroupProcess(
                      controlType,
                      row,
                      processId,
                      groupId,
                    )}
                />
              </TableCell>
              <TableCell classes={{ root: classes.bodyCellEmpty }}>
                <div
                  style={{
                    width: 20,
                    height: 20,
                    backgroundColor: row.isNew
                      ? 'none'
                      : (isEmpty(systemStatus?.details) ? '#32CD32'
                        : getStatusColor(
                          systemStatus?.details?.startuporder,
                          systemStatus?.details?.type,
                          systemStatus?.state,
                          row?.row,
                        )),
                  }}
                />
              </TableCell>
              {row.fields.map((field: any, index: number) => (
                <TableCell classes={{ root: classes.bodyCell }} key={field.id}>
                  {displayCellInput(
                    field,
                    index,
                    row.isNew ? false : isRowDisabled(row.fields[2]?.options),
                    row.fields[1]?.value,
                    row,
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

interface ISelectComponent {
  field: any;
  row: any;
  disabled: boolean;
  options: any[];
  value: any;
}

const SelectComponent: React.FC<ISelectComponent> = ({
  field,
  row,
  disabled,
  options,
  value,
}) => {
  const dispatch = useDispatch();
  const [selectValue, setSelectValue] = useState(value);

  const handleChangeCommand = (e: any, option: any) => {
    const newFields = [...row.fields];
    const changedFieldIndex = newFields.findIndex(
      (x: any) => x.id === field.id,
    );
    const newField = { ...newFields[changedFieldIndex], value: option.value };
    newFields[changedFieldIndex] = newField;
    const newRow = { ...row, fields: newFields };
    setSelectValue(option);
    dispatch(setFormData(newRow));
  };

  return (
    <Select
      onChange={handleChangeCommand}
      disabled={disabled}
      options={options}
      width={50}
      value={selectValue}
    />
  );
};

export default React.memo(SystemStartupTable);
