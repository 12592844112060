/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';

// Librairies
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { startOfDay, sub, endOfDay } from 'date-fns';

// Components
import { Alert, ButtonBase, Snackbar } from '@mui/material';
import TmsHeader from '../../../components/header';
import SubHeader from '../../../components/subHeader';
import ViewTraceLogsSearchCrad from '../../../components/ViewTraceLogsSearchCrad';
import ViewTraceLogs from '../../../components/ViewTraceLogs';

// Styles
import { useStyles } from './style';
import {
  clearViewTraceLogsNotification,
  clearViewTraceLogsSearchData,
  getViewTraceLogsData,
  searchViewTraceLogs,
} from '../../../redux/actions/workflows/viewTraceLogs';
import {
  getPGCRFormattedDate,
  getFormattedTime,
  getViewTraceLogsFullDate,
  isEmpty,
} from '../../../utils';
import { getScreensList } from '../../../redux/actions/ScreenActions';
import ConfirmationDialog from '../../../components/ConfirmDialog';

// Utils
const createStateData = (data: any[]) => data.reduce((acc, curr) => {
  const isDate = curr['\u0000*\u0000nameID'] === 'enddate'
      || curr['\u0000*\u0000nameID'] === 'startdate';
  if (isDate) {
    const value = getViewTraceLogsFullDate(
      curr['\u0000*\u0000parm']?.date,
      curr['\u0000*\u0000parm']?.time,
    );
    return {
      ...acc,
      [curr['\u0000*\u0000parm']?.label]: {
        inputValue: value,
        pickerValue: new Date(value),
      },
    };
  }
  if (curr?.['\u0000*\u0000parm']?.label === 'Search Pattern') {
    return {
      ...acc,
      [curr['\u0000*\u0000parm']?.label]: curr['\u0000*\u0000parm']?.value,
    };
  }
  const value = curr['\u0000*\u0000parm']?.options?.find(
    (x: any) => x.selected,
  );
  return {
    ...acc,
    [curr['\u0000*\u0000parm']?.label]: value,
  };
}, {});

const failMessage = 'FILES PROCESSED = 1, LINES PROCESSED = 215008, ENTRIES PROCESSED = 2403, ENTRIES FOUND = 0';

const createSearchData = (data: any[]) => data.map((elem: any) => ({
  nameId: elem['\u0000*\u0000nameID'],
  label: elem['\u0000*\u0000parm'].label,
  options: elem['\u0000*\u0000parm'].options,
  type: elem['\u0000*\u0000parm']?.type,
  isDate:
      elem['\u0000*\u0000nameID'] === 'enddate'
      || elem['\u0000*\u0000nameID'] === 'startdate',
}));

const getStartEndDatesFromPresets = (value: string | number) => {
  if (typeof value === 'number') {
    const endDate = new Date();
    const startDate = sub(endDate, { hours: value });
    return { startDate, endDate };
  }
  switch (String(value)) {
    case 'today': {
      return {
        startDate: startOfDay(new Date()),
        endDate: endOfDay(new Date()),
      };
    }
    case 'yesterday': {
      return {
        startDate: startOfDay(sub(new Date(), { days: 1 })),
        endDate: endOfDay(sub(new Date(), { days: 1 })),
      };
    }
    case 'day': {
      return {
        startDate: sub(new Date(), { days: 1 }),
        endDate: new Date(),
      };
    }
    case '2 days': {
      return {
        startDate: sub(new Date(), { days: 2 }),
        endDate: new Date(),
      };
    }
    case 'week': {
      return {
        startDate: sub(new Date(), { weeks: 1 }),
        endDate: new Date(),
      };
    }
    case '2 weeks': {
      return {
        startDate: sub(new Date(), { weeks: 2 }),
        endDate: new Date(),
      };
    }
    default:
      return null;
  }
};

const SystemStartupOptions: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    data, searchResult, loading, responseNotification,
  } = useSelector(
    (state: any) => state.Workflows.ViewTraceLogs,
  );
  const [viewTraceLogsState, setViewTraceLogsState] = useState<any>(() => ({
    searchState: {},
    searchData: [],
    selectFilterText: '',
    showResult: false,
    snackBarOpen: false,
    removeConfirmDialogOpen: false,
  }));

  const handleSelectFilterTextChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setViewTraceLogsState({
      ...viewTraceLogsState,
      selectFilterText: e.target.value,
    });
  };

  useEffect(() => {
    dispatch(getViewTraceLogsData());
    dispatch(getScreensList());
    return () => {
      dispatch(clearViewTraceLogsSearchData());
    };
  }, []);

  useEffect(() => {
    const newSearchState = createStateData(data);
    const newSearchData = createSearchData(data);
    setViewTraceLogsState((prev: any) => ({
      ...prev,
      searchData: newSearchData,
      searchState: newSearchState,
    }));
  }, [data]);

  useEffect(() => {
    if (responseNotification?.type === 'success') {
      setViewTraceLogsState((prev: any) => ({ ...prev, snackBarOpen: true }));
    }
  }, [responseNotification?.type]);

  useEffect(() => {
    if (
      localStorage.getItem('pattern')
      && !isEmpty(viewTraceLogsState?.searchState)
    ) {
      const rackStatusData = JSON.parse(localStorage.getItem('pattern') ?? '');
      const newSearchState = {
        ...viewTraceLogsState?.searchState,
        'Max Lines': {
          label: String(rackStatusData?.maxlines),
          value: rackStatusData?.maxlines,
          selected: true,
        },
        'Search Pattern': rackStatusData.pattern,
      };
      dispatch(searchViewTraceLogs(newSearchState, false));
      setViewTraceLogsState((prev: any) => ({
        ...prev,
        searchState: newSearchState,
        showResult: true,
      }));
      localStorage.removeItem('pattern');
    }
  }, [viewTraceLogsState.searchState]);

  const handleSelectChange = (name: string, option: any) => {
    if (!option) {
      setViewTraceLogsState({
        ...viewTraceLogsState,
        searchState: {
          ...viewTraceLogsState.searchState,
          [name]: { label: '', value: '' },
        },
      });
      return;
    }
    const result = getStartEndDatesFromPresets(option.value);
    if (result) {
      const { startDate, endDate } = result;
      const formattedStartDate = getPGCRFormattedDate(startDate);
      const formattedStartTime = getFormattedTime(startDate);
      const formattedEndtDate = getPGCRFormattedDate(endDate);
      const formattedEndtTime = getFormattedTime(endDate);
      setViewTraceLogsState({
        ...viewTraceLogsState,
        searchState: {
          ...viewTraceLogsState.searchState,
          [name]: option,
          'System Start Date': {
            inputValue: `${formattedStartDate} ${formattedStartTime}`,
            pickerValue: startDate,
          },
          'System End Date': {
            inputValue: `${formattedEndtDate} ${formattedEndtTime}`,
            pickerValue: endDate,
          },
        },
      });
    } else {
      setViewTraceLogsState({
        ...viewTraceLogsState,
        searchState: {
          ...viewTraceLogsState.searchState,
          [name]: option,
        },
      });
    }
  };

  const handleDateTimePckerChange = (name: string, value: Date | null) => {
    const formattedDate = getPGCRFormattedDate(value);
    const formattedTime = getFormattedTime(value);
    setViewTraceLogsState({
      ...viewTraceLogsState,
      searchState: {
        ...viewTraceLogsState.searchState,
        [name]: {
          inputValue: `${formattedDate} ${formattedTime}`,
          pickerValue: value,
        },
        'Preset Date Time': { label: 'Custom', value: 'custom' },
      },
    });
  };

  const handleInputChange = (name: string, value: string) => {
    setViewTraceLogsState({
      ...viewTraceLogsState,
      searchState: {
        ...viewTraceLogsState.searchState,
        [name]: value,
      },
    });
  };

  const onApply = () => {
    setViewTraceLogsState({ ...viewTraceLogsState, showResult: true });
    dispatch(searchViewTraceLogs(viewTraceLogsState.searchState, false));
  };

  const onCancel = () => {
    setViewTraceLogsState({ ...viewTraceLogsState, showResult: true });
  };

  const onRemove = () => {
    setViewTraceLogsState({
      ...viewTraceLogsState,
      removeConfirmDialogOpen: true,
    });
  };

  const modifySearch = () => {
    setViewTraceLogsState({ ...viewTraceLogsState, showResult: false });
  };

  const handleAddSelectValue = () => {
    if (viewTraceLogsState.selectFilterText) {
      const newSearchData = [...viewTraceLogsState.searchData];
      const filterSelect: any = newSearchData[0];
      filterSelect.options = [
        ...filterSelect.options,
        {
          label: `${viewTraceLogsState.selectFilterText} [Temporary]`,
          value: viewTraceLogsState.selectFilterText,
          selected: true,
        },
      ];
      setViewTraceLogsState({
        ...viewTraceLogsState,
        searchData: newSearchData,
        searchState: {
          ...viewTraceLogsState.searchState,
          Filter: {
            label: `${viewTraceLogsState.selectFilterText} [Temporary]`,
            value: viewTraceLogsState.selectFilterText,
            selected: true,
          },
        },
        selectFilterText: '',
      });
    }
  };

  const handleCloseSnackBar = () => {
    setViewTraceLogsState({ ...viewTraceLogsState, snackBarOpen: false });
    dispatch(clearViewTraceLogsNotification());
  };

  const closeRemoveConfirmDialog = () => {
    setViewTraceLogsState({
      ...viewTraceLogsState,
      removeConfirmDialogOpen: false,
    });
  };

  const closeAgreeRemoveConfirmDialog = () => {
    closeRemoveConfirmDialog();
    setViewTraceLogsState({
      ...viewTraceLogsState,
      showResult: true,
      removeConfirmDialogOpen: false,
    });
    dispatch(searchViewTraceLogs(viewTraceLogsState.searchState, true));
  };

  const handleCloseSearhCard = () => {
    setViewTraceLogsState({
      ...viewTraceLogsState,
      showResult: true,
    });
  };

  return (
    <div className={clsx('flexColumn fullHeight', classes.root)}>
      <TmsHeader />
      <SubHeader title="View Trace Logs" />
      <div className={clsx(classes.wrapper)}>
        {!viewTraceLogsState.showResult ? (
          <ViewTraceLogsSearchCrad
            onApply={onApply}
            onCancel={onCancel}
            onRemove={onRemove}
            searchData={viewTraceLogsState.searchData}
            handleDateTimePckerChange={handleDateTimePckerChange}
            handleInputChange={handleInputChange}
            handleSelectChange={handleSelectChange}
            searchState={viewTraceLogsState.searchState}
            handleChangeFilterText={handleSelectFilterTextChange}
            selectFilterText={viewTraceLogsState.selectFilterText}
            handleAddSelectValue={handleAddSelectValue}
            closeSearchCard={handleCloseSearhCard}
          />
        ) : null}
        <ButtonBase className={classes.button} onClick={modifySearch}>
          Modify Search
        </ButtonBase>
        <div className={classes.infoSection}>
          {loading ? (
            <img
              src="/assets/images/loadingtrain.gif"
              alt="loader"
              width={80}
              style={{ marginRight: 2 }}
            />
          ) : responseNotification?.type === 'failure' ? (
            failMessage
          ) : (
            searchResult.title
          )}
        </div>
      </div>
      <ViewTraceLogs searchResult={searchResult} />
      {responseNotification?.FilterId ? (
        <Snackbar
          open={viewTraceLogsState.snackBarOpen}
          autoHideDuration={3000}
          onClose={handleCloseSnackBar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert onClose={handleCloseSnackBar} severity="success">
            SUCCESS
            <div>
              Filter:
              {' '}
              {responseNotification?.FilterId}
              {' '}
              {responseNotification?.request === 'remove' ? 'Deleted' : 'Saved'}
            </div>
          </Alert>
        </Snackbar>
      ) : null}
      <ConfirmationDialog
        cancelLabel="Cancel"
        confirmLabel="Yes"
        handleCloseAgree={closeAgreeRemoveConfirmDialog}
        handleCloseCancel={closeRemoveConfirmDialog}
        message="Remove the selected filter?"
        open={viewTraceLogsState.removeConfirmDialogOpen}
        title="Confirm Remove"
      />
    </div>
  );
};

export default SystemStartupOptions;
