/* eslint-disable react/require-default-props */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-fragments */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';

// Librairies
import {
  ButtonBase,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import {
  Settings,
  Remove,
  Add,
  ArrowDropDown,
  ArrowDropUp,
  UnfoldMore,
} from '@mui/icons-material';

// Styles
import { useStyles } from './style';

const collapsedTableCells: { label: string; key: string }[] = [
  { label: 'ID', key: 'ID' },
  { label: 'User ID', key: 'UserID' },
  { label: 'Transaction Reference Num', key: 'TransRefNo' },
  { label: 'Product Record Number', key: 'ProdRecNo' },
  { label: 'Route Code', key: 'PrinterRouteCode' },
  { label: 'Fallback Date', key: 'FallbackDate' },
  { label: 'Waiver Code', key: 'WaiverCode' },
  { label: 'Deferred Submission Count', key: 'DeferredSubmissionCount' },
];

interface IProps {
  handleClickRow: () => void;
  openDeviceEMCSManagerDialog: () => void;
  tableData: any[];
  tableHeader: { label: string; key: string }[];
  searchText?: string;
  orderBy?: string;
  order?: string;
  sortTable?: (key: string) => void;
}

const sortedTable = (data: any[], orderBy?: string, order?: string) => {
  if (!orderBy || !order) return data;
  return data.sort((a, b) => {
    const orderResult: number = String(a[orderBy]).localeCompare(b[orderBy]);
    return order === 'asc' ? orderResult : -orderResult;
  });
};

const EMCSManagerTable: React.FC<IProps> = ({
  handleClickRow,
  openDeviceEMCSManagerDialog,
  tableData,
  tableHeader,
  searchText,
  order,
  orderBy,
  sortTable,
}) => {
  const classes = useStyles();
  const [collapsedIndexes, setCollapsedIndexes] = useState<number[]>([]);

  const onCollapse = (index: number) => {
    if (collapsedIndexes.includes(index)) {
      setCollapsedIndexes(collapsedIndexes.filter((x: number) => x !== index));
    } else {
      setCollapsedIndexes([...collapsedIndexes, index]);
    }
  };

  const handleSortClick = (key: string) => (event: React.MouseEvent<unknown>) => {
    if (sortTable) {
      sortTable(key);
    }
  };

  const getStatusLabel = (status: string): string => {
    switch (status) {
      case 'A': {
        return 'Awaiting Response';
      }
      default:
        return '';
    }
  };

  return (
    <div className={classes.root}>
      <Table classes={{ root: classes.tableRoot }}>
        <TableHead>
          <TableRow classes={{ root: classes.headerRow }}>
            <TableCell classes={{ root: classes.headerCell }}> </TableCell>
            {tableHeader.map((h) => (
              <TableCell classes={{ root: classes.headerCell }} key={h.key}>
                <TableSortLabel
                  active={orderBy === h.key}
                  onClick={handleSortClick(h.key)}
                  IconComponent={() => (order === 'asc' && orderBy === h.key ? (
                    <ArrowDropUp />
                  ) : order === 'desc' && orderBy === h.key ? (
                    <ArrowDropDown />
                  ) : (
                    <UnfoldMore />
                  ))}
                  classes={{ root: classes.sortLabel }}
                >
                  {h.label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {!tableData.length && (
            <TableRow className={classes.tableRow}>
              <TableCell
                classes={{ root: classes.bodyCellEmpty }}
                style={{ textAlign: 'center' }}
                colSpan={tableHeader.length + 1}
              >
                {searchText
                  ? 'No matching records found'
                  : 'No data available in table'}
              </TableCell>
            </TableRow>
          )}
          {sortedTable(tableData, orderBy, order)?.map((record, index) => (
            <React.Fragment key={index}>
              <TableRow onClick={handleClickRow} className={classes.tableRow}>
                <TableCell classes={{ root: classes.bodyCell }}>
                  <div className={classes.actionsWrapper}>
                    <ButtonBase
                      className={classes.actionButton}
                      onClick={() => onCollapse(index)}
                    >
                      {collapsedIndexes.includes(index) ? (
                        <Remove fontSize="small" />
                      ) : (
                        <Add fontSize="small" />
                      )}
                    </ButtonBase>
                    <ButtonBase
                      className={classes.actionButton}
                      onClick={openDeviceEMCSManagerDialog}
                    >
                      <Settings fontSize="small" />
                    </ButtonBase>
                  </div>
                </TableCell>
                {tableHeader.map((field) => (
                  <TableCell
                    classes={{ root: classes.bodyCell }}
                    key={field.key}
                  >
                    {field.key === 'ARCStatus'
                      ? getStatusLabel(record[field.key])
                      : record[field.key]}
                  </TableCell>
                ))}
              </TableRow>
              {collapsedIndexes.includes(index) ? (
                <TableRow className={classes.tableRow}>
                  <TableCell classes={{ root: classes.bodyCell }} colSpan={8}>
                    <div className={classes.detailGroup}>
                      <table style={{ borderSpacing: 0 }}>
                        <thead>
                          <tr>
                            {collapsedTableCells.map((col) => (
                              <th
                                className={classes.prodInfoCell}
                                key={col.key}
                              >
                                {col.label}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            {collapsedTableCells.map((col, i) => (
                              <td key={i} className={classes.prodStatusValue}>
                                {record[col.key] || '- - - - - - -'}
                              </td>
                            ))}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </TableCell>
                </TableRow>
              ) : null}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default React.memo(EMCSManagerTable);
