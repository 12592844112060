import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  closeButton: {
    borderWidth: '1px 1px 1px 5px !important',
    borderColor: '#ffb848 !important',
    color: 'black !important',
    textShadow: 'none !important',
    backgroundColor: '#ddd !important',
    border: '1px solid transparent !important',
    padding: '4px 10px !important',
    '&:hover': {
      color: '#fff !important',
      backgroundColor: '#545454 !important',
    },
  },
  saveButton: {
    verticalAlign: 'middle',
    textAlign: 'center',
    backgroundColor: '#00C851 !important',
    color: 'black !important',
    padding: '4px 10px !important',
    border: '1px solid transparent',
    marginLeft: '5px !important',
    '&:hover': {
      backgroundColor: '#007E33 !important',
      color: 'white !important',
    },
    '&:disabled': {
      color: '#555 !important',
      backgroundColor: '#bcbcbc !important',
      border: '1px solid transparent !important',
      cursor: 'not-allowed',
    },
  },
  cancelButton: {
    color: 'black !important',
    textShadow: 'none !important',
    backgroundColor: '#ffbb33 !important',
    padding: '4px 10px !important',
    marginLeft: '10px !important',
    border: '1px solid transparent',
    '&:hover': {
      backgroundColor: '#FF8800 !important',
      color: 'white !important',
    },
  },
  removeButton: {
    color: 'black !important',
    textShadow: 'none !important',
    backgroundColor: '#ff4444 !important',
    padding: '4px 10px !important',
    marginLeft: '10px !important',
    border: '1px solid transparent',
    '&:hover': {
      backgroundColor: '#CC0000 !important',
      color: 'white !important',
    },
  },
  buttonsContainer: {
    display: 'flex', justifyContent: 'space-between',
  },
  divider: {
    marginTop: '10px !important',
    marginBottom: '5px !important',
  },
  inputLabel: {
    fontSize: 12,
    color: 'black',
    fontWeight: 400,
  },
  layoutLabel: {
    width: '25%', flex: 1, textAlign: 'right',
  },
  secondaryButton: {
    border: '1px solid #555 !important',
    borderLeft: '4px solid #555 !important',
    padding: '2px 8px !important',
    backgroundColor: '#e5e5e5',
  },
  buttonDisabled: {
    border: '0px !important',
    borderLeft: '0px !important',
    backgroundColor: '#bcbcbc !important',
  },
  showHideGridButton: {
    border: '1px solid #555 !important',
    borderLeft: '4px solid #555 !important',
    padding: '2px 8px !important',
    backgroundColor: '#e5e5e5',
    marginRight: '4px',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  showHideGridLabel: {
    fontWeight: '400 !important',
    fontSize: '12px',
  },
  contentsContainer: {
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'row',
    height: '85%',
  },
  outlineMenuContainer: {
    flex: '0.42', flexDirection: 'column', justifyContent: 'center',
  },
  header: {
    display: 'flex', flexDirection: 'column', width: '90%', gap: 8,
  },
  headerEntryWithSideButton: {
    display: 'flex', flexDirection: 'row', alignItems: 'center',
  },
  elementContainer: {
    display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10,
  },
  inputStyles: {
    width: '75%',
  },
  body: {
    display: 'flex', flexDirection: 'row', paddingTop: '10px', paddingBottom: '10px', flexWrap: 'wrap',
  },
  outlineContentsContainer: {
    flex: '0.42', width: '50%', flexBasis: '0.42',
  },
  outlineTree: {
    paddingRight: 12, paddingLeft: 12, overflowY: 'auto', height: 700,
  },
  outlineDetailContainer: {
    flex: '0.58', width: '50%', flexBasis: '0.58', overflowY: 'auto', height: 700,
  },
  outlineDetail: {
    display: 'flex', flexDirection: 'column', width: '90%', gap: 8, justifyContent: 'center',
  },
  detailEntryContainer: {
    display: 'flex', flexDirection: 'row', gap: 10, width: '100%', alignItems: 'center',
  },
  entryWithSideButton: {
    display: 'flex', flexDirection: 'row', gap: 0, width: '70%', alignItems: 'center',
  },
  canvasWrapper: {
    flex: '0.58',
    background: 'lightgray',
    justifyContent: 'center',
    display: 'flex',
    paddingTop: '10px',
    paddingBottom: '10px',
    overflow: 'auto',
  },
});
