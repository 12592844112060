/* eslint-disable react/require-default-props */
import React from 'react';

import {
  Tab, Tabs, Box,
} from '@mui/material';

import { useStyles } from './style';
import { TableData } from './TableData';

interface TabPanelProps {
  index: number;
  value: number;
  data: any;
}

const TabPanel = (props: TabPanelProps) => {
  const { value, index, data } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {data?.tableTitle?.map((title: string, i: number) => (
        <TableData
          key={title}
          historyList={data.historyList[i] || []}
          tableHeaders={data.tableHeaders || {}}
          tableTitle={title}
        />
      ))}
    </div>
  );
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface IChangeHistory {
  historyModalData: any;
  limitNumber?: number;
}

const ChangeHistory: React.FC<IChangeHistory> = ({
  historyModalData,
  limitNumber,
}) => {
  const [value, setValue] = React.useState(0);
  const classes = useStyles();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div>
      <div style={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {Object.keys(historyModalData).map((key) => (
              <Tab
                label={key}
                {...a11yProps(0)}
                className={classes.tab}
                style={{ textTransform: 'none' }}
              />
            ))}
          </Tabs>
        </Box>
        {value === 0 && (
          <TabPanel
            value={value}
            index={0}
            data={historyModalData.ProductGroup}
          />
        )}
        {value === 1 && (
          <TabPanel
            value={value}
            index={1}
            data={historyModalData.GroupProductXRef}
          />
        )}
      </div>
    </div>
  );
};

export default ChangeHistory;
