/* eslint-disable max-len */
import React, { useEffect } from 'react';

// Librairies
import {
  ButtonBase, Divider, Grid, Table, TableCell, TableRow, Typography,
} from '@mui/material';
import clsx from 'clsx';

// Styles
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from './style';
import Select from '../Select';
import TankStrappingFirstGrid from '../TankStrappingFirstGrid';
import { getTankStrappingDetails } from '../../redux/actions/workflows/tankStrapping';

const TankStrappingTablesBody: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { detailsData } = useSelector(
    (state: any) => state?.Workflows?.TankStrapping,
  );
  return (
    <>
      <Grid container>
        <Grid item xs={12} lg={6} sm={12}>
          <div className={clsx('flexRow', classes.wrapper)}>
            <Typography variant="caption" className={classes.label}>Unit Override</Typography>
            <Select
              options={[]}
              style={{ width: '75%' }}
              required
            />
          </div>
        </Grid>
        <Grid item xs={12} lg={6} sm={12}>
          <div className={clsx('flexRow', classes.wrapper)}>
            <Typography variant="caption" className={classes.label}>Unit Override</Typography>
            <Select
              options={[]}
              style={{ width: '75%' }}
            />
          </div>
        </Grid>
        <Grid item xs={12} lg={6} sm={12}>
          <div className={clsx('flexRow', classes.wrapper)}>
            <Typography variant="caption" className={classes.label}>Outage</Typography>
            <Select
              options={['Yes', 'No']}
              style={{ width: '75%' }}
              required
            />
          </div>
        </Grid>
      </Grid>
      {Object.keys(detailsData).length && (
      <TankStrappingFirstGrid
        fractionsGrid={detailsData?.fractionsGrid || []}
        firstGridRowOne={detailsData?.firstGrid?.firstGridRowOne || []}
        firstGridRowtwo={detailsData?.firstGrid?.firstGridRowtwo || []}
        fractionsheader={detailsData?.fractionsheader || ''}
        secondGrid={detailsData?.secondGrid || []}
      />
      )}
    </>
  );
};

export default TankStrappingTablesBody;
