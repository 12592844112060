import { LOAD_USERS_DATA, CLEAR_USERS_DATA } from '../../constants/workflows';

const INITIAL_STATE = {
  data: [],
  fields: {},
};

const TrafficReducer = (state = INITIAL_STATE, { type, payload }: any) => {
  switch (type) {
    case LOAD_USERS_DATA: {
      return {
        ...state,
        data: payload.data,
        fields: payload.fields,
      };
    }
    case CLEAR_USERS_DATA: {
      return {
        ...state,
        data: [],
        fields: {},
      };
    }
    default:
      return state;
  }
};

export default TrafficReducer;
