import { Dispatch } from 'redux';
import Api from '../../api/index';
import { LOAD_VEHICLES_IN_FACILITY } from '../../constants/workflows';

export const getVehiclesInFacilityData = () => async (dispatch: Dispatch) => {
  const response = await Api.get(
    'workflow030/data',
  );
  dispatch({
    type: LOAD_VEHICLES_IN_FACILITY,
    payload: response.data,
  });
};
