import { Reducer } from 'redux';
import {
  LOAD_USER_SESSION,
} from '../../constants/workflows';

export interface IUserSessionData {
  UserId: string;
  UserName: string;
  LastLogin: string;
  ActiveSessions: number;
}

export interface IUserSessionState {
  data: Array<IUserSessionData>;
  headerList: { [key: string]: string };
  hiddenColumns: { [key: string]: string };
  visibleColumns: { [key: string]: string };
  currentRecord: IUserSessionData | null;
  formHeader: { [key: string]: string };
  responseNotification: { type: string; };
}

export const INITIAL_STATE: IUserSessionState = {
  data: [],
  headerList: {},
  hiddenColumns: {},
  visibleColumns: {},
  currentRecord: null,
  formHeader: {},
  responseNotification: { type: 'initialize' },
};

const userSessionReducer: Reducer<IUserSessionState> = (
  state = INITIAL_STATE,
  { type, payload }: any,
) => {
  switch (type) {
    case LOAD_USER_SESSION: {
      return {
        ...state,
        data: payload.data,
        headerList: payload.headerList,
        hiddenColumns: payload.hiddenColumns,
        visibleColumns: payload.visibleColumns,
      };
    }
    default:
      return state;
  }
};

export default userSessionReducer;
