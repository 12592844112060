import {
  CLEAR_TANK_GAUGE_ENTRY,
  LOAD_TANK_GAUGE_ENTRY,
  LOAD_TANK_GAUGE_ENTRY_RECORD,
  SET_SELECTED_TANK_GAUGE_RECORD,
  SET_TANK_GAUGE_ENTRY_FORM_HEADER,
  CLEAR_TANK_GAUGE_ENTRY_FORM_HEADER,
  CLEAR_TANK_GAUGE_ENTRY_FORM_DATA,
  CLEAR_TANK_GAUGE_ENTRY_EXPORT_LINK,
  CLEAR_TANK_GAUGE_ENTRY_EXPORT_FILE,
  CLEAR_TANK_GAUGE_RESPONSE_NOTIFICATION,
  SET_TANK_GAUGE_RESPONSE_NOTIFICATION,
  SET_TANK_GAUGE_ENTRY_EXPORT_FILE,
  SET_TANK_GAUGE_ENTRY_EXPORT_LINK,
  SET_TANK_GAUGE_ENTRY_FORM_DATA,
  SET_GLOBAL_TANK_GAUGE_ENTRY_FORM_DATA,
  SET_TANK_GAUGE_ENTRY_MODAL_DATA,
  CLEAR_TANK_GAUGE_ENTRY_MODAL_DATA,
  SET_AUTO_TANK_GAUGE_DATA,
  CLEAR_AUTO_TANK_GAUGE_DATA,
  CLEAR_TANK_GAUGE_ENTRY_RECORD,
  CLEAR_SELECTED_TANK_GAUGE_RECORD,
} from '../../constants/workflows';

const INITIAL_STATE = {
  data: [],
  hiddenColumns: {},
  visibleColumns: {},
  currentRecord: {},
  selectedRecord: null,
  formData: {},
  formHeader: {},
  exportFile: '',
  exportLink: '',
  responseNotification: {},
  modalData: {},
  autoTankGaugeData: {},
};

const TankGaugeEntryReducer = (state = INITIAL_STATE, { type, payload }: any) => {
  switch (type) {
    case LOAD_TANK_GAUGE_ENTRY: {
      return {
        ...state,
        data: payload.data,
        hiddenColumns: payload.hiddenColumns,
        visibleColumns: payload.visibleColumns,
      };
    }
    case CLEAR_TANK_GAUGE_ENTRY: {
      return {
        ...state,
        data: [],
        hiddenColumns: [],
        visibleColumns: [],
      };
    }
    case SET_AUTO_TANK_GAUGE_DATA: {
      return {
        ...state,
        autoTankGaugeData: payload,
      };
    }
    case CLEAR_AUTO_TANK_GAUGE_DATA: {
      return {
        ...state,
        autoTankGaugeData: {},
      };
    }
    case SET_TANK_GAUGE_ENTRY_MODAL_DATA: {
      return {
        ...state,
        modalData: payload,
      };
    }
    case CLEAR_TANK_GAUGE_ENTRY_MODAL_DATA: {
      return {
        ...state,
        modalData: {},
      };
    }
    case LOAD_TANK_GAUGE_ENTRY_RECORD: {
      return {
        ...state,
        currentRecord: {
          data: payload.data,
          fieldList: payload.fieldList,
          fieldHiddenList: payload.fieldHiddenList,
          header: payload.header,
          historyModal: payload.historyModal,
          modalFields: payload.modalFields,
        },
      };
    }
    case CLEAR_TANK_GAUGE_ENTRY_RECORD: {
      return {
        ...state,
        currentRecord: {},
      };
    }
    case SET_TANK_GAUGE_RESPONSE_NOTIFICATION: {
      return {
        ...state,
        responseNotification: payload,
      };
    }
    case CLEAR_TANK_GAUGE_RESPONSE_NOTIFICATION: {
      return {
        ...state,
        responseNotification: {},
      };
    }
    case SET_TANK_GAUGE_ENTRY_FORM_HEADER: {
      return {
        ...state,
        formHeader: {
          ...state.formHeader,
          ...payload,
        },
      };
    }
    case CLEAR_TANK_GAUGE_ENTRY_FORM_HEADER: {
      return {
        ...state,
        formHeader: {},
      };
    }
    case SET_TANK_GAUGE_ENTRY_EXPORT_FILE: {
      return {
        ...state,
        exportFile: payload,
      };
    }
    case CLEAR_TANK_GAUGE_ENTRY_EXPORT_FILE: {
      return {
        ...state,
        exportFile: '',
      };
    }
    case SET_TANK_GAUGE_ENTRY_EXPORT_LINK: {
      return {
        ...state,
        exportLink: payload,
      };
    }
    case CLEAR_TANK_GAUGE_ENTRY_EXPORT_LINK: {
      return {
        ...state,
        exportLink: '',
      };
    }
    case CLEAR_TANK_GAUGE_ENTRY_FORM_DATA: {
      return {
        ...state,
        formData: {},
      };
    }
    case SET_GLOBAL_TANK_GAUGE_ENTRY_FORM_DATA: {
      return {
        ...state,
        formData: payload,
      };
    }
    case SET_TANK_GAUGE_ENTRY_FORM_DATA: {
      return {
        ...state,
        formData: {
          ...state.formData,
          [payload.index]: payload.row,
        },
      };
    }
    case SET_SELECTED_TANK_GAUGE_RECORD: {
      return {
        ...state,
        selectedRecord: payload,
      };
    }
    case CLEAR_SELECTED_TANK_GAUGE_RECORD: {
      return {
        ...state,
        selectedRecord: {},
      };
    }
    default:
      return state;
  }
};

export default TankGaugeEntryReducer;
