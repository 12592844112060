import { combineReducers } from 'redux';
import LoginReducer from './loginReducer';
import ScreenReducer from './ScreenReducer';
import EmbbededScreenReducer from './embbededReducer';
import RecordReducer from './recordReducer';
import UnitCalculatorReducer from './unitCalculatorReducer';
import SystemStartupReducer from './systemStartupReducer';
import VsxFiltersReducer from './vsxReducer';
import Workflows from './workflows';
import Reports from './reports';
import SystemUtilities from './systemUtilities';
import ContractTransactionReducer from './workflows/ContractTransaction';

const rootReducer = combineReducers({
  LoginReducer,
  ScreenReducer,
  EmbbededScreenReducer,
  RecordReducer,
  UnitCalculatorReducer,
  SystemStartupReducer,
  VsxFiltersReducer,
  Workflows,
  Reports,
  SystemUtilities,
  ContractTransactionReducer,
});

export default rootReducer;
