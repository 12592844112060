import React from 'react';

import Subnavbar from '../../components/Sub-navbar/Subnavbar';
import Footer from '../../components/Footer/Footer';
import HomeSearch from '../../components/HomeSearchInput/HomeSearchInput';
import Logo from '../../assets/logo.png';
import './LandingPage.css';
import TmsHeader from '../../components/header';

const LandingPage = () => (
  <>
    <TmsHeader />
    <div className="container takeTheRest">
      <Subnavbar version={`8. 0. 0 Build ${process.env.REACT_APP_VERSION || '0'}`} />
      <div className="logogroup">
        <img src={Logo} className="logoimage" alt="logo" />
        <div className="prodname">Terminal Management System</div>
      </div>
      <div className="searchInput">
        <HomeSearch />
      </div>
    </div>
  </>
);

export default LandingPage;
