import React, { useState } from 'react';

// Librairies
import { useDispatch, useSelector } from 'react-redux';

// Components
import TmsHeader from '../../../components/header';
import SubHeader from '../../../components/subHeader';
import OptionsButton from '../../../components/optionsButton';
import SingleItemOptions from '../../../components/SingleItemOptions';
import TankStatusTable from '../../../components/tankStatusTable/TankStatusTable';
import { getScreensList } from '../../../redux/actions/ScreenActions';
import { getTankStatuses, sendOptionsButtonQuery } from '../../../redux/actions/workflows/tankStatus';

const TankStatus = () => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { tanks } = useSelector((state: any) => state?.Workflows?.TankStatusReducer);

  React.useEffect(() => {
    dispatch(getScreensList());
  }, []);

  React.useEffect(() => {
    dispatch(getTankStatuses());
  }, []);

  const handleOptionClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenOptionsItem = () => {
    dispatch(sendOptionsButtonQuery());
    handleCloseMenu();
  };

  return (
    <>
      <TmsHeader />
      <SubHeader title="Tank Status">
        <OptionsButton handleClick={handleOptionClick} />
        <SingleItemOptions
          anchorEl={anchorEl}
          onClose={handleCloseMenu}
          openDialog={handleOpenOptionsItem}
          itemLabel="HHL PLC Override"
        />
      </SubHeader>
      <TankStatusTable rows={tanks} />
    </>
  );
};

export default TankStatus;
