import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {},
  sortable: {
    listStyleType: 'none',
    paddingLeft: '0',
    marginRight: '10px',
  },
  sortable1: {
    listStyleType: 'none',
  },
  menuDiv: {
    background: '#EBEBEB',
    marginBottom: '1px',
    cursor: 'move',
    padding: '1px',
  },
  itemTitle: {
    cursor: 'pointer',
  },
  folderName: {
    fontWeight: 'normal',
    color: '#333333',
    backgroundColor: '#ffffff',
    border: '1px solid #e5e5e5',
    padding: '4px 0',
    width: '65%',
  },
  collapsebtn: {
    backgroundColor: 'white',
    border: '0.5px solid #EBEBEB',
  },
});
