/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import ControlButton from '../../../components/controlButton';
import TmsHeader from '../../../components/header';
import OrderMaintenanceVerticalStepper from './OrderMaintenanceVerticalStepper';
import { getScreensList } from '../../../redux/actions/ScreenActions';
import OrderMaintenanceMenu from './OrderMaintenanceMenu';
import {
  getSingleOrderMaintenanceRecord,
  getOrderMaintenanceChainedData,
} from '../../../redux/actions/workflows/orderMaintenance';

const OrderMaintenanceNewRecord = () => {
  const [stepperData, setStepperData] = useState([]);
  const { state } = useLocation();

  const [chained, setChained] = useState(false);
  const [type, setType] = useState<string>();
  const [values, setValues] = useState<any>(
    { TermID: { value: 'B344', label: 'B344: TOPTECH (LONGWOOD, FL)' } },
  );

  const [valuesToSend, setValuesToSend] = useState<any>({ TermID: 'B344' });

  const dispatch = useDispatch();
  const history = useNavigate();
  const { currentRecord, orderData } = useSelector((state: any) => state.Workflows.OrderMaintenanceReducer);

  useEffect(() => {
    dispatch(getScreensList());
    dispatch(getSingleOrderMaintenanceRecord((state as any)?.keyRec));
    setStepperData(currentRecord);
  }, [state]);
  useEffect(() => {
    setStepperData(currentRecord);
  }, [currentRecord]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    setValuesToSend({
      ...valuesToSend,
      [name]: value,
    });
  };

  useEffect(() => {
    if (chained) { dispatch(getOrderMaintenanceChainedData(type, values)); }
  }, [chained]);

  useEffect(() => {
    setChained(false);
  }, [values]);

  const handleDetailSelectChange = () => {
    //
  };
  useEffect(() => {
    if (orderData?.Orders?.[0]) {
      const TermID = orderData.Orders[0].TermID ? orderData.Orders[0].TermID : '';
      const SupplierNo = orderData.Orders[0].SupplierNo ? orderData.Orders[0].SupplierNo : '';
      const CustNo = orderData.Orders[0].CustNo ? orderData.Orders[0].CustNo : '';
      const AcctNo = orderData.Orders[0].AcctNo ? orderData.Orders[0].AcctNo : '';
      const DestinationNo = orderData.Orders[0].DestinationNo ? orderData.Orders[0].DestinationNo : '';
      const type = 'edit';
      const values = {
        SupplierNo, CustNo, AcctNo, DestinationNo, TermID,
      };
      dispatch(getOrderMaintenanceChainedData(type, values));
    }
  }, [orderData]);

  const handleRecordSelectChange = (
    label: any,
    e: React.ChangeEvent<HTMLInputElement>,
    newValue: any,
    key: any,
  ) => {
    setType(label);
    if (label === 'SupplierNo') {
      setValuesToSend({
        ...valuesToSend,
        [label?.name || label]: newValue?.value || label?.newValue,
        CustNo: '',
        AcctNo: '',
        DestinationNo: '',
        ContractNumber: '',
      });
      setValues({
        ...values,
        [label?.name || label]: newValue || label?.newValue,
        CustNo: '',
        AcctNo: '',
        DestinationNo: '',
        ContractNumber: '',
      });
      setChained(true);
    } else if (label === 'CustNo') {
      setValuesToSend({
        ...valuesToSend,
        [label?.name || label]: newValue?.value || label?.newValue,
        AcctNo: '',
        DestinationNo: '',
        ContractNumber: '',
      });
      setValues({
        ...values,
        [label?.name || label]: newValue || label?.newValue,
        AcctNo: '',
        DestinationNo: '',
        ContractNumber: '',
      });
      setChained(true);
    } else if (label === 'AcctNo') {
      setValuesToSend({
        ...valuesToSend,
        [label?.name || label]: newValue?.value || label?.newValue,
        DestinationNo: '',
        ContractNumber: '',
      });
      setValues({
        ...values,
        [label?.name || label]: newValue || label?.newValue,
        DestinationNo: '',
        ContractNumber: '',
      });
      setChained(true);
    } else if (label === 'DestinationNo') {
      setValuesToSend({
        ...valuesToSend,
        [label?.name || label]: newValue?.value || label?.newValue,
        ContractNumber: '',
      });
      setValues({
        ...values,
        [label?.name || label]: newValue || label?.newValue,
        ContractNumber: '',
      });
      setChained(true);
    } else if (label === 'ContractNumber') {
      setValuesToSend({
        ...valuesToSend,
        [label?.name || label]: newValue?.value || label?.newValue,
      });
      setValues({
        ...values,
        [label?.name || label]: newValue || label?.newValue,
      });
      setChained(true);
    } else {
      setValues({
        ...values,
        [label?.name || label]: newValue || label?.newValue,
      });
      setValuesToSend({
        ...valuesToSend,
        [label?.name || label]: newValue?.value || label?.newValue,
      });
    }
  };

  const handleInputProductChange = (name:any, e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    setValuesToSend({
      ...valuesToSend,
      [name]: value,
    });
  };

  const handleSelectChange = (
    label: any,
    e: React.ChangeEvent<HTMLInputElement>,
    newValue: any,
  ) => {
    setValues({
      ...values,
      [label]: newValue,
    });
    setValuesToSend({
      ...valuesToSend,
      [label]: newValue.value,
    });
  };

  const handleBackButton = () => {
    history('/workflow/order-maintenance');
  };
  return (
    <>
      <TmsHeader />
      <div className="sub-nav-container">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h3 id="pagetitlebar_title" className="page-title">
            Order Maintenance
          </h3>
        </div>
        <div style={{
          marginRight: '10px',
        }}
        >
          <OrderMaintenanceMenu type="details" recordData={orderData?.Orders?.[0]} />
        </div>
      </div>
      <div style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        gap: '10px',
        padding: '10px',
      }}
      >
        <ControlButton
          color="#ffbb33"
          label="Back"
          handleClick={handleBackButton}
          disabled={false}
        />
        <ControlButton
          color="red"
          label="Remove"
          handleClick={handleBackButton}
          disabled
        />
      </div>

      <OrderMaintenanceVerticalStepper
        data={stepperData}
        values={values}
        setValues={setValues}
        valuesToSend={valuesToSend}
        setValuesToSend={setValuesToSend}
        handleSelectChange={handleSelectChange}
        handleInputChange={handleInputChange}
        handleInputProductChange={handleInputProductChange}
        isBulkTransaction
        visible
        stepperTitles={
          ['Transport Information',
            'Entity/Transaction Information',
            'Date / Type / Comment', 'Products',
          ]
        }
        screenID="WORKFLOW-166"
        handleRecordSelectChange={handleRecordSelectChange}
        handleDetailSelectChange={handleDetailSelectChange}
        ProductStepReviewState={[
          { id: 'OrderItem', label: 'Order Item' },
          { id: 'ProdID', label: 'Product ID' },
          { id: 'OrderQty', label: 'Order Quantity' },
          { id: 'Trailer', label: 'Trailer' },
          { id: 'Compartment', label: 'Compartment' },
          { id: 'Retained', label: 'Retained' },
          { id: 'Loaded', label: 'Loaded' },
          { id: 'Returned', label: 'Returned' },
        ]}
      />

    </>

  );
};

export default OrderMaintenanceNewRecord;
