import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    minWidth: 220,
  },
  input: {
    padding: '0 !important',
    '& .MuiOutlinedInput-root': {
      padding: '0 !important',
      borderRadius: 0,
      fontSize: 12,
      fontWeight: 400,
      color: 'black',
    },
  },
  autoCompleteInput: {
    padding: '4.5px 8px 4.5px 6px !important',
    borderLeft: '4px solid #555 !important',
  },
  simpleAutoCompleteInput: {
    padding: '4.5px 8px 4.5px 6px !important',
  },
  autoCompleteRequired: {
    padding: '4.5px 8px 4.5px 6px !important',
    borderLeft: '4px solid red !important',
  },
});
