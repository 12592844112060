import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Alert, CircularProgress, Collapse, IconButton, Snackbar, Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation, useNavigate } from 'react-router-dom';
import Subnavbar from '../../components/Sub-navbar/Subnavbar';
import {
  CloneRecordSubmit, dissmissConfirmationAlert, LoadRecord, UpdateRecord,
} from '../../redux/actions/ScreenActions';
import CreateRecordHeader from '../../components/createMaintcomp/createRecordHeader';
import CreateRecordBody from '../../components/createMaintcomp/createRecordBody';
import {
  LoadRecordEmbbeded,
  CloneRecordSubmitEmbedded,
} from '../../redux/actions/embbededScreensActions';
import EmbeddedSubnavbar from './subNavEmbedded/subNavEmbedded';

const CreateMaintEmbeddedScreen = ({ handleEmbeddedClose }:any) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const {
    recordDetails, parsedValues, activeScreen, openConfirmationAlert, confirmationAlert,
  } = useSelector((state: any) => state.EmbbededScreenReducer);
  const [disabledFields, setDisabledFields] = useState([]);
  const [values, setValues] = useState<any>([]);
  const [open, setOpen] = useState(true);
  const [Visible, setVisible] = useState(false);
  const [isModifiy, setIsModifiy] = useState(false);
  const [valuesToSend, setValuesToSend] = useState<any>([]);
  const {
    Language,
  } = useSelector((state: any) => state.LoginReducer);
  useEffect(() => {
    if (activeScreen) {
      dispatch(LoadRecordEmbbeded({
        tableID: activeScreen.tableID,
        screenID: activeScreen.screenId,
        where: '',
        fields: activeScreen.fields,
        lang: Language,
      }));
    } else if (!activeScreen) {
      navigate('/home');
    }
  }, [activeScreen]);
  useEffect(() => {
    const parsedEmbededConfigs = JSON.parse(activeScreen.data);
    setValues(parsedEmbededConfigs);
    setValuesToSend(parsedEmbededConfigs);
    setDisabledFields(valuesToSend);
  }, [parsedValues]);

  const handleCloseConfirm = () => {
    setVisible(!Visible);
  };
  const handleClose = () => {
    dispatch(dissmissConfirmationAlert());
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    setValuesToSend({
      ...valuesToSend,
      [name]: value,
    });
    setIsModifiy(true);
  };
  const handleSelectChange = (label:any, e:React.ChangeEvent<HTMLInputElement>, newValue:any) => {
    setValues({
      ...values,
      [label]: newValue,
    });
    setValuesToSend({
      ...valuesToSend,
      [label]: newValue.value,
    });
    setIsModifiy(true);
  };
  const handleSave = () => {
    dispatch(
      CloneRecordSubmitEmbedded({
        table: activeScreen.tableID,
        navigate,
        backScreen: activeScreen.redirect,
        form_data: valuesToSend,
      }),
    );
  };
  const compare = (currentValue: any) => {
    const result = currentValue?.obj['\u0000*\u0000parm']?.options?.filter((e: any) => (e?.value === values[currentValue?.id]));
    return result ? result[0]?.label : '';
  };

  const { _, ...restData } = recordDetails;
  return (
    Object.keys(recordDetails).length === 0 ? (
      <div style={{
        width: '100%',
        height: '90vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      >
        <CircularProgress />
      </div>
    )
      : (
        <>
          <EmbeddedSubnavbar
            label
            menuOptionType="simpleItems"
            // menuOptions={FilterSaved}
            handleEmbeddedClose={handleEmbeddedClose}
            exportTable={() => { console.log('sdfds'); }}
          />
          {/* <Collapse in={open}>
        <Alert
          severity="warning"
          action={(
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          )}
          sx={{ mb: 2 }}
        >
          <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
            <Typography style={{ fontWeight: 'bold', color: 'orange' }}>Warning!</Typography>
            {' '}
            You must change the key fields before saving!
          </div>
        </Alert>
      </Collapse> */}
          <CreateRecordHeader
            embedded
            Visible={Visible}
            options={activeScreen.redirect}
            handleCloseConfirm={handleCloseConfirm}
            dataHeader={_}
            // routeBack={routeBack}
            handleSelectChange={handleSelectChange}
            handleInputChange={handleInputChange}
            values={values}
            handleSave={handleSave}
            isModifiy={isModifiy}
            compare={compare}
            valuesToSend={valuesToSend}
            disabledFields={disabledFields}
          />
          <CreateRecordBody
            dataTabs={restData}
            handleSelectChange={handleSelectChange}
            handleInputChange={handleInputChange}
            values={values}
            compare={compare}
            valuesToSend={valuesToSend}
          />
          <Snackbar open={openConfirmationAlert} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error">
              {confirmationAlert}
            </Alert>
          </Snackbar>
        </>
    // )
      )
  );
};

export default CreateMaintEmbeddedScreen;
