/* eslint-disable max-len */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useState } from 'react';

// Librairies
import {
  InputBase,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';

// Components
import SubHeader from '../../../components/subHeader';
import TmsHeader from '../../../components/header';
import OptionsButton from '../../../components/optionsButton';
import DriversThroughFacilityTable from '../../../components/DriversThroughFacilityTable';
import DriversThroughFacilityOptions from '../../../components/DriversThroughFacilityOptions';
import { getDriversThroughFacilityData, getDriversThroughFacilityOptionModal } from '../../../redux/actions/workflows/DriversThroughFacility';

// Actions
import { getScreensList } from '../../../redux/actions/ScreenActions';

// Styles
import { useStyles } from './style';

// Utils
import { getRecordToShowValue, searchRecords } from '../../../utils';
import DriversThroughFacilityDialog from '../../../components/DriversThroughFacilityDialog';
import Api from '../../../redux/api';
import Loader from '../../../components/Loader';
import { useWorkflowState } from '../../../hooks/useWorkflowState';
import { getMarqueeManagerData } from '../../../redux/actions/workflows/marqueeManagerOptions';

const dtfStatusHead = {
  Drivers: 'Drivers',
  Personnel: 'Personnel',
  Vehicles: 'Vehicles',
  entrygate: 'Entry Gate',
  staged: 'Staged',
  loading: 'Loading',
};

export interface ITableState {
  tableData: any[];
  currentPage: number;
  searchedDataCount: number;
  searchText: string;
  recordsNumber: string;
  orderBy: string;
  order: string;
}

const searchHeader = [
  { label: 'Preferred', key: 'Preferred' },
  { label: 'Driver', key: 'Driver' },
  { label: 'Carrier', key: 'Carrier' },
  { label: 'Vehicles', key: 'Vehicles' },
  { label: 'Entry Date', key: 'Entry Date' },
  { label: 'Stage Date', key: 'Stage Date' },
  { label: 'Load Start Date', key: 'Load Start Date' },
  { label: 'Parking Added Time', key: 'Parking Added Time' },
  { label: 'Driver Status', key: 'Driver Status' },
  { label: 'Bay', key: 'Bay' },
  { label: 'Bay Status', key: 'Bay Status' },
];

const DriversThroughFacilityScreen: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [driversThroughFacilyDialogOpen, setDriversThroughFacilyDialogOpen] = useState(false);
  const [modalActions, setModalActions] = useState<any>([
    { value: 'force-entry', label: 'Force Entry' },
    { value: 'dispatch', label: 'Dispatch' },
    { value: 'dispatch-override', label: 'Dispatch Override' },
    { value: 'force-stage', label: 'Force Stage' },
    { value: 'change-park-time', label: 'Change Park Time' },
    { value: 'print-bol', label: 'Force Print BOL' },
    { value: 'force-exit', label: 'Force Exit' },
    { value: 'move-prefer', label: 'Move Prefer' },
    { value: 'move-not-prefer', label: 'Move not Prefer' },
    { value: 'suspend-alarm', label: 'Suspend Alarm' },
    { value: 'unsuspend-alarm', label: 'Unsuspend Alarm' },
  ]);
  const [modalShipping, setModalShipping] = useState<any>([
    { shippingMethod: 'B', value: 'Barge' },
    { shippingMethod: 'P', value: 'PipeLine' },
    { shippingMethod: 'T', value: 'Truck' },
    { shippingMethod: 'S', value: 'Ship' },
    { shippingMethod: 'R', value: 'Rail' },
  ]);
  const {
    dtfList, dtfStatusInfo, dtfbay, dtfdispatch, dtfroutecode,
  } = useSelector(
    (state: any) => state.Workflows.DriversThroughFacility,
  );
  const {
    tableState,
    handleSearchChange,
    handleSortTable,
    handleOptionClick,
    handleCloseMenu,
  } = useWorkflowState(searchHeader, dtfList, getDriversThroughFacilityData, true);
  useEffect(() => {
    dispatch(getScreensList());
    // dispatch(getDriversThroughFacilityData());
    dispatch(getDriversThroughFacilityOptionModal());
  }, []);

  const openDriversThroughFacilityOptionsDialog = () => {
    setDriversThroughFacilyDialogOpen(true);
  };

  const closeDriversThroughFacilityOptionsDialog = () => {
    setDriversThroughFacilyDialogOpen(false);
  };

  return (
    <>
      <TmsHeader />
      <SubHeader title="Drivers Through Facility">
        <div className="flexRow" style={{ gap: 5 }}>
          <Loader getWorkflowData={getDriversThroughFacilityData} />
          <OptionsButton handleClick={handleOptionClick} />
        </div>
        <DriversThroughFacilityOptions
          anchorEl={tableState.anchorEl}
          onClose={handleCloseMenu}
          openDriversThroughFacilityOptionsDialog={openDriversThroughFacilityOptionsDialog}
        />
      </SubHeader>
      <div className={classes.subHeaderContainer}>
        <DtsFstatusTable dtfStatusInfo={dtfStatusInfo} />
      </div>
      <div
        className={clsx('flexStartSpaceBetweenRow', classes.subHeaderContainer)}
      >
        <div>
          <ul className={classes.list}>
            <li className={classes.listItem}>
              <span className={clsx(classes.itemSign, classes.TareExceeded)} />
              Tare Exceeded
            </li>
            <li className={classes.listItem}>
              <span className={clsx(classes.itemSign, classes.VoucherFlag)} />
              Voucher Flag
            </li>
            <li className={classes.listItem}>
              <span className={clsx(classes.itemSign, classes.BlendOFS)} />
              Blend OFS
            </li>
            <li className={classes.listItem}>
              <span className={clsx(classes.itemSign, classes.EMCS)} />
              EMCS
            </li>
          </ul>
        </div>
        <div className="flexRow">
          <span className={classes.label}>Search :</span>
          <InputBase
            className={classes.searchInput}
            onChange={handleSearchChange}
            value={tableState.searchText}
          />
          <span className={classes.helperText}>Viewing 0 of 0</span>
        </div>
      </div>
      <span className={classes.spacer} />
      <DriversThroughFacilityTable
        handleClickRow={() => null}
        openDriversThroughFacilityDialog={() => null}
        tableData={tableState.tableData}
        tableHeader={searchHeader}
        order={tableState.order}
        orderBy={tableState.orderBy}
        searchText={tableState.searchText}
        sortTable={handleSortTable}
      />
      <DriversThroughFacilityDialog
        onClose={closeDriversThroughFacilityOptionsDialog}
        open={driversThroughFacilyDialogOpen}
        modalActions={modalActions}
        modalShipping={modalShipping}
        modalBay={dtfbay}
        modalDispatch={dtfdispatch}
        modalRouteCode={dtfroutecode}
      />
    </>
  );
};

interface IDtsFstatusTable {
  dtfStatusInfo: string[][];
}

const getFormattedDtfStatusInfo = (
  dtfStatusInfo: string[][],
): { [key: string]: string } =>
  dtfStatusInfo.reduce(
    (acc, curr) => ({
      ...acc,
      [curr[1]]: curr[2],
    }),
    {},
  );

const DtsFstatusTable: React.FC<IDtsFstatusTable> = ({ dtfStatusInfo }) => {
  const classes = useStyles();
  const formattedDtfStatusInfo = getFormattedDtfStatusInfo(dtfStatusInfo);

  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow className={classes.headerRow}>
          {Object.values(dtfStatusHead).map((value) => (
            <TableCell
              key={value}
              className={classes.headerCell}
              style={{ textAlign: 'center' }}
            >
              {value}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          {Object.values(dtfStatusHead).map((value) => (
            <TableCell
              key={value}
              className={classes.bodyCell}
              style={{ textAlign: 'center' }}
            >
              {formattedDtfStatusInfo[value] || '0'}
            </TableCell>
          ))}
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default DriversThroughFacilityScreen;
