/* eslint-disable operator-linebreak */
import { Dispatch } from 'redux';
import Api from '../../api/index';
import { LOAD_PERSONNEL_IN_FACILITY } from '../../constants/workflows';

export const getPersonnelInFacilityData = () => async (dispatch: Dispatch) => {
  const response = await Api.get('workflow031/data?screenID=workflow-031');
  dispatch({
    type: LOAD_PERSONNEL_IN_FACILITY,
    payload: response.data,
  });
};

export const deletePersonnelInFacilityItem =
  (id: string) => async (dispatch: Dispatch, getState: any) => {
    const { data } = getState().Workflows.PersonnelInFacility;
    await Api.post('synajax/procajax', {
      data: {
        id,
        cmd: 'personnelinfacility',
        termid: 'B344',
        parm: {
          cmd: 'personnelinfacility',
        },
      },
    });
    const filtredData = data.filter((elem: any) => elem.ID !== id);
    dispatch({
      type: LOAD_PERSONNEL_IN_FACILITY,
      payload: { data: filtredData },
    });
  };
