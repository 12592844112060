import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {},
  data: {
    fontFamily: 'Courier New',
    fontSize: '12px',
    unicodeBidi: 'embed',
    whiteSpace: 'pre',
    paddingBottom: '3px',
    lineHeight: 'unset',
  },
  error: {
    color: 'red',
    background: '#FFE0DB',
  },
  info: {
    color: '#002080',
    background: '#FFFFFF',
  },
  warning: {
    color: '#8C7500',
    background: '#FFFF99',
  },
  resultWrapper: {
    overflow: 'auto',
  },
});
