import React from 'react';

// Libraries
import {
  ButtonBase, Divider,
} from '@mui/material';

// Components
import JoditEditor from 'jodit-react';
import { DialogComponent } from '../Dialog/Dialog.component';

// Styles
import { useStyles } from './style';

interface IProps {
  onClose: () => void;
  open: boolean;
  setFormData: any;
  formData: any;
}

const TankStrappingTablesOptionsDialog: React.FC<IProps> = ({
  onClose,
  open,
  setFormData,
  formData,
}) => {
  const classes = useStyles();
  const handleOptionChange = (option: any, name: string) => {
    setFormData({ ...formData, [name]: option.value });
  };
  return (
    <DialogComponent
      title="Chart Comments"
      onClose={onClose}
      open={open}
    >
      <div className={classes.dialogBody}>
        <>
          {' '}
          <div style={{ display: 'block' }}>
            <JoditEditor
              value={formData?.comment}
              onChange={(newContent: any) => setFormData({ ...formData, comment: newContent })}
            />
          </div>
          <div className={classes.actionbtn}>
            <Divider variant="middle" />
            <ButtonBase
              classes={{ root: classes.applyButton }}
              style={{ marginRight: '5px' }}
            >
              Apply
            </ButtonBase>
          </div>
        </>
      </div>
    </DialogComponent>
  );
};

export default TankStrappingTablesOptionsDialog;
