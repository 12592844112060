import {
  LOAD_VIEW_TRACE_LOGS,
  SET_VIEW_TRACE_LOGS_SEARCH_DATA,
  CLEAR_VIEW_TRACE_LOGS_SEARCH_DATA,
  CLEAR_VIEW_TRACE_LOGS,
  SET_VIEW_TRACE_LOGS_LOADING,
  SET_VTL_RESPONSE_NOTIFICATION,
  CLEAR_VTL_RESPONSE_NOTIFICATION,
} from '../../constants/workflows';

const INITIAL_STATE = {
  data: [],
  searchResult: {},
  loading: false,
  customfilterlist: '',
  responseNotification: {},
};

const viewTraceLogsReducer = (state = INITIAL_STATE, { type, payload }: any) => {
  switch (type) {
    case LOAD_VIEW_TRACE_LOGS: {
      return {
        ...state,
        data: payload.data[0],
        customfilterlist: payload.customfilterlist,
      };
    }
    case SET_VIEW_TRACE_LOGS_LOADING: {
      return {
        ...state,
        loading: payload.loading,
      };
    }
    case CLEAR_VIEW_TRACE_LOGS: {
      return {
        ...state,
        data: [],
        customfilterlist: '',
      };
    }
    case SET_VTL_RESPONSE_NOTIFICATION: {
      return {
        ...state,
        responseNotification: payload,
      };
    }
    case CLEAR_VTL_RESPONSE_NOTIFICATION: {
      return {
        ...state,
        responseNotification: {},
      };
    }
    case SET_VIEW_TRACE_LOGS_SEARCH_DATA: {
      return {
        ...state,
        searchResult: {
          title: payload.title,
          data: payload.logsData,
        },
        loading: false,
      };
    }
    case CLEAR_VIEW_TRACE_LOGS_SEARCH_DATA: {
      return {
        ...state,
        searchResult: {},
      };
    }
    default:
      return state;
  }
};

export default viewTraceLogsReducer;
