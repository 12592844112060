export const LOAD_IO_MONITORS = 'LOAD_IO_MONITORS';
export const LOAD_TANK_GAUGE_ENTRY = 'LOAD_TANK_GAUGE_ENTRY';
export const LOAD_TANK_GAUGE_ENTRY_RECORD = 'LOAD_TANK_GAUGE_ENTRY_RECORD';
export const CLEAR_TANK_GAUGE_ENTRY_RECORD = 'CLEAR_TANK_GAUGE_ENTRY_RECORD';
export const SET_SELECTED_TANK_GAUGE_RECORD = 'SET_SELECTED_TANK_GAUGE_RECORD';
export const CLEAR_SELECTED_TANK_GAUGE_RECORD = 'CLEAR_SELECTED_TANK_GAUGE_RECORD';
export const SET_TANK_GAUGE_ENTRY_FORM_DATA = 'SET_TANK_GAUGE_ENTRY_FORM_DATA';
export const SET_GLOBAL_TANK_GAUGE_ENTRY_FORM_DATA = 'SET_GLOBAL_TANK_GAUGE_ENTRY_FORM_DATA';
export const SET_AUTO_TANK_GAUGE_DATA = 'SET_AUTO_TANK_GAUGE_DATA';
export const CLEAR_AUTO_TANK_GAUGE_DATA = 'CLEAR_AUTO_TANK_GAUGE_DATA';
export const CLEAR_TANK_GAUGE_ENTRY_FORM_DATA = 'CLEAR_TANK_GAUGE_ENTRY_FORM_DATA';
export const CLEAR_TANK_GAUGE_ENTRY_MODAL_DATA = 'CLEAR_TANK_GAUGE_ENTRY_MODAL_DATA';
export const SET_TANK_GAUGE_ENTRY_MODAL_DATA = 'SET_TANK_GAUGE_ENTRY_MODAL_DATA';
export const SET_TANK_GAUGE_ENTRY_FORM_HEADER = 'SET_TANK_GAUGE_ENTRY_FORM_HEADER';
export const SET_CURRENT_TANK_GAUGE_RECORD = 'SET_CURRENT_TANK_GAUGE_RECORD';
export const CLEAR_TANK_GAUGE_ENTRY_FORM_HEADER = 'CLEAR_TANK_GAUGE_ENTRY_FORM_HEADER';
export const SET_TANK_GAUGE_ENTRY_EXPORT_FILE = 'SET_TANK_GAUGE_ENTRY_EXPORT_FILE';
export const CLEAR_TANK_GAUGE_ENTRY_EXPORT_FILE = 'CLEAR_TANK_GAUGE_ENTRY_EXPORT_FILE';
export const SET_TANK_GAUGE_ENTRY_EXPORT_LINK = 'SET_TANK_GAUGE_ENTRY_EXPORT_LINK';
export const CLEAR_TANK_GAUGE_ENTRY_EXPORT_LINK = 'CLEAR_TANK_GAUGE_ENTRY_EXPORT_LINK';
export const CLEAR_TANK_GAUGE_RESPONSE_NOTIFICATION = 'CLEAR_METER_ENTRY_RESPONSE_NOTIFICATION';
export const SET_TANK_GAUGE_RESPONSE_NOTIFICATION = 'SET_TANK_GAUGE_RESPONSE_NOTIFICATION';
export const LOAD_SYSTEM_UTILITY_QUEUE = 'LOAD_SYSTEM_UTILITY_QUEUE';
export const LOAD_BUILT_IO_MONITORS = 'LOAD_BUILT_IO_MONITORS';
export const LOAD_FIELD_ATTRIBUTES_GROUP_SCREEN = 'LOAD_FIELD_ATTRIBUTES_GROUP_SCREEN';
export const LOAD_FIELD_ATTRIBUTES_TABLE = 'LOAD_FIELD_ATTRIBUTES_TABLE';
export const LOAD_FIELD_ATTRIBUTES_CONFIRM_TABLE = 'LOAD_FIELD_ATTRIBUTES_CONFIRM_TABLE';
export const LOAD_DEVICE_COMMUNICATIONS = 'LOAD_DEVICE_COMMUNICATIONS';
export const SUBMIT_DEVICE_COMMUNICATIONS = 'SUBMIT_DEVICE_COMMUNICATIONS';
export const CLEAR_DEVICE_COMMUNICATIONS = 'CLEAR_DEVICE_COMMUNICATIONS';
export const LOAD_TRAFFICS = 'LOAD_TRAFFICS';
export const CLEAR_TRAFFICS = 'CLEAR_TRAFFICS';
export const LOAD_MARQUEE_MANAGER = 'LOAD_MARQUEE_MANAGER';
export const CLEAR_MARQUEE_MANAGER = 'CLEAR_MARQUEE_MANAGER';
export const LOAD_DRIVERS_THROUGH_FACILITY = 'LOAD_DRIVERS_THROUGH_FACILITY';
export const GET_DRIVERS_DTF = 'GET_DRIVER_DTF';
export const GET_CARRIERS_DTF = 'GET_CARRIERS_DTF';
export const LOAD_DRIVERS_CONTRACT_DISPATCH = 'LOAD_DRIVERS_CONTRACT_DISPATCH';
export const LOAD_CARRIERS_CONTRACT_DISPATCH = 'LOAD_CARRIERS_CONTRACT_DISPATCH';
export const LOAD_SHIPPING_METHOD_CONTRACT_DISPATCH = 'LOAD_SHIPPING_METHOD_CONTRACT_DISPATCH';
export const LOAD_VEHICLE0_CONTRACT_DISPATCH = 'LOAD_VEHICLE0_CONTRACT_DISPATCH';
export const LOAD_VEHICLE1_CONTRACT_DISPATCH = 'LOAD_VEHICLE1_CONTRACT_DISPATCH';
export const LOAD_VEHICLE2_CONTRACT_DISPATCH = 'LOAD_VEHICLE2_CONTRACT_DISPATCH';
export const LOAD_STAGING_BAY_CONTRACT_DISPATCH = 'LOAD_STAGING_BAY_CONTRACT_DISPATCH';
export const LOAD_ROUTE_CODE_CONTRACT_DISPATCH = 'LOAD_ROUTE_CODE_CONTRACT_DISPATCH';
export const LOAD_PRODUCT_GROUP_CONTRACT_DISPATCH = 'LOAD_PRODUCT_GROUP_CONTRACT_DISPATCH';
export const LOAD_LOADING_STATUS_CONTRACT_DISPATCH = 'LOAD_LOADING_STATUS_CONTRACT_DISPATCH';
export const LOAD_DRIVERS_THROUGH_FACILITY_OPTION = 'LOAD_DRIVERS_THROUGH_FACILITY_OPTION';
export const CLEAR_CONTRACT_DISPATCH = 'CLEAR_CONTRACT_DISPATCH';
export const CLEAR_DRIVERS_THROUGH_FACILITY = 'CLEAR_DRIVERS_THROUGH_FACILITY';
export const CLEAR_IO_MONITORS = 'CLEAR_IO_MONITORS';
export const CLEAR_SYSTEM_UTILITY_QUEUE = 'CLEAR_SYSTEM_UTILITY_QUEUE';
export const CLEAR_TANK_GAUGE_ENTRY = 'CLEAR_TANK_GAUGE_ENTRY';
export const LOAD_VEHICLES_IN_FACILITY = 'LOAD_VEHICLES_IN_FACILITY';
export const CLEAR_VEHICLES_IN_FACILITY = 'CLEAR_VEHICLES_IN_FACILITY';
export const LOAD_METER_ENTRY = 'LOAD_METER_ENTRY';
export const SET_SELECTED_METER_RECORD = 'SET_SELECTED_METER_RECORD';
export const SET_METER_ENTRY_FORM_DATA = 'SET_METER_ENTRY_FORM_DATA';
export const CLEAR_METER_ENTRY_FORM_DATA = 'CLEAR_METER_ENTRY_FORM_DATA';
export const SET_METER_ENTRY_FORM_HEADER = 'SET_METER_ENTRY_FORM_HEADER';
export const SET_CURRENT_METER_RECORDS = 'SET_CURRENT_METER_RECORDS';
export const GLOBALLY_SET_METER_ENTRY_FORM_DATA = 'GLOBALLY_SET_METER_ENTRY_FORM_DATA';
export const CLEAR_METER_ENTRY_FORM_HEADER = 'CLEAR_METER_ENTRY_FORM_HEADER';
export const CLEAR_METER_ENTRY = 'CLEAR_METER_ENTRY';
export const SET_METER_ENTRY_EXPORT_FILE = 'SET_METER_ENTRY_EXPORT_FILE';
export const CLEAR_METER_ENTRY_EXPORT_FILE = 'CLEAR_METER_ENTRY_EXPORT_FILE';
export const SET_METER_ENTRY_EXPORT_LINK = 'SET_METER_ENTRY_EXPORT_LINK';
export const SET_METER_ENTRY_TOTALIZE_DATA = 'SET_METER_ENTRY_TOTALIZE_DATA';
export const CLEAR_METER_ENTRY_TOTALIZE_DATA = 'CLEAR_METER_ENTRY_TOTALIZE_DATA';
export const CLEAR_METER_ENTRY_EXPORT_LINK = 'CLEAR_METER_ENTRY_EXPORT_LINK';
export const SET_TRANSACTION_VIEWER_FILTERS = 'SET_TRANSACTION_VIEWER_FILTERS';
export const CLEAR_TRANSACTION_VIEWER_FILTERS = 'CLEAR_TRANSACTION_VIEWER_FILTERS';
export const SET_TRANSACTION_VIEWER_EXPORT_FILE = 'SET_TRANSACTION_VIEWER_EXPORT_FILE';
export const CLEAR_TRANSACTION_VIEWER_EXPORT_FILE = 'CLEAR_TRANSACTION_VIEWER_EXPORT_FILE';
export const SET_TRANSACTION_VIEWER_RECORD_DETAIL = 'SET_TRANSACTION_VIEWER_RECORD_DETAIL';
export const CLEAR_TRANSACTION_VIEWER_RECORD_DETAIL = 'CLEAR_TRANSACTION_VIEWER_RECORD_DETAIL';
export const SET_TRANSACTION_VIEWER_EXPORT_LINK = 'SET_TRANSACTION_VIEWER_EXPORT_LINK';
export const CLEAR_TRANSACTION_VIEWER_EXPORT_LINK = 'CLEAR_TRANSACTION_VIEWER_EXPORT_LINK';
export const LOAD_METER_ENTRY_RECORD = 'LOAD_METER_ENTRY_RECORD';
export const CLEAR_METER_ENTRY_RECORD = 'CLEAR_METER_ENTRY_RECORD';
export const SET_METER_ENTRY_RESPONSE_NOTIFICATION = 'SET_METER_ENTRY_RESPONSE_NOTIFICATION';
export const CLEAR_METER_ENTRY_RESPONSE_NOTIFICATION = 'CLEAR_METER_ENTRY_RESPONSE_NOTIFICATION';
export const LOAD_TANK_STATUS = 'LOAD_TANK_STATUS';
export const LOAD_DRIVERS_IN_FACILITY = 'LOAD_DRIVERS_IN_FACILITY';
export const LOAD_USERS_DATA = 'LOAD_USERS_DATA';
export const CLEAR_USERS_DATA = 'CLEAR_USERS_DATA';
export const CLEAR_DRIVERS_IN_FACILITY = 'CLEAR_DRIVERS_IN_FACILITY';
export const LOAD_PERSONNEL_IN_FACILITY = 'LOAD_PERSONNEL_IN_FACILITY';
export const CLEAR_PERSONNEL_IN_FACILITY = 'CLEAR_PERSONNEL_IN_FACILITY';
export const UPDATE_EMCS_CONFIGURATION = 'UPDATE_EMCS_CONFIGURATION';
export const LOAD_EMCS_CONFIGURATION = 'LOAD_EMCS_CONFIGURATION';
export const DISSMISS_EMCS_CONFIGURATION_ALERT = 'DISSMISS_EMCS_CONFIGURATION_ALERT';
export const LOAD_FOLIO_FREEZE_CLOSE = 'LOAD_FOLIO_FREEZE_CLOSE';
export const CLEAR_FOLIO_FREEZE_CLOSE = 'CLEAR_FOLIO_FREEZE_CLOSE';
export const LOAD_CONDITION_SET = 'LOAD_CONDITION_SET';
export const LOAD_TRANSACTION_VIEWER = 'LOAD_TRANSACTION_VIEWER';
export const LOAD_TRANSACTION_VIEWER_COLUMNS = 'LOAD_TRANSACTION_VIEWER_COLUMNS';
export const LOAD_ALL_TRANSACTION_VIEWER_DATA = 'LOAD_ALL_TRANSACTION_VIEWER_DATA';
export const SET_TRANSACTION_VIEWER_LOADING = 'SET_TRANSACTION_VIEWER_LOADING';
export const CLEAR_TRANSACTION_VIEWER = 'CLEAR_TRANSACTION_VIEWER';
export const LOAD_CONDITION_SET_RECORD = 'LOAD_CONDITION_SET_RECORD';
export const CLEAR_CONDITION_SET_RECORD = 'CLEAR_CONDITION_SET_RECORD';
export const CLEAR_CONDITION_SET = 'CLEAR_CONDITION_SET';
export const SET_CONDITION_SET_FORM_HEADER = 'SET_CONDITION_SET_FORM_HEADER';
export const SET_CONDITION_SET_RESPONSE_NOTIFICATION = 'SET_CONDITION_SET_RESPONSE_NOTIFICATION';
export const CLEAR_CONDITION_SET_RESPONSE_NOTIFICATION = 'CLEAR_CONDITION_SET_RESPONSE_NOTIFICATION';
export const CLEAR_CONDITION_SET_FORM_HEADER = 'CLEAR_CONDITION_SET_FORM_HEADER';
export const LOAD_EMCS_MANAGER = 'LOAD_EMCS_MANAGER';
export const CLEAR_EMCS_MANAGER = 'CLEAR_EMCS_MANAGER';
export const LOAD_PRODUCT_GROUP_CROSS_REFERENCE = 'LOAD_PRODUCT_GROUP_CROSS_REFERENCE';
export const CLEAR_PRODUCT_GROUP_CROSS_REFERENCE = 'CLEAR_PRODUCT_GROUP_CROSS_REFERENCE';
export const SET_PGCR_EXPORT_FILE = 'SET_PGCR_EXPORT_FILE';
export const CLEAR_PGCR_EXPORT_FILE = 'CLEAR_PGCR_EXPORT_FILE';
export const SET_PGCR_EXPORT_LINK = 'SET_PGCR_EXPORT_LINK';
export const SET_PGCR_FORM_HEADER = 'SET_PGCR_FORM_HEADER';
export const SET_PGCR_RESPONSE_NOTIFICATION = 'SET_PGCR_RESPONSE_NOTIFICATION';
export const CLEAR_PGCR_RESPONSE_NOTIFICATION = 'CLEAR_PGCR_RESPONSE_NOTIFICATION';
export const CLEAR_PGCR_FORM_HEADER = 'CLEAR_PGCR_FORM_HEADER';
export const CLEAR_PGCR_EXPORT_LINK = 'CLEAR_PGCR_EXPORT_LINK';
export const LOAD_PGCR_RECORD = 'LOAD_PGCR_RECORD';
export const CLEAR_PGCR_RECORD = 'CLEAR_PGCR_RECORD';
export const SET_EMCS_MANAGER_EXPORT_FILE = 'SET_EMCS_MANAGER_EXPORT_FILE';
export const CLEAR_EMCS_MANAGER_EXPORT_FILE = 'CLEAR_EMCS_MANAGER_EXPORT_FILE';
export const SET_EMCS_MANAGER_EXPORT_LINK = 'SET_EMCS_MANAGER_EXPORT_LINK';
export const CLEAR_EMCS_MANAGER_EXPORT_LINK = 'CLEAR_EMCS_MANAGER_EXPORT_LINK';
export const LOAD_VIEW_TRACE_LOGS = 'LOAD_VIEW_TRACE_LOGS';
export const SET_VIEW_TRACE_LOGS_LOADING = 'SET_VIEW_TRACE_LOGS_LOADING';
export const SET_VIEW_TRACE_LOGS_SEARCH_DATA = 'SET_VIEW_TRACE_LOGS_SEARCH_DATA';
export const CLEAR_VIEW_TRACE_LOGS_SEARCH_DATA = 'CLEAR_VIEW_TRACE_LOGS_SEARCH_DATA';
export const SET_VTL_RESPONSE_NOTIFICATION = 'SET_VTL_RESPONSE_NOTIFICATION';
export const CLEAR_VTL_RESPONSE_NOTIFICATION = 'CLEAR_VTL_RESPONSE_NOTIFICATION';
export const CLEAR_VIEW_TRACE_LOGS = 'CLEAR_VIEW_TRACE_LOGS';
export const LOAD_DRIVERS_IN_FACILITY_DRIVERS = 'LOAD_DRIVERS_IN_FACILITY_DRIVERS';
export const LOAD_DRIVERS_IN_FACILITY_CARRIERS = 'LOAD_DRIVERS_IN_FACILITY_CARRIERS';
export const LOAD_REPORT_QUEUE = 'LOAD_REPORT_QUEUE';
export const LOAD_REPORT_FIELDS = 'LOAD_REPORT_FIELDS';
export const GET_REPORT_CUSTOMERS = 'GET_REPORT_CUSTOMERS';
export const GET_REPORT_FOLIOMONTH = 'GET_REPORT_FOLIOMONTH';
export const GET_REPORT_FOLIONUMBER = 'GET_REPORT_FOLIONUMBER';
export const LOAD_VIEW_REPORT = 'LOAD_VIEW_REPORT';
export const GENERATE_REPORT_RESPONSE = 'GENERATE_REPORT_RESPONSE';
export const LOAD_REPORT_SCREEN = 'LOAD_REPORT_SCREEN';
export const LOAD_CUSTOM_REPORT_CONFIGURATION_TABLE_DATA = 'LOAD_CUSTOM_REPORT_CONFIGURATION';
export const SET_DEFAULT_TILE_LAYOUT = 'SET_DEFAULT_TILE_LAYOUT';
export const GET_DEFAULT_TILE_LAYOUT = 'GET_DEFAULT_TILE_LAYOUT';
export const LOAD_SYSTEM_PRINTERS = 'LOAD_SYSTEM_PRINTERS';
export const LOAD_VIEW_REPORT_COMMENT = 'LOAD_VIEW_REPORT_COMMENT';
export const VIEW_REPORT_SAVE_SEQ_NO = 'VIEW_REPORT_SAVE_SEQ_NO';
export const VIEW_REPORT_SAVE_REPORT_DESCRIPTION = 'VIEW_REPORT_SAVE_REPORT_DESCRIPTION';
export const GET_REPORT_PRODUCTS = 'GET_REPORT_PRODUCTS';
export const SET_FORM_EDIT_STATE = 'SET_FORM_EDIT_STATE';
export const LOAD_TANK_STRAPPING = 'LOAD_TANK_STRAPPING';
export const LOAD_TANK_STRAPPING_DETAILS = 'LOAD_TANK_STRAPPING_DETAILS';
export const LOAD_EMAIL_GROUP = 'LOAD_EMAIL_GROUP';
export const LOAD_SYSTEM_UTILITY_FIELDS = 'LOAD_SYSTEM_UTILITY_FIELDS';
export const LOAD_UTILITY_SCREEN = 'LOAD_UTILITY_SCREEN';
export const GET_UTILITY_CUSTOMERS = 'GET_UTILITY_CUSTOMERS';
export const GET_UTILITY_FOLIOMONTH = 'GET_UTILITY_FOLIOMONTH';
export const GET_UTILITY_FOLIONUMBER = 'GET_UTILITY_FOLIONUMBER';
export const GET_UTILITY_PRODUCTS = 'GET_UTILITY_PRODUCTS';
export const GENERATE_UTILITY_RESPONSE = 'GENERATE_UTILITY_RESPONSE';
export const SET_UTILITY_FORM_EDIT_STATE = 'SET_UTILITY_FORM_EDIT_STATE';
export const VIEW_UTILITY_SAVE_SEQ_NO = 'VIEW_UTILITY_SAVE_SEQ_NO';
export const LOAD_RACK_STATUS_TABLE = 'LOAD_RACK_STATUS_TABLE';
export const LOAD_RACK_STATUS_BAY_RESPONSE = 'LOAD_RACK_STATUS_BAY_RESPONSE';
export const LOAD_PRESET_CARDS_DATA = 'LOAD_PRESET_CARDS_DATA';
export const PROCESS_RACK_STATUS = 'PROCESS_RACK_STATUS';
export const SET_BAY_ID_FOR_DETAIL = 'SET_BAY_ID_FOR_DETAIL';
export const UPDATE_RCU_SCREEN = 'UPDATE_RCU_SCREEN';
// Add authorized product workflow
export const LOAD_PRODUCTS_ADD_AUTH_PRODS = 'LOAD_PRODUCTS_ADD_AUTH_PRODS';
export const SAVE_PRODUCTS_ADD_AUTH_PRODS = 'SAVE_PRODUCTS_ADD_AUTH_PRODS';
export const LOAD_ORDER_MAINTENANCE = 'LOAD_ORDER_MAINTENANCE';
export const SET_SINGLE_ORDER_MAINTENANCE = 'SET_SINGLE_ORDER_MAINTENANCE';
export const GET_SINGLE_ORDER_MAINTENANCE = 'GET_SINGLE_ORDER_MAINTENANCE';
export const DISSMISS_CONFIRMATION_ALERT = 'DISSMISS_CONFIRMATION_ALERT';
export const SET_MESSAGE = 'SET_MESSAGE';
export const SET_ORDER_MAINTENANCE_FILTER = 'SET_ORDER_MAINTENANCE_FILTER';
export const SET_SELECTED_ORDER_MAINTENANCE_BATCH_ROWS = 'SET_SELECTED_ORDER_MAINTENANCE_BATCH_ROWS';
export const SET_ORDER_MAINTENANCE_BATCH_ALERT = 'SET_ORDER_MAINTENANCE_BATCH_ALERT';
export const CLEAR_ORDER_MAINTENANCE = 'CLEAR_ORDER_MAINTENANCE';
export const LOAD_ORDER_MAINTENANCE_CHAINED_LIST = 'LOAD_ORDER_MAINTENANCE_CHAINED_LIST';
export const SET_ORDER_MAINTENANCE_RESPONSE_MSG = 'SET_ORDER_MAINTENANCE_RESPONSE_MSG';
// Contract transaction workflow
export const SET_ACTIVE_STEP = 'SET_ACTIVE_STEP';
export const LOAD_USER_SESSION = 'LOAD_USER_SESSION';
export const CLEAR_USER_SESSION_NOTIFICATION = 'CLEAR_USER_SESSION_NOTIFICATION';
export const LOAD_DOCUMENT_BUILDER_TABLE = 'LOAD_DOCUMENT_BUILDER_TABLE';
export const LOAD_DOCUMENT_BUILDER_DETAILS = 'LOAD_DOCUMENT_BUILDER_DETAILS';
export const EXPORT_DOCUMENT_BUILDER_DETAILS_FILE = 'EXPORT_DOCUMENT_BUILDER_DETAILS_FILE';
export const CLEAR_DOCUMENT_BUILDER_DETAILS_FILE = 'CLEAR_DOCUMENT_BUILDER_DETAILS_FILE';
export const GET_ABOUT_PAGE_DATA = 'GET_ABOUT_PAGE_DATA';
export const GET_INIT_DATA_RCU_DITTO = 'GET_INIT_DATA_RCU_DITTO';
