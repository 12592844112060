/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import {
  Alert,
  CircularProgress,
  LinearProgress,
  Snackbar,
  Typography,
  Button,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  Link, useNavigate, useParams, useLocation,
} from 'react-router-dom';
import Subnavbar from '../../components/Sub-navbar/Subnavbar';
import EnhancedTable from '../../components/TableView/TableView';
import response from '../../dummyData/response.json';
import {
  dissmissConfirmationAlert,
  exporTableToExcelCsvPdf,
  LoadAllColumnsTables,
  LoadColumns,
  LoadRecord,
  LoadTables,
  LoadTabs,
  SetRecordsList,
} from '../../redux/actions/ScreenActions';
import TmsHeader from '../../components/header';
import EmptyTable from '../../components/emptyTable';
import useWindowDimensions from '../../utils/windowDimensions';

interface MaintParams {
  tableId: string | undefined;
  screenId: string | undefined;
  fields: string | undefined;
  maintDetails: string | undefined;
  BatchModeScreen: string | undefined;
  backScreen: string | undefined;
  page: string | undefined;
  results_per_page: string | undefined;
}
const MaintTableScreen = (props: any) => {
  const { screenId } = useParams();
  const { height, width } = useWindowDimensions();
  const { reducer } = props;
  const {
    loading,
    data,
    recordDetails,
    exportLoading,
    FilterSaved,
    confirmationAlert,
    openConfirmationAlert,
    reviewWhereCondition,
    activeScreen,
    activeScreenLabels,
    allLabels,
    config,
  } = useSelector((state: any) => state?.[reducer || 'ScreenReducer']);

  const { Language } = useSelector((state: any) => state.LoginReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const createRecordReview:any = location.state;

  useEffect(() => {
    if (!activeScreen) {
      navigate('/home');
    } else if (activeScreen) {
      const filterWhereQuery = FilterSaved?.[activeScreen.screenId]?.filters
        ?.map((item: any) => (item.condition === 'like' || item.value === 'not like'
          ? { ...item, value: `%25${item.value}%25` }
          : item))
        .map(
          (item: any) => `${item.field} ${item.condition} '${item.value}'`,
        )
        .join(FilterSaved?.[activeScreen.screenId]?.match || ' AND ');
      dispatch(
        LoadTables({
          tableID: activeScreen.tableID,
          page: '1',
          results_per_page: activeScreen.results_per_page,
          fields: activeScreen.fields,
          order_by: '',
          where: filterWhereQuery || activeScreen.where,
          EmptyRows: activeScreen.EmptyRows,
          redirect: activeScreen.redirect,
          search: activeScreen.search || '',
          screenId: activeScreen.screenId,
          whereRecord: activeScreen.whereRecord,
          keys: activeScreen.keys,
          defaultcolumns: activeScreen.defaultcolumns,
          lang: Language,
          fieldsConcatenated: activeScreen.fieldsConcatenated,
          labels: activeScreen.labels || '',
        }),
      );
      dispatch(LoadTabs(activeScreen.screenId));
      // dispatch(LoadColumns());
      dispatch(
        LoadAllColumnsTables({
          tableID: activeScreen.tableID,
          page: '1',
          results_per_page: '10',
          fields: '',
          where: '',
          order_by: '',
          Selectedcolumns: activeScreen.fields,
        }),
      );

      dispatch(
        LoadRecord({
          tableID: activeScreen.tableID,
          screenID: activeScreen.screenId,
          fields: activeScreen.fields,
          where: '',
          lang: Language,
        }),
      );
    }
  }, []);
  // const tableOptionsprop = {
  //   tableID: activeScreen.tableID,
  //   page: '1',
  //   results_per_page: '10',
  //   fields: activeScreen.fields,
  //   order_by: '',
  //   where: '',
  // };
  const handleClose = () => {
    dispatch(dissmissConfirmationAlert());
  };
  const recordsList = data?.data?.map((row:any, key:any) => (
    {
      key,
      tableID: activeScreen.tableID,
      screenId,
      lang: Language,
      where: activeScreen?.keys ? JSON.stringify(activeScreen?.keys.split(',').reduce((acc:any, item:any) => ({ ...acc, [item]: row[item]?.value }), {})) : null,
      modifyDep: activeScreen?.keys ? activeScreen?.keys.split(',').reduce((acc:any, item:any) => ({ ...acc, [item]: row[item]?.value }), {}) : null,
    }
  ));
  const filterQuery = FilterSaved?.[activeScreen?.screenId]?.filters
    ?.map((item: any) => (item.condition === 'like' || item.value === 'not like'
      ? { ...item, value: `%25${item.value}%25` }
      : item))
    .map(
      (item: any) => `${allLabels[item.field]} ${item.condition} '${item.value}'`,
    )
    .join(FilterSaved?.[activeScreen?.screenId]?.match || ' ').replace(/ like /g, ' contains ')
    .replace(/ not like /g, ' not contains ')
    .replace(/%25/g, '');
  return loading || Object.keys(recordDetails).length === 0 ? (
    <div
      style={{
        width: '100%',
        height: '90vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress />
    </div>
  ) : (
    <>
      <TmsHeader />
      <Subnavbar
        label
        menuOptionType="exportItems"
        menuOptions={filterQuery}
        exportTable={dispatch(exporTableToExcelCsvPdf)}
      />
      {exportLoading && <LinearProgress />}
      <div className="takeTheRest">
        <EnhancedTable
          pages={data.totalItems}
          headCells={activeScreenLabels}
          rows={data.data}
          tableOpt={activeScreen}
          routeDetails="/maint-details"
          screenID={screenId}
          backScreen={`/maint/${screenId}/`}
          BatchModeScreen="/batch-mode"
        />
      </div>
      <Snackbar
        open={openConfirmationAlert}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success">
          {confirmationAlert.includes('Review') ? (
            <>
              {confirmationAlert.replace('Review', '').split(':')[0]}
              .
              <Button
                size="small"
                variant="text"
                sx={{ textTransform: 'capitalize' }}
                onClick={() => {
                  handleClose();
                  try {
                    if (confirmationAlert.includes('Record created successfully') && confirmationAlert.split(':')[1]) {
                      dispatch(SetRecordsList(recordsList));
                      navigate('/maint-details', {
                        state: {
                          tableID: activeScreen.tableID,
                          screenID: screenId,
                          where: reviewWhereCondition,
                          routeDetails: `/maint/${screenId}/`,
                          modifyDep: reviewWhereCondition,
                          recordsList,
                        },
                      });
                    } else {
                      navigate(-1);
                    }
                  } catch (e:any) {
                    // eslint-disable-next-line no-unused-expressions
                    console.log(e.message);
                  }
                }}
              >
                {' ( Review )'}
              </Button>
            </>
          ) : confirmationAlert}
        </Alert>
      </Snackbar>
    </>
  );
};

export default MaintTableScreen;
