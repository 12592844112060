/* eslint-disable react/require-default-props */
import React from 'react';

// Librairies
import { Grid, InputBase, Typography } from '@mui/material';

// Styles
import clsx from 'clsx';
import { useStyles } from './style';
import RackStatusCard from '../RackStatusCard';
import RackStatus from '../../../screens/Workflows/rackStatus/rackStatus';
import { ResponseAlert } from '../ResponseAlert';

interface IProps {
    rackStatus: any;
}

const RackStatusBody: React.FC<IProps> = ({
  rackStatus,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        {rackStatus.map((rack: any) => (
          <RackStatusCard
            bayNumberDescription={rack?.bayNumberDescription}
            bayNumber={rack?.bayNumber}
            bayOpt={rack?.bayOpt}
            linkCustomer={rack?.linkCustomer}
            linkDriver={rack?.linkDriver}
            linkSupplier={rack?.linkSupplier}
            linkCarrier={rack?.linkCarrier}
            bayStatusStyle={rack?.bayStatusStyle}
            bayStatus={rack?.bayStatus}
            presetlist={rack?.presetlist}
            key={rack?.bayID}
            bayID={rack?.bayID}
          />
        ))}
      </Grid>
      <ResponseAlert />
    </div>
  );
};

export default RackStatusBody;
