/* eslint-disable max-len */
/* eslint-disable operator-linebreak */
import { Dispatch } from 'redux';
import Api from '../../api/index';
import {
  LOAD_FIELD_ATTRIBUTES_GROUP_SCREEN,
  LOAD_FIELD_ATTRIBUTES_TABLE,
} from '../../constants/workflows';

export const getFieldAttributesGroupScreen =
  () => async (dispatch: Dispatch) => {
    const userID = localStorage.getItem('username');
    const response = await Api.get(
      `workflow990/showsteps?userID=${userID}&wizardstep=S1&lang=en-US`,
    );
    dispatch({
      type: LOAD_FIELD_ATTRIBUTES_GROUP_SCREEN,
      payload: response.data.showstep,
    });
  };

export const getChosenFieldAttributes =
  (screenID: string, groupID: string) => async (dispatch: Dispatch) => {
    const userID = localStorage.getItem('username');
    const response = await Api.get(
      `workflow990/showsteps?screenID=${screenID}&groupID=${groupID}&userID=${userID}&wizardstep=S2&lang=en-US`,
    );
    const data = Object.entries(response.data.showstep || {}).map(
      ([id, columns]: any) => ({ id, columns }),
    );
    dispatch({
      type: LOAD_FIELD_ATTRIBUTES_TABLE,
      payload: data,
    });
  };
