/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Autocomplete,
  Button,
  Checkbox,
  Divider,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Close } from '@mui/icons-material';
import { useStyles } from './style';
import CustomInput from '../customInput';

function pad(n: any) {
  return n < 10 ? `0${n}` : n;
}
function splitdash(str: any) {
  let output = str;
  let aux = [];
  if (str?.search('-') !== -1) {
    aux = str?.split('-');
    output = aux?.[aux?.length - 1];
  }
  return { name: output, prefix: str?.split('-')[0] };
}

const setOptionFormat = (stepData: any) => {
  const options: any = stepData?.['\u0000*\u0000parm']?.options.length
    || stepData?.['\u0000*\u0000parm']?.options.length
    || Object.entries(stepData?.[0]?.list).length
    ? stepData?.['\u0000*\u0000parm']?.options.length
      ? stepData?.['\u0000*\u0000parm']?.options
      : Object.entries(stepData?.[0]?.list)?.map((val: any) => ({
        value: val[0],
        label: val[1],
      }))
    : [{ value: '', label: 'No Data Available' }];
  return options;
};

const ProductRecordComponent = ({
  productsData,
  values,
  handleInputChange,
  handleSelectChange,
  valuesToSend,
  stateValues,
  handleInputProductChange,
  screenID,
  visible,
  prefixProducts,
  resetPrefixProducts,
  prefix,
  product,
  removeFormFields,
  itemKey,
  products,
  review,
  transactionMode,
}: any) => {
  const classes = useStyles();
  const [productsList, setProductsList] = useState<any>(products);
  const [subProducts, setSubProducts] = useState<any>([]);
  const selectProducts = async () => {
    try {
      const response: any = await axios.post(
        `${process.env.REACT_APP_API_URL}synajax/procajax`,
        {
          queueid: 'TP',
          data: {
            cmd: 'transaleprod',
            termid: values?.term_id?.value || valuesToSend?.term_id || 'B344',
            carrier: values?.carrier?.value || valuesToSend?.carrier || '',
            supplier:
              values?.supplier_no?.value || valuesToSend?.supplier_no || '',
            customer: values?.cust_no?.value || valuesToSend?.supplier_no || '',
            account: values?.acct_no?.value || valuesToSend?.acct_no || '',
            destination: values?.destination?.value || '',
            trans_id: values?.trans_id?.value || '',
            uom: 'B',
            baseUOMList: 'B:G,C:L,G:G,I:L,K:K,L:L,MT:K,P:P,T:P',
            contractmode: values?.contractmode?.value || '',
            contractid:
              values?.contractmode?.value || valuesToSend?.contractid || '',
            prefix: prefix || '',
            newdetail: values?.newdetail?.value || 'Y',
            prodfieldlist: prefixProducts || null,
            vehlist: [],
            tankgroup:
              values?.tankgroup?.value || valuesToSend?.tankgroup?.value || 'y',
            mtmode: transactionMode,
            bayid:
              values?.[itemKey]?.ld_bay
              || valuesToSend?.[itemKey]?.ld_bay
              || '',
            prodid:
              values?.[itemKey]?.prod_id
              || valuesToSend?.[itemKey]?.prod_id
              || '',
            weightTransaction: false,
            groupID: 'Admin',
            screenID,
          },
          tagid: null,
          jqxhr: null,
        },
      );
      const formatedValues: any = {};
      const formatedSubProdValues: any = {};
      setProductsList(Object.values(response?.data?.fieldRecList));
      setSubProducts(response.data?.ProductComponentDetail?.componentList);
      Object.keys(response.data.fieldRecList).forEach((item: any) => {
        formatedValues[
          response.data.fieldRecList[item].fieldParam?.id
            || response.data.fieldRecList[item]?.['\u0000*\u0000nameID']
        ] = response.data.fieldRecList[item].fieldParam?.value || '';
      });
      Object.keys(
        response.data.ProductComponentDetail?.componentList[0],
      ).forEach((item) => {
        formatedSubProdValues[
          response.data.ProductComponentDetail?.componentList[0]?.[item]
            .fieldParam?.id
            || response.data.ProductComponentDetail?.componentList[0]?.[
              '\u0000*\u0000nameID'
            ]
        ] = response.data.ProductComponentDetail?.componentList[0]?.[item]
          .fieldParam?.value || '';
      });
      resetPrefixProducts(
        { ...formatedValues, ...formatedSubProdValues },
        itemKey,
      );

      // setSubProducts(response.data.);
    } catch (e: any) {
      console.log('error', e);
    }
  };
  useEffect(() => {
    selectProducts();
  }, [values?.[itemKey]?.prod_id]);
  return (
    <div className={classes.container}>
      <div className={classes.tableContainer}>
        <Typography fontFamily="inherit" color="#6172A7">
          Products
        </Typography>
        <Close onClick={() => removeFormFields(itemKey)} />
      </div>
      <Divider sx={{ color: 'blue' }} />
      <div className={classes.innerContainer}>
        {productsList?.prod_id?.length === 0 && (
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value=""
            // label={stepData?.fieldID}
            // onChange={(e: any) => inputHandler({ key, name: stepData?.id, value: e?.target?.value })}
            size="small"
            sx={{
              width: '20%',
              borderRadius: '0px',
              marginTop: '5px',
              '& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select':
                {
                  padding: '2px 8px',
                  border: '1px solid #E4E4E4',
                },
            }}
          >
            <MenuItem key={uuidv4()} value="" selected>
              No Data Available
            </MenuItem>
          </Select>
        )}
        <div className={classes.subContainer}>
          {productsList?.prod_id?.length !== 0
            && productsList?.map((stepData: any) => (!visible
              && stepData?.fieldParam?.visible !== true
              && ![
                'aftx',
                'tlist',
                'country',
                'state',
                'dualsel01',
                'routecode',
                'dualselYN',
                'aftx:inven_units',
                'chain',
                'static',
                'emailgroup',
                'faxgroup',
                'dualselGN',
                'transval',
                'termid',
                'product',
                'language',
                'carrier',
                'accessprofile',
                'dualselAI',
                'shippingmethodid',
                'productid',
                'preset',
                'bay',
                'tank',
                'scadproductchain',
                'vehicle',
                'baychain',
                'compartment',
                'presetchain',
                'productid',
                '',
              ].includes(stepData?.fieldParam?.type || stepData?.[0]?.type) ? (
                <></>
              ) : [
                'aftx',
                'tlist',
                'country',
                'state',
                'dualsel01',
                'routecode',
                'dualselYN',
                'aftx:inven_units',
                'chain',
                'static',
                'emailgroup',
                'faxgroup',
                'dualselGN',
                'transval',
                'termid',
                'product',
                'language',
                'carrier',
                'accessprofile',
                'dualselAI',
                'shippingmethodid',
                'productid',
                'preset',
                'bay',
                'tank',
                'scadproductchain',
                'vehicle',
                'baychain',
                'compartment',
                'presetchain',
                'productid',
                '',
              ].includes(
                stepData?.fieldParam?.type || stepData?.[0]?.type,
              ) ? (
                <Grid item xs={2} sm={4} md={4} className={classes.product}>
                  <Typography
                    fontSize="12px"
                    fontFamily="inherit"
                    sx={{
                      borderBottom: '1px solid #6172A7',
                      padding: '0px 5px',
                      whiteSpace: 'nowrap',
                      color: '#003399',
                    }}
                  >
                    {stepData?.fieldParam?.col === 'compid'
                      ? 'Compartment'
                      : stepData?.fieldParam?.col}
                    {stepData?.fieldParam?.spantext}
                  </Typography>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={
                      valuesToSend?.[itemKey]?.[stepData?.[0]?.valueid] || ''
                    }
                    name={stepData?.[0]?.valueid}
                    disabled={review}
                    // label={stepData?.fieldID}
                    onChange={(e: any) => handleInputProductChange(stepData[0]?.valueid, e, itemKey)}
                    size="small"
                    sx={{
                      // product id field is wider than the other fields so we need to specify the condition for width
                      minWidth:
                        stepData?.[0]?.valueid === 'prod_id'
                          ? '300px'
                          : '100%',
                      margin: '0px 5px',
                      borderRadius: '0px',
                      marginTop: '10px',
                      '& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select':
                        {
                          padding: '0px 8px',
                          border: '1px solid #E4E4E4',
                        },
                    }}
                  >
                    {setOptionFormat(stepData)?.map((item: any) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label.replaceAll('&nbsp;', '')}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                ) : (
                  <Grid item xs={2} sm={4} md={4} className={classes.product}>
                    <Typography
                      fontFamily="inherit"
                      fontSize="12px"
                      sx={{
                        borderBottom: '1px solid black',
                        padding: '0px 5px',
                        whiteSpace: 'nowrap',
                        color: '#003399',
                      }}
                    >
                      {stepData?.fieldParam?.col}
                      {stepData?.fieldParam?.spantext}
                    </Typography>
                    <div style={{ padding: '0px 0px' }}>
                      <CustomInput
                        type={stepData.type}
                        label=""
                        val={stepData}
                        required={stepData?.fieldParam?.attr?.includes(
                          'required',
                        )}
                        name={splitdash(stepData?.['\u0000*\u0000nameID'])?.name}
                        defaultValue={stepData?.fieldParam?.value}
                        attr={stepData?.fieldParam?.attr}
                        value={
                        prefixProducts?.[itemKey]?.[
                          stepData?.['\u0000*\u0000nameID']
                        ]
                      }
                        isBatch={false}
                        onChange={handleInputChange}
                        size="100%"
                        placeholder={stepData?.fieldParam?.helpertext}
                        isDisabled={
                        stepData?.[0]?.fieldParam?.includes('readonly')
                        || review
                      }
                        validate={stepData?.[0]?.validate}
                        customKey={itemKey}
                        transaction
                        prefix={
                        splitdash(stepData?.['\u0000*\u0000nameID'])?.prefix
                      }
                        funNamePrefix={stepData?.['\u0000*\u0000nameID']}
                      />
                    </div>
                  </Grid>
                )))}
        </div>
      </div>
      <Divider sx={{ color: 'blue' }} />
      <div className={classes.subContainer}>
        {subProducts?.map((rec: any, subKey: any) => (
          <div className={classes.innerContainerSubProducts}>
            {Object.values(rec)?.map((stepData: any) => (!visible
              && stepData?.fieldParam?.visible !== true
              && ![
                'aftx',
                'tlist',
                'country',
                'state',
                'dualsel01',
                'routecode',
                'dualselYN',
                'aftx:inven_units',
                'chain',
                'static',
                'emailgroup',
                'faxgroup',
                'dualselGN',
                'transval',
                'termid',
                'product',
                'language',
                'carrier',
                'accessprofile',
                'dualselAI',
                'shippingmethodid',
                'productid',
                'preset',
                'bay',
                'tank',
                'scadproductchain',
                'vehicle',
                'baychain',
                'compartment',
                'presetchain',
                'productid',
                '',
              ].includes(stepData?.fieldParam?.type || stepData?.[0]?.type) ? (
                <></>
              ) : [
                'aftx',
                'tlist',
                'country',
                'state',
                'dualsel01',
                'routecode',
                'dualselYN',
                'aftx:inven_units',
                'chain',
                'static',
                'emailgroup',
                'faxgroup',
                'dualselGN',
                'transval',
                'termid',
                'product',
                'language',
                'carrier',
                'accessprofile',
                'dualselAI',
                'shippingmethodid',
                'productid',
                'preset',
                'bay',
                'tank',
                'meterchain',
                '',
              ].includes(stepData?.fieldParam?.type)
                || stepData?.['\u0000*\u0000parm']?.options?.length > 0 ? (
                  <Grid item xs={2} sm={4} md={4} className={classes.product}>
                    <div style={{ padding: '0px 5px' }}>
                      <Typography
                        fontSize="10px"
                        color="#003399"
                        fontFamily="inherit"
                      >
                        {stepData?.fieldParam?.col}
                        {stepData?.fieldParam?.spantext}
                      </Typography>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        disabled={
                        stepData?.fieldParam?.attr?.includes('readonly')
                        || review
                      }
                        value={
                        valuesToSend?.[itemKey]?.[
                          stepData?.['\u0000*\u0000nameID']
                        ]
                        || stepData?.fieldParam?.value
                        || 'none'
                      }
                        onChange={(e: any) => handleInputProductChange(
                          stepData?.[0]?.valueid,
                          e,
                          itemKey,
                        )}
                        size="small"
                        sx={{
                        // product id field is wider than the other fields so we need to specify the condition for width
                          minWidth:
                          stepData?.['\u0000*\u0000nameID']?.search('prod_id')
                          > 0
                            ? '400px'
                            : '100%',
                          borderRadius: '0px',
                          marginTop: '5px',
                          '& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select':
                          {
                            padding: '1px 6px',
                            border: '1px solid #E4E4E4',
                          },
                        }}
                      >
                        {setOptionFormat(stepData)?.map((item: any) => (
                          <MenuItem key={item.value} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </Grid>
                ) : (
                  <Grid item xs={2} sm={4} md={4} className={classes.product}>
                    <div style={{ padding: '0px 5px' }}>
                      <Typography
                        fontSize="10px"
                        color="#003399"
                        fontFamily="inherit"
                      >
                        {stepData?.fieldParam?.col}
                        {stepData?.fieldParam?.spantext}
                      </Typography>
                      <CustomInput
                        type={stepData.type}
                        label=""
                        required={stepData?.fieldParam.attr?.includes('required')}
                        name={splitdash(stepData?.['\u0000*\u0000nameID'])?.name}
                        defaultValue={stepData?.[0]?.value}
                        attr={stepData?.fieldParam?.attr}
                        value={
                        prefixProducts?.[itemKey]?.[
                          stepData?.['\u0000*\u0000nameID']
                        ]
                      }
                        isBatch={false}
                        onChange={handleInputChange}
                        size="100%"
                        placeholder={stepData?.fieldParam?.helpertext}
                        isDisabled={
                        stepData?.[0]?.attr?.includes('readonly') || review
                      }
                        validate={stepData?.[0]?.validate}
                        customKey={itemKey}
                        transaction
                        productSubKey={pad(subKey)}
                        prefix={
                        splitdash(stepData?.['\u0000*\u0000nameID'])?.prefix
                      }
                        funNamePrefix={stepData?.['\u0000*\u0000nameID']}
                      />
                    </div>
                  </Grid>
                )))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductRecordComponent;
