import {
  LOAD_DEVICE_COMMUNICATIONS,
  CLEAR_DEVICE_COMMUNICATIONS,
  SUBMIT_DEVICE_COMMUNICATIONS,
} from '../../constants/workflows';

const INITIAL_STATE = {
  data: [],
  modalList: [],
  modalListResponse: {},
  submitResponse: {},
};

const deviceCommunicationsReducer = (
  state = INITIAL_STATE,
  { type, payload }: any,
) => {
  switch (type) {
    case LOAD_DEVICE_COMMUNICATIONS: {
      return {
        ...state,
        data: payload.data,
        modalList: payload.modalList,
        modalListResponse: payload.modalListResponse,
      };
    }
    case CLEAR_DEVICE_COMMUNICATIONS: {
      return {
        ...state,
        data: [],
        modalList: [],
        modalListResponse: {},
      };
    }
    case SUBMIT_DEVICE_COMMUNICATIONS: {
      return {
        ...state,
        submitResponse: payload,
      };
    }
    default:
      return state;
  }
};

export default deviceCommunicationsReducer;
