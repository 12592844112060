/* eslint-disable max-len */
/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
import { TextField } from '@mui/material';
import React from 'react';
import Table from '../CustomReportTable';

import configOptions from '../../../../dummyData/configOptionsData.json';
import CustomReportFolderTree from '../CustomReportFolderTree';

const CustomReportOptionsTable = (props:any) => {
  const [filter, setFilter] = React.useState<string>('');

  const filteredOptions = React.useMemo(() => configOptions.filter((item: any) => item.option.toLowerCase().includes(filter)), [filter]);

  return (
    <div style={{ display: 'table', height: '50vh' }}>
      <div style={{ display: 'table-row' }}>
        <div style={{ width: '59%', height: '100%', display: 'table-cell' }}>
          <CustomReportFolderTree />
        </div>
        <div style={{
          width: '100%', height: '100%', overflow: 'auto', display: 'table-cell',
        }}
        >
          <Table rows={filteredOptions} headCells={['Option', 'Description']} pages={filteredOptions.length || 0} />
        </div>
      </div>
    </div>
  );
};

export default CustomReportOptionsTable;
