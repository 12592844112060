import React from 'react';

// Libraries
import { Menu, MenuItem } from '@mui/material';

// Styles
import { useStyles } from './style';

interface IProps {
  anchorEl: any;
  onClose: () => void;
  openCreateScreen: () => void;
  openColumnsDialog: () => void;
}

const DocumentBuilderTableMenu: React.FC<IProps> = ({
  anchorEl,
  onClose,
  openCreateScreen,
  openColumnsDialog,
}) => {
  const classes = useStyles();
  return (
    <Menu
      classes={{ root: classes.root, list: classes.list, paper: classes.paper }}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
    >
      <MenuItem
        classes={{ root: classes.menuItem }}
        onClick={openCreateScreen}
      >
        New
      </MenuItem>
      <MenuItem
        classes={{ root: classes.menuItem }}
        onClick={openColumnsDialog}
      >
        Columns ...
      </MenuItem>
    </Menu>
  );
};

export default DocumentBuilderTableMenu;
