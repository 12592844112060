import React from 'react';

// Libraries
import {
  ButtonBase, DialogActions, Divider, Typography,
} from '@mui/material';
import clsx from 'clsx';

// Components
import { DialogComponent } from '../Dialog/Dialog.component';
import Select from '../Select';

// Styles
import { useStyles } from './style';

interface IProps {
  onClose: () => void;
  open: boolean;
  selectedRow: any;
}

const TrafficDialog: React.FC<IProps> = ({ onClose, open, selectedRow }) => {
  const classes = useStyles();
  return (
    <DialogComponent
      title={`Actions: ${selectedRow?.bay_desc || ''}`}
      onClose={onClose}
      open={open}
    >
      <div className={clsx('flexRow', classes.wrapper)}>
        <Typography className={classes.label}>Actions</Typography>
        <Select
          options={['Start Traffic On Bay', 'Stop Traffic On Bay']}
          width="75%"
          style={{ margin: '2px 10px' }}
        />
      </div>
      <Divider variant="middle" />
      <DialogActions>
        <ButtonBase classes={{ root: classes.applyButton }} onClick={onClose}>
          Apply Action
        </ButtonBase>
        <ButtonBase classes={{ root: classes.closeButton }} onClick={onClose}>
          Cancel
        </ButtonBase>
      </DialogActions>
    </DialogComponent>
  );
};

export default TrafficDialog;
