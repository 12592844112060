/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/require-default-props */
import React, { useState } from 'react';

// Librairies
import {
  Box,
  ButtonBase,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Tab,
  Tabs,
} from '@mui/material';
import clsx from 'clsx';
import AddBoxIcon from '@mui/icons-material/AddBox';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { useDispatch } from 'react-redux';

// Components
import Select from '../Select';
import CustomDatePicker from '../DatePicker';
import Input from '../Input';

// Styles
import { useStyles } from './style';

import { getFormattedTime, getPGCRFormattedDate } from '../../utils';
import CustomTimePicker from '../TimePicker';
import { applySearch } from '../../redux/actions/workflows/transactionViewer';

interface IPros {
  onApply: () => void;
  onCancel: () => void;
  onRemove: () => void;
  filters: any;
}

const getFieldsOptions = (fields: any) => Object.values(fields || {}).map((x: any) => ({
  label: x.label,
  value: x.id,
}));

const getOperationsOptions = (operations: string[]) => Object.values(operations || {}).map((x: string) => ({
  label: x,
  value: x,
}));

export const getChilds = (filters: any[], groupId: any) => {
  const resultArray: any[] = [];
  const groupIdChilds = filters.filter((x) => x.groupId === groupId);
  if (groupIdChilds.length) {
    resultArray.push(...groupIdChilds);
    groupIdChilds.forEach((x) => {
      if (!x.isGroup) return;
      const subChilds = getChilds(filters, x.id);
      resultArray.push(...subChilds);
    });
  }
  return resultArray;
};

const getNewFilters = (filters: any[], key: string, value: any, id: number) => {
  const changedFieldIndex = filters.findIndex((elem) => elem.ruleId === id);
  if (changedFieldIndex === -1) return null;
  const newRule = { ...filters[changedFieldIndex] };
  if (key === 'date') {
    const formattedDate = getPGCRFormattedDate(value);
    newRule.value = {
      pickerValue: value,
      inputValue: formattedDate?.replaceAll('/', '-'),
    };
  } else if (key === 'time') {
    const formattedTime = getFormattedTime(value);
    newRule.value = {
      pickerValue: value,
      inputValue: formattedTime,
    };
  } else {
    newRule[key] = value;
  }
  const newAdvancedFilters = [...filters];
  newAdvancedFilters[changedFieldIndex] = newRule;
  return newAdvancedFilters;
};

interface ICardState {
  tabValue: number;
  basicFilters: { selectedFields: any[]; match: any };
  advancedFilters: any[];
  headerForm: {
    filterId: any;
    share: any;
    resultLimit: any;
  };
}

const TransactionViewerSeacrhCard: React.FC<IPros> = ({
  onCancel,
  onApply,
  onRemove,
  filters,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [cardState, setCardState] = useState<ICardState>(() => ({
    tabValue: 0,
    headerForm: {
      filterId: '',
      share: '',
      resultLimit: '',
    },
    basicFilters: {
      selectedFields: [],
      match: '',
    },
    advancedFilters: [
      {
        id: '0',
        groupId: null,
        isGroup: true,
        condition: '',
        not: '',
        valid: '',
      },
      {
        groupId: '0',
        field: '',
        operation: '',
        date: '',
        ruleId: Date.now(),
      },
    ],
  }));

  const handleSelectField = (e: any, option: any) => {
    if (!option?.label || option?.label === '-- Select --') return;
    setCardState({
      ...cardState,
      basicFilters: {
        ...cardState.basicFilters,
        selectedFields: {
          ...cardState.basicFilters.selectedFields,
          [Date.now()]: {
            field: option,
            condition: '',
            value: '',
          },
        },
      },
    });
  };

  const handleSelectMatch = (e: any, option: any) => {
    setCardState({
      ...cardState,
      basicFilters: {
        ...cardState.basicFilters,
        match: option,
      },
    });
  };

  const unSelectField = (id: number) => {
    const newFields = { ...cardState.basicFilters.selectedFields };
    delete newFields[id];
    setCardState({
      ...cardState,
      basicFilters: {
        ...cardState.basicFilters,
        selectedFields: newFields,
      },
    });
  };

  const handleAddRule = (groupId: string) => {
    setCardState({
      ...cardState,
      advancedFilters: [
        ...cardState.advancedFilters,
        {
          groupId,
          field: '',
          operation: '',
          date: '',
          ruleId: Date.now(),
        },
      ],
    });
  };

  const handleAddGroup = (groupId: string) => {
    const newGroupId = Date.now();
    setCardState({
      ...cardState,
      advancedFilters: [
        ...cardState.advancedFilters,
        {
          groupId,
          isGroup: true,
          id: newGroupId,
          condition: '',
          not: '',
          valid: '',
        },
        {
          groupId: newGroupId,
          field: '',
          operation: '',
          date: '',
          ruleId: Date.now() + 1,
        },
      ],
    });
  };

  const handleDeleteRule = (ruleId: string) => {
    const filteredRules = cardState.advancedFilters.filter(
      (rule: any) => rule.ruleId !== ruleId,
    );
    setCardState({
      ...cardState,
      advancedFilters: filteredRules,
    });
  };

  const handleDeleteGroup = (groupId: string) => {
    const childs = getChilds(cardState.advancedFilters, groupId);
    const childsIds = childs.map((child) => (child.isGroup ? child.id : child.ruleId));
    const compareArray = [...childsIds, groupId];
    const filteredGroups = cardState.advancedFilters.filter(
      (element: any) => !compareArray.includes(element.isGroup ? element.id : element.ruleId),
    );
    setCardState({
      ...cardState,
      advancedFilters: filteredGroups,
    });
  };

  const handleSelectOperator = (id: number, option: any) => {
    setCardState({
      ...cardState,
      basicFilters: {
        ...cardState.basicFilters,
        selectedFields: {
          ...cardState.basicFilters.selectedFields,
          [id]: {
            ...cardState.basicFilters.selectedFields[id],
            condition: option,
          },
        },
      },
    });
  };

  const handleChangeValue = (id: number, value: string) => {
    setCardState({
      ...cardState,
      basicFilters: {
        ...cardState.basicFilters,
        selectedFields: {
          ...cardState.basicFilters.selectedFields,
          [id]: {
            ...cardState.basicFilters.selectedFields[id],
            value,
          },
        },
      },
    });
  };

  const handleSelectFieldOption = (id: number, option: any) => {
    const newAdvancedFilters = getNewFilters(
      cardState.advancedFilters,
      'field',
      option,
      id,
    );
    if (newAdvancedFilters) {
      setCardState({
        ...cardState,
        advancedFilters: newAdvancedFilters,
      });
    }
  };

  const handleSelectFieldOperator = (id: number, option: any) => {
    const newAdvancedFilters = getNewFilters(
      cardState.advancedFilters,
      'operation',
      option,
      id,
    );
    if (newAdvancedFilters) {
      setCardState({
        ...cardState,
        advancedFilters: newAdvancedFilters,
      });
    }
  };

  const handleSelectDate = (id: number, date: Date | null) => {
    const newAdvancedFilters = getNewFilters(
      cardState.advancedFilters,
      'date',
      date,
      id,
    );
    if (newAdvancedFilters) {
      setCardState({
        ...cardState,
        advancedFilters: newAdvancedFilters,
      });
    }
  };

  const handleSelectTime = (id: number, date: Date | null) => {
    const newAdvancedFilters = getNewFilters(
      cardState.advancedFilters,
      'time',
      date,
      id,
    );
    if (newAdvancedFilters) {
      setCardState({
        ...cardState,
        advancedFilters: newAdvancedFilters,
      });
    }
  };

  const handleChangeTabs = (event: React.SyntheticEvent, newValue: number) => {
    setCardState({ ...cardState, tabValue: newValue });
  };

  const handleCheckCondition = (id: number) => {
    const changedFieldIndex = cardState.advancedFilters.findIndex(
      (elem) => elem.id === id,
    );
    const newGroup = { ...cardState.advancedFilters[changedFieldIndex] };
    newGroup.not = !newGroup.not;
    const newAdvancedFilters = [...cardState.advancedFilters];
    newAdvancedFilters[changedFieldIndex] = newGroup;
    setCardState({
      ...cardState,
      advancedFilters: newAdvancedFilters,
    });
  };

  const handleSelectCondition = (id: number, condition: 'AND' | 'OR') => {
    const changedFieldIndex = cardState.advancedFilters.findIndex(
      (elem) => elem.id === id,
    );
    const newGroup = { ...cardState.advancedFilters[changedFieldIndex] };
    newGroup.condition = condition;
    const newAdvancedFilters = [...cardState.advancedFilters];
    newAdvancedFilters[changedFieldIndex] = newGroup;
    setCardState({
      ...cardState,
      advancedFilters: newAdvancedFilters,
    });
  };

  const handleSelectFormHeaderInput = (key: string, option: any) => {
    setCardState({
      ...cardState,
      headerForm: {
        ...cardState.headerForm,
        [key]: option,
      },
    });
  };

  const handleApply = () => {
    dispatch(applySearch(cardState));
    onApply();
  };

  return (
    <Paper elevation={3} className={classes.paper}>
      <div className={classes.modalHeader}>
        <h4 className={classes.modalTitle}>Search Criteria</h4>
      </div>
      <div className={classes.papperContent}>
        <FiltersHeaderForm
          filters={filters}
          headerForm={cardState.headerForm}
          handleSelectFormHeaderInput={handleSelectFormHeaderInput}
        />
        <div>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              marginBottom: '5px',
            }}
          >
            <Tabs
              value={cardState.tabValue}
              onChange={handleChangeTabs}
              aria-label="basic tabs example"
            >
              <Tab label="Basic" style={{ textTransform: 'none' }} />
              <Tab label="Advanced" style={{ textTransform: 'none' }} />
            </Tabs>
          </Box>
          {cardState.tabValue === 0 ? (
            <BasicFilters
              basicFiltersState={cardState.basicFilters}
              handleSelectField={handleSelectField}
              handleSelectMatch={handleSelectMatch}
              unSelectField={unSelectField}
              basicFilters={filters?.basicTab || {}}
              handleSelectOperator={handleSelectOperator}
              handleChangeValue={handleChangeValue}
            />
          ) : null}
          {cardState.tabValue === 1 ? (
            <AdvancedFilters
              filters={filters}
              advancedFiltersState={cardState.advancedFilters}
              handleAddGroup={handleAddGroup}
              handleAddRule={handleAddRule}
              handleDeleteGroup={handleDeleteGroup}
              handleDeleteRule={handleDeleteRule}
              handleSelectDate={handleSelectDate}
              handleSelectFieldOperator={handleSelectFieldOperator}
              handleSelectFieldOption={handleSelectFieldOption}
              handleCheckCondition={handleCheckCondition}
              handleSelectCondition={handleSelectCondition}
              handleSelectTime={handleSelectTime}
            />
          ) : null}
        </div>
      </div>
      <div className={classes.modalActions}>
        <ButtonBase
          className={classes.removeButton}
          onClick={onRemove}
          disabled
        >
          Remove
        </ButtonBase>
        <ButtonBase className={classes.applyButton} onClick={handleApply}>
          Apply
        </ButtonBase>
        <ButtonBase className={classes.cancleButton} onClick={onCancel}>
          Cancel
        </ButtonBase>
      </div>
    </Paper>
  );
};

interface IBasicFilters {
  basicFilters: any;
  basicFiltersState: any;
  handleSelectField: (e: any, option: any) => void;
  handleSelectMatch: (e: any, option: any) => void;
  unSelectField: (index: number) => void;
  handleSelectOperator: (id: number, option: any) => void;
  handleChangeValue: (id: number, value: string) => void;
}

const BasicFilters: React.FC<IBasicFilters> = ({
  basicFilters,
  basicFiltersState,
  handleSelectField,
  handleSelectMatch,
  unSelectField,
  handleSelectOperator,
  handleChangeValue,
}) => {
  const classes = useStyles();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid
        classes={{ root: clsx(classes.gridWrapper, 'fullWidth') }}
        container
        spacing={{ xs: 1, md: 3 }}
        columns={{ xs: 2, sm: 2, md: 12 }}
      >
        <Grid
          classes={{
            root: clsx(classes.gridWrapper, 'flexRowAlignCenter'),
          }}
          item
          xs={2}
          sm={4}
          md={8}
        >
          <div className={classes.label}>Add Search Field Max. 10</div>
          <Select
            disableClearInput
            onChange={handleSelectField}
            options={
              basicFilters.addSearchField?.['\u0000*\u0000parm']?.options
            }
            width="100%"
            value={{ label: '-- Select --', value: '' }}
          />
        </Grid>
        <Grid
          classes={{
            root: clsx(classes.gridWrapper, 'flexRowAlignCenter'),
          }}
          item
          xs={2}
          sm={4}
          md={4}
        >
          <div className={classes.label}>Match</div>
          <Select
            disableClearInput
            onChange={handleSelectMatch}
            options={basicFilters.anyAllObject?.['\u0000*\u0000parm']?.options}
            width="100%"
          />
        </Grid>
      </Grid>
      <div>
        <table className={classes.basicFiltersTable}>
          <thead>
            <tr className={classes.basicFilterRow}>
              <th
                className={classes.basicFilterTh}
                style={{ width: '20%', textAlign: 'right' }}
              >
                Field
              </th>
              <th
                className={classes.basicFilterTh}
                style={{ width: '20%', textAlign: 'center' }}
              >
                Condition
              </th>
              <th
                className={classes.basicFilterTh}
                style={{ width: '45%', textAlign: 'center' }}
              >
                Value
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(basicFiltersState.selectedFields).map(
              ([id, field]: any) => (
                <tr key={id}>
                  <td className={classes.basicFilterFirstTd}>
                    {field?.field?.label}
                  </td>
                  <td className={classes.basicFilterTd}>
                    <Select
                      disableClearInput
                      options={
                        basicFilters?.conditionsSelectObject?.[
                          '\u0000*\u0000parm'
                        ]?.options
                      }
                      width="fit-content"
                      onChange={(_, option) => handleSelectOperator(id, option)}
                      value={basicFiltersState.selectedFields[id].condition}
                    />
                  </td>
                  <td className={classes.basicFilterTd}>
                    <Input
                      onChange={(e) => handleChangeValue(id, e.target.value)}
                      style={{ width: '100%' }}
                      value={basicFiltersState.selectedFields[id].value}
                    />
                  </td>
                  <td>
                    <IconButton onClick={() => unSelectField(id)}>
                      <DeleteOutlinedIcon />
                    </IconButton>
                  </td>
                </tr>
              ),
            )}
          </tbody>
        </table>
      </div>
    </Box>
  );
};

interface IAdvancedFilters {
  filters: any;
  advancedFiltersState: any[];
  handleAddRule: (groupId: string) => void;
  handleAddGroup: (groupId: string) => void;
  handleDeleteRule: (ruleId: string) => void;
  handleDeleteGroup: (groupId: string) => void;
  handleSelectFieldOption: (id: number, option: any) => void;
  handleSelectFieldOperator: (id: number, option: any) => void;
  handleSelectDate: (id: number, date: Date | null) => void;
  handleSelectTime: (id: number, date: Date | null) => void;
  handleCheckCondition: (id: number) => void;
  handleSelectCondition: (id: number, condition: 'AND' | 'OR') => void;
}

const AdvancedFilters: React.FC<IAdvancedFilters> = ({
  filters,
  advancedFiltersState,
  handleAddGroup,
  handleAddRule,
  handleDeleteGroup,
  handleDeleteRule,
  handleSelectDate,
  handleSelectFieldOperator,
  handleSelectFieldOption,
  handleCheckCondition,
  handleSelectCondition,
  handleSelectTime,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.subContainer}>
      <Group
        filters={filters}
        group={advancedFiltersState[0]}
        handleAddRule={handleAddRule}
        advancedFiltersState={advancedFiltersState}
        handleAddGroup={handleAddGroup}
        handleDeleteRule={handleDeleteRule}
        handleDeleteGroup={handleDeleteGroup}
        handleSelectDate={handleSelectDate}
        handleSelectFieldOperator={handleSelectFieldOperator}
        handleSelectFieldOption={handleSelectFieldOption}
        handleCheckCondition={handleCheckCondition}
        handleSelectCondition={handleSelectCondition}
        handleSelectTime={handleSelectTime}
      />
    </div>
  );
};

interface IGroup {
  filters: any;
  group: any;
  handleAddRule: (ruleId: string) => void;
  handleAddGroup: (groupId: string) => void;
  advancedFiltersState: any[];
  handleDeleteRule: (ruleId: string) => void;
  handleDeleteGroup: (groupId: string) => void;
  handleSelectFieldOption: (id: number, option: any) => void;
  handleSelectFieldOperator: (id: number, option: any) => void;
  handleSelectDate: (id: number, date: Date | null) => void;
  handleCheckCondition: (id: number) => void;
  handleSelectCondition: (id: number, condition: 'AND' | 'OR') => void;
  handleSelectTime: (id: number, date: Date | null) => void;
}

const Group: React.FC<IGroup> = ({
  filters,
  group,
  handleAddRule,
  advancedFiltersState,
  handleAddGroup,
  handleDeleteRule,
  handleDeleteGroup,
  handleSelectDate,
  handleSelectFieldOperator,
  handleSelectFieldOption,
  handleCheckCondition,
  handleSelectCondition,
  handleSelectTime,
}) => {
  const classes = useStyles();
  const childs = advancedFiltersState.filter(
    (elem: any) => elem.groupId === group.id,
  );

  return (
    <div className={group.groupId && classes.ruleBuilderContainer}>
      <div className={classes.groupHeader}>
        <div className="flexStartSpaceBetweenRow">
          <div>
            <FormControlLabel
              classes={{
                root: classes.formControl,
                label: classes.checkBoxLabel,
              }}
              control={(
                <Checkbox
                  onClick={() => handleCheckCondition(group.id)}
                  className={classes.checkBox}
                  size="small"
                  defaultChecked
                />
              )}
              label="NOT"
            />
            <ButtonBase
              className={classes.controlButton}
              style={{
                backgroundColor: group.condition === 'AND' ? '#00a230' : '#aaa',
              }}
              onClick={() => handleSelectCondition(group.id, 'AND')}
            >
              AND
            </ButtonBase>
            <ButtonBase
              className={classes.controlButton}
              style={{
                backgroundColor: group.condition === 'OR' ? '#00a230' : '#aaa',
              }}
              onClick={() => handleSelectCondition(group.id, 'OR')}
            >
              OR
            </ButtonBase>
          </div>
          <div>
            <ButtonBase
              className={classes.addRuleButton}
              onClick={() => handleAddRule(group.id)}
            >
              <AddBoxIcon fontSize="small" />
              Add rule
            </ButtonBase>
            <ButtonBase
              className={classes.addGroupButton}
              onClick={() => handleAddGroup(group.id)}
            >
              <AddCircleIcon fontSize="small" />
              Add group
            </ButtonBase>
            {group?.id === '0' ? null : (
              <ButtonBase
                className={classes.deleteGroupButton}
                onClick={() => handleDeleteGroup(group.id)}
              >
                <ClearIcon fontSize="small" />
                delete
              </ButtonBase>
            )}
          </div>
        </div>
        <div className={classes.subContainer}>
          {childs.map((child: any, index: number) => {
            if (child.isGroup) {
              return child.id !== group.id ? (
                <Group
                  group={child}
                  filters={filters}
                  key={index.toExponential()}
                  handleAddRule={handleAddRule}
                  advancedFiltersState={advancedFiltersState}
                  handleAddGroup={handleAddGroup}
                  handleDeleteRule={handleDeleteRule}
                  handleDeleteGroup={handleDeleteGroup}
                  handleSelectDate={handleSelectDate}
                  handleSelectFieldOperator={handleSelectFieldOperator}
                  handleSelectFieldOption={handleSelectFieldOption}
                  handleCheckCondition={handleCheckCondition}
                  handleSelectCondition={handleSelectCondition}
                  handleSelectTime={handleSelectTime}
                />
              ) : null;
            }
            return (
              <Rule
                key={index.toExponential()}
                fields={filters?.advFilterFields}
                operations={filters?.operators}
                handleDeleteRule={handleDeleteRule}
                rule={child}
                handleSelectDate={handleSelectDate}
                handleSelectFieldOperator={handleSelectFieldOperator}
                handleSelectFieldOption={handleSelectFieldOption}
                handleSelectTime={handleSelectTime}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

interface IRule {
  fields: any;
  operations: string[];
  handleDeleteRule: (ruleId: string) => void;
  rule: any;
  handleSelectFieldOption: (id: number, option: any) => void;
  handleSelectFieldOperator: (id: number, option: any) => void;
  handleSelectDate: (id: number, date: Date | null) => void;
  handleSelectTime: (id: number, date: Date | null) => void;
}

const Rule: React.FC<IRule> = ({
  fields,
  operations,
  handleDeleteRule,
  rule,
  handleSelectFieldOption,
  handleSelectFieldOperator,
  handleSelectDate,
  handleSelectTime,
}) => {
  const classes = useStyles();
  const fieldsOptions = getFieldsOptions(fields);
  const operationsOptions = getOperationsOptions(operations);

  console.log(rule);
  console.log(fieldsOptions);
  console.log(operationsOptions);
  console.log(operations);

  const getValueInput = () => {
    switch (rule?.field?.label) {
      case 'Date': {
        return (
          <CustomDatePicker
            handleChange={(value) => handleSelectDate(rule.ruleId, value)}
            inputValue={rule?.value ? rule?.value?.inputValue : ''}
            pickerValue={rule?.value ? rule?.value?.pickerValue : Date.now()}
          />
        );
      }
      case 'Time': {
        return (
          <CustomTimePicker
            handleChange={(value) => handleSelectTime(rule.ruleId, value)}
            inputValue={rule?.value ? rule?.value?.inputValue : ''}
            pickerValue={rule?.value ? rule?.value?.pickerValue : Date.now()}
          />
        );
      }
      default: {
        return <Input onChange={() => null} />;
      }
    }
  };

  return (
    <div className={classes.ruleBuilderContainer}>
      <div className="flexStartSpaceBetweenRow">
        <div className="flexRow">
          <ImportExportIcon />
          <div className="flexRow" style={{ gap: 5 }}>
            <Select
              options={fieldsOptions}
              disableClearInput
              onChange={(_, option) => handleSelectFieldOption(rule.ruleId, option)}
              value={rule.field}
            />
            <Select
              options={operationsOptions}
              disableClearInput
              onChange={(_, option) => handleSelectFieldOperator(rule.ruleId, option)}
              value={rule.operation}
            />
          </div>
          <Divider
            variant="middle"
            orientation="vertical"
            style={{ margin: '0px 5px', height: '27px' }}
          />
          {getValueInput()}
        </div>
        <div>
          <ButtonBase
            className={classes.deleteRuleButton}
            onClick={() => handleDeleteRule(rule.ruleId)}
          >
            <ClearIcon fontSize="small" />
            delete
          </ButtonBase>
        </div>
      </div>
    </div>
  );
};

interface IFilters {
  filters: any;
  headerForm: any;
  handleSelectFormHeaderInput: (key: string, option: any) => void;
}

const FiltersHeaderForm: React.FC<IFilters> = ({
  filters,
  headerForm,
  handleSelectFormHeaderInput,
}) => {
  const classes = useStyles();
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid
        classes={{ root: clsx(classes.gridWrapper, 'fullWidth') }}
        container
        spacing={{ xs: 1, md: 3 }}
        columns={{ xs: 2, sm: 2, md: 12 }}
      >
        <Grid
          classes={{
            root: clsx(classes.gridWrapper, 'flexRowAlignCenter'),
          }}
          item
          xs={2}
          sm={4}
          md={4}
        >
          <div className={classes.label}>Filter</div>
          <Select
            options={
              filters?.customFilterSelectObj?.['\u0000*\u0000parm']?.options
            }
            onChange={(_, option) => handleSelectFormHeaderInput('filterId', option)}
            value={headerForm.filterId}
            addable
            width="100%"
            disableClearInput
          />
        </Grid>
        <Grid
          classes={{
            root: clsx(classes.gridWrapper, 'flexRowAlignCenter'),
          }}
          item
          xs={2}
          sm={4}
          md={4}
        >
          <div className={classes.label}>Share</div>
          <Select
            options={filters?.shareOptionsObject || []}
            onChange={(_, option) => handleSelectFormHeaderInput('share', option)}
            value={headerForm.share}
            width="100%"
          />
        </Grid>
        <Grid
          classes={{
            root: clsx(classes.gridWrapper, 'flexRowAlignCenter'),
          }}
          item
          xs={2}
          sm={4}
          md={4}
        >
          <div className={classes.label}>Result Limit</div>
          <Select
            options={filters?.queryLimitSelect?.['\u0000*\u0000parm']?.options}
            width="100%"
            onChange={(_, option) => handleSelectFormHeaderInput('resultLimit', option)}
            value={headerForm.resultLimit}
            disableClearInput
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default TransactionViewerSeacrhCard;
