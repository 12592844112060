import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  TextField, Button, CardMedia, Typography, Stack, Box,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import Logo from '../../assets/logo.png';
import Hexagons from '../../assets/hexagons.png';
import { signin } from '../../redux/actions';
import LoginReducer from '../../redux/reducers/loginReducer';

interface loginProps {
    username: string;
    password: string;
  }

const schema = yup.object().shape({
  username: yup.string().required('Username is required'),
  password: yup.string().required('Password is required'),
});

const LoginPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<loginProps>({
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, error, isAuthenticated } = useSelector(
    (state: any) => state.LoginReducer,
  );
  const onSubmit = async (data: loginProps) => {
    const { username, password } = data;
    dispatch(signin({ username, password }, navigate));
  };
  useEffect(() => {
    if (isAuthenticated) {
      navigate('/');
    }
  }, [isAuthenticated]);

  return (
    <div
      style={{
        backgroundImage: `url(${Hexagons})`,
        backgroundRepeat: 'no-repeat',
        height: '100vh',
        backgroundSize: 'auto 100%',
        backgroundPosition: 'right',
      }}
    >
      <div
        style={{
          maxWidth: '500px',
          position: 'fixed',
          top: '15%',
          left: '33%',
          boxShadow: '0 8px 50px -2px #888',
        }}
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          style={{ padding: '10px 30px 50px 30px' }}
        >
          <Stack style={{ paddingBottom: '25px' }}>
            <CardMedia
              style={{ verticalAlign: 'middle' }}
              component="img"
              height="80"
              image={Logo}
              alt="logo"
            />
            <Typography
              style={{
                marginTop: '-17px',
                marginLeft: '55px',
              }}
              variant="body2"
              color="text.secondary"
            >
              8.0.1 build 1
            </Typography>
          </Stack>
          <Stack
            spacing={4}
            direction="column"
          >
            <TextField
              id="username"
              label="Login"
              variant="outlined"
              required
              {...register('username')}
              helperText={errors.username?.message || error}
              error={!!errors.username?.message || error}
            />

            <TextField
              id="password"
              label="Password"
              variant="outlined"
              type="password"
              required
              {...register('password')}
              helperText={errors.password?.message || error}
              error={!!errors.password?.message || error}
            />
            <Stack>
              <Button
                type="submit"
                variant="contained"
              >
                Login
              </Button>
            </Stack>
            <Box sx={{ typography: 'subtitle2', textAlign: 'left', m: 1 }}>
              © 2014 -
              {' '}
              {(new Date().getFullYear())}
              {' '}
              Toptech Systems, Inc.
            </Box>
          </Stack>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
