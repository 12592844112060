import { LOAD_TRAFFICS, CLEAR_TRAFFICS } from '../../constants/workflows';

const INITIAL_STATE = {
  data: [],
  headers: [],
};

const TrafficReducer = (state = INITIAL_STATE, { type, payload }: any) => {
  switch (type) {
    case LOAD_TRAFFICS: {
      return {
        ...state,
        data: payload.data,
        headers: payload.headers,
      };
    }
    case CLEAR_TRAFFICS: {
      return {
        ...state,
        data: [],
        headers: [],
      };
    }
    default:
      return state;
  }
};

export default TrafficReducer;
