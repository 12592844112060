import { Dispatch } from 'redux';
import Api from '../../api/index';
import {
  LOAD_IO_MONITORS,
  LOAD_BUILT_IO_MONITORS,
} from '../../constants/workflows';

export const fetchIoMonitorData = () => async (dispatch: Dispatch) => {
  const response = await Api.get('workflow012/data');
  dispatch({
    type: LOAD_IO_MONITORS,
    payload: response.data.data,
  });
};

export const getMonitorsValues = (ioMonitors: any[]) => async (dispatch: Dispatch) => {
  const response = await Api.post(
    'workflow012/showdata',
    ioMonitors.length
      ? {
        form_data: ioMonitors,
      }
      : {},
  );
  dispatch({
    type: LOAD_BUILT_IO_MONITORS,
    payload: response.data.data,
  });
};
