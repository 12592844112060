import React from 'react';

// Librairies
import {
  ButtonBase, Divider,
} from '@mui/material';

// Styles
import { useNavigate } from 'react-router-dom';
import { useStyles } from './style';

const ButtonsHeader: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <>
      <div>
        <ButtonBase
          className={classes.saveButton}
        >
          Save
        </ButtonBase>
        <ButtonBase className={classes.cancelButton} onClick={() => navigate(-1)}>
          Cancel
        </ButtonBase>
        <ButtonBase className={classes.removeButton}>
          Remove
        </ButtonBase>
        <ButtonBase className={classes.interpolateButton}>
          Interpolate
        </ButtonBase>
      </div>
      <Divider className={classes.divider} />
    </>
  );
};

export default ButtonsHeader;
