import {
  LOAD_SYSTEM_STARTUP,
  CLEAR_SYSTEM_STARTUP,
  LOAD_SYSTEM_STARTUP_OPTIONS,
  CLEAR_SYSTEM_STARTUP_FORM_DATA,
  SET_SYSTEM_STARTUP_FORM_DATA,
  SET_SYSTEM_STARTUP_DATA,
  SET_SYSTEM_STARTUP_STATUS,
  CLEAR_SYSTEM_STARTUP_STATUS,
  SET_SYSTEM_STARTUP_RCH,
  CLEAR_SYSTEM_STARTUP_RCH,
} from '../constants/systemStartup';

const INITIAL_STATE = {
  data: [],
  binsList: [],
  options: {},
  formData: {},
  status: {},
  recordChangeHistory: [],
  newRecord: '',
};

const recordReducer = (state = INITIAL_STATE, { type, payload }: any) => {
  switch (type) {
    case LOAD_SYSTEM_STARTUP: {
      return {
        ...state,
        data: payload.data,
        binsList: payload.binsList,
      };
    }
    case LOAD_SYSTEM_STARTUP_OPTIONS: {
      return {
        ...state,
        options: {
          ...state.options,
          [payload.command]: payload.data,
        },
      };
    }
    case SET_SYSTEM_STARTUP_RCH: {
      return {
        ...state,
        recordChangeHistory: payload,
      };
    }
    case CLEAR_SYSTEM_STARTUP_RCH: {
      return {
        ...state,
        recordChangeHistory: [],
      };
    }
    case CLEAR_SYSTEM_STARTUP_FORM_DATA: {
      return {
        ...state,
        formData: {},
      };
    }
    case SET_SYSTEM_STARTUP_STATUS: {
      return {
        ...state,
        status: payload,
      };
    }
    case CLEAR_SYSTEM_STARTUP_STATUS: {
      return {
        ...state,
        status: {},
      };
    }
    case SET_SYSTEM_STARTUP_FORM_DATA: {
      return {
        ...state,
        formData: {
          ...state.formData,
          [payload.row.row]: payload.row.fields,
        },
      };
    }
    case SET_SYSTEM_STARTUP_DATA: {
      return {
        ...state,
        data: payload.data,
        formData: {
          ...state.formData,
          [payload.newRow.row]: payload.newRow.fields,
        },
        newRecord: payload.newRow.row,
      };
    }
    case CLEAR_SYSTEM_STARTUP: {
      return {
        ...state,
        data: [],
        binsList: [],
      };
    }
    default:
      return state;
  }
};

export default recordReducer;
