/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useRef, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

import TT_backdrop from '../../assets/TT_backdrop.png';
import TT_logo from '../../assets/TT_logo.png';
import gridDot from '../../assets/gridDot.png';
import DraggableDiv from './draggableDiv';

interface Props {
    showGrid?: boolean;
  }

const Canvas: React.FC<Props> = ({
  showGrid,
}) => {
  const [allDivRefs, setAllDivRefs] = useState<Array<React.RefObject<HTMLDivElement>>>([]);
  const [boxes, setBoxes] = useState([
    {
      id: 1,
      data: {
        x: 10, y: 10, w: 50, h: 50,
      },
    },
    {
      id: 2,
      data: {
        x: 60, y: 60, w: 50, h: 50,
      },
    },
    {
      id: 3,
      data: {
        x: 0, y: 0, w: 50, h: 50,
      },
    },
  ]);
  const [selectedElement, setSelectedElement] = useState<number | null>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const canvasDivRef = useRef<HTMLDivElement>(null);

  function updateElement(targetId: number, properties: any) {
    setBoxes((prevElements: any) => {
      const updatedElements = prevElements.map((element: any) => {
        if (element.id === targetId) {
          return {
            ...element, data: { ...properties },
          };
        }
        return element;
      });
      return updatedElements;
    });
  }

  return (
    <div
      ref={canvasDivRef}
      style={{
        width: '799.2px',
        height: '928.8px',
        position: 'relative',
        border: '1px solid black',
        backgroundColor: 'white',
        padding: 0,
        margin: 0,
      }}
    >
      <div
        id="grid"
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          backgroundImage: showGrid ? `url(${gridDot})` : undefined,
          backgroundRepeat: 'repeat',
        }}
      />
      <img
        id="TTBackdrop"
        src={TT_backdrop}
        alt="TT_backdrop"
        draggable={false}
        style={{
          position: 'absolute',
          width: '799.2px',
          height: '928.8px',
        }}
      />
      <img
        id="TTLogo"
        src={TT_logo}
        alt="TT_logo"
        draggable={false}
        style={{
          position: 'absolute',
          width: '799.2px',
          height: '928.8px',
        }}
      />
      {boxes.map((boxElement: any) => (
        <DraggableDiv
          key={uuidv4()}
          parentRef={canvasDivRef}
          elementId={boxElement.id}
          dimensions={boxElement.data}
          handleUpdateElement={() => updateElement(boxElement.id, {})}
          minWidth="50px"
          minHeight="50px"
        />
      ))}
    </div>
  );
};

export { Canvas };
