import * as React from 'react';
import ReactDOM from 'react-dom';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ColumnsScreen from '../columns';
import {
  BatchDeleteRecord, CloseColumnModal, DeleteRecord, LoadTables, openColumnModal,
  LoadRecord,
  SetRecentScreens,
  StartLoading,
  StopLoading,
} from '../../redux/actions/ScreenActions';
import ControlButton from '../controlButton';
import { currentUtility } from '../../redux/actions/systemUtilities/systemUtilities';
import { currentReport } from '../../redux/actions/reports/reports';
import useWindowDimensions from '../../utils/windowDimensions';

const style = {
  position: 'absolute' as const,
  left: '10%',
  top: '10%',
  width: '70%',
  height: 170,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
};

const ConfirmationModalLoseChanges = (props:any) => {
  const {
    Visible, activeScreen, setScreen,
    handleCloseConfirm, myscreen, isbatch, tableId,
    setSelected, setBatchMode, handleCloseModalYes, recentScreen,
  } = props;
  const { activeSreen, ScreensList } = useSelector((state: any) => state.ScreenReducer);
  const dispatch = useDispatch();
  const { height, width } = useWindowDimensions();
  const { Language, isAuthenticated } = useSelector(
    (state: any) => state.LoginReducer,
  );
  const navigate = useNavigate();
  const handleClose = () => {
    setScreen('');
    handleCloseConfirm();
  };
  const handleDelete = async () => {
    handleCloseModalYes();

    if (recentScreen === '/home') {
      navigate('/home');
    }
    // check of recent screen for confrmation model if change in form
    if (recentScreen) {
      console.log('recentScreen', recentScreen);
      if (recentScreen.type === 'Utility') {
        dispatch(currentUtility({
          reportId: recentScreen.reportId,
          heading: recentScreen.label,
          progName: recentScreen.progName,
        }));
        navigate('/workflow/system-utility');
        return;
      }
      if (recentScreen.type === 'Workflows') {
        navigate(`${recentScreen.path}`);
        return;
      }
      const fields = Array.from(new Set([...recentScreen.fields.split(',')])).join(',');
      dispatch(
        LoadTables({
          tableID: recentScreen.tableId,
          page: '1',
          results_per_page: `${Math.ceil(height / 60)}`,
          fields: recentScreen.fields ? fields : '',
          fieldsConcatenated: recentScreen.fieldsConcatenated,
          order_by: '',
          where: recentScreen.screenConfiguration || '',
          redirect: `/maint/${recentScreen.screenId}`,
          screenId: recentScreen.screenId,
          keys: recentScreen.keys || '',
          defaultcolumns: recentScreen.defaultcolumns || '',
          lang: Language,
          navigate,
        }),
      );
      dispatch(
        LoadRecord({
          tableID: recentScreen.tableId,
          screenID: recentScreen.screenId,
          fields: recentScreen.fields || '',
          where: '',
          lang: Language,
        }),
      );
      return;
    }

    if (myscreen === '') {
      navigate(`${activeScreen}`);
    } else {
      const optionArray = await ScreensList.filter(
        (screen: any) => {
          let val = '';
          if (screen.screenname) { val = screen.screenname; } else if (screen.label) { val = screen.label; } else if (screen.title) { val = screen.title; } else { val = ''; }
          return val === myscreen.screenName ? myscreen.screenName : '';
        },
      );

      if (optionArray.length === 0) {
        navigate(myscreen.url);
        return;
      }
      try {
        const option = optionArray[0];
        if (option.type === 'Utility') {
          dispatch(
            currentUtility({
              reportId: option.reportId,
              heading: option.label,
              progName: option.progName,
            }),
          );
          navigate('/workflow/system-utility');
          return;
        }
        if (option.type === 'WorkflowsReport') {
          dispatch(
            currentReport({
              reportId: option.reportid,
              heading: option.label,
              progName: option.prog_name,
            }),
          );
          navigate('/workflow/reports');
          return;
        }
        if (option.type === 'Workflows') {
          navigate(`${option.path}`);
          return;
        }
        const fields = Array.from(new Set([...option.fields.split(',')])).join(
          ',',
        );
        dispatch(SetRecentScreens({ options: option }));
        dispatch(
          LoadTables({
            tableID: option.tableId,
            page: '1',
            results_per_page: `${Math.ceil(height / 60)}`,
            fields: option.fields ? fields : '',
            fieldsConcatenated: option.fieldsConcatenated,
            order_by: '',
            where: option.screenConfiguration || '',
            redirect: `/maint/${option.screenId}`,
            screenId: option.screenId,
            keys: option.keys || '',
            defaultcolumns: option.defaultcolumns || '',
            lang: Language,
            labels: option.labels || '',
            navigate,
          }),
        );
        dispatch(
          LoadRecord({
            tableID: option.tableId,
            screenID: option.screenId,
            fields: option.fields || '',
            where: '',
            lang: Language,
          }),
        );
      } catch (error) {
        //
      }
    }
  };

  return ReactDOM.createPortal(
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={Visible}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 460,
      }}
    >
      <Fade in={Visible}>
        <Box sx={style}>
          <div style={{ padding: 10, backgroundColor: '#d2d2d2' }}>
            <Typography>Lose Changes</Typography>
          </div>
          <div style={{
            height: 'auto', display: 'flex', justifyContent: 'center', alignSelf: 'center', padding: '25px 0px',
          }}
          >
            <Typography>You are about to lose the changes you have made. Do you want to continue?</Typography>
          </div>
          <Divider />
          <div style={{
            height: 'auto', padding: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center',
          }}
          >
            <Typography color="red">This action cannot be undone.</Typography>
            <div style={{
              height: 'auto', display: 'flex', gap: 5, alignItems: 'center', alignSelf: 'center',
            }}
            >
              <ControlButton
                label="Cancel"
                color="#ffbb33"
                disabled={false}
                handleClick={handleClose}
              />
              <ControlButton
                label="Yes"
                color="#00c851"
                disabled={false}
                handleClick={handleDelete}
              />
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>,
    document.getElementById('draggable') as HTMLElement,
  );
};
export default ConfirmationModalLoseChanges;
