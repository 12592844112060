import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import Subnavbar from '../../components/Sub-navbar/Subnavbar';
import { LoadRecord } from '../../redux/actions/ScreenActions';
import FormBodyTerminal from '../../components/formBodyTermDetail/formBodyTerminal';
import FormHeaderTerminal from '../../components/FormHeaderTermDetail/FormHeaderTerminal';
import BatchModeFormHeaderTerminal from '../../components/FormHeaderTermDetail/BatchModeHeaderDetails';
import BatchModeFormBody from '../../components/formBodyTermDetail/BatchModeFormBody';
import TmsHeader from '../../components/header';

const BatchModeTerminalProfile = () => {
  const location = useLocation();
  const routeBack:any = location.state;
  const dispatch = useDispatch();
  const { recordDetails, activeScreen, parsedValues } = useSelector((state: any) => state.ScreenReducer);
  const [values, setValues] = useState<any>([]);
  const [isModifiy, setIsModifiy] = useState(false);
  const [showOptionalFields, setShowOptionalFields] = useState(false);
  const [valuesToSend, setValuesToSend] = useState<any>([]);
  const navigate = useNavigate();
  const {
    Language,
  } = useSelector((state: any) => state.LoginReducer);
  useEffect(() => {
    if (activeScreen) {
      dispatch(LoadRecord({
        tableID: activeScreen.tableID,
        screenID: activeScreen.screenId,
        where: '',
        fields: activeScreen.fields,
        lang: Language,
      }));
    } else if (!activeScreen) {
      navigate('/home');
    }
  }, [activeScreen]);
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const newValues = { ...values };
    const newValuesToSend = { ...valuesToSend };

    if (value !== undefined) {
      newValues[name] = value;
      newValuesToSend[name] = value;
    } else if (newValues[name] !== undefined || newValuesToSend[name] !== undefined) {
      delete newValues[name];
      delete newValuesToSend[name];
    }

    setValues(newValues);
    setValuesToSend(newValuesToSend);
    setIsModifiy(Object.keys(newValues).length > 0 || Object.keys(newValuesToSend).length > 0);
  };
  const handleShowOptionalFields = () => {
    setShowOptionalFields(!showOptionalFields);
  };
  const handleSelectChange = (label:any, e:React.ChangeEvent<HTMLInputElement>, newValue:any) => {
    const newValues = { ...values };
    const newValuesToSend = { ...valuesToSend };

    if (newValue.value !== undefined) {
      newValues[label] = newValue;
      newValuesToSend[label] = newValue.value;
    } else if (newValues[label] || newValuesToSend[label]) {
      delete newValues[label];
      delete newValuesToSend[label];
    }
    setValues(newValues);
    setValuesToSend(newValuesToSend);
    setIsModifiy(Object.keys(newValues).length > 0 || Object.keys(newValuesToSend).length > 0);
  };

  const compare = (currentValue: any) => {
    const result = currentValue?.obj['\u0000*\u0000parm']?.options?.filter((e: any) => (e?.value === values[currentValue?.id]));
    return result ? result[0]?.label : '';
  };

  // useEffect(() => {
  //   if (!activeScreen) {
  //     navigate('/home');
  //   } else if (activeScreen) {
  //     dispatch(LoadRecord(location.state));
  //   }
  // }, []);

  const { _, ...restData } = recordDetails;
  useEffect(() => {
    if (parsedValues.term_id) {
      setValues({ ...values, term_id: parsedValues.term_id });
    }
  }, []);
  return (
    recordDetails.length === 0 ? (
      <div style={{
        width: '100%',
        height: '90vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      >
        <CircularProgress />
      </div>
    )
      : (
        <>
          <TmsHeader />
          <Subnavbar
            label={`${routeBack.tableID}: Detail`}
            menuOptionType="simpleItems"
            handleShowOptionalFields={handleShowOptionalFields}
            showOptionalFields={showOptionalFields}
          />
          <BatchModeFormHeaderTerminal
            options={activeScreen.redirect}
            dataHeader={_}
           // routeBack={routeBack}
            handleSelectChange={handleSelectChange}
            handleInputChange={handleInputChange}
            values={values}
            valuesToSend={valuesToSend}
            isModifiy={isModifiy}
            compare={compare}
          />
          <BatchModeFormBody
            dataTabs={restData}
            handleSelectChange={handleSelectChange}
            handleInputChange={handleInputChange}
            compare={compare}
            valuesToSend={valuesToSend}
            values={values}
            showOptionalFields={showOptionalFields}

          />
        </>
    // )
      )
  );
};

export default BatchModeTerminalProfile;
