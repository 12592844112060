/* eslint-disable no-nested-ternary */
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

interface IState {
  timer: number;
  refresh: boolean;
}

export const useWorkflowReload = (getWorkflowData: any) => {
  const [timerState, setTimerState] = useState<IState>(() => ({
    timer: 25,
    refresh: false,
  }));
  const range = useRef<boolean | null>(false);
  const dispatch = useDispatch();

  const getSelectedText = () => {
    if (timerState.refresh) return null;
    let text: string | undefined = '';
    const selection = window.getSelection();
    if (typeof selection !== 'undefined') {
      text = window?.getSelection()?.toString();
      range.current = selection && selection.type === 'Range';
    }
    return { text, range };
  };

  const doSomethingWithSelectedText = () => {
    const selection = getSelectedText();
    if (!selection) return;
    if (selection.text && !timerState.refresh && selection.range) {
      setTimerState({
        timer: 25,
        refresh: true,
      });
    }
  };

  useEffect(() => {
    document.onmouseup = doSomethingWithSelectedText;
  }, []);

  useEffect(() => {
    if (timerState.timer === 0 && getWorkflowData) {
      dispatch(getWorkflowData());
    }
    if (timerState.refresh) {
      setTimeout(() => {
        setTimerState((prev: IState) => {
          const timer = prev.refresh === false ? 25 : prev.timer === 0 ? 25 : prev.timer - 1;
          const refresh = prev.timer === 0 ? false : prev.refresh;
          return { ...prev, timer, refresh };
        });
      }, 1000);
    }
  }, [timerState.timer, timerState.refresh]);

  return { timerState, setTimerState };
};
