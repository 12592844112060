/* eslint-disable react/require-default-props */
import React, { useState } from 'react';

// Librairies
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// Components
import Input from '../../../Input';
import { getFormattedTime } from '../../../../utils';

interface IProps {
    handleChange: (dateInput: any, name: string) => void;
    name: string;
}

const DateTimePickerReport: React.FC<IProps> = ({
  handleChange,
  name,
}) => {
  function formatDate(d: Date, join: string) {
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getDate()}`;
    const year = d.getFullYear();

    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;
    return [year, month, day].join(join);
  }
  const [dateAnchorEl, setDateAnchorEl] = React.useState<null | HTMLElement>(
    null,
  );
  const [datePickerOpen, setDatePickerOpen] = React.useState(false);
  const [inputValue, setInputValue] = useState<any>(
    `${formatDate(new Date(), '/')} ${getFormattedTime(new Date())}`,
  );
  const [pickerValue, setPickerValue] = useState<Date | null>(new Date());
  const handleDateTimePckerChange = (value: Date | null) => {
    if (value) {
      setPickerValue(value);
      handleChange(`${formatDate(value, '')}${getFormattedTime(value)}`, name);
      setInputValue(`${formatDate(value, '/')} ${getFormattedTime(value)}`);
    }
  };
  const handleDateInputClick = (event: React.MouseEvent<HTMLElement>) => {
    setDateAnchorEl(event.currentTarget);
    setDatePickerOpen(true);
  };
  const handleCloseDateTimePicker = () => {
    setDateAnchorEl(null);
    setDatePickerOpen(false);
  };
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
          inputFormat="yyyy/MM/dd hh:mm"
          PopperProps={{ anchorEl: dateAnchorEl }}
          onChange={handleDateTimePckerChange}
          value={pickerValue}
          renderInput={() => <></>}
          open={datePickerOpen}
          onClose={handleCloseDateTimePicker}
        />
        <Input
          onChange={() => null}
          value={inputValue}
          onClick={handleDateInputClick}
          style={{
            width: '100%',
            marginRight: '8px',
          }}
          required={false}
        />
      </LocalizationProvider>
    </>
  );
};

export default DateTimePickerReport;
