/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import clsx from 'clsx';
import {
  ButtonBase,
  InputBase,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Alert,
  Snackbar,
  TableSortLabel,
} from '@mui/material';
import {
  Remove,
  Add,
  ArrowDropDown,
  ArrowDropUp,
  UnfoldMore,
} from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
// Styles

import SubHeader from '../../../components/subHeader';
import TmsHeader from '../../../components/header';
import { getScreensList } from '../../../redux/actions/ScreenActions';
import Select from '../../../components/Select';
import OrderMaintenanceMenu from './OrderMaintenanceMenu';
import TableItemsText from '../../../components/TableItemsText';
import Pagination from '../../../components/Pagination';
import { getRecordToShowValue, sortedTable } from '../../../utils';
import OrderMaintenanceTabColumnsDialog from './OrderMaintenanceTabColumnsDialog';
// constants
import {
  SET_MESSAGE,
  SET_SELECTED_ORDER_MAINTENANCE_BATCH_ROWS,
  SET_ORDER_MAINTENANCE_RESPONSE_MSG,
} from '../../../redux/constants/workflows';
// actions
import {
  getOrderMaintenanceData,
  dissmissConfirmationAlert,
  updateOrderMaintenanceColumns,
} from '../../../redux/actions/workflows/orderMaintenance';
import OrderMaintenanceTransitionModal from './OrderMaintenanceTransitionModal';
import { useStyles } from './style';

interface ITableState {
  tableData: [];
  currentPage: number;
  searchedDataCount: number;
  searchText: string;
  recordsNumber: string;
  orderBy: string;
  order: string;
  columnsDialogOpen: boolean;
  snackBarOpen: boolean;
}
const disableOrdersBatch = ['A', 'C', 'F', 'L', 'S', 'H'];
const OrderMaintenance = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    data,
    visibleColumns,
    hiddenColumns,
    totalRecords,
    allTableColumns,
    columnsLabels,
    openConfirmationAlert,
    confirmationAlert,
    totalRecordsWithSearch,
    filterQuery,
    crudx,
    responseMsg,
  } = useSelector((state: any) => state.Workflows.OrderMaintenanceReducer);
  const {
    ScreensList,
  } = useSelector((state: any) => state.ScreenReducer);
  const [tableState, setTableState] = useState<ITableState>(() => ({
    tableData: [],
    currentPage: 1,
    searchedDataCount: 0,
    recordsNumber: 'Auto',
    columnsDialogOpen: false,
    snackBarOpen: false,
    searchText: '',
    orderBy: '',
    order: '',
  }));
  const recordsToShow = getRecordToShowValue(
    tableState.recordsNumber,
    data.length,
    18,
  );
  const countPages = Math.ceil(
    (tableState.searchText ? totalRecordsWithSearch : (totalRecords || 0)) / recordsToShow,
  );

  const [uniqueIdentifier, setUniqueIdentifier] = useState([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [collapsedIndexes, setCollapsedIndexes] = useState<number[]>([]);
  const [BatchMode, setBatchMode] = React.useState(false);
  const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
  const [batchAction, setBatchAction] = React.useState(false);
  const [openResponseMsg, setOpenResponseMsg] = React.useState<boolean>(false);

  async function updateOrderMaintenanceRecord() {
    const userID = localStorage.getItem('username');
    const changeList = { AcctNo: 123, Instructions: 'record' };
    try {
      const res = await axios.put(`${process.env.REACT_APP_API_URL}workflow161/update?
      screenID=WORKFLOW-161&batchAction=maint_batch_update`,
      {
        recList: JSON.stringify(uniqueIdentifier).toString(),
        changeList: JSON.stringify(changeList).toString(),
        userID,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: res.data,
      });
    } catch (err) {
      //
    }
  }
  const handleCloseAlert = () => {
    setOpenResponseMsg(false);
    dispatch({
      type: SET_ORDER_MAINTENANCE_RESPONSE_MSG,
      payload: {
        responseMsg: [],
      },
    });
  };
  const onCollapse = (index: number) => {
    if (collapsedIndexes.includes(index)) {
      setCollapsedIndexes(collapsedIndexes.filter((x: number) => x !== index));
    } else {
      setCollapsedIndexes([...collapsedIndexes, index]);
    }
  };
  const handlePaginationChange = (event: any, targetPage: any) => {
    setTableState({ ...tableState, currentPage: targetPage });
    // dispatch(
    //   getMeterEntryData(
    //     targetPage,
    //     recordsToShow,
    //     tableState.searchText ? tableState.searchText : '',
    //   ),
    // );
  };

  useEffect(() => {
    if (responseMsg?.status === 200) { setOpenResponseMsg(true); }
  }, [responseMsg]);
  useEffect(() => {
    setTableState((prevState: ITableState) => ({
      ...prevState,
      tableData: data,
    }));
  }, [data]);
  const handleSortTable = (key: string) => {
    const orderType = !tableState.order || tableState.order === 'desc' ? 'asc' : 'desc';
    setTableState({ ...tableState, orderBy: key, order: orderType });
  };

  const handleSortClick = (key: string) => (event: React.MouseEvent<unknown>) => {
    if (handleSortTable) {
      handleSortTable(key);
    }
  };

  const handleCloseColumnsDialog = () => {
    setTableState({ ...tableState, columnsDialogOpen: false });
  };
  const handleClose = () => {
    dispatch(dissmissConfirmationAlert());
  };
  useEffect(() => {
    dispatch(getOrderMaintenanceData());
    dispatch(getScreensList());
  }, [confirmationAlert]);

  const handleOptionClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (selectedRows.length > 1) { setBatchAction(true); } else { setBatchAction(false); }
  }, [selectedRows]);

  const handleChecked = (row:any) => {
    console.log('int row', row);
    const checkOrderNo = (obj:any) => obj.OrderNo === row.OrderNo;
    console.log('int checkorderno', selectedRows.some(checkOrderNo));
    if (selectedRows.some(checkOrderNo)) {
      const selectedArr = selectedRows.filter((elem:any) => elem.OrderNo !== row.OrderNo);
      console.log('int selectedArr', selectedArr);
      setSelectedRows(selectedArr);
    } else {
      setSelectedRows([...selectedRows, row]);
    }
  };
  const removeSelectedRecord = () => {
    dispatch({ type: SET_SELECTED_ORDER_MAINTENANCE_BATCH_ROWS, payload: selectedRows });
    navigate('/workflow/order-maintenance-delete');
  };
  const modifySelectedRecord = () => {
    navigate('/workflow/order-maintenance-modify');
  };
  const isSelected = (name: string) => selectedRows.indexOf(name) !== -1;
  const handleSubmitNewColumns = (newColumns: { [key: string]: string[] }) => {
    dispatch(updateOrderMaintenanceColumns(newColumns));
  };
  const handleRestoreDefault = () => {
    dispatch(updateOrderMaintenanceColumns({}));
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleSelectChange = (e: any, option: any) => {
    //
  };
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //
  };
  const handleOpenColumnsDialog = () => {
    setTableState({ ...tableState, columnsDialogOpen: true });
    handleCloseMenu();
  };

  const showClickedRecordDetails = async (childData: any) => {
    const keyRec = {
      RecType: childData.RecType,
      SupplierNo: childData.SupplierNo,
      OrderNo: childData.OrderNo,
    };
    navigate('/workflow/order-maintenance-details', {
      state: {
        keyRec,
      },
    });
  };
  return (
    <>
      <TmsHeader />
      <SubHeader title="Order Maintenance" menuOptions={filterQuery}>
        <OrderMaintenanceMenu
          type="main"
          openColumnsDialog={handleOpenColumnsDialog}
          crudx={crudx}
        />
      </SubHeader>
      <div
        className={clsx('flexStartSpaceBetweenRow', classes.subHeaderContainer)}
      >
        <div className={clsx('flexRow')}>
          <span className={classes.label}>Records :</span>
          <Select
            options={['Auto', '10', '20', '25', '35', '50', 'All']}
            width={200}
            value={tableState.recordsNumber}
            onChange={handleSelectChange}
          />
        </div>
        <div className={clsx('flexRow')}>
          {BatchMode ? (
            <>
              <ButtonBase
                className={classes.modifySearchButton}
                disabled={!data.length}
                onClick={() => setBatchMode(!BatchMode)}
              >
                Single Mode
              </ButtonBase>
              <ButtonBase
                className={
                  `${classes.batchActionButton} 
                  ${batchAction && `${classes.batchModifyBtnClr}`}`
                }
                disabled={!batchAction}
                onClick={modifySelectedRecord}
              >
                Modify
              </ButtonBase>
              <ButtonBase
                className={
                  `${classes.batchActionButton} 
                  ${batchAction && `${classes.batchRemoveBtnClr}`}`
                }
                disabled={!batchAction}
                onClick={removeSelectedRecord}
              >
                Remove
              </ButtonBase>
            </>
          )
            : (
              <ButtonBase
                className={classes.modifySearchButton}
                disabled={!data.length || crudx.access_x !== 'Y'}
                onClick={() => setBatchMode(!BatchMode)}
              >
                Batch Mode
              </ButtonBase>
            )}
        </div>
        <div className="flexRow">
          <span className={classes.label}>Search :</span>
          <InputBase
            className={classes.searchInput}
            value={tableState.searchText}
            onChange={handleSearchChange}
          />
        </div>
      </div>
      <Snackbar
        open={openConfirmationAlert}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={confirmationAlert.includes('successfully') ? 'success' : 'error'}
        >
          {confirmationAlert}
        </Alert>
      </Snackbar>
      <div className={classes.root}>
        <Table classes={{ root: classes.tableRoot }}>
          <TableHead>
            <TableRow classes={{ root: classes.headerRow }}>
              <TableCell classes={{ root: classes.headerCell }} />
              {Object.keys(visibleColumns.Orders || {}).map((key) => (
                <TableCell classes={{ root: classes.headerCell }} key={key}>
                  <TableSortLabel
                    active={tableState.orderBy === key}
                    onClick={handleSortClick(key)}
                    // eslint-disable-next-line no-nested-ternary
                    IconComponent={() => (tableState.order === 'asc'
                    && tableState.orderBy === key ? (
                      <ArrowDropUp />
                      ) : tableState.order === 'desc' && tableState.orderBy === key ? (
                        <ArrowDropDown />
                      ) : (
                        <UnfoldMore />
                      ))}
                    classes={{ root: classes.sortLabel }}
                  >
                    {columnsLabels[key]}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!data.length && (
            <TableRow className={classes.tableRow}>
              <TableCell
                classes={{ root: classes.bodyCellEmpty }}
                style={{ textAlign: 'center' }}
                colSpan={5}
              >
                No matching records found
              </TableCell>
            </TableRow>
            )}
            {sortedTable(tableState.tableData, tableState.orderBy, tableState.order)
              ?.map((row:any, index:number) => {
                const isItemSelected = isSelected(row);
                return (
                  <>
                    <TableRow
                      onClick={BatchMode ? () => handleChecked(row) : () => onCollapse(index)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      className={`${classes.tableRow}  
                      ${collapsedIndexes.includes(index) ? classes.rowBg : ''}`}
                    >
                      {BatchMode && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          disabled={!!disableOrdersBatch.includes(row.OrderStatus)}
                        />
                      </TableCell>
                      )}
                      <TableCell
                        classes={{ root: classes.bodyCell }}
                      >
                        <ButtonBase
                          className={classes.actionButton}
                        >
                          {collapsedIndexes.includes(index) ? (
                            <Remove fontSize="small" />
                          ) : (
                            <Add fontSize="small" />
                          )}
                        </ButtonBase>
                      </TableCell>
                      {Object.keys(visibleColumns.Orders).map((field) => (
                        <TableCell
                          classes={{ root: classes.bodyCell }}
                          key={field}
                        >
                          {row[field]}
                        </TableCell>
                      ))}
                    </TableRow>
                    {(collapsedIndexes.includes(index) && row.child.length > 0) ? (
                      <>
                        <TableRow className={classes.tableRow}>
                          <TableCell classes={{ root: classes.childHeaderCell }} />
                          {Object.keys(visibleColumns.OrderItem || {}).map((key) => (
                            <TableCell classes={{ root: classes.headerCell }} key={key}>
                              {columnsLabels[key]}
                            </TableCell>
                          ))}
                        </TableRow>
                        {row.child.map((child:any) => (
                          <TableRow
                            onClick={() => showClickedRecordDetails(child)}
                            className={classes.tableRow}
                          >
                            <TableCell classes={{ root: classes.childBodyCell }} />
                            {Object.keys(visibleColumns.OrderItem).map((field) => (
                              <TableCell
                                classes={{ root: classes.childBodyCell }}
                                key={field}
                              >
                                {child[field]}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </>
                    ) : null}
                  </>
                );
              })}
          </TableBody>
        </Table>
      </div>
      <OrderMaintenanceTabColumnsDialog
        title="Configure Visible Columns"
        onClose={handleCloseColumnsDialog}
        open={tableState.columnsDialogOpen}
        visibleColumns={visibleColumns}
        hiddenColumns={hiddenColumns}
        submitNewColumns={handleSubmitNewColumns}
        handleRestoreDefault={handleRestoreDefault}

      />
      <div className={clsx(classes.footerWrapper, 'flexRowAlignCenter')}>
        <TableItemsText
          allDataCount={(totalRecords as number)}
          countPages={countPages}
          currentPage={(tableState.currentPage as number)}
          recordsToShow={recordsToShow}
          searchText={tableState.searchText}
          searchedDataCount={totalRecordsWithSearch}
        />
        <span className="takeTheRest" />
        <Pagination
          count={countPages}
          onChange={handlePaginationChange}
          pageNumber={tableState.currentPage}
        />
      </div>
      {
        openResponseMsg && (
        <Snackbar
          open={openResponseMsg}
          autoHideDuration={6000}
          onClose={handleCloseAlert}
        >
          <Alert onClose={handleCloseAlert} severity="success">
            {responseMsg?.data}
          </Alert>
        </Snackbar>
        )
        }
      <OrderMaintenanceTransitionModal />
    </>
  );
};

export default OrderMaintenance;
