import { LOAD_FOLIO_FREEZE_CLOSE, CLEAR_FOLIO_FREEZE_CLOSE } from '../../constants/workflows';

const INITIAL_STATE = {
  data: [],
  hiddenColumns: {},
  visibleColumns: {},
};

const folioFreezeCloseReducer = (state = INITIAL_STATE, { type, payload }: any) => {
  switch (type) {
    case LOAD_FOLIO_FREEZE_CLOSE: {
      return {
        ...state,
        data: payload.data,
        hiddenColumns: payload.hiddenColumns,
        visibleColumns: payload.visibleColumns,
      };
    }
    case CLEAR_FOLIO_FREEZE_CLOSE: {
      return {
        data: [],
        hiddenColumns: [],
        visibleColumns: [],
      };
    }
    default:
      return state;
  }
};

export default folioFreezeCloseReducer;
