/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';

// Librairies
import {
  ButtonBase,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { ArrowDropUp, ArrowDropDown, UnfoldMore } from '@mui/icons-material';

import clsx from 'clsx';

// Components
import Pagination from '../Pagination';

// Styles
import { useStyles } from './style';
import CheckboxComponent from '../Checkbox';

// Utils
import TableItemsText from '../TableItemsText';
import { sortedTable } from '../../utils';

interface IProps {
  handleClickRow: () => void;
  headerItems: any[];
  selectRow?: (raw: any, isRowSelected: boolean) => void;
  selectedIoMonitors?: any;
  searchText?: string;
  handleSelectFirst20?: () => void;
  handleUnSelectAll?: () => void;
  allDataCount: any;
  recordsToShow: number;
  orderBy?: string;
  order?: string;
  tableState: any;
  handlePaginationChange: (event: any, targetPage: any) => void;
  sortTable?: (key: string) => void;
  tableRef?: any;
}

const getCellBackgroundColor = (
  orderBy: string | undefined,
  key: string,
  index: number,
): string | undefined => (orderBy === key ? (index % 2 !== 0 ? '#fafafa' : '#f1f1f1') : undefined);

const IOMonitorTable: React.FC<IProps> = ({
  handleClickRow,
  headerItems,
  allDataCount,
  selectRow,
  selectedIoMonitors,
  searchText,
  handleSelectFirst20,
  handleUnSelectAll,
  order,
  orderBy,
  tableState,
  recordsToShow,
  handlePaginationChange,
  sortTable,
  tableRef,
}) => {
  const classes = useStyles();
  const [processMenuAnchorEl, setProcessMenuAnchorEl] = useState<null | HTMLElement>(null);
  const { currentPage, searchedDataCount, tableData } = tableState;
  const countPages = Math.ceil(
    (searchText ? searchedDataCount : allDataCount) / recordsToShow,
  );
  const isRightTable = !handleSelectFirst20;

  const handleSortClick = (key: string) => (event: React.MouseEvent<unknown>) => {
    if (sortTable) {
      sortTable(key);
    }
  };

  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setProcessMenuAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setProcessMenuAnchorEl(null);
  };

  const isRowSelected = (row: any) => {
    if (selectedIoMonitors) {
      return selectedIoMonitors.some(
        (x: any) => x?.comment === row?.comment
          && x?.device === row?.device
          && x?.func === row?.func,
      );
    }
    return false;
  };

  return (
    <>
      <div className={classes.tableWrapper}>
        <Table classes={{ root: classes.tableRoot }}>
          <TableHead>
            <TableRow classes={{ root: classes.headerRow }}>
              {headerItems.map((h) => (h.label === 'menu' ? (
                <TableCell classes={{ root: classes.headerCell }} key={h.key}>
                  <ButtonBase
                    style={{
                      padding: '0px 10px',
                      backgroundColor: '#f0f0f0',
                    }}
                    onClick={handleButtonClick}
                  >
                    Monitor
                    {' '}
                    <ArrowDropDown fontSize="small" />
                  </ButtonBase>
                  <MonitorMenu
                    anchorEl={processMenuAnchorEl}
                    onClose={handleCloseMenu}
                    handleSelectFirst20={handleSelectFirst20}
                    handleUnSelectAll={handleUnSelectAll}
                  />
                </TableCell>
              ) : (
                <TableCell classes={{ root: classes.headerCell }} key={h.key}>
                  <TableSortLabel
                    active={orderBy === h.key}
                    onClick={handleSortClick(h.key)}
                    IconComponent={() => (order === 'asc' && orderBy === h.key ? (
                      <ArrowDropUp />
                    ) : order === 'desc' && orderBy === h.key ? (
                      <ArrowDropDown />
                    ) : (
                      <UnfoldMore />
                    ))}
                    classes={{ root: classes.sortLabel }}
                  >
                    {h.label}
                  </TableSortLabel>
                </TableCell>
              )))}
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableRoot}>
            {!tableData.length && (
              <TableRow className={classes.tableRow}>
                <TableCell
                  classes={{ root: classes.bodyCellEmpty }}
                  style={{ textAlign: 'center' }}
                  colSpan={Object.keys(headerItems).length}
                >
                  {isRightTable
                    ? 'No matching records found'
                    : searchText
                      ? 'No matching records found'
                      : 'No data available in table'}
                </TableCell>
              </TableRow>
            )}
            {sortedTable(tableData, orderBy, order)?.map(
              (row: any, index: number) => (
                <TableRow
                  onClick={handleClickRow}
                  className={classes.tableRow}
                  key={index}
                >
                  {headerItems.map((cell: any) => (cell.label === 'menu' ? (
                    <TableCell
                      classes={{ root: classes.bodyCell }}
                      key={cell.key}
                    >
                      <CheckboxComponent
                        checked={isRowSelected(row)}
                        label=""
                        onChange={() => null}
                        onClick={() => selectRow && selectRow(row, isRowSelected(row))}
                        style={{ marginBottom: 20 }}
                      />
                    </TableCell>
                  ) : (
                    <TableCell
                      classes={{ root: classes.bodyCell }}
                      key={cell.key}
                      style={{
                        backgroundColor: getCellBackgroundColor(
                          orderBy,
                          cell.key,
                          index,
                        ),
                      }}
                    >
                      {row[cell.key] || '0'}
                    </TableCell>
                  )))}
                </TableRow>
              ),
            )}
          </TableBody>
        </Table>
      </div>
      <div className={clsx(classes.footerWrapper, 'flexRowAlignCenter')}>
        {!tableData.length ? (
          <div className={classes.showingText}>
            {' '}
            No records available
            {' '}
            {searchText
              ? `(filtered from ${allDataCount} total entries)`
              : null}
            {' '}
          </div>
        ) : (
          <TableItemsText
            allDataCount={allDataCount}
            countPages={countPages}
            currentPage={currentPage}
            recordsToShow={recordsToShow}
            searchText={searchText}
            searchedDataCount={searchedDataCount}
          />
        )}
        <span className="takeTheRest" />
        <Pagination
          count={countPages}
          onChange={handlePaginationChange}
          pageNumber={currentPage}
        />
      </div>
    </>
  );
};

interface IMenuProps {
  anchorEl: any;
  onClose: () => void;
  handleSelectFirst20?: () => void;
  handleUnSelectAll?: () => void;
}

const MonitorMenu: React.FC<IMenuProps> = ({
  anchorEl,
  onClose,
  handleSelectFirst20,
  handleUnSelectAll,
}) => {
  const classes = useStyles();

  const handleSelectFirst = () => {
    if (handleSelectFirst20) {
      handleSelectFirst20();
      onClose();
    }
  };

  const handleUnSelect = () => {
    if (handleUnSelectAll) {
      handleUnSelectAll();
      onClose();
    }
  };

  useEffect(() => () => {
    if (handleUnSelectAll) {
      handleUnSelectAll();
    }
  }, []);

  return (
    <Menu
      classes={{ root: classes.root, list: classes.list, paper: classes.paper }}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
    >
      <MenuItem
        classes={{ root: classes.menuItem }}
        onClick={handleSelectFirst}
      >
        {' '}
        Select First: 20
        {' '}
      </MenuItem>
      <MenuItem classes={{ root: classes.menuItem }} onClick={handleUnSelect}>
        {' '}
        De-Select all
        {' '}
      </MenuItem>
    </Menu>
  );
};

export default React.memo(IOMonitorTable);
