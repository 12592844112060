export type IColumn = { [key: string]: { label: string; visible: boolean } };

export interface IColumns {
  visibleColumns: IColumn;
  hiddenColumns: IColumn;
}

export const filterColumns = (
  columns: IColumn,
  columnsLabels: { [key: string]: string },
  searchText: string,
): IColumn => Object.keys(columns).reduce((acc, curr: any) => {
  if (columnsLabels[curr]?.toLowerCase().includes(searchText.toLowerCase())) {
    return { ...acc, [curr]: columns[curr] };
  }
  return acc;
}, {});

export const makeDefaultValue = (columns: { [key: string]: string }) => Object.keys(columns).reduce(
  (a, c) => ({ ...a, [c]: { label: columns[c], visible: true } }),
  {},
);

export const makeMultiTabsDefaultValue = (columns: {
  [key: string]: { [key: string]: string };
}) => Object.keys(columns).reduce(
  (a, c) => ({ ...a, [c]: makeDefaultValue(columns[c]) }),
  {},
);

export const getColumns = (columns: IColumn, filtred: IColumn) => Object.keys(columns).reduce(
  (acc, curr: any) => ({
    ...acc,
    [curr]: {
      label: filtred[curr],
      visible: !!filtred[curr],
    },
  }),
  {},
);

export const makeColumnsFromIds = (arr: string[], columns: IColumn) => arr.reduce((acc, curr) => {
  if (typeof columns[curr].label === 'string') {
    return {
      ...acc,
      [curr]: { ...columns[curr], label: columns[curr].label.trim() },
    };
  }
  return {
    ...acc,
    [curr]: {
      ...columns[curr],
      label: (columns[curr].label as any)?.label.trim(),
    },
  };
}, {});

export const deleteFromColumns = (
  columns: IColumn,
  itemsToDelete: string[],
) => {
  const resultColumns = { ...columns };
  itemsToDelete.forEach((item) => {
    delete resultColumns[item];
  });
  return resultColumns;
};
