import React from 'react';

// Librairies
import clsx from 'clsx';

// Styles
import { useStyles } from './style';

// Utils
import { isEmpty } from '../../utils';

interface IProps {
  searchResult: any;
}

const ViewTraceLogs: React.FC<IProps> = ({ searchResult }): JSX.Element => {
  const classes = useStyles();
  const getItemClassName = (item: string) => {
    if (item.includes('[err    ]')) {
      return classes.error;
    }
    if (item.includes('[info   ]')) {
      return classes.info;
    }
    if (item.includes('[warning]')) {
      return classes.warning;
    }
    return classes.info;
  };
  return (
    <>
      {!isEmpty(searchResult) ? (
        <div className={clsx('takeTheRest', classes.resultWrapper)}>
          {searchResult.data.map((item: string) => (
            <div className={clsx(classes.data, getItemClassName(item))}>
              {item}
            </div>
          ))}
        </div>
      ) : null}
    </>
  );
};

export default ViewTraceLogs;
