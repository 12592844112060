/* eslint-disable implicit-arrow-linebreak */
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';

// Librairies
import { ButtonBase, InputBase } from '@mui/material';
import { ArrowDropDownOutlined } from '@mui/icons-material';
import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';

// Components
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/lab';
import SystemStartupTable from '../../../components/systemStartupTable';
import SystemStartupOptions from '../../../components/systemStartupOptions';
import TmsHeader from '../../../components/header';
import SystemStartupDialog from '../../../components/systemStartupDialog';

// Styles
import { useStyles } from './style';

// Actions
import {
  addSystemStartupRow,
  fetchSystemStartup,
  getRecordChangeHistory,
  getSystemStartupStatus,
  saveSystemStartupChanges,
  startTms,
  stopTms,
} from '../../../redux/actions/systemStartup';
import { getScreensList } from '../../../redux/actions/ScreenActions';
import ConfirmationDialog from '../../../components/ConfirmDialog';
import WorkflowRecordChangeHistory from '../../../components/WorkflowRecordChangeHistotory';

// Utils
const searchRecords = (searchText: string, records: any[]): any[] =>
  records.filter((record: any) =>
    record.fields.some((field: any) =>
      String(field.value)?.toLowerCase()?.includes(searchText?.toLowerCase())));

export const getStatusLabelAndColor = (status: any) => {
  switch (status) {
    case 'Orange': {
      return { label: 'Problem', color: '#FFA500' };
    }
    case 'Green': {
      return { label: 'Running', color: '#32CD32' };
    }
    case 'Red': {
      return { label: 'Stopped', color: '#ff4444' };
    }
    default:
      return { label: 'Unknown', color: 'grey' };
  }
};

// Utils
const tableHeader = {
  StartUpOrder: 'StartUpOrder',
  StartUpGroup: 'StartUpGroup',
  Command: 'Command',
  State: 'State',
  PostedName: 'Posted Name',
  Arguments: 'Arguments',
  Restart: 'Restart',
  Notify: 'Notify',
  Delay: 'Delay',
  Description: 'Description',
};

// Utils
const searchHeader = [
  { key: 'StartUpGroup', label: 'StartUpGroup' },
  { key: 'Command', label: 'Command' },
  { key: 'Arguments', label: 'Arguments' },
  { key: 'Restart', label: 'Restart' },
  { key: 'Delay', label: 'Delay' },
  { key: 'Description', label: 'Description' },
  { key: 'Notify', label: 'Notify' },
  { key: 'PostedName', label: 'Posted Name' },
  { key: 'StartUpOrder', label: 'StartUpOrder' },
  { key: 'State', label: 'State' },
];

interface ISystemStartupState {
  systemStartupAnchorEl: null | HTMLElement;
  systemStartupDialogOpen: boolean;
  cancelConfirmDialogOpen: boolean;
  stopTmsConfirmDialogOpen: boolean;
  recordChangeHistoryDialogOpen: boolean;
  startTmsConfirmDialogOpen: boolean;
  saveConfirmDialogOpen: boolean;
  searchText: string;
  tableData: any[];
}

const SystemStartup: React.FC = () => {
  const classes = useStyles();
  const {
    data: systemStartupData,
    binsList,
    formData,
    status: systemStatus,
    recordChangeHistory,
  } = useSelector((state: any) => state.SystemStartupReducer);
  const [systemStartupState, setSystemStartupState] = useState<ISystemStartupState>(() => ({
    systemStartupAnchorEl: null,
    systemStartupDialogOpen: false,
    cancelConfirmDialogOpen: false,
    stopTmsConfirmDialogOpen: false,
    recordChangeHistoryDialogOpen: false,
    startTmsConfirmDialogOpen: false,
    saveConfirmDialogOpen: false,
    searchText: '',
    tableData: [],
  }));
  const dispatch = useDispatch();
  const [GroupCommandStatus, setGroupCommandStatus] = useState(false);
  const [open, setOpen] = useState(false);
  const handleCloseConfirmationMessage = () => {
    setOpen(false);
  };
  const checkForGroupCommandsEntry = () => {
    const notCompletedRecords = Object.values(formData).filter(
      (record: any) =>
        (record?.[0]?.value === ''
        || record?.[1]?.value === ''),
    );
    if (notCompletedRecords.length) {
      setGroupCommandStatus(true);
    } else {
      dispatch(saveSystemStartupChanges());
      setOpen(true);
    }
  };
  const handleCloseGroupCommandStatusDialog = () => {
    setGroupCommandStatus(false);
  };
  useEffect(() => {
    if (systemStartupState.searchText) {
      const filtredRecords = searchRecords(
        systemStartupState.searchText,
        systemStartupData,
      );
      setSystemStartupState((prev) => ({
        ...prev,
        tableData: filtredRecords,
      }));
    } else {
      setSystemStartupState((prev) => ({
        ...prev,
        tableData: systemStartupData,
      }));
    }
  }, [systemStartupState.searchText, systemStartupData]);

  useEffect(() => {
    dispatch(fetchSystemStartup());
    dispatch(getSystemStartupStatus());
    dispatch(getScreensList());
  }, []);

  const handleOptionClick = (event: React.MouseEvent<HTMLElement>) => {
    setSystemStartupState((prev) => ({
      ...prev,
      systemStartupAnchorEl: event.currentTarget,
    }));
  };

  const handleCloseMenu = () => {
    setSystemStartupState((prev) => ({
      ...prev,
      systemStartupAnchorEl: null,
    }));
  };

  const handleOpenSystemStartupDialog = () => {
    setSystemStartupState((prev) => ({
      ...prev,
      systemStartupDialogOpen: true,
    }));
  };

  const handleCloseSystemStartupDialog = () => {
    setSystemStartupState((prev) => ({
      ...prev,
      systemStartupDialogOpen: false,
    }));
  };

  const handleChangeSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSystemStartupState((prev) => ({
      ...prev,
      searchText: e.target.value,
    }));
  };

  const handleAddLine = () => {
    dispatch(addSystemStartupRow());
    handleCloseMenu();
  };

  const openRecordCHangeHistoryDialog = () => {
    setSystemStartupState((prev) => ({
      ...prev,
      recordChangeHistoryDialogOpen: true,
      systemStartupAnchorEl: null,
    }));
    dispatch(getRecordChangeHistory());
  };

  const closeRecordCHangeHistoryDialog = () => {
    setSystemStartupState((prev) => ({
      ...prev,
      recordChangeHistoryDialogOpen: false,
    }));
  };

  const openCancelConfirmDialog = () => {
    setSystemStartupState((prev) => ({
      ...prev,
      cancelConfirmDialogOpen: true,
    }));
  };

  const closeCancelConfirmDialog = () => {
    setSystemStartupState((prev) => ({
      ...prev,
      cancelConfirmDialogOpen: false,
    }));
  };

  const closeAgreeCancelConfirmDialog = () => {
    closeCancelConfirmDialog();
    window.location.reload();
  };

  const openStartTmsConfirmDialog = () => {
    handleCloseMenu();
    setSystemStartupState((prev) => ({
      ...prev,
      startTmsConfirmDialogOpen: true,
    }));
  };

  const closeStartTmsConfirmDialog = () => {
    setSystemStartupState((prev) => ({
      ...prev,
      startTmsConfirmDialogOpen: false,
    }));
  };

  const closeAgreeStartTmsConfirmDialog = () => {
    dispatch(startTms());
    closeStartTmsConfirmDialog();
  };

  const openSaveConfirmDialog = () => {
    handleCloseMenu();
    setSystemStartupState((prev) => ({
      ...prev,
      saveConfirmDialogOpen: true,
    }));
  };

  const closeSaveConfirmDialog = () => {
    setSystemStartupState((prev) => ({
      ...prev,
      saveConfirmDialogOpen: false,
    }));
  };

  const closeAgreeSaveConfirmDialog = () => {
    dispatch(saveSystemStartupChanges());
  };

  const handleSave = () => {
    const allFormDataContainsRowDeleted = Object?.keys(formData)?.some(
      (key: any) => formData[key] === null,
    );
    if (allFormDataContainsRowDeleted) {
      openSaveConfirmDialog();
    } else {
      checkForGroupCommandsEntry();
    }
  };

  const openStopTmsConfirmDialog = () => {
    handleCloseMenu();
    setSystemStartupState((prev) => ({
      ...prev,
      stopTmsConfirmDialogOpen: true,
    }));
  };

  const closeStopTmsConfirmDialog = () => {
    setSystemStartupState((prev) => ({
      ...prev,
      stopTmsConfirmDialogOpen: false,
    }));
  };

  const closeAgreeStopTmsConfirmDialog = () => {
    dispatch(stopTms());
    closeStopTmsConfirmDialog();
  };

  const isCancelButtonDisabled = () => {
    const allFormDataAreRowDeleted = Object?.keys(formData)?.every(
      (key: any) => formData[key] === null,
    );
    if (!Object?.keys(formData).length) return true;
    return allFormDataAreRowDeleted;
  };

  return (
    <div className={clsx(classes.root, 'fullHeight')}>
      <TmsHeader />
      <div className={clsx(classes.header, 'flexStartSpaceBetweenRow')}>
        <h3 className={classes.title}>
          System Start-Up:
          {' '}
          <small>Determine start-up order</small>
        </h3>
        <div className={classes.buttonGroup}>
          <ButtonBase
            className={classes.statusButton}
            onClick={handleOpenSystemStartupDialog}
            style={{
              backgroundColor: getStatusLabelAndColor(
                systemStatus?.state?.split(':')?.[0],
              ).color,
            }}
          >
            {getStatusLabelAndColor(systemStatus?.state?.split(':')?.[0]).label}
          </ButtonBase>
          <ButtonBase
            className={classes.optionsButton}
            onClick={handleOptionClick}
          >
            Options
            <ArrowDropDownOutlined fontSize="small" />
          </ButtonBase>
          <SystemStartupOptions
            anchorEl={systemStartupState.systemStartupAnchorEl}
            handleAddLine={handleAddLine}
            handleStartTms={openStartTmsConfirmDialog}
            handleStopTms={openStopTmsConfirmDialog}
            openRecordCHangeHistoryDialog={openRecordCHangeHistoryDialog}
            onClose={handleCloseMenu}
            status={
              getStatusLabelAndColor(systemStatus?.state?.split(':')?.[0]).label
            }
          />
        </div>
      </div>
      <div className="flexStartSpaceBetweenRow" style={{ padding: '5px 5px' }}>
        <div>
          <ButtonBase
            className={classes.saveButton}
            onClick={handleSave}
            disabled={!Object.keys(formData).length}
          >
            Save
          </ButtonBase>
          <ButtonBase
            className={classes.cancleButton}
            disabled={isCancelButtonDisabled()}
            onClick={openCancelConfirmDialog}
          >
            Cancel
          </ButtonBase>
        </div>
        <div>
          <InputBase
            className={classes.input}
            placeholder="Search"
            value={systemStartupState.searchText}
            onChange={handleChangeSearchInput}
          />
          <span className={classes.helperText}>
            Viewing
            {' '}
            {systemStartupState.tableData.length}
            {' '}
            of
            {' '}
            {systemStartupData.length}
          </span>
        </div>
      </div>
      <SystemStartupTable
        systemStartupData={systemStartupState.tableData}
        binsList={binsList}
        systemStatus={systemStatus}
        searchText={systemStartupState.searchText}
      />
      <SystemStartupDialog
        open={systemStartupState.systemStartupDialogOpen}
        onClose={handleCloseSystemStartupDialog}
        returnedText={systemStatus?.returnedtext}
      />
      {systemStartupState.cancelConfirmDialogOpen && (
        <ConfirmationDialog
          cancelLabel="Cancel"
          confirmLabel="Yes"
          handleCloseAgree={closeAgreeCancelConfirmDialog}
          handleCloseCancel={closeCancelConfirmDialog}
          message="You have made changes
        on this page. If you leave without
        saving, your changes will be lost. Do you want to continue?"
          open={systemStartupState.cancelConfirmDialogOpen}
          title="Leave Page"
          helperText="This action cannot be undone."
        />
      )}
      {systemStartupState.stopTmsConfirmDialogOpen && (
        <ConfirmationDialog
          cancelLabel="No"
          confirmLabel="Yes"
          handleCloseAgree={closeAgreeStopTmsConfirmDialog}
          handleCloseCancel={closeStopTmsConfirmDialog}
          message="Are you sure you want to stop TMS?"
          open={systemStartupState.stopTmsConfirmDialogOpen}
          title="Stop TMS?"
        />
      )}
      {systemStartupState.startTmsConfirmDialogOpen && (
        <ConfirmationDialog
          cancelLabel="No"
          confirmLabel="Yes"
          handleCloseAgree={closeAgreeStartTmsConfirmDialog}
          handleCloseCancel={closeStartTmsConfirmDialog}
          message="Are you sure you want to start TMS?"
          open={systemStartupState.startTmsConfirmDialogOpen}
          title="Start TMS?"
        />
      )}
      {systemStartupState.saveConfirmDialogOpen && (
        <ConfirmationDialog
          cancelLabel="No"
          confirmLabel="Yes"
          handleCloseAgree={closeAgreeSaveConfirmDialog}
          handleCloseCancel={closeSaveConfirmDialog}
          message={`Altering the System Start-Up table will require TMS to stop.\n
        Are you sure you want to stop TMS and save the table?`}
          open={systemStartupState.saveConfirmDialogOpen}
          title="Save Table?"
        />
      )}
      {GroupCommandStatus && (
        <ConfirmationDialog
          cancelLabel="Close"
          handleCloseAgree={handleCloseGroupCommandStatusDialog}
          handleCloseCancel={handleCloseGroupCommandStatusDialog}
          message="Record(s) requires a Start Up Group and a Command defined."
          open={GroupCommandStatus}
          title="Start Up Group and Command are not defined"
        />
      )}
      {systemStartupState.recordChangeHistoryDialogOpen && (
        <WorkflowRecordChangeHistory
          onClose={closeRecordCHangeHistoryDialog}
          open={systemStartupState.recordChangeHistoryDialogOpen}
          historyModalData={recordChangeHistory}
          tabLabel="SystemStartUp"
          tableHeader={tableHeader}
          searchHeader={searchHeader}
          limitNumber={100}
        />
      )}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleCloseConfirmationMessage}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          onClose={handleCloseConfirmationMessage}
          severity="success"
          sx={{ width: '100%' }}
        >
          Success
          <br />
          Startup Entry Updated
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SystemStartup;
