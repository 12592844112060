import * as React from 'react';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { Button, FormControl, Typography } from '@mui/material';

const filter = createFilterOptions<any>();
const useStyles = makeStyles({
  input: {
    '& input': {
      cursor: 'pointer !important',
    },
  },
  autoComplete: {
    cursor: 'pointer !important',
  },
});

const CustomAddDropdown = ({
  type,
  query,
  isBatch,
  valuesToSend,
  required,
  selectList,
  stateValues,
  label,
  obj,
  val,
  size,
  options,
  isDisabled,
  name,
  value,
  helperText,
  onChange,
  isOptionEqualToValue,
  key,
  multiple,
  datagrid,
}: any) => {
  const [values, setValues] = React.useState(null);
  const [dynamicOption, setdynamicOption] = React.useState([]);
  const classes = useStyles();
  const backgroundColor = React.useMemo(() => {
    if (val && !val.visible) {
      return '#fff9f0';
    }
    if (isDisabled) {
      return '#f8f8f8';
    }
    return '#fff';
  }, [value, isDisabled, val]);

  return (
    <div
      key={key}
      style={{
        width: '100%',
        flex: 1,
        alignSelf: 'center',
        marginTop: datagrid ? '0px' : '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: 10,
      }}
    >
      {!datagrid && <Typography className="f12" style={{ flex: 1, textAlign: 'right' }}>{label}</Typography>}
      <FormControl
        disabled={isDisabled}
        size="small"
        variant="outlined"
        color={isDisabled ? 'error' : 'info'}
        sx={{ flex: 3, width: '100%' }}
      >
        {/* Value logic works for default values and selection values for single edit and batch edit
          Might need refactoring for better readability
      */}
        <Autocomplete
          value={value}
          classes={{
            root: classes.autoComplete,
            input: classes.autoComplete,
            inputRoot: classes.autoComplete,
          }}
          onChange={(event, newValue) => {
            if (typeof newValue === 'string') {
              onChange({
                name, newValue,
              });
            } else if (newValue && newValue.inputValue) {
              // Create a new value from the user input
              onChange({
                name, newValue: newValue.inputValue,
              });
            } else {
              onChange({
                name, newValue: newValue.inputValue,
              });
            }
          }}
          filterOptions={(opt, params) => {
            const filtered = filter(opt, params);

            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = opt.some((option) => inputValue === option.value);
            filtered.push({
              inputValue,
              label: `Add ${inputValue}`,
            });

            return filtered;
          }}
          clearOnBlur
          handleHomeEndKeys
          id="free-solo-with-text-demo"
          options={value ? [{ value, label: value }, ...dynamicOption] : [{ value: '', label: 'no options' }]}
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === 'string') {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue;
            }
            // Regular option
            return option.value;
          }}
          renderOption={(props, option) => <li {...props}>{option.label}</li>}
          sx={{ width: size }}
          renderInput={(params) => (
            <TextField
              {...params}
              className={classes.input}
              placeholder={value || 'No data available'}
              size="small"
              sx={{
                '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
                  padding: '2px',
                  border: '1px solid #dddddd',
                  width: '100%',
                  fontSize: '12px',
                },
                '& .MuiInputBase-root': {
                  '& > fieldset': {
                    borderColor: required && value === '' ? 'red' : 'gray',
                    color: 'gray',
                    borderLeft: required
                      ? '5px solid #ff3b3b'
                      : '5px solid #555',
                    borderRadius: '0px !important',
                    fontSize: '10px',
                  },
                },
                '& .MuiInputBase-root.Mui-disabled': {
                  '& > fieldset': {
                    borderLeft: required
                      ? '5px solid #ff3b3b'
                      : '5px solid #555',
                    borderRadius: '0px !important',
                    fontSize: '10px',
                  },
                },
                background: backgroundColor,
              }}
            />
          )}
        />
      </FormControl>
    </div>
  );
};
export default CustomAddDropdown;
