import React from 'react';

// Styles
import { useStyles } from './style';

interface IProps {
  currentPage: number;
  recordsToShow: number;
  searchedDataCount: number;
  allDataCount: number;
  searchText: string | undefined;
  countPages: number;
}

const TableItemsText: React.FC<IProps> = ({
  allDataCount,
  currentPage,
  recordsToShow,
  searchText,
  searchedDataCount,
  countPages,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.showingText}>
      Showing
      {' '}
      {(currentPage - 1) * recordsToShow + 1}
      {' '}
      to
      {' '}
      {countPages === currentPage
        ? (currentPage - 1) * recordsToShow
          + ((searchedDataCount || allDataCount)
            - (currentPage - 1) * recordsToShow)
        : currentPage * recordsToShow}
      {' '}
      of
      {' '}
      {searchText ? searchedDataCount : allDataCount}
      {' '}
      entries
      {' '}
      {searchText && `(filtered from ${allDataCount} total entries)`}
    </div>
  );
};

export default TableItemsText;
