/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Divider,
  Grid,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Close } from '@mui/icons-material';
import { useStyles } from './style';
import CustomInput from '../customInput';
import api from '../../redux/api';

function pad(n: any) {
  return n < 10 ? `0${n}` : n;
}
function splitdash(str: any) {
  let output = str;
  let aux = [];
  if (str?.search('-') !== -1) {
    aux = str?.split('-');
    output = aux?.[aux?.length - 1];
  }
  return { name: output, prefix: str?.split('-')[0] };
}

const setOptionFormat = (stepData: any) =>
  (stepData || [{ value: '', label: 'No Data Available' }]);
const SupplierFromRecord = ({
  handleInputChange,
  valuesToSend,
  handleInputProductChange,
  prefixProducts,
  prefix,
  removeFormFields,
  itemKey,
  products,
  review,
}: any) => {
  const classes = useStyles();
  const [productsList, setProductsList] = useState<any>(products);
  const [FirstRow, setFirstRow] = useState<any>([]);
  const [SecondRow, setSecondRow] = useState<any>([]);
  const [baysOptions, setBaysOptions] = useState<any>([]);
  const [OptionsPresets, setOptionsPresets] = useState<any>([]);
  const [OptionsMeters, setOptionsMeters] = useState<any>([]);
  const [subProducts, setSubProducts] = useState<any>([]);
  const getBaysformSupplier = async () => {
    try {
      const response = await api.get(`meterproducthierarchy?termid=B344&productfieldid=${valuesToSend?.[itemKey]?.prod_id_from}&type=bay`);
      setBaysOptions(Object.entries(response.data?.[0])?.map((val: any) => ({
        value: val[0],
        label: val[1],
      })));
    } catch (error) {
      console.log('err', error);
    }
  };
  const getPresetsformSupplier = async () => {
    try {
      const response = await api.get(`meterproducthierarchy?termid=B344&productfieldid=${valuesToSend?.[itemKey]?.prod_id_from}&bayfieldid=${valuesToSend?.[itemKey]?.ld_bay_from}&type=preset`);
      setOptionsPresets(Object.entries(response.data?.[0])?.map((val: any) => ({
        value: val[0],
        label: val[1],
      })));
    } catch (error) {
      console.log('err', error);
    }
  };
  const getMetersformSupplier = async () => {
    try {
      const response = await api.get(`meterproducthierarchy?termid=B344&productfieldid=${valuesToSend?.[itemKey]?.prod_id_from}&bayfieldid=${valuesToSend?.[itemKey]?.ld_bay_from}&presetfieldid=${valuesToSend?.[itemKey]?.ld_ctl_from}&type=meter`);
      setOptionsMeters(Object.entries(response.data?.[0])?.map((val: any) => ({
        value: val[0],
        label: val[1],
      })));
    } catch (error) {
      console.log('err', error);
    }
  };
  useEffect(() => {
    getBaysformSupplier();
    getPresetsformSupplier();
    getMetersformSupplier();
  }, [valuesToSend?.[itemKey]]);
  const selectProducts = async () => {
    try {
      const response: any = await api.post('synajax/procajax',
        {
          queueid: 'TT',
          data: {
            cmd: 'tanktransfer_new',
            term_id: 'B344   ',
            reason_x: '3150',
            direction: '0',
            supplier_no_from: valuesToSend?.[itemKey]?.supplier_no_from || '',
            uom: 'B',
            prefix: '',
            newdetail: 'y',
            fieldlist: null,
            tankDetailListFrom: '[]',
            tankDetailListTo: '[]',
          },
          tagid: null,
          jqxhr: null,
        });
      setFirstRow(response?.data?.[0]?.genBlock?.genFirstRow);
      setSecondRow(response.data?.[0]?.genBlock?.genSecondRow);
    } catch (e: any) {
      console.log('error', e);
    }
  };
  useEffect(() => {
    selectProducts();
  }, [valuesToSend?.[itemKey]?.supplier_no_from]);
  return itemKey ? (
    <div className={classes.container}>
      <div className={classes.tableContainer}>
        <Typography fontFamily="inherit" color="#6172A7">
          Products
        </Typography>
        <Close onClick={() => removeFormFields(itemKey)} />
      </div>
      <Divider sx={{ color: 'blue' }} />
      <div className={valuesToSend?.[itemKey]?.supplier_no_from ? classes.innerContainer : ''}>
        { valuesToSend?.[itemKey]?.supplier_no_from ? (
          <div className={classes.subContainer}>
            <Grid item xs={2} sm={4} md={4} className={classes.product}>
              <Typography
                fontSize="12px"
                fontFamily="inherit"
                sx={{
                  borderBottom: '1px solid #6172A7',
                  padding: '0px 5px',
                  whiteSpace: 'nowrap',
                  color: '#003399',
                }}
              >
                Supplier
              </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={
                        valuesToSend?.[itemKey]?.supplier_no_from || ''
                      }
                name="supplier_no_from"
                disabled={review}
                onChange={(e: any) => handleInputProductChange('supplier_no_from', e, itemKey)}
                size="small"
                sx={{
                  // product id field is wider than the other fields so we need to specify the condition for width
                  minWidth: '100%',
                  margin: '0px 5px',
                  borderRadius: '0px',
                  marginTop: '10px',
                  '& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select':
                          {
                            padding: '0px 8px',
                            border: '1px solid #E4E4E4',
                          },
                }}
              >
                {setOptionFormat(FirstRow?.fieldList?.supplier_no_from?.options)?.map((item: any) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label.replaceAll('&nbsp;', '')}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={2} sm={4} md={4} className={classes.product}>
              <Typography
                fontSize="12px"
                fontFamily="inherit"
                sx={{
                  borderBottom: '1px solid #6172A7',
                  padding: '0px 5px',
                  whiteSpace: 'nowrap',
                  color: '#003399',
                }}
              >
                Tank
              </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={
                        valuesToSend?.[itemKey]?.tank_id_from || ''
                      }
                name="tank_id_from"
                disabled={review}
                onChange={(e: any) => handleInputProductChange('tank_id_from', e, itemKey)}
                size="small"
                sx={{
                  // product id field is wider than the other fields so we need to specify the condition for width
                  minWidth: '100%',
                  margin: '0px 5px',
                  borderRadius: '0px',
                  marginTop: '10px',
                  '& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select':
                          {
                            padding: '0px 8px',
                            border: '1px solid #E4E4E4',
                          },
                }}
              >
                {setOptionFormat(FirstRow?.fieldList?.tank_id_from?.options)?.map((item: any) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label.replaceAll('&nbsp;', '')}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={2} sm={4} md={4} className={classes.product}>
              <Typography
                fontSize="12px"
                fontFamily="inherit"
                sx={{
                  borderBottom: '1px solid #6172A7',
                  padding: '0px 5px',
                  whiteSpace: 'nowrap',
                  color: '#003399',
                }}
              >
                Product
              </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={
                        valuesToSend?.[itemKey]?.prod_id_from || ''
                      }
                name="prod_id_from"
                disabled
                      // label={stepData?.fieldID}
                onChange={(e: any) => handleInputProductChange('prod_id_from', e, itemKey)}
                size="small"
                sx={{
                  // product id field is wider than the other fields so we need to specify the condition for width
                  minWidth: '100%',
                  margin: '0px 5px',
                  borderRadius: '0px',
                  marginTop: '10px',
                  '& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select':
                          {
                            padding: '0px 8px',
                            border: '1px solid #E4E4E4',
                          },
                }}
              >
                {setOptionFormat(FirstRow?.fieldList?.prod_id_from?.options)?.map((item: any) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label.replaceAll('&nbsp;', '')}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={2} sm={4} md={4} className={classes.product}>
              <Typography
                fontFamily="inherit"
                fontSize="12px"
                sx={{
                  borderBottom: '1px solid black',
                  padding: '0px 5px',
                  whiteSpace: 'nowrap',
                  color: '#003399',
                }}
              >
                {' Temp. (°F)'}
              </Typography>
              <div style={{ padding: '0px 0px' }}>
                <CustomInput
                  type="input"
                  label=""
                  val={FirstRow?.fieldList?.temperature_from}
                  required
                  name="temperature_from"
                  defaultValue=""
                  attr={['num']}
                  value={
                          prefixProducts?.[`${itemKey}-temperature_from`
                          ]
                        }
                  isBatch={false}
                  onChange={handleInputChange}
                  size="100%"
                  placeholder=""
                  isDisabled={false}
                  customKey={itemKey}
                  transaction
                  prefix={itemKey}
                  funNamePrefix={`${itemKey}-temperature_from`}
                />
              </div>
            </Grid>
            <Grid item xs={2} sm={4} md={4} className={classes.product}>
              <Typography
                fontFamily="inherit"
                fontSize="12px"
                sx={{
                  borderBottom: '1px solid black',
                  padding: '0px 5px',
                  whiteSpace: 'nowrap',
                  color: '#003399',
                }}
              >
                {' Density (°API)'}
              </Typography>
              <div style={{ padding: '0px 0px' }}>
                <CustomInput
                  type="input"
                  label=""
                  val={FirstRow?.fieldList?.grav_density_from}
                  required
                  name="grav_density_from"
                  defaultValue=""
                  attr={['num']}
                  value={
                  prefixProducts?.[`${itemKey}-grav_density_from`
                  ]
                        }
                  isBatch={false}
                  onChange={handleInputChange}
                  size="100%"
                  placeholder=""
                  isDisabled={false}
                  customKey={itemKey}
                  transaction
                  prefix={itemKey}
                  funNamePrefix={`${itemKey}-grav_density_from`}
                />
              </div>
            </Grid>
            <Grid item xs={2} sm={4} md={4} className={classes.product}>
              <Typography
                fontFamily="inherit"
                fontSize="12px"
                sx={{
                  borderBottom: '1px solid black',
                  padding: '0px 5px',
                  whiteSpace: 'nowrap',
                  color: '#003399',
                }}
              >
                {' Gross (Gal)'}
              </Typography>
              <div style={{ padding: '0px 0px' }}>
                <CustomInput
                  type="input"
                  label=""
                  val={FirstRow?.fieldList?.gross_from}
                  required
                  name="gross_from"
                  defaultValue=""
                  attr={['num']}
                  value={
                    prefixProducts?.[`${itemKey}-gross_from`]
                        }
                  isBatch={false}
                  onChange={handleInputChange}
                  size="100%"
                  placeholder=""
                  isDisabled={false}
                  customKey={itemKey}
                  transaction
                  prefix={itemKey}
                  funNamePrefix={`${itemKey}-gross_from`}
                />
              </div>
            </Grid>
            <Grid item xs={2} sm={4} md={4} className={classes.product}>
              <Typography
                fontFamily="inherit"
                fontSize="12px"
                sx={{
                  borderBottom: '1px solid black',
                  padding: '0px 5px',
                  whiteSpace: 'nowrap',
                  color: '#003399',
                }}
              >
                {' VCF'}
              </Typography>
              <div style={{ padding: '0px 0px' }}>
                <CustomInput
                  type="input"
                  label=""
                  val={FirstRow?.fieldList?.vcf_from}
                  required
                  name="vcf_from"
                  defaultValue=""
                  attr={['num']}
                  value={
                  prefixProducts?.[`${itemKey}-vcf_from`
                  ]
                        }
                  isBatch={false}
                  onChange={handleInputChange}
                  size="100%"
                  placeholder=""
                  isDisabled={false}
                  customKey={itemKey}
                  transaction
                  prefix={itemKey}
                  funNamePrefix={`${itemKey}-vcf_from`}
                />
              </div>
            </Grid>
            <Grid item xs={2} sm={4} md={4} className={classes.product}>
              <Typography
                fontFamily="inherit"
                fontSize="12px"
                sx={{
                  borderBottom: '1px solid black',
                  padding: '0px 5px',
                  whiteSpace: 'nowrap',
                  color: '#003399',
                }}
              >
                {' Net (bbl)'}
              </Typography>
              <div style={{ padding: '0px 0px' }}>
                <CustomInput
                  type="input"
                  label=""
                  val={FirstRow?.fieldList?.net_from}
                  required
                  name="net_from"
                  defaultValue=""
                  attr={['num']}
                  value={
                  prefixProducts?.[`${itemKey}-net_from`
                  ]
                        }
                  isBatch={false}
                  onChange={handleInputChange}
                  size="100%"
                  placeholder=""
                  isDisabled={false}
                  customKey={itemKey}
                  transaction
                  prefix={itemKey}
                  funNamePrefix={`${itemKey}-net_from`}
                />
              </div>
            </Grid>
            <Grid item xs={2} sm={4} md={4} className={classes.product}>
              <Typography
                fontFamily="inherit"
                fontSize="12px"
                sx={{
                  borderBottom: '1px solid black',
                  padding: '0px 5px',
                  whiteSpace: 'nowrap',
                  color: '#003399',
                }}
              >
                {' Weight (lb)'}
              </Typography>
              <div style={{ padding: '0px 0px' }}>
                <CustomInput
                  type="input"
                  label=""
                  val={FirstRow?.fieldList?.netBase_from}
                  required
                  name="netBase_from"
                  defaultValue=""
                  attr={['num']}
                  value={
                  prefixProducts?.[`${itemKey}-netBase_from`
                  ]
                        }
                  isBatch={false}
                  onChange={handleInputChange}
                  size="100%"
                  placeholder=""
                  isDisabled={false}
                  customKey={itemKey}
                  transaction
                  prefix={itemKey}
                  funNamePrefix={`${itemKey}-netBase_from`}
                />
              </div>
            </Grid>
          </div>
        ) : (
          <div style={{
            alignSelf: 'flex-start',
            width: '100%',
          }}
          >
            <Grid item xs={2} sm={4} md={4} className={classes.product}>
              <Typography
                fontSize="12px"
                fontFamily="inherit"
                sx={{
                  borderBottom: '1px solid #6172A7',
                  padding: '0px 5px',
                  whiteSpace: 'nowrap',
                  color: '#003399',
                }}
              >
                Supplier
              </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={
                        valuesToSend?.[itemKey]?.supplier_no_from || ''
                      }
                name="supplier_no_from"
                disabled={review}
                      // label={stepData?.fieldID}
                onChange={(e: any) => handleInputProductChange('supplier_no_from', e, itemKey)}
                size="small"
                sx={{
                // product id field is wider than the other fields so we need to specify the condition for width
                  minWidth: '10%',
                  margin: '0px 5px',
                  borderRadius: '0px',
                  marginTop: '10px',
                  '& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select':
                          {
                            padding: '0px 8px',
                            border: '1px solid #E4E4E4',
                          },
                }}
              >
                {setOptionFormat(FirstRow?.fieldList?.supplier_no_from?.options)?.map((item: any) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label.replaceAll('&nbsp;', '')}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </div>
        ) }
      </div>
      <Divider sx={{ color: 'blue' }} />
      <div className={classes.innerContainer}>
        { valuesToSend?.[itemKey]?.supplier_no_from ? (
          <div className={classes.secondRowSubContainer}>
            <Grid item xs={2} sm={4} md={4} className={classes.product}>
              <Typography
                fontSize="12px"
                fontFamily="inherit"
                sx={{
                  borderBottom: '1px solid #6172A7',
                  padding: '0px 5px',
                  whiteSpace: 'nowrap',
                  color: '#003399',
                }}
              >
                Bay
              </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={
                        valuesToSend?.[itemKey]?.ld_bay_from || ''
                      }
                name="ld_bay_from"
                disabled={review}
                onChange={(e: any) => handleInputProductChange('ld_bay_from', e, itemKey)}
                size="small"
                sx={{
                  // product id field is wider than the other fields so we need to specify the condition for width
                  minWidth: '100%',
                  margin: '0px 5px',
                  borderRadius: '0px',
                  marginTop: '10px',
                  '& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select':
                          {
                            padding: '0px 8px',
                            border: '1px solid #E4E4E4',
                          },
                }}
              >
                {baysOptions?.map((item: any) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label.replaceAll('&nbsp;', '')}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={2} sm={4} md={4} className={classes.product}>
              <Typography
                fontSize="12px"
                fontFamily="inherit"
                sx={{
                  borderBottom: '1px solid #6172A7',
                  padding: '0px 5px',
                  whiteSpace: 'nowrap',
                  color: '#003399',
                }}
              >
                Preset
              </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={
                        valuesToSend?.[itemKey]?.ld_ctl_from || ''
                      }
                name="ld_ctl_from"
                disabled={review}
                onChange={(e: any) => handleInputProductChange('ld_ctl_from', e, itemKey)}
                size="small"
                sx={{
                  // product id field is wider than the other fields so we need to specify the condition for width
                  minWidth: '100%',
                  margin: '0px 5px',
                  borderRadius: '0px',
                  marginTop: '10px',
                  '& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select':
                          {
                            padding: '0px 8px',
                            border: '1px solid #E4E4E4',
                          },
                }}
              >
                {OptionsPresets?.map((item: any) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label.replaceAll('&nbsp;', '')}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={2} sm={4} md={4} className={classes.product}>
              <Typography
                fontSize="12px"
                fontFamily="inherit"
                sx={{
                  borderBottom: '1px solid #6172A7',
                  padding: '0px 5px',
                  whiteSpace: 'nowrap',
                  color: '#003399',
                }}
              >
                Meter
              </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={
                        valuesToSend?.[itemKey]?.ld_mtr_from || ''
                      }
                name="ld_mtr_from"
                disabled={review}
                onChange={(e: any) => handleInputProductChange('ld_mtr_from', e, itemKey)}
                size="small"
                sx={{
                  // product id field is wider than the other fields so we need to specify the condition for width
                  minWidth: '100%',
                  margin: '0px 5px',
                  borderRadius: '0px',
                  marginTop: '10px',
                  '& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select':
                          {
                            padding: '0px 8px',
                            border: '1px solid #E4E4E4',
                          },
                }}
              >
                {OptionsMeters?.map((item: any) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label.replaceAll('&nbsp;', '')}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={2} sm={4} md={4} className={classes.product}>
              <Typography
                fontSize="12px"
                fontFamily="inherit"
                sx={{
                  borderBottom: '1px solid #6172A7',
                  padding: '0px 5px',
                  whiteSpace: 'nowrap',
                  color: '#003399',
                }}
              >
                ERP HT
              </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={
                        valuesToSend?.[itemKey]?.ERPHandlingType_from || ''
                      }
                name="ERPHandlingType_from"
                disabled={review}
                onChange={(e: any) => handleInputProductChange('ERPHandlingType_from', e, itemKey)}
                size="small"
                sx={{
                  // product id field is wider than the other fields so we need to specify the condition for width
                  minWidth: '100%',
                  margin: '0px 5px',
                  borderRadius: '0px',
                  marginTop: '10px',
                  '& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select':
                          {
                            padding: '0px 8px',
                            border: '1px solid #E4E4E4',
                          },
                }}
              >
                {setOptionFormat(SecondRow?.ERPHandlingType_from?.options)?.map((item: any) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label.replaceAll('&nbsp;', '')}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={2} sm={4} md={4} className={classes.product}>
              <Typography
                fontSize="12px"
                fontFamily="inherit"
                sx={{
                  borderBottom: '1px solid #6172A7',
                  padding: '0px 5px',
                  whiteSpace: 'nowrap',
                  color: '#003399',
                }}
              >
                Duty To Party
              </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={
                        valuesToSend?.[itemKey]?.dutytoparty_from || ''
                      }
                name="dutytoparty_from"
                disabled={review}
                onChange={(e: any) => handleInputProductChange('dutytoparty_from', e, itemKey)}
                size="small"
                sx={{
                  // product id field is wider than the other fields so we need to specify the condition for width
                  minWidth: '100%',
                  margin: '0px 5px',
                  borderRadius: '0px',
                  marginTop: '10px',
                  '& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select':
                          {
                            padding: '0px 8px',
                            border: '1px solid #E4E4E4',
                          },
                }}
              >
                {setOptionFormat(SecondRow?.dutytoparty_from?.options)?.map((item: any) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label.replaceAll('&nbsp;', '')}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={2} sm={4} md={4} className={classes.product}>
              <Typography
                fontFamily="inherit"
                fontSize="12px"
                sx={{
                  borderBottom: '1px solid black',
                  padding: '0px 5px',
                  whiteSpace: 'nowrap',
                  color: '#003399',
                }}
              >
                Ticket
              </Typography>
              <div style={{ padding: '0px 0px' }}>
                <CustomInput
                  type="input"
                  label=""
                  val={SecondRow?.ticket_no_from}
                  required
                  name="ticket_no_from"
                  defaultValue=""
                  attr={['num']}
                  value={
                          prefixProducts?.[itemKey]?.[
                            SecondRow?.ticket_no_from
                          ]
                        }
                  isBatch={false}
                  onChange={handleInputChange}
                  size="100%"
                  placeholder=""
                  isDisabled={false}
                  customKey={itemKey}
                  transaction
                  prefix={prefix}
                  funNamePrefix={`${prefix}-ticket_no_from`}
                />
              </div>
            </Grid>
          </div>
        ) : (<></>)}
      </div>
    </div>
  ) : (<></>);
};

export default SupplierFromRecord;
