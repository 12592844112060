import React from 'react';

// Libraries
import { useParams } from 'react-router-dom';
import DetailScreen from './detailScreen';

const DocumentBuilderDetail: React.FC = () => {
  const { DocumentName, DocumentNameToClone } = useParams();
  // Return the detail screen
  if (DocumentName) {
    return <DetailScreen DocumentName={DocumentName} />;
  }

  // Return the clone screen
  if (DocumentNameToClone) {
    return <DetailScreen mode="Clone" DocumentNameToClone={DocumentNameToClone} />;
  }

  // Return the create screen
  return <DetailScreen mode="New" />;
};

export default DocumentBuilderDetail;
