import React, { useEffect, useRef } from 'react';

// Libraries
import {
  ButtonBase, DialogActions, Divider,
} from '@mui/material';

// Components

// Styles
import { useStyles } from './style';
import { DialogComponent } from '../../Dialog/Dialog.component';
import AlarmHistoryTable from '../AlarmHistoryTable';

interface IProps {
  onClose: () => void;
  open: boolean;
  title:string,
}

const AlarmHistoryModal: React.FC<IProps> = ({
  onClose,
  open,
  title,
}) => {
  const classes = useStyles();

  return (
    <DialogComponent
      title={title}
      onClose={onClose}
      open={open}
    >
      <AlarmHistoryTable
        headCells={['Alarm Time', 'Preset Description', 'Alarm Code', 'Alarm Description']}
        rows={[]}
        pages={10}
        tableOpt={undefined}
        routeDetails=""
        screenID={undefined}
        backScreen=""
      />
      <Divider variant="middle" />
      <DialogActions style={{ overflowY: 'auto' }}>
        <ButtonBase classes={{ root: classes.closeButton }} onClick={onClose}>
          Close
        </ButtonBase>
      </DialogActions>
    </DialogComponent>
  );
};

export default AlarmHistoryModal;
