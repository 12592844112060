/* eslint-disable react/require-default-props */
import React from 'react';

// Librairies
import { InputBase } from '@mui/material';

// Styles
import clsx from 'clsx';
import { useStyles } from './style';

interface ISelectProps {
  value?: string | number | null;
  style?: React.CSSProperties;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
  required?: boolean;
  type?: string;
  disabled?: boolean;
  placeholder?: string;
  name?: string;
  maxLength?: number;
  minLength?: number;
}

const Select: React.FC<ISelectProps> = ({
  value,
  style,
  onChange,
  onClick,
  onBlur,
  required,
  type,
  disabled,
  placeholder,
  name,
  maxLength,
  minLength,
}) => {
  const classes = useStyles();
  return (
    <InputBase
      onBlur={onBlur}
      onClick={onClick}
      onChange={onChange}
      classes={{
        root: required
          ? clsx(classes.inputRequired, !value ? classes.borderRequired : classes.border)
          : classes.input,
      }}
      value={value}
      style={{ ...style, backgroundColor: disabled ? '#F4F4F4' : undefined }}
      type={type || 'text'}
      disabled={disabled}
      placeholder={placeholder}
      name={name}
      componentsProps={{ input: { maxLength, minLength } }}
      endAdornment={
        maxLength ? (
          <span>
            {(value as string)?.length}
            /
            {maxLength}
          </span>
        ) : null
      }
    />
  );
};

export default Select;
