import {
  FETCH_ACCOUNTS,
  FETCH_CUSTOMERS,
  FETCH_SUPPLIERS,
  CLEAR_VSX_FILTERS,
  FETCH_DESTINATIONS,
} from '../constants/Vsx';

const INITIAL_STATE = {
  suppliers: [],
  customers: [],
  accounts: [],
  destinations: [],
};

const vsxReducer = (state = INITIAL_STATE, { type, payload }: any) => {
  switch (type) {
    case FETCH_SUPPLIERS: {
      return {
        ...state,
        suppliers: payload,
      };
    }
    case FETCH_ACCOUNTS: {
      return {
        ...state,
        accounts: payload,
      };
    }
    case FETCH_CUSTOMERS: {
      return {
        ...state,
        customers: payload,
      };
    }
    case FETCH_DESTINATIONS: {
      return {
        ...state,
        destinations: payload,
      };
    }
    case CLEAR_VSX_FILTERS: {
      return state;
    }
    default:
      return state;
  }
};

export default vsxReducer;
