/* eslint-disable no-nested-ternary */
import React from 'react';

// Librairies
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';

// Styles
import { useStyles } from './style';

// Utils
const tableHeader = [
  { label: 'Status', key: 'Status' },
  { label: 'Line', key: 'PostedName' },
  { label: 'State', key: 'State' },
  { label: 'Arguments', key: 'Arguments' },
];

interface IProps {
  handleClickRow: (row: any) => void;
  data: any[];
}

const SystemStartupTable: React.FC<IProps> = ({ handleClickRow, data }) => {
  const classes = useStyles();

  const displayCellValue = (row: any, field: any): string => {
    if (field.key === 'State') {
      return row.State === 'E' ? 'Enabled' : 'Masked';
    }
    return row[field.key];
  };

  return (
    <div className={classes.root}>
      <Table classes={{ root: classes.tableRoot }}>
        <TableHead>
          <TableRow classes={{ root: classes.headerRow }}>
            {tableHeader.map((h) => (
              <TableCell classes={{ root: classes.headerCell }} key={h.key}>
                {h.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row) => (
            <TableRow
              onClick={() => handleClickRow(row)}
              className={classes.tableRow}
              key={row.StartUpOrder}
            >
              {tableHeader.map((field) => (field.key === 'Status' ? (
                <TableCell
                  key={field.key}
                  classes={{ root: classes.bodyCellEmpty }}
                >
                  <div
                    style={{
                      width: 20,
                      height: 20,
                      backgroundColor:
                          row.State === 'E' ? '#32CD32' : '#A9A9A9',
                    }}
                  />
                </TableCell>
              ) : (
                <TableCell
                  classes={{ root: classes.bodyCell }}
                  key={field.key}
                >
                  {displayCellValue(row, field)}
                </TableCell>
              )))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default React.memo(SystemStartupTable);
