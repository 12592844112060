/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import {
  ButtonBase,
  InputBase,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Alert,
  IconButton,
  Typography,
  Snackbar,
} from '@mui/material';
import {
  Remove,
  Add,
  ArrowDropDown,
  ArrowDropUp,
  UnfoldMore,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
// Styles

import SubHeader from '../../../components/subHeader';
import TmsHeader from '../../../components/header';
import { getScreensList } from '../../../redux/actions/ScreenActions';
import ControlButton from '../../../components/controlButton';
import Select from '../../../components/Select';
import TableItemsText from '../../../components/TableItemsText';
import Pagination from '../../../components/Pagination';
import { getRecordToShowValue, sortedTable } from '../../../utils';
import ConfirmationModal from '../../../components/confirmationModal/index';

// constants
import {
  CLEAR_ORDER_MAINTENANCE,
} from '../../../redux/constants/workflows';

// actions
import {
  dissmissConfirmationAlert,
  removeOrderMaintenanceRecord,
} from '../../../redux/actions/workflows/orderMaintenance';
import OrderMaintenanceTransitionModal from './OrderMaintenanceTransitionModal';
import { useStyles } from './style';

const OrderMaintenanceBatchModify = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    totalRecords,
    openConfirmationAlert,
    confirmationAlert,
    selectedRecord,
    totalRecordsWithSearch,
    batchMsg,
  } = useSelector((state: any) => state.Workflows.OrderMaintenanceReducer);
  const [Visible, setVisible] = React.useState(false);

  const handleCloseConfirm = () => {
    setVisible(!Visible);
  };

  const handleBackButton = () => {
    navigate('/workflow/order-maintenance');
  };
  const handleRemoveAllButton = () => {
    setVisible(true);
  };
  const handleClose = () => {
    dispatch(dissmissConfirmationAlert());
  };

  useEffect(() => {
    if (batchMsg) {
      dispatch({ type: CLEAR_ORDER_MAINTENANCE });
      navigate('/workflow/order-maintenance');
    }
  }, [batchMsg]);

  const handleSelectChange = (e: any, option: any) => {
    //
  };
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //
  };

  return (
    <>
      <TmsHeader />
      <SubHeader title="Order Maintenance" />
      <div style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        gap: '10px',
        padding: '10px',
      }}
      >
        <ControlButton
          color="#ff4444"
          label="Next"
          handleClick={handleRemoveAllButton}
          disabled={false}
        />
        <ControlButton
          color="#ffbb33"
          label="Cancel"
          handleClick={handleBackButton}
          disabled={false}
        />

      </div>

    </>
  );
};

export default OrderMaintenanceBatchModify;
