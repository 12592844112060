/* eslint-disable max-len */
/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react';

// Librairies
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

// Components
import ButtonBase from '@mui/material/ButtonBase';
import {
  Divider,
} from '@mui/material';
import {
  Apps,
} from '@mui/icons-material';
import clsx from 'clsx';
import { useStyles } from './style';
import TmsHeader from '../../../components/header';
import SubHeader from '../../../components/subHeader';
import { Canvas } from '../../../components/documentBuilderDetailCanvas';
import Select from '../../../components/Select';
import Input from '../../../components/Input';
import DocumentBuilderMenuOptions from '../../../components/documentBuilderDetailMenu';
import OptionsButton from '../../../components/optionsButton';

// Actions
import { getScreensList } from '../../../redux/actions/ScreenActions';
import {
  clearExportDoc2PkgDocumentBuilder,
  exportDoc2PkgDocumentBuilder,
  getDocumentBuilderDetails,
} from '../../../redux/actions/workflows/documentBuilder';
import ExpandableList, { ListItem } from '../../../components/documentBuilderExpandableList';

interface DetailProps {
    mode?: string;
    DocumentName?: string;
    DocumentNameToClone?: string;
}

const DetailScreen: React.FC<DetailProps> = ({ mode, DocumentName, DocumentNameToClone }) => {
  const [showGrid, setShowGrid] = useState(true);
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const [outlineValues, setOutlineValues] = useState<ListItem[]>([]);
  const [detailValues, setDetailValues] = useState<any>({});
  const [disabledDetailKeys, setDisabledDetailKeys] = useState<string[]>([]);
  const [selectedChildToAdd, setSelectedChildToAdd] = useState<any>(null);
  const [selectedEntryId, setSelectedEntryId] = useState<any>(null);
  const [selectedGroupId, setSelectedGroupId] = useState<any>(null);

  const [groupNameToAdd, setGroupNameToAdd] = useState<string>('');
  const [entryNameToAdd, setEntryNameToAdd] = useState<string>('');
  const [typeOfChildToAdd, setTypeOfChildToAdd] = useState<string>('entry');
  const [itemsTemplates, setItemTemplates] = useState<any>({});

  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    docBuilderData,
    fileBlob,
    fileName,
  } = useSelector(
    (state: any) => state?.Workflows?.DocumentBuilder,
  );

  const [detailsData, setDetailsData] = useState(docBuilderData);

  useEffect(() => {
    dispatch(getScreensList());
  }, []);

  useEffect(() => {
    if (DocumentName) {
      dispatch(getDocumentBuilderDetails({ docName: DocumentName, isEditMode: true, isCloneMode: false }));
    }
    if (mode === 'Clone' && DocumentNameToClone) {
      dispatch(getDocumentBuilderDetails({ docName: DocumentNameToClone, isEditMode: false, isCloneMode: true }));
    }
  }, [DocumentName, mode, DocumentNameToClone]);

  function parseTree(nodes: any, topLevelLabels: any, parentKey?: string): ListItem[] {
    const newSchema: ListItem[] = [];
    Object.keys(nodes).map((key: any) => {
      if (nodes[key]) {
        const item = nodes[key];

        if (Object.prototype.toString.call(item) === '[object Object]') {
          const newItem: ListItem = {
            id: key,
            title: topLevelLabels[key] || key,
            type: 'group',
          };
          let innerGroups: ListItem[] = [];
          Object.keys(item).map((nestKey: any) => {
            if (item[nestKey]) {
              const newGroupItem: ListItem = {
                id: `${key}$${nestKey}`,
                title: topLevelLabels[nestKey] || nestKey,
                type: 'group',
              };
              newGroupItem.items = parseTree(item[nestKey], null, `${key}$${nestKey}`);
              innerGroups.push(newGroupItem);
            }
            return true;
          });
          newItem.items = innerGroups;
          innerGroups = [];
          newSchema.push(newItem);
        }
        if (Array.isArray(item) && Object.prototype.toString.call(item) === '[object Array]') {
          const newItem: ListItem = {
            id: key,
            title: topLevelLabels[key] || key,
            type: 'group',
          };
          const innerEntries: ListItem[] = [];
          item.map((element: any) => {
            const newEntry: ListItem = {
              id: `${parentKey ?? key}$${element}`,
              title: element,
              type: 'entry',
            };
            innerEntries.push(newEntry);
            return true;
          });
          newItem.items = innerEntries;
          newSchema.push(newItem);
        }
        if (Array.isArray(item) && Object.prototype.toString.call(item) === '[object String]') {
          item.map((element: string, elementIndex: any) => {
            const newItem: ListItem = {
              id: `${elementIndex}$${element}`,
              title: element,
              type: 'entry',
            };
            newSchema.push(newItem);
            return true;
          });
        }

        if (typeof item === 'string') {
          const newItem: ListItem = {
            id: `${parentKey ?? key}$${item}`,
            title: item,
            type: 'entry',
          };
          newSchema.push(newItem);
        }
      }
      return true;
    });
    return newSchema;
  }

  function parseTemplates(nodes: any) {
    let templatesSchema = {};
    let doc2BoxGroup = {};
    let doc2BoxEntry = {};
    let doc2ConditionEntry = {};
    let doc2PDFEntry = {};
    let doc2DriverSignatureEntry = {};
    let doc2RoutingEntry = {};
    let doc2MetadataEntry = {};

    // Doc2Box group template
    if (nodes.Doc2Box?.length > 0) {
      doc2BoxGroup = Object.keys(nodes.Doc2Box[0] ?? {})
        .reduce((acc:any, field:any) => ({ ...acc, [field]: '' }), []);
    }
    if (nodes.fields?.length > 0) {
      doc2BoxEntry = Object.keys(nodes.fields[0] ?? {})
        .reduce((acc:any, field:any) => ({ ...acc, [field]: '' }), []);
    }
    if (nodes.Doc2Condition?.length > 0) {
      doc2ConditionEntry = Object.keys(nodes.Doc2Condition[0] ?? {})
        .reduce((acc:any, field:any) => ({ ...acc, [field]: '' }), []);
    }
    if (nodes.Doc2PDF?.length > 0) {
      doc2PDFEntry = Object.keys(nodes.Doc2PDF[0] ?? {})
        .reduce((acc:any, field:any) => ({ ...acc, [field]: '' }), []);
    }
    if (nodes.Doc2DriverSignature?.length > 0) {
      doc2DriverSignatureEntry = Object.keys(nodes.Doc2DriverSignature[0] ?? {})
        .reduce((acc:any, field:any) => ({ ...acc, [field]: '' }), []);
    }
    if (nodes.Doc2Routing?.length > 0) {
      doc2RoutingEntry = Object.keys(nodes.Doc2Routing[0] ?? {})
        .reduce((acc:any, field:any) => ({ ...acc, [field]: '' }), []);
    }
    if (nodes.Doc2Metadata?.length > 0) {
      doc2MetadataEntry = Object.keys(nodes.Doc2Metadata[0] ?? {})
        .reduce((acc:any, field:any) => ({ ...acc, [field]: '' }), []);
    }

    templatesSchema = {
      ...templatesSchema,
      Doc2Box: {
        group: doc2BoxGroup,
        entry: doc2BoxEntry,
      },
      Doc2Condition: {
        entry: doc2ConditionEntry,
      },
      Doc2PDF: {
        entry: doc2PDFEntry,
      },
      Doc2DriverSignature: {
        entry: doc2DriverSignatureEntry,
      },
      Doc2Routing: {
        entry: doc2RoutingEntry,
      },
      Doc2Metadata: {
        entry: doc2MetadataEntry,
      },
    };
    return templatesSchema;
  }

  useEffect(() => {
    // parse Outline Tree into the state
    if (detailsData) {
      const Tree = detailsData.Tree || {};
      const topLevelLabels = detailsData.header?.SelectDocumentChildToAdd
        .reduce((acc: any, item: any) => ({ ...acc, [item.value]: item.label }), {});

      const result = parseTree(Tree, topLevelLabels);
      const templates = parseTemplates(detailsData);
      setItemTemplates(templates);
      setOutlineValues(result);
      setDisabledDetailKeys([
        'BoxName',
        'ConditionName',
        'BackdropName',
        'DriverSigNumber',
        'SourceRouteCode',
        'MetadataName',
        'FieldName',
        'DocName',
      ]);
    }
  }, [detailsData]);

  const toggleCanvasGrid = () => {
    setShowGrid(!showGrid);
  };

  const handleOptionClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleExportDocument = () => {
    if (detailsData.header?.Document?.value) {
      dispatch(exportDoc2PkgDocumentBuilder(detailsData.header?.Document?.value));
    }
  };

  useEffect(() => {
    const downloadFile = () => {
      try {
        const downloadUrl = window.URL.createObjectURL(new Blob([fileBlob], { type: 'application/octet-stream' }));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        dispatch(clearExportDoc2PkgDocumentBuilder());
      } catch (error: any) {
        console.error('downloadFile', error);
      }
    };
    if (fileBlob && fileName) { downloadFile(); }
  }, [fileBlob]);

  const handleCloseMenu = () => {
    setAnchorElement(null);
  };

  const handleSelectDocument = (e: any, option: any) => {
    //
  };

  const handleSelectChildToAdd = (e: any, option: any) => {
    if (option === 'Doc2Box') {
      setTypeOfChildToAdd('entry');
    } else {
      setTypeOfChildToAdd('group');
    }
    setSelectedChildToAdd(option);
  };

  const handleGroupNameToAddChange = (e: any) => {
    setGroupNameToAdd(e.target.value);
  };

  const handleEntryNameToAddChange = (e: any) => {
    setEntryNameToAdd(e.target.value);
  };

  function isOutlineItemNameValid(name: string): boolean {
    return name === '';
  }

  // Outline detail handlers
  function updateOutlineDetailGroup(concatenatedId: string) {
    const idsInTree = concatenatedId.split('$');
    const firstLevel = idsInTree[0];
    const secondLevel = idsInTree[1];
    if (firstLevel && secondLevel) {
      let groupDetail = {};
      if (firstLevel === 'Doc2Box') {
        groupDetail = detailsData[firstLevel]?.find((entry: any) => entry.BoxName === secondLevel);
      } else if (firstLevel === 'Doc2Condition') {
        groupDetail = detailsData[firstLevel]?.find((entry: any) => entry.ConditionName === secondLevel);
      } else if (firstLevel === 'Doc2PDF') {
        groupDetail = detailsData[firstLevel]?.find((entry: any) => entry.BackdropName === secondLevel);
      } else if (firstLevel === 'Doc2DriverSignature') {
        groupDetail = detailsData[firstLevel]?.find((entry: any) => entry.DriverSigNumber === secondLevel);
      } else if (firstLevel === 'Doc2Routing') {
        groupDetail = detailsData[firstLevel]?.find((entry: any) => entry.SourceRouteCode === secondLevel);
      } else if (firstLevel === 'Doc2Metadata') {
        groupDetail = detailsData[firstLevel]?.find((entry: any) => entry.MetadataName === secondLevel);
      }
      setDetailValues(groupDetail);
    }
  }

  function updateOutlineDetailEntry(concatenatedId: string) {
    const idsInTree = concatenatedId.split('$');
    const firstLevel = idsInTree[0];
    const secondLevel = idsInTree[1];
    const thirdLevel = idsInTree[2];
    let groupDetail = {};
    if (firstLevel && secondLevel) {
      if (firstLevel === 'Doc2Box' && thirdLevel) {
        groupDetail = detailsData.fields?.find((entry: any) => entry.BoxName === secondLevel && entry.FieldName === thirdLevel);
      } else if (firstLevel === 'Doc2Condition') {
        groupDetail = detailsData[firstLevel]?.find((entry: any) => entry.ConditionName === secondLevel);
      } else if (firstLevel === 'Doc2PDF') {
        groupDetail = detailsData[firstLevel]?.find((entry: any) => entry.BackdropName === secondLevel);
      } else if (firstLevel === 'Doc2DriverSignature') {
        groupDetail = detailsData[firstLevel]?.find((entry: any) => entry.DriverSigNumber === secondLevel);
      } else if (firstLevel === 'Doc2Routing') {
        groupDetail = detailsData[firstLevel]?.find((entry: any) => entry.SourceRouteCode === secondLevel);
      } else if (firstLevel === 'Doc2Metadata') {
        groupDetail = detailsData[firstLevel]?.find((entry: any) => entry.MetadataName === secondLevel);
      } else if (firstLevel === 'definition') {
        groupDetail = detailsData.definition;
      }
      setDetailValues(groupDetail);
    }
  }

  const onAddGroupToOutline = () => {
    const itemToAdd = outlineValues.filter((item: any) => item.id === selectedChildToAdd?.value)[0];
    if (itemToAdd) {
      const newItem: ListItem = {
        id: `${uuidv4()}$${selectedChildToAdd?.value}`,
        title: groupNameToAdd,
        type: 'group',
      };
      itemToAdd?.items?.push(newItem);
    }

    const updatedItems = outlineValues.map((item: any) => {
      if (item.id === itemToAdd.id) {
        return itemToAdd;
      }
      return item;
    });

    setOutlineValues(updatedItems);
  };

  const onAddEntryToOutline = (groupId: string) => {
    let newId = '';
    const newOutlineData = [...outlineValues];
    const newDetailData = { ...detailValues };

    // item is an entry of a sub-group
    if (groupId.includes('Doc2Box$')) {
      const doc2BoxIndex = newOutlineData.findIndex((item) => item.id === 'Doc2Box');
      if (doc2BoxIndex !== -1) {
        const bolMessagingIndex = newOutlineData[doc2BoxIndex].items?.findIndex(
          (item) => item.id === groupId,
        );
        if (bolMessagingIndex !== undefined && bolMessagingIndex !== -1) {
          newId = `${groupId}$${entryNameToAdd.trim()}`;
          const newEntry: ListItem = {
            id: newId,
            title: entryNameToAdd,
            type: 'entry',
          };
          newOutlineData[doc2BoxIndex].items?.[bolMessagingIndex].items?.push(newEntry);
          setOutlineValues(newOutlineData);
        }
      }
    } else {
      // item is an entry
      const itemIndex = newOutlineData.findIndex((item) => item.id === groupId.split('$')[0]);
      if (itemIndex !== -1) {
        newId = `${groupId}$${entryNameToAdd.trim()}`;
        const newEntry: ListItem = {
          id: newId,
          title: entryNameToAdd,
          type: 'entry',
        };
        newOutlineData[itemIndex].items?.push(newEntry);
        setOutlineValues(newOutlineData);
      }
    }

    // add the new item data to
    if (newId.includes('$')) {
      const newItemContents = { ...itemsTemplates[newId.split('$')[0]].entry };
      setDetailsData((prev: any) => ({
        ...prev,
        [newId.split('$')[0]]: [
          ...prev[newId.split('$')[0]],
          newItemContents,
        ],
      }));
      // update the detail view to show the added item
      setDetailValues(newItemContents);
    }
  };

  // Outline handlers
  function deleteElement(element: ListItem, elements: ListItem[]) {
    for (let i = 0; i < elements.length; i += 1) {
      const currentElement = elements[i];

      if (currentElement.id === element.id && currentElement.type === element.type) {
        elements.splice(i, 1);
        return true;
      }

      if (currentElement.items) {
        if (deleteElement(element, currentElement.items)) {
          return true;
        }
      }
    }
    return false;
  }

  const handleSelectEntry = (item: any) => {
    setSelectedEntryId(item);
    updateOutlineDetailEntry(item);
    setSelectedGroupId(null);
  };

  const handleSelectGroup = (item: any) => {
    setSelectedGroupId(item);
    updateOutlineDetailGroup(item);
    setSelectedEntryId(null);
  };

  const handleDeleteEntry = (item: any) => {
    if (selectedEntryId && item.id === selectedEntryId) {
      setSelectedEntryId(null);
    }
    deleteElement(item, outlineValues);
  };

  const handleDeleteGroup = (item: any) => {
    if (item.id === selectedGroupId) {
      setSelectedEntryId(null);
    }
    deleteElement(item, outlineValues);
  };

  return (
    <div style={{ height: '100%' }}>
      <TmsHeader />
      <SubHeader title="Document Builder : Detail">
        <OptionsButton handleClick={handleOptionClick} />
        <DocumentBuilderMenuOptions
          anchorEl={anchorElement}
          onClose={handleCloseMenu}
          openCloneScreen={() => navigate(`Clone/${DocumentName?.trim()}`)}
          openGenerateDocument={() => null}
          openExportDocument={handleExportDocument}
        />
      </SubHeader>
      <div className={classes.buttonsContainer}>
        <div>
          {mode === 'New' && (
            <ButtonBase
              className={classes.saveButton}
            >
              Create
            </ButtonBase>
          )}
          {mode === 'Clone' && (
            <ButtonBase
              className={classes.saveButton}
            >
              Clone
            </ButtonBase>
          )}
          {!mode && (
            <ButtonBase
              className={classes.saveButton}
            >
              Save
            </ButtonBase>
          )}
          <ButtonBase className={classes.cancelButton} onClick={() => navigate(-1)}>
            Cancel
          </ButtonBase>
          {!mode && (
            <ButtonBase className={classes.removeButton}>
              Remove
            </ButtonBase>
          )}
        </div>
        <ButtonBase
          className={classes.showHideGridButton}
          onClick={toggleCanvasGrid}
        >
          <Apps style={{ height: '80%' }} />
          <span className={classes.showHideGridLabel}>
            Show / Hide Grid
          </span>
        </ButtonBase>
      </div>
      <Divider className={classes.divider} />

      <div
        className={clsx('takeTheRest', classes.contentsContainer)}
      >
        <div
          id="outlineMenu"
          className={classes.outlineMenuContainer}
        >
          <div className={classes.header}>
            <div className={classes.elementContainer}>
              <div
                className={clsx(classes.inputLabel, classes.layoutLabel)}
              >
                Document
              </div>
              <Select
                options={detailsData.header?.Document || []}
                value={detailsData.header?.Document?.value || ''}
                onChange={handleSelectDocument}
                style={{
                  width: '75%',
                }}
                disableClearInput
                required
                disabled
              />
            </div>
            <div className={classes.elementContainer}>
              <div
                className={clsx(classes.inputLabel, classes.layoutLabel)}
              >
                Select Document Child To Add
              </div>
              <Select
                options={detailsData.header?.SelectDocumentChildToAdd || []}
                value={selectedChildToAdd}
                onChange={handleSelectChildToAdd}
                style={{
                  width: '75%',
                }}
                disableClearInput
              />
            </div>
            <div className={classes.headerEntryWithSideButton}>
              <div className={classes.detailEntryContainer}>
                <div
                  className={clsx(classes.inputLabel, classes.layoutLabel)}
                >
                  Name
                </div>

                <Input
                  value={groupNameToAdd}
                  style={{
                    width: '75%',
                  }}
                  onChange={handleGroupNameToAddChange}
                  onClick={() => null}
                  onBlur={() => null}
                  required={false}
                  type="text"
                  disabled={false}
                  placeholder=""
                  name="Name"
                />
              </div>
              <div style={{ marginRight: -53 }}>
                <ButtonBase
                  onClick={onAddGroupToOutline}
                  classes={{
                    root: classes.secondaryButton,
                    disabled: classes.buttonDisabled,
                  }}
                  disabled={!!selectedChildToAdd && isOutlineItemNameValid(groupNameToAdd)}
                >
                  <div style={{
                    color: !!selectedChildToAdd && isOutlineItemNameValid(groupNameToAdd)
                      ? '#555'
                      : 'inherit',
                  }}
                  >
                    Add
                  </div>
                </ButtonBase>
              </div>
            </div>
          </div>

          <div className={classes.body}>
            {/* outline contents */}
            <div className={classes.outlineContentsContainer}>
              <div className={classes.outlineTree}>
                <ExpandableList
                  rowHeight={24}
                  items={outlineValues}
                  handleSelectEntry={handleSelectEntry}
                  handleSelectGroup={handleSelectGroup}
                  handleDeleteEntry={handleDeleteEntry}
                  handleDeleteGroup={handleDeleteGroup}
                  selectedEntryId={selectedEntryId}
                  selectedGroupId={selectedGroupId}
                />
              </div>
            </div>
            {/* outline details */}
            <div className={classes.outlineDetailContainer}>
              <div className={classes.outlineDetail}>
                {detailValues && selectedGroupId && Object.keys(detailValues ?? {})
                  .filter((key: string) => !['DocName'].includes(key))
                  .map((field: string) => (
                    <div className={classes.detailEntryContainer}>
                      <div
                        className={clsx(classes.inputLabel, classes.layoutLabel)}
                      >
                        {field}
                      </div>

                      <Input
                        value={detailValues[field]}
                        style={{
                          width: '70%',
                        }}
                        onChange={() => null}
                        onClick={() => null}
                        onBlur={() => null}
                        required={false}
                        type="text"
                        disabled={disabledDetailKeys.includes(field)}
                        placeholder=""
                        name="Name"
                      />
                    </div>
                  ))}
                {detailValues && selectedGroupId && (
                <div className={classes.detailEntryContainer}>
                  <div
                    className={clsx(classes.inputLabel, classes.layoutLabel)}
                  >
                    Field Name
                  </div>
                  <div className={classes.entryWithSideButton}>
                    <Input
                      value={entryNameToAdd}
                      style={{
                        width: '100%',
                      }}
                      onChange={handleEntryNameToAddChange}
                      onClick={() => null}
                      onBlur={() => null}
                      required={false}
                      type="text"
                      disabled={false}
                      placeholder=""
                      name="Name"
                    />
                    <div style={{ marginRight: -53 }}>
                      <ButtonBase
                        onClick={() => onAddEntryToOutline(selectedGroupId)}
                        classes={{
                          root: classes.secondaryButton,
                          disabled: classes.buttonDisabled,
                        }}
                        disabled={!!selectedChildToAdd && isOutlineItemNameValid(entryNameToAdd)}
                      >
                        <div style={{
                          color: !!selectedChildToAdd && isOutlineItemNameValid(entryNameToAdd)
                            ? '#555'
                            : 'inherit',
                        }}
                        >
                          Add
                        </div>
                      </ButtonBase>

                    </div>

                  </div>
                </div>
                )}
              </div>
              <div className={classes.outlineDetail}>
                {detailValues && selectedEntryId && Object.keys(detailValues ?? {})
                  .filter((key: string) => !['DocName', 'BoxName'].includes(key))
                  .map((field: string) => (
                    <div className={classes.detailEntryContainer}>
                      <div
                        className={clsx(classes.inputLabel, classes.layoutLabel)}
                      >
                        {field}
                      </div>

                      <Input
                        value={detailValues[field]}
                        style={{
                          width: '70%',
                        }}
                        onChange={() => null}
                        onClick={() => null}
                        onBlur={() => null}
                        required={false}
                        type="text"
                        disabled={disabledDetailKeys.includes(field)}
                        placeholder=""
                        name="Name"
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div
          id="outerCanvas"
          className={classes.canvasWrapper}
        >
          <Canvas showGrid={showGrid} />
        </div>
      </div>

    </div>
  );
};

export default DetailScreen;
