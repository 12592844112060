import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    minWidth: 220,
  },
  Stepperdiv: {
    marginLeft: '20px',
  },
  autoCompleteInput: {
    padding: '4.5px 8px 4.5px 6px !important',
    borderLeft: '4px solid #555 !important',
  },
  containerContract: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  Typo: {
    paddingTop: '5px',
  },
  nextPrevbtn: {
    marginRight: '10px',
    marginTop: '5px',
    blockSize: '27.1375px',
    borderBlockEndColor: 'rgb(255, 184, 72)',
    borderBlockEndStyle: 'solid',
    borderBlockEndWidth: '1px',
    borderBlockStartColor: 'rgb(255, 184, 72)',
    borderBlockStartStyle: 'solid',
    borderBlockStartWidth: '1px',
    borderCollapse: 'collapse',
    borderInlineEndColor: 'rgb(255, 184, 72)',
    borderInlineEndStyle: 'solid',
    borderInlineEndWidth: '1px',
    borderInlineStartColor: 'rgb(255, 184, 72)',
    borderInlineStartStyle: 'solid',
    borderInlineStartWidth: '5px',
    caretColor: 'rgb(255, 255, 255)',
    color: 'rgb(255, 255, 255)',
    columnRuleColor: 'rgb(255, 255, 255)',
    cursor: 'pointer',
    height: '27.1375px',
    inlineSize: '80.1px',
    paddingBlockEnd: '4px',
    paddingBlockStart: '4px',
    paddingInlineEnd: '10px',
    paddingInlineStart: '10px',
    perspectiveOrigin: '40.05px 13.5625px',
    pointerEvents: 'all',
    textDecoration: 'none solid rgb(255, 255, 255)',
    textEmphasisColor: 'rgb(255, 255, 255)',
    textSizeAdjust: '100%',
    touchAction: 'manipulation',
    transformOrigin: '40.05px 13.5688px',
    userSelect: 'none',
    verticalAlign: 'middle',
    whiteSpace: 'nowrap',
    width: '80.1px',
    background:
      'rgb(96, 134, 207) none repeat scroll 0% 0% / auto padding-box border-box',
    borderTop: '1px solid rgb(255, 184, 72)',
    borderRight: '1px solid rgb(255, 184, 72)',
    borderBottom: '1px solid rgb(255, 184, 72)',
    borderLeft: '5px solid rgb(255, 184, 72)',
    font: '12px / 17.1429px "Open Sans"',
    outline: 'rgb(255, 255, 255) none 0px',
    padding: '4px 10px',
  },
  disableBtn: {
    marginRight: '10px',
    marginTop: '5px',
    blockSize: '27.1375px',
    borderBlockEndColor: 'rgba(0, 0, 0, 0)',
    borderBlockEndStyle: 'solid',
    borderBlockEndWidth: '1px',
    borderBlockStartColor: 'rgba(0, 0, 0, 0)',
    borderBlockStartStyle: 'solid',
    borderBlockStartWidth: '1px',
    borderCollapse: 'collapse',
    borderInlineEndColor: 'rgba(0, 0, 0, 0)',
    borderInlineEndStyle: 'solid',
    borderInlineEndWidth: '1px',
    borderInlineStartColor: 'rgba(0, 0, 0, 0)',
    borderInlineStartStyle: 'solid',
    borderInlineStartWidth: '1px',
    caretColor: 'rgb(85, 85, 85)',
    columnRuleColor: 'rgb(85, 85, 85)',
    cursor: 'not-allowed',
    height: '27.1375px',
    inlineSize: '84.4125px',
    opacity: 0.65,
    paddingBlockEnd: '4px',
    paddingBlockStart: '4px',
    paddingInlineEnd: '10px',
    paddingInlineStart: '10px',
    perspectiveOrigin: '37.2px 13.5625px',
    pointerEvents: 'all',
    textDecoration: 'none solid rgb(85, 85, 85)',
    textEmphasisColor: 'rgb(85, 85, 85)',
    textSizeAdjust: '100%',
    touchAction: 'manipulation',
    transformOrigin: '37.2062px 13.5688px',
    userSelect: 'none',
    verticalAlign: 'middle',
    whiteSpace: 'nowrap',
    width: '74.4125px',
    background:
      'rgb(188, 188, 188) none repeat scroll 0% 0% / auto padding-box border-box',
    border: '1px solid rgba(0, 0, 0, 0)',
    font: '12px / 17.1429px "Open Sans"',
    outline: 'rgb(85, 85, 85) none 0px',
    padding: '4px 10px',
    color: 'white',
  },
  sideButton: {
    position: 'absolute',
    left: '95%',
  },
  btn_default: {
    color: '#333',
    fontFamily: 'calibri',
    backgroundColor: '#fff',
    borderColor: '#ddd',
    borderRadius: '2px',
    borderWidth: '0.3px',
    padding: '5px 12px',
  },
  dispatchbtn: {
    appearance: 'button',
    blockSize: '27.1375px',
    borderBlockEndColor: 'rgb(53, 170, 71)',
    borderBlockEndStyle: 'solid',
    borderBlockEndWidth: '1px',
    borderBlockStartColor: 'rgb(53, 170, 71)',
    borderBlockStartStyle: 'solid',
    borderBlockStartWidth: '1px',
    borderInlineEndColor: 'rgb(53, 170, 71)',
    borderInlineEndStyle: 'solid',
    borderInlineEndWidth: '1px',
    borderInlineStartColor: 'rgb(53, 170, 71)',
    borderInlineStartStyle: 'solid',
    borderInlineStartWidth: '5px',
    cursor: 'pointer',
    height: '27.1375px',
    inlineSize: '128.863px',
    paddingBlockEnd: '4px',
    paddingBlockStart: '4px',
    paddingInlineEnd: '10px',
    paddingInlineStart: '10px',
    perspectiveOrigin: '64.425px 13.5625px',
    pointerEvents: 'all',
    textSizeAdjust: '100%',
    touchAction: 'manipulation',
    transformOrigin: '64.4313px 13.5688px',
    userSelect: 'none',
    verticalAlign: 'middle',
    whiteSpace: 'nowrap',
    width: '128.863px',
    background:
      'rgb(221, 221, 221) none repeat scroll 0% 0% / auto padding-box border-box',
    borderTop: '1px solid rgb(53, 170, 71)',
    borderRight: '1px solid rgb(53, 170, 71)',
    borderBottom: '1px solid rgb(53, 170, 71)',
    borderLeft: '5px solid rgb(53, 170, 71)',
    font: '12px / 17.1429px "Open Sans"',
    padding: '4px 10px',
  },
  cross: {
    padding: '20px 0px',
  },
});
