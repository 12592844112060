/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
import React from 'react';

// Librairies
import { InputBase } from '@mui/material';

// Styles
import clsx from 'clsx';
import { useStyles } from './style';

interface IInputProps {
  value?: string | number | null;
  style?: React.CSSProperties;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
  required?: boolean;
  type?: string;
  disabled?: boolean;
  noBorder?: boolean;
  placeholder?: string;
  name?: string;
  maxLength?: number;
  minLength?: number;
  noShowLength?: boolean;
  error?: boolean;
}

const Input: React.FC<IInputProps> = ({
  value,
  style,
  onChange,
  onClick,
  onBlur,
  required,
  type,
  disabled,
  placeholder,
  name,
  maxLength,
  minLength,
  noBorder,
  noShowLength,
  error,
}) => {
  const classes = useStyles();

  const handleOnKeyDown = (e: any) => (type === 'number' ? e.keyCode === 69 && e.preventDefault() : null);

  return (
    <InputBase
      onBlur={onBlur}
      onClick={onClick}
      onChange={onChange}
      classes={{
        root: required
          ? clsx(
            classes.inputRequired,
            !noBorder && !value ? classes.borderRequired : classes.border,
            error && classes.error,
          )
          : noBorder
            ? clsx(classes.simpleInput, error && classes.error)
            : clsx(classes.input, error && classes.error),
      }}
      value={value}
      style={{ ...style, backgroundColor: disabled ? '#F4F4F4' : undefined }}
      type={type || 'text'}
      disabled={disabled}
      placeholder={placeholder}
      name={name}
      componentsProps={{
        input: {
          maxLength,
          minLength,
        },
      }}
      inputProps={{ onKeyDown: handleOnKeyDown }}
      endAdornment={
        maxLength && !noShowLength ? (
          <span>
            {(value as string)?.length}
            /
            {maxLength}
          </span>
        ) : null
      }
    />
  );
};

export default Input;
