import React, { useState } from 'react';

// library
import {
  TextField,
  Typography,
  InputAdornment,
  Fab,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

interface IProps {
    name: string;
    label: string;
    maxLength: number;
    classes: any;
    handleChange: any,
    min: number;
    step: number;
}

const CounterField: React.FC<IProps> = ({
  name,
  label,
  maxLength,
  classes,
  handleChange,
  min,
  step,
}) => {
  const [count, setCount] = useState<number>(min);
  // this for Adding Count for Counter field
  const add = () => {
    if (count !== maxLength) {
      setCount(count + step);
    }
  };
  // this for Subtracting Count for Counter field
  const subtract = () => {
    if (count !== min) {
      setCount(count - step);
    }
  };
  return (
    <>
      <Typography
        variant="caption"
        className={classes.label}
      >
        {label}
      </Typography>
      <TextField
        className="textfield"
        onChange={(e: any) => handleChange(e.target, name)}
        name={name}
        value={count}
        size="small"
        inputProps={{ maxLength }}
        style={{
          color: '#f7f7f7',
          background: 'transparent',
        // border: required ? '1px solid red' : 'transparent',
        }}
        // color={required && value === '' ? 'error' : 'primary'}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Fab size="small" color="primary" variant="extended">
                <RemoveIcon onClick={subtract} />
              </Fab>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <Fab size="small" color="primary" variant="extended">
                <AddIcon onClick={add} />
              </Fab>
            </InputAdornment>
          ),
        }}
        sx={{
          '& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input': {
            padding: '1px 2px',
            border: '1px solid #E4E4E4',
          },
          width: '75%',
          '& .MuiInputBase-root': {
            '& > fieldset': {
            // borderColor:
            //  'red',
              borderLeft: '5px solid red',
              marginLeft: '5px',
              marginRight: '8px',
            },
          },
        }}
      />
    </>
  );
};

export default CounterField;
