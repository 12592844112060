import React, { useState } from 'react';

// Librairies
import { Divider, Menu, MenuItem } from '@mui/material';
// import Api from '../../../../redux/api/index';
// Styles
import { useStyles } from './style';
// import ConfirmationDialog from '../../../ConfirmDialog';

interface IProps {
  anchorEl: any;
  onClose: () => void;
  downloadReportTxt: () => void;
  handleOpenDialog: (type: string) => void;
  downloadReportPDF: () => void;
}

const ViewReportOptions: React.FC<IProps> = ({
  anchorEl,
  onClose,
  downloadReportTxt,
  handleOpenDialog,
  downloadReportPDF,
}) => {
  const classes = useStyles();
  const handleViewReportOptionClick = () => {
    onClose();
  };

  return (
    <>
      <Menu
        classes={{ root: classes.root, list: classes.list, paper: classes.paper }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={onClose}
      >
        <MenuItem
          classes={{ root: classes.menuItem }}
          onClick={() => handleOpenDialog('Comment')}
        >
          Comment
        </MenuItem>
        <MenuItem
          classes={{ root: classes.menuItem }}
          onClick={() => handleOpenDialog('Email Report')}
        >
          Email
        </MenuItem>
        <MenuItem
          classes={{ root: classes.menuItem }}
          onClick={() => handleOpenDialog('Print Report')}
        >
          System-Printer
        </MenuItem>
        <Divider />
        <MenuItem classes={{ root: classes.menuItem }} onClick={downloadReportTxt}>
          Download Report(Text)
        </MenuItem>
        <MenuItem classes={{ root: classes.menuItem }} onClick={downloadReportPDF}>
          Download Report(PDF)
        </MenuItem>
      </Menu>
    </>
  );
};

export default ViewReportOptions;
