import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {},
  header: {
    width: '90%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '10px 0px',

  },
  dropdownUnit: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
  container: {
    border: '0.5px solid #D8D8D8',
    width: '100%',
    padding: '5px',
    margin: '10px 0px',
    '&:hover': {
      background: '#F4F8FF',
    },
  },
  tableContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  innerContainer: {
    width: '100%',
    display: 'flex',
    // alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    // flexWrap: 'wrap',
    // backgroundColor: 'cyan',
    overflowY: 'hidden',
  },
  innerContainerSubProducts: {
    width: '99%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    // flexWrap: 'wrap',
    // backgroundColor: 'cyan',
    overflowY: 'hidden',
    margin: '10px 0px 0px 0px',
  },
  product: {
    display: 'table',
    // justifyContent: 'space-between',
    // width: 'max-content',
    alignItems: 'center',
    // backgroundColor: 'red',
    width: '99%',
    margin: '0px 0px',
  },
  subContainer: {
    display: 'flex',
    width: '99%',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 4,
  },
  secondRowSubContainer: {
    display: 'flex',
    width: '80%',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 4,
  },
});
