import {
  LOAD_METER_ENTRY,
  CLEAR_METER_ENTRY,
  LOAD_METER_ENTRY_RECORD,
  SET_SELECTED_METER_RECORD,
  SET_METER_ENTRY_FORM_DATA,
  CLEAR_METER_ENTRY_FORM_DATA,
  SET_METER_ENTRY_FORM_HEADER,
  CLEAR_METER_ENTRY_FORM_HEADER,
  SET_METER_ENTRY_EXPORT_FILE,
  CLEAR_METER_ENTRY_EXPORT_FILE,
  SET_METER_ENTRY_EXPORT_LINK,
  CLEAR_METER_ENTRY_EXPORT_LINK,
  SET_CURRENT_METER_RECORDS,
  SET_METER_ENTRY_RESPONSE_NOTIFICATION,
  CLEAR_METER_ENTRY_RESPONSE_NOTIFICATION,
  GLOBALLY_SET_METER_ENTRY_FORM_DATA,
  SET_METER_ENTRY_TOTALIZE_DATA,
  CLEAR_METER_ENTRY_TOTALIZE_DATA,
} from '../../constants/workflows';

const INITIAL_STATE = {
  data: [],
  totalRecords: 0,
  totalRecordsWithSearch: 0,
  currentRecord: {},
  hiddenColumns: {},
  visibleColumns: {},
  selectedRecord: null,
  formData: {},
  formHeader: {},
  exportFile: '',
  exportLink: '',
  responseNotification: {},
};

const MeterEntryReducer = (state = INITIAL_STATE, { type, payload }: any) => {
  switch (type) {
    case LOAD_METER_ENTRY: {
      return {
        ...state,
        data: payload.data,
        totalRecords: payload.totalRecords,
        totalRecordsWithSearch: payload.totalRecordsWithSearch,
        hiddenColumns: payload.hiddenColumns,
        visibleColumns: payload.visibleColumns,
      };
    }
    case LOAD_METER_ENTRY_RECORD: {
      return {
        ...state,
        currentRecord: payload,
      };
    }
    case SET_CURRENT_METER_RECORDS: {
      return {
        ...state,
        currentRecord: {
          ...state.currentRecord,
          meterRecords: payload,
        },
      };
    }
    case SET_METER_ENTRY_TOTALIZE_DATA: {
      return {
        ...state,
        currentRecord: {
          ...state.currentRecord,
          totalizeData: payload,
        },
      };
    }
    case CLEAR_METER_ENTRY_TOTALIZE_DATA: {
      return {
        ...state,
        currentRecord: {
          ...state.currentRecord,
          totalizeData: {},
        },
      };
    }
    case GLOBALLY_SET_METER_ENTRY_FORM_DATA: {
      return {
        ...state,
        formData: payload,
      };
    }
    case SET_METER_ENTRY_RESPONSE_NOTIFICATION: {
      return {
        ...state,
        responseNotification: payload,
      };
    }
    case CLEAR_METER_ENTRY_RESPONSE_NOTIFICATION: {
      return {
        ...state,
        responseNotification: {},
      };
    }
    case SET_METER_ENTRY_FORM_HEADER: {
      return {
        ...state,
        formHeader: {
          ...state.formHeader,
          ...payload,
        },
      };
    }
    case CLEAR_METER_ENTRY_FORM_HEADER: {
      return {
        ...state,
        formHeader: {},
      };
    }
    case SET_METER_ENTRY_EXPORT_FILE: {
      return {
        ...state,
        exportFile: payload,
      };
    }
    case CLEAR_METER_ENTRY_EXPORT_FILE: {
      return {
        ...state,
        exportFile: '',
      };
    }
    case SET_METER_ENTRY_EXPORT_LINK: {
      return {
        ...state,
        exportLink: payload,
      };
    }
    case CLEAR_METER_ENTRY_EXPORT_LINK: {
      return {
        ...state,
        exportLink: '',
      };
    }
    case CLEAR_METER_ENTRY_FORM_DATA: {
      return {
        ...state,
        formData: {},
      };
    }
    case SET_METER_ENTRY_FORM_DATA: {
      return {
        ...state,
        formData: {
          ...state.formData,
          [payload.index]: payload.row,
        },
      };
    }
    case SET_SELECTED_METER_RECORD: {
      return {
        ...state,
        selectedRecord: payload,
      };
    }
    case CLEAR_METER_ENTRY: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
};

export default MeterEntryReducer;
