/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';

// Librairies
import { useSelector } from 'react-redux';

// Components
import TmsHeader from '../../../components/header';
import SubHeader from '../../../components/subHeader';
import Loader from '../../../components/Loader';
import ReportTable from '../../../components/Reports/Workflows/WorkflowTable';
import { useWorkflowState } from '../../../hooks/useWorkflowState';
import WorkflowTableUtils from '../../../components/WorkflowTableUtils';

// Actions
import { getCustomReport } from '../../../redux/actions/reports/customreportconfiguration';

const tableHeader = {
  group_id: 'Group ID',
};

const searchHeader = [
  { label: 'Group ID', key: 'group_id' },
];

const CustomReportConfiguration = () => {
  const { data } = useSelector(
    (state: any) => state?.Reports?.CustomReportConfiguration,
  );
  const {
    recordsToShow,
    tableState,
    handlePaginationChange,
    handleSearchChange,
    handleSelectChange,
    handleSortTable,
  } = useWorkflowState(searchHeader, data, getCustomReport);
  const handleRowClick = (row: any) => {
    console.log('clicked');
  };

  return (
    <>
      <TmsHeader />
      <SubHeader title="Custom Report Configuration">
        <Loader getWorkflowData={getCustomReport} />
      </SubHeader>
      <WorkflowTableUtils
        handleSearchChange={handleSearchChange}
        handleSelectChange={handleSelectChange}
        recordsNumber={tableState.recordsNumber}
        searchText={tableState.searchText}
      />
      <ReportTable
        handleClickRow={(e: any) => handleRowClick(e)}
        visibleColumns={tableHeader}
        allDataCount={data.length}
        handlePaginationChange={handlePaginationChange}
        recordsToShow={recordsToShow}
        tableHeader={tableHeader}
        tableState={tableState}
        order={tableState.order}
        orderBy={tableState.orderBy}
        searchText={tableState.searchText}
        sortTable={handleSortTable}
      />
    </>
  );
};

export default CustomReportConfiguration;
