import axios from 'axios';
import { concatenateFields } from '../../utils';
import {
  GET_ALL_EMBBEDED_COLUMNS,
  LOAD_EMBBEDED_RECORD,
  LOAD_EMBBEDED_SCREEN,
  SWITCH_EMBEDDED_NAVIGATION,
  BATCH_MODE_COLUMS_EMBEDDED,
  SET_RECORD_LIST_EMBEDDED,
  DELETE_RECORD_EMBEDDED,
  UPDATE_RECORD,
  SAVE_BATCH_MODE_QUERY_EMBEDDED,
  OPEN_COLUMN_MODAL_EMBEDDED,
  CLOSE_COLUMN_MODAL_EMBEDDED,
  OPEN_FILTER_MODAL_EMBEDDED,
  CLOSE_FILTER_MODAL_EMBEDDED,
  START_LOADING_EMBEDDED,
  STOP_LOADING_EMBEDDED,
  START_LOADING_EXPORT_EMBEDDED,
  STOP_LOADING_EXPORT_EMBEDDED,
  SAVE_FILTER_EMBEDDED,
  DELETE_FILTER_EMBEDDED,
} from '../constants/Screens';

type screenOption = {
  tableID: any;
  page?: any;
  results_per_page?: string;
  fields?: string;
  fieldsConcatenated?: string;
  where?: string;
  order_by?: string;
  screenID?: string;
  lang?: string;
  Selectedcolumns?: any;
  navigate?: any;
  redirect?: any;
  EmptyRows?: any;
  whereRecord?: any;
  screenId?: any;
  keys?: any;
  search?: string;
  dependentKeyEmbedded?: any;
  data?: any;
};
export const LoadEmbbededTables = (options: any) => async (dispatch: any) => {
  console.log('options', options);
  try {
    const fieldsConcatenated = concatenateFields(
      options.fields?.split(',') || [],
      options.fieldsConcatenated || '',
    );

    const fields = Array.from(new Set([...fieldsConcatenated.split(','), ...options.keys.split(',')])).join(',');

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}tablescreen/tablemaintdata/?tableID=${
        options.tableID
      }
        &page=${options.page}&results_per_page=${options.results_per_page}
      &fields=${fields}&where=${options.where}&search=${
  options.search ? options.search : ''
}&order_by=${options.order_by}&lang=${options.lang}`,
      {
        headers: {
          accept: '*/*',
        },
      },
    );
    dispatch({
      type: LOAD_EMBBEDED_SCREEN,
      payload: response.data,
      screen: options,
    });
  } catch (error: any) {
    console.log(error.data);
  }
};
export const LoadRecordEmbbeded = (options: any) => async (dispatch: any) => {
  console.log('LoadRecord Options', options);
  try {
    dispatch({ type: START_LOADING_EMBEDDED });
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}tablescreen/showrecord?tableID=${options.tableID}
      &screenID=${options.screenID}&where=${options.where}&lang=${options.lang}`,
      {
        headers: {
          accept: '*/*',
        },
      },
    );

    const result = await response.data.data.map((item: any) => (
      {
        [item.id || item]: {
          default: item.default,
          id: item.id,
          label: item.label,
          helpertext: item.helpertext,
          type: item.type,
          obj: item.obj,
          query: item.query,
          selectList: item.selectList,
          chain: item.chain,
          valForm: item.value,
          attr: item.attr,
          mask: item.mask,
          required: item.attr.includes('required'),
          gridId: item.gridId,
          validate: item.validate,
          visible: item.visible,
          key: item.key,
        },
      }
    )).reduce((acc:any, item:any) => ({ ...acc, ...item }), {});
    const resultAll = Object.keys(response.data.fieldTabs).reduce((acc:any, tab:any) => ({
      ...acc,
      [tab]: response.data.fieldTabs[tab].map((field:any) => result[field]),
    }), {});
    // const resultAll = await response.data.data.reduce(
    //   (h: any, tabs: any) => Object.assign(h, {
    //     [tabs.tab]: (h[tabs.tab] || []).concat({
    //       default: tabs.default,
    //       id: tabs.id,
    //       label: tabs.label,
    //       helpertext: tabs.helpertext,
    //       type: tabs.type,
    //       obj: tabs.obj,
    //       query: tabs.query,
    //       selectList: tabs.selectList,
    //       chain: tabs.chain,
    //       valForm: tabs.value,
    //       attr: tabs.attr,
    //       mask: tabs.mask,
    //       required: tabs.attr.includes('required'),
    //       gridId: tabs.gridId,
    //       validate: tabs.validate,
    //       visible: tabs.visible,
    //     }),
    //   }),
    //   {},
    // );
    const values = await response.data.data.reduce(
      (h: any, tabs: any) => Object.assign(h, {
        [tabs.id]: tabs.value || '',
      }),
      {},
    );
    const allTypes = await new Set(
      Object.values(
        response.data.data.reduce(
          (h: any, tabs: any) => Object.assign(h, {
            [tabs.id]: tabs.type,
          }),
          {},
        ),
      ),
    );

    const activelabels = await response.data.data.reduce(
      (h: any, tabs: any) => Object.assign(h, {
        [tabs.id]: tabs.label,
      }),
      {},
    );

    const fields = options?.fields
      ? options.fields.split(',')
      : Object.keys(activelabels);
    const activeLabelsTableView = fields.map(
      (value: any) => activelabels[value] || value,
    );
    const mappedData: any = await Object.values(resultAll);
    const Ids = Object.entries(mappedData)
      .map((elt: any) => elt?.[1]?.map((element: any) => [element?.id, element?.valForm]))
      .flat();
    // const FormatedLabels = await Object.entries(activelabels)
    //   .map((elt: any) => elt[1].map((element: any) => [element.id, element.valForm]))
    //   .flat();
    // const arrayToObject = Ids.reduce((o, k) => ({ ...o, [k]: '' }), {});
    // const Ids:any = mappedData.map((v: any)=>v.map((element: any) => [element.id, element.valForm]));
    // const mapData = new Map(Ids);
    const arrayToObject = Object.fromEntries(Ids);
    const { grid } = response.data;
    Object.keys(grid).forEach((key: any) => {
      grid[key].fieldlist = grid[key].fieldlist.map((field:any) => field.map((item:any) => result[item]));
    });

    dispatch({
      type: LOAD_EMBBEDED_RECORD,
      payload: resultAll,
      cloneForm: arrayToObject,
      parsedValues: values,
      labels: activeLabelsTableView,
      allTypes,
      allLabels: activelabels,
      grid,
      unitCalculator: response.data?.unitCalculator,
    });
  } catch (error: any) {
    console.log('error Load Record Action:', error);
  }
};
export const LoadAllColumnsEmbbededTables = (options: screenOption) => async (dispatch: any) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}tablescreen/?tableID=${options.tableID}
        &page=${options.page}&results_per_page=${options.results_per_page}`,
      {
        headers: {
          accept: '*/*',
        },
      },
    );
    const allTableColumns = Object.keys(response.data.data[0]);
    // const AllfiltredColumns = allTableColumns.filter((item:any) => !options.Selectedcolumns.includes(item));
    dispatch({ type: GET_ALL_EMBBEDED_COLUMNS, payload: allTableColumns });
  } catch (error: any) {
    console.log(error.data);
  }
};
export const SwitchEmbeddedNavigation = (options: string) => async (dispatch: any) => {
  try {
    dispatch({ type: SWITCH_EMBEDDED_NAVIGATION, payload: options });
  } catch (error: any) {
    console.log(error.data);
  }
};

export const UpdateRecordEmbedded = (query: any) => async (dispatch: any) => {
  try {
    const o = Object.fromEntries(
      Object.entries(query.form_data).filter(([_, v]) => v !== ''),
    );
    o.filler = '00';
    const compData = query.compulsory_data;
    compData.map((v: any) => ({ ...v, filler: '00' }));
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}batch/modifyformdata`,
      {
        table: query.table,
        compulsory_data: compData,
        form_data: o,
      },
      {
        headers: {
          accept: '*/*',
          'Content-Type': 'application/json',
        },
      },
    );
    dispatch({ type: SWITCH_EMBEDDED_NAVIGATION, payload: 'tableScreen' });
  } catch (error: any) {
    console.error(error);
  }
};

export const SetBatchModeEmbedded = ({
  tableOpt, selected, deleteQuery, backScreen, selectedBatch,
}: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: BATCH_MODE_COLUMS_EMBEDDED,
      payload: {
        tableOpt,
        selected,
        backScreen,
        deleteQuery,
        selectedBatch,
      },
    });
  } catch (error: any) {
    console.log(error.data);
  }
};

export const SetRecordsListEmbedded = (list: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: SET_RECORD_LIST_EMBEDDED,
      payload: list,
    });
  } catch (error: any) {
    console.log(error.data);
  }
};

export const DeleteRecordEmbedded = ({ options, activeScreen }: any) => async (dispatch: any) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}tablescreen/deleterec`,
      {
        params: {
          tableID: options.tableID,
          where: JSON.stringify([JSON.parse(options.where)]),
        },
        headers: {
          accept: '*/*',
        },
      },
    );
    dispatch(
      LoadEmbbededTables({
        tableID: activeScreen.tableID,
        page: '1',
        results_per_page: '10',
        fields: activeScreen.fields,
        order_by: '',
        where: '',
        EmptyRows: activeScreen.EmptyRows,
        redirect: activeScreen.redirect,
        keys: activeScreen.keys,
        screenId: activeScreen.screenId,
      }),
    );
    dispatch({ type: DELETE_RECORD_EMBEDDED, payload: response.data });
  } catch (error: any) {
    console.log(error.data);
  }
};
export const BatchDeleteRecordEmbedded = ({
  options, navigate, tableId, activeScreen,
}: any) => async (dispatch: any) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}tablescreen/deleterec`,
      {
        params: {
          tableID: tableId,
          where: JSON.stringify(options),
        },
        headers: {
          accept: '*/*',
        },
      },
    );
    dispatch({ type: DELETE_RECORD_EMBEDDED, payload: response.data });
    dispatch(
      LoadEmbbededTables({
        tableID: activeScreen.tableID,
        page: '1',
        results_per_page: '10',
        fields: activeScreen.fields,
        order_by: '',
        where: '',
        EmptyRows: activeScreen.EmptyRows,
        redirect: activeScreen.redirect,
        keys: activeScreen.keys,
        screenId: activeScreen.screenId,
      }),
    );
    // navigate(activeScreen.redirect);
  } catch (error: any) {
    console.log(error.data);
  }
};

export const CloneRecordSubmitEmbedded = (query: any) => async (dispatch: any) => {
  const o = Object.fromEntries(
    Object.entries(query.form_data).filter(([_, v]) => v !== null),
  );
  console.log('sending======>', o);
  o.filler = '00';
  o.last_upd_usr = 'chaione';
  fetch(`${process.env.REACT_APP_API_URL}formscreen/storeformdata`, {
    method: 'post',
    headers: { 'Content-Type': 'application/json', accept: '*/*' },
    body: JSON.stringify({
      table: query.table,
      form_data: o,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status < 230) {
        dispatch(SwitchEmbeddedNavigation('tableScreen'));
        dispatch({
          type: UPDATE_RECORD,
          payload: res,
          message: 'Record created/cloned successfully',
        });
      } else {
        dispatch({
          type: UPDATE_RECORD,
          payload: res,
          message: res.message || 'An error has occured!',
        });
      }
    });
};

export const saveFieldBatchModeEmbedded = ({ valuesToSend, values }: any) => async (dispatch: any) => {
  try {
    // const batchFormat=query.map((val:any,key:any)=>{
    //   return(
    //     [{Key:val.}]
    //   )
    // })
    console.log('query', values);
    const batchFormat = Object.keys(valuesToSend);
    const batchFormatVal = Object.values(valuesToSend);
    const batchFormatLabels: any[] = Object.values(values);
    const armixed = batchFormat.map((x, i) => ({
      key: x,
      value: batchFormatVal[i],
    }));
    const armixedLables = batchFormat.map((x, i) => ({
      key: x,
      value: batchFormatLabels[i]?.label || batchFormatLabels[i],
    }));
    dispatch({
      type: SAVE_BATCH_MODE_QUERY_EMBEDDED,
      payload: { armixed, query: valuesToSend, values: armixedLables },
    });
  } catch (error: any) {
    console.log(error.data);
  }
};

export const openColumnModalEmbedded = () => async (dispatch: any) => {
  try {
    dispatch({ type: OPEN_COLUMN_MODAL_EMBEDDED, payload: true });
  } catch (error: any) {
    console.log(error.data);
  }
};
export const CloseColumnModalEmbedded = () => async (dispatch: any) => {
  try {
    dispatch({ type: CLOSE_COLUMN_MODAL_EMBEDDED, payload: false });
  } catch (error: any) {
    console.log(error.data);
  }
};
export const openFilterModalEmbedded = () => async (dispatch: any) => {
  try {
    dispatch({ type: OPEN_FILTER_MODAL_EMBEDDED, payload: true });
  } catch (error: any) {
    console.log(error.data);
  }
};
export const CloseFilterModalEmbedded = () => async (dispatch: any) => {
  try {
    dispatch({ type: CLOSE_FILTER_MODAL_EMBEDDED, payload: false });
  } catch (error: any) {
    console.log(error.data);
  }
};
export const StartLoadingEmbedded = () => async (dispatch: any) => {
  dispatch({ type: START_LOADING_EMBEDDED });
};
export const StopLoading = () => async (dispatch: any) => {
  dispatch({ type: STOP_LOADING_EMBEDDED });
};
export const StartExporting = () => async (dispatch: any) => {
  dispatch({ type: START_LOADING_EXPORT_EMBEDDED });
};
export const StopExporting = () => async (dispatch: any) => {
  dispatch({ type: STOP_LOADING_EXPORT_EMBEDDED });
};
export const SaveFilterEmbedded = (screenId: string, filter: any) => async (dispatch: any) => {
  try {
    dispatch({ type: SAVE_FILTER_EMBEDDED, payload: filter, screenId });
  } catch (error: any) {
    console.log(error.data);
  }
};

export const DeleteFilterEmbedded = (screenId: string) => async (dispatch: any) => {
  try {
    dispatch({ type: DELETE_FILTER_EMBEDDED, payload: screenId });
  } catch (error: any) {
    console.log(error.data);
  }
};
