import React, { useState } from 'react';
import './style.css';
import { Button, CircularProgress, Typography } from '@mui/material';
import clsx from 'clsx';
import TmsHeader from '../../../components/header';
import CustomDropdown from '../../../components/customDropdown';
import { useStyles } from '../vipScullyCommunications/style';
import OptionsButton from '../../../components/optionsButton';
import SingleItemOptions from '../../../components/SingleItemOptions';
import SubHeader from '../../../components/subHeader';
import ContractDispatchStepper from '../../../components/ContractDispatchStepper';
import ContractDispatchOptionButton from '../../../components/ContractDispatchOptionButton';

const ContractDispatch = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [clicked1, setClicked1] = useState(false);
  const [clicked2, setClicked2] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [disabled1, setDisabled1] = useState(false);
  const [disabled2, setDisabled2] = useState(true);
  const handleToggle1 = () => {
    setClicked1((prev) => !prev);
  };
  const handleToggle2 = () => {
    setClicked2((prev) => !prev);
  };
  const NextStep = () => {
    setDisabled1(true);
    setDisabled2(false);
    setClicked1(false);
    setClicked2(true);
  };
  const handleOptionClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleOpenColumnsDialog = () => {
    handleCloseMenu();
  };

  return (
    Loading ? (
      <div
        style={{
          width: '100%',
          height: '90vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </div>
    ) : (
      <>
        <TmsHeader />
        <SubHeader title="Contract Dispatch">
          <OptionsButton handleClick={handleOptionClick} />
          <ContractDispatchOptionButton
            anchorEl={anchorEl}
            onClose={handleCloseMenu}
            openDialog={handleOpenColumnsDialog}
            itemLabel="Show Optional Fields"
          />
        </SubHeader>
        <ContractDispatchStepper />
      </>
    )
  );
};

export default ContractDispatch;
