import React, { useState } from 'react';

// Librairies
import { ButtonBase } from '@mui/material';
import { ArrowDropDownOutlined } from '@mui/icons-material';

// Components
import ProcessMenuList from './Menu';

// Styles
import { useStyles } from './style';

interface IProps {
  manageGroupProcess: (controlType: string, processId: boolean, groupId: boolean) => void;
  status: string;
  removeRow: () => void;
}

const ProcessMenu: React.FC<IProps> = (props) => {
  const [processMenuAnchorEl, setProcessMenuAnchorEl] = useState<null | HTMLElement>(null);

  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setProcessMenuAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setProcessMenuAnchorEl(null);
  };

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <ButtonBase
        onClick={handleButtonClick}
        style={{
          padding: '2px 2px',
          border: 'solid 1px rgb(221, 221, 221)',
        }}
      >
        <ArrowDropDownOutlined fontSize="small" />
      </ButtonBase>
      <ProcessMenuList anchorEl={processMenuAnchorEl} onClose={handleCloseMenu} {...props} />
    </div>
  );
};

export default ProcessMenu;
