/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/function-component-definition */
import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { PlusOne } from '@mui/icons-material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxRoundedIcon from '@mui/icons-material/IndeterminateCheckBoxRounded';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import CustomDropdown from '../bulkTransactionCustomDropdown/index';
import CustomInput from '../bulkTransactionCustomInput/index';
import { useStyles } from '../../screens/Workflows/bulkTransaction/style';
import BulkTransactionProductStep from '../bulkTransactionProducts/bulkTransactionProductStep';
import CustomAddDropdown from '../customAddDropdown';
import { GetTransactionSchema } from '../../redux/actions/ScreenActions';
import { SubmitTransactionDialog } from '../dialogSubmitTransaction';
import { SetActiveStep } from '../../redux/actions/workflows/contractTransaction';
import SupplierFromRecord from './supplierFromRecord';
import BulkTransferTankFromSupplierStep from './bulkTransferFromSupplierStep';
import BulkTransferTankToSupplierStep from './bulktransferTanksToSupplier';

export default function BulkTransferTankVerticalStepper({
  data,
  values,
  handleInputChange,
  handleSelectChange,
  valuesToSend,
  isBulkTransaction,
  handleOtherStepsChange,
  visible,
  stepperTitles,
  handleInputProductChange,
  screenID,
  onOpenSubmit,
  currentSchema,
  prefixProducts,
  resetPrefixProducts,
  prefix,
  products,
  addProducts,
  removeFormFields,
  handleRecordSelectChange,
  SupplierfromStepReviewState,
  SupplierToStepReviewState,
  startNewTransaction,
  onCloseSubmit,
  openSubmit,
  contractidOptions,
  transactionMode,
  addSupplierTo,
  removeToFields,
  supplierTo,
  prefixToSupplier,
  prefixFromSupplier,
}: any) {
  const [activeStep, setActiveStep] = React.useState<any>(0);
  const [EditingStep, setEditingStep] = React.useState<any>(0);
  const [review, setReview] = React.useState(false);
  const [expandedState, setExpandedState] = React.useState<any>([]);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loading, stepperData } = useSelector(
    (state: any) => state.ScreenReducer,
  );
  const handleExpandStep = (stepKey: string, collapseAll = false) => {
    if (collapseAll) {
      const keysList = Object.keys(expandedState);
      const newStates = keysList?.reduce(
        (acc: any, key: any) => ({ ...acc, [key]: false }),
        {},
      );
      setExpandedState(newStates ?? expandedState);
    } else {
      setExpandedState((previousExpandState: any) => ({
        ...previousExpandState,
        [stepKey]: !previousExpandState[stepKey],
      }));
    }
  };
  const getValueForReview = (key:string, field:string, valuesSource:any) => {
    const searchedField:any = Object.keys(valuesSource).filter((item:any) => item?.split('-')?.[1] === field);
    return field === 'contractid' ? field : searchedField?.[0];
  };
  React.useEffect(() => {
    dispatch(SetActiveStep({ activeStep, EditingStep }));
  }, [activeStep, EditingStep]);

  const handleNext = () => {
    if (activeStep < 3) {
      setReview(true);
      setActiveStep((prevActiveStep:any) => prevActiveStep + 1);
      setEditingStep((prevEditingStep:any) => prevEditingStep + 1);
    } else {
      onOpenSubmit();
    }
  };
  const handleBack = () => {
    setReview(true);
    setActiveStep((prevActiveStep:any) => prevActiveStep - 1);
    setEditingStep((prevEditingStep:any) => prevEditingStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setEditingStep(0);
  };

  const handleActiveStepEditing = (index: number) => {
    setEditingStep(index);
    setActiveStep(index);
  };
  const handleActiveStepReviewing = (index: number) => {
    setReview(false);
    setActiveStep(index);
  };
  const startNewTransactionAndReset = () => {
    setActiveStep(0);
    setEditingStep(0);
    startNewTransaction();
    onCloseSubmit();
  };
  const copyTransaction = () => {
    setActiveStep(0);
    setEditingStep(0);
    onCloseSubmit();
  };

  React.useEffect(() => {
    const stepNames = data?.reduce(
      (h: any, step: any) => Object.assign(h, {
        [step[0]?.stepID]: false,
      }),
      {},
    );

    setExpandedState(stepNames);
    handleActiveStepEditing(0);
  }, [data]);

  return (
    <div
      style={{
        width: '100%',
        padding: '10px 0px',
        display: 'flex',
        justifyContent: 'center !important',
        alignItems: 'center !important',
        maxHeight: '90vh',
        overflow: 'auto',
      }}
    >
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        sx={{
          width: '100%',
          '& .css-8t49rw-MuiStepConnector-line': {
            display: 'block',
            borderLeft: '1px solid #bdbdbd',
            minHeight: '2px',
          },
        }}
      >
        {data?.map((step: any, index: any) => (
          <Step
            key={index}
            sx={{
              width: '100%',
              padding: '0px',
            }}
            expanded={index === activeStep}
          >
            <StepLabel
              icon={
                index === activeStep ? (
                  <IndeterminateCheckBoxRoundedIcon onClick={() => setActiveStep(null)} color="inherit" />
                ) : (
                  <AddBoxIcon onClick={() => handleActiveStepReviewing(index)} />
                )
              }
              sx={{
                backgroundColor: index === activeStep ? '#d8f0ff' : '#ffffff',
                border: index === activeStep ? 'none' : '1px solid #C0C0C0',
                fontWeight: 'bold',
                alignSelf: 'center',
                padding: '0px 20px',
                display: 'flex',
                '& .css-14yr603-MuiStepContent-root': {
                  marginLeft: '0px',
                },
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <div
                  onClick={() => handleActiveStepReviewing(index)}
                >
                  <Typography variant="h6" fontFamily="inherit" fontSize={14} color="#000">
                    Step -
                    {index + 1}
                  </Typography>
                  <Typography fontSize={11} color="#777">
                    {index === EditingStep
                      ? (
                        <div style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 5,
                        }}
                        >
                          {stepperTitles[index]}
                          <Typography fontSize={11} color="#000" fontFamily="inherit" fontWeight="bold">(Editing)</Typography>
                        </div>
                      )
                      : stepperTitles[index]}
                  </Typography>
                </div>
                {index !== activeStep ? (
                  index === 0 ? (
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        width: '80%',
                        whiteSpace: 'nowrap',
                        position: 'relative',
                      }}
                    >
                      {step.map(
                        (val: any, key: any) => valuesToSend?.[val.id] && (
                        <div className={classes.review}>
                          <div
                            style={{
                              width: '200px',
                              display: 'flex',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <Typography
                              variant="body2"
                              fontFamily="inherit"
                              fontSize={12}
                              fontWeight="bolder"
                            >
                              {val?.label || val}
                              {' '}
                            </Typography>
                          </div>
                          <div
                            style={{
                              width: '200px',
                              display: 'flex',
                              justifyContent: 'flex-start',
                            }}
                          >
                            <Typography
                              variant="body2"
                              fontFamily="inherit"
                              fontSize={12}
                            >
                              {/* {values?.[val?.id]?.value}
                            {' '}
                            -
                            {' '} */}
                              {val?.id?.search('date') > 1 ? valuesToSend?.[val?.id]?.replaceAll('-', '/') : (values?.[val?.id]?.label || valuesToSend?.[val?.id])}
                            </Typography>
                          </div>
                        </div>
                        ),
                      )}
                    </div>
                  )
                    : index === 1 ? (
                      products?.length && prefix && prefixFromSupplier ? (
                        <div>
                          <table>
                            <tbody>
                              <tr>
                                {SupplierfromStepReviewState?.map((product:any, keyProd:any) => (<td style={{ padding: '0px 20px', fontWeight: 'bold' }}>{product?.label}</td>))}
                              </tr>
                              {products?.map((product:any, keyProd:any) => (
                                <tr>
                                  {SupplierfromStepReviewState?.map((reviewProduct:any, key:any) => {
                                    getValueForReview(product.id, reviewProduct?.id, prefixFromSupplier);
                                    return (
                                      <td style={{ padding: '0px 20px', borderBottom: '1px solid #C0C0C0' }}>
                                        <Typography fontSize={12} fontFamily="inherit">
                                          {prefixFromSupplier?.[getValueForReview(product.id, reviewProduct?.id, prefixFromSupplier)]}
                                        </Typography>

                                      </td>
                                    );
                                  })}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      ) : <></>
                    ) : (
                      products?.length && prefix && prefixToSupplier ? (
                        <div>
                          <table>
                            <tbody>
                              <tr>
                                {SupplierToStepReviewState?.map((product:any, keyProd:any) => (<td style={{ padding: '0px 20px', fontWeight: 'bold' }}>{product?.label}</td>))}
                              </tr>
                              {products?.map((product:any, keyProd:any) => (
                                <tr>
                                  {SupplierToStepReviewState?.map((reviewProduct:any, key:any) => {
                                    getValueForReview(product.id, reviewProduct?.id, prefixToSupplier);
                                    return (
                                      <td style={{ padding: '0px 20px', borderBottom: '1px solid #C0C0C0' }}>
                                        <Typography fontSize={12} fontFamily="inherit">
                                          {prefixToSupplier?.[getValueForReview(product.id, reviewProduct?.id, prefixToSupplier)]}
                                        </Typography>

                                      </td>
                                    );
                                  })}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      ) : <></>
                    )
                ) : (
                  <></>
                )}
                {EditingStep !== index && (
                <Button
                  variant="outlined"
                  size="small"
                  color="inherit"
                  sx={{
                    textTransform: 'none',
                    height: '80%',
                  }}
                  onClick={() => handleActiveStepEditing(index)}
                >
                  Edit
                </Button>
                )}
              </div>
            </StepLabel>

            <StepContent sx={{
              marginLeft: '0px',
            }}
            >
              {isBulkTransaction
                && index === 0
                && activeStep < 1
                && step.map((val: any, key: any) => (!currentSchema?.[val.id]?.visible && !visible ? (
                  <></>
                ) : ['truck', 'trailer1', 'trailer2']?.includes(val.id) ? (
                  <CustomAddDropdown
                    val={val}
                    isBatch={false}
                    label={val?.label}
                    name={val?.id}
                    defaultValue={val?.value}
                    isDisabled={activeStep !== EditingStep}
                    value={values[val?.id]}
                    isOptionEqualToValue={(option: any, value: any) => option?.label === value}
                    onChange={handleRecordSelectChange}
                    size="70%"
                    margin={0}
                    options={val?.obj['\u0000*\u0000parm'].options}
                    type={val?.type}
                    obj={val?.obj}
                    query={val?.query}
                    required={val?.attr?.includes('required')}
                    selectList={val?.selectList}
                    chain={val?.chain}
                    stateValues={values}
                    valuesToSend={valuesToSend}
                  />
                )
                  : (currentSchema?.[val.id]?.type
                    || currentSchema?.[val.id]?.['\u0000*\u0000parm']?.options
                      ?.length)
                    ? (
                      <CustomDropdown
                        val={currentSchema?.[val.id]}
                        isBatch={false}
                        label={val?.label}
                        name={val?.id}
                        defaultValue={val?.value}
                        isDisabled={currentSchema?.[val?.id]?.disabled || (activeStep !== EditingStep)}
                        value={
                         valuesToSend[val?.id]
                      }
                        isOptionEqualToValue={(option: any, value: any) => option?.label === value && value !== ''}
                        onChange={handleRecordSelectChange}
                        size="70%"
                        margin={0}
                        chained={currentSchema?.[val?.id]?.['\u0000*\u0000parm']}
                        options={
                          val?.id === 'contractid' ? contractidOptions
                            : currentSchema?.[val.id]?.['\u0000*\u0000parm']
                              ?.options
                        || (currentSchema?.[val.id]?.list
                          ? Object.entries(currentSchema?.[val.id]?.list).map(
                            (item: any) => ({
                              value: item[0],
                              label: item[1]?.replaceAll('&nbsp;', ''),
                            }),
                          )
                          : [])
}
                        type={val?.type}
                        obj={val?.obj}
                        query={currentSchema?.[val.id]?.['\u0000*\u0000parm']}
                        required={val?.attr?.includes('required')}
                        selectList={val?.selectList}
                        chain={val?.chain}
                        stateValues={values}
                        valuesToSend={valuesToSend}
                      />
                    ) : (
                      <CustomInput
                        dateType={currentSchema?.[val.id]?.['\u0000*\u0000parm']?.mode
                        === 'datetime'}
                        type={
                        currentSchema?.[val.id]?.['\u0000*\u0000parm']?.mode
                        === 'datetime'
                          ? 'date'
                          : 'text'
                      }
                        label={val?.label}
                        required={val?.attr?.includes('required')}
                        name={val?.id}
                        defaultValue={val?.valForm}
                        attr={val?.attr}
                        value={valuesToSend?.[val?.id]}
                        onChange={handleInputChange}
                        size="70%"
                        maxLength={10}
                        inputProps={{ maxLength: 10 }}
                        placeholder={val.label}
                        isDisabled={activeStep !== EditingStep}
                        validate={val?.validate}
                      />
                    )))}
              {activeStep === 1 && (
                <div style={{
                  width: '99%',
                }}
                >
                  <BulkTransferTankFromSupplierStep
                    productsData={data[3] ?? []}
                    values={values}
                    handleInputChange={handleInputChange}
                    handleSelectChange={handleSelectChange}
                    handleInputProductChange={handleInputProductChange}
                    valuesToSend={valuesToSend}
                    stateValues={values}
                    visible={visible}
                    screenID={screenID}
                    prefixProducts={prefixFromSupplier}
                    resetPrefixProducts={resetPrefixProducts}
                    prefix={prefix}
                    products={products}
                    addProducts={addProducts}
                    removeFormFields={removeFormFields}
                    review={activeStep !== EditingStep}
                    transactionMode={transactionMode}
                  />
                </div>
              )}
              {activeStep === 2 && (
              <div style={{
                width: '99%',
              }}
              >
                <BulkTransferTankToSupplierStep
                  productsData={data[3] ?? []}
                  values={values}
                  handleInputChange={handleInputChange}
                  handleSelectChange={handleSelectChange}
                  handleInputProductChange={handleInputProductChange}
                  valuesToSend={valuesToSend}
                  stateValues={values}
                  visible={visible}
                  screenID={screenID}
                  prefixProducts={prefixToSupplier}
                  prefixFromSupplier={products?.[0]?.id}
                  resetPrefixProducts={resetPrefixProducts}
                  prefix={prefix}
                  products={supplierTo}
                  addProducts={addSupplierTo}
                  removeFormFields={removeToFields}
                  review={activeStep !== EditingStep}
                  transactionMode={transactionMode}
                />
              </div>
              )}

              {isBulkTransaction && index === activeStep && (
                <Box sx={{ mb: 2 }}>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Button
                      variant="contained"
                      disabled={index === 0}
                      onClick={handleBack}
                      sx={{ mt: 1, mr: 1, textTransform: 'none' }}
                    >
                      Prev Step
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      sx={{ mt: 1, mr: 1, textTransform: 'none' }}
                      disabled={index === data.length - 1 && Object.values(prefixFromSupplier)?.length === 0}
                    >
                      {index === data.length - 1
                        ? 'Submit Transaction'
                        : 'Next Step'}
                    </Button>
                  </div>
                </Box>
              )}
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === data?.length && data?.length > 0 && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
        </Paper>
      )}
      <SubmitTransactionDialog
        onClose={onCloseSubmit}
        open={openSubmit}
        startNewTransaction={startNewTransactionAndReset}
        copyTransaction={copyTransaction}
      />
    </div>
  );
}
