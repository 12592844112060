/* eslint-disable quote-props */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useState } from 'react';

// Librairies
import {
  Box,
  ButtonBase,
  Divider,
  Tab,
  Tabs,
  useMediaQuery,
} from '@mui/material';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

// Components
import { DropResult, DragStart } from 'react-beautiful-dnd';
import SubHeader from '../../../components/subHeader';
import TmsHeader from '../../../components/header';
import Input from '../../../components/Input';

// Styles
import { useStyles } from './style';

// Actions
import { getScreensList } from '../../../redux/actions/ScreenActions';

// Utils
import { isEmpty } from '../../../utils';
import {
  IColumns,
  makeDefaultValue,
  getColumns,
  deleteFromColumns,
  makeColumnsFromIds,
  filterColumns,
} from '../../../components/ColumnsDialog/utils';
import { Columns } from '../../../components/ColumnsDialog';
import ConfirmationDialog from '../../../components/ConfirmDialog';
import Select from '../../../components/Select';
import {
  addRecord,
  deleteRecord,
  getConditionSetNewRecord,
  getConditionSetRecord,
  setConditionSetFormHeader,
  updateRecord,
} from '../../../redux/actions/workflows/conditionSet';

const formatHeaderValues = (showFields: any[]) => showFields.reduce(
  (acc, curr) => ({
    ...acc,
    [curr.id]: curr,
  }),
  {},
);

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const TabPanel = (props: any) => {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
};

const ConditionSetRecordDetail: React.FC = () => {
  const { conditionSetId } = useParams();
  if (!conditionSetId) return <AddRecord />;
  return <UpdateRecord conditionSetId={conditionSetId} />;
};

const AddRecord: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [cancelConfirmDialogOpen, setCancelConfirmDialogOpen] = useState<boolean>(false);
  const [removeConfirmDialogOpen, setRemoveConfirmDialogOpen] = useState<boolean>(false);
  const [tabValue, setTabValue] = React.useState(0);
  const navigate = useNavigate();
  const { currentRecord, formHeader } = useSelector(
    (state: any) => state.Workflows.ConditionSet,
  );
  const [erpHandlingColumns, setErpHandlingColumns] = useState<IColumns>({
    visibleColumns: {},
    hiddenColumns: {},
  });
  const [productColumns, setProductColumns] = useState<IColumns>({
    visibleColumns: {},
    hiddenColumns: {},
  });

  const handleChangeTabs = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    dispatch(getScreensList());
    dispatch(getConditionSetNewRecord());
  }, []);

  const headerValues = currentRecord?.showFields
    ? formatHeaderValues(currentRecord?.showFields)
    : {};

  const openCancelConfirmDialog = () => {
    if (formHeader?.change) {
      setCancelConfirmDialogOpen(true);
    } else {
      window.location.href = '/workflow/condition-set';
    }
  };

  useEffect(() => {
    if (
      !isEmpty(currentRecord?.erpHandlingdestList)
      || !isEmpty(currentRecord?.erpHandlingSourceList)
    ) {
      setErpHandlingColumns({
        hiddenColumns: makeDefaultValue(
          currentRecord?.erpHandlingSourceList || {},
        ),
        visibleColumns: makeDefaultValue(
          currentRecord?.erpHandlingdestList || {},
        ),
      });
    }
  }, [
    JSON.stringify(currentRecord?.erpHandlingdestList),
    JSON.stringify(currentRecord?.erpHandlingSourceList),
  ]);

  useEffect(() => {
    if (
      !isEmpty(currentRecord?.productsDestLis)
      || !isEmpty(currentRecord?.productsSourceList)
    ) {
      setProductColumns({
        hiddenColumns: makeDefaultValue(
          currentRecord?.productsSourceList || {},
        ),
        visibleColumns: makeDefaultValue(currentRecord?.productsDestLis || {}),
      });
    }
  }, [
    JSON.stringify(currentRecord?.productsSourceList),
    JSON.stringify(currentRecord?.productsDestLis),
  ]);

  const closeCancelConfirmDialog = () => {
    setCancelConfirmDialogOpen(false);
  };

  const closeAgreeCancelConfirmDialog = () => {
    closeCancelConfirmDialog();
    window.location.href = '/workflow/condition-set';
  };

  const openRemoveConfirmDialog = () => {
    setRemoveConfirmDialogOpen(true);
  };

  const closeRemoveConfirmDialog = () => {
    setRemoveConfirmDialogOpen(false);
  };

  const closeAgreeRemoveConfirmDialog = () => {
    navigate('/workflow/condition-set');
  };

  const handleSave = () => {
    dispatch(addRecord(
      Object.keys(productColumns.visibleColumns),
      Object.keys(erpHandlingColumns.visibleColumns),
    ));
    navigate('/workflow/condition-set');
  };

  return (
    <div className={clsx('fullHeight', 'flexColumn', classes.root)}>
      <TmsHeader />
      <SubHeader title="Condition Set: Detail" />
      <div>
        <ButtonBase
          className={classes.saveButton}
          onClick={handleSave}
          disabled={!formHeader?.change || !formHeader?.Description}
        >
          Save
        </ButtonBase>
        <ButtonBase
          className={classes.cancleButton}
          onClick={openCancelConfirmDialog}
        >
          Cancel
        </ButtonBase>
        <ButtonBase
          className={classes.removeButton}
          disabled
        >
          Remove
        </ButtonBase>
      </div>
      <Divider className={classes.divider} />
      <ConditionSetForm
        values={headerValues}
        formValues={formHeader}
        update={false}
      />
      <div>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 1 }}>
          <Tabs
            aria-label="basic tabs example"
            onChange={handleChangeTabs}
            value={tabValue}
          >
            <Tab
              label="ERP Handling Type"
              {...a11yProps(0)}
              className={classes.tab}
              value={0}
            />
            <Tab
              label="Product"
              {...a11yProps(0)}
              className={classes.tab}
              value={1}
            />
          </Tabs>
        </Box>
        {tabValue === 0 ? (
          <TabPanel value={0} index={0}>
            <ColumnsComponent
              columns={erpHandlingColumns}
              setColumns={setErpHandlingColumns}
              visibleColumns={currentRecord?.erpHandlingdestList || {}}
              hiddenColumns={currentRecord?.erpHandlingSourceList || {}}
              formValues={formHeader}
              leftTitle="ERP Handling Types"
              rightTitle="Set ERP Handling Types"
              columnsLabels={{
                ...currentRecord?.erpHandlingdestList,
                ...currentRecord?.erpHandlingSourceList,
              }}
            />
          </TabPanel>
        ) : null}
        {tabValue === 1 ? (
          <TabPanel value={1} index={1}>
            <ColumnsComponent
              columns={productColumns}
              setColumns={setProductColumns}
              visibleColumns={currentRecord?.productsDestLis || {}}
              hiddenColumns={currentRecord?.productsSourceList || {}}
              columnsLabels={{
                ...currentRecord?.productsDestLis,
                ...currentRecord?.productsSourceList,
              }}
              formValues={formHeader}
              leftTitle="Products"
              rightTitle="Set Products"
            />
          </TabPanel>
        ) : null}
      </div>
      <ConfirmationDialog
        cancelLabel="Cancel"
        confirmLabel="Yes"
        handleCloseAgree={closeAgreeCancelConfirmDialog}
        handleCloseCancel={closeCancelConfirmDialog}
        message="You have made changes
        on this page. If you leave without
        saving, your changes will be lost. Do you want to continue?"
        open={cancelConfirmDialogOpen}
        title="Leave Page"
        helperText="This action cannot be undone."
      />
      <ConfirmationDialog
        cancelLabel="Cancel"
        confirmLabel="Yes"
        handleCloseAgree={closeAgreeRemoveConfirmDialog}
        handleCloseCancel={closeRemoveConfirmDialog}
        message="You are about to remove this record. Do you want to continue?"
        open={removeConfirmDialogOpen}
        title="Remove Entry"
        helperText="This action cannot be undone."
      />
    </div>
  );
};

const UpdateRecord: React.FC<{ conditionSetId: string }> = ({
  conditionSetId,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [cancelConfirmDialogOpen, setCancelConfirmDialogOpen] = useState<boolean>(false);
  const [removeConfirmDialogOpen, setRemoveConfirmDialogOpen] = useState<boolean>(false);
  const [tabValue, setTabValue] = React.useState(0);
  const navigate = useNavigate();
  const { currentRecord, formHeader } = useSelector(
    (state: any) => state.Workflows.ConditionSet,
  );
  const [erpHandlingColumns, setErpHandlingColumns] = useState<IColumns>({
    visibleColumns: {},
    hiddenColumns: {},
  });
  const [productColumns, setProductColumns] = useState<IColumns>({
    visibleColumns: {},
    hiddenColumns: {},
  });

  const handleChangeTabs = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    dispatch(getScreensList());
    dispatch(getConditionSetRecord({ conditionSetId }));
  }, []);

  const headerValues = currentRecord?.showFields
    ? formatHeaderValues(currentRecord?.showFields)
    : {};

  const openCancelConfirmDialog = () => {
    if (formHeader?.change) {
      setCancelConfirmDialogOpen(true);
    } else {
      window.location.href = '/workflow/condition-set';
    }
  };

  useEffect(() => {
    setErpHandlingColumns({
      hiddenColumns: makeDefaultValue(
        currentRecord?.erpHandlingSourceList || {},
      ),
      visibleColumns: makeDefaultValue(
        currentRecord?.erpHandlingdestList || {},
      ),
    });
  }, [
    JSON.stringify(currentRecord?.erpHandlingdestList),
    JSON.stringify(currentRecord?.erpHandlingSourceList),
  ]);

  useEffect(() => {
    setProductColumns({
      hiddenColumns: makeDefaultValue(
        currentRecord?.productsSourceList || {},
      ),
      visibleColumns: makeDefaultValue(currentRecord?.productsDestList || {}),
    });
  }, [
    JSON.stringify(currentRecord?.productsSourceList),
    JSON.stringify(currentRecord?.productsDestList),
  ]);

  const closeCancelConfirmDialog = () => {
    setCancelConfirmDialogOpen(false);
  };

  const closeAgreeCancelConfirmDialog = () => {
    closeCancelConfirmDialog();
    window.location.href = '/workflow/condition-set';
  };

  const openRemoveConfirmDialog = () => {
    setRemoveConfirmDialogOpen(true);
  };

  const closeRemoveConfirmDialog = () => {
    setRemoveConfirmDialogOpen(false);
  };

  const closeAgreeRemoveConfirmDialog = () => {
    dispatch(deleteRecord(conditionSetId));
    navigate('/workflow/condition-set');
  };

  const handleSave = () => {
    dispatch(updateRecord(
      Object.keys(productColumns.visibleColumns),
      Object.keys(erpHandlingColumns.visibleColumns),
      conditionSetId,
    ));
    navigate('/workflow/condition-set');
  };

  return (
    <div className={clsx('fullHeight', 'flexColumn', classes.root)}>
      <TmsHeader />
      <SubHeader title="Condition Set: Detail" />
      <div>
        <ButtonBase className={classes.saveButton} onClick={handleSave}>
          Save
        </ButtonBase>
        <ButtonBase
          className={classes.cancleButton}
          onClick={openCancelConfirmDialog}
        >
          Cancel
        </ButtonBase>
        <ButtonBase
          className={classes.removeButton}
          onClick={openRemoveConfirmDialog}
        >
          Remove
        </ButtonBase>
      </div>
      <Divider className={classes.divider} />
      <ConditionSetForm values={headerValues} formValues={formHeader} update />
      <div>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 1 }}>
          <Tabs
            aria-label="basic tabs example"
            onChange={handleChangeTabs}
            value={tabValue}
          >
            <Tab
              label="ERP Handling Type"
              {...a11yProps(0)}
              className={classes.tab}
              value={0}
            />
            <Tab
              label="Product"
              {...a11yProps(0)}
              className={classes.tab}
              value={1}
            />
          </Tabs>
        </Box>
        {tabValue === 0 ? (
          <TabPanel value={0} index={0}>
            <ColumnsComponent
              columns={erpHandlingColumns}
              setColumns={setErpHandlingColumns}
              visibleColumns={currentRecord?.erpHandlingdestList || {}}
              hiddenColumns={currentRecord?.erpHandlingSourceList || {}}
              formValues={formHeader}
              leftTitle="ERP Handling Types"
              rightTitle="Set ERP Handling Types"
              columnsLabels={{
                ...currentRecord?.erpHandlingdestList,
                ...currentRecord?.erpHandlingSourceList,
              }}
            />
          </TabPanel>
        ) : null}
        {tabValue === 1 ? (
          <TabPanel value={1} index={1}>
            <ColumnsComponent
              columns={productColumns}
              setColumns={setProductColumns}
              visibleColumns={currentRecord?.productsDestList || {}}
              hiddenColumns={currentRecord?.productsSourceList || {}}
              columnsLabels={{
                ...currentRecord?.productsDestList,
                ...currentRecord?.productsSourceList,
              }}
              formValues={formHeader}
              leftTitle="Products"
              rightTitle="Set Products"
            />
          </TabPanel>
        ) : null}
      </div>
      <ConfirmationDialog
        cancelLabel="Cancel"
        confirmLabel="Yes"
        handleCloseAgree={closeAgreeCancelConfirmDialog}
        handleCloseCancel={closeCancelConfirmDialog}
        message="You have made changes
        on this page. If you leave without
        saving, your changes will be lost. Do you want to continue?"
        open={cancelConfirmDialogOpen}
        title="Leave Page"
        helperText="This action cannot be undone."
      />
      <ConfirmationDialog
        cancelLabel="Cancel"
        confirmLabel="Yes"
        handleCloseAgree={closeAgreeRemoveConfirmDialog}
        handleCloseCancel={closeRemoveConfirmDialog}
        message="You are about to remove this record. Do you want to continue?"
        open={removeConfirmDialogOpen}
        title="Remove Entry"
        helperText="This action cannot be undone."
      />
    </div>
  );
};

interface IConditionSetForm {
  values: any;
  formValues: any;
  update: boolean;
}

const ConditionSetForm: React.FC<IConditionSetForm> = ({
  values,
  formValues,
  update,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const matches = useMediaQuery('(min-width:992px)');
  const [formState, setFormState] = React.useState(() => ({
    Description: '',
    ProductFlag: '',
  }));

  useEffect(() => {
    const selectedProductFlag = values?.ProductFlag
      && values?.ProductFlag?.obj?.['\u0000*\u0000parm']?.options.find(
        (x: any) => x.value === values?.ProductFlag?.obj?.['\u0000*\u0000parm'].value,
      );
    setFormState({
      Description: values?.Description?.value?.trim() || '',
      ProductFlag: selectedProductFlag,
    });
    dispatch(
      setConditionSetFormHeader({
        Description: values?.Description?.value?.trim() || '',
        ProductFlag: selectedProductFlag,
        change: false,
      }),
    );
  }, [values?.Description?.value, values?.ProductFlag?.value]);

  const handleTextInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setFormState({ ...formState, [event.target.name]: event.target.value });
  };

  const handleSelectChange = (e: any, option: any) => {
    setFormState({
      ...formState,
      ProductFlag: option,
    });
    dispatch(
      setConditionSetFormHeader({
        Description: formState.Description,
        ProductFlag: option,
        change: true,
      }),
    );
  };

  const handleInputBlur = () => {
    dispatch(
      setConditionSetFormHeader({
        Description: formState.Description,
        ProductFlag: formState.ProductFlag,
        change: true,
      }),
    );
  };

  return (
    <form className={clsx(classes.form, 'flexColumn')}>
      <div
        className={clsx(
          classes.inputContainer,
          matches ? 'flexRowAlignCenter' : 'flexColumn',
        )}
      >
        <div
          className={clsx(matches && 'flexStartEndColumn', classes.inputLabel)}
          style={{ width: '25%' }}
        >
          Description
        </div>
        <Input
          onChange={handleTextInputChange}
          value={formState.Description}
          name="Description"
          placeholder="Description"
          onBlur={handleInputBlur}
          disabled={update}
          required
          maxLength={update ? undefined : 60}
          style={{
            minWidth: matches ? 500 : undefined,
            width: matches ? '75%' : undefined,
          }}
        />
      </div>
      <div
        className={clsx(
          classes.inputContainer,
          matches ? 'flexRowAlignCenter' : 'flexColumn',
        )}
      >
        <div
          className={clsx(matches && 'flexStartEndColumn', classes.inputLabel)}
          style={{ width: '25%' }}
        >
          Condition Type
        </div>
        <Select
          options={
            values?.ProductFlag?.obj?.['\u0000*\u0000parm']?.options || []
          }
          value={formState.ProductFlag}
          onChange={handleSelectChange}
          style={{
            minWidth: matches ? 500 : undefined,
            width: matches ? '75%' : undefined,
          }}
          required
        />
      </div>
    </form>
  );
};

interface IColumnsComponent {
  columns: IColumns;
  setColumns: React.Dispatch<React.SetStateAction<IColumns>>;
  visibleColumns: { [key: string]: string };
  hiddenColumns: { [key: string]: string };
  columnsLabels: { [key: string]: string };
  formValues: any;
  leftTitle: string;
  rightTitle: string;
}

export const ColumnsComponent: React.FC<IColumnsComponent> = ({
  columns,
  setColumns,
  hiddenColumns,
  visibleColumns,
  formValues,
  leftTitle,
  rightTitle,
  columnsLabels,
}) => {
  const [searchText, setSearchText] = useState('');
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
  const [draggingColumnId, setDraggingColumnId] = useState('');
  const dispatch = useDispatch();

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    window.addEventListener('click', onWindowClick);
    window.addEventListener('keydown', onWindowKeyDown);
    window.addEventListener('touchend', onWindowTouchEnd);
  }, []);

  const onWindowKeyDown = (event: KeyboardEvent) => {
    if (event.defaultPrevented) {
      return;
    }

    if (event.key === 'Escape') {
      unselectAll();
    }
  };

  const onWindowClick = (event: MouseEvent) => {
    if (event.defaultPrevented) {
      return;
    }
    unselectAll();
  };

  const onWindowTouchEnd = (event: TouchEvent) => {
    if (event.defaultPrevented) {
      return;
    }
    unselectAll();
  };

  const unselectAll = () => {
    setSelectedColumns([]);
  };

  useEffect(() => {
    const filtredColumns: any = filterColumns(
      makeDefaultValue({ ...visibleColumns, ...hiddenColumns }),
      columnsLabels,
      searchText,
    );
    const newVisibleColumns = getColumns(
      columns.visibleColumns,
      filtredColumns,
    );
    const newHiddenColumns = getColumns(columns.hiddenColumns, filtredColumns);
    setColumns({
      hiddenColumns: newHiddenColumns,
      visibleColumns: newVisibleColumns,
    });
  }, [searchText]);

  const handleDragFields = (result: DropResult) => {
    setDraggingColumnId('');
    if (!result) return;
    dispatch(
      setConditionSetFormHeader({
        ...formValues,
        change: true,
      }),
    );
    if (result?.destination?.droppableId !== result.source.droppableId) {
      if (result?.destination?.droppableId === 'hiddenColumns') {
        if (selectedColumns.length > 1) {
          const newHiddenColumns = {
            ...columns.hiddenColumns,
            ...makeColumnsFromIds(selectedColumns, columns.visibleColumns),
          };
          const arrayToReorder = Object.keys(newHiddenColumns);
          const [...removed] = arrayToReorder.splice(
            arrayToReorder.length - selectedColumns.length,
            selectedColumns.length,
          );
          arrayToReorder.splice(
            result?.destination?.index as number,
            0,
            ...removed,
          );
          const resultArray = makeColumnsFromIds(
            arrayToReorder,
            newHiddenColumns,
          );
          const newVisibleColumns = deleteFromColumns(
            columns.visibleColumns,
            selectedColumns,
          );
          setColumns({
            hiddenColumns: resultArray,
            visibleColumns: newVisibleColumns,
          });
          return;
        }
        const newHiddenColumns = {
          ...columns.hiddenColumns,
          [result.draggableId]: columns.visibleColumns[result.draggableId],
        };
        const arrayToReorder = Object.keys(newHiddenColumns);
        const [removed] = arrayToReorder.splice(arrayToReorder.length - 1, 1);
        arrayToReorder.splice(result?.destination?.index as number, 0, removed);
        const resultArray = makeColumnsFromIds(
          arrayToReorder,
          newHiddenColumns,
        );
        const newVisibleColumns = { ...columns.visibleColumns };
        delete newVisibleColumns[result.draggableId];
        setColumns({
          hiddenColumns: resultArray,
          visibleColumns: newVisibleColumns,
        });
      } else {
        if (selectedColumns.length > 1) {
          const newVisibleColumns = {
            ...columns.visibleColumns,
            ...makeColumnsFromIds(selectedColumns, columns.hiddenColumns),
          };
          const arrayToReorder = Object.keys(newVisibleColumns);
          const [...removed] = arrayToReorder.splice(
            arrayToReorder.length - selectedColumns.length,
            selectedColumns.length,
          );
          arrayToReorder.splice(
            result?.destination?.index as number,
            0,
            ...removed,
          );
          const resultArray = makeColumnsFromIds(
            arrayToReorder,
            newVisibleColumns,
          );
          const newHiddenColumns = deleteFromColumns(
            columns.hiddenColumns,
            selectedColumns,
          );
          setColumns({
            hiddenColumns: newHiddenColumns,
            visibleColumns: resultArray,
          });
          return;
        }
        const newVisibleColumns = {
          ...columns.visibleColumns,
          [result.draggableId]: columns.hiddenColumns[result.draggableId],
        };
        const arrayToReorder = Object.keys(newVisibleColumns);
        const [removed] = arrayToReorder.splice(arrayToReorder.length - 1, 1);
        arrayToReorder.splice(result?.destination?.index as number, 0, removed);
        const resultArray = makeColumnsFromIds(
          arrayToReorder,
          newVisibleColumns,
        );
        const newHiddenColumns = { ...columns.hiddenColumns };
        delete newHiddenColumns[result.draggableId];
        setColumns({
          hiddenColumns: newHiddenColumns,
          visibleColumns: resultArray,
        });
      }
      return;
    }
    if (result?.destination?.droppableId === result.source.droppableId) {
      if (result?.destination?.droppableId === 'visibleColumns') {
        if (selectedColumns.length > 1) {
          const allColumnsArray = Object.keys(columns.visibleColumns);
          const prevElemInOriginal = allColumnsArray[result.destination.index];
          const newVisibleColumns = deleteFromColumns(
            columns.visibleColumns,
            selectedColumns,
          );
          const arrayToReorder = Object.keys(newVisibleColumns);
          const prevElIndexInNewArray = arrayToReorder.indexOf(prevElemInOriginal);
          let destinationIndex;
          if (result.destination.index > result.source.index) {
            destinationIndex = prevElIndexInNewArray + 1;
          } else {
            destinationIndex = prevElIndexInNewArray;
          }
          arrayToReorder.splice(destinationIndex, 0, ...selectedColumns);
          const resultArray = makeColumnsFromIds(
            arrayToReorder,
            columns.visibleColumns,
          );
          setColumns({
            ...columns,
            visibleColumns: resultArray,
          });
          return;
        }
        const arrayToReorder = Object.keys(columns.visibleColumns);
        const [removed] = arrayToReorder.splice(result.source.index, 1);
        arrayToReorder.splice(result.destination.index, 0, removed);
        const resultArray = makeColumnsFromIds(
          arrayToReorder,
          columns.visibleColumns,
        );
        setColumns({
          ...columns,
          visibleColumns: resultArray,
        });
      } else {
        if (selectedColumns.length > 1) {
          const allColumnsArray = Object.keys(columns.hiddenColumns);
          const prevElemInOriginal = allColumnsArray[result.destination.index];
          const newHiddenColumns = deleteFromColumns(
            columns.hiddenColumns,
            selectedColumns,
          );
          const arrayToReorder = Object.keys(newHiddenColumns);
          const prevElIndexInNewArray = arrayToReorder.indexOf(prevElemInOriginal);
          let destinationIndex;
          if (result.destination.index > result.source.index) {
            destinationIndex = prevElIndexInNewArray + 1;
          } else {
            destinationIndex = prevElIndexInNewArray;
          }
          arrayToReorder.splice(destinationIndex, 0, ...selectedColumns);
          const resultArray = makeColumnsFromIds(
            arrayToReorder,
            columns.hiddenColumns,
          );
          setColumns({
            ...columns,
            hiddenColumns: resultArray,
          });
          return;
        }
        const arrayToReorder = Object.keys(columns.hiddenColumns);
        const [removed] = arrayToReorder.splice(result.source.index, 1);
        arrayToReorder.splice(result.destination.index, 0, removed);
        const resultArray = makeColumnsFromIds(
          arrayToReorder,
          columns.hiddenColumns,
        );
        setColumns({
          ...columns,
          hiddenColumns: resultArray,
        });
      }
    }
  };

  const onDragStart = (start: DragStart) => {
    const id: string = start.draggableId;
    const selected = selectedColumns.find((x) => x === id);
    if (!selected) {
      unselectAll();
    }
    setDraggingColumnId(start.draggableId);
  };

  const toggleSelection = (columnId: string) => {
    const selColumns = selectedColumns;
    const wasSelected: boolean = selColumns.includes(columnId);
    const newColumnsIds: string[] = (() => {
      if (!wasSelected) {
        return [columnId];
      }
      if (selColumns.length > 1) {
        return [columnId];
      }
      return [];
    })();

    setSelectedColumns(newColumnsIds);
  };

  const toggleSelectionInGroup = (columnId: string) => {
    const selCols: string[] = selectedColumns;
    const index: number = selCols.indexOf(columnId);
    if (index === -1) {
      setSelectedColumns([...selCols, columnId]);
      return;
    }
    const shallow: string[] = [...selCols];
    shallow.splice(index, 1);
    setSelectedColumns(shallow);
  };

  const multiSelectTo = (newColumnId: string) => {
    console.log(newColumnId);
  };

  return (
    <div>
      <Columns
        leftTitle={leftTitle}
        rightTitle={rightTitle}
        columns={columns}
        columnsLabels={columnsLabels}
        draggingColumnId={draggingColumnId}
        handleDragFields={handleDragFields}
        handleSearchChange={handleSearchChange}
        multiSelectTo={multiSelectTo}
        onDragStart={onDragStart}
        searchText={searchText}
        selectedColumns={selectedColumns}
        toggleSelection={toggleSelection}
        toggleSelectionInGroup={toggleSelectionInGroup}
      />
    </div>
  );
};

export default ConditionSetRecordDetail;
