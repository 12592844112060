/* eslint-disable react/jsx-indent */
/* eslint-disable react/require-default-props */
import React from 'react';

// Librairies
import { Grid, InputBase, Typography } from '@mui/material';

// Styles
import clsx from 'clsx';
import { useStyles } from './style';
import { ResponseAlert } from '../ResponseAlert';
import PresetCard from '../PresetCard';

interface IProps {
    presetStatus: any;
    confirmationBeforeProCommand: any;
}

const PresetCardBody: React.FC<IProps> = ({ presetStatus, confirmationBeforeProCommand }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        {presetStatus.map((preset: any) => (
          <PresetCard
            presetDescription={preset?.presetDescription}
            presetOpt={preset?.options}
            presetStatusStyle={preset?.presetStatusStyle}
            presetStatusLang={preset?.presetStatusLang}
            meterlist={preset?.meterList}
            presetID={preset?.presetID}
            preset={preset?.preset}
            delivered={preset?.delivered}
            temperature={preset?.temperature}
            flowRate={preset?.flowrate}
            pctComplete={preset?.pctcomplete}
            etcStr={preset?.etcStr}
            confirmationBeforeProCommand={confirmationBeforeProCommand}
          />
        ))}
      </Grid>
      <ResponseAlert />
    </div>
  );
};

export default PresetCardBody;
